import { Formik, FormikProps } from 'formik';
import { filter, map } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, PageHeader } from 'react-bootstrap';
import { fieldSchemas } from '../../util/validations/formSchemas';
import * as Yup from 'yup';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { emptyUser } from '../../store/initial';
import { InjectedProps, User } from '../../types';
import { UserRole } from '../../types/UserRole';
import ControlButtonGroup from '../misc/ControlButtonGroup';
import NotFound from '../misc/NotFound';
import UserForm, { IUserForm } from './UserForm';

interface UserEditState {
    editing: boolean;
    notFound: boolean;
}

@inject('store')
@observer
class UserEdit extends React.Component<{}, UserEditState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state = {
        editing: false,
        notFound: false
    };

    public get id() {
        return this.injected.store.router.params.id ? parseInt(this.injected.store.router.params.id, 10) : 0;
    }

    public getUser() {
        const { users } = this.injected.store;
        return users.getItem(this.id);
    }

    public initialValues(user: User) {
        const { isLoading } = this.injected.store;

        return isLoading || !user
            ? emptyUser
            : {
                id: user.id,
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                role_id: user.role_id,
                company_ids: map(user.companies, 'id'),
                agency_ids: map(user.agencies, 'id'),
                pass: '',
                confirm_pass: '',
                reset_password: user.reset_password
            };
    }

    public fetchData() {
        const { fetchRoles, fetchUsers } = this.injected.store;
        return fetchUsers(this.id)
            .then(() => fetchRoles())
            .catch(() => this.setState({ notFound: true }));
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public render() {
        if (this.state.notFound) {
            return <NotFound />;
        }
        const user = this.getUser();
        const initialValues = this.initialValues(user);
        return (
            <>
                <PageHeader>User Details</PageHeader>
                <Col className="fixed-controls">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={this.onSubmit}
                        onReset={this.handleCancel}
                        validationSchema={Yup.object().shape({
                            email: fieldSchemas.email.standard
                        })}
                    >
                        {this.renderForm()}
                    </Formik>
                </Col>
            </>
        );
    }

    public renderForm = () => {
        const { activeUser, getActiveCompanies, fetchUserData } = this.injected.store;
        const { accessibleAgencies } = this.injected.store.agencyStore;
        const { editing } = this.state;
        const { canEdit, canDelete, availableRoles } = this.getPermissions();

        return UserForm({
            fetchUserData,
            activeUser,
            editing,
            companies: getActiveCompanies() || [],
            agencies: accessibleAgencies,
            roles: availableRoles,
            isNew: false,
            controls: (bag: FormikProps<IUserForm>) => (
                <ControlButtonGroup
                    editing={this.state.editing}
                    canEdit={canEdit}
                    canDelete={canDelete}
                    handleEdit={this.toggleEdit}
                    handleBack={this.handleBack}
                    handleDelete={this.handleDelete}
                />
            )
        });
    };

    public getPermissions() {
        const { roles, hasAccess, activeUser, activeUserFetched } = this.injected.store;

        const user = this.getUser();
        const activeUserRole = roles.getItem(activeUser!.role_id);

        let canEdit = hasAccess('users.update');
        let canDelete = hasAccess('users.delete');
        let availableRoles: UserRole[] = [];

        if (user && activeUser && activeUserFetched) {
            const role = roles.getItem(user.role_id);

            if (role) {
                canEdit = canEdit && activeUserRole.access_level >= role.access_level;
                canEdit = user.id === activeUser.id || canEdit;
                canDelete = canDelete && activeUserRole.access_level > role.access_level;
            }

            availableRoles = filter(
                roles.data,
                role => activeUserRole.slug === 'super_admin' || role.access_level <= activeUser.role.access_level
            );
        }
        return { canEdit, canDelete, availableRoles };
    }

    public toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    public handleCancel = () => {
        this.setState({ editing: false });
    };

    public handleBack = () => {
        const { router } = this.injected.store;
        const backLink = (router.params?.backRoute as unknown) as Route;
        if (backLink) {
            return router.goTo(backLink, router.params, this.injected.store);
        }
        router.goTo(
            router.params.agencyId ? DefaultRoutes.AgencyUsers : DefaultRoutes.UserList,
            { ...router.params },
            this.injected.store
        );
    };

    public onSubmit = (values: any, { setErrors }: any) => {
        const { users } = this.injected.store;
        return users
            .update(values)
            .then(() => {
                this.setState({ editing: false });
            })
            .catch(error => setErrors(error.response.data));
    };

    public handleDelete = () => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
            const { deleteUser } = this.injected.store;
            deleteUser(this.id);
            this.handleBack();
        }
        return;
    };
}

export default UserEdit;
