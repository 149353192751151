export const remoteAuthTypes = {
    Outlook: 1,
    Gmail: 2,
    AWS: 3,
    Zoho: 4
};

export const remoteAuthTypeNames = {
    1: 'Microsoft Outlook',
    2: 'Google Gmail',
    3: 'Amazon Web Services',
    4: 'Zoho'
};
