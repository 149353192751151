import React, { Reducer, useReducer } from 'react';
import { observer } from 'mobx-react';
import {
    IconButton,
    AppHeader,
    AppPage,
    AppContent,
    AppList,
    AppListItem,
    AppListItemIcon,
    AppListItemContent,
    Button,
    Title,
    ButtonPrimary,
    Modal,
    ModalHeader,
    ModalActions,
    ButtonDanger,
    Form,
    InputText,
    InputSelect,
    ButtonUnstyled,
    ActionList
} from '../../components-v2/shared';

import useStore from '../../store/useStore';
import styles from './schedule-editor.module.scss';
import { handlePromise, useAsyncEffect } from '../../util/async';
import {
    ScheduleEditorListReducerAction,
    ScheduleEditorListReducerState,
    scheduleEditorListReducer
} from './ScheduleEditor.helpers';
import { FormikHelpers, FormikProps } from 'formik';
import { Link } from 'mobx-router';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { CalendarDayoff } from '../../types/CalendarDayoff';
import { AvailabilitySchedule } from './ScheduleEditor.types';
import { CreateScheduleFormValidationSchema } from './ScheduleEditor.validation';

interface CreateScheduleForm {
    name: string;
    selectedHoliday?: { label: string; value: CalendarDayoff | { id?: number } };
}

interface CopyScheduleForm {
    name: string;
    id?: number;
}

export const ScheduleEditorListPage = observer(() => {
    const { store } = useStore();
    const { router, Api } = store;
    const { companyId } = router.params;

    const [state, dispatch] = useReducer<Reducer<ScheduleEditorListReducerState, ScheduleEditorListReducerAction>>(
        scheduleEditorListReducer,
        {
            modal: {
                active: 'none',
                data: {}
            }
        }
    );
    const { schedules, modal, holidays } = state;

    const fetchSchedules = async () => {
        const [getSchedulesResponse, getSchedulesError] = await handlePromise<{
            data: { count: number; data: AvailabilitySchedule[] };
        }>(Api.client.get(`company/${companyId}/availability-schedules`));

        if (getSchedulesError || !getSchedulesResponse) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setSchedules', payload: getSchedulesResponse.data.data });
    };

    const fetchSchedulesAndHolidays = async () => {
        await fetchSchedules();

        const [getHolidaysResponse, getHolidaysError] = await handlePromise<{
            data: { count: number; calendars_daysoff: CalendarDayoff[] };
        }>(Api.client.get(`calendars_daysoff`, { params: { company_id: companyId } }));

        if (getHolidaysError || !getHolidaysResponse) {
            // TODO: handle error
            return;
        }
        dispatch({ name: 'setHolidays', payload: getHolidaysResponse.data.calendars_daysoff });
    };

    useAsyncEffect(fetchSchedulesAndHolidays);

    const handleOpenModal = (modalName: string, modalData?: object) => {
        dispatch({ name: 'openModal', payload: modalName });

        if (modalData) {
            dispatch({ name: 'setModalData', payload: modalData });
        }
    };

    const handleCreateSchedule: (
        values: CreateScheduleForm,
        formikHelpers: FormikHelpers<CreateScheduleForm>
    ) => void = async ({ name, selectedHoliday }, formikHelpers) => {
        const newSchedule = {
            name,
            company_id: Number.parseInt(companyId, 10),
            holidays_id: selectedHoliday?.value?.id,
            monday: [{ startTime: '09:00', endTime: '17:00' }],
            tuesday: [{ startTime: '09:00', endTime: '17:00' }],
            wednesday: [{ startTime: '09:00', endTime: '17:00' }],
            thursday: [{ startTime: '09:00', endTime: '17:00' }],
            friday: [{ startTime: '09:00', endTime: '17:00' }],
            saturday: [{ startTime: '10:00', endTime: '16:00' }],
            sunday: [{ startTime: '10:00', endTime: '16:00' }]
        };

        const [createScheduleResponse, createScheduleError] = await handlePromise<{ data: AvailabilitySchedule }>(
            Api.client.post(`company/${companyId}/availability-schedules`, newSchedule)
        );

        if (createScheduleError || !createScheduleResponse) {
            // TODO: handle error
            return;
        }

        formikHelpers.resetForm();
        router.goTo(
            DefaultRoutes.ScheduleEditorEditSchedulePage,
            { companyId, scheduleId: createScheduleResponse.data.id },
            store
        );
    };

    const handleCopySchedule: (
        values: CopyScheduleForm,
        formikHelpers: FormikHelpers<CopyScheduleForm>
    ) => void = async ({ name, id }, formikHelpers) => {
        const [copyScheduleResponse, copyScheduleError] = await handlePromise<{ data: AvailabilitySchedule }>(
            Api.client.post(`company/${companyId}/availability-schedules/${id}/copy`, { name })
        );

        if (copyScheduleError || !copyScheduleResponse) {
            // TODO: handle error
            return;
        }

        formikHelpers.resetForm();
        router.goTo(
            DefaultRoutes.ScheduleEditorEditSchedulePage,
            { companyId, scheduleId: copyScheduleResponse.data.id },
            store
        );
    };

    const handleDeleteSchedule = async (selectedSchedule?: AvailabilitySchedule) => {
        if (!selectedSchedule) {
            return;
        }

        const [deleteScheduleResponse, deleteScheduleError] = await handlePromise<{ data: AvailabilitySchedule }>(
            Api.client.delete(`company/${companyId}/availability-schedules/${selectedSchedule.id}`)
        );

        if (deleteScheduleError || !deleteScheduleResponse) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'closeModal', payload: 'deleteSchedule' });

        await fetchSchedules();
    };

    return (
        <>
            <AppPage>
                <AppHeader title="Availability">
                    <div className="flex-spacer" />
                    {holidays && (
                        <ButtonPrimary onClick={() => handleOpenModal('createSchedule')}>Create Schedule</ButtonPrimary>
                    )}
                </AppHeader>
                <AppContent loading={!schedules} className={styles['schedule-editor-list-page-content']}>
                    {schedules && schedules?.length > 0 && (
                        <AppList>
                            {schedules?.map(schedule => {
                                const { id, name } = schedule;
                                const usedBy = schedule.usedBy ? schedule.usedBy.map(by => by.name) : [];

                                return (
                                    <AppListItem key={id}>
                                        <AppListItemIcon
                                            color={usedBy.length > 0 ? 'green' : 'light-gray'}
                                            name="schedule"
                                        />

                                        <AppListItemContent
                                            title={
                                                <ButtonUnstyled
                                                    as={buttonProps => (
                                                        <Link
                                                            {...buttonProps}
                                                            view={DefaultRoutes.ScheduleEditorEditSchedulePage}
                                                            params={{ companyId, scheduleId: id }}
                                                            store={store}
                                                        />
                                                    )}
                                                >
                                                    {name}
                                                </ButtonUnstyled>
                                            }
                                            description={`Apps: ${usedBy?.length > 0 ? usedBy.join(', ') : 'None'}`}
                                        />

                                        <ActionList position="end">
                                            <IconButton
                                                icon="pencil"
                                                as={buttonProps => (
                                                    <Link
                                                        {...buttonProps}
                                                        view={DefaultRoutes.ScheduleEditorEditSchedulePage}
                                                        params={{ companyId, scheduleId: id }}
                                                        store={store}
                                                    />
                                                )}
                                            />
                                            <IconButton
                                                icon="copy"
                                                onClick={() =>
                                                    handleOpenModal('copySchedule', { selectedSchedule: schedule })
                                                }
                                            />
                                            <IconButton
                                                icon="trash"
                                                disabled={usedBy.length > 0}
                                                onClick={() =>
                                                    handleOpenModal('deleteSchedule', { selectedSchedule: schedule })
                                                }
                                            />
                                        </ActionList>
                                    </AppListItem>
                                );
                            })}
                        </AppList>
                    )}

                    {schedules?.length === 0 && (
                        <div className={styles['schedule-editor-list-page-empty']}>
                            <Title className={styles['schedule-editor-list-page-empty-title']}>
                                Create your first schedule!
                            </Title>
                        </div>
                    )}
                </AppContent>
            </AppPage>
            <Modal
                isOpen={modal.active === 'createSchedule'}
                onAfterClose={() => dispatch({ name: 'closeModal' })}
                closeButton={false}
            >
                <ModalHeader title="New Schedule" />
                <Form
                    initialValues={{
                        name: '',
                        selectedHoliday: { label: 'Default (No Holidays)', value: {} }
                    }}
                    onSubmit={handleCreateSchedule}
                    validationSchema={CreateScheduleFormValidationSchema}
                >
                    {(formikProps: FormikProps<CreateScheduleForm>) => {
                        const options = holidays?.map(holiday => ({ label: holiday.title, value: holiday })) || [];
                        return (
                            <>
                                <InputText
                                    autoFocus={true}
                                    name="name"
                                    label="Schedule Name"
                                    formikProps={formikProps}
                                />
                                <InputSelect
                                    name="selectedHoliday"
                                    label="Select Base Holiday Calendar"
                                    formikProps={formikProps}
                                    // disableClearable={true}
                                    options={[{ label: 'Default (No Holidays)', value: null }, ...options]}
                                />
                                <ModalActions>
                                    <div className="flex-spacer" />
                                    <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                                    <ButtonPrimary type="submit">Create Schedule</ButtonPrimary>
                                </ModalActions>
                            </>
                        );
                    }}
                </Form>
            </Modal>

            <Modal
                isOpen={modal.active === 'copySchedule'}
                onAfterClose={() => dispatch({ name: 'setModalData', payload: {} })}
                closeButton={false}
            >
                <ModalHeader title={`Copy ${modal.data.selectedSchedule?.name}`} />
                <Form
                    initialValues={{
                        id: modal.data.selectedSchedule?.id || undefined,
                        name: `${modal.data.selectedSchedule?.name} - copy`
                    }}
                    // TODO: finish API for copying schedules
                    onSubmit={handleCopySchedule}
                    validationSchema={CreateScheduleFormValidationSchema}
                >
                    {(formikProps: FormikProps<CopyScheduleForm>) => {
                        return (
                            <>
                                <input type="hidden" defaultValue={formikProps.values.id} />
                                <InputText name="name" label="Schedule Name" formikProps={formikProps} />
                                <p className="text">Note: Externally linked calendars will not be copied.</p>
                                <ModalActions>
                                    <div className="flex-spacer" />
                                    <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                                    <ButtonPrimary type="submit">Copy Schedule</ButtonPrimary>
                                </ModalActions>
                            </>
                        );
                    }}
                </Form>
            </Modal>

            <Modal
                isOpen={modal.active === 'deleteSchedule'}
                onAfterClose={() => dispatch({ name: 'setModalData', payload: {} })}
                closeButton={false}
            >
                <ModalHeader title={`Delete Schedule`} />

                <p className="text">
                    Are you sure you want to delete{' '}
                    <strong className={styles['bold']}>{modal.data.selectedSchedule?.name}</strong>?
                </p>
                <ModalActions>
                    <div className="flex-spacer" />
                    <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                    <ButtonDanger onClick={() => handleDeleteSchedule(modal.data.selectedSchedule)}>
                        Delete Schedule
                    </ButtonDanger>
                </ModalActions>
            </Modal>
        </>
    );
});
