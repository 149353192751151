import { useAsyncEffect } from '@lambdacurry/component-library';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { AppListLoading } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import CompanyRoleList from '../CompanyRole/CompanyRoleList';
import { ApplicationSettings } from './ApplicationSettings';

export const ApplicationSettingsWrapper = observer(() => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const { store } = useStore();

    const { activeUser, publicRoles } = store;
    const hasApplicationSettingsPreview = activeUser?.previewFlags.includes('application-settings');
    const firstItemId = publicRoles.length > 0 ? publicRoles.getFirstItemId() : 0;

    const useBetaWidget =
        (publicRoles.getItem(firstItemId)?.beta_widget_enabled ?? true) && hasApplicationSettingsPreview;

    const fetchData = async () => {
        await store.fetchPublicRoles();
        setLoaded(true);
    };

    useAsyncEffect(fetchData, undefined, []);

    if (!loaded) {
        return <AppListLoading title="Loading setttings" />;
    }

    return useBetaWidget ? <ApplicationSettings /> : <CompanyRoleList />;
});
