import React, { FC, useState } from 'react';
import { AppHeader, AppPage, AppSection, useSnackbar } from '../../components-v2/shared';
import { Filters } from '../Filters/Filters.helpers';
import { FormikHelpers, FormikProps } from 'formik';
import { Form, ButtonPrimary, handlePromise, InputText } from '@lambdacurry/component-library';
import { DateTime } from 'luxon';
import useStore from '../../store/useStore';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import Modal from 'react-modal';

interface EmailSearchForm {
    recipient_email?: string;
}

export const EmailViewer: FC<{
    filters?: Filters;
}> = () => {
    const [searchResults, setSearchResults] = useState<any[] | undefined>(undefined);
    const { store } = useStore();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedEmail, setSelectedEmail] = useState<string>();
    const { Api } = store;

    const { addSnackbar } = useSnackbar();

    const fetchSearchResults = async (values: EmailSearchForm) => {
        const [response, error] = await handlePromise(Api.client.post(`emails/search`, values));

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to fetch contacts.', { variant: 'error' });
            return;
        }

        if (response.data.length === 0) {
            addSnackbar('Query returned zero results.', { variant: 'warning' });
            return;
        }

        setSearchResults(response.data);
    }

    const handleSubmit = async (values: EmailSearchForm, formikHelpers: FormikHelpers<EmailSearchForm>) => {
        formikHelpers.setSubmitting(true);
        await fetchSearchResults(values);
        formikHelpers.setSubmitting(false);
    };

    const onModalClose = () => {
        setShowModal(false);
        setSelectedEmail(undefined);
    };

    return (
        <AppPage>
            <Form className="form-holder-block" initialValues={{ recipient_email: undefined }} onSubmit={handleSubmit}>
                {(formikProps: FormikProps<EmailSearchForm>) => (
                    searchResults ? (
                        <><AppHeader title={`Email Search Results for ${formikProps.values.recipient_email}`} onBack={() => setSearchResults(undefined)}></AppHeader>
                            <AppSection>
                                <ReactDataGrid
                                    dataSource={searchResults || []}
                                    idProperty="mail_guid"
                                    preventRowSelectionOnClickWithMouseMove={true}
                                    onRowClick={(props) => {
                                        setSelectedEmail(`${props.data.sent_via === 'emailhub' ? `https://storage.cloud.google.com/xperience-emails/${props.data.sender_guid}` : `${process.env.REACT_APP_API_SERVER_URL}/local-emails`}/${props.data.mail_guid}.${props.data.format}`);
                                        setShowModal(true);
                                    }}
                                    columns={[
                                        { name: 'sender', header: 'Sender', defaultFlex: 1 },
                                        { name: 'subject', header: 'Subject', minWidth: 200, defaultFlex: 2 },
                                        { name: 'sender_guid', visible: false },
                                        { name: 'sent_via', visible: false },
                                        { name: 'sent_at', header: 'Sent At', minWidth: 120, defaultFlex: 1, render: ({ value }) => value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT) : '' },
                                    ]}
                                    loading={false}
                                    style={{ minHeight: 683 }}
                                    pagination='local'
                                    defaultLimit={15}
                                />
                            </AppSection>
                        </>
                    ) :
                        (
                            <>
                                <AppHeader title="Email Viewer"></AppHeader>
                                <AppSection>
                                    <InputText style={{ width: 400 }} formikProps={formikProps} name='recipient_email' label='Recipient Email Address' />
                                    <ButtonPrimary disabled={formikProps.isSubmitting} className='filter-submit' type="submit">{formikProps.isSubmitting ? 'Searching...' : 'Search'}</ButtonPrimary>
                                </AppSection >
                                <AppSection><p>This tool will display the past 10 days of emails sent to the selected address including CC'd messages.
                                    Links inside the emails are LIVE so clicking on an Unsubscribe link *will* unsubscribe the recipient. Also,
                                    clicking any links, such as Call To Action links, will result in those link counter being incremented and might
                                    skew reporting data for campaign effectiveness. This tool does not include attachments.</p>
                                    <p><strong>Use with caution.</strong></p>
                                </AppSection>
                            </>
                        )
                )}
            </Form>
            <Modal
                isOpen={showModal}
                onRequestClose={onModalClose}
                style={{}}
                contentLabel="Example Modal"
            ><div style={{ marginBottom: 12 }}><button onClick={onModalClose}>Close</button></div>
                {(selectedEmail) && (
                    <div style={{ width: '80%', height: '95%' }}><embed style={{ width: '100%', height: '100%' }} src={`${selectedEmail}`} /></div>
                )}
            </Modal>
        </AppPage>
    );
};
