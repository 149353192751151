import React, { FC, Reducer, useEffect, useMemo, useReducer } from 'react';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import {
    DateRangePreset,
    Filters,
    FiltersReducer,
    FiltersReducerAction,
    FiltersState,
    hasActiveFilter,
    InitialFilters,
    getInitialFiltersState,
    getFiltersFromQueryParams,
    selectFiltersDateStartFromContext,
    FilterQueryParams,
    FilterQueryParamsSetup,
    setQueryParamsFromFilters
} from './Filters.helpers';
import { FormikProps, useFormik } from 'formik';
import { formSchemas } from '../../util/validations/formSchemas';
import useStore from '../../store/useStore';
import { Button, Icon, IconButton, InputDate, InputSelect, useDrawer } from '@lambdacurry/component-library';
import { FilterDrawerName, FilterDrawer, FilterDrawerData } from './FilterDrawer/FilterDrawer';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useQueryParams } from 'use-query-params';

import './filters.scss';

export const useFilters = () => {
    const {
        drawerState,
        drawerActions: { getDrawerData }
    } = useDrawer();

    const filters = useMemo(() => {
        return getDrawerData<FilterDrawerData>(FilterDrawerName).filters;
    }, [drawerState.data[FilterDrawerName]?.filters]);

    const setFilters = useMemo(() => {
        return getDrawerData<FilterDrawerData>(FilterDrawerName).setFilters;
    }, [drawerState.data[FilterDrawerName]?.setFilters]);

    return { filters, setFilters };
};

interface FiltersProps {
    initialFilters?: Partial<Filters>;
    showDateRange?: boolean;
}

export const FiltersSelector: FC<FiltersProps> = observer(({ initialFilters, showDateRange = true }) => {
    const { store, agencyStore } = useStore();
    const { router, activeCompanyId, activeCompany, accessibleCompanies } = store;
    const { activeAgencyId, activeAgency } = agencyStore;
    const { params } = router;

    const [query, setQuery] = useQueryParams(FilterQueryParamsSetup);

    const {
        drawerActions: { toggleDrawer, getDrawerData, setDrawerData }
    } = useDrawer();

    const initialFiltersState = getInitialFiltersState(
        getFiltersFromQueryParams(query as FilterQueryParams, initialFilters)
    );

    if (params.agencyId) {
        initialFiltersState.filters.agency_ids = [parseInt(params.agencyId, 10)];
    }
    if (params.company_ids) {
        initialFiltersState.filters.company_ids = [parseInt(params.companyId, 10)];
    }

    const [state, dispatch] = useReducer<Reducer<FiltersState, FiltersReducerAction>>(FiltersReducer, {
        ...initialFiltersState
    });

    const filters = {
        ...state.filters,
        agency_ids: params.agencyId ? [parseInt(params.agencyId, 10)] : state.filters.agency_ids,
        company_ids: params.companyId ? [parseInt(params.companyId, 10)] : state.filters.company_ids
    };

    useEffect(() => {
        dispatch({ name: 'setFiltersContext', payload: params });
        dispatch({ name: 'setFilters', payload: filters });
    }, [activeAgencyId, activeCompanyId]);

    useEffect(() => {
        setDrawerData({
            name: FilterDrawerName,
            data: { filters, setFilters: (filters: Filters) => dispatch({ name: 'setFilters', payload: filters }) }
        });
        setQuery(setQueryParamsFromFilters(filters), 'replaceIn');
    }, [state.filters]);

    // Clear filters whenever the context, activeCompanyId, or activeAgencyId changes.
    useEffect(() => {
        if (!isEqual(state.filters, initialFiltersState.filters)) {
            dispatch({ name: 'setFilters', payload: InitialFilters });
        }
        if (!isEqual(state.filters.dateRange, initialFiltersState.filters.dateRange)) {
            dispatch({ name: 'setDateRange', payload: { dateRange: InitialFilters.dateRange } });
        }
    }, [activeCompanyId, activeAgencyId]);

    const customDateRange = useMemo(
        () => ({
            minDate: selectFiltersDateStartFromContext(state.context, activeAgency, activeCompany, accessibleCompanies),
            maxDate: DateTime.local().toJSDate()
        }),
        [state.context]
    );

    const dateRangeFormikProps: FormikProps<{ start?: Date; end?: Date }> = useFormik({
        enableReinitialize: true,
        validationSchema: formSchemas.dateRange,
        initialValues: {
            start: filters.dateRange.custom?.start,
            end: filters.dateRange.custom?.end
        },
        onSubmit: () => {
            // We do not need to do anything onSubmit since we are listening to changes on the dateRange values to set
            // the filter data in a `useEffect` below.
        }
    });

    useEffect(() => {
        const { start, end } = dateRangeFormikProps.values;
        if (filters.dateRange.preset !== DateRangePreset.CUSTOM || !filters.dateRange.custom) {
            return;
        }
        if (start && end && (filters.dateRange.custom.start !== start || filters.dateRange.custom.end !== end)) {
            if (!DateTime.fromJSDate(start).isValid || !DateTime.fromJSDate(end).isValid) {
                return;
            }
            const newStart = DateTime.fromJSDate(start)
                .setZone(filters.dateRange.timezone)
                .startOf('day')
                .toJSDate();
            const newEnd = DateTime.fromJSDate(end)
                .setZone(filters.dateRange.timezone)
                .endOf('day')
                .toJSDate();

            dispatch({
                name: 'setDateRange',
                payload: { dateRangePreset: DateRangePreset.CUSTOM, start: newStart, end: newEnd }
            });

            dateRangeFormikProps.validateForm(dateRangeFormikProps.values).then(errors => {
                dateRangeFormikProps.setErrors(errors);
                dateRangeFormikProps.setFieldTouched('start', true, true);
                dateRangeFormikProps.setFieldTouched('end', true, true);
            });
        }
    }, [dateRangeFormikProps.values]);

    return (
        <div className="filters">
            {getDrawerData<FilterDrawerData>(FilterDrawerName).showFiltersButton && (
                <IconButton
                    icon="filter"
                    className={classNames(
                        'filters-icon-button',
                        { active: hasActiveFilter(filters, state.context) },
                        'clx-display-inline-flex lg:clx-display-none'
                    )}
                    onClick={() => toggleDrawer({ name: FilterDrawerName })}
                />
            )}

            {showDateRange && (
                <>
                    {(filters.dateRange.preset === DateRangePreset.CUSTOM || !filters.dateRange.preset) && (
                        <div className="filters-daterange-inputs">
                            <InputDate
                                name="start"
                                label="Begin Date"
                                labelPlacement="above"
                                datePickerProps={{
                                    ...customDateRange
                                }}
                                formikProps={dateRangeFormikProps}
                            />
                            <InputDate
                                name="end"
                                label="End Date"
                                labelPlacement="above"
                                datePickerProps={{
                                    ...customDateRange
                                }}
                                formikProps={dateRangeFormikProps}
                            />
                        </div>
                    )}
                    <InputSelect
                        className="filters-daterange-selector"
                        name="date_range"
                        value={filters.dateRange.preset || DateRangePreset.CUSTOM}
                        optionValueKey="value"
                        options={[
                            { label: 'Last 7 Days', value: DateRangePreset.LAST7 },
                            { label: 'Last 30 Days', value: DateRangePreset.LAST30 },
                            { label: 'Last 90 Days', value: DateRangePreset.LAST90 },
                            { label: 'Current Week', value: DateRangePreset.CURRENT_WEEK },
                            { label: 'Current Month', value: DateRangePreset.CURRENT_MONTH },
                            { label: 'Current Quarter', value: DateRangePreset.CURRENT_QUARTER },
                            { label: 'Current Year', value: DateRangePreset.YTD },
                            { label: 'Last Week', value: DateRangePreset.PREVIOUS_WEEK },
                            { label: 'Last Month', value: DateRangePreset.PREVIOUS_MONTH },
                            { label: 'Last Quarter', value: DateRangePreset.PREVIOUS_QUARTER },
                            { label: 'Last Year', value: DateRangePreset.PREVIOUS_YEAR },
                            { label: 'Custom Range', value: DateRangePreset.CUSTOM }
                        ]}
                        onChange={(event, dateRangePreset) =>
                            dispatch({ name: 'setDateRange', payload: { dateRangePreset } })
                        }
                    />
                </>
            )}

            {getDrawerData<FilterDrawerData>(FilterDrawerName).showFiltersButton && (
                <Button
                    className={classNames(
                        'filters-button',
                        { active: hasActiveFilter(filters, state.context) },
                        'clx-display-none lg:clx-display-inline-flex'
                    )}
                    icon={<Icon name="filter" />}
                    onClick={() => toggleDrawer({ name: FilterDrawerName })}
                >
                    More Filters
                </Button>
            )}

            <FilterDrawer
                filters={filters}
                context={state.context}
                onSave={filters => dispatch({ name: 'setFilters', payload: filters })}
            />
        </div>
    );
});
