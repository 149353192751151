import React, { FunctionComponent, useReducer, useState } from 'react';
import { get as _get } from 'lodash';
import { Wysiwyg, InputSwitch, useSnackbar } from '../../../components-v2/shared';
import { handlePromise, InputCheckbox, InputText, useAsyncEffect, PaginationPrimary } from '@lambdacurry/component-library';
import styles from '../email-editor.module.scss';
import useStore from '../../../store/useStore';
import {
    RealyncReducer,
    RealyncVideo,
    RealyncVideosResponse
} from '../../Company/CompanyIntegrations/RealyncIntegration/RealyncIntegration.helpers';
import { EditAppEmailFormValues, EmailSectionValues, VideoValuePack } from '../EmailEditor.types';
import { FormikProps } from 'formik';
import { EditEmailSectionProps } from './EditEmailSectionForm';

export const EditEmailVideoSectionForm: FunctionComponent<EditEmailSectionProps> = ({
    section,
    formikProps,
    response
}) => {
    const [initialLoading, setInitialLoading] = useState(true);
    const { store } = useStore();
    const [vidsSelected, toggleVidsSelected] = useState(false);
    const { router, api, isCompanyRole } = store;
    const { params } = router;
    const [state, dispatch] = useReducer(RealyncReducer, { properties: [] });
    const { addSnackbar } = useSnackbar();

    useAsyncEffect(async () => {
        const [response, error] = await handlePromise(api.client.get(`/realync/company/${params.companyId}/settings`));
        if (!response?.data || error) {
            setInitialLoading(false);

            if (error?.status === 404 || error.toString().includes('404')) {
                return;
            }
            return;
        }

        dispatch({ name: 'setRealync', payload: response.data });

        const [videosResponse, videosError] = await handlePromise<{ data: RealyncVideosResponse }>(
            api.client.get<RealyncVideosResponse>(
                `/realync/company/${params.companyId}/property/${response.data.realync_property_id}/videos`
            )
        );

        if (!videosResponse?.data || videosError) {
            setInitialLoading(false);

            if (videosError?.status === 404 || videosError.toString().includes('404')) {
                return;
            }
            return;
        }

        toggleVidsSelected(
            ((formikProps.values['realync-video-section'] as EmailSectionValues).videos || []).some(
                (vals: VideoValuePack) => {
                    return vals?.selected;
                }
            )
        );

        dispatch({ name: 'setVideos', payload: videosResponse.data });
        setInitialLoading(false);
    });

    if (initialLoading) {
        return <>Loading...</>;
    }

    const isVideoSelected = (formikProps: FormikProps<EditAppEmailFormValues>, i: number): boolean => {
        const vids = (formikProps.values['realync-video-section'] as EmailSectionValues).videos || [];

        return vids && vids[i] && vids[i].selected;
    };

    const handleCheckbox = (formikProps: FormikProps<EditAppEmailFormValues>, video: RealyncVideo, i: number) => {
        const wasPreviouslySelected = isVideoSelected(formikProps, i);
        formikProps.setFieldValue(`realync-video-section.videos[${i}].selected`, !wasPreviouslySelected);
        formikProps.setFieldValue(`realync-video-section.videos[${i}].imageUrl`, video.videoImageURL);
        formikProps.setFieldValue(`realync-video-section.videos[${i}].videoId`, video.id);

        // Are all videos unchecked? If so, toggle the main switch to off
        const noVideosSelected =
            (formikProps.values['realync-video-section'].videos as Array<{
                videoId: string;
                selected: boolean;
            }>).filter(vid => {
                return video?.id === vid?.videoId ? !wasPreviouslySelected : vid?.selected;
            }).length === 0;
        if (noVideosSelected) {
            formikProps.setFieldValue(`${section.id}.includeVideo`, false);
        }
        toggleVidsSelected(!noVideosSelected || !wasPreviouslySelected);
    };

    const handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void = async (_, page) => {
        const [videosResponse, videosError] = await handlePromise<{ data: RealyncVideosResponse }>(
            api.client.get<RealyncVideosResponse>(
                `/realync/company/${params.companyId}/property/${state?.realync?.realync_property_id}/videos`,
                {
                    params: {
                        page
                    }
                }
            )
        );
        if (!videosResponse?.data || videosError) {
            addSnackbar('Failed to fetch Realync videos.', { variant: 'error' });
            return;
        }

        dispatch({ name: 'setVideos', payload: videosResponse.data });
    };

    const canEditSection = !isCompanyRole;

    return (
        <>
            <div hidden={canEditSection}>
                <p>This section is read only. Your role does not have rights to modify video content.</p>
            </div>
            <InputSwitch
                label="Include Realync Video"
                name={`${section.id}.includeVideo`}
                formikProps={formikProps}
                disabled={!canEditSection || !vidsSelected}
            />
            {state?.videos?.records.map((video: RealyncVideo, i: number) => (
                <div key={i} >
                    <div className={styles['email-editor-video-grid']} id={`rlVideoRow-${video.id}`}>
                        <InputText
                            hidden={true}
                            name={`${section.id}.videos[${i}].imageUrl`}
                            value={video.videoImageURL}
                            formikProps={formikProps}
                            disabled={!canEditSection}
                        />
                        <InputText
                            hidden={true}
                            name={`${section.id}.videos[${i}].videoId`}
                            value={video.id}
                            formikProps={formikProps}
                            disabled={!canEditSection}
                        />
                        <div>
                            <InputCheckbox
                                label={video.title}
                                key={video.id}
                                checked={isVideoSelected(formikProps, i)}
                                onClick={() => handleCheckbox(formikProps, video, i)}
                                name={`${section.id}.videos[${i}].selected`}
                                disabled={!canEditSection}
                            />
                            <img src={video.videoImageURL} />
                        </div>
                    </div>
                    <div hidden={!isVideoSelected(formikProps, i)}>
                        <Wysiwyg
                            templateVariables={response.templateVariables}
                            name={`${section.id}.videos[${i}].htmlContent`}
                            formikProps={formikProps}
                            readOnly={!canEditSection}
                        />
                    </div>
                </div>
            ))}
            <div className="realync__videos-list__pagination">
                {(state?.videos?.count || 0) > 20 && (
                    <PaginationPrimary
                        pagesCount={state?.videos?.pages || 1}
                        page={state?.videos?.currentPage}
                        onChange={handlePageChange}
                    />
                )}
            </div>
        </>
    );
};
