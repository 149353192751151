import React, { FC, memo } from 'react';
import { ClarityReducerState } from './Clarity.helpers';
import { ClarityDashboardChartCard } from './ClarityDashboardChartCard';
import { Sortable } from '../../components-v2/shared/dnd/Sortable';
import { isEqual } from 'lodash';
import { useFilters } from '../Filters/Filters';
import { ClarityChartSize } from '../../constants';

interface ClarityDashboardChartsProps {
    state: ClarityReducerState;
    onDragEnd?: (arr: any[]) => void;
}

export const ClarityDashboardCharts: FC<ClarityDashboardChartsProps> = memo(
    ({ state, onDragEnd }) => {
        const { preferences } = state;
        const { filters } = useFilters();

        if (!preferences?.config) {
            return null;
        }

        return (
            <Sortable
                getClassNames={value =>
                    ['line', 'bar', 'combo', 'bubble'].includes(value.chartType) || value.size === ClarityChartSize.full
                        ? 'clarity-dashboard-chart-grid-item-full-width'
                        : ''
                }
                useDragOverlay={false}
                items={preferences.config.charts}
                handle={true}
                Container={(props: any) => <div className="clarity-dashboard-chart-grid" {...props} />}
                renderItem={(props: any) => (
                    <ClarityDashboardChartCard
                        key={props.value.id}
                        filters={filters}
                        displayMode={preferences.config.displayMode}
                        {...props}
                    />
                )}
                onDragEnd={onDragEnd}
            />
        );
    },
    (prev: ClarityDashboardChartsProps, next: ClarityDashboardChartsProps) =>
        isEqual(prev.state.preferences, next.state.preferences)
);
