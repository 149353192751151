import React, { FC, useEffect } from 'react';
import { ButtonOutline, ButtonPrimary, Drawer, Form, InputSelect, useDrawer } from '@lambdacurry/component-library';
import { observer } from 'mobx-react';
import { AppSectionHeader } from '../../../components-v2/shared';
import './settings-drawer.scss';
import { ClarityUserPreferences } from '../Clarity.types';

export const SettingsDrawerName = 'SettingsDrawer';

export interface SettingsDrawerData {
    showSettingsButton: boolean;
}

export const SettingsDrawer: FC<{
    preferences?: ClarityUserPreferences;
    onSave: (preferences: Partial<ClarityUserPreferences>) => void;
}> = observer(({ preferences, onSave }) => {
    const {
        drawerActions: { toggleDrawer, setDrawerData }
    } = useDrawer();

    useEffect(() => {
        const showSettingsButton = true;

        setDrawerData({ name: 'settings-drawer', data: { showSettingsButton } });
    }, []);

    return (
        <Drawer className="settings-drawer" name={SettingsDrawerName} options={{ width: 360 }}>
            {preferences && (
                <Form
                    className="settings-drawer-form"
                    initialValues={preferences}
                    onSubmit={values => {
                        onSave(values);
                        toggleDrawer({ name: SettingsDrawerName });
                    }}
                >
                    {formikProps => (
                        <>
                            <AppSectionHeader title="Settings" />
                            <InputSelect
                                label="Leads Per Application Boxes"
                                name="settings.leads_per_application"
                                options={[
                                    { id: 'SG', name: 'Schedule Genie' },
                                    { id: 'CM', name: 'Spotlight' },
                                    { id: 'IC', name: 'Income Calculator' },
                                    { id: 'BPN', name: 'Best Price Now' },
                                    { id: 'CH', name: 'Chat' },
                                    { id: 'TU', name: 'Text Us Now' }
                                ]}
                                optionValueKey="id"
                                optionLabelKey="name"
                                formikProps={formikProps}
                                autocompleteConfig={{ multiple: true, disableClearable: false }}
                                inputProps={{ autoComplete: 'disable' }}
                            />
                            <div className="settings-drawer-actions">
                                <ButtonOutline
                                    onClick={() => {
                                        toggleDrawer({ name: SettingsDrawerName });
                                    }}
                                >
                                    Cancel
                                </ButtonOutline>
                                <ButtonPrimary type="submit">Apply Settings</ButtonPrimary>
                            </div>
                        </>
                    )}
                </Form>
            )}
        </Drawer>
    );
});
