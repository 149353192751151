import { DateTime } from 'luxon';
import * as Yup from 'yup';

import { ClarityChartConfig } from '../../../constants';
import { DateRangePreset, Filters } from '../../Filters/Filters.helpers';
import { ClarityScheduleInterval } from '../Clarity.types';

export const unixToLocal = (unixTime: number | null, dateOnly: boolean = false): string => {
    if (!unixTime) {
        return '(Ongoing)';
    }

    const fmt = dateOnly ? DateTime.DATE_SHORT : DateTime.DATETIME_SHORT;
    return `${DateTime.fromSeconds(unixTime).toLocaleString(fmt)}`;
};

export const unixToWeekday = (unixTime: number | null): string => {
    if (!unixTime) {
        return 'unknown';
    }

    return DateTime.fromSeconds(unixTime).toFormat('cccc');
};

// Array of chart id's
export const recommendedChartIds: string[] = [
    'sessions_with_interactions',
    'leads_per_source_first_touch',
    'leads_per_module_type',
    'leads_per_module_type_trend',
    'leads_per_city',
    'conversion_rate_by_day_of_week'
];

export const getScheduledReportDescription = (
    interval: ClarityScheduleInterval,
    startDate: number,
    endDate: number | null
): string => {
    const intervalLabel = interval === 'day' ? 'daily' : interval === 'week' ? 'weekly' : 'monthly';
    const endDateLabel = `${endDate ? 'to' : ''} ${unixToLocal(endDate, true)}`;
    const dateRange = `${unixToLocal(startDate)} ${endDateLabel}`;
    const dayLabel = interval !== 'month' ? `on ${unixToWeekday(startDate)},` : '';

    return `Delivered ${intervalLabel} ${dayLabel} from ${dateRange}`;
};

export interface ClaritySchedulerForm {
    id: number;
    name: string;
    series_start_date: Date;
    series_start_time: number;
    end_series_toggle: boolean;
    series_end?: Date;
    recipients: string;
    interval: ClarityScheduleInterval;
    available_charts: Array<{ chart: ClarityChartConfig; enabled: boolean }>;
    filters: Filters;
}

export const initialScheduleReportState: ClaritySchedulerForm = {
    available_charts: [],
    filters: {
        agency_ids: [],
        business_category_ids: [],
        company_ids: [],
        dmas: [],
        include_test_companies: false,
        only_active_companies: false,
        ownership_group_ids: [],
        property_management_company_ids: [],
        region_ids: [],
        dateRange: {
            preset: DateRangePreset.LAST30,
            timezone: DateTime.local().zoneName
        }
    },
    id: 0,
    name: '',
    interval: 'week',
    end_series_toggle: false,
    recipients: '',
    series_start_date: DateTime.local()
        .startOf('day')
        .toJSDate(),
    series_start_time: 0
};

export type ClarityChartObj = {
    chart: ClarityChartConfig;
    enabled: boolean;
};

export const SchedulerFormValidationSchema = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return Yup.object().shape({
        name: Yup.string().required('Field Required'),
        series_start_date: Yup.date()
            .required('Field Required'),
        series_end: Yup.date()
            .notRequired()
            .when('end_series_toggle', {
                is: true,
                then: Yup.date()
                    .required('Field Required')
                    .min(today, 'Date cannot be in the past.')
            }),
        recipients: Yup.array()
            .transform((_, originalValue) => originalValue.split(/\s*,\s*/))
            .of(Yup.string().email('One of the email addresses in the list is invalid.'))
            .required('Field Required')
    });
};

export const SchedulerFormEditValidationSchema = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return Yup.object().shape({
        name: Yup.string().required('Field Required'),
        series_start_date: Yup.date().required('Field Required'),
        series_end: Yup.date()
            .notRequired()
            .when('end_series_toggle', {
                is: true,
                then: Yup.date()
                    .required('Field Required')
                    .min(today, 'Date cannot be in the past.')
            }),
        recipients: Yup.array()
            .transform((_, originalValue) => originalValue.split(/\s*,\s*/))
            .of(Yup.string().email('One of the email addresses in the list is invalid.'))
            .required('Field Required')
    });
};

export const reportTimeOptions = [
    { id: 0, name: '12 AM' },
    { id: 1, name: '1 AM' },
    { id: 2, name: '2 AM' },
    { id: 3, name: '3 AM' },
    { id: 4, name: '4 AM' },
    { id: 5, name: '5 AM' },
    { id: 6, name: '6 AM' },
    { id: 7, name: '7 AM' },
    { id: 8, name: '8 AM' },
    { id: 9, name: '9 AM' },
    { id: 10, name: '10 AM' },
    { id: 11, name: '11 AM' },
    { id: 12, name: '12 PM' },
    { id: 13, name: '1 PM' },
    { id: 14, name: '2 PM' },
    { id: 15, name: '3 PM' },
    { id: 16, name: '4 PM' },
    { id: 17, name: '5 PM' },
    { id: 18, name: '6 PM' },
    { id: 19, name: '7 PM' },
    { id: 20, name: '8 PM' },
    { id: 21, name: '9 PM' },
    { id: 22, name: '10 PM' },
    { id: 23, name: '11 PM' }
];

export const reportIntervalOptions = [
    { id: 'day', name: 'Daily' },
    { id: 'week', name: 'Weekly' },
    { id: 'month', name: 'Monthly' }
];

export const endDateOptions = [
    { id: false, name: 'Ongoing' },
    { id: true, name: 'Select specific end date' }
];

export const chartDateRangeOptions = [
    { label: 'Last 7 Days', value: DateRangePreset.LAST7 },
    { label: 'Last 30 Days', value: DateRangePreset.LAST30 },
    { label: 'Last 90 Days', value: DateRangePreset.LAST90 },
    { label: 'Current Week', value: DateRangePreset.CURRENT_WEEK },
    { label: 'Current Month', value: DateRangePreset.CURRENT_MONTH },
    { label: 'Current Quarter', value: DateRangePreset.CURRENT_QUARTER },
    { label: 'Current Year', value: DateRangePreset.YTD },
    { label: 'Last Week', value: DateRangePreset.PREVIOUS_WEEK },
    { label: 'Last Month', value: DateRangePreset.PREVIOUS_MONTH },
    { label: 'Last Quarter', value: DateRangePreset.PREVIOUS_QUARTER },
    { label: 'Last Year', value: DateRangePreset.PREVIOUS_YEAR }
];
