import { IconButton, InputRadioGroup, InputSelect } from '@lambdacurry/component-library';
import classNames from 'classnames';
import React, { FC, Fragment } from 'react';
import { AppCard, AppSectionHeader } from '../../../../components-v2/shared';
import {
    labels,
    positioningInputs,
    SectionProps,
    widgetAnimationOptions,
    widgetPositionOptions
} from '../../ApplicationSettings.helpers';
import './position-section.scss';

export const PositionSection: FC<SectionProps> = ({ formikProps, formIsDisabled }) => {
    const resetAdvancedPositions = (field: string) => {
        const isMobile = field === 'widget_position_mobile';
        const indexRange = isMobile ? [4, 8] : [0, 4];

        positioningInputs.slice(...indexRange).forEach(({ name }) => {
            formikProps.setFieldValue(`widget_adjustments.${name}`, 0);
        });
    };

    return (
        <AppCard className="position-section">
            {['widget_position', 'widget_position_mobile'].map(field => (
                <Fragment key={field}>
                    <AppSectionHeader title={labels[field]} />
                    <InputRadioGroup formikProps={formikProps} name={field} row value={formikProps.values[field]}>
                        {widgetPositionOptions.map(({ icon, value }) => (
                            <IconButton
                                key={`${field}-${value}`}
                                className={classNames('position-button', {
                                    active: formikProps.values[field] === value
                                })}
                                icon={icon}
                                onClick={() => {
                                    formikProps.setFieldValue(field, value);
                                    resetAdvancedPositions(field);
                                }}
                                disabled={formIsDisabled}
                            />
                        ))}
                    </InputRadioGroup>
                </Fragment>
            ))}

            <AppSectionHeader title="Animation" />
            <InputSelect
                label="Style"
                name="widget_animation_style"
                options={widgetAnimationOptions}
                formikProps={formikProps}
                disabled={formIsDisabled}
                optionValueKey="value"
            />
        </AppCard>
    );
};
