import React, { FC, useEffect } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputSelect,
    ButtonOutlinePrimary,
    InputSwitch
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
import { RuleAction } from '../../../../types';
export interface EnquireFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface EnquireCrm extends Crm {
    configuration: {
        exact_property_name: string;
        activity_description: string;
        activity_id: string;
        is_activity_enabled: boolean;
    };
}

const ruleActions: Array<RuleAction> = [];

export const EnquireForm: FC<EnquireFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<EnquireCrm>) => {
        await onValidate(formikProps.values, crmConfigTypes.enquire);
    };

    const handleSubmit = async (values: EnquireCrm, actions: FormikHelpers<EnquireCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.enquire);
    };

    const { fetchEnquireProperties, enquireProperties } = store;

    useEffect(() => {
        fetchEnquireProperties();
    }, []);

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<EnquireCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Enquire" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader
                                    title="Pre-Defined Property Names"
                                    subtitle="If the Exact Property Name select list is empty, you might need to verify that you have a valid Enquire API Portal ID in your company settings. This might require assistance from TCC support."
                                />

                                <InputSelect
                                    label="Exact Property Name"
                                    name="configuration.exact_property_name"
                                    options={enquireProperties || []}
                                    optionValueKey="exactPropertyName"
                                    optionLabelKey="name"
                                    disabled={!enquireProperties}
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader title="Activity Options" />
                                <InputText
                                    name="configuration.activity_id"
                                    label="Enquire Activity ID"
                                    helperText="Enquire Activity ID"
                                    formikProps={formikProps}
                                    required={false}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.activity_description"
                                    label="Enquire Activity Description"
                                    helperText="Enquire Activity Description"
                                    required={false}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputSwitch
                                    name='configuration.is_activity_enabled'
                                    label="Enable Activity"
                                    labelPlacement="start"
                                    formikProps={formikProps}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
