import React, { FunctionComponent } from 'react';
import { ButtonOutlinePrimary, useSnackbar } from '../../../components-v2/shared';
import styles from '../email-editor.module.scss';

import { handlePromise } from '../../../util/async';
import { observer } from 'mobx-react';
import useStore from '../../../store/useStore';
import { EditAppEmailReducerAction, ServerRequestStatus } from '../EmailEditor.helpers';

export const EmailEditorSendTestButton: FunctionComponent<{
    dispatch: (action: EditAppEmailReducerAction) => void;
    testEmailStatus: ServerRequestStatus;
    disabled: boolean;
}> = observer(({ dispatch, testEmailStatus, disabled }) => {
    const { store } = useStore();
    const { router, Api, activeUser } = store;
    const { companyId, appEmailId } = router.params;
    const { addSnackbar } = useSnackbar();

    const handleSendTest = async () => {
        dispatch({ name: 'updateTestEmailStatus', payload: 'sending' });

        const [, error] = await handlePromise(
            Api.client.post(`mail-templates/company/${companyId}/template/${appEmailId}/testEmail`)
        );

        if (error) {
            dispatch({ name: 'updateTestEmailStatus', payload: 'error' });
            return;
        }

        dispatch({ name: 'updateTestEmailStatus', payload: 'sent' });
        addSnackbar(`Test email was sent to ${activeUser?.email}.`, { variant: 'success' });
        setTimeout(() => dispatch({ name: 'updateTestEmailStatus', payload: 'waiting' }), 5000);
    };

    const buttonText = {
        waiting: 'Send Test',
        sending: 'Sending...',
        sent: 'Sent!',
        error: 'Try Again'
    };

    return (
        <div className={styles['email-editor-edit-app-test']}>
            <ButtonOutlinePrimary
                className={styles['email-editor-edit-app-test-button']}
                onClick={handleSendTest}
                disabled={testEmailStatus === 'sending' || testEmailStatus === 'sent' || disabled}
            >
                {buttonText[testEmailStatus]}
            </ButtonOutlinePrimary>
            <span className={styles['email-editor-edit-app-test-hint']}>
                Test email will send to {activeUser?.email}.
            </span>
        </div>
    );
});
