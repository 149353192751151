import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Illustration } from './OnboardingIllustration2.svg';
import * as Yup from 'yup';
import styles from './company-onboarding.module.scss';
import './company-onboarding.global.scss';
import { ButtonPrimary, Form, InputSelect, InputText, StepChecklist, Title } from '../../components-v2/shared';
import { Company } from '../../types';
import { usStates } from '../../constants/us-states';
import { timeZoneList } from '../../constants';
import { observer } from 'mobx-react';
import useStore from '../../store/useStore';
import { merge } from 'lodash';
import { FormikHelpers } from 'formik';
import { handlePromise } from '../../util/async';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { urlRegExp } from '../../util/url';
import { useAsyncEffect } from '@lambdacurry/component-library';
import { AgencyLogo } from '../Agency/AgencyLogo';

const companyOnboardingCompanyDetailsFormSchema = Yup.object().shape({
    company: Yup.object().shape({
        name: Yup.string().required('Please enter the company name.'),
        address1: Yup.string().required('Please enter an address.'),
        city: Yup.string().required('Please enter a city.'),
        state: Yup.string().required('Please select a state.'),
        zipcode: Yup.string().required('Please enter a ZIP Code.'),
        website_url: Yup.string()
            .matches(urlRegExp, 'Please enter a valid URL.')
            .required('Please enter your company URL.'),
        reply_to_email: Yup.string()
            .matches(/^\S+@\S+\.[^,]+$/, 'Please enter a valid email address.')
            .required('Please enter a reply-to email.'),
        time_zone: Yup.string().required('Please select a time zone.')
    })
});

export const CompanyOnboardingCompanyDetailsPage: React.FC<{}> = observer(() => {
    const { agencyStore, store } = useStore();
    const { companyOnboardingFormData, updateCompanyOnboardingFormData } = agencyStore;
    const { router, authenticate } = store;
    const {
        params: { token }
    } = router;

    const [loading, setLoading] = useState(true);
    const [businessCategories, setBusinessCategories] = useState([]);
    const [initialValues] = useState<{ company: Partial<Company> }>(
        merge(
            {
                company: {
                    name: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    website_url: '',
                    reply_to_email: '',
                    business_category_id: 0,
                    time_zone: ''
                }
            },
            companyOnboardingFormData.formValues.company
        )
    );

    const fetchBusinessCategories = async () => {
        try {
            const { data } = await store.Api.client.post('/company-invite/business-categories', { token });

            if (data.business_categories) {
                setBusinessCategories(data.business_categories);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchData = async () => {
        await fetchBusinessCategories();
        setLoading(false);
    };

    useAsyncEffect(fetchData);

    const handleSubmit: (
        values: {
            company: Partial<Company>;
        },
        formikHelpers: FormikHelpers<{
            company: Partial<Company>;
        }>
    ) => void | Promise<any> = async (formValues, formikHelpers) => {
        const formData = updateCompanyOnboardingFormData({ formValues });
        const [response, errors] = await handlePromise(
            store.Api.client.post('/company-invite/create-company', { ...formData.formValues, token })
        );

        if (errors) {
            updateCompanyOnboardingFormData({ errors: errors.response.data });
            formikHelpers.setStatus({ serverErrors: errors.response.data });
            if (errors.user) {
                return router.goTo(DefaultRoutes.CompanyOnboardingCredentialsPage, router.params, store);
            }
            return;
        }

        authenticate(response);
        updateCompanyOnboardingFormData({ response: response.data });
        return router.goTo(DefaultRoutes.CompanyOnboardingSMSPage, router.params, store);
    };

    return (
        <div
            className={classNames(
                'company-onboarding',
                styles.companyOnboarding,
                styles.companyOnboardingCompanyDetails
            )}
        >
            <header>
                <div className={styles.companyOnboardingAgencyLogo}>
                    <AgencyLogo />
                </div>

                <StepChecklist
                    className={styles.companyOnboardingStepChecklist}
                    steps={['Credentials', 'Company Info', 'Enable SMS', 'Embed App', 'Invite Team']}
                    currentStepIndex={1}
                />

                <Illustration className={classNames(styles.companyOnboardingIllustration)} />
            </header>
            <main className={styles.companyOnboardingContent}>
                <Title>Add company details</Title>
                <p className={styles.companyOnboardingSubtitle}>Let us know a little about your organization:</p>
                <Form
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={companyOnboardingCompanyDetailsFormSchema}
                    onSubmit={handleSubmit}
                    initialErrors={companyOnboardingFormData.errors}
                >
                    {formikProps => (
                        <>
                            <InputText
                                labelPlacement="above"
                                label="Company Name"
                                name="company.name"
                                formikProps={formikProps}
                            />
                            <InputText
                                labelPlacement="above"
                                label="Company Address"
                                name="company.address1"
                                formikProps={formikProps}
                            />

                            <div className={styles.companyOnboardingFormRow}>
                                <InputText
                                    labelPlacement="above"
                                    label="Address Line 2"
                                    name="company.address2"
                                    formikProps={formikProps}
                                />
                                <InputText
                                    labelPlacement="above"
                                    label="City"
                                    name="company.city"
                                    formikProps={formikProps}
                                />
                            </div>

                            <div className={styles.companyOnboardingFormRow}>
                                <InputSelect
                                    labelPlacement="above"
                                    label="State"
                                    name="company.state"
                                    optionLabelKey="name"
                                    optionValueKey="code"
                                    options={usStates.filter(({ name }) => !!name)}
                                    formikProps={formikProps}
                                />
                                <InputText
                                    labelPlacement="above"
                                    label="Zip Code"
                                    name="company.zipcode"
                                    formikProps={formikProps}
                                />
                            </div>

                            <InputText
                                labelPlacement="above"
                                label="Company URL"
                                name="company.website_url"
                                formikProps={formikProps}
                            />

                            <InputText
                                labelPlacement="above"
                                label="Reply-To Email (Used for email responses)"
                                name="company.reply_to_email"
                                formikProps={formikProps}
                            />

                            <div className={styles.companyOnboardingFormRow}>
                                {loading || businessCategories.length ? (
                                    <InputSelect
                                        labelPlacement="above"
                                        label="Business Category"
                                        name="company.business_category_id"
                                        optionLabelKey="title"
                                        optionValueKey="id"
                                        inputProps={{ autoComplete: 'off' }}
                                        options={[
                                            ...(businessCategories
                                                ? businessCategories.map(({ title, id }) => ({ title, id }))
                                                : []),
                                            { title: 'None', id: 0 }
                                        ]}
                                        formikProps={formikProps}
                                        disabled={!businessCategories.length}
                                    />
                                ) : null}
                                <InputSelect
                                    labelPlacement="above"
                                    label="Time Zone"
                                    name="company.time_zone"
                                    optionValueKey="value"
                                    inputProps={{ autoComplete: 'off' }}
                                    options={Object.keys(timeZoneList).map(timeZoneKey => ({
                                        label: timeZoneKey,
                                        value: timeZoneKey
                                    }))}
                                    formikProps={formikProps}
                                />
                            </div>

                            <div className={styles.companyOnboardingFormActions}>
                                <ButtonPrimary type="submit">Continue</ButtonPrimary>
                            </div>
                        </>
                    )}
                </Form>
            </main>
        </div>
    );
});
