import React, { FC, HtmlHTMLAttributes } from 'react';
import classNames from 'classnames';
import { ReactComponent as EmptyPieChart } from './EmptyPieChart.svg';

import './chart-card.scss';
import { ChartType } from '../../../types';

export interface ChartCardEmptyProps extends HtmlHTMLAttributes<HTMLDivElement> {
    type: keyof typeof ChartType;
}

export const ChartCardEmpty: FC<ChartCardEmptyProps> = ({ className, type = '', ...props }) => {
    const isPieChart = ['pie', 'doughnut'].includes(type);
    const isComboChart = ['combo', 'bubble'].includes(type)

    // TODO: Add more empty states as we need them.
    // TODO: We can add svg in the future for bubble & combo chart. 
    return (
        <div className={classNames('chart-card__empty', className)} {...props}>
            {isPieChart && (
                <>
                    <EmptyPieChart className="chart-card__empty__illustration" />
                    <div className="chart-card__empty__description">No data to display.</div>
                </>
            )}
            {isComboChart && (
                <div className="chart-card__empty__description">No data to display.</div>
            )}
        </div>
    );
};
