import classNames from 'classnames';
import React, { FC } from 'react';
import './app-footer.scss';

interface AppFooterProps {
    className?: string;
    sticky?: boolean;
    variant?: 'primary' | 'full-width';
}

export const AppFooter: FC<AppFooterProps> = ({ className, children, sticky, variant = 'primary' }) => (
    <footer
        className={classNames(
            'app-footer',
            { 'app-footer-sticky': sticky, [`app-footer-${variant}`]: variant },
            className
        )}
    >
        {children}
    </footer>
);
