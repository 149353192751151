import React, { ChangeEvent, FC, useReducer } from 'react';
import { observer } from 'mobx-react';
import { formSchemas } from '../../util/validations/formSchemas';
import { FormikProps, useFormik } from 'formik';
import { pick } from 'lodash';
import { DateTime } from 'luxon';
import { CircularProgress } from '@material-ui/core';
import {
    ButtonLink,
    ButtonOutlinePrimary,
    ButtonPrimary,
    handlePromise,
    Icon,
    IconButton,
    Tabs,
    useAsyncEffect
} from '@lambdacurry/component-library';

import { ActionList, AppHeader, Header, useSnackbar } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { ReactComponent as ClarityLogo } from './ClarityLogo.svg';
import ClarityReports from './ClarityReports';
import { ClarityDashboardTab } from './ClarityDashboardTab';
import {
    DashboardInitialState,
    ClarityReducer,
    formatClarityDateRange,
    getClarityTabNameFromIndex,
    getClarityTabIndexFromName
} from './Clarity.helpers';
import { DashboardData } from '../Dashboard/HomeDashboard.helpers';
import { ScheduledReports } from './ScheduledReports';
import { ClarityChartConfig, ClarityConfig } from '../../constants';
import { useFilters } from '../Filters/Filters';
import {
    ClarityCreateReportConfig,
    ClarityFilterExplanation,
    ClarityLoadingStatus,
    ClarityReportRequestStatus,
    ClarityScheduledReportsItems,
    ClarityScheduler,
    ClaritySettings,
    ClarityTabName,
    ClarityUserPreferences
} from './Clarity.types';
import { ScheduleReportModal } from './ScheduleReportModal/ScheduleReportModal';
import {
    ClaritySchedulerForm,
    recommendedChartIds,
    initialScheduleReportState,
    SchedulerFormValidationSchema,
    SchedulerFormEditValidationSchema
} from './ScheduleReportModal/ScheduleReportModal.helpers';
import { ClarityCreateReportModal } from './ClarityCreateReportModal';
import { ClarityCreateReportFailedModal } from './ClarityCreateReportFailedModal/ClarityCreateReportFailedModal';

import './clarity-dashboard.scss';

export const ClarityDashboard: FC<{}> = observer(() => {
    const { store } = useStore();
    const { filters } = useFilters();
    const { addSnackbar, removeSnackbar } = useSnackbar();
    const { Api, activeCompanyId, agencyStore, activeUser, fetchClarityReportRequests } = store;
    const { activeAgencyId } = agencyStore;

    // TODO: Only implement setting active tab via query parameters when we can figure out how to set
    // the initial filters in this top-level page component. Otherwise, you will get errors when
    // trying to run 'createReport' until the 'default' tab is nested.
    //
    // const [queryParams, setQueryParams] = useQueryParams({
    //     activeTab: withDefault(StringParam, ClarityTabName.default)
    // });

    const [state, dispatch] = useReducer(ClarityReducer, {
        ...DashboardInitialState,
        activeTab: ClarityTabName.default

        // TODO: See note above about active tab query params implementation
        // activeTab: (queryParams.activeTab as ClarityTabName) || ClarityTabName.default
    });
    const { filterExplanation, reportsTab, scheduledReportsTab } = state;

    const availableCharts = state.preferences?.availableCharts || [];

    const schedulerFormikProps: FormikProps<ClaritySchedulerForm> = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...initialScheduleReportState,
            recipients: `${activeUser?.email}, `
        } as typeof initialScheduleReportState,
        validationSchema: state.createSchedulerModal.open
            ? SchedulerFormValidationSchema
            : SchedulerFormEditValidationSchema,
        onSubmit: () => handleSubmit()
    });

    // TODO: See note above about active tab query params implementation
    // useEffect(() => {
    //     setQueryParams({ activeTab: state.activeTab });
    // }, [state.activeTab]);

    const fetchClarityReportsData = async (pageNumber?: number, newLimit?: number, search: string = '') => {
        pageNumber = pageNumber || reportsTab.page;
        newLimit = newLimit || reportsTab.limit;
        const offset = (pageNumber - 1) * newLimit;

        dispatch({ name: 'setReportsTabLoading', payload: true });

        const [response, error] = await handlePromise(fetchClarityReportRequests(newLimit, offset, search));

        if (!response?.data || error) {
            return console.error('error: ', error);
        }

        dispatch({ name: 'setReportsTabPage', payload: pageNumber });
        dispatch({ name: 'setReportsTabLimit', payload: newLimit });
        dispatch({ name: 'setReportsTabLoading', payload: false });
        dispatch({ name: 'setReportsTabItems', payload: response?.data });
    };

    const fetchClarityUserPreferences = async () => {
        if (!filters) {
            return;
        }

        let preferences:
            | {
                  id?: number | undefined;
                  filters?: any;
                  name: string;
                  config: ClarityConfig;
                  settings: ClaritySettings;
                  availableCharts: Array<ClarityChartConfig>;
              }
            | undefined;

        let fetchPreferencesError;

        // Are the settings coming from localStorage or the API?
        if (localStorage.staticClaritySettings) {
            preferences = JSON.parse(localStorage.staticClaritySettings);
            if (preferences) {
                preferences.config.displayMode = 'print';
            }
        } else {
            const [response, error] = await handlePromise<{ data: ClarityUserPreferences }>(
                Api.client.get('/clarity/preferences')
            );
            fetchPreferencesError = error;
            preferences = response?.data;
            if (preferences) {
                preferences.config.displayMode = 'screen';
            }
        }

        if (!preferences || fetchPreferencesError) {
            dispatch({ name: 'setLoadingStatus', payload: ClarityLoadingStatus.error });
            addSnackbar('Failed to fetch CLarity user preferences data.', { variant: 'error' });
            return console.error('error: ', fetchPreferencesError);
        }

        dispatch({ name: 'setInitialSelectedCharts', payload: preferences.config.charts });
        dispatch({ name: 'setSelectedCharts', payload: preferences.config.charts });
        dispatch({ name: 'setPreferences', payload: preferences });
        dispatch({ name: 'setLoadingStatus', payload: ClarityLoadingStatus.complete });
        return;
    };

    useAsyncEffect(fetchClarityUserPreferences, undefined, [filters, activeCompanyId, activeAgencyId]);

    const fetchFilterExplain = async () => {
        if (!filters) {
            return;
        }

        let filterExplanation: ClarityFilterExplanation;

        if (localStorage.staticClaritySettings) {
            const preferences: { filter_explanation: ClarityFilterExplanation } = JSON.parse(
                localStorage.staticClaritySettings
            );
            filterExplanation = preferences.filter_explanation;
        } else {
            const [response, error] = await handlePromise<{ data: ClarityFilterExplanation }>(
                Api.client.post('/clarity/filter', { filter: filters })
            );

            if (!response?.data || error) {
                dispatch({ name: 'setLoadingStatus', payload: ClarityLoadingStatus.error });
                addSnackbar('Failed to fetch filter explanation.', { variant: 'error' });
                return console.error('error: ', error);
            }
            filterExplanation = response.data;
        }

        dispatch({ name: 'setFilterExplanation', payload: filterExplanation });
    };

    useAsyncEffect(fetchFilterExplain, undefined, [filters]);

    const dateRangeFormikProps: FormikProps<{ start?: Date; end?: Date }> = useFormik({
        enableReinitialize: true,
        validationSchema: formSchemas.dateRange,
        initialValues: {
            start: filters?.dateRange?.custom?.start,
            end: filters?.dateRange?.custom?.end
        },
        onSubmit: () => {
            // We do not need to do anything onSubmit since we are listening to changes on the dateRange values to set
            // the filter data in a `useEffect` below.
        }
    });

    // Re-fetch analytics data whenever the filters, context, activeCompanyId, or activeAgencyId changes.
    const fetchAnalyticsData = async () => {
        if (!state.preferences) {
            return;
        }

        // Are the analytics coming from localStorage or the API?
        if (localStorage.staticClaritySettings) {
            const preferences = JSON.parse(localStorage.staticClaritySettings);
            if (preferences.analyticsData) {
                dispatch({ name: 'setLoadingStatus', payload: ClarityLoadingStatus.complete });
                dispatch({ name: 'setPageData', payload: { ...preferences.analyticsData } });
                return;
            }
        }

        dispatch({ name: 'setLoadingStatus', payload: ClarityLoadingStatus.loading });
        const [response, error] = await handlePromise<{ data: DashboardData }>(
            Api.client.post('/analytics/dashboard', { filter: filters })
        );

        if (!response?.data || error) {
            dispatch({ name: 'setLoadingStatus', payload: ClarityLoadingStatus.error });
            addSnackbar('Failed to fetch CLarity stats data.', { variant: 'error' });
            return console.error('error: ', error);
        }

        dispatch({ name: 'setLoadingStatus', payload: ClarityLoadingStatus.complete });
        dispatch({ name: 'setPageData', payload: { ...response.data } });
    };

    useAsyncEffect(fetchAnalyticsData, undefined, [filters, activeCompanyId, activeAgencyId, state.preferences]);

    const createReport = async (createReportConfig: ClarityCreateReportConfig) => {
        const [response, error] = await handlePromise<{ data: { url: string; id: string } }>(
            Api.client.post('/clarity/report', createReportConfig)
        );

        if (!response?.data || error) {
            if (error.response.status === 409) {
                addSnackbar('Report names must be unique.', { variant: 'error' });
                return;
            }

            addSnackbar('Failed to request report.', { variant: 'error' });
            return console.error('error: ', error);
        }

        // TODO: It might be good to make this sort of snackbar reusable.
        const createReportSnackbar = addSnackbar(
            <>
                <CircularProgress size="20px" />
                <div>
                    <Header as="h4" className="clx-margin-bottom-0">
                        Creating report. Feel free to continue working.
                    </Header>
                    <div>
                        Your report will display in the{' '}
                        <ButtonLink onClick={() => dispatch({ name: 'setActiveTab', payload: ClarityTabName.reports })}>
                            Reports list
                        </ButtonLink>{' '}
                        in a few minutes.
                    </div>
                </div>
            </>,
            { persist: true, className: 'clarity-report-snackbar' }
        );

        if (state.activeTab === ClarityTabName.reports) {
            fetchClarityReportsData(1);
        }

        dispatch({ name: 'setActiveTab', payload: ClarityTabName.reports });
        dispatch({ name: 'setCreateReportModalOpen', payload: false });
        dispatch({ name: 'setCreateReportModalReportName', payload: undefined });
        dispatch({ name: 'setCreateReportModalReportNameError', payload: undefined });

        subscribeToReportCreationProgress(response.data.id, createReportSnackbar);
    };

    async function subscribeToReportCreationProgress(requestGuid: string, snackbarKey: string | number) {
        const subscribe = async () => {
            await new Promise(resolve => setTimeout(resolve, 4000));

            const [response, error] = await handlePromise<{ data: { status: ClarityReportRequestStatus } }>(
                Api.client.get(`/clarity/report-request/${requestGuid}`)
            );

            let { status } = response?.data || {};

            if (error) {
                if (error.response?.status === 502) {
                    await subscribe();
                } else if (error.response?.status === 404) {
                    await fetchClarityReportsData(1);
                    removeSnackbar(snackbarKey);
                    addSnackbar(
                        <>
                            <Icon name="error" />
                            <div>
                                <Header as="h4" className="clx-margin-bottom-0">
                                    Report creation failed
                                </Header>

                                <div>
                                    Something went wrong when generating the report. Please try again or contact support
                                    for assistance.
                                </div>
                            </div>
                        </>,
                        { persist: true, className: 'clarity-report-snackbar' }
                    );
                } else {
                    // An error - let's show it
                    addSnackbar(`There was a server connection issue when fetching the report progress. Retrying...`, {
                        variant: 'error'
                    });

                    // Reconnect in one second
                    await new Promise(resolve => setTimeout(resolve, 4000));
                    await subscribe();
                }
            } else if (status === ClarityReportRequestStatus.requested) {
                await subscribe();
            } else if (status === ClarityReportRequestStatus.failed) {
                await fetchClarityReportsData(1);
                removeSnackbar(snackbarKey);
                dispatch({ name: 'setCreateReportFailedModalOpen', payload: true });
            } else {
                await fetchClarityReportsData(1);

                // TODO: It might be good to make this sort of snackbar reusable.
                addSnackbar(
                    <>
                        <Icon name="check" />
                        <div>
                            <Header as="h4" className="clx-margin-bottom-0">
                                Report created successfully!
                            </Header>

                            <div>
                                You may view and download your report on the Reports tab now.
                            </div>
                        </div>
                    </>,
                    { persist: true, className: 'clarity-report-snackbar' }
                );
            }

            return 'done';
        };

        await subscribe();
    }

    const handleScheduleNewReport = () => {
        schedulerFormikProps.resetForm();
        schedulerFormikProps.setFieldValue(
            'available_charts',
            availableCharts.map(ac => {
                const isEnabledByDefault = recommendedChartIds.includes(ac.id);
                return { chart: ac, enabled: isEnabledByDefault };
            })
        );
        dispatch({ name: 'setCreateSchedulerModalOpen', payload: true });
    };

    const fetchClarityScheduledReports = async (pageNumber?: number, newLimit?: number, search?: string) => {
        pageNumber = pageNumber || scheduledReportsTab.page;
        newLimit = newLimit || scheduledReportsTab.limit;
        const offset = (pageNumber - 1) * newLimit;

        const [response, error] = await handlePromise<{ data: ClarityScheduledReportsItems }>(
            Api.client.get('/clarity/scheduler', { params: { offset, limit: newLimit, search } })
        );

        dispatch({ name: 'setScheduledReportsTabLoading', payload: false });

        if (!response?.data || error) {
            return console.error('error: ', error);
        }

        if (response.data) {
            dispatch({ name: 'setScheduledReportsTabItems', payload: response.data });
            dispatch({ name: 'setScheduledReportsTabPage', payload: pageNumber });
            dispatch({ name: 'setScheduledReportsTabLimit', payload: newLimit });
        }
    };

    const handleSubmit = async () => {
        const {
            available_charts,
            id,
            recipients,
            series_start_date,
            series_start_time,
            series_end
        } = schedulerFormikProps.values;

        const chart_configs: ClarityChartConfig[] = available_charts
            .filter(availableChart => availableChart.enabled)
            .map(acc => acc.chart);

        const data: Partial<ClarityScheduler> = {
            ...pick(schedulerFormikProps.values, ['name', 'interval', 'filters']),
            recipients: recipients
                .split(/[/:/;,]+/)
                .map(recipient => recipient.trim())
                .join(', '),
            series_start: Math.round(
                DateTime.fromJSDate(series_start_date, { zone: DateTime.local().zone })
                    .startOf('day')
                    .plus({ hours: series_start_time })
                    .toSeconds()
            ),
            series_end: series_end
                ? Math.round(
                      DateTime.fromJSDate(series_end, { zone: DateTime.local().zone })
                          .startOf('day')
                          .toSeconds()
                  )
                : undefined,
            config: { charts: chart_configs, displayMode: 'screen' }
        };

        const [response, error] =
            id > 0
                ? await handlePromise(Api.client.patch(`/clarity/scheduler/${id}`, data))
                : await handlePromise(Api.client.post('/clarity/scheduler', data));

        if (!response) {
            if (error.response.status === 409) {
                schedulerFormikProps.setFieldError('name', 'Please choose a unique report name.');
            }

            return;
        }

        await fetchClarityScheduledReports(scheduledReportsTab.page, scheduledReportsTab.limit);
        dispatch({ name: 'setEditSchedulerModalOpen', payload: false });
        dispatch({ name: 'setCreateSchedulerModalOpen', payload: false });
    };

    const handleTabChange = (event: ChangeEvent<any>, value: number) => {
        const tabName = getClarityTabNameFromIndex(value);
        dispatch({ name: 'setActiveTab', payload: tabName });
    };

    return (
        <>
            <div className="clarity-dashboard">
                <AppHeader>
                    <div className="clarity-logo">
                        <ClarityLogo />
                    </div>
                    {filterExplanation && (
                        <>
                            <div className="flex-spacer" />
                            <div className="clarity-dashboard-daterange clx-display-none pr:clx-display-block">
                                {formatClarityDateRange(filterExplanation.dateRange)}
                            </div>
                        </>
                    )}
                    <ActionList position="end">
                        <div className="filters">
                            <IconButton
                                className="clx-display-inline-flex lg:clx-display-none"
                                icon="scheduleReport"
                                onClick={() => handleScheduleNewReport()}
                            />
                            <ButtonOutlinePrimary
                                className="clx-display-none lg:clx-display-inline-flex"
                                icon={<Icon name="scheduleReport" />}
                                onClick={() => handleScheduleNewReport()}
                            >
                                Schedule Report
                            </ButtonOutlinePrimary>
                        </div>
                        <ButtonPrimary onClick={() => dispatch({ name: 'setCreateReportModalOpen', payload: true })}>
                            Create Report
                        </ButtonPrimary>
                    </ActionList>
                </AppHeader>

                <div className="clarity-dashboard-tabs">
                    <Tabs
                        onChange={handleTabChange}
                        value={getClarityTabIndexFromName(state.activeTab)}
                        tabs={[
                            {
                                label: 'Clarity Attribution',
                                render: (
                                    <ClarityDashboardTab
                                        state={state}
                                        dispatch={dispatch}
                                        dateRangeFormikProps={dateRangeFormikProps}
                                    />
                                )
                            },
                            {
                                label: 'Reports',
                                render: (
                                    <ClarityReports
                                        state={state}
                                        dispatch={dispatch}
                                        fetchClarityReportsData={fetchClarityReportsData}
                                    />
                                )
                            },
                            {
                                label: 'Scheduled Reports',
                                render: (
                                    <ScheduledReports
                                        state={state}
                                        dispatch={dispatch}
                                        createReport={createReport}
                                        fetchClarityScheduledReports={fetchClarityScheduledReports}
                                        handleScheduleNewReport={handleScheduleNewReport}
                                        schedulerFormikProps={schedulerFormikProps}
                                    />
                                )
                            }
                        ]}
                    />
                </div>
            </div>

            <ClarityCreateReportModal state={state} dispatch={dispatch} createReport={createReport} filters={filters} />
            <ClarityCreateReportFailedModal state={state} dispatch={dispatch} />
            <ScheduleReportModal state={state} dispatch={dispatch} schedulerFormikProps={schedulerFormikProps} />
        </>
    );
});
