import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { Transform } from '@dnd-kit/utilities';
import { DecorativeIconProps, IconButton, InputCheckbox } from '@lambdacurry/component-library';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, { CSSProperties, FC, memo, ReactElement, Ref, forwardRef, useEffect } from 'react';
import {
    AppListItem,
    AppListItemActions,
    AppListItemContent,
    AppListItemDetails
} from '../../../../components-v2/shared';
import { Handle } from '../../../../components-v2/shared/dnd';
import { Sortable } from '../../../../components-v2/shared/dnd/Sortable';
import { ApplicationSettingsFormValues } from '../../ApplicationSettings.helpers';
import { ActiveApp } from './OrderSection';

export interface ActiveAppItem extends ActiveApp {
    icon: DecorativeIconProps;
}

export interface ActiveAppOrderListProps {
    appsList: ActiveAppItem[];
    appListField: string;
    hiddenAppsField: string;
    formikProps: FormikProps<ApplicationSettingsFormValues>;
}

export interface ActiveAppOrderListItemProps extends ActiveAppItem {
    index: number;
    handleMoveInArray: (index: number, direction: string) => void;
    handleToggleHidden: (hide: boolean) => void;
    hidden: boolean;
    appsList: any[];
}

export interface AppOrderSortableProps {
    className: string;
    dragOverlay?: boolean;
    color?: string;
    disabled?: boolean;
    dragging?: boolean;
    handle?: boolean;
    height?: number;
    index?: number;
    fadeIn?: boolean;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    sorting?: boolean;
    style?: CSSProperties;
    transition?: string | null;
    wrapperStyle?: CSSProperties;
    value: ActiveAppOrderListItemProps;
    onRemove?(): void;
    renderItem?(args: {
        dragOverlay: boolean;
        dragging: boolean;
        sorting: boolean;
        index: number | undefined;
        fadeIn: boolean;
        listeners: DraggableSyntheticListeners;
        ref: Ref<HTMLElement>;
        style: CSSProperties | undefined;
        transform: AppOrderSortableProps['transform'];
        transition: AppOrderSortableProps['transition'];
        value: AppOrderSortableProps['value'];
    }): ReactElement;
}

const ActiveAppOrderListItem = memo(
    forwardRef<HTMLLIElement, AppOrderSortableProps>(
        (
            {
                className,
                color,
                dragOverlay,
                dragging,
                disabled,
                fadeIn,
                handle,
                height,
                index,
                listeners,
                onRemove,
                renderItem,
                sorting,
                style,
                transition,
                transform,
                value,
                wrapperStyle,
                ...props
            },
            ref
        ) => {
            useEffect(() => {
                if (!dragOverlay) {
                    return;
                }

                document.body.style.cursor = 'grabbing';

                return () => {
                    document.body.style.cursor = '';
                };
            }, [dragOverlay]);

            return (

                value.module_name !== 'MODULE_BPN' ?
                    <>
                        <li
                            className={classNames('Wrapper', className, {
                                fadeIn,
                                sorting,
                                dragOverlay
                            })}
                            style={
                                {
                                    ...wrapperStyle,
                                    transition,
                                    '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
                                    '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                                    '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
                                    '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
                                    '--index': index,
                                    '--color': color
                                } as CSSProperties
                            }
                            ref={ref}
                        >
                            <AppListItem
                                className={classNames('Item', {
                                    dragging,
                                    withHandle: handle,
                                    dragOverlay,
                                    disabled,
                                    color
                                })}
                                style={style}
                                {...props}
                                tabIndex={!handle ? 0 : undefined}
                            >
                                <AppListItemContent icon={value.icon} title={value.display_name} />
                                <InputCheckbox
                                    className="app-list-item-checkbox"
                                    label="Hidden"
                                    checked={value.hidden}
                                    onChange={(_, checked) => value.handleToggleHidden(checked)}
                                />
                                {/* This empty component is used to push the actions to the end of the list item */}
                                <AppListItemDetails />
                                <AppListItemActions position="end">
                                    <IconButton
                                        icon="chevronUp"
                                        disabled={index === 0}
                                        onClick={() => value.handleMoveInArray(value.index, 'up')}
                                    />
                                    <IconButton
                                        icon="chevronDown"
                                        disabled={index === value.appsList.length - 1}
                                        onClick={() => value.handleMoveInArray(value.index, 'down')}
                                    />
                                    {handle && <Handle className="app-list-item-handle" {...listeners} />}
                                </AppListItemActions>
                            </AppListItem>
                        </li>
                    </> :
                    <>
                    </>


            );
        }
    )
);

export const AppOrderList: FC<ActiveAppOrderListProps> = ({ appsList, appListField, hiddenAppsField, formikProps }) => {
    const handleToggleHidden = (module_name: string, hide: boolean) => {
        const isAlreadyHidden = formikProps.values[hiddenAppsField].includes(module_name);
        if (isAlreadyHidden === hide) return;
        if (hide)
            return formikProps.setFieldValue(hiddenAppsField, [...formikProps.values[hiddenAppsField], module_name]);
        if (!hide)
            return formikProps.setFieldValue(
                hiddenAppsField,
                formikProps.values[hiddenAppsField].filter(m => m !== module_name)
            );
    };
    const handleMoveInArray = (currentIndex: number, direction: 'up' | 'down') => {
        const newArray = [...formikProps.values[appListField]];
        const removedItem = newArray.splice(currentIndex, 1)[0];
        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        newArray.splice(newIndex, 0, removedItem);

        formikProps.setFieldValue(appListField, newArray);
    };

    if (!appsList || !appsList.length) {
        return <p className="clx-margin-top-0">No active apps to display.</p>;
    }

    const hiddenApps = formikProps.values[hiddenAppsField];

    return (
        <Sortable
            items={appsList.map((appItem, index) => ({
                ...appItem,
                index,
                handleMoveInArray,
                appsList,
                handleToggleHidden: (hide: boolean) => handleToggleHidden(appItem.module_name, hide),
                hidden: hiddenApps.includes(appItem.module_name),
                id: `${appItem.app_id}-${appItem.slug}`
            }))}
            Container={(props: any) => <ul className="app-order-list-container" {...props} />}
            renderItem={(props: any) => {
                return <ActiveAppOrderListItem key={props.value.id} {...props} />;
            }}
            handle={true}
            useDragOverlay={false}
            onDragEnd={newArray => {
                formikProps.setFieldValue(
                    appListField,
                    newArray.map(({ module_name }) => module_name)
                );
            }}
        />
    );
};
