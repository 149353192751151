import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import './grid.scss';

export interface GridProps extends HTMLAttributes<HTMLElement> {}

export const Grid: FC<GridProps> = ({ className, ...props }) => (
    <div className={classNames('lc-grid', className)} {...props} />
);
