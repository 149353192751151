import { groupBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { Highlighter, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import * as constants from '../../../constants';
import Store from '../../../store';
import {
    AccessibleAgency,
    AccessibleCompany,
    InjectedProps,
    LeadStatusNames,
    LeadTypeNamesOptions
} from '../../../types';
import { numberFormatter } from '../../../util/formatters';
import ControlSelectFilter from '../../misc/ControlSelectFilter';
import ControlTextFilter from '../../misc/ControlTextFilter';
import DateTimeIntervalPicker from '../../misc/DateTimeIntervalPicker';

interface CommonFilterProps {
    filterCode: string;
    listCode?: string;
    applyFilter?: () => void;
    applyFilterDate?: () => void;
    store?: Store;
    hideDateFilter?: boolean;
    hideBCFilter?: boolean;
    hideDMAFilter?: boolean;
    hideOGFilter?: boolean;
    hidePMCFilter?: boolean;
    hideRegionFilter?: boolean;
    hideCompanyFilter?: boolean;
    hideAgencyFilter?: boolean;
    hideLeadStateFilter?: boolean;
    showLeadStatusFilter?: boolean;
    showLeadTypeFilter?: boolean;
    showSearchFilter?: boolean;
    counterCompanies?: number;
    clearFilter?: () => void;
}

interface CommonFilterState {
    loading: boolean;
    refresh: boolean;
    businessCategories: any[];
}

@inject('store')
@observer
class CommonFilter extends React.Component<CommonFilterProps, CommonFilterState> {
    public state = {
        loading: true,
        refresh: false,
        businessCategories: []
    };
    public setTimerSearchId: any;
    public filterCode = constants.FILTER_METRIC_CODE;
    public listCode = constants.FILTER_METRIC_CODE;
    public commonFilterFields = [
        'agency_id',
        'company_id',
        'datetime',
        'business_category_id',
        'dma',
        'ownership_group_id',
        'property_management_company_id',
        'region_id',
        'status',
        'lead_state_name',
        'lead_type',
        'search'
    ];

    public elements: { [item: string]: any } = {};

    public get injected() {
        return this.props as InjectedProps;
    }

    public getFullCompanyName(company: AccessibleCompany): string {
        return company.name + (company.state ? ' - ' + company.state : '');
    }

    // TODO: look at converting this to componentDidMount before React v17
    public UNSAFE_componentWillMount() {
        if (this.props.filterCode) {
            this.filterCode = this.props.filterCode;
        }
        if (this.props.listCode) {
            this.listCode = this.props.listCode;
        }
    }

    public componentDidMount() {
        return this.fetchData().then(() => {
            this.setState({ loading: false });
        });
    }

    public fetchData(): Promise<boolean> {
        const {
            fetchCategoryFilter,
            fetchOwnershipGroup,
            fetchPropertyManagementCompany,
            fetchRegions
        } = this.injected.store;

        const fetchData: any[] = [];

        if (!this.props.hideBCFilter) {
            fetchData.push(
                fetchCategoryFilter().then((r: any) => {
                    this.setState({ businessCategories: r });
                })
            );
        }

        if (!this.props.hideOGFilter) {
            fetchData.push(fetchOwnershipGroup());
        }

        if (!this.props.hidePMCFilter) {
            fetchData.push(fetchPropertyManagementCompany());
        }

        if (!this.props.hideRegionFilter) {
            fetchData.push(fetchRegions());
        }

        return fetchData.length
            ? Promise.all(fetchData).then(() => {
                return true;
            })
            : Promise.resolve(true);
    }

    public renderMenu = (results: any, menuProps: any) => {
        let idx = 0;
        const grouped = groupBy(results, 'active');
        const items = Object.keys(grouped)
            .sort()
            .reverse()
            .map(group => [
                !!idx && <Menu.Divider key={`${group}-divider`} />,
                group === 'false' && <Menu.Header key={`clx-${group}-header`}>-- Not Active --</Menu.Header>,
                grouped[group].map((state: any, index) => {
                    const item = (
                        <MenuItem
                            key={idx}
                            option={state}
                            position={idx}
                            className={!state.active ? 'clx-not-active' : 'clx-active'}
                        >
                            <Highlighter search={menuProps.text}>{state.full_name || state.name || ''}</Highlighter>
                        </MenuItem>
                    );

                    idx++;
                    return item;
                })
            ]);

        return <Menu {...menuProps}>{items}</Menu>;
    };

    public render() {
        if (this.state.loading) {
            return null;
        }

        const {
            accessibleCompanies,
            activeCompanyId,
            ownershipGroup,
            PropertyManagementCompany,
            regions,
            getFilterSetting
        } = this.injected.store;
        const { accessibleAgencies, activeAgencyId } = this.injected.store.agencyStore;
        const filter = getFilterSetting(this.filterCode);

        let selectedDma;
        if (filter.dma) {
            selectedDma = constants.dma.filter(v => v.code === filter.dma);
        }

        let selectedCompany: AccessibleCompany[] = [];
        if (filter.company_id) {
            selectedCompany = accessibleCompanies.filter(v => v.id === filter.company_id);
        }

        let selectedAgency: AccessibleAgency[] = [];
        if (filter.agency_id) {
            selectedAgency = accessibleAgencies.filter(a => a.id === filter.agency_id);
        }

        const filterActiveClass = constants.FILTER_ACTIVE_CSS;
        const filterPassiveClass = constants.FILTER_PASSIVE_CSS;
        const disabledForCompany = !!filter.company_id && !activeCompanyId && !this.props.hideCompanyFilter;

        const compare = (a: { name: string }, b: { name: string }) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            return nameA === nameB ? 0 : nameA > nameB ? 1 : -1;
        };
        const counterCompanies = this.props.counterCompanies || 0;

        const getCompanyOptions = () => {
            let options = accessibleCompanies;

            if (activeAgencyId) {
                options = options.filter(c => c.agency_id === activeAgencyId);
            }

            if (filter.business_category_id) {
                options = options.filter(
                    c => c.business_category_id === parseInt(filter.business_category_id || '-1', 10)
                );
            }

            return options;
        };

        return (
            <>
                <div className="wrapper-metric__bottom holder-rangecontainer">
                    {!this.props.hideCompanyFilter && !activeCompanyId ? (
                        <div className="col-lg-2 col-md-3 metric-name-box first-box-metric">
                            <div className="leadCount" key={'companies'}>
                                <div>{numberFormatter(counterCompanies)}</div>
                                <div className="moduleName">Companies</div>
                            </div>
                        </div>
                    ) : null}
                    <div className="wrapper-metric-sessions">
                        {!this.props.hideAgencyFilter && !activeAgencyId ? (
                            <div className="col-md-4 col-sm-6 metric-name-box">
                                <div className={filter.agency_id ? filterActiveClass : filterPassiveClass}>
                                    <Typeahead
                                        id="agency_id"
                                        labelKey="name"
                                        options={accessibleAgencies}
                                        placeholder="Please Select an Agency"
                                        clearButton={true}
                                        selected={selectedAgency}
                                        onChange={this.handleChangeAgency}
                                        ref={component => (this.elements.agency_id = component)}
                                        renderMenu={this.renderMenu}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {!this.props.hideCompanyFilter && !activeCompanyId ? (
                            <div className="col-md-4 col-sm-6 metric-name-box">
                                <div className={filter.company_id ? filterActiveClass : filterPassiveClass}>
                                    <Typeahead
                                        id="company_id"
                                        labelKey="full_name"
                                        options={getCompanyOptions()}
                                        placeholder="Please Select a Company"
                                        clearButton={true}
                                        selected={selectedCompany}
                                        onChange={this.handleChangeCompany}
                                        ref={component => (this.elements.company_id = component)}
                                        filterBy={(company: AccessibleCompany, input) =>
                                            this.getFullCompanyName(company)
                                                .toLowerCase()
                                                .includes(input.text.toLowerCase())
                                        }
                                        renderMenu={this.renderMenu}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {!this.props.hideDateFilter ? (
                            <div className="col-md-4 col-sm-6 metric-name-box">
                                <div className={filter.start && filter.end ? filterActiveClass : filterPassiveClass}>
                                    <DateTimeIntervalPicker
                                        start={filter.start ? moment.unix(filter.start) : undefined}
                                        end={filter.end ? moment.unix(filter.end) : undefined}
                                        onChange={this.handleDateFilterRangeChange}
                                        handleClear={this.handleDateFilterRangeClear}
                                        ref={component => (this.elements.datetime = component)}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {!this.props.hideBCFilter ? (
                            <div className="col-md-4 col-sm-6 metric-name-box">
                                <ControlSelectFilter
                                    id="business_category_id"
                                    optionData={this.state.businessCategories}
                                    optionEmptyTitle="Please Select a Business Category"
                                    optionKeyLabel="title"
                                    onChange={this.changeSelect}
                                    value={filter.business_category_id}
                                    ref={component => (this.elements.business_category_id = component)}
                                    disabled={disabledForCompany}
                                />
                            </div>
                        ) : null}
                        {!this.props.hideDMAFilter ? (
                            <div className="col-md-4 col-sm-6 metric-name-box">
                                <div
                                    className={
                                        selectedDma && !disabledForCompany ? filterActiveClass : filterPassiveClass
                                    }
                                >
                                    <Typeahead
                                        id="dma"
                                        labelKey="area"
                                        options={constants.dma}
                                        placeholder="Please Select a DMA"
                                        clearButton={true}
                                        selected={selectedDma}
                                        onChange={selected =>
                                            selected.length === 0
                                                ? this.changeSelect('dma', undefined, true)
                                                : this.changeSelect('dma', selected[0].code, true)
                                        }
                                        ref={component => (this.elements.dma = component)}
                                        disabled={disabledForCompany}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {!this.props.hideOGFilter ? (
                            <div className="col-md-4 col-sm-6 metric-name-box">
                                <ControlSelectFilter
                                    id="ownership_group_id"
                                    optionData={ownershipGroup.values.sort(compare)}
                                    optionEmptyTitle="Please Select a Ownership Group"
                                    onChange={this.changeSelect}
                                    value={filter.ownership_group_id}
                                    ref={component => (this.elements.ownership_group_id = component)}
                                    disabled={disabledForCompany}
                                />
                            </div>
                        ) : null}
                        {!this.props.hidePMCFilter ? (
                            <div className="col-md-4 col-sm-6 metric-name-box">
                                <ControlSelectFilter
                                    id="property_management_company_id"
                                    optionData={PropertyManagementCompany.values.sort(compare)}
                                    optionEmptyTitle="Please Select a Property Mgmt Company"
                                    onChange={this.changeSelect}
                                    value={filter.property_management_company_id}
                                    ref={component => (this.elements.property_management_company_id = component)}
                                    disabled={disabledForCompany}
                                />
                            </div>
                        ) : null}
                        {!this.props.hideRegionFilter ? (
                            <div className="col-md-4 col-sm-6 metric-name-box">
                                <ControlSelectFilter
                                    id="region_id"
                                    optionData={regions.values.sort(compare)}
                                    optionEmptyTitle="Please Select a Region"
                                    onChange={this.changeSelect}
                                    value={filter.region_id}
                                    ref={component => (this.elements.region_id = component)}
                                    disabled={disabledForCompany}
                                />
                            </div>
                        ) : null}

                        {this.props.showLeadStatusFilter ? (
                            <div className="col-md-4 col-sm-6 metric-name-box">
                                <ControlSelectFilter
                                    id="status"
                                    optionKeyValue="code"
                                    optionData={LeadStatusNames}
                                    optionEmptyTitle="Please Select a Lead Status"
                                    onChange={this.changeSelect}
                                    value={filter.status}
                                    ref={component => (this.elements.status = component)}
                                />
                            </div>
                        ) : null}

                        {this.props.showLeadTypeFilter ? (
                            <div className="col-md-4 col-sm-6 metric-name-box">
                                <ControlSelectFilter
                                    id="lead_type"
                                    optionKeyValue="code"
                                    optionData={LeadTypeNamesOptions}
                                    optionEmptyTitle="Please Select a Lead Type"
                                    onChange={this.changeSelect}
                                    value={filter.lead_type}
                                    ref={component => (this.elements.lead_type = component)}
                                />
                            </div>
                        ) : null}

                        {this.props.showSearchFilter ? (
                            <div className="col-md-4 col-sm-6 metric-name-box">
                                <ControlTextFilter
                                    id="search"
                                    defaultValue={filter.search}
                                    changeFilter={this.changeSelect}
                                    placeholder="Search"
                                    ref={component => (this.elements.search = component)}
                                />
                            </div>
                        ) : null}
                    </div>
                    <button className="btn btn-primary" onClick={this.clearFilter}>
                        Clear Filters
                    </button>
                </div>
            </>
        );
    }

    protected handleDateFilterRangeChange = (start: moment.Moment, end: moment.Moment) => {
        const { changeListSetting, changeFilterSetting } = this.injected.store;

        changeFilterSetting(this.filterCode, {
            start: start.unix(),
            end: end.unix()
        });
        changeListSetting(this.listCode, { page: 1 });

        this.setState({ refresh: !this.state.refresh });

        this.applyFilterDate();
    };

    protected handleDateFilterRangeClear = () => {
        const { changeListSetting, changeFilterSetting } = this.injected.store;

        changeFilterSetting(this.filterCode, {
            start: undefined,
            end: undefined
        });
        changeListSetting(this.listCode, { page: 1 });

        this.setState({ refresh: !this.state.refresh });

        this.applyFilter();
    };

    protected handleChangeCompany = (selected: any[]) =>
        !selected.length
            ? this.changeSelect('company_id', undefined, true)
            : this.changeSelect('company_id', selected[0].id, true);

    protected handleChangeAgency = (selected: any[]) =>
        !selected.length
            ? this.changeSelect('agency_id', undefined, true)
            : this.changeSelect('agency_id', selected[0].id, true);

    protected clearFilter = () => {
        if (this.props.clearFilter) {
            this.props.clearFilter();
        } else {
            const { clearFilterSetting } = this.injected.store;
            clearFilterSetting(this.filterCode);
        }

        this.clearRefs();
        this.setState({ refresh: !this.state.refresh });

        this.applyFilter();
    };

    protected changeSelect = (k: string, v: any, refresh: boolean = false) => {
        const { changeListSetting, changeFilterSetting } = this.injected.store;

        if (k) {
            const st: { [x: string]: any } = {};
            st[k] = v;
            changeFilterSetting(this.filterCode, st);
            changeListSetting(this.listCode, { page: 1 });
        }

        if (refresh) {
            this.setState({ refresh: !this.state.refresh });
        }

        this.applyFilter();
    };

    protected changeSearch = (e: any) => {
        const search = e.target.value;

        if (this.setTimerSearchId !== undefined) {
            clearTimeout(this.setTimerSearchId);
        }

        const searchTimeoutDelay = 800;
        this.setTimerSearchId = window.setTimeout(() => {
            const { changeListSetting, changeFilterSetting } = this.injected.store;

            changeFilterSetting(this.filterCode, { search });
            changeListSetting(this.listCode, { page: 1 });

            this.setState({ refresh: !this.state.refresh });

            return this.applyFilter();
        }, searchTimeoutDelay);
    };

    private applyFilter() {
        if (this.props.applyFilter) {
            this.props.applyFilter();
        }
    }

    private applyFilterDate() {
        if (this.props.applyFilterDate) {
            this.props.applyFilterDate();
        }
        this.applyFilter();
    }

    private clearRefs() {
        this.commonFilterFields.forEach(k => {
            if (this.elements[k]) {
                this.elements[k].clear();
            }
        });
    }
}

export default CommonFilter;
