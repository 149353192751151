import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { AppHeader, AppPage, AppContent, AppListEmpty, ButtonPrimary, useSnackbar } from '../../components-v2/shared';
import { TextUsNowForm } from './TextUsNowForm';
import { ReactComponent as EmptyIllustration } from './EmptyTextUsNowIllustration.svg';
import DefaultRoutes from '../../routes/DefaultRoutes';
import useStore from '../../store/useStore';
import { useAsyncEffect } from '../../util/async';
import { emptyTextUs } from '../../store/initial';
import { FormikHelpers } from 'formik';
import { TextUsNowEdit } from './TextUsNowEdit';

export const TextUsNowNew = observer(() => {
    const [loading, setLoading] = useState(true);
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { fetchTextUs, fetchSchedules, fetchTwilioNumbers, schedules, twilioNumbers, textUs, router } = store;
    const { companyId } = router.params;

    if (!companyId) {
        router.goTo(DefaultRoutes.Home, {}, store);
        return null;
    }

    const fetchData = async () => {
        await fetchTextUs();
        await fetchSchedules();
        await fetchTwilioNumbers();
        setLoading(false);
    };

    useAsyncEffect(fetchData);

    const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
        try {
            await textUs.create({ ...values, schedule_id: values.schedule_id || null, active: true });
            addSnackbar(`Text us now has been created.`, {
                variant: 'success'
            });
        } catch (error) {
            actions.setErrors(error.response.data);
        }
    };

    const goToCompanySettings = () => {
        const companyEditRoute = DefaultRoutes.CompanyEdit;
        router.goTo(companyEditRoute, { companyId, ...router.params }, store);
    };

    const initialValues = {
        ...emptyTextUs,
        company_id: parseInt(companyId, 10),
        schedule_id: schedules.values.length ? schedules.values[0].id : undefined,
        twilio_id: twilioNumbers?.values.length ? twilioNumbers.values[0].id : 0
    };

    return textUs?.length > 0 && twilioNumbers.values.length > 0 ? (
        <TextUsNowEdit textUsId={textUs.getFirstItemId()} />
    ) : (
        <AppPage loading={loading}>
            <AppHeader title="Text Us Now" icon={{ name: 'sms', color: 'blue' }} />

            {twilioNumbers.values.length > 0 && (
                <TextUsNowForm
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    companyId={parseInt(companyId, 10)}
                    schedules={schedules}
                    twilioNumbers={twilioNumbers}
                />
            )}

            {twilioNumbers.values.length < 1 && (
                <AppContent>
                    <AppListEmpty
                        illustration={<EmptyIllustration />}
                        title="Communicate directly with customers through text"
                        description="Get started by creating a unique number to text leads with below. Adding an SMS Twilio number starts a monthly subscription."
                        action={<ButtonPrimary onClick={goToCompanySettings}>Set up your SMS Number</ButtonPrimary>}
                    />
                </AppContent>
            )}
        </AppPage>
    );
});
