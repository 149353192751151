import React, { FC, useEffect, useRef, useState, ChangeEvent, ReactNode, KeyboardEventHandler, useCallback } from 'react';
import classNames from 'classnames';
import { ButtonPrimary, ButtonProps, Icon, InputText } from '@lambdacurry/component-library';
import { useOnClickOutside } from '../../../../../util/hooks';
import { ViewSwitcherMenuItem } from './ViewSwitcherMenuItem';
import { ViewSwitcherToggle } from './ViewSwitcherToggle';
import { ViewSwitcherOption, ViewSwitcherOptions } from './ViewSwitcher.types';

import './view-switcher.scss';
import { observer } from 'mobx-react';
import useStore from '../../../../../store/useStore';
import { InviteNewCompanyModal } from '../../../../Modals/InviteNewCompanyModal/InviteNewCompanyModal';
import { USER_PROFILE_DEFAULT_LIMIT, USER_PROFILE_DEFAULT_SKIP } from '../../../../../constants';
import { debounce } from 'lodash';
import { CircularProgress } from '@material-ui/core';

export interface ViewSwitcherProps {
    name: 'companySearch' | 'partnerSearch';
    label: 'Company' | 'Partner';
    options: ViewSwitcherOptions;
    value?: number | ViewSwitcherOption;
    loading?: boolean;
    onChange?: (newValue?: ViewSwitcherOption, oldValue?: ViewSwitcherOption) => void;
    onCreateNewClick?: ButtonProps['onClick'];
    defaultIcon?: ReactNode;
    className?: string;
    disabled?: boolean;
    readonly?: boolean;
    hasCreateNew?: boolean;
    limit: number;
    setLimit: React.Dispatch<React.SetStateAction<number>>
}

export const ViewSwitcher: FC<ViewSwitcherProps> = observer(
    ({
        name,
        label,
        value,
        options,
        loading,
        defaultIcon,
        onChange,
        onCreateNewClick,
        className,
        disabled,
        readonly,
        hasCreateNew = true,
        limit,
        setLimit,
    }) => {
        const { agencyStore, store } = useStore();
        const { fetchActiveUser } = store;
        const { activeAgencyId } = agencyStore;
        const [open, setOpen] = useState<boolean>(false);
        const [isLoading, setIsLoading] = useState<boolean>(false);
        const [noMore, setNoMore] = useState<boolean>(false);
        const [openInviteCompanyModal, setOpenInviteCompanyModal] = useState<boolean>();
        const [filteredOptions, setFilteredOptions] = useState<ViewSwitcherOptions>([]);
        const [inactiveOptions, setInactiveOptions] = useState<ViewSwitcherOptions>([]);
        const [selectedOption, setSelectedOption] = useState<ViewSwitcherOption | undefined>();
        const [searchQuery, setSearchQuery] = useState<string>('');

        const skipClick = useRef<boolean>(false);
        const switcherRef = useRef<HTMLDivElement>(null);
        const toggleRef = useRef<HTMLButtonElement>(null);
        const menuRef = useRef<HTMLUListElement>(null);
        const inputRef = useRef<HTMLInputElement>(null);

        const switcherEl = switcherRef.current;
        const toggleEl = toggleRef.current;
        const menuEl = menuRef.current;
        const inputEl = inputRef.current;

        const switcherId = `view-switcher-${name}`;
        const itemButtonClassName = `.view-switcher-menu-item-button`;

        const openDropdown = () => {
            setOpen(true);

            setTimeout(() => {
                if (inputEl) {
                    inputEl.focus();
                }
            }, 150);
        };

        const closeDropdown = (focusToggle = false) => {
            setOpen(false);

            if (focusToggle) {
                toggleEl?.focus();
            }

            setTimeout(() => {
                setSearchQuery('');

                if (menuEl) {
                    menuEl.scrollTop = 0;
                }
            }, 300);
        };

        const debouncedFn = useCallback(debounce((searchQuery) => {
            if (isLoading) return;

            let query = { type: '', search: searchQuery };
            if (name === 'companySearch') {
                query.type = 'company';
            }
            if (name === 'partnerSearch') {
                query.type = 'agency';
            }

            setIsLoading(true);
            fetchActiveUser(query).then((res) => {
                setIsLoading(false);
                if (res?.count) {
                    if (name === 'companySearch') {
                        setNoMore(!(res.count.companies > options.length))
                    }
                    if (name === 'partnerSearch') {
                        setNoMore(!(res.count.agencies > options.length))
                    }
                }
            });
        }, 300), []);

        const applySearch = (searchQuery: string) => {
            if (!searchQuery && !isLoading) {
                setLimit(() => USER_PROFILE_DEFAULT_LIMIT);
                fetchCompaniesData(true);
                return
            };

            debouncedFn(searchQuery);
        };

        const fetchCompaniesData = (initialFetch?: boolean) => {
            setIsLoading(true);

            fetchActiveUser({ skip: `${USER_PROFILE_DEFAULT_SKIP}`, limit: `${initialFetch ? USER_PROFILE_DEFAULT_LIMIT : limit + USER_PROFILE_DEFAULT_LIMIT}` }).then((res) => {
                setIsLoading(false);
                if (res?.count) {
                    if (name === 'companySearch') {
                        setNoMore(!(res.count.companies > options.length))
                    }
                    if (name === 'partnerSearch') {
                        setNoMore(!(res.count.agencies > options.length))
                    }
                }
            });
        }

        const isSelectedOption = (option: ViewSwitcherOption) => selectedOption?.id === option.id;

        const handleToggleClick = (toggle?: boolean) => {
            if (skipClick.current) {
                return;
            }

            const shouldOpen = typeof toggle !== 'undefined' ? toggle : !open;

            if (shouldOpen) {
                return openDropdown();
            }

            closeDropdown(true);
        };

        const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(event.target.value);
            applySearch(event.target.value);
        };

        const handleItemClick = (event: any, option: ViewSwitcherOption) => {
            event.preventDefault();
            const newValue = isSelectedOption(option) ? undefined : option;
            const oldValue = selectedOption;

            localStorage.setItem('activeCompany', JSON.stringify(newValue ? newValue : null));

            setSelectedOption(newValue);
            closeDropdown(true);

            if (onChange) {
                onChange(newValue, oldValue);
            }
        };

        const handleCreateNewClick: ButtonProps['onClick'] = event => {
            closeDropdown();

            if (name === 'companySearch' && activeAgencyId) {
                setOpenInviteCompanyModal(true);
            } else {
                if (onCreateNewClick) {
                    onCreateNewClick(event);
                }
            }
        };

        const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
            const target = event.target as Node;
            const key = event.key;

            if (!open || !target || !switcherEl || !menuEl || !inputEl) {
                return;
            }

            const menuItems: NodeListOf<HTMLAnchorElement> | null = menuEl.querySelectorAll(itemButtonClassName);

            // If the escape key is pressed, close the menu.
            if (key === 'Escape' || key === 'Esc') {
                return closeDropdown(true);
            }

            // If we have no menu items, or if the down or up arrow keys were not pressed, return early.
            if (!menuItems.length || (key !== 'ArrowDown' && key !== 'ArrowUp')) {
                return;
            }

            // If the event target was not the input or a menu item, return early.
            if (!inputEl.isSameNode(target) && !menuEl.contains(target)) {
                return;
            }

            event.preventDefault();

            // If the input is focused.
            if (inputEl.isSameNode(target)) {
                // If the down arrow key is pressed, focus the first list item.
                if (key === 'ArrowDown') {
                    return menuItems[0].focus();
                }

                // If the up arrow key is pressed, focus the last list item.
                if (key === 'ArrowUp') {
                    return menuItems[menuItems.length - 1].focus();
                }
            }

            // If a menu item is focused.
            if (menuEl.contains(target)) {
                const nextItem = target.parentElement?.nextElementSibling;
                const nextItemLink = nextItem?.querySelector(itemButtonClassName) as HTMLButtonElement;
                const previousItem = target.parentElement?.previousElementSibling;
                const previousItemLink = previousItem?.querySelector(itemButtonClassName) as HTMLButtonElement;

                // If the down arrow key is pressed, focus the next or first list item.
                if (key === 'ArrowDown') {
                    return nextItemLink ? nextItemLink?.focus() : menuItems[0].focus();
                }

                // If the up arrow key is pressed, focus the previous or last list item.
                if (key === 'ArrowUp') {
                    return previousItemLink ? previousItemLink?.focus() : menuItems[menuItems.length - 1].focus();
                }
            }
        };

        // TODO: Disabling blur handler for now until we can figure out a cross-browser solution. As it is,
        // in non-Chrome browsers, the `event.relatedTarget` is null, so click events on menu items in the switcher
        // is overridden by the blur event.
        //
        // const handleBlur: FocusEventHandler<HTMLDivElement> = event => {
        //     if (open && !switcherEl?.contains((event.relatedTarget as Node) || document.activeElement)) {
        //         closeDropdown();

        //         // Reference: https://github.com/szhsin/react-menu/blob/c4a4b038b20a3ddef28f3290ceea0f36f4e43694/src/components/useMenuList.js#L92-L95
        //         // If a user clicks on the menu button when a menu is open, we need to close the menu.
        //         // However, a blur event will be fired prior to the click event on menu button,
        //         // which makes the menu first close and then open again.
        //         // If this happen, e.relatedTarget is incorrectly set to null instead of the button in Safari and Firefox,
        //         // and makes it difficult to determine whether onBlur is fired because of clicking on menu button.
        //         // This is a workaround approach which sets a flag to skip a following click event.
        //         if (skipClick) {
        //             skipClick.current = true;
        //             setTimeout(() => (skipClick.current = false), 300);
        //         }
        //     }
        // };

        useEffect(() => {
            // Set/update the `selectedOption` based on the `value` prop.
            setSelectedOption(typeof value === 'number' ? options.find(({ id }) => id === value) : value);
        }, [value, options]);

        useEffect(() => {
            setFilteredOptions(options.filter(option => option.active));
            setInactiveOptions(options.filter(option => !option.active));
        }, [options]);

        useOnClickOutside(() => closeDropdown(), `#${switcherId}`);

        const handleScroll = () => {
            if (!menuRef.current || isLoading || noMore) return;

            const { scrollTop, clientHeight, scrollHeight } =
                menuRef.current;

            if (scrollTop + clientHeight >= scrollHeight && !searchQuery) {
                setLimit(prevState => prevState + USER_PROFILE_DEFAULT_LIMIT);
                fetchCompaniesData();
            }
        }

        return (
            <div
                id={switcherId}
                ref={switcherRef}
                // NOTE: See TODO for `handleBlur` function above.
                // onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                className={classNames(
                    'view-switcher',
                    {
                        'view-switcher-is-open': open,
                        'view-switcher-is-disabled': disabled,
                        'view-switcher-is-readonly': readonly,
                        'view-switcher-has-value': !!selectedOption
                    },
                    className
                )}
            >
                <ViewSwitcherToggle
                    ref={toggleRef}
                    label={label}
                    selectedOption={selectedOption}
                    defaultIcon={defaultIcon}
                    onClick={() => handleToggleClick(!open)}
                    disabled={disabled || readonly}
                />

                <div className="view-switcher-dropdown-container">
                    <nav className="view-switcher-dropdown">
                        <>
                            <div className="view-switcher-search-input">
                                <InputText
                                    inputRef={inputRef}
                                    name={name}
                                    placeholder={name === 'partnerSearch' ? 'Search Partners' : 'Search Companies'}
                                    suffix={<Icon name="search" />}
                                    value={searchQuery}
                                    onChange={handleInputChange}
                                    autoComplete="off"
                                />
                            </div>

                            {loading && <div className="view-switcher-menu-loading">Loading options...</div>}

                            {!loading && filteredOptions.length < 1 && inactiveOptions.length < 1 && (
                                <div className="view-switcher-menu-empty">No items match your query.</div>
                            )}

                            <ul className="view-switcher-menu" ref={menuRef} onScroll={handleScroll}>
                                {
                                    !loading &&
                                    filteredOptions.length > 0 &&
                                    (!loading && filteredOptions.length > 0 &&
                                        filteredOptions.map(option => (
                                            <ViewSwitcherMenuItem
                                                key={option.id}
                                                isSelected={option.id === selectedOption?.id}
                                                onClick={handleItemClick}
                                                {...option}
                                            />
                                        ))
                                    )
                                }

                                {
                                    !loading &&
                                    inactiveOptions.length > 0 &&
                                    inactiveOptions.map(option => (
                                        <ViewSwitcherMenuItem
                                            key={option.id}
                                            isSelected={option.id === selectedOption?.id}
                                            onClick={handleItemClick}
                                            {...option}
                                        />
                                    ))
                                }

                                {
                                    isLoading &&
                                    <div className="center">
                                        <CircularProgress size="24px" />
                                    </div>
                                }
                            </ul>
                        </>

                        {hasCreateNew && onCreateNewClick && (
                            <div className="view-switcher-create-new">
                                <ButtonPrimary icon={<Icon name="plus" />} onClick={handleCreateNewClick}>
                                    Create {label}
                                </ButtonPrimary>
                                {activeAgencyId && (
                                    <InviteNewCompanyModal
                                        agencyId={activeAgencyId}
                                        open={openInviteCompanyModal}
                                        setOpen={setOpenInviteCompanyModal}
                                    />
                                )}
                            </div>
                        )}
                    </nav>
                </div>
            </div>
        );
    }
);
