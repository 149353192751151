import { FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ICompanyRoleForm } from '../CompanyRoleForm';
import { InjectedProps } from '../../../types';

export interface CompanyRoleWidgetProps {
    settings: FormikProps<ICompanyRoleForm>;
    defaultSettings: any;
}

@inject('store')
@observer
export class CompanyRoleWidgetForm extends React.Component<CompanyRoleWidgetProps & Partial<InjectedProps>> {
    public get injected() {
        return this.props as InjectedProps;
    }
    public render() {
        const { calendars } = this.injected.store;
        const calendar = calendars.values[0];
        const classWidgetPosition = this.props.settings.values.widget_position
            ? `clx-${this.props.settings.values.widget_position}`
            : ``;
        const styleWidgetForm = {
            borderColor:
                this.props.settings.values.color_border_show_hide_button ||
                this.props.defaultSettings.color_border_show_hide_button,
            backgroundColor:
                this.props.settings.values.color_background_default ||
                this.props.defaultSettings.color_background_default
        };
        const errorField = {
            color: this.props.settings.values.color_error_field || this.props.defaultSettings.color_error_field
        };
        const errorMessage = {
            color: this.props.settings.values.color_error || this.props.defaultSettings.color_error
        };
        const submit = {
            backgroundColor:
                this.props.settings.values.color_background_submit_button ||
                this.props.defaultSettings.color_background_submit_button,
            color: this.props.settings.values.color_button || this.props.defaultSettings.color_button
        };
        const defaultColor = {
            color: this.props.settings.values.color_default || this.props.defaultSettings.color_default
        };

        return (
            <div
                className={`clx-app-wrap clx-calendar-container clx-container-contact ${classWidgetPosition}`}
                style={styleWidgetForm}
            >
                <div>
                    <div className="clx-header-wrap">
                        <i style={defaultColor} className="clxicon clxicon-close" id="clx-close-icon" />
                        <p style={defaultColor} id="clx-schedule-headline">
                            {calendar?.call_to_action}
                        </p>
                        <i style={defaultColor} className="clxicon clxicon-calendar" id="clx-calendar-icon" />
                    </div>
                    <div className="clx-contact-info">
                        <div>
                            <h2 className="clx-info-time-day" style={defaultColor}>
                                <i className="clxicon clxicon-previous spanvmiddle" />{' '}
                                <span className="spanvmiddle">March 27, 2019 @&nbsp;12:45pm</span>
                            </h2>
                            <div className="clx-scheduler-questions-form">
                                <form className="clx-form">
                                    <div className="clx-wrapper-label">
                                        <label>
                                            First Name<i>*</i>
                                        </label>
                                    </div>
                                    <div className="clx-scheduler-question-container">
                                        <input
                                            className="clx-scheduler-question-field-error"
                                            style={errorField}
                                            type="text"
                                            placeholder="First Name"
                                        />
                                    </div>
                                    <div className="clx-wrapper-label">
                                        <label>
                                            Last Name<i>*</i>
                                        </label>
                                    </div>
                                    <div className="clx-scheduler-question-container">
                                        <input
                                            className="clx-scheduler-question-field-error"
                                            style={errorField}
                                            type="text"
                                            placeholder="Last Name"
                                        />
                                    </div>
                                    <div className="clx-wrapper-label">
                                        <label>
                                            Phone Number<i>*</i>
                                        </label>
                                    </div>
                                    <div className="clx-scheduler-question-container">
                                        <input
                                            className="clx-scheduler-question-field-error"
                                            style={errorField}
                                            type="text"
                                            placeholder="Phone Number"
                                            value="911"
                                        />
                                    </div>
                                    <div className="clx-wrapper-label">
                                        <label>
                                            Email <i>*</i>
                                        </label>
                                    </div>
                                    <div className="clx-scheduler-question-container">
                                        <input
                                            className="clx-scheduler-question-field-error"
                                            style={errorField}
                                            type="text"
                                            placeholder="Email"
                                        />
                                    </div>
                                </form>
                                <button style={submit} className="clx-scheduler-submit-button">
                                    <span>Schedule Your demo</span>
                                </button>
                                <div style={errorMessage} className="clx-scheduler-field-error">
                                    <div>First Name is invalid</div>
                                </div>
                                <div className="clx-container-privacy">
                                    <p className="clx-link" role="link">
                                        Consumer Privacy Notice
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyRoleWidgetForm;
