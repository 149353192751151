import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import styles from './public.module.scss';
import { observer } from 'mobx-react';
import useStore from '../../store/useStore';
import { Formik, FormikProps, FormikHelpers, Form } from 'formik';
import { ButtonPrimary, InputText } from '../../components-v2/shared';
import { apiServerURL, tccLogo } from '../../constants';
import { handlePromise } from '../../util/async';
import { Alert } from 'react-bootstrap';
import { formSchemas } from '../../util/validations/formSchemas';

interface ResetPasswordFormValues {
    email: string;
}

export const ResetPasswordPage = observer(() => {
    const { store, agencyStore } = useStore();
    const [state, setState] = useState<{ sent: boolean }>({ sent: false });

    const initialValues = {
        email: ''
    };

    const handleSubmit = async (
        { email }: ResetPasswordFormValues,
        actions: FormikHelpers<ResetPasswordFormValues>
    ) => {
        actions.setSubmitting(true);
        store.clearErrors();

        const {
            agencyCustomization: { agency_id }
        } = store.agencyStore;

        // Note: we send the agency_id from agencyCustomization so the email can be customized for the agency layer.
        const [, err] = await handlePromise(store.Api.client.post('/reset-password', { email, agency_id }));

        actions.setSubmitting(false);
        if (!err) {
            setState({ sent: true });
        }
    };

    return (
        <main className={styles['public-page']}>
            {state.sent && (
                <Alert
                    className="login-alert"
                    bsStyle="success"
                    onDismiss={() => setState({ sent: false })}
                    role="alert"
                >
                    We have sent you instructions for resetting your password. Please check your email inbox.
                </Alert>
            )}
            <Card className={styles['public-card']}>
                <div className={styles['public-branding']}>
                    {agencyStore.agencyCustomization?.agency_name ? (
                        agencyStore.agencyCustomization.agency_logo ? (
                            <img
                                src={`${apiServerURL}/images/${agencyStore.agencyCustomization.agency_logo}`}
                                alt="The Conversion Cloud"
                            />
                        ) : (
                                agencyStore.agencyCustomization.agency_name
                            )
                    ) : (
                            <img src={`/images/${tccLogo}`} alt="The Conversion Cloud" />
                        )}
                </div>

                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={formSchemas.resetPasswordPage.resetPasswordValidationSchema}
                >
                    {(formikProps: FormikProps<ResetPasswordFormValues>) => (
                        <Form className={styles['public-form']}>
                            <InputText name="email" type="email" label="Email" formikProps={formikProps} />

                            {store.errors['401'] ? (
                                <div className={styles['public-form-error']}>{store.errors['401']}</div>
                            ) : null}

                            <ButtonPrimary type="submit" className="full-width" disabled={formikProps.isSubmitting}>
                                {formikProps.isSubmitting ? 'Sending...' : 'Reset Password'}
                            </ButtonPrimary>
                        </Form>
                    )}
                </Formik>
            </Card>
        </main>
    );
});
