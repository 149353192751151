import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Formik, FormikHelpers, FormikProps, FormikState } from 'formik';
import { InjectedProps } from '../../types';
import { PropertyManagementCompanyTypes } from '../../types/PropertyManagementCompany';
import ControlButtonGroup from '../misc/ControlButtonGroup';
import PropertyManagementCompanyForm, { IPropertyManagementCompany } from './PropertyManagementCompanyForm';
import { emptyPropertyManagementCompany } from '../../store/initial';
import DefaultRoutes from '../../routes/DefaultRoutes';
import {
    AppContent,
    AppHeader,
    AppPage,
} from '../../components-v2/shared';

interface PropertyManagementCompanyEditState {
    editing: boolean;
}

@inject('store')
@observer
export class PropertyManagementCompanyEdit extends React.Component<{}, PropertyManagementCompanyEditState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state = {
        editing: false
    };

    public initialValues = (managementCompany: PropertyManagementCompanyTypes) => {
        const { isLoading } = this.injected.store;
        return isLoading ? emptyPropertyManagementCompany : managementCompany;
    };

    public get id() {
        return this.injected.store.router.params.id ? parseInt(this.injected.store.router.params.id, 10) : 0;
    }

    public getManagementCompany() {
        return this.injected.store.PropertyManagementCompany.getItem(this.id);
    }

    public fetchData() {
        const { fetchPropertyManagementCompany } = this.injected.store;
        return fetchPropertyManagementCompany(this.id);
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public render() {
        const managementCompany = this.getManagementCompany();
        const initialValues = this.initialValues(managementCompany);

        if (!initialValues) {
            return null;
        }

        const onBackButtonHandler = () => {
            const { router } = this.injected.store;
            router.goTo(DefaultRoutes.PropertyManagementCompanyList, { ...router.params }, this.injected.store);
        }

        return (
            <AppPage>
                <AppHeader onBack={() => onBackButtonHandler()} title="Property Management Company">
                </AppHeader>
                <AppContent>
                    <Formik
                        initialValues={ initialValues }
                        enableReinitialize
                        onSubmit={this.handleSubmit}
                    >
                        {this.renderForm()}
                    </Formik>
                </AppContent>
            </AppPage>
        );
    }

    public renderForm = () => {
        return PropertyManagementCompanyForm({
            editing: this.state.editing,
            controls: (bag: FormikProps<IPropertyManagementCompany>) => (
                <ControlButtonGroup
                    editing={this.state.editing}
                    canEdit
                    canDelete={this.state.editing}
                    handleEdit={this.toggleEdit}
                    handleCancel={this.handleCancel(bag)}
                    handleBack={this.handleBack}
                    handleDelete={this.handleDelete}
                />
            )
        });
    };

    public toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    public handleCancel = (bag: FormikProps<IPropertyManagementCompany>) => () => {
        this.setState({ editing: false });
        bag.resetForm(bag.initialValues as Partial<FormikState<IPropertyManagementCompany>>);
    };

    public handleBack = () => {
        const { router } = this.injected.store;
        router.goTo(DefaultRoutes.PropertyManagementCompanyList, {}, this.injected.store);
    };

    public handleSubmit = (
        values: PropertyManagementCompanyTypes,
        actions: FormikHelpers<PropertyManagementCompanyTypes>
    ) => {
        actions.setSubmitting(true);
        const { PropertyManagementCompany, router } = this.injected.store;
        return PropertyManagementCompany.update(values)
            .then(() => {
                actions.setSubmitting(false);
                this.setState({ editing: false });
                router.goTo(DefaultRoutes.PropertyManagementCompanyList, { ...router.params }, this.injected.store);
            })
            .catch(error => {
                actions.setErrors(error.response.data);
            });
    };

    protected handleDelete = () => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
            this.injected.store.deletePropertyManagementCompany(this.id);
            this.handleBack();
        }
        return;
    };
}

export default PropertyManagementCompanyEdit;
