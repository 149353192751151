import { pickBy, identity } from 'lodash';

export function isAllowedToggleWidget({
    isAdminRole,
    isAgencyRole,
    editing
}: {
    isAdminRole: boolean;
    isAgencyRole: boolean;
    editing: boolean;
}): boolean {
    return (isAdminRole || isAgencyRole) && editing;
}

export function appendKeyToObjProps(input: object, append: string, keepOriginal?: boolean) {
    const output = JSON.parse(JSON.stringify(input));
    Object.keys(output).forEach(key => {
        output[`${key}${append}`] = output[key];

        if (!keepOriginal) {
            delete output[key];
        }
    });
    return output;
}

export const cloneObject = (obj: object) => JSON.parse(JSON.stringify(obj));

export const removeEmptyFromObject = (obj: object) => pickBy(obj, identity);
