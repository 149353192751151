import React, { FunctionComponent } from 'react';
import styles from './app-content.module.scss';
import classNames from 'classnames';

import { AppListLoading } from '..';

export const AppContent: FunctionComponent<{ className?: string; loading?: boolean }> = ({
    className,
    loading,
    children
}) => {
    return (
        <div className={classNames('app-content', styles['app-content'], loading ? styles['loading'] : '', className)}>
            {/* TODO: add a better loading state for app content */}
            {loading ? <AppListLoading title="Loading content" /> : children}
        </div>
    );
};
