import React, { FC } from 'react';

import { Action, ActionProps } from '../Action';

export interface RemoveActionProps extends Omit<ActionProps, 'icon'> {}

export const Remove: FC<RemoveActionProps> = props => (
    <Action
        {...props}
        icon="close"
        className="dnd-action-remove"
        active={{
            fill: 'rgba(255, 70, 70, 0.95)',
            background: 'rgba(255, 70, 70, 0.1)'
        }}
    />
);
