export const errorMessages = {
    commaSeparatedEmails : {
        valid: `This field must be a comma-separated list of valid email addresses.`
    },
    email: {
        valid: `Please enter a valid email.`,
        required: `Please enter an email.`
    },
    password: {
        length: `Please enter a longer password.`,
        required: `Please enter a password.`,
        match: `Please make sure your passwords match.`,
        confirmRequired: `Please confirm your password.`
    },
    phone: {
        required: `Please enter a phone number`
    },
    dateRange: {
        required: `Please enter a date.`,
        endAfterBegin: `The end date can't be before the start date.`
    },
    url: {
        required: 'Please enter a url including \'http://\' or \'https://\'.'
    },
};
