import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { startCase } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import { IconButton, Modal, ModalHeader } from '@lambdacurry/component-library';
import {
    AppListItem,
    AppListItemActions,
    AppListItemContent,
    AppListItemDetails,
    DescriptionList,
    DescriptionListItem,
} from '../../components-v2/shared';

import { ClarityReportRequestData, ClarityReportRequestStatus } from './Clarity.types';
import { formatClarityDateRange } from './Clarity.helpers';

export interface ClarityReportRequestProps {
    reportRequest: ClarityReportRequestData;
    handleDelete: (guid: string) => void;
}

export interface ClarityReportRequestState {
    hideDetails: boolean;
}

const ClarityReportMeta: FC<ClarityReportRequestProps> = observer(({ reportRequest, handleDelete }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const title = `${reportRequest.name || 'CLarity Report'} ${(reportRequest.report_num)}`;
    const dateRange = reportRequest.filter_explanation.dateRange;

    const creationDate = new Date(reportRequest.created_at.toString());
    const createdAt = `Created ${creationDate.toLocaleDateString()} ${creationDate
        .toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })
        .replace(' ', '')}`;

    const expirationText = (iso: string | DateTime): string => {
        const expires = DateTime.fromISO(iso.toString(), { zone: DateTime.local().zoneName });
        const daysLeft = Math.ceil(expires.diffNow(['days']).toObject().days || 0);
        return daysLeft < 4 ? `Report expires in ${daysLeft} day${daysLeft === 1 ? '' : 's'}` : '';
    };

    const listTitle = (name: string) => {
        const listTitles = {
            agency_list: 'Partners',
            company_list: 'Companies',
            region_list: 'Regions',
            management_company_list: 'Management Companies',
            ownership_group_list: 'Ownership Groups',
            business_category_list: 'Business Categories',
            dma_list: 'Designated Marketing Areas'
        };
        return listTitles[name] || 'Unknown';
    };

    return (
        <>
            <AppListItem className={`clarity-report-request clarity-report-request-status-${reportRequest.status}`}>
                <AppListItemContent
                    title={title}
                    description={formatClarityDateRange(dateRange)}
                    icon={{ name: 'analytics', color: 'secondary_color' }}
                />

                <AppListItemDetails>
                    {reportRequest.status !== ClarityReportRequestStatus.requested && (
                        <div className="clarity-report-request-created-at">{createdAt}</div>
                    )}
                    {reportRequest.status !== ClarityReportRequestStatus.ready && (
                        <div className="clarity-report-request-status-label">
                            {reportRequest.status === ClarityReportRequestStatus.requested
                                ? 'Creating report...'
                                : startCase(reportRequest.status)}
                        </div>
                    )}
                    {reportRequest.status === ClarityReportRequestStatus.ready && reportRequest.expires_at && (
                        <div className="clarity-report-request-expires-at">
                            {expirationText(reportRequest.expires_at)}
                        </div>
                    )}
                </AppListItemDetails>

                <AppListItemActions className="clarity-report-list-actions">
                    {reportRequest.status === ClarityReportRequestStatus.requested && (
                        <CircularProgress size="20px" color="inherit" className="clx-margin-right-12" />
                    )}

                    {reportRequest.status === ClarityReportRequestStatus.ready && (
                        <IconButton
                            icon="download"
                            title="Download"
                            onClick={() => window.open(reportRequest.download_url)}
                        />
                    )}

                    {reportRequest.status !== ClarityReportRequestStatus.requested && (
                        <IconButton icon="eye" title="Show Report Details" onClick={() => setModalOpen(true)} />
                    )}

                    {reportRequest.status !== ClarityReportRequestStatus.requested && (
                        <IconButton
                            icon="trash"
                            title="Delete Report"
                            onClick={() => handleDelete(reportRequest.report_guid)}
                        />
                    )}
                </AppListItemActions>
            </AppListItem>

            <Modal
                id={`report-request-details-${reportRequest.report_guid}`}
                isOpen={modalOpen}
                onAfterClose={() => setModalOpen(false)}
            >
                <ModalHeader title="Report Details" />

                {reportRequest.filter_explanation.lists.length < 1 && <>No filters used.</>}

                {reportRequest.filter_explanation.lists.length > 0 && (
                    <DescriptionList>
                        {reportRequest.filter_explanation.lists.map((list, index) => (
                            <DescriptionListItem
                                key={index}
                                label={listTitle(list.name)}
                                value={list.items.reduce((acc, curr) => [...acc, curr.name], []).join(', ')}
                            />
                        ))}
                    </DescriptionList>
                )}
            </Modal>
        </>
    );
});

export default ClarityReportMeta;
