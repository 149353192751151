import { createContext, useContext } from 'react';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import Store from '../../../store';

export const getSupportUrl = (store: Store): string => {
    const { activeUser, isAdminRole, isAgencyRole, agencies } = store;

    const brandedSupport = `https://help.theconversioncloud.com/?login=1`;
    const unbrandedSupport = `http://agencysupport.conversioncloud.happyfox.com/home/`;
    const currentCompany = (activeUser?.companies || [])[0];
    const currentCompanyAgencyId = currentCompany?.agency_id;
    const isInternalAgency = currentCompanyAgencyId
        ? agencies.values.find(agency => agency.id === currentCompanyAgencyId)?.internal
        : false;
    const CLXAgencyIds = [1, 2];
    const url =
        isAdminRole ||
        isAgencyRole ||
        !currentCompanyAgencyId ||
        CLXAgencyIds.includes(currentCompanyAgencyId || -1) ||
        isInternalAgency
            ? brandedSupport
            : unbrandedSupport;

    return url;
};

export const goToAgency = (id: number, store: Store) =>
    store.router.goTo(DefaultRoutes.AgencyDashboard, { agencyId: id }, store);

export const goToCompany = (id: number, store: Store) =>
    store.router.goTo(DefaultRoutes.CompanyDashboard, { companyId: id }, store);

export const goToHome = (store: Store) => store.router.goTo(DefaultRoutes.Home, {}, store);

export interface SideNavMenuContextType {
    submenuOpen: string | null;
    setSubmenuOpen: (name: string | null) => void;
}

export const SideNavMenuContext = createContext({} as SideNavMenuContextType);

export const useSideNavMenuContext = () => useContext<SideNavMenuContextType>(SideNavMenuContext);
