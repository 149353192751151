import React, { FunctionComponent } from 'react';
import { get as _get } from 'lodash';
import { InputText } from '../../../components-v2/shared';
import { EditEmailSectionProps } from './EditEmailSectionForm';

export const EditEmailHeadlineForm: FunctionComponent<EditEmailSectionProps> = ({ section, formikProps }) => {
    return (
        <InputText id="headlineText" label="Headline" name={`${section.id}.textContent`} formikProps={formikProps} />
    );
};
