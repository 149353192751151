import React, { Dispatch, FC } from 'react';
import { ButtonPrimary, Icon, Modal, ModalActions, ModalHeader } from '@lambdacurry/component-library';
import { ClarityReducerAction, ClarityReducerState } from '../Clarity.helpers';
import { ActionList } from '../../../components-v2/shared';

export interface ClarityCreateReportFailedModalProps {
    state: ClarityReducerState;
    dispatch: Dispatch<ClarityReducerAction>;
}

export const ClarityCreateReportFailedModal: FC<ClarityCreateReportFailedModalProps> = ({ state, dispatch }) => {
    const { createReportFailedModal } = state;
    const { open } = createReportFailedModal;

    const handleCloseClick = () => dispatch({ name: 'setCreateReportFailedModalOpen', payload: false });

    return (
        <Modal isOpen={open}>
            <ModalHeader
                title={
                    <>
                        <Icon name="error" className="icon color-danger" />
                        Report creation failed
                    </>
                }
            />
            <div>We are unable to create the report at this time. Please contact support for assistance.</div>
            <ModalActions>
                <ActionList position="end">
                    <ButtonPrimary onClick={handleCloseClick}>Close</ButtonPrimary>
                </ActionList>
            </ModalActions>
        </Modal>
    );
};
