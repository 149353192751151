import React, { Reducer, useReducer } from 'react';
import { observer } from 'mobx-react';
import {
    AppHeader,
    AppPage,
    AppContent,
    IconButton,
    Form,
    AppFooter,
    ButtonPrimary,
    Button
} from '../../components-v2/shared';

import useStore from '../../store/useStore';
import styles from './schedule-editor.module.scss';
import { handlePromise, useAsyncEffect } from '../../util/async';
import {
    ScheduleEditorEditScheduleReducerAction,
    ScheduleEditorEditScheduleReducerState,
    scheduleEditorEditScheduleReducer
} from './ScheduleEditor.helpers';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { FormikHelpers, FormikProps } from 'formik';
import { AvailabilitySchedule, TimeRange } from './ScheduleEditor.types';
import { EditScheduleNameForm } from './EditScheduleNameForm';
import { EditScheduleWeek } from './EditScheduleWeek';
import { EditScheduleTimeOff } from './EditScheduleTimeOff';

export interface EditScheduleForm {
    monday: TimeRange[];
    tuesday: TimeRange[];
    wednesday: TimeRange[];
    thursday: TimeRange[];
    friday: TimeRange[];
    saturday: TimeRange[];
    sunday: TimeRange[];
}

export const ScheduleEditorEditSchedulePage = observer(() => {
    const { store } = useStore();
    const { router, Api, activeCompany } = store;
    const { companyId, scheduleId } = router.params;

    const [state, dispatch] = useReducer<
        Reducer<ScheduleEditorEditScheduleReducerState, ScheduleEditorEditScheduleReducerAction>
    >(scheduleEditorEditScheduleReducer, {
        modal: {
            active: 'none',
            data: {}
        },
        schedule: {}
    });
    const {
        isEditingName,
        schedule
        // modal
    } = state;

    const fetchSchedule = async () => {
        const [response, err] = await handlePromise<{ data: AvailabilitySchedule }>(
            Api.client.get(`company/${companyId}/availability-schedules/${scheduleId}`)
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setSchedule', payload: response.data });
    };

    useAsyncEffect(fetchSchedule);

    const handleEditScheduleName: (
        values: { name: string },
        formikHelpers: FormikHelpers<{ name: string }>
    ) => void = async values => {
        const { usedBy, company_id, ...updatedSchedule } = schedule;
        const [response, err] = await handlePromise<{ data: AvailabilitySchedule }>(
            Api.client.patch(`company/${companyId}/availability-schedules`, { ...updatedSchedule, name: values.name })
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setIsEditingName', payload: false });
        dispatch({ name: 'setSchedule', payload: response.data });
    };

    const handleFormSubmit: (values: EditScheduleForm, formikHelpers: FormikHelpers<EditScheduleForm>) => void = async (
        values,
        formikHelpers
    ) => {
        const { usedBy, company_id, ...latestSchedule } = schedule;
        const [response, err] = await handlePromise<{ data: AvailabilitySchedule }>(
            Api.client.patch(`company/${companyId}/availability-schedules`, {
                ...latestSchedule,
                ...values,
                monday: values.monday || [],
                tuesday: values.tuesday || [],
                wednesday: values.wednesday || [],
                thursday: values.thursday || [],
                friday: values.friday || [],
                saturday: values.saturday || [],
                sunday: values.sunday || []
            })
        );

        formikHelpers.setSubmitting(false);

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setSchedule', payload: response.data });
    };

    return (
        <AppPage loading={!schedule.name}>
            <AppHeader
                title={!isEditingName ? schedule.name : ''}
                onBack={
                    !isEditingName
                        ? () => router.goTo(DefaultRoutes.ScheduleEditorListPage, { companyId }, store)
                        : undefined
                }
            >
                {isEditingName ? (
                    <EditScheduleNameForm
                        name={schedule.name}
                        onSubmit={handleEditScheduleName}
                        onCancel={() => dispatch({ name: 'setIsEditingName', payload: false })}
                    />
                ) : (
                    <IconButton
                        className={styles['schedule-editor-edit-name-button']}
                        icon="pencil"
                        onClick={() => dispatch({ name: 'setIsEditingName', payload: true })}
                    />
                )}
            </AppHeader>
            <Form
                enableReinitialize
                confirmUnsavedChanges
                unsavedChangesConfig={{
                    containerQuerySelectorAll: `[class*="app-header "] ~ *, #editTimeOffModal, #deleteTimeOffModal`
                }}
                initialValues={{
                    monday: schedule.monday,
                    tuesday: schedule.tuesday,
                    wednesday: schedule.wednesday,
                    thursday: schedule.thursday,
                    friday: schedule.friday,
                    saturday: schedule.saturday,
                    sunday: schedule.sunday
                }}
                onSubmit={handleFormSubmit}
            >
                {(formikProps: FormikProps<EditScheduleForm>) => {
                    return (
                        <>
                            <AppContent loading={!schedule} className={styles['schedule-editor-content']}>
                                <EditScheduleWeek
                                    className={styles['schedule-editor-week']}
                                    activeCompany={activeCompany}
                                />
                                <EditScheduleTimeOff className={styles['schedule-editor-time-off']} />
                            </AppContent>
                            <AppFooter className={styles['schedule-editor-footer']} sticky={true}>
                                <div className="flex-spacer" />
                                {formikProps.dirty && formikProps.isValid && (
                                    <Button
                                        onClick={() => formikProps.resetForm()}
                                        className={styles['schedule-editor-footer-cancel']}
                                        disabled={formikProps.isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary
                                    type="submit"
                                    disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}
                                >
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </AppFooter>
                        </>
                    );
                }}
            </Form>
        </AppPage>
    );
});
