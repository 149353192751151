import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputSelect,
    ButtonOutlinePrimary
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, RulesActionFactory, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { RuleAction } from '../../../../types';
export interface YardiSoapFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface YardiSoapCrm extends Crm {
    configuration: {
        source_name: string;
        source_type: string;
        soap_endpoint: string;
        db_username: string;
        db_password: string;
        db_name: string;
        db_server: string;
        property_code: string;
        initial_activity_type: string;
    };
}

const ruleActions: Array<RuleAction> = [
    RulesActionFactory.ReplaceStringAction('source_name', 'Replace Lead Source Name with new value')
];

export const YardiSoapForm: FC<YardiSoapFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<YardiSoapCrm>) => {
        await onValidate(formikProps.values, crmConfigTypes.yardi_soap);
    };

    const handleSubmit = async (values: YardiSoapCrm, actions: FormikHelpers<YardiSoapCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.yardi_soap);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<YardiSoapCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Yardi Senior Housing" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader title="Lead Parameters" />
                                <InputText
                                    name="configuration.source_name"
                                    label="Lead Source Name"
                                    helperText="Yardi Senior Housing Lead Source Name"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.initial_activity_type"
                                    label="Initial Activity Type"
                                    helperText="Yardi Senior Housing Initial Activity Type"
                                    required={false}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <InputSelect
                                    label="Lead Source Type"
                                    name="configuration.source_type"
                                    options={[{ val: 's', label: 'Senior Living' }]}
                                    optionValueKey="val"
                                    optionLabelKey="label"
                                    helperText="Yardi Senior Housing Lead Source Type"
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                                <InputText
                                    name="configuration.property_code"
                                    label="Property Code"
                                    helperText="Yardi Senior Housing Property Code"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader
                                    title="Connection Settings"
                                    subtitle="Contact Yardi Senior Housing support for your exact connection settings"
                                />
                                <InputText
                                    name="configuration.soap_endpoint"
                                    label="API Endpoint"
                                    helperText="Yardi Senior Housing API Endpoint"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.db_server"
                                    label="Database Server"
                                    helperText="Yardi Senior Housing Database Server"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.db_name"
                                    label="Database Name"
                                    helperText="Yardi Senior Housing Database Name"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.db_username"
                                    label="Database Username"
                                    helperText="Yardi Senior Housing Database Username"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.db_password"
                                    label="Database Password"
                                    helperText="Yardi Senior Housing Database Password"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
