import { Formik, FormikHelpers, FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import Store from '../../store';
import { emptyCategory } from '../../store/initial';
import { InjectedProps } from '../../types';
import { Category } from '../../types/Category';
import ControlButtonSave from '../misc/ControlButtonSave';
import CategoryForm, { ICategoryForm } from './CategoryForm';

interface CategoryNewProps {
    handleClose: (category: Category) => any;
    store?: Store;
}

@inject('store')
@observer
export class CategoryNew extends React.Component<CategoryNewProps> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public initialValues = () => ({ ...emptyCategory });

    public render() {
        return (
            <>
                <Col>
                    <Formik
                        initialValues={this.initialValues()}
                        enableReinitialize
                        onSubmit={this.handleSubmit}
                    >{this.renderForm()}</Formik>
                </Col>
            </>
        );
    }

    public renderForm = () => {
        return CategoryForm({
            editing: true,
            controls: (bag: FormikProps<ICategoryForm>) => <ControlButtonSave />
        });
    };

    public handleSubmit = (values: any, actions: FormikHelpers<any>) => {
        const { businessCategory } = this.injected.store;
        const { activeAgencyId } = this.injected.store.agencyStore;
        const { handleClose } = this.props;

        // Note: if we are in the context of an Agency, we should create business categories for that Agency
        if (activeAgencyId) {
            values.agency_id = activeAgencyId;
        }

        return businessCategory
            .create(values)
            .then(() => {
                actions.resetForm();
                handleClose(values);
                actions.setSubmitting(false);
            })
            .catch(error => actions.setErrors(error.response.data));
    };
}

export default CategoryNew;
