import React, { FC, ReactNode, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Icon } from '@lambdacurry/component-library';

export interface ListGroupItemProps extends HTMLAttributes<HTMLLIElement> {
    icon?: {
        name: string;
        color?: 'blue' | 'green';
    };
    label?: ReactNode;
    value?: ReactNode;
    isDisabled?: boolean;
}

export const ListGroupItem: FC<ListGroupItemProps> = ({
    className,
    icon,
    label,
    value,
    isDisabled,
    children,
    ...props
}) => (
    <li
        className={classNames('lc-list-group__item', { [`lc-list-group__item--disabled`]: isDisabled }, className)}
        {...props}
    >
        {icon && (
            <div
                className={classNames(
                    'lc-list-group__item__icon',
                    `lc-list-group__item__icon--${icon.color || 'blue'}`
                )}
            >
                <Icon name={icon.name} />
            </div>
        )}
        <div className="lc-list-group__item__content">
            {label && <div className="lc-list-group__item__label">{label}</div>}
            <div className="lc-list-group__item__value">{value || children}</div>
        </div>
    </li>
);
