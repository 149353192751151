import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import './app-card.scss';
import { AppCardHelp } from './AppCardHelp';

export interface AppCardProps extends HTMLAttributes<HTMLDivElement> {
    helpText?: string;
}

export const AppCard: FC<AppCardProps> = ({ className, helpText, children, ...props }) => (
    <div className={classNames('app-card', className)} {...props}>
        {children}
        {helpText && <AppCardHelp helpText={helpText} />}
    </div>
);
