import React, { FC, MouseEventHandler, ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    AliasIconNames,
    ButtonProps,
    ButtonUnstyled,
    DefaultIconNames,
    Icon,
    IconButton
} from '@lambdacurry/component-library';
import classNames from 'classnames';
import { SideNavSubmenu } from './SideNavSubmenu';
import { SideNavSubmenuItem } from './SideNavSubmenuItem';
import useStore from '../../../store/useStore';
import { CLXIconNames } from '../../Bengal/IconRegistry';
import { useSideNavMenuContext } from './SideNav.helpers';

export interface SideNavMenuItem {
    label?: string;
    active?: boolean;
    onClick?: ButtonProps['onClick'];
    route?: any;
    hidden?: boolean;
}

export interface SideNavMenuItemProps extends SideNavMenuItem {
    name: string;
    icon: DefaultIconNames | AliasIconNames | CLXIconNames;
    className?: string;
    onClick?: ButtonProps['onClick'];
    submenu?: { items: SideNavMenuItem[] };
    notificationCount?: number;
    children?: ReactNode;
}

export const SideNavMenuItem: FC<SideNavMenuItemProps> = observer(
    ({ name, label, icon, hidden, submenu, onClick, children, route, notificationCount }) => {
        const { submenuOpen, setSubmenuOpen } = useSideNavMenuContext();
        const { store } = useStore();
        const { router } = store;
        const { goTo, params, currentView } = router;

        const isOpen = submenuOpen === name;
        const showNotificationCount = !!notificationCount;

        const isActiveRoute = (): boolean => {
            if (!route || !currentView) {
                return false;
            }

            if (route.displayName === currentView.displayName) {
                return true;
            }

            if (route.originalPath === currentView.originalPath) {
                return true;
            }

            if (route.originalPath === '/') {
                return false;
            }

            if (
                currentView.originalPath.includes(route.originalPath) &&
                // NOTE: Add check against `endsWith` to allow routes with similar paths
                // to be nested within other routes without incorrectly applying active state to items
                !currentView.originalPath.endsWith(route.originalPath)
            ) {
                return true;
            }

            if (submenu?.items.find(item => item.route?.displayName === currentView.displayName)) {
                return true;
            }

            return false;
        };

        const handleToggleClick: MouseEventHandler<any> = event => setSubmenuOpen(submenuOpen === name ? null : name);

        const handleClick: ButtonProps['onClick'] = event => {
            if (route) {
                goTo(route, params, store);
            }

            if (onClick) {
                onClick(event);
            }
        };

        const setMenuOpen = () => {
            if (isOpen) {
                return;
            }

            if (isActiveRoute()) {
                return setSubmenuOpen(name);
            }
        };

        useEffect(() => {
            setMenuOpen();
        }, [currentView]);

        if (hidden) {
            return null;
        }

        const displayName = label || route?.displayName;

        return (
            <li
                className={classNames('side-nav-menu-item', {
                    'side-nav-menu-item-is-open': isOpen,
                    'side-nav-menu-item-is-active': isActiveRoute()
                })}
            >
                <ButtonUnstyled className="side-nav-menu-item-button" onClick={handleClick} data-test-id={displayName + '_menuItemButton'}>
                    <Icon name={icon} className="side-nav-menu-item-icon" />
                    <span className="side-nav-menu-item-label">{displayName}</span>
                    {showNotificationCount && <span className="side-nav-menu-item-count">{notificationCount}</span>}
                </ButtonUnstyled>

                {((submenu?.items && submenu?.items.length > 0) || children) && (
                    <IconButton icon="chevronDown" className="side-nav-menu-item-caret" onClick={handleToggleClick} />
                )}

                {isOpen && (
                    <>
                        {children}

                        {submenu?.items && submenu?.items.length > 0 && (
                            <SideNavSubmenu>
                                {submenu?.items.map((item, index) => (
                                    <SideNavSubmenuItem key={index} {...item} />
                                ))}
                            </SideNavSubmenu>
                        )}
                    </>
                )}
            </li>
        );
    }
);
