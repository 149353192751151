import { observer } from 'mobx-react';
import React, { useReducer, useEffect } from 'react';
import {
    ActionList,
    AppFooter,
    AppHeader,
    AppSection,
    Form,
    InputSwitch,
    S3FileUploader,
    useSnackbar
} from '../../components-v2/shared';
import { DateTime } from 'luxon';
import useStore from '../../store/useStore';
import { initialFormValues } from '../ApplicationSettings/ApplicationSettings.helpers';
import { CompanyDetailsMembers } from './CompanyDetailsMembers';
import {
    companyDetailsFormSchema,
    CompanyDetailsFormValues,
    CompanyDetailsReducer,
    EnablingSMSStatus,
    getCompanyLogoPreview
} from './CompanyDetailsForm.helpers';
import {
    Button,
    ButtonPrimary,
    handlePromise,
    InputDate,
    Modal,
    ModalActions,
    ModalHeader,
    useAsyncEffect
} from '@lambdacurry/component-library';
import { CompanyDetailsSMSNumber } from './CompanyDetailsSMSNumber';
import { CompanyDetailsAdminSettings } from './CompanyDetailsAdminSettings';
import { CompanyDetailsNotificationSettings } from './CompanyDetailsNotificationSettings';
import { CompanyDetailsSettings } from './CompanyDetailsSettings';
import './company-details.scss';
import { isNil, omitBy } from 'lodash';
import { FormikHelpers, FormikProps, isString } from 'formik';
import { unsavedChangesContainerSelectorDefault } from '../../constants';
import { EmbedTab } from '../ApplicationSettings/AdvancedSettings/Tabs';
import { ApplicationSettingsFormValues } from '../ApplicationSettings/ApplicationSettings.helpers';
import { PublicRole } from '../../types';

export const CompanyDetailsForm: React.FC<{
    title: string;
    onSubmit: (
        values: CompanyDetailsFormValues,
        formikHelpers: FormikHelpers<CompanyDetailsFormValues>,
        isActive?: boolean
    ) => void;
}> = observer(({ title, onSubmit }) => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const {
        activeCompany,
        isAdminRole,
        isSuperAdminRole,
        isAgencyRole,
        activeUser,
        Api,
        getCompanyFeatures,
        saveCompanyFeatures,
        twilioNumbers,
        fetchTwilioNumbers,
        refreshCompany
    } = store;
    const [state, dispatch] = useReducer(CompanyDetailsReducer, {
        initialCompany: activeCompany,
        fileUploading: false,
        enablingSMSStatus: EnablingSMSStatus.LOADING,
        endTrialEarlyModal: false,
        trialDismissed: false,
        isCompanyActive: activeCompany?.active ?? true
    });

    const isCreateCompanyForm = title === 'Add Company';

    const fetchData = async () => {
        window.scrollTo(0, 0);
        if (isCreateCompanyForm) {
            return;
        }

        fetchTwilioNumbers();
        dispatch({ name: 'setEnablingSMSStatus', payload: EnablingSMSStatus.WAITING });
    };

    useAsyncEffect(fetchData);

    // Check if Twilio Numbers has loaded
    useEffect(() => {
        if (!twilioNumbers.values) {
            return;
        }

        if (state.enablingSMSStatus === EnablingSMSStatus.LOADING) {
            dispatch({ name: 'setEnablingSMSStatus', payload: EnablingSMSStatus.WAITING });
        }
    }, [twilioNumbers.dataSize]);

    const formatDateInput = (input?: string | null | Date): Date | null => {
        if (!input) {
            return null;
        }
        if (isString(input)) {
            return DateTime.fromISO(input).startOf('day').toJSDate();
        }

        try {
            return DateTime.fromJSDate(input as Date).startOf('day').toJSDate();
        } catch (e) {
            return null;
        }
    }

    const getInitialValues = (): ApplicationSettingsFormValues => {
        const { publicRoles } = store;
        const firstItemId = publicRoles.length > 0 ? publicRoles.getFirstItemId() : 0;
        const publicRole: PublicRole | {} = firstItemId === 0 ? {} : publicRoles.getItem(firstItemId);

        // `omitBy(publicRole, isNil)` removes nullish properties so we can receive the default values defined by `initialFormValues`
        return { ...initialFormValues, ...omitBy(publicRole, isNil) };
    };

    // Note we might need to determine whether or not this form is a create or edit form to determine what the initial values should be
    const initialValues: CompanyDetailsFormValues = isCreateCompanyForm
        ? {
            active: true,
            name: '',
            logo: '',
            logo_file: [],
            agency_id: undefined,
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            time_zone: '',
            website_url: '',
            dma: 0,
            business_category_id: 0,
            is_test: false,
            is_universal: false,
            weekly_notification: false,
            weekly_lead_notification_email: '',
            lead_notification_primary_emails: '',
            lead_notification_cc_emails: '',
            reply_to_email: '',
            phone: '',
            crm_config_id: null,
            ownership_group_id: null,
            property_management_company_id: null,
            region_id: null,
            enquire_portal_id: undefined,
            assigned_users: undefined,
            expiration_date: null
        }
        : { ...activeCompany, logo_file: getCompanyLogoPreview(activeCompany), expiration_date: formatDateInput(activeCompany?.expiration_date) };

    const enableSMS = async () => {
        dispatch({ name: 'setEnablingSMSStatus', payload: EnablingSMSStatus.ENABLING });

        const [response, error] = await handlePromise(
            Api.client.post(`/twilio-numbers/${activeCompany.id}/auto-assign`)
        );

        if (!response || error) {
            addSnackbar(`An error occurred when trying to provision your number.`, {
                variant: 'error'
            });
            dispatch({ name: 'setEnablingSMSStatus', payload: EnablingSMSStatus.ERROR });
            return console.error(`An error occurred when trying to provision your number.`, error);
        }

        getCompanyFeatures().then(async ({ data: features }) => {
            const currentFeatures = Object.entries(features)
                .map(([key, value]) => (value ? key : ''))
                .filter(key => key !== '');

            await saveCompanyFeatures([...currentFeatures, 'sms_messaging']);
            fetchTwilioNumbers();
        });

        dispatch({ name: 'setEnablingSMSStatus', payload: EnablingSMSStatus.DONE });
    };

    const trialEndDate = DateTime.fromISO(activeCompany?.trial_end_date || '');
    const trialHasExpired =
        trialEndDate.startOf('day').toSeconds() <=
        DateTime.local()
            .startOf('day')
            .toSeconds();
    const shouldShowTrialEnding =
        (isAdminRole || isAgencyRole) && trialEndDate.isValid && !trialHasExpired && !state.trialDismissed;

    const endTrialEarly = async () => {
        const [response, error] = await handlePromise(
            Api.client.post(`/companies/${activeCompany.id}/endTrial`, { user: activeUser })
        );

        if (!response || error) {
            console.error('Error ending trial early');
        }

        // Note: Once the trial is ended we want to dismiss the alert and fetch companies to show the trial ended banner
        dispatch({ name: 'dismissTrial' });
        refreshCompany(activeCompany.id);
    };

    const toggleActiveCompany = async (formikProps: FormikProps<CompanyDetailsFormValues>) => {
        try {
            const validate = await formikProps.validateForm()
            const errors = Object.keys(validate)?.length;
            if (!errors) {
                onSubmit(formikProps.values, formikProps, state.isCompanyActive);
                dispatch({ name: 'toggleIsCompanyActive' });
                addSnackbar(`The company has been ${!state.isCompanyActive ? 'deactivated' : 'activated'}.`, {
                    variant: 'success'
                });
            }
            formikProps.submitForm();
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to ${!state.isCompanyActive ? 'deactivate' : 'activate'} the company.`, {
                variant: 'error'
            });
        }
    };

    return (
        <div className="company-details">
            <Form
                confirmUnsavedChanges
                initialValues={initialValues}
                validationSchema={companyDetailsFormSchema}
                onSubmit={onSubmit}
                unsavedChangesConfig={{
                    containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
                }}
            >
                {(formikProps: FormikProps<CompanyDetailsFormValues>) => (
                    <>
                        <AppHeader title={title}>
                            <ActionList position="end">
                                {isCreateCompanyForm && (
                                    <InputSwitch
                                        name="active"
                                        labelOn="Active"
                                        labelOff="Inactive"
                                        labelPlacement="start"
                                        formikProps={formikProps}
                                    />
                                )}

                                {!isCreateCompanyForm && (isAdminRole || isAgencyRole) && (
                                    <InputSwitch
                                        name="active"
                                        labelOn="Active"
                                        labelOff="Inactive"
                                        labelPlacement="start"
                                        onClick={() => toggleActiveCompany(formikProps)}
                                        checked={state.isCompanyActive}
                                    />
                                )}
                                <InputDate unselectable='on' disabled={(!isSuperAdminRole)} label="Expiration Date" name="expiration_date" disablePast={true} formikProps={formikProps} style={{ top: 12 }} />
                            </ActionList>
                        </AppHeader>

                        {shouldShowTrialEnding && (
                            <>
                                <div className="company-details-trial">
                                    <p>
                                        Company trial is ending in{' '}
                                        {Math.ceil(trialEndDate.diffNow(['days']).toObject().days || 0)} days.
                                    </p>
                                    <ButtonPrimary onClick={() => dispatch({ name: 'toggleEndTrialModal' })}>
                                        End Trial Early
                                    </ButtonPrimary>
                                </div>

                                <Modal
                                    isOpen={state.endTrialEarlyModal}
                                    onAfterClose={() => dispatch({ name: 'toggleEndTrialModal' })}
                                >
                                    <ModalHeader title="Are you sure you want to end the 30 day trial early?" />
                                    <ModalActions>
                                        <ActionList position="end">
                                            <Button onClick={() => dispatch({ name: 'toggleEndTrialModal' })}>
                                                Cancel
                                            </Button>
                                            <ButtonPrimary onClick={() => endTrialEarly()}>End Trial</ButtonPrimary>
                                        </ActionList>
                                    </ModalActions>
                                </Modal>
                            </>
                        )}
                        <AppSection className="field-group">
                            <div className="field-group-content">
                                <div>
                                    <CompanyDetailsSettings formikProps={formikProps} />
                                </div>
                                {formikProps.values.is_universal ? (
                                    <Form
                                        confirmUnsavedChanges
                                        initialValues={getInitialValues()}
                                        onSubmit={() => { }}
                                        validateOnMount
                                    >

                                        {(appFormikProps: FormikProps<ApplicationSettingsFormValues>) =>
                                        (
                                            <>
                                                <EmbedTab formikProps={appFormikProps} />
                                            </>
                                        )
                                        }

                                    </Form>
                                )
                                    : (
                                        <div>
                                            <S3FileUploader
                                                name="logo_file"
                                                application="company"
                                                formikProps={formikProps}
                                                fileUploaderProps={{
                                                    accept: ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'],
                                                    onDrop: () => dispatch({ name: 'setFileUploading', payload: true }),
                                                    onClearAll: () => {
                                                        formikProps.setFieldValue('logo', '');
                                                        dispatch({ name: 'setFileUploading', payload: false });
                                                    },
                                                    onRemoveFile: () => {
                                                        formikProps.setFieldValue('logo', '');
                                                        dispatch({ name: 'setFileUploading', payload: false });
                                                    }
                                                }}
                                                imageCropperProps={{
                                                    onSave: (fileUrl: string) => {
                                                        const fileName = fileUrl.split('/').pop();
                                                        formikProps.setFieldValue('logo', fileName);
                                                        dispatch({ name: 'setFileUploading', payload: false });
                                                    },
                                                    onCancel: () => dispatch({ name: 'setFileUploading', payload: false })
                                                }}
                                            />
                                        </div>
                                    )}
                            </div>
                        </AppSection>
                        {!formikProps.values.is_universal && (
                            <AppSection className="field-group">
                                <div className="field-group-content">
                                    <div>
                                        <CompanyDetailsNotificationSettings formikProps={formikProps} />
                                    </div>
                                    <div>
                                        <CompanyDetailsSMSNumber
                                            isCreateCompanyForm={isCreateCompanyForm}
                                            state={state}
                                            twilioNumbers={twilioNumbers}
                                            enableSMS={enableSMS}
                                        />
                                    </div>
                                </div>
                            </AppSection>
                        )}



                        {(isAdminRole || isAgencyRole) && <CompanyDetailsAdminSettings formikProps={formikProps} />}

                        <CompanyDetailsMembers
                            state={state}
                            dispatch={dispatch}
                            formikProps={formikProps}
                            isCreateCompanyForm={isCreateCompanyForm}
                        />

                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button
                                        onClick={() => {
                                            formikProps.resetForm();
                                            dispatch({
                                                name: 'resetCompanyMembers'
                                            });
                                        }}
                                        disabled={state.fileUploading || formikProps.isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary
                                    type="submit"
                                    disabled={state.fileUploading || !formikProps.dirty || formikProps.isSubmitting}
                                >
                                    {isCreateCompanyForm
                                        ? formikProps.isSubmitting
                                            ? 'Adding...'
                                            : 'Add Company'
                                        : formikProps.isSubmitting
                                            ? 'Saving...'
                                            : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                )}
            </Form>
        </div>
    );
});
