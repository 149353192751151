import React, { FC, HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { DecorativeIcon, DecorativeIconProps } from '@lambdacurry/component-library';
import { ActionList, ActionListProps } from '../../action-list';
import { Title, Header } from '../..';

import './app-list.scss';
import { CircularProgress } from '@material-ui/core';

interface AppListProps extends HTMLAttributes<HTMLElement> {}

interface AppListHeaderProps extends HTMLAttributes<HTMLElement> {}

interface AppListItemProps extends HTMLAttributes<HTMLElement> {}

interface AppListItemIconProps extends DecorativeIconProps {}

export interface AppListEmptyProps extends Omit<HTMLAttributes<HTMLElement>, 'title'> {
    illustration?: ReactNode;
    title: ReactNode;
    description?: ReactNode;
    action?: ReactNode;
}

export interface AppListItemContentProps extends Omit<HTMLAttributes<HTMLElement>, 'title'> {
    title: string | ReactNode;
    description?: string | ReactNode;
    icon?: DecorativeIconProps;
}

export interface AppListItemDetailsProps extends HTMLAttributes<HTMLElement> {}

export interface AppListItemActionsProps extends ActionListProps {}

export const AppList: FC<AppListProps> = ({ className, ...props }) => (
    <div className={classNames('app-list', className)} {...props} />
);

export const AppListHeader: FC<AppListHeaderProps> = ({ className, ...props }) => (
    <div className={classNames('app-list-header', className)} {...props} />
);

export const AppListItem: FC<AppListItemProps> = ({ className, ...props }) => (
    <div className={classNames('app-list-item', className)} {...props} />
);

export const AppListItemIcon: FC<AppListItemIconProps> = ({ className, ...props }) => (
    <DecorativeIcon className={classNames('app-list-item-icon', className)} {...props} />
);

export const AppListItemContent: FC<AppListItemContentProps> = ({ className, title, description, icon, ...props }) => (
    <div className={classNames('app-list-item-content', className)} {...props}>
        {icon && <AppListItemIcon {...icon} />}
        <div>
            <Header className="app-list-item-title">{title}</Header>
            {description && <div className="app-list-item-description">{description}</div>}
        </div>
    </div>
);

export const AppListItemDetails: FC<AppListItemDetailsProps> = ({ className, children, ...props }) => (
    <div className={classNames('app-list-item-details', className)} {...props}>
        <div>{children}</div>
    </div>
);

export const AppListItemActions: FC<AppListItemActionsProps> = ({ className, children, ...props }) => (
    <div className={classNames('app-list-item-actions', className)} {...props}>
        <ActionList position="end">{children}</ActionList>
    </div>
);

export const AppListEmpty: FC<AppListEmptyProps> = ({
    className,
    illustration,
    title,
    description,
    action,
    ...props
}) => (
    <div className={classNames('app-list-empty', className)} {...props}>
        {illustration && <div className="app-list-empty-illustration">{illustration}</div>}
        <Title className="app-list-empty-title">{title}</Title>
        {description && <p className="app-list-empty-description">{description}</p>}
        {action && <div className="app-list-empty-action">{action}</div>}
    </div>
);

export const AppListLoading: FC<Omit<AppListEmptyProps, 'illustration'>> = ({
    className,
    description = 'This may take a moment.',
    ...props
}) => (
    <AppListEmpty
        className={classNames('app-list-loading', className)}
        illustration={<CircularProgress size={48} />}
        description={description}
        {...props}
    />
);
