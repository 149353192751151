import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

export interface CheckboxListItemProps extends HTMLAttributes<HTMLLIElement> {}

export const CheckboxListItem: FC<CheckboxListItemProps> = ({ className, children, ...props }) => (
    <li className={classNames('lc-checkbox-list-item', className)} {...props}>
        {children}
    </li>
);
