import React, { FC } from 'react';
import { InputSwitch, useSnackbar } from '../../../components-v2/shared';
import Resource from '../../../store/Resource';
import useStore from '../../../store/useStore';
import { handlePromise } from '../../../util/async';
import { Chatbot } from '../../../types/Chatbot';
import { AppsName } from '../../../types';

export interface ToggleChatbotSwitchProps {
    chatbot: Chatbot;
    chatbots: Resource<Chatbot>;
    index?: Number;
}

export const ToggleChatbotSwitch: FC<ToggleChatbotSwitchProps> = ({ chatbot, chatbots, index }) => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { activeCompanyId, Api } = store;
    const handleClick = async (e: any) => {
        try {
            await chatbots.update({ ...chatbot, active: !chatbot?.active });
            addSnackbar(`Virtual Assistant has been ${chatbot.active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });

            const [response, error] = await handlePromise(
                Api.client.patch(`public_roles/updateActiveModules`, {
                    toggleType: chatbot.active ? 'remove' : 'add',
                    company_id: activeCompanyId,
                    moduleName: AppsName.CB
                })
            );

            if (!response?.data || error) {
                return console.error('error: ', error);
            }
        } catch (error) {
            console.error(error);
            addSnackbar(`Failed to ${chatbot.active ? 'deactivate' : 'activate'} Virtual Assistant.`, {
                variant: 'error'
            });
        }
    };

    return (
        <InputSwitch
            labelOn="Active"
            labelOff="Inactive"
            labelPlacement="start"
            onClick={e => {
                handleClick(e);
            }}
            checked={chatbot?.active}
            // Index can be undefined and that's okay.
            data-test-id={'button_cb_toggle_question_' + index}
        />
    );
};
