import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Illustration } from './OnboardingIllustration2.svg';
import styles from './company-onboarding.module.scss';
import './company-onboarding.global.scss';
import { ButtonLink, ButtonPrimary, StepChecklist, Title } from '../../components-v2/shared';
import { useCopyToClipboard } from '@lambdacurry/component-library';
import useStore from '../../store/useStore';
import { observer } from 'mobx-react';

import DefaultRoutes from '../../routes/DefaultRoutes';
import { getWidgetPublicScript } from '../../constants';
import { AgencyCustomization } from '../../types';
import { useAsyncEffect } from '../../util/async';
import { AgencyLogo } from '../Agency/AgencyLogo';

export const CompanyOnboardingEmbedPage: React.FC<{}> = observer(() => {
    const [agencyCustomizationData, setAgencyCustomizationData] = useState<AgencyCustomization>();
    const [embedCode, setEmbedCode] = useState<string>('Loading...');

    const [copyText, copyState] = useCopyToClipboard(embedCode, '.copy-embed-code', {
        copyable: 'Copy Embed Code',
        copied: 'Copied!'
    });

    const { agencyStore, store } = useStore();
    const { companyOnboardingFormData, fetchAgencyCustomization } = agencyStore;
    const { router, publicRoles, setActiveCompanyId } = store;

    const { agency_id, id: companyId } = companyOnboardingFormData.response?.companies[0] || {};

    const continueOn = () => router.goTo(DefaultRoutes.CompanyOnboardingInviteTeamPage, router.params, store);
    const api_key = publicRoles.values[0]?.api_key;

    useAsyncEffect(async () => {
        const data = await fetchAgencyCustomization(agency_id);
        if (data) {
            setAgencyCustomizationData(data);
        }
    });

    useEffect(() => {
        // Note: the setActiveCompanyId also fetches public roles
        if (companyId) {
            setActiveCompanyId(companyId);
        }
    }, []);

    useEffect(() => {
        if (!api_key) {
            return;
        }

        setEmbedCode(
            getWidgetPublicScript({
                api_key
            })
        );
    }, [agencyCustomizationData, api_key]);

    return (
        <div className={classNames('company-onboarding', styles.companyOnboarding, styles.companyOnboardingEmbed)}>
            <header>
                <div className={styles.companyOnboardingAgencyLogo}>
                    <AgencyLogo />
                </div>

                <StepChecklist
                    className={styles.companyOnboardingStepChecklist}
                    steps={['Credentials', 'Company Info', 'Enable SMS', 'Embed App', 'Invite Team']}
                    currentStepIndex={3}
                />

                <Illustration className={classNames(styles.companyOnboardingIllustration)} />
            </header>
            <main className={styles.companyOnboardingContent}>
                <Title>Add the application code to your website</Title>
                <p className="text">
                    Add the code snippet below to your website. Don’t worry, you’ll be able to make edits before it goes
                    live!
                </p>
                <div className={styles.companyOnboardingEmbedCode}>
                    <code>{embedCode}</code>
                    {embedCode !== 'Loading...' && (
                        <ButtonPrimary
                            className={classNames('copy-embed-code', styles.companyOnboardingEmbedCodeCopy)}
                            disabled={copyState === 'copied'}
                        >
                            {copyText}
                        </ButtonPrimary>
                    )}
                </div>
                <div className={styles.companyOnboardingActions}>
                    <ButtonLink onClick={continueOn}>I'll do this later</ButtonLink>
                    <ButtonPrimary onClick={continueOn}>Done</ButtonPrimary>
                </div>
            </main>
        </div>
    );
});
