import { CalendarDayoff } from '../../types/CalendarDayoff';
import { AvailabilitySchedule, TimeOffCustom } from './ScheduleEditor.types';

export interface ScheduleEditorListReducerState {
    modal: {
        active: ScheduleEditorListModals;
        data: ScheduleEditorModalData;
    };
    schedules?: AvailabilitySchedule[];
    holidays?: CalendarDayoff[];
}

export interface ScheduleEditorModalData {
    title?: string;
    selectedSchedule?: AvailabilitySchedule;
    formInitialValues?: any;
    timeOff?: TimeOffCustom;
    linkedExternalCalendar?: number;
}

export type ServerRequestStatus = 'waiting' | 'sending' | 'sent' | 'error';

export type ScheduleEditorListModals =
    | 'none'
    | 'createSchedule'
    | 'copySchedule'
    | 'deleteSchedule'
    | 'editTimeOff'
    | 'editRemoteCalendar'
    | 'deleteTimeOff';

export interface ScheduleEditorListReducerAction {
    name: keyof typeof scheduleEditorListReducers;
    payload?: any;
}

const scheduleEditorListReducers = {
    closeModal: (state: ScheduleEditorListReducerState, _: any) => {
        state.modal.active = 'none' as ScheduleEditorListModals;
        return { ...state };
    },
    openModal: (state: ScheduleEditorListReducerState, activeModal: ScheduleEditorListModals) => {
        state.modal.active = activeModal;
        return { ...state };
    },
    setModalData: (state: ScheduleEditorListReducerState, modalData: ScheduleEditorModalData) => {
        state.modal.data = { ...state.modal.data, ...modalData };
        return { ...state };
    },
    setSchedules: (state: ScheduleEditorListReducerState, schedules: AvailabilitySchedule[]) => ({
        ...state,
        schedules
    }),
    setHolidays: (state: ScheduleEditorListReducerState, holidays: CalendarDayoff[]) => ({
        ...state,
        holidays
    })
};

export const scheduleEditorListReducer = (
    state: ScheduleEditorListReducerState,
    action: ScheduleEditorListReducerAction
) => {
    if (!scheduleEditorListReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: ScheduleEditorListReducerState = scheduleEditorListReducers[action.name](state, action.payload);
    return nextState;
};

export interface ScheduleEditorEditScheduleReducerState {
    isEditingName?: boolean;
    modal: {
        active: ScheduleEditorListModals;
        data: ScheduleEditorModalData;
    };
    schedule: any;
}

export type ScheduleEditorEditScheduleModals = 'none';

export interface ScheduleEditorEditScheduleReducerAction {
    name: keyof typeof scheduleEditorEditScheduleReducers;
    payload?: any;
}

const scheduleEditorEditScheduleReducers = {
    closeModal: (state: ScheduleEditorEditScheduleReducerState, _: any) => {
        state.modal.active = 'none' as ScheduleEditorEditScheduleModals;
        return { ...state };
    },
    openModal: (state: ScheduleEditorEditScheduleReducerState, activeModal: ScheduleEditorEditScheduleModals) => {
        state.modal.active = activeModal;
        return { ...state };
    },
    setModalData: (state: ScheduleEditorEditScheduleReducerState, modalData: ScheduleEditorModalData) => {
        state.modal.data = { ...state.modal.data, ...modalData };
        return { ...state };
    },
    setSchedule: (state: ScheduleEditorEditScheduleReducerState, schedule: any) => ({
        ...state,
        schedule
    }),
    setIsEditingName: (state: ScheduleEditorEditScheduleReducerState, isEditingName: boolean) => ({
        ...state,
        isEditingName
    })
};

export const scheduleEditorEditScheduleReducer = (
    state: ScheduleEditorEditScheduleReducerState,
    action: ScheduleEditorEditScheduleReducerAction
) => {
    if (!scheduleEditorEditScheduleReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: ScheduleEditorEditScheduleReducerState = scheduleEditorEditScheduleReducers[action.name](
        state,
        action.payload
    );
    return nextState;
};
