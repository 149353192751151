import {
    handlePromise,
    Icon,
    Table,
    useAsyncEffect,
    Modal,
    ModalHeader,
    ModalActions,
    Button,
    IconButton
} from '@lambdacurry/component-library';
import React, { useState } from 'react';
import {
    AppSection,
    AppSectionHeader,
    ButtonPrimary,
    InputSelect,
    useSnackbar,
    ActionList
} from '../../components-v2/shared';
import { differenceBy } from 'lodash';
import {
    ApiUser,
    CompanyAssignableUser,
    CompanyDetailsAction,
    CompanyDetailsFormValues,
    CompanyDetailsState
} from './CompanyDetailsForm.helpers';
import { observer } from 'mobx-react';
import useStore from '../../store/useStore';
import { FormikProps } from 'formik';
import { ActiveUser } from '../../types';
import Resource from '../../store/Resource';
import { getRouteWithContext } from '../../routes/routeUtils';

export const CompanyDetailsMembers: React.FC<{
    state: CompanyDetailsState;
    dispatch: React.Dispatch<CompanyDetailsAction>;
    formikProps: FormikProps<CompanyDetailsFormValues>;
    isCreateCompanyForm: boolean;
}> = observer(({ state, dispatch, formikProps, isCreateCompanyForm }) => {
    const { store } = useStore();
    const { Api, activeCompany, router, fetchUsers, users } = store;
    const { addSnackbar } = useSnackbar();
    const [selectedMember, setSelectedMember] = useState();
    const [deleteMemberModal, setDeleteMemberModal] = useState<boolean>(false);
    const [deletedMember, setDeletedMember] = useState<CompanyAssignableUser | null>(null);

    const fetchAssignedUsers = async () => {
        const [data, error] = await handlePromise<ApiUser[]>(Api.getAssignedUsers(activeCompany.id));

        if (!data || error) {
            console.error(error?.response);
            addSnackbar('Failed to fetch company members.', { variant: 'error' });
            return;
        }

        dispatch({ name: 'setInitialCompanyMembers', payload: data });
        dispatch({ name: 'setCompanyMembers', payload: data });
    };

    const fetchData = async () => {
        if (!isCreateCompanyForm) {
            fetchAssignedUsers();
        }

        fetchUsers();
    };

    useAsyncEffect(fetchData);

    const assignableUsers = differenceBy(
        (users as Resource<ActiveUser>).values.map(({ id, first_name, last_name, email, role }) => ({
            id,
            label: `${first_name} ${last_name || ''} (${email})`,
            name: `${first_name} ${last_name || ''}`,
            email,
            role: role.name
        })),
        state.companyMembers || [],
        'id'
    );

    const goToEditUser = (userID: number) => {
        const userEditRoute = getRouteWithContext('UserEdit', router);
        const params = { ...router.params, backRoute: router.currentView, companyId: store.activeCompany?.id };

        router.goTo(userEditRoute, { ...params, id: userID }, store);
    };

    const onMemberAdd: (member?: CompanyAssignableUser) => void = member => {
        if (!member) {
            return;
        }

        dispatch({ name: 'addCompanyMember', payload: member });

        const updatedAssignedIds = state.companyMembers?.map(member => member.id) || [];
        updatedAssignedIds.push(member.id);

        formikProps.setFieldValue('assigned_users', updatedAssignedIds);
    };

    const removeMember = () => {
        dispatch({ name: 'removeCompanyMember', payload: deletedMember! });

        const filteredMembers = state.companyMembers?.filter(member => member.id !== deletedMember!.id) || [];
        const updatedAssignedIds = filteredMembers.map(member => member.id);

        setDeletedMember(null);
        formikProps.setFieldValue('assigned_users', updatedAssignedIds);
    };

    return (
        <>
            <AppSection>
                <AppSectionHeader title="Company Members">
                    <ActionList position="end">
                        <InputSelect
                            className="company-details-members-search"
                            label="Assignable Users"
                            name="member"
                            options={assignableUsers}
                            value={selectedMember}
                            onChange={(event, value) => {
                                setSelectedMember(value);
                            }}
                        />
                        <ButtonPrimary
                            onClick={() => {
                                onMemberAdd(selectedMember);
                                setSelectedMember(undefined);
                            }}
                            disabled={!selectedMember}
                        >
                            Add Member
                        </ButtonPrimary>
                    </ActionList>
                </AppSectionHeader>

                {state.companyMembers ? (
                    <Table>
                        <tbody>
                            {state.companyMembers.map(user => {
                                return (
                                    <tr key={user.id}>
                                        <td className="clx-font-weight-bold">{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.role}</td>
                                        <td>
                                            <ActionList position="end">
                                                <IconButton icon="pencil" onClick={() => goToEditUser(user.id)} />
                                                <IconButton
                                                    icon="trash"
                                                    onClick={() => {
                                                        setDeletedMember(user);
                                                        setDeleteMemberModal(true);
                                                    }}
                                                />
                                            </ActionList>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                ) : (
                    !isCreateCompanyForm && 'Loading...'
                )}
            </AppSection>

            <Modal isOpen={deleteMemberModal} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="color-danger" />
                            Remove Member?
                        </>
                    }
                />

                <p>
                    Are you sure you want to remove <span className="clx-font-weight-700">{deletedMember?.name}</span>{' '}
                    from team members?
                </p>

                <ModalActions>
                    <ActionList position="end">
                        <Button
                            onClick={() => {
                                setDeletedMember(null);
                                setDeleteMemberModal(false);
                            }}
                        >
                            No, Cancel
                        </Button>
                        <ButtonPrimary
                            onClick={() => {
                                removeMember();
                                setDeleteMemberModal(false);
                            }}
                        >
                            Yes, Delete
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>
        </>
    );
});
