import { InputCheckbox } from '@lambdacurry/component-library';
import React, { FC } from 'react';

import { AppList, AppListItem, AppSectionHeader } from '../../../../components-v2/shared';
import { ScheduleReportModalProps } from '../ScheduleReportModal';
import { ClarityChartObj, recommendedChartIds } from '../ScheduleReportModal.helpers';

export const ChartsTab: FC<Partial<ScheduleReportModalProps>> = ({ schedulerFormikProps }) => {
    const availableCharts = schedulerFormikProps?.values.available_charts ?? [];
    const recommendedReports: ClarityChartObj[] = [];
    const additionalReports: ClarityChartObj[] = [];

    availableCharts.forEach(chartObj => {
        const { chart } = chartObj;
        if (recommendedChartIds.includes(chart.id)) {
            return recommendedReports.push(chartObj);
        }

        return additionalReports.push(chartObj);
    });

    const handleToggle = (guid: string) => {
        const index = availableCharts.findIndex(({ chart }) => chart.guid === guid);
        const chartObj = availableCharts[index];

        const updatedCharts = [...availableCharts];
        updatedCharts[index] = { ...chartObj, enabled: !chartObj.enabled };

        schedulerFormikProps?.setFieldValue('available_charts', updatedCharts);
    };

    return (
        <div className="clarity-schedule-report-modal-charts-tab tab-content">
            {availableCharts.length > 0 && (
                <>
                    <AppSectionHeader title="Recommended Reports" />
                    <AppList className="clx-margin-bottom-24">
                        {recommendedReports.map(({ chart, enabled }) => (
                            <AppListItem key={chart.guid} className="clarity-report-list-item">
                                <InputCheckbox
                                    label={chart.title}
                                    checked={enabled}
                                    onChange={() => handleToggle(chart.guid)}
                                />
                            </AppListItem>
                        ))}
                    </AppList>

                    <AppSectionHeader title="Additional Reports" />
                    <AppList>
                        {additionalReports.map(({ chart, enabled }) => (
                            <AppListItem key={chart.guid} className="clarity-report-list-item">
                                <InputCheckbox
                                    label={chart.title}
                                    checked={enabled}
                                    onChange={() => handleToggle(chart.guid)}
                                />
                            </AppListItem>
                        ))}
                    </AppList>
                </>
            )}
        </div>
    );
};
