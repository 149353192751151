import { Form, FormikProps } from 'formik';
import { toJS } from 'mobx';
import * as React from 'react';
import { Panel } from 'react-bootstrap';
import { Daysoff } from '../../types/CalendarDayoff';
import DaysOffEdit from '../misc/DaysOffEdit';
import { FieldGroup } from '../misc/FieldGroup';

export interface ICalendarDayoffForm {
    id?: number;
    title: string;
    daysoff: Daysoff[];
}

export const labels = {
    title: 'Title',
    daysoff: 'Days off'
};

interface ParentProps {
    controls: (bag: FormikProps<ICalendarDayoffForm>) => React.ReactNode;
    editing: boolean;
}

export default (props: ParentProps) => {
    return (bag: FormikProps<ICalendarDayoffForm>) => {
        const fieldProps = {
            ...bag,
            labels,
            disabled: !props.editing,
            className: 'col-sm-6'
        };
        return (
            <Form className="form-holder-block holiday-details-form">
                <Panel bsStyle="primary" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle>
                            <h4>Holiday Details</h4>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="row">
                            <FieldGroup id="title" {...fieldProps} />
                        </div>
                        <DaysOffEdit
                            daysOff={toJS(bag.values.daysoff)}
                            onChange={handleChangeDaysOff(bag)}
                            disabled={!props.editing}
                        />
                    </Panel.Body>
                </Panel>
                {props.controls(bag)}
            </Form>
        );
    };
};

const handleChangeDaysOff = (bag: FormikProps<ICalendarDayoffForm>) => (daysOff: Daysoff[]) => {
    bag.setFieldValue('daysoff', daysOff);
};
