import { ButtonPrimary, useCopyToClipboard } from '@lambdacurry/component-library';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AppCard, AppSectionHeader } from '../../../../../components-v2/shared';
import { getWidgetPublicScript } from '../../../../../constants/widget';
import { ApplicationSettingsFormValues } from '../../../ApplicationSettings.helpers';

export interface EmbedTabProps {
    formikProps: FormikProps<ApplicationSettingsFormValues>;
}

export const EmbedTab: FC<EmbedTabProps> = observer(({ formikProps }) => {
    const appEmbedCode = getWidgetPublicScript({
        api_key: formikProps.values.api_key || ''
    });

    const copyTarget = '.embed-copy';
    const copyOptions = { copyable: 'Copy Code', copied: 'Copied!' };
    const [copyEmbedCode, copyEmbedState] = useCopyToClipboard(appEmbedCode, copyTarget, copyOptions);

    return (
        <AppCard className="advanced-settings-embed">
            <AppSectionHeader title="Application Code" />
            <div className="embed-code embed-code-readonly embed-code-has-button">
                <div className="embed-code-content">{appEmbedCode}</div>
                <ButtonPrimary className="embed-copy" disabled={copyEmbedState === 'copied'}>
                    {copyEmbedCode}
                </ButtonPrimary>
            </div>

            <AppSectionHeader title="Install Instructions" />
            <p className="clx-margin-0">
                Copy and paste the code snippet above to each page of your website. Place the code in the HTML files
                before the end of body or &lt;/body&gt; tag. Don't make changes to the code or your application may not
                display correctly! Don't worry, you'll be able to make edits before it goes live!
            </p>
        </AppCard>
    );
});
