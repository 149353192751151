import * as Yup from 'yup';

export const EditCallUsNowFormValidationSchema = () =>
    Yup.object().shape({
        schedule_id: Yup.number()
            .nullable()
            .required('Please select a schedule.'),
        call_to_action_text: Yup.string().required('Please enter a call to action.'),
        phone: Yup.string().required('Please enter a phone number.')
    });
