import { useAsyncEffect } from '@lambdacurry/component-library';
import React, { FC, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { DateTime } from 'luxon';
import Store from '../../../store';

import './company-trial-ending-alert.scss';

export const CompanyTrialEndingAlert: FC<{ store: Store }> = ({ store }) => {
    const { activeCompanyId, fetchCompanies, companies } = store;
    const storageKey = activeCompanyId ? `company${activeCompanyId}TrialAlertDismissed` : null;
    const activeCompany = companies.getItem(activeCompanyId);

    const trialEndDate: DateTime = activeCompany?.trial_end_date
        ? DateTime.fromISO(activeCompany.trial_end_date)
        : DateTime.fromSeconds(0);

    const trialHasExpired = trialEndDate.isValid
        ? trialEndDate.startOf('day').toSeconds() <=
          DateTime.local()
              .startOf('day')
              .toSeconds()
        : true;

    const trialEndsInDays = Math.ceil(trialEndDate.diffNow(['days']).toObject().days || 0);

    const storage = trialHasExpired ? localStorage : sessionStorage;

    const [loading, setLoading] = useState<boolean>(true);
    const [isDismissed, setDismissed] = useState<boolean>(storage.getItem(storageKey || '') === 'true' || false);

    const fetchData = async () => {
        if (trialHasExpired && !isDismissed && !activeCompany && !companies) {
            await fetchCompanies();
        }
        setLoading(false);
    };
    useAsyncEffect(fetchData);

    useEffect(() => {
        if (storageKey && isDismissed) {
            storage.setItem(storageKey, `${isDismissed}`);
        }
    }, [isDismissed]);

    useEffect(() => {
        setDismissed(storage.getItem(storageKey || '') === 'true' || false);
    }, [storage]);

    // Note: If the trial has been over for more than a day, we can dismiss this alert per Brian on 01/26/21 - Jake
    if (trialEndsInDays <= -1) {
        return null;
    }

    if (!activeCompany || loading || isDismissed || !trialEndDate.isValid) {
        return null;
    }

    const days = trialEndsInDays === 1 ? 'day' : 'days';

    return (
        <Alert
            key="proofingModeAlert"
            className="company-trial-ending-alert"
            bsStyle="danger"
            onDismiss={() => setDismissed(true)}
        >
            {trialHasExpired ? `Your trial is complete.` : `Your trial ends in ${trialEndsInDays} ${days}.`}
        </Alert>
    );
};
