import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Alert, Button, Col, Collapse, Glyphicon, PageHeader, Row, Well } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { InjectedProps } from '../../types';
import { CalendarDayoff } from '../../types/CalendarDayoff';
import { dateFormatter } from '../../util/formatters';
import CalendarDayoffNew from './CalendarDayoffNew';

interface CalendarDayoffListState {
    open: boolean;
    newItem?: CalendarDayoff;
}

@inject('store')
@observer
export class CalendarDayoffList extends React.Component<{}, CalendarDayoffListState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state: CalendarDayoffListState = {
        open: false
    };

    public componentDidMount() {
        const { fetchCalendarDaysOff } = this.injected.store;
        return fetchCalendarDaysOff();
    }

    public render() {
        const { newItem } = this.state;
        const options = {
            onRowClick: this.handleRowClick
        };
        const { calendarsDaysoff } = this.injected.store;
        return (
            <>
                <PageHeader>Calendar Holidays</PageHeader>
                <Col className="col-lg-12">
                    {newItem && (
                        <Alert bsStyle="success" onDismiss={this.handleDismissAlert}>
                            New Holiday "{newItem.title}", created successfully.
                        </Alert>
                    )}
                    <Row>
                        <Button bsStyle="primary" onClick={this.toggleNewForm}>
                            Create Holiday <Glyphicon glyph="plus" />
                        </Button>
                    </Row>
                    <Collapse in={this.state.open}>
                        <Row>
                            <Well>
                                <CalendarDayoffNew handleClose={this.itemAdded} />
                            </Well>
                        </Row>
                    </Collapse>
                    <Row>
                        <BootstrapTable data={calendarsDaysoff.values} options={options} pagination search striped>
                            <TableHeaderColumn
                                dataField="created_at"
                                dataFormat={dateFormatter}
                                isKey
                                width="120"
                                dataSort
                            >
                                Created
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="title">Title</TableHeaderColumn>
                        </BootstrapTable>
                    </Row>
                </Col>
            </>
        );
    }

    public handleRowClick = (row: any) => {
        const { router } = this.injected.store;
        router.goTo(
            router.params.agencyId ? DefaultRoutes.AgencyHolidaysEdit : DefaultRoutes.HolidaysEdit,
            { ...router.params, id: row.id },
            this.injected.store
        );
    };

    public toggleNewForm = () => {
        this.setState({ open: !this.state.open });
    };

    public itemAdded = (item: any) => {
        this.setState({ newItem: item });
        this.toggleNewForm();
    };

    public handleDismissAlert = () => {
        this.setState({ newItem: undefined });
    };
}

export default CalendarDayoffList;
