import * as React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ControlButtonSave = (props?: any) => {
    const text = props.text || 'Save changes';
    return (
        <div className="form-group col-sm-6 save-changes">
            <div className="row btns-row">
                <Button className="btn btn-green btn-success" disabled={props.isSaving} type="submit">
                    {props.isSaving && <FontAwesomeIcon icon={faSpinner} spin size="lg" />}
                    {!props.isSaving && text}
                </Button>
                {props.showBack && props.onClickBackHandler ? (
                    <Button bsStyle="warning" onClick={props.onClickBackHandler}>
                        Back
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default ControlButtonSave;
