import React, { FC, CSSProperties } from 'react';
import classNames from 'classnames';
import { IconButton, IconButtonProps } from '@lambdacurry/component-library';

import './Action.scss';

export interface ActionProps extends IconButtonProps {
    active?: {
        fill: string;
        background: string;
    };
    cursor?: CSSProperties['cursor'];
}

export const Action: FC<ActionProps> = ({ active, className, cursor, style, ...props }) => (
    <IconButton
        {...props}
        className={classNames('Action', 'dnd-action', className)}
        tabIndex={0}
        style={
            {
                ...style,
                cursor,
                '--fill': active?.fill,
                '--background': active?.background
            } as CSSProperties
        }
    />
);
