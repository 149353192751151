import { IconButton, Tooltip } from '@lambdacurry/component-library';
import React, { FC, ReactFragment } from 'react';

export interface HelpIconProps {
    className?: string;
    tooltip: ReactFragment;
}

export const HelpIcon: FC<HelpIconProps> = ({ className, tooltip }) => (
    <Tooltip title={tooltip}>
        <IconButton icon="help" className={className} />
    </Tooltip>
);
