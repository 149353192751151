import { InputCheckbox, InputSelect, InputText, useAsyncEffect } from '@lambdacurry/component-library';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react';
import React from 'react';
import { InputPhone } from '../../components-v2/shared';
import { dma, timeZoneList } from '../../constants';
import { usStates } from '../../constants/us-states';
import useStore from '../../store/useStore';
import { Category } from '../../types/Category';
import { CompanyDetailsFormValues } from './CompanyDetailsForm.helpers';

export const CompanyDetailsSettings: React.FC<{
    formikProps: FormikProps<CompanyDetailsFormValues>;
}> = observer(({ formikProps }) => {
    const { store, agencyStore } = useStore();
    const { fetchCategory, businessCategory, isAdminRole, isAgencyRole } = store;
    const { activeAgencyId, accessibleAgencies } = agencyStore;

    const fetchData = async () => {
        fetchCategory();
    };

    useAsyncEffect(fetchData);

    const selectableBusinessCategories = (categories: Category[], agencyId?: number | null) => {
        return categories.filter((cat: Category) => {
            return !cat.agency_id || cat.agency_id == agencyId
        });
    };

    return (
        <>
            {isAdminRole && (
                <>
                <InputCheckbox
                    label="Test Company (Data not used in reporting)"
                    labelPlacement="end"
                    name="is_test"
                    formikProps={formikProps}
                /><br/>
                <InputCheckbox
                    label="Universal TCC (Minimal config with Zero Modules)"
                    labelPlacement="end"
                    name="is_universal"
                    formikProps={formikProps}
                />
                </>
            )}
            <InputText label="Company Name" name="name" formikProps={formikProps} />
            <InputText label="Address Line 1" name="address1" formikProps={formikProps} />
            <InputText label="Address Line 2" name="address2" formikProps={formikProps} />
            <InputText label="City" name="city" formikProps={formikProps} />

            <div className="field-row">
                <InputSelect
                    label="State"
                    name="state"
                    optionLabelKey="name"
                    optionValueKey="code"
                    options={usStates.filter(({ name }) => !!name)}
                    formikProps={formikProps}
                />
                <InputText label="Zip Code" name="zipcode" formikProps={formikProps} />
            </div>

            <InputSelect
                label="Time Zone"
                name="time_zone"
                optionValueKey="value"
                options={Object.keys(timeZoneList).map(timeZoneKey => ({
                    label: timeZoneKey,
                    value: timeZoneKey
                }))}
                formikProps={formikProps}
                inputProps={{ autoComplete: 'off' }}
            />
            <InputPhone label="Phone Number" name="phone" formikProps={formikProps} />
            <InputText label="Website URL" name="website_url" formikProps={formikProps} />
            {(formikProps.values.agency_id || activeAgencyId || isAdminRole) && (
                <InputSelect
                    label="Partner"
                    name="agency_id"
                    optionLabelKey="name"
                    optionValueKey="id"
                    options={accessibleAgencies}
                    formikProps={formikProps}
                    autocompleteConfig={{ disableClearable: false }}
                    inputProps={{ autoComplete: 'off' }}
                    disabled={!isAdminRole && !isAgencyRole}
                />
            )}
            <InputSelect
                label="Business Category"
                name="business_category_id"
                optionLabelKey="title"
                optionValueKey="id"
                options={[
                    { title: 'None', id: 0 },
                    ...selectableBusinessCategories(businessCategory.values || [], formikProps.values.agency_id)
                ]}
                formikProps={formikProps}
                disabled={!businessCategory.values?.length}
                autocompleteConfig={{ disableClearable: false }}
                inputProps={{ autoComplete: 'off' }}
            />
            <InputSelect
                label="Designated Marketing Area"
                name="dma"
                optionLabelKey="area"
                optionValueKey="code"
                options={dma}
                formikProps={formikProps}
                autocompleteConfig={{ disableClearable: false }}
                inputProps={{ autoComplete: 'off' }}
            />
        </>
    );
});
