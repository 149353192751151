import React, { FunctionComponent } from 'react';
import {
    InputText,
    ButtonLink,
    AppSectionHeader,
    InputSwitch,
    InputTextarea,
    InputSelect
} from '../../../components-v2/shared';
import { FormikProps } from 'formik';
import { EditAppEmailFormDeliveryValues, EmailResponse } from '../EmailEditor.types';

const characterCount = (txt?: string | null): number => {
    if (!txt) {
        return 0;
    }
    return txt.length;
};

export const EditEmailInboxDetailsForm: FunctionComponent<{
    formikProps: FormikProps<EditAppEmailFormDeliveryValues>;
    emailResponse: EmailResponse;
    smsReady: boolean;
    onReset?: () => void;
}> = ({ formikProps, onReset, emailResponse, smsReady }) => {
    return (
        <>
            <AppSectionHeader title="Inbox Details">
                {onReset ? <ButtonLink onClick={onReset}>Revert to default</ButtonLink> : null}
            </AppSectionHeader>
            <InputText
                id="subject"
                name="delivery.subject"
                label="Subject"
                inputProps={{ maxLength: 78 }}
                formikProps={formikProps}
            />
            <InputText
                id="previewText"
                name="delivery.previewText"
                label="Preview Text"
                inputProps={{ maxLength: 130 }}
                formikProps={formikProps}
            />
            <InputText
                id="replyToEmail"
                name="delivery.replyToEmail"
                label="Reply To Email"
                formikProps={formikProps}
            />
            {emailResponse.sections.filter(sec => sec.hideable).length > 0 && (
                <>
                    <AppSectionHeader title="Optional Sections" />
                    {emailResponse.sections
                        .filter(section => section.hideable)
                        .map(section => {
                            const displayName = section.displayName || section.id;
                            return (
                                <div>
                                    <InputSwitch
                                        label={`Hide ${displayName}`}
                                        name={`${section.id}.hidden`}
                                        formikProps={formikProps}
                                    />
                                </div>
                            );
                        })}
                    <p> </p>
                </>
            )}
            {!smsReady && (
                <>
                    <AppSectionHeader title="SMS Alternative" />
                    To configure SMS Alternative messages, you must enable the SMS Messaging feature and create a new
                    Twilio texting number.
                </>
            )}
            {emailResponse.id.includes('lead-nurture') && smsReady && (
                <div>
                    {' '}
                    <AppSectionHeader title="SMS Alternative" />
                    <InputSelect
                        name="placeholders"
                        placeholder="Template variables"
                        optionValueKey="id"
                        optionLabelKey="name"
                        onChange={(event: React.ChangeEvent<any>, arg: string) => {
                            const newAlt = formikProps.values['delivery']['smsAlternative'] + arg;
                            formikProps.setFieldValue('delivery.smsAlternative', newAlt);
                        }}
                        options={emailResponse.templateVariables.map(item => {
                            return { id: item, name: item };
                        })}
                    />
                    <InputTextarea name={`delivery.smsAlternative`} disabled={!smsReady} formikProps={formikProps} />
                    <div>
                        Character Count Estimate:{' '}
                        <span style={{ fontWeight: 'bolder' }}>
                            {characterCount(formikProps.values['delivery']['smsAlternative'])}
                        </span>
                    </div>
                    <div
                        style={{ color: 'red' }}
                        hidden={characterCount(formikProps.values['delivery']['smsAlternative']) < 140}
                    >
                        Warning: Messages with more than 140 characters might be broken up into multiple texts.
                    </div>
                    {emailResponse.delivery.defaultValues.suggestedSMSAlternative && (
                        <>
                            {' '}
                            <hr />{' '}
                            <div>
                                <h4>Suggested SMS Content:</h4>
                            </div>
                            <div>
                                <code>{emailResponse.delivery.defaultValues.suggestedSMSAlternative}</code>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};
