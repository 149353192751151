export enum ClarityApplicationModule {
    BPN = 'MODULE_BPN',
    CM = 'MODULE_CONCESSIONMANAGER',
    IC = 'MODULE_INCOMECALCULATOR',
    SG = 'MODULE_SCHEDULEGENIE',
    CU = 'MODULE_CALLUS',
    CH = 'MODULE_CHAT',
    TU = 'MODULE_TEXTUS',
    IN = 'MODULE_INSITE'
}
