import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { AppHeader, AppPage, AppSection } from '../../../../components-v2/shared';
import useStore from '../../../../store/useStore';
import { Button, ButtonPrimary, Icon } from '@lambdacurry/component-library';
import RealyncLogo from '../../../../assets/images/realync-logo.png';
import './integrations-page.scss';
import { handlePromise, useAsyncEffect } from '../../../../util/async';

enum Integrations {
    'REALYNC' = 'REALYNC'
}

export const IntegrationsPage = observer(() => {
    const [integrations, setIntegrations] = useState<Integrations[]>([]);
    const { store } = useStore();
    const { router } = store;

    const getIntegrationRoute = (route: string) => `/company/${router.params.companyId}/integrations/${route}`;

    const fetchIntegrations = async () => {
        const [response, error] = await handlePromise(
            store.api.client.get(`/integrations/company/${router.params.companyId}`)
        );

        if (!response?.data || error) setIntegrations([]);
        setIntegrations(response.data);
    };

    useAsyncEffect(fetchIntegrations);

    return (
        <AppPage className="integrations-page">
            <AppHeader title="Integrations" />
            <AppSection>
                <div className="integrations-page__row">
                    <div className="integrations-page__logo">
                        <img src={RealyncLogo} alt="realync logo" />
                    </div>
                    <div className="integrations-page__details">
                        <h3 className="integrations-page__title">Realync</h3>
                        <p className="integrations-page__description">Enterprise video leasing & engagement platform</p>
                    </div>
                    <div className="flex-spacer" />
                    {integrations.includes(Integrations.REALYNC) ? (
                        <Button
                            className="integrations-page__setup"
                            icon={<Icon name="pencil" />}
                            as={buttonProps => <a {...buttonProps} href={getIntegrationRoute('realync')} />}
                        >
                            Edit
                        </Button>
                    ) : (
                        <ButtonPrimary
                            className="integrations-page__setup"
                            as={buttonProps => (
                                <a {...buttonProps} href={getIntegrationRoute('realync')}>
                                    Setup
                                </a>
                            )}
                        />
                    )}
                </div>
            </AppSection>
        </AppPage>
    );
});
