import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Glyphicon, Jumbotron, Col } from 'react-bootstrap';
import { InjectedProps } from '../../types';
import Store from '../../store';
import { comparer } from 'mobx';

const SCOREBOARD_WIDGET_COL_SIZE_L = 2;
const REFRESH_INTERVAL_IN_MILLIS = 60000;
const REFRESH_DATA_CHECK_IN_MILLIS = 1000;

export interface ScoreboardWidgetType {
    icon: string;
    displayName: string;
    fetchMethod: string | null;
    fetchParams?: any;
    displayCols?: number;
    calculateMethod?: any;
    storeResultAs?: string;
}

export interface ScoreboardWidgetProps {
    store?: Store;
    type: ScoreboardWidgetType;
}

@inject('store')
@observer
export class ScoreboardWidget extends React.Component<ScoreboardWidgetProps> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public static sharedMetrics = {};

    public state = {
        data: 0
    };

    private flashClass: string = '';
    private cancelTimeout: any = null;
    private cancelTimeoutNoFetch: any = null;
    private previousResult: any = null;
    private dataUpdated: boolean = false;

    public fetchData() {
        if (!this.props.type.fetchMethod) {
            return;
        }

        this.flashClass = '';
        const fetchMethod = this.injected.store[this.props.type.fetchMethod];
        const fetchMethodParams: any = [];

        if (this.props.type.fetchParams) {
            Object.values(this.props.type.fetchParams).forEach(value => {
                fetchMethodParams.push(value);
            });
        }

        if (!fetchMethod) {
            return;
        }

        fetchMethod.apply(null, fetchMethodParams).then((response: any) => {
            const data = response && response.data;

            if (this.props.type.storeResultAs) {
                ScoreboardWidget.sharedMetrics[this.props.type.storeResultAs] = data;
            }

            this.setState({ data }, () => {
                this.cancelTimeout = setTimeout(() => {
                    this.fetchData();
                }, REFRESH_INTERVAL_IN_MILLIS);
            });
        });
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public componentWillUnmount() {
        if (this.cancelTimeout) {
            clearTimeout(this.cancelTimeout);
        }

        if (this.cancelTimeoutNoFetch) {
            clearTimeout(this.cancelTimeoutNoFetch);
        }
    }

    public render() {
        const data: any = this.state.data;

        if (!this.dataUpdated && this.props.type.calculateMethod) {
            this.updateData();
        }

        this.flashClass = '';

        if (this.hasDataChanged(data)) {
            this.flashClass = 'flash';
            this.previousResult = data;
        }

        return (
            <Col className="scoreboard-widget" lg={this.props.type.displayCols || SCOREBOARD_WIDGET_COL_SIZE_L}>
                <Jumbotron className={`${this.flashClass}`}>
                    <h1>
                        <div>
                            <Glyphicon glyph={this.props.type.icon} />
                        </div>
                        <div>{this.props.type.displayName}</div>
                        {ScoreboardWidget.getScoreElement(data)}
                    </h1>
                </Jumbotron>
            </Col>
        );
    }

    private updateData() {
        const data = this.props.type.calculateMethod(ScoreboardWidget.sharedMetrics);

        if (data === -1) {
            // If we don't have the data yet, check again in a bit.
            this.cancelTimeoutNoFetch = setTimeout(() => {
                this.updateData();
            }, REFRESH_DATA_CHECK_IN_MILLIS);
        } else {
            this.dataUpdated = true;
            this.setState({ data: data });
        }
    }

    private static getScoreElement(data: any) {
        let score: any = 0;

        if (Array.isArray(data)) {
            score = (
                <div className="company-by-lead-count">
                    {data.map((result: any, index: number) => {
                        return (
                            <div key={`${index}_${Date.now()}`}>
                                <span>{result.name || 'NO DATA'}</span>
                                <span className="total-lead-count">{result.num_leads}</span>
                            </div>
                        );
                    })}
                </div>
            );
        } else if (typeof data === 'number' || !data) {
            score = <div className="scoreboard-number">{data}</div>;
        }

        return score;
    }

    private hasDataChanged(data: any) {
        // Deep equal comparison on these objects.
        const isObjectAndDataHasChanged = typeof data === 'object' && !comparer.structural(data, this.previousResult);
        const isNumberAndDataHasChanged = typeof data === 'number' && data !== this.previousResult;

        return isObjectAndDataHasChanged || isNumberAndDataHasChanged;
    }
}
