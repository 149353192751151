import { ListSegment } from './ListSegmentEditor.types';

export interface ListSegmentEditorListReducerState {
    modal: {
        active: ListSegmentEditorListModals;
        data: ListSegmentEditorModalData;
    };
    listSegments?: ListSegment[];
}

export interface ListSegmentEditorModalData {
    title?: string;
    selectedListSegment?: ListSegment;
    formInitialValues?: any;
}

export type ServerRequestStatus = 'waiting' | 'sending' | 'sent' | 'error';

export type ListSegmentEditorListModals = 'none' | 'createListSegment' | 'copyListSegment' | 'deleteListSegment';

export interface ListSegmentEditorListReducerAction {
    name: keyof typeof listSegmentEditorListReducers;
    payload?: any;
}

const listSegmentEditorListReducers = {
    closeModal: (state: ListSegmentEditorListReducerState, _: any) => {
        state.modal.active = 'none' as ListSegmentEditorListModals;
        return { ...state };
    },
    openModal: (state: ListSegmentEditorListReducerState, activeModal: ListSegmentEditorListModals) => {
        state.modal.active = activeModal;
        return { ...state };
    },
    setModalData: (state: ListSegmentEditorListReducerState, modalData: ListSegmentEditorModalData) => {
        state.modal.data = { ...state.modal.data, ...modalData };
        return { ...state };
    },
    setListSegments: (state: ListSegmentEditorListReducerState, listSegments: ListSegment[]) => ({
        ...state,
        listSegments
    })
};

export const listSegmentEditorListReducer = (
    state: ListSegmentEditorListReducerState,
    action: ListSegmentEditorListReducerAction
) => {
    if (!listSegmentEditorListReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: ListSegmentEditorListReducerState = listSegmentEditorListReducers[action.name](
        state,
        action.payload
    );
    return nextState;
};

export interface ListSegmentEditorEditSegmentReducerState {
    isEditingName?: boolean;
    modal: {
        active: ListSegmentEditorListModals;
        data: ListSegmentEditorModalData;
    };
    listSegment: ListSegment;
}

export type ListSegmentEditorEditSegmentModals = 'none';

export interface ListSegmentEditorEditSegmentReducerAction {
    name: keyof typeof listSegmentEditorEditSegmentReducers;
    payload?: any;
}

const listSegmentEditorEditSegmentReducers = {
    closeModal: (state: ListSegmentEditorEditSegmentReducerState, _: any) => {
        state.modal.active = 'none' as ListSegmentEditorEditSegmentModals;
        return { ...state };
    },
    openModal: (
        state: ListSegmentEditorEditSegmentReducerState,
        activeModal: ListSegmentEditorEditSegmentModals
    ) => {
        state.modal.active = activeModal;
        return { ...state };
    },
    setModalData: (state: ListSegmentEditorEditSegmentReducerState, modalData: ListSegmentEditorModalData) => {
        state.modal.data = { ...state.modal.data, ...modalData };
        return { ...state };
    },
    setListSegment: (state: ListSegmentEditorEditSegmentReducerState, listSegment: ListSegment) => {
        return {
            ...state,
            listSegment
        };
    },
    setIsEditingName: (state: ListSegmentEditorEditSegmentReducerState, isEditingName: boolean) => ({
        ...state,
        isEditingName
    })
};

export const listSegmentEditorEditSegmentReducer = (
    state: ListSegmentEditorEditSegmentReducerState,
    action: ListSegmentEditorEditSegmentReducerAction
) => {
    if (!listSegmentEditorEditSegmentReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: ListSegmentEditorEditSegmentReducerState = listSegmentEditorEditSegmentReducers[action.name](
        state,
        action.payload
    );
    return nextState;
};
