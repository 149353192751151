import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import './action-list.scss';

export interface ActionListProps extends HTMLAttributes<HTMLElement> {
    position?: 'end' | 'start' | 'center';
}

export const ActionList: FC<ActionListProps> = ({ className, position, ...props }) => (
    <div
        className={classNames('action-list', { [`action-list--position-${position}`]: position }, className)}
        {...props}
    />
);
