import {
    Table,
} from '@lambdacurry/component-library';
import { orderBy } from 'lodash';
import React from 'react';
import { phoneFormatter } from '../../util/formatters';
import { DateTime } from 'luxon';
import { AppCard, AppListEmpty, AppSectionHeader } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { TUNInterceptions } from '../../types';
import { CircularProgress } from '@material-ui/core';

export const InterceptionsList = () => {
    const [interceptionsList, setInterceptionsList] = React.useState<TUNInterceptions[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>();

    const { store } = useStore();
    const { fetchTextUsInterceptions } = store;

    React.useEffect(() => {
        setLoading(true);
        fetchTextUsInterceptions()
            .then((res) => {
                setInterceptionsList(res);
                setLoading(false);
            }).catch(error =>
                setError(error)
            )
    }, []);

    return (
        <AppCard>
            <AppSectionHeader title="List of TUN Interceptions" />

            {loading && (
                <div style={{ textAlign: 'center' }} role="status">
                    <CircularProgress size="50px" aria-label="Loading interceptions" />
                </div>
            )}

            {!loading && error && (
                <div role="alert" className="error-message">
                    {error}
                </div>
            )}

            {!loading && !error && interceptionsList.length > 0 &&
                <Table>
                    <thead>
                        <tr>
                            <th>Phone Number</th>
                            <th>Initial Message</th>
                            <th>Creation Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderBy(interceptionsList, ['created_at'], ['desc']).map(interception => {
                            const {
                                phone_number,
                                initial_message,
                                created_at
                            } = interception;

                            return (
                                <tr key={interception.id}>
                                    <td>
                                        {phoneFormatter(phone_number)}
                                    </td>
                                    <td>
                                        {initial_message}
                                    </td>
                                    <td>
                                        {DateTime.fromISO(created_at).toLocaleString(
                                            DateTime.DATETIME_SHORT
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            }

            {!loading && !error && interceptionsList.length === 0 && (
                <AppListEmpty
                    title="There are no TUN Interceptions to show"
                />
            )}
        </AppCard>
    )
}
