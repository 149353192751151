import React from 'react';
import { Route } from 'mobx-router';
import { dirBasename } from '../constants';
import { checkUserPermissions, checkUserHome, checkRouteAccess, checkPasswordReset, routeCheckAll } from './routeUtils';
import InsiteMetricDataList from '../components/InsiteMetricData/InsiteMetricDataList';
import InsitePageList from '../components/InsiteMetricData/InsitePageList';
import UserList from '../components/User/UserList';
import AuditLogs from '../components/Audit/AuditLog';
import CalendarDayoffList from '../components/CalendarDayoff/CalendarDayoffList';
import CalendarDayoffEdit from '../components/CalendarDayoff/CalendarDayoffEdit';
import CategoryList from '../components/Category/CategoryList';
import CategoryEdit from '../components/Category/CategoryEdit';
import InsiteDefinedFieldsList from '../components/InsiteMetricData/InsiteDefinedFieldsList';
import InsiteDefinedFieldsEdit from '../components/InsiteMetricData/InsiteDefinedFieldsEdit';
import { Messages } from '../components/Messages';
import UserEdit from '../components/User/UserEdit';
import { HomeDashboard } from '../components/Dashboard/HomeDashboard';
import { AgencyDetails, AgencyHostSettings } from '../components/Agency';

import { CompanyCreate } from '../components/Company/CompanyCreate';
import { LeadView } from '../components/Lead/LeadView/LeadView';
import { LeadDetails } from '../components/Lead/LeadDetails/LeadDetails';
import { ClarityDashboard } from '../components/Clarity/ClarityDashboard';
import { QuestionDefinitionPresets } from '../components/QuestionDefinitionPresets/QuestionDefinitionPresets';
import CsvImport from '../components/CsvImport/CsvImport';
import CssToolList from '../components/CssTool/CssToolList';
import { OptOutQueueList } from '../components/OptOutQueue/OptOutList';
import { EmailViewer } from '../components/EmailViewer/EmailViewer';
import { EmailSenderProfile } from '../components/EmailSenderProfile/EmailSenderProfile';
import { CompanyLists } from '../components/CompanyLists/CompanyLists';

const routeBasename = dirBasename ? dirBasename : '';
const agencyRouteBase = `${routeBasename}/partners/:agencyId`;

// Dashboard routes
const dashboardRouteBasename = `${agencyRouteBase}/dashboard`;
const DashboardRoutes = {
    AgencyDashboard: new Route({
        displayName: 'Dashboard',
        path: dashboardRouteBasename,
        component: <HomeDashboard />,
        beforeEnter: routeCheckAll([checkUserHome(), checkPasswordReset()])
    })
};

const AgencySettingsRoutes = {
    AgencyDetails: new Route({
        displayName: 'Partner Details',
        path: `${agencyRouteBase}/details`,
        component: <AgencyDetails />,
        beforeEnter: () =>
            routeCheckAll([
                checkRouteAccess({
                    userPermissions: { resource: 'agencies', action: 'view' }
                }),
                checkPasswordReset()
            ])
    }),
    AgencyCreate: new Route({
        displayName: 'New Partner',
        path: routeBasename + '/agencies/new',
        component: <AgencyDetails />,
        beforeEnter: () =>
            routeCheckAll([
                checkRouteAccess({
                    userPermissions: { resource: 'agencies', action: 'create' }
                }),
                checkPasswordReset()
            ])
    }),

    AgencyHostSettings: new Route({
        displayName: 'Email Sender Profile',
        path: `${agencyRouteBase}/host`,
        component: <AgencyHostSettings />,
        beforeEnter: () =>
            routeCheckAll([
                checkRouteAccess({
                    userPermissions: { resource: 'agencies', action: 'update' }
                }),
                checkPasswordReset()
            ])
    }),
    AgencyEmailSenderProfile: new Route({
        displayName: 'Email Sender Profile',
        path: `${agencyRouteBase}/email-sender-profile`,
        component: <EmailSenderProfile />,
        beforeEnter: () =>
            routeCheckAll([
                checkRouteAccess({
                    userPermissions: { resource: 'agencies', action: 'update' }
                }),
                checkPasswordReset()
            ])
    })
};

const CompanyCreateAgencyRoutes = {
    AgencyCompanyCreate: new Route({
        path: `${agencyRouteBase}/company/new`,
        component: <CompanyCreate />,
        displayName: 'New Company',
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'create'), checkPasswordReset()])
    })
};

// Question Definition Presets route
const AgencyQuestionDefinitionPresetRoutes = {
    AgencyQuestionDefinitionPresetPage: new Route({
        displayName: 'Question Presets',
        path: `${agencyRouteBase}/question-definition-presets`,
        component: <QuestionDefinitionPresets />,
        beforeEnter: routeCheckAll([checkUserPermissions('agencies', 'update'), checkPasswordReset()])
    })
};

const AgencyToolsRoutes = {
    AgencyCompanyLists: new Route({
        displayName: 'Company Lists',
        path: routeBasename + '/company-lists',
        component: <CompanyLists />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'view'), checkPasswordReset()])
    }),
    AgencyEmailViewer: new Route({
        displayName: 'Email Viewer',
        path: `${agencyRouteBase}/email-viewer`,
        component: <EmailViewer />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'view'), checkPasswordReset()])
    }),
    AgencyOptOutQueueList: new Route({
        displayName: 'CCPA Opt Out Queue',
        path: `${agencyRouteBase}/opt-out-queue`,
        component: <OptOutQueueList />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'view'), checkPasswordReset()])
    }),
    AgencyAuditLog: new Route({
        path: `${agencyRouteBase}/audit`,
        component: <AuditLogs />,
        displayName: 'Audit Logs',
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'dashboard'), checkPasswordReset()])
    }),
    AgencyCssToolsList: new Route({
        displayName: 'CSS Tools',
        path: agencyRouteBase + '/css-tool',
        component: <CssToolList />,
        beforeEnter: routeCheckAll([checkUserPermissions('roles', 'view'), checkPasswordReset()])
    }),
    AgencyCsvImport: new Route({
        displayName: 'CSV Import',
        path: agencyRouteBase + '/csv-import',
        component: <CsvImport />,
        beforeEnter: routeCheckAll([checkUserPermissions('roles', 'view'), checkPasswordReset()])
    })
};

export const AgencyRoutes = {
    ...DashboardRoutes,
    ...AgencySettingsRoutes,
    ...AgencyToolsRoutes,
    ...AgencyQuestionDefinitionPresetRoutes,
    ...CompanyCreateAgencyRoutes,

    AgencyUsers: new Route({
        displayName: 'Users',
        path: `${agencyRouteBase}/users`,
        component: <UserList />,
        beforeEnter: routeCheckAll([checkUserPermissions('users', 'view'), checkPasswordReset()])
    }),

    AgencyUserEdit: new Route({
        displayName: 'Users',
        path: `${agencyRouteBase}/users/:id`,
        component: <UserEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('users', 'view'), checkPasswordReset()])
    }),

    AgencyLeadView: new Route({
        displayName: 'Leads',
        path: `${agencyRouteBase}/leads`,
        component: <LeadView />,
        beforeEnter: routeCheckAll([checkUserPermissions('leads', 'view'), checkPasswordReset()])
    }),

    AgencyLeadDetailsByCustomer: new Route({
        displayName: 'Lead Details',
        path: `${agencyRouteBase}/leads/:customerId`,
        component: <LeadDetails />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    }),

    AgencyInsiteMetricDataList: new Route({
        displayName: 'Insite Metric',
        path: `${agencyRouteBase}/insite-metric-data`,
        component: <InsiteMetricDataList />,
        beforeEnter: routeCheckAll([checkUserPermissions('insite', 'view'), checkPasswordReset()])
    }),

    AgencyInsitePageList: new Route({
        displayName: 'Insite Metric',
        path: `${agencyRouteBase}/insite-page`,
        component: <InsitePageList />,
        beforeEnter: routeCheckAll([checkUserPermissions('insite', 'view'), checkPasswordReset()])
    }),

    AgencyInsiteDefinedFieldsList: new Route({
        displayName: 'Insite Metric',
        path: `${agencyRouteBase}/insite-defined-fields`,
        component: <InsiteDefinedFieldsList />,
        beforeEnter: routeCheckAll([checkUserPermissions('insite', 'view'), checkPasswordReset()])
    }),

    AgencyInsiteDefinedFieldsEdit: new Route({
        displayName: 'Insite Metric',
        path: `${agencyRouteBase}/insite-defined-fields/:id`,
        component: <InsiteDefinedFieldsEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('insite', 'view'), checkPasswordReset()])
    }),

    AgencyCategoryList: new Route({
        displayName: 'Business Categories',
        path: `${agencyRouteBase}/category`,
        component: <CategoryList />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    AgencyCategoryEdit: new Route({
        displayName: 'Business Categories',
        path: `${agencyRouteBase}/category/:id`,
        component: <CategoryEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    // Note: holding off on csv CssToolListimport for agency layer for now since there is no template provided to end user
    // If we do add this back in the future, we will need to update AdminSettingsMenuItem to include it
    // AgencyCsvImport: new Route({
    //     path: `${agencyRouteBase}/csv-import`,
    //     component: <CsvImport />,
    //     displayName: 'CSV Import',
    //     beforeEnter: checkUserPermissions('roles', 'view')
    // }),

    AgencyHolidaysList: new Route({
        displayName: 'Holidays',
        path: `${agencyRouteBase}/holidays`,
        component: <CalendarDayoffList />,
        beforeEnter: routeCheckAll([checkUserPermissions('calendars_daysoff', 'view'), checkPasswordReset()])
    }),

    AgencyHolidaysEdit: new Route({
        displayName: 'Holidays',
        path: `${agencyRouteBase}/holidays/:id`,
        component: <CalendarDayoffEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('calendars_daysoff', 'view'), checkPasswordReset()])
    }),

    AgencyMessages: new Route({
        displayName: 'Messages',
        path: `${agencyRouteBase}/messages/:messageId?`,
        component: <Messages />,
        beforeEnter: routeCheckAll([checkUserPermissions('roles', 'view'), checkPasswordReset()])
    }),

    AgencyClarityDashboard: new Route({
        displayName: 'CLarity Attribution',
        path: `${agencyRouteBase}/clarity/dashboard`,
        component: <ClarityDashboard />,
        beforeEnter: routeCheckAll([checkUserPermissions('stat', 'view'), checkPasswordReset()])
    })
};
