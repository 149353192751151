export type CrmModals = 'none' | 'deleteCrm' | 'copyCrm';
export type CrmReducerNames = 'closeModal' | 'openModal';

export interface CrmReducerState {
    modal: {
        active: CrmModals;
        data: any;
    };
}

export interface CrmReducerAction {
    name: keyof typeof crmReducers;
    payload?: any;
}

export const crmReducers = {
    closeModal: (state: CrmReducerState) => ({
        ...state,
        modal: { ...state.modal, active: 'none' }
    }),
    openModal: (state: CrmReducerState, modal: CrmReducerState['modal']) => ({
        ...state,
        modal
    }),
    setModalData: (state: CrmReducerState, data: CrmReducerState['modal']['data']) => ({
        ...state,
        modal: {
            ...state.modal,
            data
        }
    })
};

export const crmReducer = (state: CrmReducerState, { name, payload }: CrmReducerAction) => {
    if (!crmReducers[name]) {
        throw new Error(`reducer ${name} not defined`);
    }

    return crmReducers[name](state, payload);
};

export const crmInitialState: CrmReducerState = {
    modal: {
        active: 'none',
        data: null
    }
};

export const openModal = (dispatch: React.Dispatch<CrmReducerAction>, name: CrmModals, data?: any) =>
    dispatch({ name: 'openModal', payload: { active: name, data } });

export const closeModal = (dispatch: React.Dispatch<CrmReducerAction>) => dispatch({ name: 'closeModal' });

export const setModalData = (dispatch: React.Dispatch<CrmReducerAction>, data: any) =>
    dispatch({ name: 'setModalData', payload: data });
