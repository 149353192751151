import React, { FC, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { get as _get } from 'lodash';
import {
    CroppedImage,
    FileUpload,
    FileUploaderProps,
    FileUploaderWithImageCropper,
    ImageCropperProps,
    useSnackbar
} from '..';
import useStore from '../../../store/useStore';
import { FormikProps } from 'formik';
import { FileUploadApplication, uploadFileToS3 } from './S3FileUploader.helpers';

export interface S3FileUploaderProps {
    name: string;
    data?: any;
    formikProps: FormikProps<any>;
    application: FileUploadApplication;
    imageCropperProps?: Omit<ImageCropperProps, 'onSave'> & {
        onSave: (fileUrl: string) => void;
    };
    fileUploaderProps?: Partial<FileUploaderProps>;
}

export const S3FileUploader: FC<S3FileUploaderProps> = ({
    formikProps,
    name,
    application,
    data,
    fileUploaderProps,
    imageCropperProps
}) => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const [uploadingFile, setUploadingFile] = useState<boolean>(false);
    const files = _get(formikProps.values, name, []);

    const { onSave: onCropSave, onCancel: onCropCancel, ...imageCropperPropsRest } = imageCropperProps || {};

    const setImageFile = (file: FileUpload[] | null) => formikProps.setFieldValue(name, file ? [file] : null);

    const clearFile = () => {
        setImageFile(null);
    };

    const handleDropRejected = (rejectedFiles: FileRejection[]) => {
        if (rejectedFiles.length) {
            const errorMessages =
                rejectedFiles.length > 1 ? ['Too many files'] : rejectedFiles[0].errors.map(error => error.message);

            addSnackbar(`${errorMessages.join('. ')}.`, { variant: 'error' });
        }
    };

    const handleCropSave = (croppedImage: CroppedImage) => {
        setUploadingFile(true);
        addSnackbar('Uploading file...');

        setTimeout(async () => {
            const [fileUrl, error] = await uploadFileToS3({
                file: croppedImage.file,
                application,
                data,
                store
            });

            setUploadingFile(false);

            if (error) {
                addSnackbar(`File failed to upload. Please try again.`, { variant: 'error' });
                clearFile();
                return;
            }

            if (!fileUrl) {
                clearFile();
                return;
            }

            addSnackbar('File uploaded!', { variant: 'success' });

            setImageFile({ ...files[0], preview: fileUrl });

            if (onCropSave) {
                onCropSave(fileUrl);
            }
        }, 1000);
    };

    const handleCropCancel = () => {
        clearFile();

        if (onCropCancel) {
            onCropCancel();
        }
    };

    return (
        <FileUploaderWithImageCropper
            name={name}
            formikProps={formikProps}
            fileUploaderProps={{
                multiple: false,
                accept: ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'],
                onDropRejected: handleDropRejected,
                disabled: uploadingFile,
                maxSize: 5242880, // 5 MB,
                ...fileUploaderProps
            }}
            imageCropperProps={{
                onSave: handleCropSave,
                onCancel: handleCropCancel,
                ...imageCropperPropsRest
            }}
        />
    );
};
