import React from 'react';
import { observer } from 'mobx-react';
import { FormikHelpers } from 'formik';
import { AppPage, AppHeader, useSnackbar } from '../../components-v2/shared';
import { SelectCrmTypeForm } from './components';

import { emptyCrm } from '../../store/initial';
import useStore from '../../store/useStore';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { Crm } from '../../types/Crm';

export const CrmSelectType = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, crms } = store;
    const { companyId } = router.params;

    const initialValues = {
        ...emptyCrm,
        company_id: companyId
    };

    const handleSubmit = async ({ ...values }: Crm, actions: FormikHelpers<Crm>) => {
        try {
            const crmId = await crms.create({ ...values });
            addSnackbar(`Crm "${values.name}" has been added.`, { variant: 'success' });
            router.goTo(DefaultRoutes.CrmEdit, { companyId, crmId }, store);
        } catch (error) {
            actions.setErrors(error.response.data);
        }
    };

    return (
        <AppPage>
            <AppHeader title="CRM: Add New" onBack={() => router.goTo(DefaultRoutes.CrmList, { companyId }, store)} />
            <SelectCrmTypeForm enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit} />
        </AppPage>
    );
});
