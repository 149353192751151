import React, { FC, useState } from 'react';
import { ReactComponent as Illustration } from '../Clarity/EmptyReportsIllustration.svg';
import {
    ActionList,
    AppContent,
    AppHeader,
    AppList,
    AppListItem,
    AppListItemContent,
    AppPage,
    ButtonPrimary,
    IconButton,
    AppListEmpty,
    Button,
    Icon,
    Modal,
    ModalActions,
    ModalHeader,
    useSnackbar
} from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { ButtonOutline, handlePromise, useAsyncEffect } from '@lambdacurry/component-library';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { RemoteAuth } from '../../types';

interface RegistrationKey {
    id: number;
    type_id: number;
    link: string;
}

export const RemoteAuthList: FC<{}> = ({}) => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { Api, activeCompany, router } = store;

    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
    const [linkCopied, setLinkCopied] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number>();
    const [registrationKey, setRegistrationKey] = useState<RegistrationKey>();
    const [providerType, setProviderType] = useState<string>('');

    const [remoteAuths, setRemoteAuths] = useState<Array<RemoteAuth>>();

    const fetchCompanyAuthTokens = async () => {
        if (!activeCompany || !activeCompany.id) {
            addSnackbar(`No company selected. Cannot load company auth tokens.`, { variant: 'warning' });
            router.goTo(DefaultRoutes.Home, { ...router.params }, store);

            return Promise.resolve();
        }

        const [response, err] = await handlePromise(Api.client.get(`external/auth/tokens/${activeCompany?.id}`));

        if (err) {
            // TODO: handle error
            return;
        }
        setRemoteAuths(response.data);
    };

    useAsyncEffect(fetchCompanyAuthTokens);

    const deleteButtonHandler = async (id?: number) => {
        if (!activeCompany || !activeCompany.id) {
            addSnackbar(`No company selected. Cannot delete API Keys.`, { variant: 'warning' });
            router.goTo(DefaultRoutes.Home, { ...router.params }, store);
            return Promise.resolve();
        }
        const [result, err] = await handlePromise(Api.client.delete(`external/auth/${id}`));
        if (err) {
            addSnackbar(`Failed to remove auth token.`, {
                variant: 'error'
            });
            return;
        }
        if (result) {
            addSnackbar(`Auth token has been removed.`, {
                variant: 'success'
            });
        }
        await fetchCompanyAuthTokens();
    };

    const getRemoteAuthRegistrationLinks = async () => {
        if (!activeCompany || !activeCompany.id) {
            addSnackbar(`No company selected. Cannot register new auth tokens.`, { variant: 'warning' });
            router.goTo(DefaultRoutes.Home, { ...router.params }, store);
            return Promise.resolve();
        }
        const regLinkData = await Api.getExternalCalendarRegLinks(activeCompany.id);
        return regLinkData;
    };

    function getTypeBasedOnTypeId(typeId) {
        switch (typeId) {
            case 1:
                return 'Microsoft Outlook';
            case 4:
                return 'Zoho ';
            default:
                return 'Google GMail';
        }
    }

    const newItemButtonHandler = async (typeId: number) => {
        const result = await getRemoteAuthRegistrationLinks();
        if (result && Array.isArray(result.data)) {
            const item = result.data.find((rk: RegistrationKey) => {
                return rk.type_id === typeId;
            });
            if (!item) {
                addSnackbar(`Could not generate auth token registration link.`, {
                    variant: 'error'
                });
                return;
            }
            setRegistrationKey(item);
            const type = getTypeBasedOnTypeId(typeId);
            setProviderType(type);
            addSnackbar(`New auth token has been generated.`, {
                variant: 'success'
            });

            setCreateModalOpen(true);
        }
    };

    const createNewAuthTokenButton = (
        <>
            <p>
                <ButtonPrimary onClick={() => newItemButtonHandler(4)}>Zoho Token Request</ButtonPrimary>
            </p>
            <p>
                <ButtonPrimary onClick={() => newItemButtonHandler(2)}>Google GMail Token Request</ButtonPrimary>
            </p>
            <p>
                <ButtonPrimary onClick={() => newItemButtonHandler(1)}>Microsoft Outlook Token Request</ButtonPrimary>
            </p>
        </>
    );

    return (
        <AppPage>
            <AppHeader title="Remote Auth Token Manager">
                <ActionList position="end">{createNewAuthTokenButton}</ActionList>
            </AppHeader>
            <AppContent>
                Remote authorization keys can be configured for both Microsoft (Outlook) and Google (Gmail) resources.
                Select the appropriate service below to get a <strong>registration link</strong>. The registration link
                is used to request access to the remote service (either Outlook or Gmail). When completed successfully,
                a new key will be added to the list on the bottom of this page.
            </AppContent>
            <AppContent>
                {Array.isArray(remoteAuths) &&
                    (!remoteAuths?.length ? (
                        <AppListEmpty
                            illustration={<Illustration />}
                            title="Generate New Auth Token"
                            action={createNewAuthTokenButton}
                        />
                    ) : (
                        <AppList>
                            {remoteAuths.map(({ id, name, type_name }: RemoteAuth, index: number) => (
                                <AppListItem key={index}>
                                    <AppListItemContent title={name} />
                                    <AppListItemContent title={type_name} />
                                    <ActionList position="end">
                                        <IconButton
                                            icon="trash"
                                            onClick={() => {
                                                setSelectedId(id);
                                                setDeleteModalOpen(true);
                                            }}
                                        />
                                    </ActionList>
                                </AppListItem>
                            ))}
                        </AppList>
                    ))}
            </AppContent>
            <Modal isOpen={deleteModalOpen} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="color-danger" />
                            Are you sure you want to remove this remote auth token?
                        </>
                    }
                />
                <p>This action cannot be undone.</p>
                <ModalActions>
                    <ActionList position="end">
                        <Button
                            onClick={() => {
                                setDeleteModalOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <ButtonPrimary
                            onClick={() => {
                                deleteButtonHandler(selectedId);
                                setDeleteModalOpen(false);
                            }}
                        >
                            Confirm
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>
            {registrationKey && (
                <Modal isOpen={createModalOpen} closeButton={false}>
                    <ModalHeader
                        title={
                            <>
                                <Icon name="check" className="color-success" />
                                Auth Token Request Link
                            </>
                        }
                    />
                    <div>
                        <ButtonPrimary
                            onClick={() => {
                                window.open(registrationKey?.link, '_blank');
                            }}
                        >
                            Launch Registration Request
                        </ButtonPrimary>
                        <p>Use the above link to authorize TCC to connect to your {providerType} account.</p>
                    </div>
                    <div>
                        <ButtonOutline
                            hidden={linkCopied}
                            onClick={() => {
                                navigator.clipboard.writeText(registrationKey.link);
                                setLinkCopied(true);
                                setTimeout(() => {
                                    setLinkCopied(false);
                                }, 2000);
                            }}
                        >
                            Copy Link
                        </ButtonOutline>
                        <ButtonOutline hidden={!linkCopied}>Copied!</ButtonOutline>
                    </div>
                    <p>
                        You can also copy-paste the link using the "Copy Link" button to give to owner of the {' '}
                         {providerType} account if you do not have access or sufficient rights.
                    </p>
                    <ModalActions>
                        <ActionList position="end">
                            <ButtonPrimary
                                onClick={async () => {
                                    setCreateModalOpen(false);
                                    await fetchCompanyAuthTokens();
                                }}
                            >
                                Close
                            </ButtonPrimary>
                        </ActionList>
                    </ModalActions>
                </Modal>
            )}
        </AppPage>
    );
};
