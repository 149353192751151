import { RemoteCalendarAdapter } from './RemoteCalendarAdapter';
import Api from '../../../util/api';

export default class ScheduleRCA implements RemoteCalendarAdapter {
    private Api: Api;
    private scheduleId: number;

    public title = 'Scheduled Blocked Time';

    public pushDescription = `The sync for Scheduled Blocked Time with remote calendars is one-way
    only. This means that any blocked time off entries created within this dashboard will not be seen
    on the remote calendar. Blocked time off events created on the remote calendar will automatically
    be copied to the dashboard. Also, time off events that are deleted on the remote calendar
    will be deleted on the dashboard as well.`;

    public constructor(internalApi: Api, scheduleId: number) {
        this.Api = internalApi;
        this.scheduleId = scheduleId;
    }

    public getExternalCalendar = async () => {
        return await this.Api.getExternalCalendarForSchedule(this.scheduleId);
    };

    public assignToken = async (tokenId: number) => {
        await this.Api.assignTokenToSchedule(this.scheduleId, { external_auth_id: tokenId })!;
    };

    public unlinkToken = async () => {
        await this.Api.unlinkTokenFromSchedule(this.scheduleId)!;
    };
}
