import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import {
    Announcements,
    closestCenter,
    CollisionDetection,
    DragOverlay,
    DndContext,
    DropAnimation,
    defaultDropAnimation,
    KeyboardSensor,
    Modifiers,
    MouseSensor,
    LayoutMeasuring,
    PointerActivationConstraint,
    ScreenReaderInstructions,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    useSortable,
    SortableContext,
    sortableKeyboardCoordinates,
    SortingStrategy,
    rectSortingStrategy,
    AnimateLayoutChanges
} from '@dnd-kit/sortable';

import { createRange } from './utilities';
import { Item, List, Wrapper } from '.';
import { isEqual } from 'lodash';

export interface Props {
    getClassNames?: (value: any) => string;
    activationConstraint?: PointerActivationConstraint;
    animateLayoutChanges?: AnimateLayoutChanges;
    adjustScale?: boolean;
    collisionDetection?: CollisionDetection;
    Container?: any; // To-do: Fix me
    dropAnimation?: DropAnimation | null;
    itemCount?: number;
    items?: any[];
    handle?: boolean;
    layoutMeasuring?: Partial<LayoutMeasuring>;
    modifiers?: Modifiers;
    renderItem?: any;
    removable?: boolean;
    strategy?: SortingStrategy;
    useDragOverlay?: boolean;
    getItemStyles?(args: {
        id: UniqueIdentifier;
        index: number;
        isSorting: boolean;
        isDragOverlay: boolean;
        overIndex: number;
        isDragging: boolean;
    }): React.CSSProperties;
    wrapperStyle?(args: { index: number; isDragging: boolean; id: string }): React.CSSProperties;
    isDisabled?(id: UniqueIdentifier): boolean;
    onDragEnd?: (arr: any[]) => void;
}

const defaultDropAnimationConfig: DropAnimation = {
    ...defaultDropAnimation,
    dragSourceOpacity: 0.5
};

const screenReaderInstructions: ScreenReaderInstructions = {
    draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `
};

export function Sortable({
    getClassNames = () => '',
    activationConstraint,
    animateLayoutChanges,
    adjustScale = false,
    Container = List,
    collisionDetection = closestCenter,
    dropAnimation = defaultDropAnimationConfig,
    getItemStyles = () => ({}),
    handle = false,
    itemCount = 16,
    items: initialItems,
    isDisabled = () => false,
    layoutMeasuring,
    modifiers,
    removable,
    renderItem,
    strategy = rectSortingStrategy,
    useDragOverlay = true,
    wrapperStyle = () => ({}),
    onDragEnd
}: Props) {
    const [items, setItems] = useState<any[]>(
        () => initialItems ?? createRange<string>(itemCount, index => (index + 1).toString())
    );
    const [activeId, setActiveId] = useState<string | null>(null);

    useEffect(() => {
        if (initialItems && !isEqual(items, initialItems)) {
            setItems(initialItems);
        }
    }, [items, initialItems]);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint
        }),
        useSensor(TouchSensor, {
            activationConstraint
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );
    const getIndex = (id: string) => items.findIndex(item => item.id === id);
    const getPosition = (id: string) => getIndex(id) + 1;
    const activeIndex = activeId ? getIndex(activeId) : -1;
    const handleRemove = removable ? (id: string) => setItems(items => items.filter(item => item !== id)) : undefined;
    const announcements: Announcements = {
        onDragStart(id) {
            return `Picked up sortable item ${id}. Sortable item ${id} is in position ${getPosition(id)} of ${items.length
                }`;
        },
        onDragOver(id, overId) {
            if (overId) {
                return `Sortable item ${id} was moved into position ${getPosition(overId)} of ${items.length}`;
            }

            return;
        },
        onDragEnd(id, overId) {
            if (overId) {
                return `Sortable item ${id} was dropped at position ${getPosition(overId)} of ${items.length}`;
            }

            return;
        },
        onDragCancel(id) {
            return `Sorting was cancelled. Sortable item ${id} was dropped.`;
        }
    };

    if (!items) {
        return null;
    }

    return (
        <DndContext
            announcements={announcements}
            screenReaderInstructions={screenReaderInstructions}
            sensors={sensors}
            collisionDetection={collisionDetection}
            onDragStart={({ active }) => {
                if (!active) {
                    return;
                }

                setActiveId(active.id);
            }}
            onDragEnd={({ over }) => {
                setActiveId(null);

                if (over) {
                    const overIndex = getIndex(over.id);
                    if (activeIndex !== overIndex) {
                        const newArray = arrayMove(items, activeIndex, overIndex);
                        onDragEnd?.(newArray);

                        setItems(newArray);
                    }
                }
            }}
            onDragCancel={() => setActiveId(null)}
            layoutMeasuring={layoutMeasuring}
            modifiers={modifiers}
        >
            <Wrapper center>
                <SortableContext items={items} strategy={strategy}>
                    <Container>
                        {items.map((value, index) => (
                            <SortableItem
                                className={getClassNames(value)}
                                key={value.id}
                                value={value}
                                handle={handle}
                                index={index}
                                style={getItemStyles}
                                wrapperStyle={wrapperStyle}
                                disabled={isDisabled(value.id)}
                                renderItem={renderItem}
                                onRemove={handleRemove}
                                animateLayoutChanges={animateLayoutChanges}
                                useDragOverlay={useDragOverlay}
                            />
                        ))}
                    </Container>
                </SortableContext>
            </Wrapper>
            {useDragOverlay
                ? createPortal(
                    <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
                        {activeId ? (
                            <Item
                                className={getClassNames(items[activeIndex])}
                                value={items[activeIndex]}
                                handle={handle}
                                renderItem={renderItem}
                                wrapperStyle={wrapperStyle({
                                    index: activeIndex,
                                    isDragging: true,
                                    id: items[activeIndex].id
                                })}
                                style={getItemStyles({
                                    id: items[activeIndex].id,
                                    index: activeIndex,
                                    isSorting: activeId !== null,
                                    isDragging: true,
                                    overIndex: -1,
                                    isDragOverlay: true
                                })}
                                dragOverlay
                            />
                        ) : null}
                    </DragOverlay>,
                    document.body
                )
                : null}
        </DndContext>
    );
}

interface SortableItemProps {
    className: string;
    animateLayoutChanges?: AnimateLayoutChanges;
    disabled?: boolean;
    value: any;
    index: number;
    handle: boolean;
    useDragOverlay?: boolean;
    onRemove?(id: string): void;
    style(values: any): React.CSSProperties;
    renderItem?(args: any): React.ReactElement;
    wrapperStyle({ index, isDragging, id }: { index: number; isDragging: boolean; id: string }): React.CSSProperties;
}

export function SortableItem({
    className,
    disabled,
    animateLayoutChanges,
    value,
    index,
    handle,
    onRemove,
    style,
    renderItem,
    useDragOverlay,
    wrapperStyle
}: SortableItemProps) {
    const { attributes, isDragging, isSorting, listeners, overIndex, setNodeRef, transform, transition } = useSortable({
        animateLayoutChanges,
        id: value.id,
        disabled
    });

    return (
        <Item
            className={className}
            ref={setNodeRef}
            value={value}
            disabled={disabled}
            dragging={isDragging}
            sorting={isSorting}
            handle={handle}
            renderItem={renderItem}
            index={index}
            style={style({
                index,
                id: value.id,
                isDragging,
                isSorting,
                overIndex
            })}
            onRemove={onRemove ? () => onRemove(value.id) : undefined}
            transform={transform}
            transition={!useDragOverlay && isDragging ? 'none' : transition}
            wrapperStyle={wrapperStyle({ index, isDragging, id: value.id })}
            listeners={listeners}
            data-index={index}
            data-id={value.id}
            dragOverlay={!useDragOverlay && isDragging}
            {...attributes}
        />
    );
}
