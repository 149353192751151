import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Illustration } from './OnboardingIllustration2.svg';
import * as Yup from 'yup';
import styles from './company-onboarding.module.scss';
import './company-onboarding.global.scss';
import { ButtonPrimary, Form, InputText, StepChecklist, Title, useSnackbar } from '../../components-v2/shared';
import { observer } from 'mobx-react';
import useStore from '../../store/useStore';
import { FastField, FieldArray, FieldProps, FormikHelpers } from 'formik';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { ButtonLink } from '@lambdacurry/component-library';
import { errorMessages } from '../../util/validations/errorMessages';
import { handlePromise } from '../../util/async';
import { AgencyLogo } from '../Agency/AgencyLogo';

const companyOnboardingInviteTeamFormSchema = Yup.object().shape({
    emails: Yup.array().of(Yup.string().email(errorMessages.email.valid))
});

export const CompanyOnboardingInviteTeamPage: React.FC<{}> = observer(() => {
    const { store, agencyStore } = useStore();
    const {
        companyOnboardingFormData: { response }
    } = agencyStore;
    const { router } = store;
    const { addSnackbar } = useSnackbar();

    const companyId = response?.companies[0].id;

    const continueOn = () =>
        router.goTo(DefaultRoutes.Home, router.params, store, { companyOnboardingSuccess: 'true' });

    const handleSubmit: (
        values: {
            emails: string[];
        },
        formikHelpers: FormikHelpers<{
            emails: string[];
        }>
    ) => void | Promise<any> = async formValues => {
        const emails = formValues.emails.filter(email => email !== '');

        if (!emails.length) {
            return continueOn();
        }

        const [response, errors] = await handlePromise(
            store.Api.client.post(`/company/${companyId}/invite-users`, { emails })
        );

        if (!response || errors) {
            addSnackbar(`Error sending email invite, please add new teammates manually.`, {
                variant: 'error'
            });
        }

        addSnackbar(`Invitations have been sent to the email addresses you provided.`, {
            variant: 'success'
        });

        return continueOn();
    };

    return (
        <div className={classNames('company-onboarding', styles.companyOnboarding, styles.companyOnboardingInviteTeam)}>
            <header>
                <div className={styles.companyOnboardingAgencyLogo}>
                    <AgencyLogo />
                </div>

                <StepChecklist
                    className={styles.companyOnboardingStepChecklist}
                    steps={['Credentials', 'Company Info', 'Enable SMS', 'Embed App', 'Invite Team']}
                    currentStepIndex={4}
                />

                <Illustration className={classNames(styles.companyOnboardingIllustration)} />
            </header>
            <main className={styles.companyOnboardingContent}>
                <Title>Invite your teammates</Title>
                <p className={styles.companyOnboardingSubtitle}>
                    Invite team members to your organization. Enter their emails below and we’ll send them an invite to
                    your organization. You can also add more team members later.
                </p>
                <Form
                    enableReinitialize
                    initialValues={{ emails: ['', '', ''] }}
                    validationSchema={companyOnboardingInviteTeamFormSchema}
                    onSubmit={handleSubmit}
                >
                    {formikProps => (
                        <>
                            <FieldArray name="emails">
                                {arrayHelpers => {
                                    const emails: string[] = formikProps.values['emails'];
                                    if (emails.length < 1) {
                                        arrayHelpers.push('');
                                        return null; // Make sure we return null the first time to avoid console warnings.
                                    }

                                    return (
                                        <>
                                            {emails.map((_, index) => {
                                                const inputName = `emails.${index}`;
                                                return (
                                                    <div
                                                        key={inputName}
                                                        className={styles['concession-manager-paths-row']}
                                                    >
                                                        <FastField name={inputName}>
                                                            {(fieldProps: FieldProps) => (
                                                                <>
                                                                    <InputText
                                                                        label={index === 0 ? 'Email' : false}
                                                                        labelPlacement="above"
                                                                        placeholder="example@email.com"
                                                                        {...fieldProps.field}
                                                                        error={!!fieldProps.meta.error}
                                                                        helperText={
                                                                            fieldProps.meta.touched &&
                                                                            fieldProps.meta.error
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </FastField>
                                                    </div>
                                                );
                                            })}
                                            <ButtonLink onClick={() => arrayHelpers.push('')}>+ Add Another</ButtonLink>
                                        </>
                                    );
                                }}
                            </FieldArray>
                            <div className={styles.companyOnboardingActions}>
                                <ButtonLink onClick={continueOn}>Skip this for now</ButtonLink>
                                <ButtonPrimary type="submit">Send Invite</ButtonPrimary>
                            </div>
                        </>
                    )}
                </Form>
            </main>
        </div>
    );
});
