import React, { useEffect } from 'react';
import classNames from 'classnames';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { Transform } from '@dnd-kit/utilities';

import { Handle, Remove } from './components';

import './Item.scss';

export interface Props {
    className: string;
    dragOverlay?: boolean;
    color?: string;
    disabled?: boolean;
    dragging?: boolean;
    handle?: boolean;
    height?: number;
    index?: number;
    fadeIn?: boolean;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    sorting?: boolean;
    style?: React.CSSProperties;
    transition?: string | null;
    wrapperStyle?: React.CSSProperties;
    value: React.ReactNode;
    onRemove?(): void;
    renderItem?(args: {
        className: string;
        dragOverlay: boolean;
        dragging: boolean;
        sorting: boolean;
        index: number | undefined;
        fadeIn: boolean;
        listeners: DraggableSyntheticListeners;
        ref: React.Ref<HTMLElement>;
        style: React.CSSProperties | undefined;
        transform: Props['transform'];
        transition: Props['transition'];
        value: Props['value'];
        handle: boolean | undefined;
    }): React.ReactElement;
}

export const Item = React.memo(
    React.forwardRef<HTMLLIElement, Props>(
        (
            {
                className,
                color,
                dragOverlay,
                dragging,
                disabled,
                fadeIn,
                handle,
                height,
                index,
                listeners,
                onRemove,
                renderItem,
                sorting,
                style,
                transition,
                transform,
                value,
                wrapperStyle,
                ...props
            },
            ref
        ) => {
            useEffect(() => {
                if (!dragOverlay) {
                    return;
                }

                document.body.style.cursor = 'grabbing';

                return () => {
                    document.body.style.cursor = '';
                };
            }, [dragOverlay]);

            return renderItem ? (
                renderItem({
                    className,
                    dragOverlay: Boolean(dragOverlay),
                    dragging: Boolean(dragging),
                    sorting: Boolean(sorting),
                    index,
                    fadeIn: Boolean(fadeIn),
                    listeners,
                    ref,
                    style,
                    transform,
                    transition,
                    value,
                    handle
                })
            ) : (
                <li
                    className={classNames(
                        className,
                        'Wrapper',
                        fadeIn && 'fadeIn',
                        sorting && 'sorting',
                        dragOverlay && 'dragOverlay'
                    )}
                    style={
                        {
                            ...wrapperStyle,
                            transition,
                            '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
                            '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                            // Note: The below styles were in the original Item component, but do not seem to be needed
                            // leaving commented in case future instances need these styles and we can utilize a prop
                            // to enable them
                            // '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
                            // '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
                            '--index': index,
                            '--color': color
                        } as React.CSSProperties
                    }
                    ref={ref}
                >
                    <div
                        className={classNames(
                            'Item',
                            dragging && 'dragging',
                            handle && 'withHandle',
                            dragOverlay && 'dragOverlay',
                            disabled && 'disabled',
                            color && 'color'
                        )}
                        style={style}
                        data-cypress="draggable-item"
                        {...(!handle ? listeners : undefined)}
                        {...props}
                        tabIndex={!handle ? 0 : undefined}
                    >
                        {value}
                        <span className={'Actions'}>
                            {onRemove ? <Remove className={'Remove'} onClick={onRemove} /> : null}
                            {handle ? <Handle {...listeners} /> : null}
                        </span>
                    </div>
                </li>
            );
        }
    )
);
