import React, { FC, useMemo } from 'react';
import { InputPhone as LCInputPhone } from '@lambdacurry/component-library';
import { OutlinedTextFieldProps } from '@material-ui/core';
import { FormikProps } from 'formik';
import { get as _get, set as _set } from 'lodash';
import { runInAction } from 'mobx';

// TODO: Get types from component library
type LabelPlacements = 'inset' | 'above';
interface InputConfig {
    labelPlacement?: 'inset' | 'above';
    safeName?: boolean;
    inputBorderWhite?: boolean;
    shrinkLabel?: boolean;
}

type InputProps = Omit<OutlinedTextFieldProps, 'variant'> & {
    name: string;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    formikProps?: FormikProps<any>;
    labelPlacement?: LabelPlacements;
    variant?: 'outlined'; // Don't remove any typing because this breaks the build. - Jake
    inputConfig?: InputConfig;
};

export interface InputPhoneProps extends InputProps {
    name: string;
    label?: string;
    formikProps: FormikProps<any>;
}

const formatInitialPhoneValue = (value: string): string => {
    if (!value || value.match(/^\d{10}$/)) {
        return value;
    }

    if (value.match(/^\+\d+$/)) {
        return value.substr(-10);
    }

    return value;
};

export const InputPhone: FC<InputPhoneProps> = props => {
    const inputValue = _get(props.formikProps.values, props.name);
    // Note: LC-615 formatting the input value since the server adds a +1 when it is saved - Jake 12/16/2020
    useMemo(() => {
        runInAction(() => {
            _set(props.formikProps.values, props.name, formatInitialPhoneValue(inputValue));
        });
    }, [inputValue]);

    // TODO: get type from component library so we don't need to use an any type here
    return <LCInputPhone {...(props as any)} />;
};
