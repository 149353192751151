import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Formik, FormikHelpers, FormikProps, FormikState } from 'formik';
import { Col, PageHeader } from 'react-bootstrap';
import { InjectedProps } from '../../types';
import { OwnershipGroup } from '../../types/ownershipGroup';
import ControlButtonGroup from '../misc/ControlButtonGroup';
import OwnershipGroupForm, { IOwnershipGroup } from './OwnershipGroupForm';
import { emptyOwnershipGroup } from '../../store/initial';
import DefaultRoutes from '../../routes/DefaultRoutes';

interface OwnershipGroupEditState {
    editing: boolean;
}

@inject('store')
@observer
export class OwnershipGroupEdit extends React.Component<{}, OwnershipGroupEditState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state = {
        editing: false
    };

    public initialValues = (ownershipGroup: OwnershipGroup) => {
        const { isLoading } = this.injected.store;
        return isLoading ? emptyOwnershipGroup : ownershipGroup;
    };

    public get id() {
        return this.injected.store.router.params.id ? parseInt(this.injected.store.router.params.id, 10) : 0;
    }

    public getOwnershipCompany() {
        return this.injected.store.ownershipGroup.getItem(this.id);
    }

    public fetchData() {
        const { fetchOwnershipGroup } = this.injected.store;
        return fetchOwnershipGroup(this.id);
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public render() {
        const ownershipGroup = this.getOwnershipCompany();
        const initialValues = this.initialValues(ownershipGroup);

        if (!initialValues) {
            return null;
        }

        return (
            <>
                <PageHeader>Ownership Group</PageHeader>
                <Col className="fixed-controls">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={this.handleSubmit}
                    >{this.renderForm()}</Formik>
                </Col>
            </>
        );
    }

    public renderForm = () => {
        return OwnershipGroupForm({
            editing: this.state.editing,
            controls: (bag: FormikProps<IOwnershipGroup>) => (
                <ControlButtonGroup
                    editing={this.state.editing}
                    canEdit
                    canDelete={this.state.editing}
                    handleEdit={this.toggleEdit}
                    handleCancel={this.handleCancel(bag)}
                    handleBack={this.handleBack}
                    handleDelete={this.handleDelete}
                />
            )
        });
    };

    public toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    public handleCancel = (bag: FormikProps<IOwnershipGroup>) => () => {
        this.setState({ editing: false });
        bag.resetForm(bag.initialValues as Partial<FormikState<IOwnershipGroup>>);
    };

    public handleBack = () => {
        const { router } = this.injected.store;
        router.goTo(DefaultRoutes.OwnershipGroupList, {}, this.injected.store);
    };

    public handleSubmit = (values: OwnershipGroup, actions: FormikHelpers<OwnershipGroup>) => {
        actions.setSubmitting(true);
        const { ownershipGroup } = this.injected.store;
        return ownershipGroup
            .update(values)
            .then(() => {
                actions.setSubmitting(false);
                this.setState({ editing: false });
            })
            .catch(error => {
                actions.setErrors(error.response.data);
                actions.setSubmitting(false);
            });
    };

    protected handleDelete = () => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
            this.injected.store.deleteOwnershipGroup(this.id);
            this.handleBack();
        }
        return;
    };
}

export default OwnershipGroupEdit;
