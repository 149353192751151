import { Formik, FormikHelpers, FormikProps, FormikState } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, PageHeader } from 'react-bootstrap';
import { emptyPublicRole } from '../../store/initial';
import { InjectedProps, PublicRole } from '../../types';
import ControlButtonGroup from '../misc/ControlButtonGroup';
import CompanyRoleForm, { ICompanyRoleForm } from './CompanyRoleForm';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { apiServerURL } from '../../constants/env';
import { defaultWidgetColorTheme, defaultWidgetColorValues } from '../../constants';
import { getColorValues } from '../../util/colors';

interface CompanyRoleEditState {
    editing: boolean;
    activeColorFieldName: string;
}

@inject('store')
@observer
export class CompanyRoleEdit extends React.Component<{ firstItemId?: number }, CompanyRoleEditState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public textAreaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

    public state = {
        editing: false,
        activeColorFieldName: ''
    };

    public initialValues = (publicRole: PublicRole) => {
        const { isLoading } = this.injected.store;
        if (isLoading || !publicRole) {
            return emptyPublicRole;
        }

        return {
            ...publicRole,
            widget_disabled: !!publicRole.widget_disabled,
            widget_logo: '',
            widget_logo_url: publicRole.widget_logo ? `${apiServerURL}/images/${publicRole.widget_logo}` : ''
        };
    };

    public get id() {
        return this.props.firstItemId ?? 0;
    }

    public getCompanyRole() {
        return this.injected.store.publicRoles.getItem(this.id);
    }

    public componentDidMount() {
        addWidgetClasses();
        const { fetchPublicRoles, fetchCssPaths, fetchCalendars } = this.injected.store;
        return fetchPublicRoles()
            .then(() => fetchCssPaths())
            .then(() => fetchCalendars());
    }

    public componentWillUnmount = () => {
        removeWidgetClasses();
    };

    public render() {
        const app = this.getCompanyRole();
        const initialValues = this.initialValues(app);
        return (
            <React.Fragment>
                <PageHeader>App Details</PageHeader>
                <Col>
                    <Formik initialValues={initialValues} enableReinitialize onSubmit={this.handleSubmit}>
                        {(formikProps: FormikProps<ICompanyRoleForm>) => (
                            <CompanyRoleForm
                                formikProps={formikProps}
                                defaultSettings={defaultWidgetColorValues[defaultWidgetColorTheme]}
                                editing={this.state.editing}
                                activeColorFieldName={this.state.activeColorFieldName}
                                handleChangeColorFocus={this.handleChangeColorFocus}
                                textAreaRef={this.textAreaRef}
                                copyToClipboard={this.copyToClipboard}
                                controls={(bag: FormikProps<ICompanyRoleForm>) => (
                                    <ControlButtonGroup
                                        isSaving={formikProps.isSubmitting}
                                        editing={this.state.editing}
                                        canEdit
                                        canDelete={false}
                                        hideBack
                                        handleEdit={this.toggleEdit}
                                        handleCancel={this.handleCancel(bag)}
                                        handleBack={this.handleBack}
                                        handleDelete={this.handleDelete}
                                    />
                                )}
                            />
                        )}
                    </Formik>
                </Col>
            </React.Fragment>
        );
    }

    public copyToClipboard = () => {
        if (this.textAreaRef && this.textAreaRef.current) {
            this.textAreaRef.current.select();
            document.execCommand('copy');
        }
    };

    public toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    public handleCancel = (bag: FormikProps<ICompanyRoleForm>) => () => {
        bag.resetForm(bag.initialValues as Partial<FormikState<ICompanyRoleForm>>);
        this.setState({ editing: false });
    };

    public handleBack = () => {
        const { router } = this.injected.store;
        router.goTo(DefaultRoutes.ApplicationSettings, {}, this.injected.store);
    };

    public handleSubmit = (values: PublicRole, actions: FormikHelpers<PublicRole>) => {
        actions.setSubmitting(true);
        const { publicRoles } = this.injected.store;
        return publicRoles
            .update({
                id: values.id,
                skin: values.skin,
                is_mini: values.is_mini,
                css_id: values.css_id,
                company_id: values.company_id,
                ga_id: values.ga_id,
                gm_id: values.gm_id,
                beta_widget_enabled: values.beta_widget_enabled,
                brand_primary_color: values.brand_primary_color,
                brand_text_color: values.brand_text_color,
                widget_size: values.widget_size,
                widget_style: values.widget_style,
                widget_disabled: values.widget_disabled,
                widget_hidden: values.widget_hidden,
                widget_logo: values.widget_logo,
                use_custom_colors: values.use_custom_colors,
                ...getColorValues(values),
                widget_animation_style: values.widget_animation_style,
                widget_position: values.widget_position,
                widget_position_mobile: values.widget_position_mobile,
                widgets_order: values.widgets_order,
                widgets_order_mobile: values.widgets_order_mobile,
                hidden_widgets: values.hidden_widgets,
                hidden_widgets_mobile: values.hidden_widgets_mobile,
                widget_launcher_button: values.widget_launcher_button,
                widgetbar_expanded: values.widgetbar_expanded,
                widget_code_mobile_css: values.widget_code_mobile_css,
                widget_draggable: values.widget_draggable,
                widget_code_css: values.widget_code_css,
                widget_spacer_color: values.widget_spacer_color,
                widget_spacer_enabled: values.widget_spacer_enabled,
                widget_zoom_desktop: values.widget_zoom_desktop,
                widget_zoom_mobile: values.widget_zoom_mobile,
                widget_adjustments: {
                    left: +values.widget_adjustments.left || 0,
                    right: +values.widget_adjustments.right || 0,
                    top: +values.widget_adjustments.top || 0,
                    bottom: +values.widget_adjustments.bottom || 0,
                    mobile_left: +values.widget_adjustments.mobile_left || 0,
                    mobile_right: +values.widget_adjustments.mobile_right || 0,
                    mobile_top: +values.widget_adjustments.mobile_top || 0,
                    mobile_bottom: +values.widget_adjustments.mobile_bottom || 0
                },
                wallet_greeting_heading: values.wallet_greeting_heading,
                wallet_greeting_subheading: values.wallet_greeting_subheading
            })
            .then(() => {
                actions.setSubmitting(false);
                this.setState({ editing: false });
            })
            .catch(error => {
                actions.setSubmitting(false);
                actions.setErrors(error.response.data);
            });
    };

    public handleDelete = () => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
            this.injected.store.deleteCompanyRole(this.id);
            this.handleBack();
        }
    };

    public handleChangeColorFocus = (field: string) => {
        this.setState({
            activeColorFieldName: field
        });
    };
}

export default CompanyRoleEdit;

const addWidgetClasses = () => {
    const el = document.getElementById('main-container');
    if (el) {
        el.classList.add('widget-settings');
    }
};

const removeWidgetClasses = () => {
    const el = document.getElementById('main-container');
    if (el) {
        el.classList.remove('widget-settings');
    }
};
