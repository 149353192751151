export const RulesActionFactory = {
    ReplaceStringAction: (copyTo: string, name: string, picklist?: Array<{ id: number; description: string }>) => {
        return { actionType: 'ReplaceString', name, options: { copyTo, newValue: '' }, picklist };
    },
    ReplaceCrmEmailsAction: {
        actionType: 'ReplaceStringArray',
        name: 'Replace CRM Email',
        options: { copyTo: 'crm_emails', newValues: [] }
    }
};
