import React from 'react';
import styles from './public.module.scss';
import Card from '@material-ui/core/Card';
import useStore from '../../store/useStore';
import { Link } from 'mobx-router';
import DefaultRoutes from '../../routes/DefaultRoutes';

export const NotifyPasswordReset = () => {
    const { store } = useStore();

    return (
        <main className={styles['public-page']}>
            <div className={styles['public-reset']}>
                <Card className={styles['public-reset-card']}>
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21 0C9.408 0 0 9.408 0 21C0 32.592 9.408 42 21 42C32.592 42 42 32.592 42 21C42 9.408 32.592 0 21 0ZM21.0001 23.1C19.8451 23.1 18.9001 22.155 18.9001 21V12.6C18.9001 11.445 19.8451 10.5 21.0001 10.5C22.1551 10.5 23.1001 11.445 23.1001 12.6V21C23.1001 22.155 22.1551 23.1 21.0001 23.1ZM18.9001 27.3001V31.5001H23.1001V27.3001H18.9001Z" fill="#D85656"/>
                    </svg>
                    <h2>For security purposes your password needs to be reset.</h2>
                    <p><Link view={DefaultRoutes.ResetPassword} store={store}>Click here to update your password.</Link></p>
                </Card>
                <p>If you have any difficulties resetting your password please contact us at: <a href="mailto:help@clxmedia.com">help@clxmedia.com</a></p>
            </div>
        </main>
    );
}