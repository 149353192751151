import { Formik } from 'formik';
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, PageHeader, Row, Alert, Panel } from 'react-bootstrap';
import { InjectedProps } from '../../types';
import DefaultRoutes from '../../routes/DefaultRoutes';
import DefinedFieldsForm from './InsiteDefinedFieldsForm';

interface InsiteDefinedFieldsState {
    definedFields: {};
    selectItems: [];
    title: string;
    stored: boolean;
}

@inject('store')
@observer
class InsiteDefinedFieldsEdit extends React.Component<{}, InsiteDefinedFieldsState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state: InsiteDefinedFieldsState = {
        definedFields: {},
        selectItems: [],
        title: '',
        stored: false
    };

    public componentDidMount() {
        return this.fetchData(this.id);
    }

    public get id() {
        return this.injected.store.router.params.id ? parseInt(this.injected.store.router.params.id, 10) : 0;
    }

    public fetchData(id: number) {
        const { fetchDefineFields } = this.injected.store;
        return fetchDefineFields(id).then((response: any) => {
            this.setState({
                definedFields: response.data[0].defined_fields,
                selectItems: response.data[0].names_fields.split(','),
                title: response.data[0].host_uri
            });
        });
    }

    public render() {
        const initialValues = this.initialValues(this.state.definedFields);
        return (
            <>
                <Col className="col-lg-12 wrapper-holder__tible-leads">
                    {this.state.stored === true && (
                        <Alert bsStyle="success" onDismiss={this.handleDismissAlert}>
                            Insite defined fields have been changed successfully.
                        </Alert>
                    )}
                </Col>
                <PageHeader>Insite Defined Fields Details</PageHeader>
                <Col className="col-lg-12 wrapper-holder__tible-leads">
                    <Row>
                        <Panel bsStyle="primary" defaultExpanded>
                            <Panel.Heading>
                                <Panel.Title toggle>
                                    <h4>Customer fields</h4>
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body collapsible>
                                <Formik
                                    initialValues={initialValues}
                                    enableReinitialize
                                    onSubmit={this.handleSubmit}
                                >{this.renderForm()}</Formik>
                            </Panel.Body>
                        </Panel>
                    </Row>
                </Col>
            </>
        );
    }

    protected handleBack = () => {
        const { router } = this.injected.store;
        const { activeAgencyId } = this.injected.store.agencyStore;

        if (activeAgencyId) {
            router.goTo(DefaultRoutes.AgencyInsiteDefinedFieldsList, { agencyId: activeAgencyId }, this.injected.store);
        } else {
            router.goTo(DefaultRoutes.InsiteDefinedFieldsList, {}, this.injected.store);
        }
    };

    public initialValues = (definedFields: {}) => {
        return { ...definedFields, titleName: this.state.title };
    };

    public handleSubmit = (data: any) => {
        delete data.titleName;
        const { updateDefineFields } = this.injected.store;
        this.setState({ stored: true });
        updateDefineFields(this.id, data);
    };

    private handleDismissAlert = () => {
        this.setState({ stored: false });
    };

    public renderForm = () => {
        return DefinedFieldsForm({
            titleName: this.state.title,
            definedFields: this.state.definedFields,
            selectItems: this.state.selectItems,
            controls: (
                <>
                    <div className=" btn__holder-block">
                        <button className={'btn btn-primary btn-right'} type="submit">
                            Save
                        </button>
                        &nbsp;
                        <button className={'btn btn-default btn-right'} onClick={this.handleBack}>
                            Back
                        </button>
                    </div>
                </>
            )
        });
    };
}

export default InsiteDefinedFieldsEdit;
