import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { InputPhone, InputSelect, InputText } from '..';
import { LeadStatus } from '../../../types';

export interface LeadCustomerFieldGroupProps {
    formikProps: FormikProps<any>;
    enableSmsMessages: boolean;
}

export const LeadCustomerFieldGroup: FC<LeadCustomerFieldGroupProps> = ({ formikProps, enableSmsMessages }) => {
    const leadStatusesMap = [
        [LeadStatus.APPOINTMENT_SCHEDULED, 'Appointment Scheduled'],
        [LeadStatus.OPEN_OPPORTUNITY, 'Open Opportunity'],
        [LeadStatus.APPOINTMENT_COMPLETED, 'Completed Appointment'],
        [LeadStatus.APPOINTMENT_CANCELLED, 'Cancelled Appointment'],
        [LeadStatus.PURCHASED, 'Purchased'],
        [LeadStatus.NO_OPPORTUNITY, 'No Opportunity']
    ];
    const leadStatusOptions = leadStatusesMap.map(([value, label]) => ({ value, label }));

    return (
        <div className="field-group-content">
            <div className="field-group-col">
                <InputText name="first_name" label="First Name" formikProps={formikProps} />
                <InputText name="last_name" label="Last Name" formikProps={formikProps} />
                <InputText name="email" label="Email" formikProps={formikProps} />
            </div>
            <div className="field-group-col">
                <InputPhone name="phone_number" label="Phone Number" formikProps={formikProps} />
                <InputSelect
                    name="status"
                    label="Opportunity Status"
                    optionValueKey="value"
                    options={leadStatusOptions}
                    autocompleteConfig={{ disableClearable: true }}
                    formikProps={formikProps}
                />
                {enableSmsMessages ? (
                    <InputSelect
                        name="is_agree_sms_notification"
                        label="SMS Message Preference"
                        optionValueKey="value"
                        options={[
                            { value: 'No', label: 'No user consent' },
                            { value: 'Yes', label: 'Yes user consent' }
                        ]}
                        formikProps={formikProps}
                    />
                ) : null}
            </div>
        </div>
    );
};
