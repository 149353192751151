import * as React from 'react';
import { FormControl } from 'react-bootstrap';
import * as constants from '../../constants';

export interface ControlTextProps {
    id?: string;
    defaultValue?: string;
    changeFilter?: (e: string, v: any) => void;
    disabled?: boolean;
    placeholder?: string;
}

export interface ControlTextState {
    active: boolean;
    value?: any;
}

export default class ControlTextFilter extends React.Component<ControlTextProps, ControlTextState> {
    public state = {
        active: false,
        value: ''
    };

    public setTimerSearchId: any;

    // TODO: look at converting this to componentWillMount before React v17
    public UNSAFE_componentWillMount() {
        if (this.props.defaultValue) {
            this.setState({
                active: true,
                value: this.props.defaultValue
            });
        }
    }

    protected handleChange = (e: any) => {
        const id = e.target.id;
        const value = e.target.value;

        this.setState({
            active: !!value,
            value
        });

        if (this.setTimerSearchId !== undefined) {
            clearTimeout(this.setTimerSearchId);
        }

        const searchTimeoutDelay = 800;
        this.setTimerSearchId = window.setTimeout(() => {
            if (this.props.changeFilter) {
                this.props.changeFilter(id, value);
            }
        }, searchTimeoutDelay);

        return;
    };

    public clear = () => {
        this.setState({
            active: false,
            value: ''
        });
    };

    public render() {
        const filterActiveClass = constants.FILTER_ACTIVE_CSS;
        const filterPassiveClass = constants.FILTER_PASSIVE_CSS;

        const className = this.state.active && !this.props.disabled ? filterActiveClass : filterPassiveClass;

        return (
            <FormControl
                type="text"
                className={className}
                id={this.props.id}
                name={this.props.id}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                onChange={this.handleChange}
                value={this.state.value}
            />
        );
    }
}
