import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
    ActionList,
    AppContent,
    AppFooter,
    AppHeader,
    AppPage,
    AppSectionHeader,
    Button,
    ButtonPrimary,
    Form,
    InputPhone,
    InputSelect,
    InputSwitch,
    InputText,
    useSnackbar
} from '../../components-v2/shared';
import { emptyCallUs } from '../../store/initial';
import useStore from '../../store/useStore';
import { FormikHelpers, FormikProps } from 'formik';
import { CallUs } from '../../types';
import { EditCallUsNowFormValidationSchema } from './CallUsNow.validation';
import { handlePromise, useAsyncEffect } from '../../util/async';
import { unsavedChangesContainerSelectorDefault } from '../../constants';
import { AppsName } from '../../types';

export const CallUsNow = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, callUs, schedules, fetchSchedules, fetchCallUs, Api } = store;
    const { companyId } = router.params;
    const callUsItem = callUs.values.length ? callUs.values[0] : null;
    const mode = callUsItem?.id ? 'update' : 'create';
    const [loading, setLoading] = useState<boolean>(true);
    const [active, setActive] = useState<boolean>(false);
    
    const scheduleOptions = schedules.values.map(({ id, name }) => ({
        value: id,
        label: name
    }));

    const fetchData = async () => {
        await fetchCallUs();
        await fetchSchedules();
        setLoading(false);
    };
    useAsyncEffect(fetchData);
    // Change the active state when switching between modes
    useEffect(() => {
        setActive(mode === 'update' ? !!callUsItem?.active : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, callUsItem?.active]);

    const handleToggleClick = async () => {
        if (mode !== 'update') {
            return;
        }

        try {
            await callUs.update({ ...callUsItem, active: !active });
            setActive(!active);
            addSnackbar(`Call Us Now has been ${active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });
            const [response, error] = await handlePromise(
                Api.client.patch(`public_roles/updateActiveModules`, {
                    toggleType: active ? 'remove' : 'add',
                    company_id: companyId,
                    moduleName: AppsName.CU
                })
            );

            if (!response?.data || error) {
                return console.error('error: ', error);
            }
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to ${active ? 'deactivate' : 'activate'} Call Us Now.`, {
                variant: 'error'
            });
        }
    };

    const handleSubmit = async (values: CallUs, actions: FormikHelpers<CallUs>) => {
        try {
            const callUsId = await callUs[mode]({ ...values, active });
            addSnackbar('Call Us Now saved.', { variant: 'success' });
            actions.resetForm({ values: callUs.getItem(callUsId) });
        } catch (error) {
            actions.setErrors(error.response.data);
            addSnackbar('Failed to save Call Us Now.', { variant: 'error' });
        }
    };

    const initialValues = () => {
        return {
            ...emptyCallUs,
            company_id: parseInt(companyId, 10),
            ...callUsItem
        };
    };

    return (
        <AppPage loading={loading}>
            <AppHeader title="Call Us Now" icon={{ name: 'phoneRing', color: 'blue' }}>
                {mode === 'update' && (
                    <ActionList position="end">
                        <InputSwitch
                            labelOn="Active"
                            labelOff="Inactive"
                            labelPlacement="start"
                            onClick={handleToggleClick}
                            checked={active}
                        />
                    </ActionList>
                )}
            </AppHeader>

            <Form
                initialValues={initialValues()}
                onSubmit={handleSubmit}
                validationSchema={EditCallUsNowFormValidationSchema}
                confirmUnsavedChanges
                unsavedChangesConfig={{
                    containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
                }}
            >
                {(formikProps: FormikProps<CallUs>) => (
                    <>
                        <AppContent className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="Schedule" />
                                <InputSelect
                                    name="schedule_id"
                                    label="Schedule"
                                    optionValueKey="value"
                                    options={scheduleOptions}
                                    formikProps={formikProps}
                                />

                                <AppSectionHeader title="App" />
                                <InputText
                                    name="call_to_action_text"
                                    label="Call to action"
                                    formikProps={formikProps}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader title="Contact" />
                                <InputPhone name="phone" label="Phone number" formikProps={formikProps} />
                            </div>
                        </AppContent>

                        <AppFooter>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button
                                        onClick={() => formikProps.resetForm()}
                                        disabled={formikProps.isSubmitting}
                                        data-lc-trigger-unsaved-changes={true}
                                        data-test-id="button_cancel"
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary
                                    type="submit"
                                    disabled={(!!callUsItem?.id && !formikProps.dirty) || formikProps.isSubmitting}
                                    data-test-id="button_save"
                                >
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                )}
            </Form>
        </AppPage>
    );
});
