import React, { FC } from 'react';
import { InputSwitch, useSnackbar } from '../../../components-v2/shared';
import Resource from '../../../store/Resource';
import { Offer } from '../../../types/Offer';
import useStore from '../../../store/useStore';
export interface ToggleOfferSwitchProps {
    offer: Offer;
    offers: Resource<Offer>;
    index?: Number;
}

export const ToggleOfferSwitch: FC<ToggleOfferSwitchProps> = ({ offer, offers, index }) => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { fetchChatbotQuestions, questions } = store;
    const handleClick = async (referrerContact = false) => {
        try {
            if (referrerContact) {
                const acceptingInfluencers = !offer.accepting_influencers_info;
                await offers.update({ ...offer, accepting_influencers_info: acceptingInfluencers });
                addSnackbar(`Influencer toggle has been updated.`, {
                    variant: 'success',
                });
                return;
            }
            await offers.update({ ...offer, active: !offer.active });
            await fetchChatbotQuestions();
            const question = questions?.values?.find(chatBotQuestion => chatBotQuestion.action === String(offer.id));
            if (question?.id) {
                questions.update({ ...question, enabled: !offer.active });
            }
            addSnackbar(`Offer "${offer.offer_text}" has been ${offer.active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });
        } catch (error) {
            console.error(error);
            addSnackbar(`Failed to ${offer.active ? 'deactivate' : 'activate'} "${offer.offer_text}" offer.`, {
                variant: 'error'
            });
        }
    };

    return (
        <>
            <InputSwitch
                label='Influencer Toggle'
                onClick={() => handleClick(true)}
                checked={offer?.accepting_influencers_info}
                disabled={!offer?.active}
            />
            <InputSwitch
                labelOn='Active'
                labelOff='Inactive'
                onClick={() => handleClick()}
                checked={offer?.active}
                // Index can be undefined and that's okay.
                data-test-id={'button_cm_toggle_offer_' + index}
            />
        </>
    );
};
