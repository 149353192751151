export interface EmailEditorListReducerState {
    modal: {
        active: EmailEditorListModals;
        data: EmailEditorModalData;
    };
}

export enum LeadNurtureAppSource {
    customer = 'customer',
    spotlight = 'spotlight',
    schedule_genie = 'schedule_genie'
}

export interface EmailEditorModalData {
    title?: string;
    formInitialValues?: any;
}

export type ServerRequestStatus = 'waiting' | 'sending' | 'sent' | 'error';

export type EmailEditorListModals =
    | 'none'
    | 'createTemplate';

export interface EmailEditorListReducerAction {
    name: keyof typeof emailEditorListReducers;
    payload?: any;
}

const emailEditorListReducers = {
    closeModal: (state: EmailEditorListReducerState, _: any) => {
        state.modal.active = 'none' as EmailEditorListModals;
        return { ...state };
    },
    openModal: (state: EmailEditorListReducerState, activeModal: EmailEditorListModals) => {
        state.modal.active = activeModal;
        return { ...state };
    },
    setModalData: (state: EmailEditorListReducerState, modalData: EmailEditorModalData) => {
        state.modal.data = { ...state.modal.data, ...modalData };
        return { ...state };
    }
};

export const emailEditorListReducer = (
    state: EmailEditorListReducerState,
    action: EmailEditorListReducerAction
) => {
    if (!emailEditorListReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: EmailEditorListReducerState = emailEditorListReducers[action.name](state, action.payload);
    return nextState;
};
