import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Alert, Button, Col, Glyphicon, PageHeader, Row } from 'react-bootstrap';
import { InjectedProps } from '../../types';

interface CssToolListState {
    countCssFiles?: number;
}

@inject('store')
@observer
export class CssToolList extends React.Component<{}, CssToolListState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state = {
        countCssFiles: undefined
    };

    public render() {
        return (
            <>
                <PageHeader>CSS Tools</PageHeader>
                <Col className="col-lg-12">
                    {this.state.countCssFiles !== undefined && (
                        <Alert className="css-alert-files" bsStyle="success" onDismiss={this.handleDismissAlertCss}>
                            Css files ({this.state.countCssFiles}) were rebuilt successfully.
                        </Alert>
                    )}
                    <Row>
                        <div className="btn-toolbar">
                            <Button bsStyle="primary" onClick={this.rebuildAllCss}>
                                Rebuild All Custom CSS <Glyphicon glyph="wrench" />
                            </Button>
                        </div>
                    </Row>
                </Col>
            </>
        );
    }

    private handleDismissAlertCss = () => {
        this.setState({ countCssFiles: undefined });
    };

    private rebuildAllCss = () => {
        const { Api } = this.injected.store;
        return Api.rebuildAllCss().then(countFiles => {
            this.setState({
                countCssFiles: countFiles
            });
        });
    };
}

export default CssToolList;
