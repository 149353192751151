import React, { FC, ReactNode } from 'react';
import { HeadingTags } from './typography.types';
import classNames from 'classnames';

import styles from './typography.module.scss';

export interface SubTitleProps {
    children: ReactNode;
    as?: keyof typeof HeadingTags;
    className?: string;
}

export const SubTitle: FC<SubTitleProps> = ({ children, as: Component = 'h3', className, ...props }) => (
    <Component className={classNames(styles['title'], styles[Component], 'title', className)} {...props}>
        {children}
    </Component>
);
