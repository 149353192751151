import classNames from 'classnames';
import { ArrayHelpers, FastField, FieldArray, FieldProps, connect } from 'formik';
import React from 'react';
import { ButtonLink, TimeRangeSlider, IconButton, InputSwitch, AppSectionHeader } from '../../components-v2/shared';
import { AccessibleCompany } from '../../types';
import { shouldUpdateFastField } from '../../util/form';
import styles from './schedule-editor.module.scss';
import { TimeRange } from './ScheduleEditor.types';
import { EditScheduleForm } from './ScheduleEditorEditSchedulePage';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const EditScheduleWeek = connect<{ className: string; activeCompany: AccessibleCompany }, EditScheduleForm>(
    ({ className, activeCompany, formik }) => {
        const activeDays = new Set(days.filter(day => formik.values[day.toLowerCase()].length > 0));

        return (
            <div className={className}>
                <AppSectionHeader title="Availability">
                    {activeCompany?.time_zone && (
                        <p className="text">
                            Time zone currently set to <b className="bold">{activeCompany.time_zone}</b>
                        </p>
                    )}
                </AppSectionHeader>

                {days.map(day => {
                    const fieldArrayKey = day.toLowerCase();
                    const timeRanges: TimeRange[] = formik.values[fieldArrayKey];
                    const defaultTime = ['saturday', 'sunday'].includes(fieldArrayKey)
                        ? { startTime: '10:00', endTime: '16:00' }
                        : { startTime: '09:00', endTime: '17:00' };
                    const addTimeRange = (arrayHelpers: ArrayHelpers) => arrayHelpers.push(defaultTime);

                    return (
                        <div className={styles['edit-schedule-day']} key={fieldArrayKey}>
                            <AppSectionHeader as="Header" title={day}>
                                <InputSwitch
                                    checked={activeDays.has(day)}
                                    onChange={event => {
                                        if (event.target.checked) {
                                            return formik.setFieldValue(
                                                fieldArrayKey,
                                                formik.initialValues[fieldArrayKey].length > 0
                                                    ? formik.initialValues[fieldArrayKey]
                                                    : [defaultTime]
                                            );
                                        }
                                        formik.setFieldValue(fieldArrayKey, []);
                                    }}
                                />
                            </AppSectionHeader>

                            {activeDays.has(day) && (
                                <div className={styles['time-range-array']}>
                                    <FieldArray name={fieldArrayKey}>
                                        {arrayHelpers => {
                                            return timeRanges.map((timeRange, index) => {
                                                const timeRangeKey = `${fieldArrayKey}.${index}`;
                                                return (
                                                    <FastField
                                                        key={timeRangeKey}
                                                        name={timeRangeKey}
                                                        shouldUpdate={shouldUpdateFastField}
                                                    >
                                                        {(fieldProps: FieldProps) => {
                                                            const handleTimeRangeChange: (
                                                                event: React.ChangeEvent<{}>,
                                                                value: TimeRange
                                                            ) => void = (_, { startTime, endTime }: TimeRange) => {
                                                                if (startTime !== "00:00" && endTime !== "00:00") {
                                                                    formik.setFieldValue(timeRangeKey, { startTime, endTime });
                                                                    arrayHelpers.replace(index, { startTime, endTime });
                                                                }
                                                            };

                                                            return (
                                                                <>
                                                                    {
                                                                        <div
                                                                            className={classNames(
                                                                                styles['edit-schedule-slider-row'],
                                                                                index === 0
                                                                                    ? styles[
                                                                                    'edit-schedule-slider-row-first'
                                                                                    ]
                                                                                    : ''
                                                                            )}
                                                                        >
                                                                            <TimeRangeSlider
                                                                                className={
                                                                                    styles['edit-schedule-slider']
                                                                                }
                                                                                {...fieldProps.field}
                                                                                onChange={handleTimeRangeChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />

                                                                            {index > 0 && (
                                                                                <IconButton
                                                                                    className={
                                                                                        styles[
                                                                                        'edit-schedule-slider-delete-button'
                                                                                        ]
                                                                                    }
                                                                                    icon="close"
                                                                                    onClick={() =>
                                                                                        arrayHelpers.remove(index)
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    }
                                                                    {timeRanges.length === index + 1 && (
                                                                        <ButtonLink
                                                                            className={
                                                                                styles['edit-schedule-add-button']
                                                                            }
                                                                            onClick={() => addTimeRange(arrayHelpers)}
                                                                        >
                                                                            + Add time slot
                                                                        </ButtonLink>
                                                                    )}
                                                                </>
                                                            );
                                                        }}
                                                    </FastField>
                                                );
                                            });
                                        }}
                                    </FieldArray>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
);
