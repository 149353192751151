import * as React from 'react';
import DayOffEdit from './DayOffEdit';
import { Daysoff } from '../../types/CalendarDayoff';
import { Button } from 'react-bootstrap';

interface DayOffEditProps {
    daysOff: Daysoff[];
    onChange: (daysOff: Daysoff[]) => void;
    disabled: boolean;
}

export class DaysOffEdit extends React.PureComponent<DayOffEditProps> {
    public render() {
        const { daysOff, disabled } = this.props;

        return (
            <div className="holiday-details-content holiday-btn">
                <div>
                    <div className="row holiday-details-form-title">
                        <div className="col-xs-2">Name</div>
                        <div className="col-xs-2">Year</div>
                        <div className="col-xs-2">Month</div>
                        <div className="col-xs-6">Dayoff</div>
                    </div>
                    {daysOff.map((d, i) => (
                        <DayOffEdit
                            key={i}
                            dayOff={d}
                            onChange={this.handleChange(i)}
                            onDelete={this.handleDelete(i)}
                            disabled={disabled}
                        />
                    ))}
                    <Button bsStyle="primary" onClick={this.handleAdd} disabled={disabled}>
                        Add holiday
                    </Button>
                </div>
            </div>
        );
    }

    protected handleChange = (i: number) => (name: string, value: number | undefined) => {
        const daysOff = [...this.props.daysOff];
        if (typeof value === 'undefined') {
            delete daysOff[i][name];
        } else {
            daysOff[i][name] = value;
        }
        this.props.onChange(daysOff);
    };

    protected handleDelete = (i: number) => () => {
        const daysOff = [...this.props.daysOff];
        daysOff.splice(i, 1);
        this.props.onChange(daysOff);
    };

    protected handleAdd = () => {
        const daysOff = [...this.props.daysOff];
        daysOff.push({ name: '', month: 1, day: 1 });
        this.props.onChange(daysOff);
    };
}

export default DaysOffEdit;
