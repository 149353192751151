import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { Col, Row, Clearfix } from 'react-bootstrap';
import { OptOutQueue } from '../../types/OptOutQueue';
import ControlButtonSave from '../misc/ControlButtonSave';
import OptOutQueueForm from './OptOutForm';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { AppContent, AppHeader, AppPage, useSnackbar } from '../../components-v2/shared';
import useStore from '../../store/useStore';

const OptOutQueueNew = observer(() => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { router } = store;
    const onBackButtonHandler = () => {
        router.goTo(DefaultRoutes.OptOutQueueList, { ...router.params }, store);
    };
    const handleSubmit = (values: OptOutQueue, actions: FormikHelpers<Partial<OptOutQueue>>) => {
        const { optOutQueues, router } = store;

        return optOutQueues
            .create(values)
            .then(() => {
                addSnackbar(`Email added to CCPA Opt Out Queue.`, { variant: 'success' });
                router.goTo(DefaultRoutes.OptOutQueueList, { ...router.params }, store);

                return Promise.resolve();
            })
            .catch(error => {
                let errMsg = 'Failed to create.';
                if (error?.response?.data) {
                    errMsg = error.response.data;
                }
                addSnackbar(errMsg, { variant: 'error' });
                actions.setErrors(error.response.data);
            });
    };
    const renderForm = () =>
        OptOutQueueForm({
            editing: true,
            controls: <ControlButtonSave />
        });
    return (
        <AppPage>
            <AppHeader onBack={() => onBackButtonHandler()} title="Opt Out Queue: Add new email"></AppHeader>
            <AppContent>
                <Fragment>
                    <div className="col-lg-12">
                        <Row>
                            <Col className="new-indent">
                                <Formik
                                    initialValues={{
                                        email: ''
                                    }}
                                    enableReinitialize
                                    onSubmit={handleSubmit}
                                >
                                    {renderForm()}
                                </Formik>
                            </Col>
                        </Row>
                    </div>
                    <Clearfix />
                </Fragment>
            </AppContent>
        </AppPage>
    );
});

export default OptOutQueueNew;
