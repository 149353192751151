import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Alert, Button, Col, Collapse, Glyphicon, PageHeader, Row, Well } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { InjectedProps } from '../../types';
import { OwnershipGroup } from '../../types/ownershipGroup';
import OwnershipGroupNew from './OwnershipGroupNew';

interface OwnershipGroupListState {
    open: boolean;
    newItem?: OwnershipGroup;
    sortName: any;
    sortOrder: any;
}

@inject('store')
@observer
export class OwnershipGroupList extends React.Component<{}, OwnershipGroupListState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state: OwnershipGroupListState = {
        open: false,
        sortName: 'name',
        sortOrder: 'asc'
    };

    public componentDidMount() {
        const { fetchOwnershipGroup } = this.injected.store;
        return fetchOwnershipGroup();
    }

    public onSortChange(sortName: any, sortOrder: any) {
        this.setState({
            sortName,
            sortOrder
        });
    }

    public render() {
        const { newItem } = this.state;
        const options = {
            onRowClick: this.handleRowClick,
            sortName: this.state.sortName,
            sortOrder: this.state.sortOrder,
            onSortChange: this.onSortChange
        };
        const { ownershipGroup } = this.injected.store;

        return (
            <>
                <PageHeader>Ownership Groups</PageHeader>
                <Col className="col-lg-12">
                    {newItem && (
                        <Alert bsStyle="success" onDismiss={this.handleDismissAlert}>
                            New Ownership Group "{newItem.name}" was created successfully.
                        </Alert>
                    )}
                    <Row>
                        <Button bsStyle="primary" onClick={this.toggleNewForm}>
                            Create Ownership Group <Glyphicon glyph="plus" />
                        </Button>
                    </Row>
                    <Collapse in={this.state.open}>
                        <Row>
                            <Well>
                                <OwnershipGroupNew handleClose={this.itemAdded} />
                            </Well>
                        </Row>
                    </Collapse>
                    <Row>
                        <BootstrapTable data={ownershipGroup.values} options={options} pagination search striped>
                            <TableHeaderColumn dataField="id" isKey width="80" dataSort>
                                ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="name" dataSort>
                                Name
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Row>
                </Col>
            </>
        );
    }

    public handleRowClick = (row: any) => {
        const { router } = this.injected.store;
        router.goTo(DefaultRoutes.OwnershipGroupEdit, { id: row.id }, this.injected.store);
    };

    public toggleNewForm = () => {
        this.setState({ open: !this.state.open });
    };

    public itemAdded = (item: any) => {
        this.setState({ newItem: item });
        this.toggleNewForm();
    };

    public handleDismissAlert = () => {
        this.setState({ newItem: undefined });
    };
}

export default OwnershipGroupList;
