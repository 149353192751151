import * as React from 'react';
import ControlSelect, { ControlSelectProps } from './ControlSelect';
import * as constants from '../../constants';

export interface ControlSelectState {
    active: boolean;
    value?: any;
}

export default class ControlSelectFilter extends React.Component<ControlSelectProps, ControlSelectState> {
    public state = {
        active: false,
        value: undefined
    };

    public elementRef: any;

    // TODO: look at converting this to componentWillMount before React v17
    public UNSAFE_componentWillMount() {
        if (this.props.value) {
            this.setState({
                active: true,
                value: this.props.value
            });
        }
    }

    public onChange = (id: string, value: any) => {
        this.setState({
            active: !!value,
            value
        });

        if (this.props.onChange) {
            this.props.onChange(id, value);
        }
    };

    public clear = () => {
        this.setState({
            active: false,
            value: undefined
        });
    };

    public render() {
        const filterActiveClass = constants.FILTER_ACTIVE_CSS;
        const filterPassiveClass = constants.FILTER_PASSIVE_CSS;

        const className = this.state.active && !this.props.disabled ? filterActiveClass : filterPassiveClass;

        return (
            <ControlSelect {...this.props} className={className} onChange={this.onChange} value={this.state.value} />
        );
    }
}
