import React, { FC } from 'react';
import {
    ActionList,
    Button,
    ButtonPrimary,
    Modal,
    ModalHeader,
    ModalActions,
    useSnackbar
} from '../../../components-v2/shared';
import Resource from '../../../store/Resource';
import { Offer } from '../../../types/Offer';
import { closeModal, setModalData, ConcessionManagerReducerAction } from '../ConcessionManager.helpers';

export interface DeleteOfferModalProps {
    isOpen: boolean;
    dispatch: React.Dispatch<ConcessionManagerReducerAction>;
    offers: Resource<Offer>;
    offer: Offer;
    onAfterDelete?: (offerId: Offer['id']) => void;
}

export const DeleteOfferModal: FC<DeleteOfferModalProps> = ({ isOpen, dispatch, offers, offer, onAfterDelete }) => {
    const { addSnackbar } = useSnackbar();

    const handleConfirmClick = async () => {
        try {
            await offers.delete(offer.id);
            addSnackbar(`Offer "${offer.offer_text}" has been deleted.`, {
                variant: 'success'
            });

            if (typeof onAfterDelete === 'function') {
                onAfterDelete(offer.id);
            }
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to delete "${offer.offer_text}" offer.`, {
                variant: 'error'
            });
        }
    };

    const handleAfterClose = () => {
        closeModal(dispatch);
        setModalData(dispatch, null);
    };

    return (
        <Modal id="deleteOffer" isOpen={isOpen} onAfterClose={handleAfterClose}>
            {offer && (
                <>
                    <ModalHeader title="Delete offer?" />
                    Are you sure you want to delete the offer "{offer.offer_text}"?
                    <ModalActions>
                        <ActionList position="end">
                            <Button onClick={() => closeModal(dispatch)}>No, don't delete</Button>
                            <ButtonPrimary onClick={handleConfirmClick}>Yes, delete</ButtonPrimary>
                        </ActionList>
                    </ModalActions>
                </>
            )}
        </Modal>
    );
};
