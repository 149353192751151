import { RemoteCalendarAdapter } from './RemoteCalendarAdapter';
import Api from '../../../util/api';

export default class CalendarRCA implements RemoteCalendarAdapter {
    private Api: Api;
    private calendarId: number;

    public title = 'Schedule Genie';

    public pushDescription = `The sync for Schedule Genie with remote calendars is two-way.
    This means that leads data will automatically be pushed to the linked remote calendar.
    Leads that are updated via the remote calendar can optionally be updated here as well
    by turning on the "Get Real-Time Updates" feature below.`;

    public constructor(internalApi: Api, calendarId: number) {
        this.Api = internalApi;
        this.calendarId = calendarId;
    }

    public getExternalCalendar = async () => {
        return await this.Api.getExternalCalendar(this.calendarId);
    };

    public assignToken = async (tokenId: number) => {
        await this.Api.assignTokenToCalendar(this.calendarId, { external_auth_id: tokenId })!;
    };

    public unlinkToken = async () => {
        await this.Api.unlinkTokenFromCalendar(this.calendarId)!;
    };
}
