import { FormikProps } from 'formik';
import * as React from 'react';
import { Checkbox, Col, FormControlProps, FormGroup, HelpBlock } from 'react-bootstrap';
import { FieldGroupColSizes, COLUMNS_ONE } from './FieldGroupColSizes';
import ControlTooltip from './ControlTooltip';

interface FieldGroupProps<T> extends FormControlProps, FormikProps<T> {
    labels?: Record<string, string>;
    colSize?: FieldGroupColSizes;
    tooltip?: string;
}
type ValidationState = 'success' | 'warning' | 'error' | null;

export function FieldGroupCheckbox<T>(props: FieldGroupProps<T>) {
    let validationState: ValidationState = null;
    const error = props.errors[`${props.id}`];

    if (props.value) {
        validationState = 'success';
    }

    if (error) {
        validationState = 'error';
    }

    let colSizes = COLUMNS_ONE;
    if (props.colSize) {
        colSizes = props.colSize;
    }

    const hasValue = !!props.values[`${props.id}`];
    const label = props.labels ? props.labels[`${props.id}`] : '';

    return (
        <Col {...colSizes}>
            <FormGroup
                controlId={props.id}
                name={props.id}
                validationState={validationState}
                className={props.className}
                disabled={props.disabled}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
            >
                <Checkbox
                    id={props.id}
                    name={props.id}
                    checked={hasValue}
                    disabled={props.disabled}
                    onChange={props.handleChange}
                >
                    {label}
                    {props.tooltip ? <ControlTooltip text={props.tooltip} /> : null}
                </Checkbox>
                {error && <HelpBlock>{error}</HelpBlock>}
            </FormGroup>
        </Col>
    );
}
