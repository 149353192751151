import React, { useState } from 'react';

export interface FieldFileUploadProps {
    onClearFile?: () => void;
    onChangeFile?: (file: File) => void;
}

const NO_FILE = 'No file chosen';

export const FieldFileUpload: React.FC<FieldFileUploadProps> = props => {
    const [fileChooseName, setFileChooseName] = useState(NO_FILE);

    const clearFile = (e: any) => {
        e.target.value = null;
        setFileChooseName(NO_FILE);

        if (props.onClearFile) {
            props.onClearFile();
        }
    };

    const onSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file: File | undefined= event && event.target && event.target.files ? event.target.files[0] : undefined;

        if (file) {
            setFileChooseName(file.name);
            if (props.onChangeFile) {
                props.onChangeFile(file);
            }    
        }
        else {
            setFileChooseName(NO_FILE);
            if (props.onClearFile) {
                props.onClearFile();
            }
        }
    };

    return (
        <>
            <label htmlFor="csv_uploads">Choose File</label>
            <input
                id="csv_uploads"
                type="file"
                accept=".csv"
                onClick={clearFile}
                onChange={onSelected}
            />
            <div className={`text-choose-file ${fileChooseName && fileChooseName !== NO_FILE ? 'selected' : ''}`}>{fileChooseName}</div>
        </>
    );
}

export default FieldFileUpload;