import { Icon, Modal, ModalHeader, Table } from '@lambdacurry/component-library';
import React, { FC, useState } from 'react';
import { DescriptionList, DescriptionListItem, Title } from '../../../../components-v2/shared';
import { AgencySenderEmailDns, AgencySenderEmailDnsItem } from '../../../../types';

export interface AgencyEmailDnsSettingsTableProps {
    senderEmailDns?: AgencySenderEmailDns;
}

export const AgencyEmailDnsSettingsTable: FC<AgencyEmailDnsSettingsTableProps> = ({ senderEmailDns }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState<AgencySenderEmailDnsItem | null>();

    const openModal = (data: any) => {
        setModalData(data);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalData(null);
    };

    return (
        <>
            <Title as="h3">DNS for custom sender email</Title>
            {senderEmailDns && (
                <p>
                    To finish setting up your custom domain, please add the following DNS records to your domain
                    registrar.
                </p>
            )}

            <Table
                className="agency-email-dns-settings-table"
                footnote={senderEmailDns ? 'Note: Sometimes these changes can take up to an hour to take effect.' : ''}
            >
                {senderEmailDns && (
                    <thead>
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Name</th>
                            <th scope="col">Value</th>
                            <th scope="col">Required</th>
                            <th scope="col">Configured</th>
                        </tr>
                    </thead>
                )}

                <tbody>
                    {!senderEmailDns && (
                        <tr>
                            <td colSpan={5} className="agency-email-dns-settings-table-empty-state">
                                The DNS records required to configure your custom sender email will appear here once you
                                have saved the sender email field above.
                            </td>
                        </tr>
                    )}

                    {senderEmailDns && (
                        <>
                            {senderEmailDns.map(dns => (
                                <tr key={dns.value} onClick={() => openModal(dns)}>
                                    <th scope="row">{dns.type}</th>
                                    <td>{dns.name}</td>
                                    <td className="agency-email-dns-settings-table-value">{dns.value}</td>
                                    <td>{dns.required ? 'Yes' : 'No'}</td>
                                    <td>
                                        {dns.configured ? (
                                            <Icon name="check" className="color-success" />
                                        ) : (
                                            <Icon name="block" className="color-danger" />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </>
                    )}
                </tbody>
            </Table>

            <Modal isOpen={modalOpen} onAfterClose={() => closeModal()} className="agency-email-dns-settings-modal">
                <ModalHeader title="Sender Email DNS" />
                <DescriptionList>
                    {modalData?.type && <DescriptionListItem label="Type" value={modalData.type} />}
                    {modalData?.name && <DescriptionListItem label="Name" value={modalData.name} />}
                    {modalData?.value && <DescriptionListItem label="Value" value={modalData.value} />}
                    {typeof modalData?.required !== 'undefined' && (
                        <DescriptionListItem label="Required" value={modalData.required ? 'Yes' : 'No'} />
                    )}
                    {typeof modalData?.configured !== 'undefined' && (
                        <DescriptionListItem label="Configured" value={modalData.configured ? 'Yes' : 'No'} />
                    )}
                </DescriptionList>
            </Modal>
        </>
    );
};
