import React, { FC } from 'react';
import { IconButton, Tooltip, useTooltip } from '@lambdacurry/component-library';

export interface AppCardHelpProps {
    helpText: string;
}

export const AppCardHelp: FC<AppCardHelpProps> = ({ helpText }) => {
    const { tooltipOpen, handleTooltipOpen, handleTooltipClose } = useTooltip();

    return (
        <div className="app-card__help">
            <Tooltip title={helpText} open={tooltipOpen} onClose={handleTooltipClose} options={{ openOnClick: true }}>
                <IconButton icon="question" onClick={handleTooltipOpen} />
            </Tooltip>
        </div>
    );
};
