import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
    ActionList,
    AppHeader,
    AppPage,
    useSnackbar,
} from '../../components-v2/shared';
import useStore from '../../store/useStore';
import {
    AppsName,
} from '../../types';
import { handlePromise, InputSwitch, useAsyncEffect } from '@lambdacurry/component-library';
import { returnAsArray } from './LiveChat.helpers';
import { CallToAction } from './CallToAction';

export const LiveChat = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, liveChat, fetchLivechat, Api } = store;
    const { companyId } = router.params;
    const livechatItem = liveChat.values.length ? liveChat.values[0] : null;
    const [active, setActive] = useState<boolean>(livechatItem && livechatItem[0]?.active);
    const [loading, setLoading] = useState<boolean>(true);
    const [activateWidgetToggle, setActivateWidgetToggle] = useState<boolean>(false);

    const fetchRequiredData = async () => {
        await fetchLivechat();
        setLoading(false);
    };
    useAsyncEffect(fetchRequiredData);

    useEffect(() => {
        const items = returnAsArray(livechatItem);
        if (items) {
            setActive(items[0]?.active || false);
            setActivateWidgetToggle(!!items[0]?.call_to_action)
        }
    }, [loading, livechatItem, activateWidgetToggle]);

    const handleToggleClick = async () => {
        try {
            const items = returnAsArray(livechatItem);
            await liveChat.updateChatWidget({ ...items[0], active: !active });
            setActive(!active);

            addSnackbar(`Livechat widget has been ${active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });

            const [response, error] = await handlePromise(
                Api.client.patch(`public_roles/updateActiveModules`, {
                    toggleType: active ? 'remove' : 'add',
                    company_id: companyId,
                    moduleName: AppsName.LC
                })
            );
            if (!response?.data || error) {
                return console.error('error: ', error);
            }
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to ${active ? 'deactivate' : 'activate'} Livechat.`, {
                variant: 'error'
            });
        }
    };

    return (
        <AppPage>
            <AppHeader title="Live Chat" icon={{ name: 'chat', color: 'blue' }}>
                <ActionList position="end">
                    <InputSwitch
                        disabled={!activateWidgetToggle}
                        labelOn="Active"
                        labelOff="Inactive"
                        labelPlacement="start"
                        onClick={handleToggleClick}
                        checked={active}
                    />
                </ActionList>
            </AppHeader>
            <CallToAction
                liveChat={liveChat}
                companyId={companyId}
                active={active}
                setActivateWidgetToggle={setActivateWidgetToggle}
            />
        </AppPage>
    );
});
