import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, RulesActionFactory, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
export interface KnockFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface KnockCrm extends Crm {
    configuration: {
        knock_source: string;
        knock_username: string;
    };
}

const ruleActions = [RulesActionFactory.ReplaceStringAction('knock_source', 'Replace Knock Source with new value')];

export const KnockForm: FC<KnockFormProps> = observer(({ onSubmit, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleSubmit = async (values: KnockCrm, actions: FormikHelpers<KnockCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.knock);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {formikProps => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Knock" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader title="Email Parameters" />
                                <InputText
                                    name="configuration.knock_source"
                                    label="Knock Source"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.knock_username"
                                    label="Knock Username"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
