import { BlastSchedule } from './BlastScheduleEditor.types';

export interface BlastScheduleEditorListReducerState {
    modal: {
        active: BlastScheduleEditorListModals;
        data: BlastScheduleEditorModalData;
    };
    schedules?: BlastSchedule[];
    canCreate?: boolean;
}

export interface BlastScheduleEditorModalData {
    title?: string;
    selectedSchedule?: BlastSchedule;
    formInitialValues?: any;
}

export type ServerRequestStatus = 'waiting' | 'sending' | 'sent' | 'error';

export type BlastScheduleEditorListModals = 'none' | 'createBlastSchedule' | 'copyBlastSchedule' | 'deleteBlastSchedule' | 'archiveBlastSchedule';

export interface BlastScheduleEditorListReducerAction {
    name: keyof typeof blastScheduleEditorListReducers;
    payload?: any;
}

const blastScheduleEditorListReducers = {
    closeModal: (state: BlastScheduleEditorListReducerState, _: any) => {
        state.modal.active = 'none' as BlastScheduleEditorListModals;
        return { ...state };
    },
    openModal: (state: BlastScheduleEditorListReducerState, activeModal: BlastScheduleEditorListModals) => {
        state.modal.active = activeModal;
        return { ...state };
    },
    setModalData: (state: BlastScheduleEditorListReducerState, modalData: BlastScheduleEditorModalData) => {
        state.modal.data = { ...state.modal.data, ...modalData };
        return { ...state };
    },
    setSchedules: (state: BlastScheduleEditorListReducerState, schedules: BlastSchedule[]) => ({
        ...state,
        schedules
    }),
    setCanCreate: (state: BlastScheduleEditorListReducerState, canCreate: boolean) => ({
        ...state,
        canCreate
    }),
};

export const blastScheduleEditorListReducer = (
    state: BlastScheduleEditorListReducerState,
    action: BlastScheduleEditorListReducerAction
) => {
    if (!blastScheduleEditorListReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: BlastScheduleEditorListReducerState = blastScheduleEditorListReducers[action.name](
        state,
        action.payload
    );
    return nextState;
};

export interface BlastScheduleEditorEditScheduleReducerState {
    isEditingName?: boolean;
    modal: {
        active: BlastScheduleEditorListModals;
        data: BlastScheduleEditorModalData;
    };
    schedule: BlastSchedule;
}

export type BlastScheduleEditorEditScheduleModals = 'none';

export interface BlastScheduleEditorEditScheduleReducerAction {
    name: keyof typeof blastScheduleEditorEditScheduleReducers;
    payload?: any;
}

const blastScheduleEditorEditScheduleReducers = {
    closeModal: (state: BlastScheduleEditorEditScheduleReducerState, _: any) => {
        state.modal.active = 'none' as BlastScheduleEditorEditScheduleModals;
        return { ...state };
    },
    openModal: (
        state: BlastScheduleEditorEditScheduleReducerState,
        activeModal: BlastScheduleEditorEditScheduleModals
    ) => {
        state.modal.active = activeModal;
        return { ...state };
    },
    setModalData: (state: BlastScheduleEditorEditScheduleReducerState, modalData: BlastScheduleEditorModalData) => {
        state.modal.data = { ...state.modal.data, ...modalData };
        return { ...state };
    },
    setBlastSchedule: (state: BlastScheduleEditorEditScheduleReducerState, schedule: BlastSchedule) => {
        return {
            ...state,
            schedule
        };
    },
    setIsEditingName: (state: BlastScheduleEditorEditScheduleReducerState, isEditingName: boolean) => ({
        ...state,
        isEditingName
    })
};

export const blastScheduleEditorEditScheduleReducer = (
    state: BlastScheduleEditorEditScheduleReducerState,
    action: BlastScheduleEditorEditScheduleReducerAction
) => {
    if (!blastScheduleEditorEditScheduleReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: BlastScheduleEditorEditScheduleReducerState = blastScheduleEditorEditScheduleReducers[action.name](
        state,
        action.payload
    );
    return nextState;
};
