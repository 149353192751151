import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Formik, FormikHelpers, FormikProps, FormikState } from 'formik';
import { Col, PageHeader } from 'react-bootstrap';
import { InjectedProps } from '../../types';
import { CalendarDayoff } from '../../types/CalendarDayoff';
import ControlButtonGroup from '../misc/ControlButtonGroup';
import CalendarDayoffForm, { ICalendarDayoffForm } from './CalendarDayoffForm';
import { emptyCalendarDayoff } from '../../store/initial';
import DefaultRoutes from '../../routes/DefaultRoutes';

interface CalendarDayoffEditState {
    editing: boolean;
}

@inject('store')
@observer
export class CalendarDayoffEdit extends React.Component<{}, CalendarDayoffEditState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state = {
        editing: false
    };

    public initialValues = (calendarDayoff: CalendarDayoff) => {
        const { isLoading } = this.injected.store;

        if (isLoading || !calendarDayoff) {
            return emptyCalendarDayoff;
        }

        const { id, title, daysoff } = calendarDayoff;
        return { id, title, daysoff };
    };

    public get canEdit() {
        const { router, isAdminRole } = this.injected.store;
        return (
            (!router.params.agencyId && isAdminRole) ||
            parseInt(router.params.agencyId, 10) === this.getDayoff()?.agency_id
        );
    }

    public get id() {
        return this.injected.store.router.params.id ? parseInt(this.injected.store.router.params.id, 10) : 0;
    }

    public getDayoff() {
        return this.injected.store.calendarsDaysoff.getItem(this.id);
    }

    public fetchData() {
        const { fetchCalendarDaysOff } = this.injected.store;
        return fetchCalendarDaysOff(this.id);
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public render() {
        const calendarDayoff = this.getDayoff();
        const initialValues = this.initialValues(calendarDayoff);
        return (
            <>
                <PageHeader>Holiday Details</PageHeader>
                <Col className="fixed-controls">
                    <Formik initialValues={initialValues} enableReinitialize onSubmit={this.handleSubmit}>
                        {this.renderForm()}
                    </Formik>
                </Col>
            </>
        );
    }

    public renderForm = () => {
        return CalendarDayoffForm({
            editing: this.state.editing,
            controls: (bag: FormikProps<ICalendarDayoffForm>) => (
                <ControlButtonGroup
                    editing={this.state.editing}
                    canEdit={this.canEdit}
                    canDelete={this.state.editing}
                    handleEdit={this.toggleEdit}
                    handleCancel={this.handleCancel(bag)}
                    handleBack={this.handleBack}
                    handleDelete={this.handleDelete}
                />
            )
        });
    };

    public toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    public handleCancel = (bag: FormikProps<ICalendarDayoffForm>) => () => {
        this.setState({ editing: false });
        bag.resetForm(bag.initialValues as Partial<FormikState<ICalendarDayoffForm>>);
    };

    public handleBack = () => {
        const { router } = this.injected.store;
        router.goTo(
            router.params.agencyId ? DefaultRoutes.AgencyHolidaysList : DefaultRoutes.HolidaysList,
            router.params,
            this.injected.store
        );
    };

    public handleSubmit = (values: CalendarDayoff, actions: FormikHelpers<CalendarDayoff>) => {
        actions.setSubmitting(true);
        const { calendarsDaysoff } = this.injected.store;

        if (values.daysoff) {
            values.daysoff.forEach(dayOff => {
                Object.keys(dayOff).forEach(key => {
                    if (typeof dayOff[key] === 'string' && key !== 'name') {
                        dayOff[key] = +dayOff[key];
                    }
                });
            });
        }

        return calendarsDaysoff
            .update(values)
            .then(() => {
                actions.setSubmitting(false);
                this.setState({ editing: false });
            })
            .catch(error => {
                actions.setErrors(error.response.data);
                actions.setSubmitting(false);
            });
    };

    protected handleDelete = async () => {
        const { deleteHoliday } = this.injected.store;
        if (window.confirm('Are you sure you wish to delete this item?')) {
            await deleteHoliday(this.id);
            this.handleBack();
        }
        return;
    };
}

export default CalendarDayoffEdit;
