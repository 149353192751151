import {
    Button,
    ButtonOutline,
    ButtonPrimary,
    handlePromise,
    Icon,
    Modal,
    ModalActions,
    ModalHeader,
    useAsyncEffect
} from '@lambdacurry/component-library';
import { omit } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { ActionList, Header, useSnackbar } from '../../../components-v2/shared';
import useStore from '../../../store/useStore';
import { getColorValues } from '../../../util/colors';
import './demo-mode-alert.scss';

export const DemoModeAlert: FC = observer(() => {
    const [loading, setLoading] = useState<boolean>(true);
    const [canApprove, setCanApprove] = useState<boolean>(false);
    const [showActivationModal, setShowActivationModal] = useState<boolean>(false);
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { activeCompany, activeCompanyId, Api, fetchCompanies, fetchPublicRoles, publicRoles, companies } = store;
    const publicRole = publicRoles.getItem(publicRoles.getFirstItemId());
    const { agency_id: agencyId, website_url: websiteUrl } = activeCompany || {};

    const fetchData = async () => {
        try {
            if (!companies) {
                await Promise.all([fetchPublicRoles(), fetchCompanies()]); // Using Promise.all() to run in parallel
            } else {
                await fetchPublicRoles();
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    };

    const fetchCanApprove = async () => {
        if (!agencyId) {
            setCanApprove(true);
            return;
        }

        setLoading(true);

        const [response, error] = await handlePromise<{ data: { canApprove: boolean } }>(
            Api.client.get(`/company/${activeCompanyId}/proofing/can-approve`)
        );

        setLoading(false);

        if (!response?.data || error) {
            console.error(error);
            addSnackbar("Unable to verify partner's status", { variant: 'error' });
            return;
        }

        setCanApprove(response.data.canApprove);
    };

    useAsyncEffect(fetchData, undefined, []);
    useAsyncEffect(fetchCanApprove, undefined, [agencyId]);

    const handleAppActivation = async () => {
        const omitValues = [
            'company_id',
            'api_key',
            'updated_by',
            'created_at',
            'deleted_at',
            'updated_at',
            'css_updated_at',
            'css_filename',
            'css_filename_mobile',
            'widgets_order_mobile',
            'widget_logo'
        ];

        const data = omit({ ...publicRole, ...getColorValues(publicRole), widget_hidden: false }, omitValues);
        await publicRoles.update(data);

        // On approval we want to fetch the company data again to show the company trial alert banner
        await fetchCompanies();
        setShowActivationModal(false);
    };

    if (
        !activeCompanyId ||
        loading ||
        !publicRole ||
        !publicRole.widget_hidden ||
        publicRole.company_id !== activeCompanyId
    ) {
        return null;
    }

    return (
        <>
            <div className="demo-mode-alert">
                <div className="demo-mode-alert-icon">
                    <Icon name="demoMode" />
                </div>

                <div className="demo-mode-alert-content">
                    <div className="demo-mode-alert-message">
                        <Header as="h3" className="clx-margin-0">
                            Demo Mode Enabled
                        </Header>
                    </div>

                    <ActionList position="end">
                        <ButtonOutline
                            className="demo-mode-alert-preview-button"
                            icon={<Icon name="preview" />}
                            iconPlacement="start"
                            href={`${websiteUrl}?&test=true`}
                            target="_blank"
                            as={buttonProps => <a {...buttonProps} />}
                            aria-label="Preview"
                        >
                            Preview
                        </ButtonOutline>

                        {canApprove && (
                            <ButtonPrimary onClick={() => setShowActivationModal(true)}>Activate Live</ButtonPrimary>
                        )}
                    </ActionList>
                </div>
            </div>

            <Modal isOpen={showActivationModal} closeButton={false}>
                <ModalHeader title="Activate?"></ModalHeader>
                <p>Are you sure you want to activate the application? It will be available for site visitors.</p>
                <ModalActions>
                    <ActionList position="end">
                        <Button onClick={() => setShowActivationModal(false)}>Do not activate, revert</Button>
                        <ButtonPrimary onClick={handleAppActivation}>Activate</ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>
        </>
    );
});
