import { FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ICompanyRoleForm } from '../CompanyRoleForm';
import { InjectedProps } from '../../../types';
import classnames from 'classnames';
import { Calendar } from '../../../types/Calendar';

export interface CompanyRoleWidgetCommonProps {
    settings: FormikProps<ICompanyRoleForm>;
    defaultSettings: any;
}

@inject('store')
@observer
export class CompanyRoleWidgetMenuCommon extends React.Component<
    CompanyRoleWidgetCommonProps & Partial<InjectedProps>
> {
    public get injected() {
        return this.props as InjectedProps;
    }
    public render() {
        const { settings, defaultSettings } = this.props;
        const { values } = settings;

        const classWidgetPosition = values.widget_position ? `clx-${values.widget_position}` : `clx-bottom-right`;
        const classWidgetAnimation = values.widget_animation_style || '';
        const isMini = values.is_mini || false;
        const widgetZoom = values.widget_zoom_desktop ? `scale(${values.widget_zoom_desktop})` : 'none';

        const styleWidgetMenu = {
            color: values.color_default || defaultSettings.color_default,
            borderColor: values.color_border_show_hide_button || defaultSettings.color_border_show_hide_button,
            backgroundColor: values.color_background_default || defaultSettings.color_background_default,
            backgroundColorIcon: values.color_background_icon_small || defaultSettings.color_background_icon_small,
            transform: widgetZoom
        };

        const styleWidgetMenuWrapper = {
            backgroundColor: 'transparent',
            border: 'none'
        };

        const miniWidgetCloseButtonStyles = {
            borderColor: styleWidgetMenu.backgroundColor,
            color: styleWidgetMenu.backgroundColor
        };

        const styleWidgetTitle = {
            color: values.color_default || defaultSettings.color_default
        };
        const { calendars } = this.injected.store;
        const calendar: Calendar | undefined = calendars.values[0];

        return (
            <div
                className={classnames('clx-widget-menu', 'animated', classWidgetPosition, classWidgetAnimation, {
                    mini: isMini
                })}
                style={styleWidgetMenuWrapper}
            >
                <div className={`clx-widget-menu mini-widget_default `} style={styleWidgetMenu}>
                    {isMini ? (
                        <button style={miniWidgetCloseButtonStyles} className="chevron-mini-widget__close-btn">
                            <i className={'clxicon'}></i>
                        </button>
                    ) : null}
                    <div className="clx-widget-single-item">
                        <span className="clxicon clxicon-calendar" />
                        <p className="clx-widget-title" style={styleWidgetTitle}>
                            {calendar?.call_to_action}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyRoleWidgetMenuCommon;
