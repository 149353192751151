export type QuestionModals = 'none' | 'deleteQuestion' | 'copyQuestion';
export type QuestionReducerNames = 'closeModal' | 'openModal';

export interface QuestionReducerState {
    modal: {
        active: QuestionModals;
        data: any;
    };
}

export interface QuestionReducerAction {
    name: keyof typeof questionReducers;
    payload?: any;
}

export const questionReducers = {
    closeModal: (state: QuestionReducerState) => ({
        ...state,
        modal: { ...state.modal, active: 'none' }
    }),
    openModal: (state: QuestionReducerState, modal: QuestionReducerState['modal']) => ({
        ...state,
        modal
    }),
    setModalData: (state: QuestionReducerState, data: QuestionReducerState['modal']['data']) => ({
        ...state,
        modal: {
            ...state.modal,
            data
        }
    })
};

export const questionReducer = (state: QuestionReducerState, { name, payload }: QuestionReducerAction) => {
    if (!questionReducers[name]) {
        throw new Error(`reducer ${name} not defined`);
    }

    return questionReducers[name](state, payload);
};

export const questionInitialState: QuestionReducerState = {
    modal: {
        active: 'none',
        data: null
    }
};

export const openModal = (dispatch: React.Dispatch<QuestionReducerAction>, name: QuestionModals, data?: any) =>
    dispatch({ name: 'openModal', payload: { active: name, data } });

export const closeModal = (dispatch: React.Dispatch<QuestionReducerAction>) => dispatch({ name: 'closeModal' });

export const setModalData = (dispatch: React.Dispatch<QuestionReducerAction>, data: any) =>
    dispatch({ name: 'setModalData', payload: data });
