import { FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ICompanyRoleForm } from '../CompanyRoleForm';
import Store from '../../../store';
import { CompanyRoleWidgetMenu } from './CompanyRoleWidgetMenu';
import { CompanyRoleWidgetCalendar } from './CompanyRoleWidgetCalendar';
import { CompanyRoleWidgetAvailableTime } from './CompanyRoleWidgetAvailableTime';
import { CompanyRoleWidgetForm } from './CompanyRoleWidgetForm';
import { defaultWidgetColorTheme, defaultWidgetColorValues } from '../../../constants';
import { CssPath } from '../../../types';

export interface CompanyRoleWidgetProps {
    store?: Store;
    settings: FormikProps<ICompanyRoleForm>;
    activeColorFieldName: string;
    cssPaths: Record<number, CssPath>;
}

@inject('store')
@observer
export class CompanyRoleWidget extends React.Component<CompanyRoleWidgetProps> {
    public render() {
        const fieldsCalendar = [
            'color_month',
            'color_back_month',
            'color_calendar_past_day',
            'color_back_calendar_past_day',
            'color_back_calendar_day_hover',
            'color_calendar_background',
            'color_calendar_day',
            'color_background_chevron'
        ];
        const fieldsForm = ['color_error', 'color_error_field', 'color_button', 'color_background_submit_button'];
        const fieldsTime = [
            'color_available_times_item',
            'color_background_available_times_item',
            'color_background_available_times_item_hover'
        ];

        let step = 'menu';
        if (fieldsCalendar.indexOf(this.props.activeColorFieldName) >= 0) {
            step = 'calendar';
        } else if (fieldsForm.indexOf(this.props.activeColorFieldName) >= 0) {
            step = 'form';
        } else if (fieldsTime.indexOf(this.props.activeColorFieldName) >= 0) {
            step = 'time';
        }

        let widgetScheme = defaultWidgetColorTheme;
        if (
            this.props.cssPaths &&
            this.props.settings.values.css_id &&
            this.props.cssPaths[this.props.settings.values.css_id]
        ) {
            widgetScheme = this.props.cssPaths[this.props.settings.values.css_id].name;
        }

        switch (step) {
            case 'calendar':
                return (
                    <CompanyRoleWidgetCalendar
                        settings={this.props.settings}
                        defaultSettings={defaultWidgetColorValues[widgetScheme]}
                    />
                );
            case 'time':
                return (
                    <CompanyRoleWidgetAvailableTime
                        settings={this.props.settings}
                        defaultSettings={defaultWidgetColorValues[widgetScheme]}
                    />
                );
            case 'form':
                return (
                    <CompanyRoleWidgetForm
                        settings={this.props.settings}
                        defaultSettings={defaultWidgetColorValues[widgetScheme]}
                    />
                );
            case 'menu':
                return (
                    <CompanyRoleWidgetMenu
                        settings={this.props.settings}
                        defaultSettings={defaultWidgetColorValues[widgetScheme]}
                    />
                );
            default:
                return <div />;
        }
    }
}

export default CompanyRoleWidget;
