import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    ButtonOutlinePrimary
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { RuleAction } from '../../../../types';

export interface GlennisSolutionsFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface GlennisSolutionsCrm extends Crm {
    configuration: {
        token_endpoint: string;
        lead_endpoint: string;
        community_number: string;
    };
}

const ruleActions: Array<RuleAction> = [];

export const GlennisSolutionsForm: FC<GlennisSolutionsFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<GlennisSolutionsCrm>) => {
        await onValidate(formikProps.values, crmConfigTypes.glennis_solutions);
    };

    const handleSubmit = async (values: GlennisSolutionsCrm, actions: FormikHelpers<GlennisSolutionsCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.glennis_solutions);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<GlennisSolutionsCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Glennis Solutions" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader
                                    title="Connection Settings"
                                    subtitle="Contact Glennis Solution support for your exact connection settings"
                                />
                                <InputText
                                    name="configuration.token_endpoint"
                                    label="Token Endpoint"
                                    helperText="Glennis Solution user's token endpoint"
                                    required
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <InputText
                                    name="configuration.lead_endpoint"
                                    label="Lead Endpoint"
                                    helperText="Glennis Solution user's lead endpoint"
                                    required
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader title="Property Options" />
                                <InputText
                                    name="configuration.community_number"
                                    label="Glennis Solution Community Number"
                                    helperText="Glennis Solution Community Number"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
