import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../store/useStore';
import { apiServerURL } from '../../constants';
import { tccLogo } from '../../constants';

export const AgencyLogo = observer(() => {
    const { agencyStore } = useStore();

    if (agencyStore.agencyCustomization?.agency_logo) {
        return (
            <img
                src={`${apiServerURL}/images/${agencyStore.agencyCustomization.agency_logo}`}
                alt={agencyStore.agencyCustomization.agency_name}
            />
        );
    }

    if (agencyStore.agencyCustomization?.agency_name) {
        return <span>{agencyStore.agencyCustomization.agency_name}</span>;
    }

    return <img src={`/images/${tccLogo}`} alt="The Conversion Cloud" />;
});
