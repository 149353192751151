import { InputSelect, useAsyncEffect, handlePromise } from '@lambdacurry/component-library';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { AppSection, AppSectionHeader, InputText } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { CompanyDetailsFormValues } from './CompanyDetailsForm.helpers';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { ControlLabel } from 'react-bootstrap';
import { Company } from '../../types';
import { map } from 'lodash';

export const CompanyDetailsAdminSettings: React.FC<{
    formikProps: FormikProps<CompanyDetailsFormValues>;
}> = observer(({ formikProps }) => {
    const { store } = useStore();
    const {
        fetchOwnershipGroup,
        ownershipGroup,
        fetchPropertyManagementCompany,
        PropertyManagementCompany,
        fetchRegions,
        regions,
        isAdminRole,
        isSuperAdminRole,
        Api,
        activeCompanyId,
        activeUser,
    } = store;
    const [companies, setCompanies] = useState<Company[]>([]);
    const [ulmCompanies, setULMCompanies] = useState<Company[]>([]);
    const hasULMPreview = activeUser?.previewFlags.includes('ulm-preview');

    const fetchData = async () => {
        fetchOwnershipGroup();
        fetchPropertyManagementCompany();
        fetchRegions();
        if (isSuperAdminRole) {
            getCompanies();
            getULMChildren();
        }
    };

    const getCompanies = async () => {
        const [response, error] = await handlePromise(Api.client.get('/companies?orderBy=name'));
        if (response) {
            setCompanies(response.data.companies);
        }
        if (!response || error) {
            console.log(error);
        }
    };

    const getULMChildren = async () => {
        const [response, error] = await handlePromise(Api.client.get(`/ulm/${activeCompanyId}`));
        if (response) {
            setULMCompanies(response.data);
        }
        if (!response || error) {
            console.log(error);
        }
    };

    useAsyncEffect(fetchData);

    const handleULMCompaniesChange = (formikProps: FormikProps<CompanyDetailsFormValues>) => (selected: Company[]) => {
        setULMCompanies(selected);
        formikProps.setFieldValue('ulm_companies', map(selected, 'id'));
    };

    return (
        <AppSection>
            <AppSectionHeader title="Admin Settings" />
            <div className="field-row">
                <InputSelect
                    label="Ownership Group"
                    name="ownership_group_id"
                    optionLabelKey="name"
                    optionValueKey="id"
                    options={[
                        { id: null, name: 'None' },
                        ...ownershipGroup.values.sort((a, b) => a.name.localeCompare(b.name)),
                    ]}

                    autocompleteConfig={{ filterOptions: (x) => x }}
                    formikProps={formikProps}
                />
                <InputSelect
                    label="Property Management Company"
                    name="property_management_company_id"
                    optionLabelKey="name"
                    optionValueKey="id"
                    options={[
                        { id: null, name: 'None' },
                        ...PropertyManagementCompany.values.sort((a, b) => a.name.localeCompare(b.name)),
                    ]}
                    autocompleteConfig={{ filterOptions: (x) => x }}
                    formikProps={formikProps}
                />
            </div>
            <div className="field-row">
                <InputSelect
                    label="Region"
                    name="region_id"
                    optionLabelKey="name"
                    optionValueKey="id"
                    options={[{ id: null, name: 'None' }, ...regions.values]}
                    autocompleteConfig={{ filterOptions: (x) => x }}
                    formikProps={formikProps}
                />
                <div>
                    {isAdminRole && (
                        <InputText name="enquire_portal_id" label="Enquire Portal ID" formikProps={formikProps} />
                    )}
                </div>
                <div>
                    {isAdminRole && (
                        <InputText
                            name="master_id"
                            label="Odoo Master ID"
                            formikProps={formikProps}
                        />
                    )}
                </div>
            </div>
            {isSuperAdminRole && hasULMPreview && (
                <div>
                    <div className="field-row">
                        <ControlLabel>ULM Companies</ControlLabel>
                        <Typeahead<Company>
                            options={companies.filter(
                                (company) => company.active === true && company.id !== activeCompanyId
                            )}
                            onChange={handleULMCompaniesChange(formikProps)}
                            selected={ulmCompanies}
                            multiple
                            labelKey="name"
                            id="ulm_companies"
                        />
                    </div>
                </div>
            )}
        </AppSection>
    );
});
