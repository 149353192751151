import React from 'react';
import { AppCard, AppCardHeader, ListGroup, ListGroupItem } from '../../../components-v2/shared';

import './lead-details.scss';
import {
    hasExternalId,
    LeadDetailsState,
    selectDeviceIcon,
    selectDevices,
    selectExternalId,
    selectInteraction,
    selectLastLocation,
    selectSMSOptIn
} from './LeadDetails.helpers';

export const LeadDetailsMetricsCard: React.FC<{
    state: LeadDetailsState;
}> = ({ state }) => {
    const { metrics } = state;

    return (
        <AppCard className="lead-details-card lead-details-card--metrics">
            <AppCardHeader title="Additional Information" />
            {!metrics && 'Loading...'}
            {metrics && (
                <ListGroup>
                    <ListGroupItem icon={{ name: 'location' }} label="Location" value={selectLastLocation(state)} />
                    <ListGroupItem
                        icon={{ name: 'phoneCheck' }}
                        label="SMS Opt-In"
                        isDisabled={selectSMSOptIn(state) === 'No'}
                    >
                        <span className={selectSMSOptIn(state) === 'No' ? 'color-danger' : ''}>
                            {selectSMSOptIn(state)}
                        </span>
                    </ListGroupItem>
                    <ListGroupItem
                        icon={{ name: selectDeviceIcon(state) }}
                        label="Device Info"
                        value={selectDevices(state)}
                    />
                    <ListGroupItem
                        icon={{ name: 'touch' }}
                        label="Interaction Count"
                        value={selectInteraction(state)}
                    />
                    {hasExternalId(state) &&
                                        <ListGroupItem
                                        icon={{ name: 'company' }}
                                        label={hasExternalId(state)}
                                        value={selectExternalId(state)}
                                    />
                    }

                </ListGroup>
            )}
        </AppCard>
    );
};
