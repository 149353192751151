import React, { useEffect } from 'react';
import {
    ButtonOutline,
    ButtonPrimary,
    Drawer,
    Form,
    IconButton,
    InputCheckbox,
    InputSelect,
    useAsyncEffect,
    useDrawer
} from '@lambdacurry/component-library';
import useStore from '../../../store/useStore';
import { observer } from 'mobx-react';
import { dma, supportedTimezones } from '../../../constants';
import { ActionList, AppSectionHeader, useSnackbar } from '../../../components-v2/shared';
import './filter-drawer.scss';
import { Filters, FiltersContext, InitialFilters } from '../Filters.helpers';

export const FilterDrawerName = 'FiltersDrawer';

export interface FilterDrawerData {
    showFiltersButton: boolean;
    filters?: Filters;
    setFilters: (filters: Partial<Filters>) => void;
}

export const FilterDrawer: React.FC<{
    context?: FiltersContext;
    filters: Filters;
    onSave: (filters: Partial<Filters>) => void;
}> = observer(({ context, filters, onSave }) => {
    const { store } = useStore();
    const {
        fetchCategory,
        businessCategory,
        fetchRegions,
        regions,
        fetchPropertyManagementCompany,
        PropertyManagementCompany,
        fetchOwnershipGroup,
        ownershipGroup,
        isAdminRole,
        activeCompanyId,
        fetchFiltersData,
        filterCompanies,
        filterAgencies,
    } = store;

    const accessibleDMAValues = filterCompanies.length > 0 && filterCompanies.reduce((acc, curr) => {
        if (curr.dma) return { ...acc, [curr.dma]: true };
        return acc;
    }, {});

    const {
        drawerActions: { toggleDrawer, setDrawerData }
    } = useDrawer();

    const { addSnackbar } = useSnackbar();

    // Re-fetch filter options whenever the  activeCompanyId, or activeAgencyId changes.
    const fetchFilterOptions = async () => {
        if (!context) {
            return;
        }

        try {
            await fetchCategory();
            await fetchPropertyManagementCompany();
            await fetchOwnershipGroup();
            await fetchRegions();
            await fetchFiltersData();
        } catch (error) {
            console.error(error);
            addSnackbar('Failed to fetch filters.', { variant: 'error' });
        }
    };
    useAsyncEffect(fetchFilterOptions, undefined, [context]);

    const DMAValues = dma.filter(d => !!accessibleDMAValues[d.code]);
    const showAgenciesFilter = context !== FiltersContext.AGENCY && context !== FiltersContext.COMPANY;
    const showCompaniesFilter = context !== FiltersContext.COMPANY;
    const showBusinessCategoriesFilter = businessCategory.values.length > 0 && context !== FiltersContext.COMPANY;
    const showDMAValuesFilter = DMAValues.length > 0 && context !== FiltersContext.COMPANY;
    const showPropertyManagementCompanyFilter = PropertyManagementCompany.values.length > 0;
    const showOwnershipGroupFilter = ownershipGroup.values.length > 0;
    const showRegionsFilter = regions.values.length > 0;

    const hasTestCompany =
        isAdminRole ||
        filterCompanies.length > 0 &&
        !!filterCompanies.find((co: { is_test: boolean }) => {
            return co.is_test;
        });
    useEffect(() => {
        if (hasTestCompany && activeCompanyId) {
            const enableTestCompanyCheck = filterCompanies.find((co: { id: number }) => {
                return co.id === activeCompanyId;
            });
            filters.include_test_companies = enableTestCompanyCheck?.is_test || false;
        }
    }, [hasTestCompany, activeCompanyId]);
    useEffect(() => {
        const showFiltersButton =
            showAgenciesFilter ||
            showCompaniesFilter ||
            showBusinessCategoriesFilter ||
            showDMAValuesFilter ||
            showPropertyManagementCompanyFilter ||
            showOwnershipGroupFilter ||
            showRegionsFilter;

        setDrawerData({ name: FilterDrawerName, data: { showFiltersButton } });
    }, [
        showAgenciesFilter,
        showCompaniesFilter,
        showBusinessCategoriesFilter,
        showDMAValuesFilter,
        showPropertyManagementCompanyFilter,
        showOwnershipGroupFilter,
        showRegionsFilter
    ]);

    return (
        <Drawer className="filter-drawer" name={FilterDrawerName} options={{ width: 360 }}>
            <Form
                className="filter-drawer-form"
                initialValues={filters}
                onSubmit={values => {
                    onSave(values);
                    toggleDrawer({ name: FilterDrawerName });
                }}
            >
                {formikProps => (
                    <>
                        <AppSectionHeader title="More Filters">
                            <ActionList position="end">
                                <IconButton icon="close" onClick={() => toggleDrawer({ name: FilterDrawerName })} />
                            </ActionList>
                        </AppSectionHeader>

                        <InputSelect
                            label="Timezone"
                            name="dateRange.timezone"
                            options={supportedTimezones}
                            optionValueKey="key"
                            optionLabelKey="label"
                            formikProps={formikProps}
                            autocompleteConfig={{ disableClearable: true }}
                            inputProps={{ autoComplete: 'disable' }}
                        />

                        {showAgenciesFilter && (
                            <InputSelect
                                label="Partners"
                                name="agency_ids"
                                options={[...filterAgencies]}
                                optionValueKey="id"
                                optionLabelKey="name"
                                formikProps={formikProps}
                                autocompleteConfig={{ multiple: true, disableClearable: false }}
                                inputProps={{ autoComplete: 'disable' }}
                            />
                        )}

                        {showCompaniesFilter && (
                            <InputSelect
                                label="Companies"
                                name="company_ids"
                                options={[...filterCompanies]}
                                optionValueKey="id"
                                optionLabelKey="name"
                                formikProps={formikProps}
                                autocompleteConfig={{ multiple: true, disableClearable: false }}
                                inputProps={{ autoComplete: 'disable' }}
                            />
                        )}

                        {showBusinessCategoriesFilter && (
                            <InputSelect
                                label="Business Categories"
                                name="business_category_ids"
                                options={[...businessCategory.values]}
                                optionValueKey="id"
                                optionLabelKey="title"
                                formikProps={formikProps}
                                autocompleteConfig={{ multiple: true, disableClearable: false }}
                                inputProps={{ autoComplete: 'disable' }}
                            />
                        )}

                        {showDMAValuesFilter && (
                            <InputSelect
                                label="Designated Marketing Areas"
                                name="dmas"
                                options={DMAValues}
                                optionValueKey="code"
                                optionLabelKey="area"
                                formikProps={formikProps}
                                autocompleteConfig={{ multiple: true, disableClearable: false }}
                                inputProps={{ autoComplete: 'disable' }}
                            />
                        )}

                        {showPropertyManagementCompanyFilter && (
                            <InputSelect
                                label="Property Management Companies"
                                name="property_management_company_ids"
                                options={[...PropertyManagementCompany.values]}
                                optionValueKey="id"
                                optionLabelKey="name"
                                formikProps={formikProps}
                                autocompleteConfig={{ multiple: true, disableClearable: false }}
                                inputProps={{ autoComplete: 'disable' }}
                            />
                        )}

                        {showOwnershipGroupFilter && (
                            <InputSelect
                                label="Ownership Groups"
                                name="ownership_group_ids"
                                options={[...ownershipGroup.values]}
                                optionValueKey="id"
                                optionLabelKey="name"
                                formikProps={formikProps}
                                autocompleteConfig={{ multiple: true, disableClearable: false }}
                                inputProps={{ autoComplete: 'disable' }}
                            />
                        )}

                        {showRegionsFilter && (
                            <InputSelect
                                label="Regions"
                                name="region_ids"
                                options={[...regions.values]}
                                optionValueKey="id"
                                optionLabelKey="name"
                                formikProps={formikProps}
                                autocompleteConfig={{ multiple: true, disableClearable: false }}
                                inputProps={{ autoComplete: 'disable' }}
                            />
                        )}

                        {hasTestCompany && (
                            <InputCheckbox
                                name="include_test_companies"
                                label="Include Test Company Data"
                                formikProps={formikProps}
                            />
                        )}

                        <InputCheckbox
                            name="only_active_companies"
                            label="Only Active Companies"
                            formikProps={formikProps}
                        />

                        <div className="filter-drawer-actions">
                            <ButtonOutline
                                onClick={() => {
                                    onSave({
                                        ...InitialFilters,
                                        dateRange: filters.dateRange
                                    });
                                    toggleDrawer({ name: FilterDrawerName });
                                }}
                            >
                                Clear All
                            </ButtonOutline>
                            <ButtonPrimary type="submit">Apply Filters</ButtonPrimary>
                        </div>
                    </>
                )}
            </Form>
        </Drawer>
    );
});
