import { FormikHelpers } from 'formik';
import React from 'react';
import { ButtonPrimary, Button, Form, InputText } from '../../components-v2/shared';
import styles from './drip-schedule-editor.module.scss';

export const EditDripScheduleNameForm: React.FC<{
    name: string;
    onSubmit: (values: { name: string }, formikHelpers: FormikHelpers<{ name: string }>) => void;
    onCancel: () => void;
}> = ({ name, onSubmit, onCancel }) => {
    return (
        <Form className={styles['drip-schedule-editor-edit-name-form']} initialValues={{ name }} onSubmit={onSubmit}>
            {formikProps => (
                <>
                    <InputText
                        label="Edit Drip Schedule Name"
                        className={styles['drip-schedule-editor-edit-name-input']}
                        name="name"
                        formikProps={formikProps}
                    />
                    <ButtonPrimary className={styles['drip-schedule-editor-edit-name-save-button']} type="submit">
                        Save
                    </ButtonPrimary>
                    <Button className={styles['drip-schedule-editor-edit-name-cancel-button']} onClick={onCancel}>
                        Cancel
                    </Button>
                </>
            )}
        </Form>
    );
};
