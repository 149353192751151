import React, { FC, useState } from 'react';
import { AppHeader, AppPage, AppSection, useSnackbar } from '../../components-v2/shared';
import { Filters } from '../Filters/Filters.helpers';
import { FormikHelpers, FormikProps } from 'formik';
import { Form, ButtonPrimary, handlePromise, InputSelect, InputCheckbox } from '@lambdacurry/component-library';
import useStore from '../../store/useStore';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { crmConfigTypeLabels } from '../../constants';
import DefaultRoutes from '../../routes/DefaultRoutes';

interface CompanyListsForm {
    crm_type?: string;
    has_rules?: boolean;
    is_active: boolean;
}

export const CompanyLists: FC<{
    filters?: Filters;
}> = () => {
    const [searchResults, setSearchResults] = useState<any[] | undefined>(undefined);
    const { store } = useStore();
    const { router } = store;
    const { Api } = store;

    const { addSnackbar } = useSnackbar();

    const fetchSearchResults = async (values: CompanyListsForm) => {
        const [response, error] = await handlePromise(Api.client.post(`companies/search`, values));

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to fetch companies.', { variant: 'error' });
            return;
        }

        if (response.data.length === 0) {
            addSnackbar('Query returned zero results.', { variant: 'warning' });
            return;
        }

        setSearchResults(response.data);
    }

    const handleSubmit = async (values: CompanyListsForm, formikHelpers: FormikHelpers<CompanyListsForm>) => {
        formikHelpers.setSubmitting(true);
        await fetchSearchResults(values);
        formikHelpers.setSubmitting(false);
    };

    return (
        <AppPage>
            <Form className="form-holder-block" initialValues={{ crm_type: undefined, has_rules: undefined, is_active: true }} onSubmit={handleSubmit}>
                {(formikProps: FormikProps<CompanyListsForm>) => (
                    searchResults ? (
                        <><AppHeader title={`Company Search Results`} onBack={() => setSearchResults(undefined)}></AppHeader>
                            <AppSection>
                                <ReactDataGrid
                                    dataSource={searchResults || []}
                                    idProperty="company_id"
                                    preventRowSelectionOnClickWithMouseMove={true}
                                    onRowClick={(props) => {
                                        router.goTo(DefaultRoutes.CompanyDashboard, { companyId: props.data.company_id }, store);
                                    }}
                                    columns={[
                                        { name: 'company_name', header: 'Company Name', defaultFlex: 1 },
                                        { name: 'crm_types', header: 'CRM Types', minWidth: 200, defaultFlex: 2, render: ({ value }) => !value ? 'None' : value.map(val => crmConfigTypeLabels[val]|| val).join(', ') },
                                        { name: 'has_rules', header: 'Has Rules', defaultFlex: 1, render: ({ value }) => value ? 'Yes' : 'No'},
                                        { name: 'active', header: 'Active', defaultFlex: 1, render: ({ value }) => value ? 'Yes' : 'No'}
                                    ]}
                                    loading={false}
                                    style={{ minHeight: 683 }}
                                    pagination='local'
                                    defaultLimit={15}
                                />
                            </AppSection>
                        </>
                    ) :
                        (
                            <>
                                <AppHeader title="Company Lists"></AppHeader>
                                <AppSection>
                                <div className="field-group-content">
                                    <div className="field-group-col">
                                    <InputSelect style={{ width: 400 }}
                                    formikProps={formikProps} name='crm_type' label='CRM Type' optionValueKey='value' options={[{ value: undefined, label: 'Any'}, ...Object.keys(crmConfigTypeLabels).map(key => ({ value: key, label: crmConfigTypeLabels[key] }))]} />

                                    </div>
                                    <div className="field-group-col">
                                    <InputCheckbox style={{ width: 400 }} formikProps={formikProps} name='has_rules' label='Only Companies with CRM Rules' />
                                    <InputCheckbox style={{ width: 400 }} formikProps={formikProps} name='is_active' label='Only Active' />
                       
                                    </div>
                                    <ButtonPrimary disabled={formikProps.isSubmitting} className='filter-submit' type="submit">{formikProps.isSubmitting ? 'Searching...' : 'Search'}</ButtonPrimary>
                    

                               </div>
                                            </AppSection>
                            </>
                        )
                )}
            </Form>
        </AppPage>
    );
};
