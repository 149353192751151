import React, { FC } from 'react';
import { Link } from 'mobx-router';
import { FormikHelpers, FormikProps } from 'formik';
import {
    Form,
    AppSection,
    AppSectionHeader,
    InputText,
    InputSelect,
    ButtonOutline,
    Icon,
    InputTextarea,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    NotificationSettingsFieldGroup,
} from '../../components-v2/shared';

import { IncomeCalculator } from '../../types/IncomeCalculator';
import { FloorplansList } from './components/FloorplansList/FloorplansList';
import DefaultRoutes from '../../routes/DefaultRoutes';
import useStore from '../../store/useStore';

import styles from './IncomeCalculator.module.scss';
import { createNumericalInputOptions } from '../../util/form';
import { unsavedChangesContainerSelectorDefault } from '../../constants';
import { JSONWithQuestionBundle, QuestionEditor } from '../QuestionEditor/QuestionEditor';
import * as Yup from 'yup';

export interface IncomeCalculatorFormProps {
    initialValues: IncomeCalculator;
    onSubmit: (values: IncomeCalculator, formikHelpers: FormikHelpers<IncomeCalculator>) => void | Promise<any>;
    incomeCalculatorId?: number;
}

export const incomeCalculatorValidationSchema = () =>
    Yup.object().shape({
        description: Yup.string()
            .max(70, 'Description can be at most 70 characters long.')
            .default('Let us quickly help you determine if you pre-qualify.'),
    });

export const IncomeCalculatorForm: FC<IncomeCalculatorFormProps> = ({
    initialValues,
    onSubmit,
    incomeCalculatorId,
    ...props
}) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;
    const { hasLegacyWidget } = store;

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            confirmUnsavedChanges
            validationSchema={incomeCalculatorValidationSchema}
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault,
            }}
            {...props}
        >
            {(formikProps: FormikProps<IncomeCalculator>) => (
                <>
                    <AppSection className="field-group-content">
                        <div className="field-group-column">
                            <AppSectionHeader title="App Title" />
                            <InputText name="menu_title" label="Title" formikProps={formikProps} />
                            <InputText name="description" label="Description" formikProps={formikProps} />
                            <AppSectionHeader title="Default Income Qualification" />
                            <div className="field-row">
                                <InputText
                                    type="number"
                                    name="configuration.minimum_income_ratio"
                                    label="Income to Rent Variable"
                                    helperText="For example, if this is set to 2.5, that means the applicant's monthly income must exceed 2.5 times the monthly rent. You can also set different values for each specific floorplan."
                                    formikProps={formikProps}
                                    suffix="X"
                                />
                            </div>
                            <AppSectionHeader
                                title={`Average Median Income`}
                            />
                            <InputText
                                name="configuration.ami4Person"
                                type="number"
                                label="AMI for a family of 4"
                                helperText="This value is only required if you wish to provide calculations for income restricted properties or floorplans."
                                formikProps={formikProps}
                                prefix="$"
                            />
                        </div>
                        <div className="field-group-column">
                            <AppSectionHeader
                                title="Required Questions"
                                subtitle="These required fields help to determine pre-qualification for residence."
                            />
                            <div className="field-row">
                                <div className={styles.incomeCalculatorRequiredQuestionOneText}>
                                    <InputText name="question1_label" label="Household Size" formikProps={formikProps} />
                                </div>
                                <InputSelect
                                    name="configuration.household_size_max"
                                    label="Max Allowed"
                                    formikProps={formikProps}
                                    options={createNumericalInputOptions(8)}
                                    autocompleteConfig={{ disableClearable: true }}
                                    optionValueKey="value"
                                />
                            </div>
                            <InputText name="question2_label" label="Annual Income" formikProps={formikProps} />
                            <InputText name="question3_label" label="Floorplan Selection" formikProps={formikProps} />
                            <QuestionEditor
                                questionLimit={hasLegacyWidget ? 3 : Infinity}
                                sectionTitle={`Additional Qualification Questions`}
                                formikProps={formikProps as FormikProps<JSONWithQuestionBundle>}
                            />
                        </div>
                    </AppSection>

                    <FloorplansList formikProps={formikProps} />

                    <AppSection className="field-group-content">
                        <div className="field-group-column">
                            <AppSectionHeader title="Disclaimer" />
                            <InputTextarea name="disclaimer" formikProps={formikProps} rows={6} characterLimit={180} />

                            <AppSectionHeader title="Qualification Policy" />
                            <InputTextarea
                                name="qualification_policy"
                                formikProps={formikProps}
                                rows={6}
                                characterLimit={500}
                            />
                        </div>

                        <div className="field-group-column">
                            <AppSectionHeader title="Confirmation Email" />
                            <ButtonOutline
                                className="field-group-button-full-width"
                                data-lc-trigger-unsaved-changes={true}
                                icon={<Icon name="confirmationEmail" />}
                                as={(buttonProps) => (
                                    <Link
                                        {...buttonProps}
                                        view={DefaultRoutes.EmailTemplatesList}
                                        params={{ companyId }}
                                        store={store}
                                    />
                                )}
                            >
                                Edit Email
                            </ButtonOutline>
                            <div className={styles.margin_top}>
                                <NotificationSettingsFieldGroup formikProps={formikProps} crmBlacklist={[]} />
                            </div>
                        </div>
                    </AppSection>

                    <AppFooter sticky={true}>
                        <ActionList position="end">
                            {formikProps.dirty && (
                                <Button
                                    onClick={() => formikProps.resetForm()}
                                    disabled={formikProps.isSubmitting}
                                    data-lc-trigger-unsaved-changes={true}
                                    data-test-id="button_cancel"
                                >
                                    Cancel
                                </Button>
                            )}
                            <ButtonPrimary
                                type="submit"
                                disabled={
                                    (!!incomeCalculatorId && !formikProps.dirty) ||
                                    formikProps.isSubmitting ||
                                    !formikProps.isValid
                                }
                                data-test-id="button_save"
                            >
                                {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                            </ButtonPrimary>
                        </ActionList>
                    </AppFooter>
                </>
            )}
        </Form>
    );
};
