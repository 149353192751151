import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

export type GridColumnSpan = 3 | 4 | 6 | 8 | 12;

export interface GridColumnProps extends HTMLAttributes<HTMLElement> {
    base?: GridColumnSpan;
    xs?: GridColumnSpan;
    sm?: GridColumnSpan;
    md?: GridColumnSpan;
    lg?: GridColumnSpan;
    xl?: GridColumnSpan;
}

export const GridColumn: FC<GridColumnProps> = ({ className, base = 12, xs, sm, md, lg, xl, ...props }) => (
    <div
        className={classNames(
            'lc-grid__column',
            {
                [`lc-grid__column--${base}`]: base,
                [`lc-grid__column--xs-${xs}`]: xs,
                [`lc-grid__column--sm-${sm}`]: sm,
                [`lc-grid__column--md-${md}`]: md,
                [`lc-grid__column--lg-${lg}`]: lg,
                [`lc-grid__column--xl-${xl}`]: xl
            },
            className
        )}
        {...props}
    />
);
