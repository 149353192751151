import { range } from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { Button, FormControl, FormGroup } from 'react-bootstrap';
import { Daysoff } from '../../types/CalendarDayoff';

interface DayOffEditProps {
    disabled: boolean;
    dayOff: Daysoff;
    onChange: (name: string, value: number | undefined) => void;
    onDelete: () => void;
}

export class DayOffEdit extends React.Component<DayOffEditProps> {
    private monthsCount = 12;
    private yearsCount = 20;

    public render() {
        const { dayOff, disabled } = this.props;
        return (
            <div className="row">
                <div className="col-xs-1">
                    <Button bsStyle="danger" onClick={this.props.onDelete} disabled={disabled}>
                        Delete
                    </Button>
                </div>
                <FormGroup className="col-xs-2">
                    <FormControl
                        name="name"
                        componentClass="input"
                        type="text"
                        value={dayOff.name}
                        onChange={event => this.props.onChange('name', (event.target as any).value)}
                        disabled={disabled}
                    />
                </FormGroup>
                <FormGroup className="col-xs-2">
                    <FormControl
                        name="year"
                        componentClass="select"
                        value={dayOff.year}
                        onChange={this.handleChange('year')}
                        disabled={disabled}
                    >
                        <option>Without year</option>
                        {range(moment().year() - 1, moment().year() + this.yearsCount).map(y => (
                            <option key={y} value={y + 1}>
                                {y + 1}
                            </option>
                        ))}
                    </FormControl>
                </FormGroup>
                <FormGroup className="col-xs-2">
                    <FormControl
                        name="month"
                        componentClass="select"
                        value={dayOff.month}
                        onChange={this.handleChange('month')}
                        disabled={disabled}
                    >
                        {range(this.monthsCount).map(m => (
                            <option key={m} value={m + 1}>
                                {moment()
                                    .month(m)
                                    .format('MMMM')}
                            </option>
                        ))}
                    </FormControl>
                </FormGroup>
                <FormGroup className="col-xs-2">
                    <FormControl
                        name="dayOrWeekday"
                        componentClass="select"
                        value={dayOff.day ? 'day' : 'weekday'}
                        onChange={this.handleChangeDayOrWeekday}
                        disabled={disabled}
                    >
                        <option value="day">Day</option>
                        <option value="weekday">Week Day</option>
                    </FormControl>
                </FormGroup>
                {!!dayOff.day ? this.dayHoliday() : this.weekDayHoliday()}
            </div>
        );
    }

    private handleChange = (name: string) => (event: React.SyntheticEvent<any>) => {
        this.props.onChange(name, Number((event.target as any).value));
    };

    private handleChangeDayOrWeekday = (event: React.SyntheticEvent<any>) => {
        const { onChange } = this.props;
        switch (String((event.target as any).value)) {
            case 'day': {
                onChange('weekday', undefined);
                onChange('occurrence', undefined);
                onChange('weekday', undefined);
                onChange('day', 1);
                break;
            }
            case 'weekday': {
                onChange('day', undefined);
                onChange('weekday', 1);
                onChange('occurrence', 1);
                break;
            }
        }
    };

    private getWeekCount = (momentObj: moment.Moment) => {
        const clonedMoment = moment(momentObj);
        const first = clonedMoment.startOf('month').week();
        let last = clonedMoment.endOf('month').week();
        if (first > last) {
            last = first + last;
        }
        return last - first + 1;
    };

    private weekDayHoliday = () => {
        const { dayOff, disabled } = this.props;
        const time = moment().set('month', dayOff.month);
        if (dayOff.year) {
            time.set('year', dayOff.year);
        }
        return (
            <>
                <FormGroup className="col-xs-2">
                    <FormControl
                        name="weekName"
                        componentClass="select"
                        value={dayOff.weekday}
                        onChange={this.handleChange('weekday')}
                        disabled={disabled}
                    >
                        {moment.weekdays().map((wd, i) => (
                            <option key={i} value={i}>
                                {wd}
                            </option>
                        ))}
                    </FormControl>
                </FormGroup>
                <FormGroup className="col-xs-2">
                    <FormControl
                        name="week"
                        componentClass="select"
                        value={dayOff.occurrence}
                        onChange={this.handleChange('occurrence')}
                        disabled={disabled}
                    >
                        {range(this.getWeekCount(time)).map(w => (
                            <option key={w} value={w + 1}>
                                {w + 1}
                            </option>
                        ))}
                    </FormControl>
                </FormGroup>
            </>
        );
    };

    private dayHoliday = () => {
        const { dayOff, disabled } = this.props;
        const time = moment().set('month', dayOff.month);
        if (dayOff.year) {
            time.set('year', dayOff.year);
        }
        return (
            <>
                <FormGroup className="col-xs-2">
                    <FormControl
                        name="day"
                        componentClass="select"
                        value={dayOff.day}
                        onChange={this.handleChange('day')}
                        disabled={disabled}
                    >
                        {range(time.daysInMonth()).map(day => (
                            <option key={day} value={day + 1}>
                                {day + 1}
                            </option>
                        ))}
                    </FormControl>
                </FormGroup>
                <div className="col-xs-2" />
            </>
        );
    };
}

export default DayOffEdit;
