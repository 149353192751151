import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import Store from '.';
import { AgencyStore } from './agencyStore';

export default function useStore(): { store: Store; agencyStore: AgencyStore } {
    const { store } = useContext(MobXProviderContext);
    const { agencyStore } = store;
    return { store, agencyStore };
}
