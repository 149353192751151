import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Title, IconButton, DecorativeIcon } from '../..';

import './app-header.scss';

export interface AppHeaderProps {
    className?: string;
    title?: ReactNode;
    icon?: { name: string; color: string; img?: string; height?: number };
    onBack?: () => void;
    variant?: 'primary' | 'secondary' | 'simple';
}

export const AppHeader: FC<AppHeaderProps> = ({ className, title, icon, onBack, variant = 'primary', children }) => {
    return (
        <header className={classNames('app-header', { [`app-header-${variant}`]: variant }, className)}>
            {onBack ? <IconButton className={'app-header-back'} icon="arrowLeft" onClick={onBack} /> : null}
            {icon ? (
                icon.img ? (
                    <img src={icon.img} height={icon.height || 36} />
                ) : (
                    <DecorativeIcon className={'app-header-icon'} {...icon} />
                )
            ) : null}
            <Title className={'app-header-title'}>{title}</Title>
            {children}
        </header>
    );
};
