import React, { FC, MutableRefObject, useMemo, useState } from 'react';
import { AppCard, AppCardDivider, AppCardHeader, AppHeader, AppPage, AppSection, useSnackbar } from '../../../components-v2/shared';
import { DateRangePreset, Filters } from '../../Filters/Filters.helpers';
import { FormikProps } from 'formik';
import { Form, ButtonPrimary, InputSelect, InputSwitch, InputDate, handlePromise, useAsyncEffect, InputCheckbox, Button } from '@lambdacurry/component-library';
import { DateTime } from 'luxon';
import styles from './advanced-search.scss';
import useStore from '../../../store/useStore';
import classNames from 'classnames';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import DefaultRoutes from '../../../routes/DefaultRoutes';

interface AdvancedSearchForm {
    count_only?: boolean;
    date_range_preset: DateRangePreset;
    timezone: string;
    start?: Date;
    end?: Date;
    not_in_existing_segment?: boolean;
    list_segment?: number;
    specific_list_segment: number;
    sms_opt_in?: boolean;
    app_filters: {
        sg: boolean;
        bpn: boolean;
        ic: boolean;
        chat: boolean;
        sl: boolean;
        ex: boolean;
    };
    specific_spotlight: number;
    question_filters: {
        [x: string]: { active: boolean, picklist: { [x: string]: boolean }[] }
    };
    include_question_filters: boolean;
    filter_by_apps: boolean;
}

interface QuestionTag {
    tag: string; question: string; picklist: string[];
}

interface SpotlightEntry {
    id: number;
    description: string;
}

interface ListSegement {
    id: number;
    name: string;
}

export const AdvancedCustomerSearch: FC<{
    filters?: Filters;
}> = () => {
    const [questions, setQuestions] = useState<QuestionTag[]>([]);
    const [spotlights, setSpotlights] = useState<SpotlightEntry[]>([]);
    const [listSegments, setListSegments] = useState<ListSegement[]>([]);
    const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null>>({ current: null });
    const [searchResults, setSearchResults] = useState<any[] | undefined>(undefined);
    const { store } = useStore();
    const { Api, router } = store;

    const { addSnackbar } = useSnackbar();

    async function fetchData() {
        await fetchQuestions();
        await fetchSpotlights();
        await fetchListSegments()
    }

    async function fetchListSegments() {
        const [response, error] = await handlePromise(Api.client.get(`company/${router.params.companyId}/list-segments`));

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to fetch list segments.', { variant: 'error' });
            return;
        }

        setListSegments(response.data.data);
    }

    async function fetchQuestions() {
        const [response, error] = await handlePromise(Api.client.get(`company/${router.params.companyId}/questions/active`));

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to fetch active questions.', { variant: 'error' });
            return;
        }

        setQuestions(response.data);
    }

    async function fetchSpotlights() {
        const [response, error] = await handlePromise(Api.client.get(`company/${router.params.companyId}/spotlights`));

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to fetch Spotlights.', { variant: 'error' });
            return;
        }

        setSpotlights(response.data);
    }

    useAsyncEffect(fetchData);

    function anyActive(items: { [x: string]: boolean }): boolean {
        if (!items) {
            return false;
        }
        return Object.values(items).some((val) => {
            return val;
        });
    }

    function anyQuestionActive(items: { [x: string]: { active: boolean } }): boolean {
        if (!items) {
            return false;
        }
        return Object.values(items).some((val) => {
            return val.active;
        });
    }

    function validateQuesetionFilters(items: { [x: string]: { active: boolean, picklist: { [x: string]: boolean }[] } }): boolean {
        return Object.values(items).some((opts) => {
            if (!opts.active) {
                return false;
            }
            if (!opts.picklist) {
                return false;
            }
            return Object.values(opts.picklist).some((isIncluded) => {
                return isIncluded;
            });
        })
    }

    const fetchSearchResults = async (values: AdvancedSearchForm) => {
        if (values.date_range_preset === DateRangePreset.CUSTOM) {
            if (!values.start || !values.end) {
                addSnackbar('Both start and end dates are required when specifying custom date range.', { variant: 'error' });
                return;
            }
        }
        if (values.filter_by_apps && !anyActive(values.app_filters)) {
            addSnackbar('At least one application needs to be selected to filter by apps.', { variant: 'error' });
            return;
        }
        if (values.include_question_filters && !anyQuestionActive(values.question_filters)) {
            addSnackbar('At least one question needs to be selected to filter by question responses.', { variant: 'error' });
            return;
        }
        if (values.include_question_filters && !validateQuesetionFilters(values.question_filters)) {
            addSnackbar('At least one response needs to be selected to filter by question responses.', { variant: 'error' });
            return;
        }

        const [response, error] = await handlePromise(Api.client.post(`company/${router.params.companyId}/contacts/search`, values));

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to fetch contacts.', { variant: 'error' });
            return;
        }

        if (response.data.length === 0) {
            addSnackbar('Query returned zero results.', { variant: 'warning' });
            return;
        }


        setSearchResults(response.data);

    }

    const handleSubmit = async (values: AdvancedSearchForm) => {
        fetchSearchResults(values);
    };

    const customDateRange = useMemo(
        () => ({
            minDate: DateTime.local().minus({ years: 3 }).startOf('year').toJSDate(),
            maxDate: DateTime.local().toJSDate()
        }),
        []
    );

    const defaultAppFilters = {
        sg: false,
        bpn: false,
        ic: false,
        chat: false,
        sl: false,
        ex: false
    }

    const downloadBlob = (blob, fileName = 'grid-data.csv') => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    const exportCSV = () => {
        const SEPARATOR = ',';
        const columns = gridRef?.current?.visibleColumns;

        if (!columns || !searchResults) {
            return;
        }

        const header = columns.map((c) => c.name).join(SEPARATOR);
        const rows = searchResults.map((data) => columns.map((c) => data[c.id]).join(SEPARATOR));

        const contents = [header].concat(rows).join('\n');
        const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

        downloadBlob(blob);
    };

    const renderPhone = ({ value }) => {
        return value && value.length > 10 ?
            `(${value.slice(2, 5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`
            : ''
    }

    async function addToListSegment(val?: number) {
        const rowIds = searchResults?.map((item: any) => item.lead_customer_id);
        if (!rowIds || rowIds.length === 0) {
            return;
        }
        const [response, error] = await handlePromise(Api.client.post(`company/${router.params.companyId}/list-segments/${val}`, rowIds));

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to add contacts to list segment.', { variant: 'error' });
            return;
        }

        router.goTo(
            DefaultRoutes.EditListSegmentPage,
            { companyId: router.params.companyId, listSegmentId: response.data.listSegmentId },
            store
        );
    }

    return (
        <AppPage>
            <Form className="form-holder-block" initialValues={{ date_range_preset: DateRangePreset.LAST7, list_segment: 0, specific_list_segment: 0, timezone: DateTime.local().zoneName, not_in_existing_segment: false, app_filters: defaultAppFilters, filter_by_apps: false, include_question_filters: false, question_filters: {}, specific_spotlight: 0 }} onSubmit={handleSubmit}>
                {(formikProps: FormikProps<AdvancedSearchForm>) => (
                    searchResults ? (
                        <><AppHeader title="Advanced Search Results" onBack={() => setSearchResults(undefined)}></AppHeader>
                            <AppSection>
                                <div className='top-toolbar'>
                                    <div>
                                        <Button style={{ marginTop: 0 }} onClick={() => exportCSV()}>
                                            Export to CSV
                                        </Button></div>
                                    <div>
                                        <InputSelect style={{ marginLeft: 50, width: 400 }} label='List Segment' name='list_segment' formikProps={formikProps} options={[{ id: 0, name: 'Create new list segment...' }, ...listSegments]} allowCreateOption={false} optionLabelKey='name' optionValueKey='id' />
                                    </div>
                                    <div><Button style={{ marginLeft: 20 }} onClick={() => addToListSegment(formikProps.values.list_segment)}>
                                        Add to List Segment
                                    </Button></div>
                                </div>
                                <ReactDataGrid
                                    dataSource={searchResults || []}
                                    onReady={setGridRef}
                                    idProperty="lead_customer_id"
                                    preventRowSelectionOnClickWithMouseMove={true}
                                    columns={[
                                        { name: 'first_name', header: 'First Name', defaultFlex: 2 },
                                        { name: 'last_name', header: 'Last Name', defaultFlex: 2 },
                                        { name: 'email', header: 'Email Address', minWidth: 200, defaultFlex: 4 },
                                        { name: 'phone_number', header: 'Phone Number', defaultFlex: 3, render: renderPhone },
                                        { name: 'lists', header: 'Lists', minWidth: 60, defaultFlex: 1 },
                                        { name: 'agree_sms', header: 'SMS', minWidth: 60, defaultFlex: 1, render: ({ value }) => value ? 'Yes' : 'No' },
                                        { name: 'created_at', header: 'Created', minWidth: 120, defaultFlex: 1, render: ({ value }) => value ? DateTime.fromISO(value).toISODate() : '' },
                                    ]}
                                    loading={false}
                                    style={{ minHeight: 683 }}
                                    pagination='local'
                                    defaultLimit={15}
                                />
                            </AppSection>
                        </>
                    ) :
                        (
                            <>
                                <AppHeader title="Advanced Search"></AppHeader>
                                <AppSection>
                                    <div className='filters'>
                                        <InputSelect
                                            name="date_range_preset"
                                            optionValueKey="value"
                                            className={classNames(styles['as-input'])}
                                            formikProps={formikProps}
                                            options={[
                                                { label: 'Last 7 Days', value: DateRangePreset.LAST7 },
                                                { label: 'Last 30 Days', value: DateRangePreset.LAST30 },
                                                { label: 'Last 90 Days', value: DateRangePreset.LAST90 },
                                                { label: 'Current Week', value: DateRangePreset.CURRENT_WEEK },
                                                { label: 'Current Month', value: DateRangePreset.CURRENT_MONTH },
                                                { label: 'Current Quarter', value: DateRangePreset.CURRENT_QUARTER },
                                                { label: 'Current Year', value: DateRangePreset.YTD },
                                                { label: 'Last Week', value: DateRangePreset.PREVIOUS_WEEK },
                                                { label: 'Last Month', value: DateRangePreset.PREVIOUS_MONTH },
                                                { label: 'Last Quarter', value: DateRangePreset.PREVIOUS_QUARTER },
                                                { label: 'Last Year', value: DateRangePreset.PREVIOUS_YEAR },
                                                { label: 'Custom Range', value: DateRangePreset.CUSTOM }
                                            ]}
                                        />
                                        {(formikProps.values.date_range_preset === DateRangePreset.CUSTOM) && (
                                            <div className="filters-daterange-inputs">
                                                <InputDate
                                                    name="start"
                                                    label="Begin Date"
                                                    labelPlacement="above"
                                                    datePickerProps={{
                                                        ...customDateRange
                                                    }}
                                                    formikProps={formikProps}
                                                />
                                                <InputDate
                                                    name="end"
                                                    label="End Date"
                                                    labelPlacement="above"
                                                    datePickerProps={{
                                                        ...customDateRange
                                                    }}
                                                    formikProps={formikProps}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className='filters'>

                                        <AppCard className='filter-card'>
                                            <AppCardHeader title="Filter By Application" ><InputSwitch name="filter_by_apps" label='Enabled' formikProps={formikProps} /></AppCardHeader>

                                            {formikProps.values.filter_by_apps ? (
                                                <>
                                                    <InputCheckbox name="app_filters['sl']" label='Spotlight' formikProps={formikProps} />
                                                    <InputCheckbox name="app_filters['sg']" label='Schedule Genie' formikProps={formikProps} />
                                                    <InputCheckbox name="app_filters['bpn']" label='Best Price Now' formikProps={formikProps} />
                                                    <InputCheckbox name="app_filters['ic']" label='Income Calculator' formikProps={formikProps} />
                                                    <InputCheckbox name="app_filters['chat']" label='Chat' formikProps={formikProps} />
                                                    <InputCheckbox name="app_filters['ex']" label='External Import' formikProps={formikProps} />
                                                    <AppCardDivider hidden={!formikProps.values.app_filters.sl} />
                                                    <InputSelect
                                                        className='filter-card-select'
                                                        style={{ width: '600px' }}
                                                        hidden={!formikProps.values.app_filters.sl}
                                                        label="Spotlight" name="specific_spotlight"
                                                        optionLabelKey='description' optionValueKey='id'
                                                        options={[{ id: 0, description: 'Any Spotlight' }, ...spotlights]} formikProps={formikProps} />
                                                    <AppCard>Find contacts that were created using any of the selected applications.</AppCard>
                                                </>
                                            ) : <p>Include all contacts collected via any application including external contact imports.</p>}

                                        </AppCard>

                                        <AppCard className='filter-card'>
                                            <AppCardHeader title="Filter By Prospect Questions" ><InputSwitch name="include_question_filters" label='Enabled' formikProps={formikProps} /></AppCardHeader>
                                            {formikProps.values.include_question_filters && questions ? (
                                                questions.map((question) => (
                                                    <div style={{ width: '100%' }}>
                                                        <InputCheckbox name={`question_filters[${question.tag}].active`} label={question.tag} formikProps={formikProps} labelPlacement='end' />
                                                        <span>{question.question}</span>
                                                        {formikProps.values.question_filters && formikProps.values.question_filters[question.tag] && formikProps.values.question_filters[question.tag].active && (
                                                            <div className='question-card-picklist'>
                                                                {question.picklist && question.picklist.length > 0 && question.picklist.map((pickitem, p) => (
                                                                    <><InputCheckbox name={`question_filters[${question.tag}]['picklist'][${pickitem}]`} label={pickitem} labelPlacement='end' formikProps={formikProps} /></>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <AppCardDivider />
                                                    </div>
                                                )
                                                )
                                            ) : (
                                                <p>Include all contacts regardless of their responses to qualifying questions.</p>
                                            )}
                                        </AppCard>

                                        <AppCard className='filter-card'>
                                            <AppCardHeader title="Not Already Added To Existing Segment" ><InputSwitch name="not_in_existing_segment" label='Enabled' formikProps={formikProps} /></AppCardHeader>

                                            {formikProps.values.not_in_existing_segment ? (
                                                <div>                                                    <InputSelect
                                                    className='filter-card-select'
                                                    style={{ width: '600px' }}
                                                    label="List Segment" name="specific_list_segment"
                                                    optionLabelKey='name' optionValueKey='id'
                                                    options={[{ id: 0, name: 'Any List Segment' }, ...listSegments]} formikProps={formikProps} />
                                                </div>
                                            ) : <p>Show all contacts even if they have been added to an existing list segment.</p>}

                                        </AppCard>
                                        <AppCard className='filter-card'>
                                            <AppCardHeader title="Opted In To SMS" ><InputSwitch name="sms_opt_in" label='Enabled' formikProps={formikProps} /></AppCardHeader>

                                            {formikProps.values.sms_opt_in ? (
                                                <p>Only include the contacts that have opted in to receive SMS messages.</p>
                                            ) : <p>Show all contacts regardless of their SMS opt-in status.</p>}

                                        </AppCard>
                                        <ButtonPrimary className='filter-submit' type="submit">{formikProps.isSubmitting ? 'Searching...' : 'Search'}</ButtonPrimary>
                                    </div>
                                </AppSection >
                            </>
                        )
                )}
            </Form>
        </AppPage>
    );
};
