import React from 'react';

import { PageHeader, Col, Row } from 'react-bootstrap';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import { dateTimeFormats } from '../../constants';

export class AuditProps {
    public CompaniesTableOptions: any;
}

export default class CompaniesAudit extends React.Component<AuditProps> {
    public render() {
        return (
            <>
                <PageHeader>Companies</PageHeader>
                <Col className="col-lg-12">
                    <Row>
                        <BootstrapTable {...this.props.CompaniesTableOptions}>
                            <TableHeaderColumn dataField="old_id" isKey width="50">
                                ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_name" width="200" dataSort>
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_phone" width="150">
                                phone
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_address1" width="200">
                                Address 1
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_address2" width="120">
                                Address 2
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_city" width="200">
                                City
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_state" width="200">
                                State
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_zipcode" width="200">
                                Zipcode
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_website_url" width="200">
                                Website
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_business_category_id" width="200">
                                Business Category ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_active" width="100">
                                Active
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_is_test" width="100">
                                Is Test
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_cc_email" width="200">
                                CC Email
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_crm_email" width="200">
                                CRM Email
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_reply_to_email" width="200">
                                Reply To Email
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_ownership_group_id" width="200">
                                Ownership Group ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_property_management_company_id" width="250">
                                Property Management ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_region_id" width="120">
                                Region ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_esitmate_multiplier" width="200">
                                Esitmate Multiplier
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_custom_disclaimer" width="200">
                                Custom Disclaimer
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_active_coupon_id" width="200">
                                Active Coupon ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_created_at" width="200">
                                Created At
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="old_updated_at" width="200">
                                Updated At
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="operation" width="100">
                                Operation
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="time_of_action"
                                width="200"
                                dataSort
                                dataFormat={this.dateFormat}
                            >
                                Date
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="updated_by" width="200" dataSort>
                                Updated by
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Row>
                </Col>
            </>
        );
    }
    public dateFormat = (cell: any, row: any) => {
        return moment(cell).format(dateTimeFormats.auditTime);
    };
}
