import React from 'react';
import { AppCard, AppCardDivider, AppCardHeader, AppSectionHeader, ListGroup, ListGroupItem, Panel } from '../../../../components-v2/shared';
import { DateTime } from 'luxon';
import { Lead, LeadType } from '../../../../types/Lead';
import { underscoreToCapitalize } from '../LeadDetails.helpers';
import { Box } from '@material-ui/core';
import { Icon, Tooltip } from '@lambdacurry/component-library';
import { groupBy } from 'lodash';
import { ILiveChatMessage } from '../../../../types/LiveChat';

const tagColors = ['purple', 'red', 'cyan', 'white', 'light-red']

function getRandomElement(array: string[]) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}

export const AppEventLiveChat: React.FC<{ lead: Lead }> = ({ lead }) => {
    const data = lead.livechat;
    if (!data) return null;
    const livechatMessages = Object.values(groupBy(data?.messages, 'chat_id'));

    const UTMTag: React.FC<{ val: string; className: string, label?: string }> = ({ val, className, label }) => {
        if (!val || val === '') return null;

        return (
            <div className={className}>
                {label &&
                    <h5>
                        {label}
                    </h5>
                }
                <span className={label && 'bold'}>
                    {val}
                </span>
            </div>
        );
    };

    const tooltipText = data.touch_status === 'form_filled'
        ? "Only Initial touch is included and the lead is not yet completed"
        : "Initial touch and closing is included in the lead"

    return (
        <AppCard>
            <Box sx={{
                position: 'relative'
            }}>
                <AppCardHeader
                    icon={{ name: 'chat' }}
                    title="Live Chat"
                    description={DateTime.fromISO(lead.created_at as string).toLocaleString(DateTime.DATETIME_SHORT)}
                />
                <Box style={{
                    position: 'absolute',
                    margin: 0,
                    padding: 0,
                    top: 0,
                    right: 0,
                }}>
                    <Tooltip title={tooltipText}>
                        <Panel type={data.touch_status === 'form_filled' ? "warning" : "success"}
                            style={{
                                margin: 0,
                                padding: '8px 30px',
                            }}
                        >
                            <div className={`color-${data.touch_status === 'form_filled' ? 'warning' : 'success'} clx-text-align-center clx-font-size-16 clx-font-weight-700`}>
                                <Icon name={data.touch_status === 'form_filled' ? 'clock' : 'radioFilled'} /> {
                                    data?.touch_status === 'form_filled' ? 'Unqualified' : 'Qualified'
                                }
                            </div>
                        </Panel>
                    </Tooltip>
                </Box>
            </Box>
            {
                livechatMessages.length > 0 &&
                <>
                    <AppSectionHeader title="Messages" as="Title" />
                    {livechatMessages.map((messages: ILiveChatMessage[], index: number) => {
                        return (
                            <React.Fragment key={index}>
                                <AppSectionHeader
                                    title={
                                        DateTime.fromISO(messages[0].created_at).toLocaleString(DateTime.DATE_FULL)
                                    }
                                    as="Header"
                                    className='app-event-card-message-date'
                                />
                                <Panel className="app-event-card-live-chat-comments customer-lead-journey-labels">
                                    {
                                        messages.map((message: ILiveChatMessage, idx: number) => {
                                            return (
                                                <UTMTag
                                                    key={idx}
                                                    val={`${idx}.  ${message.text}`}
                                                    className={`customer-lead-journey-label ${getRandomElement(tagColors) || 'generic-color'}`}
                                                />
                                            )
                                        })
                                    }
                                </Panel>
                            </React.Fragment>
                        )
                    })}
                </>
            }
            {
                data?.last_pages?.length > 0 &&
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Site Info" as="Header" />
                    <Panel className="app-event-card-live-chat-comments customer-lead-journey-labels">
                        {
                            Object.entries(data?.last_pages[0]).map(([key, value]) => {
                                if (key === 'opened_at') {
                                    return null;
                                }
                                return (
                                    <div className='customer-lead-journey-label' key={key}>
                                        <span className='bold'>{underscoreToCapitalize(key)}</span>: {' '}{value}
                                    </div>
                                )
                            })
                        }
                    </Panel>
                </>
            }

            {
                data?.tags?.length > 0 &&
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Tags" as="Header" />
                    <Panel className="app-event-card-live-chat-comments customer-lead-journey-labels">
                        {
                            data.tags.map((tag: string, index: number) => {
                                return (
                                    <UTMTag
                                        key={index}
                                        val={`${tag}`}
                                        className={`customer-lead-journey-label ${getRandomElement(tagColors) || 'generic-color'}`}
                                    />
                                );
                            })
                        }
                    </Panel>
                </>
            }
            {lead.opened_from_chatbot_ai && (
                <>
                    <AppCardDivider />
                    <AppCard>
                        <AppCardHeader
                            icon={{ name: 'chat' }}
                            title="Claire AI+"
                            description={DateTime.fromISO(lead.created_at as string).toLocaleString(
                                DateTime.DATETIME_SHORT
                            )}
                        />
                        <ListGroup>
                            <ListGroupItem icon={{ name: 'deal' }} label="Lead created by">
                                {(LeadType.LC === lead.lead_type) && 'Live Chat'}
                            </ListGroupItem>
                        </ListGroup>
                    </AppCard>
                </>
            )}

            {
                (data?.statistics && !Array.isArray(data?.statistics)) &&
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Statistics" as="Header" />
                    <Panel className="app-event-card-live-chat-comments customer-lead-journey-labels">
                        {
                            Object.entries(data.statistics).map(([key, value]) => {
                                return (
                                    <UTMTag
                                        label={underscoreToCapitalize(key)}
                                        key={key}
                                        val={`${value}`}
                                        className={`customer-lead-journey-label white align-center`}
                                    />
                                )
                            })
                        }
                    </Panel>
                </>
            }

            {
                (data?.geolocation && !Array.isArray(data?.geolocation)) &&
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Geolocation" as="Header" />
                    <Panel className="app-event-card-live-chat-comments customer-lead-journey-labels">
                        {
                            Object.entries(data.geolocation).map(([key, value]) => {
                                return (
                                    <UTMTag
                                        label={underscoreToCapitalize(key)}
                                        key={key}
                                        val={`${value}`}
                                        className={`customer-lead-journey-label white align-center`}
                                    />
                                )
                            })
                        }
                    </Panel>
                </>
            }
        </AppCard>
    );
};
