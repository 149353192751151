import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { InjectedProps } from '../../types';
import {
    ActionList,
    AppContent,
    AppHeader,
    AppList,
    AppListItem,
    AppListItemContent,
    AppPage,
    InputSwitch
} from '../../components-v2/shared';


export interface IState {
    data?: any;
}

export interface IFeatureFlags {
    flags_id: number;
    name: string;
    allow_global: boolean;
    company_has_flag: boolean;
}

@inject('store')
@observer
export default class FeatureFlags extends React.Component<{}, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
        };
    }


    public get injected() {
        return this.props as InjectedProps;
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public fetchData() {
        const { getAllFeatureFlags } = this.injected.store;
        return getAllFeatureFlags().then((response: any) => {
            this.setState({ data: response.data });
        });
    }

    public render() {

        const handleToggle = (flagId: number, newValue: boolean) => {
            const data = this.state.data;
            const changedIndex = data.findIndex(({ flags_id }: any) => flagId === flags_id);
            data[changedIndex].company_has_flag = newValue
            const { saveAllFeatureFlags } = this.injected.store;
            saveAllFeatureFlags(data)
                .then(() => {
                    this.fetchData();
                })
                .catch(() => {
                });
        }

        return (
            <AppPage>
                <AppHeader title="Beta Features"></AppHeader>
                <AppContent>
                    <AppList>
                        {this.state.data && this.state.data.map((feature: { name: string; company_has_flag: boolean, flags_id: number }, index: number) => {
                            return (
                                <>
                                    <AppListItem key={index}>
                                        <AppListItemContent title={feature.name} />
                                        <ActionList position="end">
                                            <InputSwitch
                                                onClick={() => {
                                                    handleToggle(feature.flags_id, !feature.company_has_flag);
                                                }}
                                                checked={feature.company_has_flag}
                                            />
                                        </ActionList>
                                    </AppListItem>
                                </>
                            );
                        })}
                    </AppList>
                </AppContent>
            </AppPage>
        );
    }
}