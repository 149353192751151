import React, { forwardRef, ReactNode, RefObject } from 'react';
import { Icon } from '@lambdacurry/component-library';
import { ViewSwitcherOption } from './ViewSwitcher.types';
import { Avatar } from '../../../../../components-v2/shared';

export interface ViewSwitcherToggleProps {
    label: string;
    selectedOption?: ViewSwitcherOption;
    defaultIcon?: ReactNode;
    onClick: (event: any) => void;
    disabled?: boolean;
}

export const ViewSwitcherToggle = forwardRef(
    (
        { label, selectedOption, defaultIcon, onClick, disabled }: ViewSwitcherToggleProps,
        ref: RefObject<HTMLButtonElement>
    ) => (
        <button ref={ref} className="view-switcher-toggle" onClick={onClick} disabled={disabled}>
            <div className="view-switcher-toggle-icon">
                {!selectedOption && defaultIcon}
                {selectedOption && (
                    <Avatar img={selectedOption.icon} className="view-switcher-toggle-avatar">
                        {selectedOption.label.charAt(0).toUpperCase()}
                    </Avatar>
                )}
            </div>
            <div className="view-switcher-toggle-content">
                <div className="view-switcher-toggle-label">{label}</div>
                <div className="view-switcher-toggle-value">{selectedOption?.label}</div>
            </div>
            <Icon name="unfold" className="view-switcher-toggle-caret" />
        </button>
    )
);
