import React, { FC } from 'react';
import classNames from 'classnames';
import { AppCard, AppCardProps, Title } from '..';
import { ChartCardEmpty } from './ChartCardEmpty';
import { ChartType } from '../../../types';

import './chart-card.scss';

export interface ChartCardProps extends AppCardProps {
    title?: string;
    type?: keyof typeof ChartType;
    isEmpty?: boolean;
}

export const ChartCard: FC<ChartCardProps> = ({ className, title, type = 'pie', isEmpty, children, ...props }) => (
    <AppCard className={classNames('chart-card', `chart-card--${type}`, className)} {...props}>
        {title && <Title className="chart-card__title">{title}</Title>}

        {children && <>{children}</>}

        {isEmpty && <ChartCardEmpty type={type} />}
    </AppCard>
);
