import classNames from 'classnames';
import { FormikProps } from 'formik';
import React from 'react';
import { InputSwitch, InputSelect, IconButton, InputCheckbox, InputText } from '../../components-v2/shared';
import { LeadNurtureTemplate } from '../EmailEditor/EmailEditor.types';
import styles from './drip-schedule-editor.module.scss';
import { EditDripScheduleForm } from './DripScheduleEditorEditSchedulePage';

const timeRangeOptions = [
    { key: 1, label: '1 hour' },
    { key: 2, label: '2 hours' },
    { key: 3, label: '3 hours' },
    { key: 4, label: '4 hours' },
    { key: 8, label: '8 hours' },
    { key: 24, label: '1 day' },
    { key: 48, label: '2 days' },
    { key: 72, label: '3 days' },
    { key: 96, label: '4 days' },
    { key: 120, label: '5 days' },
    { key: 144, label: '6 days' },
    { key: 168, label: '1 week' },
    { key: 336, label: '2 weeks' },
    { key: 504, label: '3 weeks' },
    { key: 672, label: '4 weeks' }
];

const timeRangeOptionList = (timeRange: 'before' | 'after') => {
    return timeRange === 'after'
        ? timeRangeOptions
        : timeRangeOptions.map(opt => {
              return {
                  key: -opt.key,
                  label: opt.label
              };
          });
};

const templateNameLookup = (templateId: number, templates: LeadNurtureTemplate[]) => {
    return (
        templates.find(t => {
            return t.id === templateId;
        })?.name || 'Unknown'
    );
};

const templateSMSEnabledLookup = (templateId: number, templates: LeadNurtureTemplate[]): boolean => {
    return (
        templates.find(t => {
            return t.id === templateId;
        })?.smsEnabled === true
    );
};

export const EditDripEntryRowForm: React.FC<{
    i: number;
    templates: LeadNurtureTemplate[];
    formikProps: FormikProps<EditDripScheduleForm>;
    anchorName: string;
    timeRange: 'before' | 'after';
    handleDeleteEntry: (i: number, formikProps: FormikProps<EditDripScheduleForm>) => void;
}> = ({ i, templates, formikProps, handleDeleteEntry, anchorName, timeRange }) => {
    return (
        <div className={classNames(styles['edit-drip-schedule-template-row'])}>
            <InputText hidden={true} name={`entries[${i}].id`} formikProps={formikProps} />
            <InputText name={`entries[${i}].lead_nurture_template_id`} formikProps={formikProps} hidden={true} />
            <InputSwitch
                name={`entries[${i}].active`}
                label="Enabled"
                formikProps={formikProps}
                className={classNames(styles['edit-drip-schedule-template-row-toggle'])}
            />
            <InputText
                value={templateNameLookup(formikProps.values.entries[i].lead_nurture_template_id, templates)}
                label="Selected Email Template"
                name={`entries[${i}].lead_nurture_template_name`}
                className={classNames(styles['edit-drip-schedule-template-row-name'])}
            />
            <InputSelect
                name={`entries[${i}].hours_from_lead_event`}
                label={`Time ${timeRange === 'before' ? 'Before' : 'After'} ${anchorName}`}
                className={classNames(styles['edit-drip-schedule-template-row-time-select'])}
                optionValueKey="key"
                contentEditable={false}
                options={timeRangeOptionList(timeRange)}
                formikProps={formikProps}
            />
            <InputCheckbox name={`entries[${i}].send_via_email`} label="Email" formikProps={formikProps} />
            <InputCheckbox
                name={`entries[${i}].send_via_sms`}
                label="SMS"
                formikProps={formikProps}
                disabled={!templateSMSEnabledLookup(formikProps.values.entries[i].lead_nurture_template_id, templates)}
            />
            {timeRange === 'before' && (
                <InputSwitch
                    name={`entries[${i}].allow_catchup`}
                    label="Allow Catch-up?"
                    formikProps={formikProps}
                    className={classNames(styles['edit-drip-schedule-template-row-toggle'])}
                />
            )}
            <IconButton
                icon="trash"
                onClick={() => handleDeleteEntry(i, formikProps)}
                className={classNames(styles['edit-drip-schedule-template-row-trash'])}
            />
            <div className="flex-spacer" />
        </div>
    );
};
