import React, { useEffect, FunctionComponent, useState, useRef } from 'react';
import ReactQuill, { ReactQuillProps, QuillOptions } from 'react-quill';
import { FormikProps } from 'formik';
import { Icon } from '..';
import { get as _get } from 'lodash';

import 'react-quill/dist/quill.snow.css';
import './wysiwyg.scss';

interface WysiwygProps extends ReactQuillProps {
    name: string;
    formikProps: FormikProps<{}>;
    templateVariables?: string[];
    hideFontControls?: boolean;
}

// Tokens which should be replaced in the editor
const replaceTokens: { [x: string]: any } = {
    companyEmail: '<a href="mailto:{{companyEmail}}">{{companyEmail}}</a>',
    companyPhone: '<a href="tel:{{companyPhone}}">{{companyPhone}}</a>'
};

const errorStyles = {
    color: '#f44336',
    fontSize: '0.8em',
    lineHeight: '1.25',
    marginTop: '8px',
    marginLeft: '14px'
};

const buildQuillConfig: (templateVariables: string[], hideFontControls?: boolean) => QuillOptions = (
    templateVariables,
    hideFontControls
) => {
    const container = !!hideFontControls
        ? [{ placeholder: templateVariables }]
        : [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            // [{ font: [] }],
            ['clean'],
            [{ placeholder: templateVariables }]
        ];
    return {
        theme: 'snow',
        modules: {
            toolbar: {
                container,
                handlers: {
                    // tslint:disable-next-line: object-literal-shorthand
                    placeholder: function (value: any) {
                        if (!value) {
                            return;
                        }

                        const initialValue = value;

                        // Some placeholders should be replaced with HTML so that they are properly formatted when sent
                        const replaceTokenValue = replaceTokens[value.replace(/{/g, '').replace(/}/g, '')];

                        const wrapper = this as any;
                        const cursorPosition = wrapper.quill.getSelection().index;
                        const newCursorPosition = replaceTokenValue
                            ? cursorPosition + initialValue.length
                            : cursorPosition + value.length;

                        replaceTokenValue
                            ? wrapper.quill.clipboard.dangerouslyPasteHTML(cursorPosition, replaceTokenValue)
                            : wrapper.quill.insertText(cursorPosition, value);
                        wrapper.quill.setSelection(newCursorPosition);
                    }
                }
            }
        }
    };
};

export const Wysiwyg: FunctionComponent<WysiwygProps> = ({
    placeholder = 'Enter your text here...',
    templateVariables,
    hideFontControls,
    formikProps,
    style,
    ...props
}) => {
    // Note: we need to initialize the QuillConfig once and couldn't use a useEffect, because it needed to be
    // instantiated before the React Quill component.
    const [quillConfig] = useState(buildQuillConfig(templateVariables || [], hideFontControls));

    const wysiwygRef = useRef<ReactQuill>(null);

    const fieldValue = _get(formikProps.values, props.name);

    const fieldError =
        formikProps?.errors && props.name && _get(formikProps.touched, props.name)
            ? _get(formikProps.errors, props.name)
            : '';

    const quillStyle = fieldError ? {} : style;

    // Note: we sometimes need to force trigger an update when fields are reset
    useEffect(() => {
        const value = _get(formikProps.values, props.name);
        if (value && wysiwygRef.current?.getEditorContents() !== fieldValue) {
            const editor = wysiwygRef.current?.editor;
            editor?.setContents(editor.clipboard.convert(value), 'silent');
        }
    }, [formikProps.values, props.name]);

    useEffect(() => {
        const placeholderPickerItems: NodeListOf<HTMLElement> = document.querySelectorAll(
            '.ql-placeholder .ql-picker-item'
        );
        if (
            placeholderPickerItems.length > 0 &&
            placeholderPickerItems[0].textContent === placeholderPickerItems[0].dataset.value
        ) {
            return;
        }

        placeholderPickerItems.forEach((item: any) => {
            item.textContent = item.dataset.value;
        });
        const quillPlaceholderLabel = document.querySelector('.ql-placeholder .ql-picker-label');

        if (quillPlaceholderLabel) {
            quillPlaceholderLabel.innerHTML =
                'Insert placeholder&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
                document.querySelector('.ql-placeholder .ql-picker-label')?.innerHTML;
        }
    }, []);

    const handleChange = (newValue: string) => {
        // Make sure we set a true empty value to avoid saving extra space when the editor is cleared out.
        if (newValue === '<p><br></p>') {
            newValue = '';
        }

        formikProps.setFieldValue(props.name, newValue);
    };

    return (
        <div className="wysiwyg">
            <ReactQuill
                ref={wysiwygRef}
                className="wysiwyg-editor"
                placeholder={placeholder}
                theme={quillConfig.theme}
                modules={quillConfig.modules}
                onChange={handleChange}
                defaultValue={fieldValue}
                style={quillStyle}
                {...props}
            />
            <div className="wysiwyg-resize">
                <Icon name="resize" />
            </div>
            {fieldError && <p style={errorStyles}>{fieldError}</p>}
        </div>
    );
};
