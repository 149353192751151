import { DateTime } from 'luxon';
import { BooleanParam, DateParam, NumericArrayParam, StringParam } from 'use-query-params';
import { AccessibleAgency, AccessibleCompany } from '../../types';
import { removeEmptyFromObject } from '../../util/other';

export const FilterQueryParamsSetup = {
    dateRangePreset: StringParam,
    dateRangeTimezone: StringParam,
    dateRangeStart: DateParam,
    dateRangeEnd: DateParam,
    business_category_ids: NumericArrayParam,
    dmas: NumericArrayParam,
    ownership_group_ids: NumericArrayParam,
    property_management_company_ids: NumericArrayParam,
    region_ids: NumericArrayParam,
    company_ids: NumericArrayParam,
    agency_ids: NumericArrayParam,
    include_test_companies: BooleanParam,
    only_active_companies: BooleanParam
};

export interface FilterQueryParams {
    dateRangePreset?: string | null | undefined;
    dateRangeTimezone?: string | null | undefined;
    dateRangeStart?: Date | null | undefined;
    dateRangeEnd?: Date | null | undefined;
    business_category_ids?: number[];
    dmas?: number[];
    ownership_group_ids?: number[];
    property_management_company_ids?: number[];
    region_ids?: number[];
    company_ids?: number[];
    agency_ids?: number[];
    include_test_companies?: boolean;
    only_active_companies?: boolean;
}

export const getFiltersFromQueryParams: (
    filterQueryParams: FilterQueryParams,
    initialFilters: Partial<Filters> | undefined
) => Filters = ({ dateRangePreset, dateRangeTimezone, dateRangeStart, dateRangeEnd, ...rest }, initialFilters) => {
    const filters: Filters = { ...InitialFilters, ...initialFilters, ...removeEmptyFromObject(rest) };

    if (dateRangePreset) {
        filters.dateRange.preset = dateRangePreset as DateRangePreset;
    }

    if (dateRangeTimezone) {
        filters.dateRange.timezone = dateRangeTimezone;
    }

    if (dateRangeStart && dateRangeEnd) {
        filters.dateRange.custom = { start: dateRangeStart, end: dateRangeEnd };
    }

    return filters;
};

export const setQueryParamsFromFilters: (filters: Filters) => FilterQueryParams = filters => {
    const queryParams: FilterQueryParams = {};

    if (filters.dateRange.preset) {
        queryParams.dateRangePreset = filters.dateRange.preset;
    }
    if (filters.dateRange.timezone) {
        queryParams.dateRangeTimezone = filters.dateRange.timezone;
    }
    if (filters.dateRange.custom && filters.dateRange.preset === DateRangePreset.CUSTOM) {
        const startDate =
            typeof filters.dateRange.custom.start === 'string'
                ? DateTime.fromISO(filters.dateRange.custom.start).toJSDate()
                : filters.dateRange.custom.start;
        const endDate =
            typeof filters.dateRange.custom.end === 'string'
                ? DateTime.fromISO(filters.dateRange.custom.end).toJSDate()
                : filters.dateRange.custom.end;

        queryParams.dateRangeStart = startDate;
        queryParams.dateRangeEnd = endDate;
    }
    if (filters.business_category_ids) {
        queryParams.business_category_ids = filters.business_category_ids;
    }
    if (filters.dmas) {
        queryParams.dmas = filters.dmas;
    }
    if (filters.ownership_group_ids) {
        queryParams.ownership_group_ids = filters.ownership_group_ids;
    }
    if (filters.property_management_company_ids) {
        queryParams.property_management_company_ids = filters.property_management_company_ids;
    }
    if (filters.region_ids) {
        queryParams.region_ids = filters.region_ids;
    }
    if (filters.company_ids) {
        queryParams.company_ids = filters.company_ids;
    }
    if (filters.agency_ids) {
        queryParams.agency_ids = filters.agency_ids;
    }
    if (filters.include_test_companies) {
        queryParams.include_test_companies = filters.include_test_companies;
    }
    if (filters.only_active_companies) {
        queryParams.only_active_companies = filters.only_active_companies;
    }

    return queryParams;
};

export enum FiltersContext {
    ADMIN = 'admin',
    AGENCY = 'agency',
    COMPANY = 'company'
}

export interface FilterDateRange {
    preset: DateRangePreset;
    timezone: string;
    custom?: {
        start: Date;
        end: Date;
    };
}

export enum DateRangePreset {
    LAST7 = 'last7',
    LAST30 = 'last30',
    LAST90 = 'last90',
    YTD = 'ytd',
    CURRENT_WEEK = 'current_week',
    CURRENT_MONTH = 'current_month',
    CURRENT_QUARTER = 'current_quarter',
    PREVIOUS_WEEK = 'previous_week',
    PREVIOUS_MONTH = 'previous_month',
    PREVIOUS_QUARTER = 'previous_quarter',
    PREVIOUS_YEAR = 'previous_year',
    CUSTOM = 'custom'
}

export const InitialFilters = {
    dateRange: { preset: DateRangePreset.LAST7, timezone: DateTime.local().zoneName },
    business_category_ids: [],
    dmas: [],
    ownership_group_ids: [],
    property_management_company_ids: [],
    region_ids: [],
    company_ids: [],
    agency_ids: [],
    include_test_companies: false,
    only_active_companies: false
};

export const getInitialFiltersState: (initialFilters?: Filters) => FiltersState = initialFilters => ({
    loadingStatus: 'loading',
    filters: initialFilters || InitialFilters
});

export interface Filters {
    dateRange: FilterDateRange;
    business_category_ids: number[];
    dmas: number[];
    ownership_group_ids: number[];
    property_management_company_ids: number[];
    region_ids: number[];
    company_ids: number[];
    agency_ids: number[];
    include_test_companies: boolean;
    only_active_companies: boolean;
}

export type FiltersLoadingStatus = 'loading' | 'error' | 'complete';

export interface FiltersState {
    loadingStatus: FiltersLoadingStatus;
    context?: FiltersContext;
    filterDrawer?: boolean;
    filters: Filters;
}

export const FiltersReducers = {
    setLoadingStatus: (state: FiltersState, loadingStatus: FiltersLoadingStatus) => ({
        ...state,
        loadingStatus
    }),
    setFiltersContext: (state: FiltersState, { agencyId, companyId }: { agencyId?: number; companyId?: number }) => {
        const context = agencyId ? FiltersContext.AGENCY : companyId ? FiltersContext.COMPANY : FiltersContext.ADMIN;
        return { ...state, agencyId, companyId, context };
    },
    toggleFilterDrawer: (state: FiltersState) => ({
        ...state,
        filterDrawer: !state.filterDrawer
    }),
    setDateRange: (
        state: FiltersState,
        { dateRangePreset, start, end }: { dateRangePreset: DateRangePreset; start: Date; end: Date }
    ) => {
        state.filters = { ...state.filters };

        const dateRange: FilterDateRange = {
            preset: dateRangePreset,
            timezone: state.filters.dateRange.timezone
        };

        if (dateRangePreset === DateRangePreset.CUSTOM) {
            const today = DateTime.local()
                .setZone(state.filters.dateRange.timezone)
                .startOf('day');
            const newStartDate = start || state.filters.dateRange.custom?.start || today.minus({ week: 1 }).toJSDate();
            const newEndDate = end || state.filters.dateRange.custom?.end || today.endOf('day').toJSDate();
            dateRange.custom = { start: newStartDate, end: newEndDate };
        }

        state.filters.dateRange = { ...dateRange };

        return { ...state };
    },
    setFilters: (state: FiltersState, filters: Partial<Filters>) => {
        const newFilters = { ...state.filters, ...filters };
        if (filters.dateRange?.preset && filters.dateRange.preset !== DateRangePreset.CUSTOM) {
            delete newFilters.dateRange?.custom;
        }
        return { ...state, filters: newFilters };
    }
};

export interface FiltersReducerAction {
    name: keyof typeof FiltersReducers;
    payload?: any;
}

export const FiltersReducer = (state: FiltersState, action: FiltersReducerAction) => {
    if (!FiltersReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: FiltersState = FiltersReducers[action.name](state, (action as any).payload);
    return nextState;
};

export const selectFiltersDateStartFromContext = (
    context: FiltersContext | undefined,
    agency: AccessibleAgency,
    company: AccessibleCompany,
    accessibleCompanies: AccessibleCompany[]
) => {
    if (!context) {
        return;
    }

    if (agency && context === FiltersContext.AGENCY) {
        const agencyCompanies = accessibleCompanies
            .filter(({ agency_id }) => agency.id === agency_id)
            .sort((a, b) => (a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0));

        return agencyCompanies.length ? DateTime.fromISO(agencyCompanies[0].created_at).toJSDate() : null;
    }

    if (company && context === FiltersContext.COMPANY) {
        return DateTime.fromISO(company.created_at).toJSDate();
    }

    return DateTime.local()
        .minus({ years: 3 })
        .startOf('year')
        .toJSDate();
};

export const hasActiveFilter = (
    {
        business_category_ids,
        dmas,
        ownership_group_ids,
        property_management_company_ids,
        region_ids,
        company_ids,
        agency_ids,
        include_test_companies,
        only_active_companies
    }: Filters,
    context?: FiltersContext
) => {
    return business_category_ids.length ||
        dmas.length ||
        ownership_group_ids.length ||
        property_management_company_ids.length ||
        region_ids.length ||
        (context !== FiltersContext.COMPANY && company_ids.length) ||
        (context !== FiltersContext.AGENCY && agency_ids.length)
        ? true
        : false || include_test_companies || only_active_companies;
};
