export const DEFAULT_EMAIL_REGEX = /^([a-z0-9])([a-z\.0-9_%\-\+\*'’!#`&\$/]*)@(.+)\.(.+)$/;
export type EntityLevel = 'global' | 'agency' | 'company';

export interface EmailHubSenderProfile {
    id: number;
    guid: string;
    domain_name: string;
    domain_id: string;
    mailjet_sender_id: string;
    dkim_txt_name: string;
    dkim_txt_value: string;
    dkim_status: string;
    token_txt_name: string;
    token_txt_value: string;
    spf_txt_value: string;
    spf_status: string;
    created_at: string;
    updated_at?: string;
    verified_at?: string;
}

export interface EmailSender {
    id: number;
    from_email: string;
    settings?: EmailHubSenderProfile;
    created_at: string;
    updated_at: string | null;
    verified_at: string | null;
}

export interface EmailSenderProfile {
    senderEmail: string;
    senderName: string;
    replyToEmail: string;
}

export interface EmailSenderBundle {
    emailSender?: EmailSender;
    managedBy: EntityLevel;
    agencyExists: boolean;
    agencyName?: string;
    profile: EmailSenderProfile;
}
