import { observer } from 'mobx-react';
import {
    ActionList,
    AppContent,
    AppHeader,
    AppList,
    AppListItem,
    AppListItemContent,
    AppPage,
    IconButton,
    InputSwitch,
    Tooltip
} from '../../components-v2/shared';
import React, { useState } from 'react';
import useStore from '../../store/useStore';
import { useAsyncEffect } from '../../util/async';

interface FeatureToggleData {
    name: string;
    slug: string;
    disabled?: boolean;
    tooltip?: string;
}

const Features = observer(() => {
    const [loading, setLoading] = useState(true);
    const { store } = useStore();
    const { router, fetchFeatures, features, saveCompanyFeatures, isSuperAdminRole, activeUser } = store;
    const { companyId } = router.params;

    const fetchRequiredData = async () => {
        window.scrollTo(0, 0);
        await fetchFeatures(parseInt(companyId, 10));
        setLoading(false);
    };
    useAsyncEffect(fetchRequiredData);

    const generallyAvailableFeatures: FeatureToggleData[] = [
        { name: 'Chat Legacy', slug: 'chat' },
        { name: 'Live Chat', slug: 'live_chat' },
        { name: 'SMS Messaging', slug: 'sms_messaging' },
        { name: 'Income Calculator', slug: 'income_calculator' },
        { name: 'Call Us Now', slug: 'call_us_now' },
        { name: 'Spotlight', slug: 'concession_manager' },
        { name: 'Schedule Genie', slug: 'schedule_genie' },
        { name: 'Text Us Now', slug: 'text_us_now' },
        { name: 'Insite', slug: 'insite' },
        { name: 'Claire AI+', slug: 'chatbot_ai' }
    ];

    // only manageable by super admins
    const additionalAvailableFeatures: FeatureToggleData[] = isSuperAdminRole
        ? [
              { name: 'ULM Spotlight', slug: 'ulm_concession_manager' },
              { name: 'ULM Schedule Genie', slug: 'ulm_schedule_genie' },
              { name: 'Realync Video Integration', slug: 'realync' },
              { name: 'Lead Nurturing', slug: 'lead_nurturing' },
              {
                  name: 'Concession Automation',
                  slug: 'concession_automation',
                  disabled: true,
                  tooltip: 'This feature is managed in the CLXperience Platform'
              }
          ]
        : [];

    let featureFlagFeatures: any = [];
    activeUser?.previewFlags.map(flag => {
        switch (flag) {
            case 'chatbot':
                featureFlagFeatures.push({ name: 'Virtual Assistant', slug: 'chatbot' });
                break;
        }
    });

    const availableFeatures = generallyAvailableFeatures.concat(additionalAvailableFeatures, featureFlagFeatures);

    const handleToggle = (slug: string) => {
        const activeFeatureSlugs: string[] = availableFeatures
            .filter((feature: { name: string; slug: string }) => {
                if (feature.slug === slug) {
                    return !features[feature.slug];
                } else {
                    return features[feature.slug];
                }
            })
            .map((feature: { name: string; slug: string }) => {
                return feature.slug;
            });

        saveCompanyFeatures(activeFeatureSlugs);
    };

    return (
        <AppPage loading={loading}>
            <AppHeader title="Features"></AppHeader>
            <AppContent>
                <AppList>
                    {availableFeatures.map((feature, index) => {
                        return (
                            <>
                                <AppListItem key={index}>
                                    <AppListItemContent title={feature.name} />
                                    <ActionList position="end">
                                        {!!feature.tooltip && (
                                            <Tooltip title={feature.tooltip}>
                                                <IconButton icon="question" />
                                            </Tooltip>
                                        )}
                                        <InputSwitch
                                            onClick={() => {
                                                handleToggle(feature.slug);
                                            }}
                                            checked={!!features[feature.slug]}
                                            disabled={feature.disabled}
                                        />
                                    </ActionList>
                                </AppListItem>
                            </>
                        );
                    })}
                </AppList>
            </AppContent>
        </AppPage>
    );
});

export default Features;
