import { FormikProps } from 'formik';
import * as React from 'react';
import { Col, ControlLabel, FormControl, FormControlProps, FormGroup, HelpBlock } from 'react-bootstrap';
import { FieldGroupColSizes, COLUMNS_ONE } from './FieldGroupColSizes';
import classNames from 'classnames';
import styles from './fieldgroup.module.scss';
import { replaceErrorLabel } from '../../util/error';

export interface FieldGroupProps<T> extends FormControlProps, FormikProps<T> {
    key?: string | number | undefined;
    labels: Record<string, string | undefined>;
    placeholders?: Record<string, string | undefined>;
    maxLength?: number;
    colSize?: FieldGroupColSizes;
    helpText?: string | React.ReactElement;
    suffix?: string | React.ReactElement;
    placeholder?: string;
    showCharacters?: boolean;
    popoverText?: () => {};
    mask?: (value: string | number) => {};
    preview?: boolean;
}
export type ValidationState = 'success' | 'warning' | 'error' | null;

export function FieldGroup<T>(props: FieldGroupProps<T>) {
    let validationState: ValidationState = null;

    const { id, preview } = props;
    const maxLength = props.maxLength || 50;
    const colSize = props.colSize ? props.colSize : COLUMNS_ONE;
    let value = props.values[`${id}`] === null || props.values[`${id}`] === undefined ? '' : props.values[`${id}`];
    const label = props.labels[`${id}`];
    const placeholder = props.placeholder ? props.placeholder : props.placeholders ? props.placeholders[`${id}`] : '';
    const error = replaceErrorLabel(props.errors[`${id}`], label || '');
    const countItems = value ? value.length : 0;
    const calcCharacters = maxLength - countItems;

    if (props.value) {
        validationState = 'success';
    }

    if (error) {
        validationState = 'error';
    }

    if (props.mask) {
        value = props.mask(value);
    }
    const isSelect = props.componentClass === 'select';
    let optionText = '';
    if (preview && isSelect && Array.isArray(props.children)) {
        const filterOption: any = props.children.find((option: any) => {
            if (option && typeof option === 'object') {
                return option.props && typeof value === 'string' ? option.props.value === value : false;
            }
            return false;
        });
        optionText = filterOption && filterOption.props ? filterOption.props.children : '';
    }
    return (
        <Col {...colSize}>
            <FormGroup
                controlId={id}
                validationState={validationState}
                className={classNames(props.className, props.suffix ? styles['hasSuffix'] : '')}
                hidden={props.hidden}
            >
                <ControlLabel>
                    {label} {props.popoverText ? props.popoverText() : ''}
                </ControlLabel>
                {preview ? (
                    isSelect ? (
                        <p>{optionText}</p>
                    ) : (
                        <p>{value}</p>
                    )
                ) : (
                    <FormControl
                        name={id}
                        componentClass={props.componentClass}
                        rows={props.rows}
                        disabled={props.disabled}
                        type={props.type}
                        autoComplete={props.autoComplete}
                        value={value}
                        onChange={props.handleChange}
                        maxLength={props.maxLength}
                        hidden={props.hidden}
                        bsSize={props.bsSize}
                        placeholder={placeholder}
                        onKeyUp={props.onKeyUp}
                    >
                        {props.children}
                    </FormControl>
                )}
                {props.showCharacters ? (
                    <HelpBlock className={'text-right'} style={calcCharacters < 0 ? { color: 'red' } : {}}>
                        {calcCharacters} characters remaining
                    </HelpBlock>
                ) : null}
                {props.suffix ? <div className={styles['suffix']}>{props.suffix}</div> : null}
                {props.helpText && <HelpBlock>{props.helpText}</HelpBlock>}
                {error && <HelpBlock>{error}</HelpBlock>}
            </FormGroup>
        </Col>
    );
}
