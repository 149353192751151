import React, { FC } from 'react';
import { Icon, ListGroupItem } from '../../components-v2/shared';

type StatData = { label: string; val: number; previousVal?: number, denominator?: number };

export interface ClarityStatChartProps {
    stats: Array<StatData>;
}

const upOrDown = (stat: { val: number; previousVal?: number }) => {
    return stat.val < (stat.previousVal || 0) ? 'arrowDown' : 'arrowUp';
};

const previousValueClass = (stat: { val: number; previousVal?: number }) => {
    if (stat.val === stat.previousVal) {
        return 'stat-card-previous-value';
    }
    return stat.val < (stat.previousVal || 0) ? 'stat-card-previous-value-down' : 'stat-card-previous-value-up';
};

const asPercent = (stat: { val: number; previousVal?: number }) => {
    if (stat.previousVal === undefined || stat.previousVal === 0) {
        return <>Up from 0</>;
    }
    const result = Math.abs(Math.round(((stat.val - stat.previousVal) / stat.previousVal) * 100));
    const word = stat.val < stat.previousVal ? 'Down' : 'Up';
    return `${word} ${result}% (${stat.previousVal})`;
};

export const ClarityStatChart: FC<ClarityStatChartProps> = stats => {
    return (
        <>
            <div className='clarity-dashboard-stat-grid'>
                {stats.stats.map(stat => (
                    <ListGroupItem label={stat.label} className="clarity-stat-card">
                        <div className={'stat-card-value'}><span className='stat-as-number'>{stat.val}</span><span className='stat-as-percent'>{stat.denominator && stat.denominator > 0 ? (<>{Math.round((stat.val / stat.denominator) * 100)}%</>) : (<>{stat.val}</>)}</span></div>
                        {stat.previousVal !== undefined && (
                            <span className={previousValueClass(stat)}>
                                {stat.val === stat.previousVal ? (
                                    <>No change</>
                                ) : (
                                    <>
                                        <Icon name={upOrDown(stat)} />
                                        {asPercent(stat)}
                                    </>
                                )}
                            </span>
                        )}
                    </ListGroupItem>
                ))}
            </div>
        </>
    );
};
