import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputSelect,
    ButtonOutlinePrimary
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmTimezones } from '../helpers/FormHelper';
import { crmConfigTypes, RulesActionFactory, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
import { RuleAction } from '../../../../types';
export interface RentCafeFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface RentCafeCrm extends Crm {
    configuration: {
        property_code: string;
        source_name: string;
        rentcafe_api_key: string;
        rentcafe_api_vendor_key: string;
        company_code: string;
        crm_tz: string;
        sync_options: string;
    };
}

const ruleActions: Array<RuleAction> = [
    RulesActionFactory.ReplaceStringAction('source_name', 'Replace Source Name with new value')
];

export const RentCafeForm: FC<RentCafeFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<RentCafeCrm>) => {
        await onValidate(formikProps.values, crmConfigTypes.rentcafe);
    };

    const handleSubmit = async (values: RentCafeCrm, actions: FormikHelpers<RentCafeCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.rentcafe);
    };

    const initialValues = props.initialValues;
    if (!initialValues.configuration) {
        initialValues.configuration = { sync_options: 'push' };
    }

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<RentCafeCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: RENTCafé" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader
                                    title="Connection Settings"
                                    subtitle="Contact RENTCafé support for your exact connection settings"
                                />
                                <InputText
                                    name="configuration.company_code"
                                    label="Company Code"
                                    required={true}
                                    helperText="RENTCafé Company Code"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.property_code"
                                    label="Property Code"
                                    helperText="RENTCafé Property Code"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader title="Time Zone" />
                                <InputSelect
                                    label="CRM Time Zone"
                                    name="configuration.crm_tz"
                                    helperText="The time zone used for submitting leads with appointment time information"
                                    options={crmTimezones}
                                    optionValueKey="key"
                                    optionLabelKey="label"
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                                <AppSectionHeader title="Lead Settings" />
                                <InputText
                                    name="configuration.source_name"
                                    label="Source Name"
                                    helperText="RENTCafé Source Name"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                            <div className="field_group_col">
                                <AppSectionHeader title="Marketing API" />
                                <InputText
                                    name="configuration.rentcafe_api_vendor_key"
                                    label="Marketing API Vendor Key"
                                    helperText="RENTCafé Marketing API Vendor Key"
                                    required={false}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.rentcafe_api_key"
                                    label="Marketing API Key"
                                    helperText="RENTCafé Marketing API Key"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                            <div className="field_group_col">
                                <AppSectionHeader
                                    title="Web Services API"
                                    subtitle="Use for leads that do not create a calendar appointment"
                                />
                                <InputText
                                    name="configuration.webservice_api_token"
                                    label="API Token"
                                    helperText="RENTCafé Webservice API Token"
                                    required={false}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.webservice_api_username"
                                    label="API Username"
                                    helperText="RENTCafé Webservice API Username"
                                    required={false}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.webservice_api_password"
                                    label="API Password"
                                    helperText="RENTCafé Webservice API Password"
                                    required={false}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
