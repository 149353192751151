import React, { FC, useState, FocusEvent } from 'react';
import classNames from 'classnames';
import { ReactComponent as Illustration } from './OnboardingIllustration1.svg';
import * as Yup from 'yup';
import './user-onboarding.scss';
import { ButtonPrimary, Form, InputText, Title, useSnackbar } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { observer } from 'mobx-react';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { handlePromise } from '../../util/async';
import { FormikHelpers, FormikProps } from 'formik';

export interface UserOnboardingCredentials {
    first_name: string;
    last_name: string;
    email: string;
    pass: string;
    confirm_pass: string;
}

export const UserOnboardingPage: FC<{}> = observer(() => {
    const { addSnackbar } = useSnackbar();
    const [unavailableEmails, setUnavailableEmails] = useState<string[]>([]);
    const [checkingEmail, setCheckingEmail] = useState(false);
    const { store } = useStore();
    const { router } = store;
    const {
        params: { token }
    } = router;

    const continueOn = () => router.goTo(DefaultRoutes.Home, router.params, store, { userOnboardingSuccess: 'true' });

    const checkEmailAvailability = async (
        email: string,
        formikProps: FormikProps<UserOnboardingCredentials> | FormikHelpers<UserOnboardingCredentials>
    ) => {
        // Make sure we don't call this more once at a time.
        if (checkingEmail) {
            return;
        }

        // Don't call the API if we already have the email in the list.
        if (!unavailableEmails.includes(email)) {
            setCheckingEmail(true);
            const [response, errors] = await handlePromise(
                store.Api.client.post('/company/check-email', { email, token })
            );

            setCheckingEmail(false);

            if (errors) {
                console.error(errors);
                throw new Error('Email check failed.');
            }

            // If user exists, update the unavailable emails list.
            if (response.data.user_exists) {
                setUnavailableEmails([...unavailableEmails, email]);
            }
        }

        // Trigger form validation so that the field error will show.
        formikProps.validateForm();
    };

    const handleSubmit = async (
        formValues: UserOnboardingCredentials,
        formikHelpers: FormikHelpers<UserOnboardingCredentials>
    ) => {
        await checkEmailAvailability(formValues.email, formikHelpers);

        const [response, errors] = await handlePromise(
            store.Api.client.post('/company/create-user-from-invite', { token, ...formValues })
        );

        if (!response || errors) {
            console.error(errors);
            return addSnackbar(
                `Failed to create user. Please try again. If you continue to experience this issue, please contact our support staff.`,
                {
                    variant: 'error'
                }
            );
        }

        return continueOn();
    };

    const handleEmailInputBlur = async (
        event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
        formikProps: FormikProps<UserOnboardingCredentials>
    ) => {
        try {
            const email = event.target.value;
            await checkEmailAvailability(email, formikProps);
        } catch (error) {
            console.error(error);
        }
    };

    const userOnboardingCredentialsFormSchema = Yup.object().shape({
        first_name: Yup.string().required('Please enter your first name.'),
        last_name: Yup.string().required('Please enter your last name.'),
        email: Yup.string()
            .email('Please enter a valid email address.')
            .test({
                name: 'email-is-available',
                test: value => !unavailableEmails.length || !unavailableEmails.includes(value),
                message: 'The email address is already in use. Please use a different email address.'
            })
            .required('Please enter your email.'),
        pass: Yup.string().required('Please enter a password.'),
        confirm_pass: Yup.string().oneOf([Yup.ref('pass'), null], 'Your passwords do not match.')
    });

    return (
        <div className={classNames('user-onboarding', 'user-onboarding-credentials')}>
            <header>
                <h1>You’re a few clicks away from converting more customers.</h1>
                <p>We just need a little bit more information to get started!</p>
                <Illustration className="user-onboarding-illustration" />
            </header>
            <main className="user-onboarding-content">
                <Title>Create your account</Title>
                <Form
                    enableReinitialize
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        email: '',
                        pass: '',
                        confirm_pass: ''
                    }}
                    validationSchema={userOnboardingCredentialsFormSchema}
                    onSubmit={handleSubmit}
                >
                    {(formikProps: FormikProps<UserOnboardingCredentials>) => (
                        <>
                            <div className="user-onboarding-form-row">
                                <InputText
                                    labelPlacement="above"
                                    label="First Name"
                                    name="first_name"
                                    formikProps={formikProps}
                                />
                                <InputText
                                    labelPlacement="above"
                                    label="Last Name"
                                    name="last_name"
                                    formikProps={formikProps}
                                />
                            </div>

                            <InputText
                                labelPlacement="above"
                                label="Email"
                                name="email"
                                formikProps={formikProps}
                                disabled={checkingEmail}
                                onBlur={event => handleEmailInputBlur(event, formikProps)}
                            />

                            <div className="user-onboarding-form-row">
                                <InputText
                                    labelPlacement="above"
                                    label="Password"
                                    type="password"
                                    name="pass"
                                    formikProps={formikProps}
                                />
                                <InputText
                                    labelPlacement="above"
                                    label="Repeat Password"
                                    type="password"
                                    name="confirm_pass"
                                    formikProps={formikProps}
                                />
                            </div>

                            <div className="user-onboarding-form-actions">
                                <ButtonPrimary type="submit" disabled={checkingEmail}>
                                    Continue
                                </ButtonPrimary>
                            </div>
                        </>
                    )}
                </Form>
            </main>
        </div>
    );
});
