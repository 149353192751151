import React, { FunctionComponent, Dispatch } from 'react';
import { Wysiwyg, AppSectionHeader, ButtonLink } from '../../../components-v2/shared';
import { EditEmailHeaderForm } from './EditEmailHeaderForm';
import { FormikProps } from 'formik';
import { EmailSection, EditAppEmailFormValues, EmailResponse } from '../EmailEditor.types';
import { EditAppEmailReducerAction, EditAppEmailReducerState } from '../EmailEditor.helpers';
import { EditEmailVideoSectionForm } from './EditEmailVideoSectionForm';
import { EditEmailCallToActionForm } from './EditEmailCallToActionForm';
import { EditEmailBannerForm } from './EditEmailBannerForm';
import { EditEmailLogoForm } from './EditEmailLogoForm';
import { EditEmailHeadlineForm } from './EditEmailHeadlineForm';
import { EditEmailOverlayForm } from './EditEmailOverlayForm';

export interface EditEmailSectionProps {
    onReset?: () => void;
    section: EmailSection;
    response: EmailResponse;
    formikProps: FormikProps<EditAppEmailFormValues>;
    useEditAppEmailReducer: () => [EditAppEmailReducerState, Dispatch<EditAppEmailReducerAction>];
}

export const EditEmailSectionForm: FunctionComponent<EditEmailSectionProps> = props => {
    const { response, section, onReset, formikProps } = props;

    return (
        <>
            <AppSectionHeader title="Details">
                {onReset ? <ButtonLink onClick={onReset}>Revert to default</ButtonLink> : null}
            </AppSectionHeader>

            {section.type === 'header' ? (
                <EditEmailHeaderForm {...props} />
            ) : section.type === 'realync-video' ? (
                <EditEmailVideoSectionForm {...props} />
            ) : section.type === 'overlay' ? (
                <EditEmailOverlayForm {...props} />
            ) : section.type === 'cta' ? (
                <EditEmailCallToActionForm {...props} />
            ) : section.type === 'banner' ? (
                <EditEmailBannerForm {...props} />
            ) : section.type === 'headline' ? (
                <EditEmailHeadlineForm {...props} />
            ) : section.type === 'logo' ? (
                <EditEmailLogoForm {...props} />
            ) : (
                <Wysiwyg
                    name={`${section.id}.htmlContent`}
                    templateVariables={response.templateVariables}
                    formikProps={formikProps}
                />
            )}
        </>
    );
};
