import React, { FC, ReactNode, HTMLAttributes } from 'react';
import classNames from 'classnames';

export interface DescriptionListItemProps extends HTMLAttributes<HTMLDivElement> {
    label: ReactNode;
    value?: ReactNode;
}

export const DescriptionListItem: FC<DescriptionListItemProps> = ({ className, label, value, children, ...props }) => (
    <div className={classNames('lc-description-list__item', className)} {...props}>
        <dt className="lc-description-list__item__label">{label}</dt>
        <dd className="lc-description-list__item__value">{value ? value : children}</dd>
    </div>
);
