import * as Yup from 'yup';
import { errorMessages } from './errorMessages';

export const fieldSchemas = {
    email: {
        standard: Yup.string()
            .email(errorMessages.email.valid)
            .required(errorMessages.email.required),
        nonRequired: Yup.string()
            .nullable()
            .email(errorMessages.email.valid)
    },
    password: {
        standard: Yup.string()
            // Note: passwords do not currently have a length requirement
            // .min(8, errorMessages.password.length)
            .required(errorMessages.password.required),
        confirmPassword: Yup.string()
            .required(errorMessages.password.confirmRequired)
            .oneOf([Yup.ref('password'), null], errorMessages.password.match)
    },
    phone: {
        standard: Yup.string().required(errorMessages.phone.required)
    },
};

export const formSchemas = {
    loginPage: {
        loginValidationSchema: Yup.object().shape({
            email: fieldSchemas.email.standard,
            password: fieldSchemas.password.standard
        })
    },
    resetPasswordPage: {
        resetPasswordValidationSchema: Yup.object().shape({
            email: fieldSchemas.email.standard
        })
    },
    changPasswordPage: {
        changePasswordValidationSchema: Yup.object().shape({
            password: fieldSchemas.password.standard,
            confirmPassword: fieldSchemas.password.confirmPassword
        })
    },
    dateRange: Yup.object().shape({
        start: Yup.date()
            .required(errorMessages.dateRange.required)
            .typeError('Please enter a valid date.'),
        end: Yup.date()
            .min(Yup.ref('start'), errorMessages.dateRange.endAfterBegin)
            .required(errorMessages.dateRange.required)
            .typeError('Please enter a valid date.')
    })
};
