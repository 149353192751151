import * as React from 'react';

export const NotFound = () => (
    <div className="error-template">
        <h1>Oops!</h1>
        <h2>404 Not Found</h2>
        <div className="error-details">Sorry, an error has occured, Requested page not found!</div>
    </div>
);

export default NotFound;
