import { DripSchedule } from './DripScheduleEditor.types';

export interface DripScheduleEditorListReducerState {
    modal: {
        active: DripScheduleEditorListModals;
        data: DripScheduleEditorModalData;
    };
    schedules?: DripSchedule[];
}

export interface DripScheduleEditorModalData {
    title?: string;
    selectedSchedule?: DripSchedule;
    formInitialValues?: any;
}

export type ServerRequestStatus = 'waiting' | 'sending' | 'sent' | 'error';

export type DripScheduleEditorListModals = 'none' | 'createDripSchedule' | 'copyDripSchedule' | 'deleteDripSchedule';

export interface DripScheduleEditorListReducerAction {
    name: keyof typeof dripScheduleEditorListReducers;
    payload?: any;
}

const dripScheduleEditorListReducers = {
    closeModal: (state: DripScheduleEditorListReducerState, _: any) => {
        state.modal.active = 'none' as DripScheduleEditorListModals;
        return { ...state };
    },
    openModal: (state: DripScheduleEditorListReducerState, activeModal: DripScheduleEditorListModals) => {
        state.modal.active = activeModal;
        return { ...state };
    },
    setModalData: (state: DripScheduleEditorListReducerState, modalData: DripScheduleEditorModalData) => {
        state.modal.data = { ...state.modal.data, ...modalData };
        return { ...state };
    },
    setSchedules: (state: DripScheduleEditorListReducerState, schedules: DripSchedule[]) => ({
        ...state,
        schedules
    })
};

export const dripScheduleEditorListReducer = (
    state: DripScheduleEditorListReducerState,
    action: DripScheduleEditorListReducerAction
) => {
    if (!dripScheduleEditorListReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: DripScheduleEditorListReducerState = dripScheduleEditorListReducers[action.name](
        state,
        action.payload
    );
    return nextState;
};

export interface DripScheduleEditorEditScheduleReducerState {
    isEditingName?: boolean;
    modal: {
        active: DripScheduleEditorListModals;
        data: DripScheduleEditorModalData;
    };
    schedule: DripSchedule;
}

export type DripScheduleEditorEditScheduleModals = 'none';

export interface DripScheduleEditorEditScheduleReducerAction {
    name: keyof typeof dripScheduleEditorEditScheduleReducers;
    payload?: any;
}

const dripScheduleEditorEditScheduleReducers = {
    closeModal: (state: DripScheduleEditorEditScheduleReducerState, _: any) => {
        state.modal.active = 'none' as DripScheduleEditorEditScheduleModals;
        return { ...state };
    },
    openModal: (
        state: DripScheduleEditorEditScheduleReducerState,
        activeModal: DripScheduleEditorEditScheduleModals
    ) => {
        state.modal.active = activeModal;
        return { ...state };
    },
    setModalData: (state: DripScheduleEditorEditScheduleReducerState, modalData: DripScheduleEditorModalData) => {
        state.modal.data = { ...state.modal.data, ...modalData };
        return { ...state };
    },
    setDripSchedule: (state: DripScheduleEditorEditScheduleReducerState, schedule: DripSchedule) => {
        return {
            ...state,
            schedule
        };
    },
    setIsEditingName: (state: DripScheduleEditorEditScheduleReducerState, isEditingName: boolean) => ({
        ...state,
        isEditingName
    })
};

export const dripScheduleEditorEditScheduleReducer = (
    state: DripScheduleEditorEditScheduleReducerState,
    action: DripScheduleEditorEditScheduleReducerAction
) => {
    if (!dripScheduleEditorEditScheduleReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: DripScheduleEditorEditScheduleReducerState = dripScheduleEditorEditScheduleReducers[action.name](
        state,
        action.payload
    );
    return nextState;
};
