export interface SmsMessage {
    id: number;
    company_id: number;
    company_name?: string;
    lead_id?: number;
    status: string;
    // status: SmsMessageStatus
    delivery_status?: TwilioMsgStatus;
    from_number: string;
    from_name?: string;
    to_number: string;
    to_name?: string;
    notification_type: string;
    message: string;
    message_error: string;
    message_sid?: string;
    is_outgoing: boolean;
    created_at: string;
    has_attachment: boolean;
    attachment_url?: string;
    thread_id: number;
    sent_at: string;
}

export enum SmsMessageStatus {
    New = 'New',
    NotSent = 'Not Sent',
    Sent = 'Sent'
}

export interface ChatMessage extends SmsMessage {
    hasError?: boolean;
    classes?: string[];
}

export enum TwilioMsgStatus {
    accepted = 'accepted',
    queued = 'queued',
    sending = 'sending',
    sent = 'sent',
    receiving = 'receiving',
    received = 'received',
    delivered = 'delivered',
    undelivered = 'undelivered',
    failed = 'failed',
    read = 'read'
}
