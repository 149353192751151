import { difference } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { move, reOrder } from '../../util/widgets';
import WidgetsOrderColumn from './WidgetsOrderColumn';

interface WidgetsOrderProps {
    items: string[];
    enabled: boolean;
    modules: any;
    moduleList: string[];
    onChange: (items: string[]) => void;
}

export const WidgetsOrder: React.FC<WidgetsOrderProps> = props => {
    const { items, enabled, modules, moduleList } = props;

    const [list, setWidgets] = useState(difference(moduleList, items));

    useEffect(() => {
        setWidgets(difference(moduleList, props.items));
    }, [props.items]);

    function handleOnDragEnd(result: DropResult) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const newItems =
            source.droppableId === destination.droppableId
                ? reOrder(props.items, result.source.index, destination.index)
                : move(list, props.items, source, destination);

        setWidgets(newItems);
        props.onChange(newItems);
    }

    return (
        <div className="draggable-widgets">
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <div className="holder-order__col">
                    <div className="draggable-widgets-titles">
                        <h4 className="order-col">Available Apps</h4>
                    </div>
                    <WidgetsOrderColumn enabled={enabled} idPrefix="all_" items={list} modules={modules} />
                </div>
                <div className="holder-order__col">
                    <div className="draggable-widgets-titles">
                        <h4 className="order-col">Selected Apps</h4>
                    </div>
                    <WidgetsOrderColumn enabled={enabled} idPrefix="enabled_" items={items} modules={modules} />
                </div>
            </DragDropContext>
        </div>
    );
};

export default WidgetsOrder;
