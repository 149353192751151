import React, { useReducer, Reducer } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../store/useStore';
import {
    AppHeader,
    AppPage,
    AppContent,
    AppFooter,
    ButtonPrimary,
    Button,
    Modal,
    ModalActions,
    ModalHeader,
    Header,
    Form,
    IconButton,
    useSnackbar
} from '../../components-v2/shared';
import classNames from 'classnames';
import styles from './email-editor.module.scss';
import { EmailEditorPreview } from './EmailEditorPreview';
import { EditEmailSectionForm } from './EmailEditorForms/EditEmailSectionForm';
import { EditEmailInboxDetailsForm } from './EmailEditorForms/EditEmailInboxDetailsForm';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { FormikHelpers, FormikProps } from 'formik';
import {
    EmailSection,
    EditAppEmailFormValues,
    EditAppEmailFormDeliveryValues,
    EmailResponse,
    LeadNurtureTemplate,
    EmailDeliveryValues
} from './EmailEditor.types';
import { buildEmailEditorValidationSchema } from './EmailEditorValidationSchema';
import { handlePromise, useAsyncEffect } from '../../util/async';
import { EmailEditorSendTestButton } from './EmailEditorComponents/EmailEditorSendTestButton';
import {
    formatEmailEditorValues,
    formatInitialValues,
    editAppEmailReducer,
    EditAppEmailReducerState,
    EditAppEmailReducerAction
} from './EmailEditor.helpers';
import { EmailEditorNameForm } from './EmailEditorComponents/EmailEditorNameForm';
import { FEATURES } from '../../types/Features';

export const EmailEditorEditAppEmailPage = observer(() => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { router, Api, features } = store;
    const { companyId, appEmailId } = router.params;

    const isSMSReady = () => {
        return features[FEATURES.sms_messages] && (features as any)[FEATURES.amount_twilio_numbers] > 0;
    };

    const [state, dispatch] = useReducer<Reducer<EditAppEmailReducerState, EditAppEmailReducerAction>>(
        editAppEmailReducer,
        {
            testEmailStatus: 'waiting',
            isEditingName: false,
            activeModal: 'none',
            revertStatus: 'waiting',
            uploadingFile: false
        }
    );

    const fetchTemplateEmail = async () => {
        const [response, error] = await handlePromise(
            Api.client.get(`mail-templates/company/${companyId}/template/${appEmailId}`)
        );

        if (error) {
            // TODO: handle error
            console.error(error);
            return;
        }

        dispatch({ name: 'setEmailResponse', payload: response.data });
    };

    useAsyncEffect(fetchTemplateEmail);

    const emailResponse = state.emailResponse;
    const initialValues = emailResponse ? formatInitialValues(emailResponse) : {};

    const handleSubmit = async (values: EditAppEmailFormValues, actions: FormikHelpers<EditAppEmailFormValues>) => {
        if (!initialValues || !emailResponse) {
            return;
        }

        if ((values.delivery as EmailDeliveryValues).smsAlternative === '') {
            values.delivery['smsAlternative'] = null;
        }

        const data = formatEmailEditorValues(values, initialValues, emailResponse.overrides);

        const [response, error] = await handlePromise(
            Api.client.put(`mail-templates/company/${companyId}/template/${appEmailId}`, data)
        );

        actions.setSubmitting(false);

        if (error) {
            const msg = error.response.data || 'Failed to update template. Unknown error.';
            dispatch({ name: 'cancelEditing' });
            addSnackbar(msg, {
                variant: 'error'
            });
            return;
        }

        dispatch({ name: 'cancelEditing' });
        dispatch({ name: 'setEmailResponse', payload: response.data });
    };

    const handleSectionClick = (section: EmailSection) => {
        window.scrollTo(0, 0);
        if (state.editing?.id !== section.id) {
            dispatch({ name: 'setEditing', payload: section });
        }
    };

    const handleCancel = (formikProps: FormikProps<EditAppEmailFormValues>) => {
        formikProps.resetForm();
        dispatch({ name: 'cancelEditing' });
    };

    const openRevertInboxDetailsModal = () => dispatch({ name: 'openModal', payload: 'revertInboxDetails' });

    const handleRevert = async (value: { [x: string]: null }) => {
        if (!initialValues || !emailResponse) {
            return;
        }

        dispatch({ name: 'updateRevertStatus', payload: 'sending' });

        const data = formatEmailEditorValues(value, initialValues, emailResponse.overrides);

        const [response, error] = await handlePromise<{ data: EmailResponse }>(
            Api.client.put(`mail-templates/company/${companyId}/template/${appEmailId}`, data)
        );

        if (!response || error) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setEmailResponse', payload: response.data });
        dispatch({ name: 'updateRevertStatus', payload: 'waiting' });
        dispatch({ name: 'closeModal' });

        if (state.editing) {
            dispatch({
                name: 'setEditing',
                payload: response.data.sections.find(section => section.id === state.editing?.id)
            });
        }
    };

    const openRevertEmailSectionModal = () => dispatch({ name: 'openModal', payload: 'revertEmailSection' });

    const handleCancelRevert = () => dispatch({ name: 'closeModal' });

    const canRevert = (obj?: object | null) => {
        return obj && Object.keys(obj).length > 0 && obj.constructor === Object;
    };

    const handleEditTemplateName: (
        values: { name: string },
        formikHelpers: FormikHelpers<{ name: string }>
    ) => void = async values => {
        if (!emailResponse || !emailResponse.leadNurtureTemplate?.id) {
            dispatch({ name: 'setIsEditingName', payload: false });
            return;
        }

        const [response, err] = await handlePromise<{ data: LeadNurtureTemplate }>(
            Api.client.patch(`company/${companyId}/lead-nurture-templates`, {
                ...emailResponse.leadNurtureTemplate,
                name: values.name
            })
        );

        if (err || !response) {
            // TODO: handle error
            addSnackbar(`Failed to update template name. Please make sure the name is unique.`, {
                variant: 'error'
            });
            return;
        }

        fetchTemplateEmail();
        dispatch({ name: 'setIsEditingName', payload: false });
    };

    const { isEditingName } = state;

    return (
        <AppPage loading={!state.emailResponse} className={classNames(state.editing ? styles['editing'] : '')}>
            {emailResponse && (
                <Form
                    confirmUnsavedChanges
                    unsavedChangesConfig={{
                        containerQuerySelectorAll: `[class*="email-editor_email-editor-edit-app-header"] ~ *, #email-editor-revert-section-modal, .image-cropper-modal, .snackbars`
                    }}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={buildEmailEditorValidationSchema(emailResponse)}
                    validateOnChange={true}
                >
                    {(formikProps: FormikProps<EditAppEmailFormValues>) => (
                        <>
                            <AppHeader
                                className={styles['email-editor-edit-app-header']}
                                title={!isEditingName ? emailResponse.name : ''}
                                onBack={() => router.goTo(DefaultRoutes.EmailTemplatesList, { companyId }, store)}
                            >
                                {isEditingName ? (
                                    <EmailEditorNameForm
                                        name={emailResponse.name}
                                        onSubmit={handleEditTemplateName}
                                        onCancel={() => dispatch({ name: 'setIsEditingName', payload: false })}
                                    />
                                ) : (
                                    <IconButton
                                        className={styles['schedule-editor-edit-name-button']}
                                        icon="pencil"
                                        hidden={!state.emailResponse?.leadNurtureTemplate}
                                        onClick={() => dispatch({ name: 'setIsEditingName', payload: true })}
                                    />
                                )}
                                <div className="flex-spacer" />
                                <EmailEditorSendTestButton
                                    dispatch={dispatch}
                                    testEmailStatus={state.testEmailStatus}
                                    disabled={formikProps.dirty}
                                />
                            </AppHeader>
                            <AppContent className={styles['email-editor-edit-app-content']}>
                                {state.editing && state.emailResponse ? (
                                    <div className={styles['email-editor-edit-app-form']}>
                                        <EditEmailSectionForm
                                            onReset={
                                                canRevert(state.editing.overrideValues)
                                                    ? openRevertEmailSectionModal
                                                    : undefined
                                            }
                                            section={state.editing}
                                            response={state.emailResponse}
                                            formikProps={formikProps}
                                            useEditAppEmailReducer={() => [state, dispatch]}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles['email-editor-edit-app-details']}>
                                        <EditEmailInboxDetailsForm
                                            onReset={
                                                canRevert(state.emailResponse?.overrides.delivery)
                                                    ? openRevertInboxDetailsModal
                                                    : undefined
                                            }
                                            smsReady={isSMSReady()}
                                            formikProps={formikProps as FormikProps<EditAppEmailFormDeliveryValues>}
                                            emailResponse={state.emailResponse!}
                                        />
                                    </div>
                                )}
                                <div className={styles['email-editor-edit-app-preview']}>
                                    <EmailEditorPreview
                                        editing={state.editing}
                                        email={emailResponse}
                                        onSectionClick={handleSectionClick}
                                        formikProps={formikProps as FormikProps<EditAppEmailFormValues>}
                                    />
                                </div>
                            </AppContent>
                            <AppFooter className={styles['email-editor-footer']} sticky={true}>
                                <div className="flex-spacer" />
                                {formikProps.dirty || (state.editing && !formikProps.isSubmitting) ? (
                                    <Button
                                        onClick={() => handleCancel(formikProps)}
                                        className={styles['email-editor-footer-cancel']}
                                        disabled={state.uploadingFile}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                                <ButtonPrimary
                                    type="submit"
                                    disabled={
                                        state.uploadingFile ||
                                        !formikProps.dirty ||
                                        !formikProps.isValid ||
                                        formikProps.isSubmitting
                                    }
                                >
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </AppFooter>
                        </>
                    )}
                </Form>
            )}

            <Modal
                id="email-editor-revert-section-modal"
                isOpen={state.activeModal === 'revertInboxDetails'}
                onAfterClose={() => dispatch({ name: 'closeModal' })}
            >
                <ModalHeader title="Are you sure?" />
                <p className="text">Click confirm to revert the inbox details to their default content.</p>
                <br />
                {state.emailResponse?.delivery.overrideValues?.subject ? (
                    <>
                        <Header>Subject</Header>
                        <p className="text">{state.emailResponse?.delivery.defaultValues.subject}</p>
                    </>
                ) : null}
                {state.emailResponse?.delivery.overrideValues?.previewText ? (
                    <>
                        <Header>Preview Text</Header>
                        <p className="text">{state.emailResponse?.delivery.defaultValues.previewText}</p>
                    </>
                ) : null}
                {state.emailResponse?.delivery.overrideValues?.replyToEmail ? (
                    <>
                        <Header>Reply To Email</Header>
                        <p className="text">{state.emailResponse?.delivery.defaultValues.replyToEmail}</p>
                    </>
                ) : null}
                <ModalActions>
                    <div className="flex-spacer" />
                    <Button onClick={handleCancelRevert}>Cancel</Button>
                    <ButtonPrimary onClick={() => handleRevert({ delivery: null })}>
                        {state.revertStatus === 'sending' ? 'Reverting...' : 'Confirm'}
                    </ButtonPrimary>
                </ModalActions>
            </Modal>

            {state.editing?.defaultHtml && (
                <Modal
                    isOpen={state.activeModal === 'revertEmailSection'}
                    onAfterClose={() => dispatch({ name: 'closeModal' })}
                >
                    <ModalHeader title="Are you sure?" />
                    <p className="text">Click confirm to revert the email section to its default content.</p>
                    <br />

                    <div
                        className={classNames(styles['email-editor-preview'], 'email-preview')}
                        dangerouslySetInnerHTML={{ __html: state.editing.defaultHtml }}
                    />
                    <ModalActions>
                        <div className="flex-spacer" />
                        <Button onClick={handleCancelRevert}>Cancel</Button>
                        <ButtonPrimary onClick={() => handleRevert({ [(state.editing as EmailSection).id]: null })}>
                            {state.revertStatus === 'sending' ? 'Reverting...' : 'Confirm'}
                        </ButtonPrimary>
                    </ModalActions>
                </Modal>
            )}
        </AppPage>
    );
});
