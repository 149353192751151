import { handlePromise } from '@lambdacurry/component-library';
import Store from '../../../store';

export type FileUploadApplication = 'company' | 'agency' | 'agency-avatar' | 'mail-template';

export const fetchUploadLocation = async ({
    contentType,
    store,
    application,
    data
}: {
    contentType: string;
    store: Store;
    application: FileUploadApplication;
    data: any;
}) =>
    await handlePromise(
        store.Api.client.post('/file-upload/upload-url/', {
            ...data,
            application,
            contentType
        })
    );

export const putFile = async ({
    file,
    uploadLocation: { url, headers },
    store
}: {
    file: File;
    uploadLocation: {
        url: string;
        headers: { [key: string]: string };
    };
    store: Store;
}) =>
    await handlePromise(
        store.Api.client({
            url,
            baseURL: '',
            method: 'put',
            data: file,
            headers: {
                ...headers,
                'Content-Type': file.type,
                'Access-Control-Allow-Origin': '*'
            }
        })
    );

export const uploadFileToS3 = async ({
    file,
    data,
    store,
    application
}: {
    file: File;
    data?: any;
    store: Store;
    application: FileUploadApplication;
}): Promise<[string?, any?]> => {
    const [uploadLocationResponse, uploadLocationError] = await fetchUploadLocation({
        store,
        contentType: file.type,
        application,
        data
    });

    if (uploadLocationError || !uploadLocationResponse.data) {
        return [, uploadLocationError];
    }

    const [, putFileError] = await putFile({ store, file, uploadLocation: uploadLocationResponse.data.upload });

    if (putFileError) {
        return [, putFileError];
    }

    return [uploadLocationResponse.data.url];
};
