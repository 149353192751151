import { DateTime } from 'luxon';

interface AppEventScheduleGenieState {
    originalDate: DateTime;
    date: DateTime;
    timeSlots: { label: string; value: string }[];
    confirmCancelModal: boolean;
}

const AppEventScheduleGenieReducers = {
    setDate: (state: AppEventScheduleGenieState, date: DateTime) => ({
        ...state,
        date
    }),
    updateTimeSlots: (state: AppEventScheduleGenieState, payload: string[]) => {
        let timeSlots: { value: string; label: string }[] = [];
        if (state.originalDate.equals(state.date)) {
            timeSlots = [...state.timeSlots, ...payload.map(timeSlot => ({ label: timeSlot, value: timeSlot }))];
        } else {
            timeSlots = payload.map(timeSlot => ({ label: timeSlot, value: timeSlot }));
        }

        return {
            ...state,
            timeSlots
        };
    },
    toggleConfirmCancelModal: (state: AppEventScheduleGenieState) => ({
        ...state,
        confirmCancelModal: !state.confirmCancelModal
    })
};

export type LeadDetailsAction =
    | {
          name: 'setDate';
          payload: DateTime;
      }
    | {
          name: 'updateTimeSlots';
          payload: string[];
      }
    | { name: 'toggleConfirmCancelModal' };

export const AppEventScheduleGenieReducer = (state: AppEventScheduleGenieState, action: LeadDetailsAction) => {
    if (!AppEventScheduleGenieReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: AppEventScheduleGenieState = AppEventScheduleGenieReducers[action.name](
        state,
        (action as any).payload
    );
    return nextState;
};

export const selectIsDatePast = (state: AppEventScheduleGenieState) => {
    const apptDateTimeInMillis = state.date.toMillis();
    const nowInMillis = DateTime.local().toMillis();
    const startOfDayInMillis = DateTime.local()
        .startOf('day')
        .toMillis();

    return apptDateTimeInMillis < startOfDayInMillis || apptDateTimeInMillis < nowInMillis;
};

export const selectDaysUntil = (state: AppEventScheduleGenieState) =>
    DateTime.local()
        .until(state.date)
        .count('days');

export const selectScheduleGenieMessage = (state: AppEventScheduleGenieState, isCanceled: boolean): string => {
    if (isCanceled) {
        return 'Appointment has been cancelled.';
    }

    if (selectIsDatePast(state)) {
        return 'Prior appointments cannot be rescheduled.';
    }

    const daysUntil = selectDaysUntil(state) - 1;

    if (daysUntil < 2) {
        return `Appointment is ${daysUntil === 1 ? 'tomorrow' : 'today'}.`;
    }

    return `Appointment is in ${daysUntil} days.`;
};
