import * as React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface ButtonSpinnerProps {
    bsStyle?: string;
    onClick?: () => void;
    isSaving: boolean;
    children: any;
}

const ButtonSpinner = (props: ButtonSpinnerProps) => {
    const bsStyle = props.bsStyle || 'primary';

    return (
        <Button bsStyle={bsStyle} disabled={props.isSaving} onClick={props.onClick} type="button">
            {props.isSaving && <FontAwesomeIcon icon={faSpinner} spin size="lg" />}
            {!props.isSaving && props.children}
        </Button>
    );
};

export default ButtonSpinner;
