import { Form, FormikProps } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import {
    ActionList,
    AppFooter,
    AppSection,
    AppSectionHeader,
    ButtonPrimary,
    InputText
} from '../../components-v2/shared';
import { OptOutQueue } from '../../types/OptOutQueue';
interface OptOutQueueProps {
    editing: boolean;
    controls: React.ReactNode;
}
export default (props: OptOutQueueProps) => {
    return (bag: FormikProps<OptOutQueue>) => {
        return (
            <>
                <Form>
                    <AppSection>
                        <div className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="Opt Out" />
                                <InputText name="email" label="Email Address" formikProps={bag} />
                            </div>
                        </div>
                    </AppSection>
                    <AppFooter sticky={true}>
                        <ActionList position="end">
                            {bag.dirty && (
                                <Button
                                    onClick={() => bag.resetForm()}
                                    disabled={bag.isSubmitting}
                                    data-lc-trigger-unsaved-changes={true}
                                    data-test-id="button_cancel"
                                >
                                    Cancel
                                </Button>
                            )}
                            <ButtonPrimary
                                type="submit"
                                onClick={() => props.controls}
                                disabled={!bag.dirty || bag.isSubmitting}
                            >
                                {bag.isSubmitting ? 'Saving...' : 'Save'}
                            </ButtonPrimary>
                        </ActionList>
                    </AppFooter>
                </Form>
            </>
        );
    };
};
