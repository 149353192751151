import React from 'react';
import Card from '@material-ui/core/Card';
import styles from './public.module.scss';
import { observer } from 'mobx-react';
import useStore from '../../store/useStore';
import { Formik, FormikProps, FormikHelpers, Form } from 'formik';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { ButtonPrimary, InputText } from '../../components-v2/shared';
import { InputCheckbox } from '@lambdacurry/component-library';
import { Link } from 'mobx-router';
import { mailFromAddress } from '../../constants';
import { formSchemas } from '../../util/validations/formSchemas';
import { AgencyLogo } from '../Agency/AgencyLogo';

export interface LoginFormValues {
    email: string;
    password: string;
    rememberEmail: boolean;
}

export const LoginPage = observer(() => {
    const { store } = useStore();

    const rememberEmailKey = 'rememberEmail';
    const email = localStorage.getItem(rememberEmailKey);

    const initialValues = {
        email: email || '',
        password: '',
        rememberEmail: email ? true : false
    };

    const authenticationErrorMessage = 'Invalid Credentials';

    const handleSubmit = (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
        actions.setSubmitting(true);
        store.clearErrors();

        if (values.rememberEmail) {
            localStorage.setItem(rememberEmailKey, values.email);
        } else if (localStorage.getItem(rememberEmailKey)) {
            localStorage.removeItem(rememberEmailKey);
        }

        store
            .login(values)
            .then(() => {
                actions.setSubmitting(false);
                let params = {};
                if (store.isCompanyRole) {
                    params = {
                        companyId: store.activeCompanyId
                    };
                }
                store.router.goTo(DefaultRoutes.Home, params, store);
            })
            .catch(() => {
                actions.setSubmitting(false);
            });
    };

    return (
        <main className={styles['public-page']}>
            <Card className={styles['public-card']}>
                <div className={styles['public-branding']}>
                    <AgencyLogo />
                </div>

                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={formSchemas.loginPage.loginValidationSchema}
                >
                    {(formikProps: FormikProps<LoginFormValues>) => {
                        return (
                            <Form className={styles['public-form']}>
                                <InputText name="email" type="email" label="Email" formikProps={formikProps} />
                                <InputText name="password" type="password" label="Password" formikProps={formikProps} />
                                <InputCheckbox
                                    name="rememberEmail"
                                    label="Remember me on this device"
                                    formikProps={formikProps}
                                />

                                {store.errors['401'] ? (
                                    <div className={styles['public-form-error']}>{authenticationErrorMessage}</div>
                                ) : null}

                                <ButtonPrimary type="submit" className="full-width" disabled={formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Signing In...' : 'Sign In'}
                                </ButtonPrimary>
                            </Form>
                        );
                    }}
                </Formik>
            </Card>

            <div className={styles['public-help']}>
                Forget your <a href={`mailto:${mailFromAddress}`}>email</a> or{' '}
                <Link view={DefaultRoutes.ResetPassword} store={store}>
                    password?
                </Link>
            </div>
        </main>
    );
});
