import * as React from 'react';
import { Col, Row, Table, OverlayTrigger, Popover } from 'react-bootstrap';

interface CsvImportListStore {
    list: any;
}

export class CsvImportList extends React.Component<CsvImportListStore> {
    public render() {
        return (
            <>
                <Col className="col-lg-12">
                    <Row>
                        <Table className="csv-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    {this.props.list[0].data.map((item: string, i: number) => {
                                        return <th key={i}>{item}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.list.map((row: any, i: number) => {
                                    if (!i) {
                                        return null;
                                    }

                                    let recordClass = '';
                                    if (row.result) {
                                        recordClass = 'sucess';
                                    } else {
                                        recordClass = 'error';
                                    }

                                    const OverlayTriggerDelay = 100;
                                    const OverlayTriggerDelayHide = 100;

                                    return (
                                        <tr className={`csv-row csv-row-${recordClass}`} key={`csv-row${i}`}>
                                            <td className="csv-col-error">
                                                {!row.result ? (
                                                    <OverlayTrigger
                                                        trigger="click"
                                                        delay={OverlayTriggerDelay}
                                                        delayHide={OverlayTriggerDelayHide}
                                                        placement="bottom"
                                                        overlay={this.renderPopover(row.error)}
                                                    >
                                                        <span className="csv-glyphicon">
                                                            <span className="glyphicon glyphicon-question-sign" />
                                                        </span>
                                                    </OverlayTrigger>
                                                ) : null}
                                                {i}
                                            </td>
                                            {row.data.map((v: string, j: number) => {
                                                return <td key={`csv-column${i}${j}`}>{v}</td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Row>
                </Col>
            </>
        );
    }

    protected renderPopover = (content: string) => {
        return <Popover id="popover-basic">{content}</Popover>;
    };
}

export default CsvImportList;
