import { Formik, FormikHelpers, FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import Store from '../../store';
import { emptyPublicRole } from '../../store/initial';
import { PublicRole } from '../../types';
import ControlButtonSave from '../misc/ControlButtonSave';
import CompanyRoleForm, { ICompanyRoleForm } from './CompanyRoleForm';
import { defaultWidgetColorTheme, defaultWidgetColorValues } from '../../constants';
import { getColorValues } from '../../util/colors';

export interface CompanyRoleNewProps {
    handleClose: (role: PublicRole) => any;
    opened: boolean;
    store?: Store;
}

@inject('store')
@observer
export class CompanyRoleNew extends React.Component<CompanyRoleNewProps> {
    public state = {
        activeColorFieldName: ''
    };

    public textAreaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

    public get injected() {
        return this.props.store!;
    }

    public initialValues = () => {
        const { getActiveCompanies, cssPaths } = this.injected;
        return {
            ...emptyPublicRole,
            company_id: getActiveCompanies()[0].id,
            css_id: cssPaths.getFirstItemId()
        };
    };

    public componentWillUnmount = () => {
        removeWidgetClasses();
    };

    public render() {
        if (this.props.opened) {
            addWidgetClasses();
        } else {
            removeWidgetClasses();
        }

        const colProps = {
            xs: 12
        };

        return (
            <Row>
                <Col {...colProps}>
                    <Formik
                        enableReinitialize
                        initialValues={this.initialValues()}
                        onSubmit={this.handleSubmit}
                    >
                        {(formikProps: FormikProps<ICompanyRoleForm>) => (
                            <CompanyRoleForm
                                formikProps={formikProps}
                                defaultSettings={defaultWidgetColorValues[defaultWidgetColorTheme]}
                                editing={true}
                                activeColorFieldName={this.state.activeColorFieldName}
                                handleChangeColorFocus={this.handleChangeColorFocus}
                                textAreaRef={this.textAreaRef}
                                copyToClipboard={this.copyToClipboard}
                                controls={(bag: FormikProps<ICompanyRoleForm>) => <ControlButtonSave />}
                            />
                        )}
                    </Formik>
                </Col>
            </Row>
        );
    }

    public copyToClipboard = () => {
        if (this.textAreaRef && this.textAreaRef.current) {
            this.textAreaRef.current.select();
            document.execCommand('copy');
        }
    };

    public handleSubmit = (values: PublicRole, actions: FormikHelpers<PublicRole>) => {
        const { publicRoles } = this.injected;
        return publicRoles
            .create({
                ...values,
                ...getColorValues(values),
            })
            .then(() => {
                actions.resetForm();
                this.props.handleClose(values);
            })
            .catch(error => actions.setErrors(error.response.data));
    };

    public handleChangeColorFocus = (field: string) => {
        this.setState({
            activeColorFieldName: field
        });
    };
}

export default CompanyRoleNew;

const addWidgetClasses = () => {
    const el = document.getElementById('main-container');
    if (el) {
        el.classList.add('widget-settings');
    }
};

const removeWidgetClasses = () => {
    const el = document.getElementById('main-container');
    if (el) {
        el.classList.remove('widget-settings');
    }
};
