import { Button, ButtonPrimary, Modal, ModalActions, ModalHeader, Tabs } from '@lambdacurry/component-library';
import { FormikProps } from 'formik';
import React, { Dispatch, FC } from 'react';

import { ActionList } from '../../../components-v2/shared';
import { ChartsTab } from './ChartsTab/ChartsTab';
import { DetailsTab } from './DetailsTab/DetailsTab';
import { FiltersTab } from './FiltersTab/FiltersTab';
import { ClaritySchedulerForm } from './ScheduleReportModal.helpers';
import './schedule-report-modal.scss';
import { ClarityReducerAction, ClarityReducerState } from '../Clarity.helpers';

export interface ScheduleReportModalProps {
    state: ClarityReducerState;
    dispatch: Dispatch<ClarityReducerAction>;
    schedulerFormikProps: FormikProps<ClaritySchedulerForm>;
}

export const ScheduleReportModal: FC<ScheduleReportModalProps> = props => {
    const { state, dispatch, schedulerFormikProps } = props;
    const { editSchedulerModal, createSchedulerModal } = state;
    const title = `${createSchedulerModal.open ? 'Create' : 'Edit'} Scheduled Report`;

    const handleCloseModal = () => {
        dispatch({ name: 'setEditSchedulerModalOpen', payload: false });
        dispatch({ name: 'setCreateSchedulerModalOpen', payload: false });
    }

    const handleCreateClick = () => {
        schedulerFormikProps.submitForm();
    };

    return (
        <Modal
            isOpen={editSchedulerModal.open || createSchedulerModal.open}
            closeButton={false}
            className="clarity-schedule-report-modal"
            onRequestClose={handleCloseModal}
        >
            <ModalHeader title={title} />

            <div className="clarity-schedule-report-modal-content">
                <Tabs
                    variant="fullWidth"
                    tabs={[
                        { label: 'Details', render: <DetailsTab schedulerFormikProps={schedulerFormikProps} createSchedulerModal={createSchedulerModal.open} /> },
                        { label: 'Filters', render: <FiltersTab {...props} /> },
                        { label: 'Charts', render: <ChartsTab {...props} /> }
                    ]}
                />
            </div>

            <ModalActions>
                <ActionList position="end">
                    <Button onClick={handleCloseModal}>Cancel</Button>
                <ButtonPrimary onClick={handleCreateClick}>{createSchedulerModal.open ? 'Create' : 'Save'}</ButtonPrimary>
                </ActionList>
            </ModalActions>
        </Modal>
    );
};
