import React, { FC, useState, Dispatch } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    AppSectionHeader,
    useSnackbar,
    ActionList,
    CheckboxList,
    CheckboxListItem,
    AppCardGrid,
    StatCard
} from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { FormikProps } from 'formik';
import {
    Button,
    Icon,
    handlePromise,
    Modal,
    ModalHeader,
    ModalActions,
    ButtonPrimary,
    InputCheckbox
} from '@lambdacurry/component-library';

import './clarity-dashboard.scss';
import { cloneDeep, differenceBy, sortBy } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import { SettingsDrawer } from './SettingsDrawer/SettingsDrawer';
import { ClarityReducerState, ClarityReducerAction } from './Clarity.helpers';
import { ClarityLoadingStatus } from './Clarity.types';
import { dashboardApps, ulmDashboardApps } from '../Dashboard/HomeDashboard.helpers';
import { AppIcons } from '../../constants/apps';
import { numberFormatter } from '../../util/formatters';
import { ClarityDashboardCharts } from './ClarityDashboardCharts';
import { ClarityChartConfig } from '../../constants';
import { FiltersSelector, useFilters } from '../Filters/Filters';
import { Filters } from '../Filters/Filters.helpers';

export const ClarityDashboardTab: FC<{
    state: ClarityReducerState;
    dispatch: Dispatch<ClarityReducerAction>;
    dateRangeFormikProps: FormikProps<{
        start?: Date | undefined;
        end?: Date | undefined;
    }>;
}> = ({ state, dispatch, dateRangeFormikProps }) => {
    const [editChartsModal, setEditChartsModal] = useState<boolean>(false);
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { filters } = useFilters();
    const { Api } = store;

    const saveLayout = async (newChartConfigs: ClarityChartConfig[]) => {
        const [response, error] = await handlePromise<{ data: number }>(
            Api.client.patch(`/clarity/preferences/${state.preferences?.id || 0}`, {
                config: { charts: newChartConfigs },
                filter: filters,
                settings: state.preferences?.settings
            })
        );
        if (!response?.data || error) {
            dispatch({ name: 'setLoadingStatus', payload: ClarityLoadingStatus.error });
            addSnackbar('Failed to fetch CLarity user preferences data.', { variant: 'error' });
            return console.error('error: ', error);
        }
        dispatch({ name: 'setPreferencesIdWithoutReRender', payload: response.data });
        dispatch({
            name: 'setPreferences',
            payload: { ...state.preferences, config: { charts: newChartConfigs } }
        });
        dispatch({ name: 'setSelectedCharts', payload: newChartConfigs });
        dispatch({ name: 'setInitialSelectedCharts', payload: newChartConfigs });
        addSnackbar('Saved chart layout.', { variant: 'success' });
    };

    const updateChartsInLayout = async () => {
        const preferences = cloneDeep(state.preferences);
        const selectedCharts = state.selectedCharts ?? [];
        if (!preferences) {
            return;
        }
        const newCharts = differenceBy(selectedCharts, preferences.config.charts, 'id');
        const removedCharts = differenceBy(preferences.config.charts, selectedCharts, 'id');
        const newChartsLayout = differenceBy([...preferences.config.charts, ...newCharts], removedCharts, 'id');

        // Give the new chart a fresh guid to disambiguate in case the same chart is added again with different settings
        const newChartsLayoutWithNewGuids: ClarityChartConfig[] = newChartsLayout.map(chart => ({
            ...chart,
            guid: uuidv4()
        }));

        saveLayout(newChartsLayoutWithNewGuids);
    };

    const handleCheckbox = (chart: ClarityChartConfig, checked: boolean) => {
        const selectedCharts = state.selectedCharts ?? [];

        if (checked) {
            return dispatch({ name: 'setSelectedCharts', payload: [...selectedCharts, chart] });
        }
        const remainingCharts = selectedCharts.filter(({ id }) => id !== chart.id);
        return dispatch({ name: 'setSelectedCharts', payload: remainingCharts });
    };

    const initialFilters = localStorage.staticClaritySettings
        ? (JSON.parse(localStorage.staticClaritySettings).filters as Filters)
        : state.preferences?.filters;

    // const isViewOnly = !!localStorage.staticClaritySettings;
    return (
        <>
            <AppSectionHeader title="Leads Per Application">
                <div className="filters">
                    {state.loadingStatus === ClarityLoadingStatus.loading && (
                        <div className="filters-loading">
                            <CircularProgress size="20px" />
                        </div>
                    )}
                    <FiltersSelector initialFilters={initialFilters} />
                    <Button
                        className="filters-button lc-display-none lg:lc-display-inline-flex"
                        icon={<Icon name="pieChart" />}
                        onClick={() => setEditChartsModal(true)}
                    >
                        Edit Charts
                    </Button>
                </div>
            </AppSectionHeader>

            <Modal isOpen={editChartsModal} closeButton={false} className="clarity-dashboard-modal">
                <ModalHeader title="Edit Charts" />

                <CheckboxList>
                    {sortBy(state.preferences?.availableCharts, chart => chart.title).map((chart, index) => {
                        const isChecked = state.selectedCharts
                            ? state.selectedCharts.findIndex(({ id }) => id === chart.id) > -1
                            : false;

                        return (
                            <CheckboxListItem key={index}>
                                <InputCheckbox
                                    label={chart.title}
                                    checked={isChecked}
                                    onClick={() => handleCheckbox(chart, !isChecked)}
                                />
                            </CheckboxListItem>
                        );
                    })}
                </CheckboxList>

                <ModalActions>
                    <ActionList position="end">
                        <Button
                            onClick={() => {
                                dispatch({ name: 'setSelectedCharts', payload: state.initialSelectedCharts });
                                setEditChartsModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <ButtonPrimary
                            onClick={() => {
                                updateChartsInLayout();
                                setEditChartsModal(false);
                            }}
                        >
                            Save
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>

            {state.data && (
                <>
                    <AppCardGrid className="clarity-dashboard-stat-grid">
                        {dashboardApps.map(({ type, label }) => {
                            const value: number | undefined = state.data?.totals.leadsByModule[type];

                            if (value === undefined) {
                                return null;
                            }

                            return (
                                <StatCard
                                    key={type}
                                    label={label}
                                    value={numberFormatter(value)}
                                    color="blue"
                                    icon={AppIcons[type]}
                                />
                            );
                        })}
                        {ulmDashboardApps.map(({ type, label }) => {
                            const value: number | undefined = state.data?.totals?.ulmEventTotals![type];

                            if (value === undefined || value === 0) {
                                return null;
                            }

                            return (
                                <StatCard
                                    key={type}
                                    label={label}
                                    value={numberFormatter(value)}
                                    color="blue"
                                    icon={AppIcons[type]}
                                />
                            );
                        })}
                    </AppCardGrid>
                </>
            )}

            {state.loadingStatus === ClarityLoadingStatus.complete && (
                <ClarityDashboardCharts state={state} onDragEnd={saveLayout} />
            )}

            <SettingsDrawer
                preferences={state.preferences}
                onSave={preferences => dispatch({ name: 'setPreferences', payload: preferences })}
            />
        </>
    );
};
