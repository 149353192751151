import { InputCheckbox, InputDate, InputSelect, InputText, InputTextarea } from '@lambdacurry/component-library';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { FormikProps } from 'formik';

import { AppSectionHeader } from '../../../../components-v2/shared';
import useStore from '../../../../store/useStore';
import {
    endDateOptions,
    reportIntervalOptions,
    reportTimeOptions,
    ClaritySchedulerForm
} from '../ScheduleReportModal.helpers';

export interface DetailsTabProps {
    schedulerFormikProps: FormikProps<ClaritySchedulerForm>;
    createSchedulerModal: boolean;
}

export const DetailsTab: FC<DetailsTabProps> = observer(({ schedulerFormikProps, createSchedulerModal }) => {
    const { isAdminRole } = useStore().store;

    return (
        <div className="clarity-schedule-report-modal-details-tab tab-content">
            <InputText label="Report Name" name="name" formikProps={schedulerFormikProps} />

            <div className="field-row">
                {createSchedulerModal && (
                    <InputDate label="Start Date" name="series_start_date" formikProps={schedulerFormikProps} />
                )}
                <InputSelect
                    label="End Date"
                    name="end_series_toggle"
                    options={endDateOptions}
                    optionValueKey="id"
                    optionLabelKey="name"
                    formikProps={schedulerFormikProps}
                />
            </div>

            {!!schedulerFormikProps?.values.end_series_toggle && (
                <div className="field-row">
                    <div></div>
                    <InputDate label="End Date" name="series_end" formikProps={schedulerFormikProps} />
                </div>
            )}

            <div className="field-row">
                <InputSelect
                    label="Time of Day"
                    name="series_start_time"
                    options={reportTimeOptions}
                    optionValueKey="id"
                    optionLabelKey="name"
                    formikProps={schedulerFormikProps}
                />
                <InputSelect
                    label="Interval"
                    name="interval"
                    options={reportIntervalOptions}
                    optionValueKey="id"
                    optionLabelKey="name"
                    formikProps={schedulerFormikProps}
                />
            </div>

            <AppSectionHeader
                title="Recipients"
                subtitle="Enter emails for who should receive these reports separated by a comma."
            />
            <InputTextarea
                label="Report Email Recipient"
                name="recipients"
                formikProps={schedulerFormikProps}
                rows={2}
            />

            {isAdminRole && (
                <>
                    <AppSectionHeader title="Additional data" />
                    <InputCheckbox
                        name="filters.include_test_companies"
                        label="Include test data"
                        labelPlacement="end"
                        formikProps={schedulerFormikProps}
                    />
                    <InputCheckbox
                        name="filters.only_active_companies"
                        label="Only active companies"
                        labelPlacement="end"
                        formikProps={schedulerFormikProps}
                    />
                </>
            )}
        </div>
    );
});
