import React from 'react';
import { AppCard, AppCardHeader, ListGroupItem } from '../../../../components-v2/shared';
import { DateTime } from 'luxon';
import { FutureDripEvent } from '../../../../types/Lead';

const TITLES = {
    SCHEDULE_GENIE: 'Schedule Genie',
    CONCESSION_MANAGER: 'Spotlight',
    CUSTOMER: 'Blast'
};

const ICONS = {
    SCHEDULE_GENIE: { name: 'schedule' },
    CONCESSION_MANAGER: { name: 'concessionManager', color: 'green' },
    CUSTOMER: { name: 'mailOutline', color: 'green' }
};

export const FutureDripEventCard: React.FC<{ futureDripEvent: FutureDripEvent }> = ({ futureDripEvent }) => {
    const title =
        futureDripEvent.type === 'CONCESSION_MANAGER'
            ? `${TITLES[futureDripEvent.type]}: ${futureDripEvent.data.spotlight_offer}`
            : TITLES[futureDripEvent.type];
    return (
        <AppCard>
            <AppCardHeader
                icon={ICONS[futureDripEvent.type]}
                title={title}
                description={
                    futureDripEvent.timestamp
                        ? `Notification Scheduled: ${DateTime.fromISO(futureDripEvent.timestamp as string).toLocaleString(
                            DateTime.DATETIME_SHORT
                        )}`
                        : 'Notification Queued'
                }
            ></AppCardHeader>
            <div className="drip-event-card-content">
                <>
                    <div className="field-row">
                        <ListGroupItem icon={{ name: 'schedule' }} label={futureDripEvent.type === 'CUSTOMER' ? 'Blast Schedule' : 'Drip Schedule'}>
                            {futureDripEvent.data.drip_schedule_name}
                        </ListGroupItem>
                        <ListGroupItem icon={{ name: 'details' }} label="Notification Template">
                            {futureDripEvent.data.email_template_name}
                        </ListGroupItem>
                        <ListGroupItem></ListGroupItem><ListGroupItem></ListGroupItem>
                    </div>
                </>
            </div>
        </AppCard>
    );
};
