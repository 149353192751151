import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { getItemStyle } from '../../util/widgets';

interface WidgetsOrderColumnProps {
    items: string[];
    idPrefix: string;
    enabled: boolean;
    modules: any;
}

export const WidgetsOrderColumn: React.FC<WidgetsOrderColumnProps> = props => {
    const { items, enabled, idPrefix, modules } = props;
    return (
        <Droppable droppableId={idPrefix + 'droppable'} isDropDisabled={!enabled} isCombineEnabled={!enabled}>
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={snapshot.isDraggingOver ? 'draggable-widget-col-drag-over' : 'draggable-widget-col'}
                >
                    {items.map((item, i) => {
                        if (modules[item]) {
                            return (
                                <Draggable key={idPrefix + item} draggableId={idPrefix + item} index={i}>
                                    {(subProvided, subSnapshot) => (
                                        // @ts-ignore:next-line
                                        <div
                                            ref={subProvided.innerRef}
                                            {...(subProvided.draggableProps as any)}
                                            {...(subProvided.dragHandleProps as any)}
                                            style={getItemStyle(
                                                subSnapshot.isDragging,
                                                subProvided.draggableProps.style
                                            )}
                                        >
                                            <span className={`clxicon ${modules[item].icon}`} />
                                            {modules[item].displayName === 'Chatbot'
                                                ? 'Virtual Assistant'
                                                : modules[item].displayName}
                                        </div>
                                    )}
                                </Draggable>
                            );
                        } else {
                            return null;
                        }
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default WidgetsOrderColumn;
