import { FormikHelpers } from 'formik';
import { omit } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { AppPage, useSnackbar } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { CompanyDetailsForm } from './CompanyDetailsForm';
import { CompanyDetailsFormValues } from './CompanyDetailsForm.helpers';

export const CompanyDetails: React.FC<{}> = observer(() => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { companies, updateAccessibleCompany, activeCompanyId } = store;

    const updateCompany = (
        values: CompanyDetailsFormValues,
        formikHelpers: FormikHelpers<CompanyDetailsFormValues>,
        isActive?: boolean
    ) => {
        const omittedValues = [
            'full_name',
            'rev_link_google_date',
            'rev_link_google_small',
            'rev_link_yelp_date',
            'rev_link_yelp_small',
            'logo_file',
            'active_coupon_id',
            'custom_disclaimer',
            'deleted_at',
            'estimate_multiplier',
            'widget_logo'
        ];

        if (typeof isActive === 'boolean') {
            values.active = !isActive;
        }

        const formattedCompanyObj = omit(values, omittedValues);

        return companies
            .update(formattedCompanyObj)
            .then(() => {
                const updatedCompany = companies.getItem(activeCompanyId);
                updateAccessibleCompany(updatedCompany);
                formikHelpers.resetForm({ values });
            })
            .catch(() => {
                addSnackbar('Failed to update company.', { variant: 'error' });
                formikHelpers.setSubmitting(false);
            });
    };

    return (
        <AppPage>
            <CompanyDetailsForm title="Company Settings" onSubmit={updateCompany} />
        </AppPage>
    );
});
