export interface ChatbotReducerState {
    loading: boolean;
}

export interface ChatbotReducerAction {
    name: keyof typeof chatbotReducers;
    payload?: any;
}

const chatbotReducers = {
    setLoading: (state: ChatbotReducerState, loading: ChatbotReducerState['loading']) => ({
        ...state,
        loading
    })
};

export const chatbotReducer = (state: ChatbotReducerState, action: ChatbotReducerAction) => {
    if (!chatbotReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: ChatbotReducerState = chatbotReducers[action.name](state, action.payload);
    return nextState;
};
