import React from 'react';
import { observer } from 'mobx-react';
import { FieldArray, FormikHelpers } from 'formik';
import {
    AppPage,
    AppHeader,
    AppContent,
    AppSectionHeader,
    ActionList,
    Button,
    ButtonLink,
    ButtonPrimary,
    Form,
    IconButton,
    InputColor,
    InputSwitch,
    InputTextarea,
    InputText,
    AppFooter,
    useSnackbar
} from '../../components-v2/shared';
import { InputDate, useAsyncEffect } from '@lambdacurry/component-library';
import useStore from '../../store/useStore';
import { Company, NotificationBarLink } from '../../types';
import { unsavedChangesContainerSelectorDefault } from '../../constants';
import { omit } from 'lodash';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { FEATURES } from '../../types/Features';

const companyOmittedValues = [
    'active_coupon_id',
    'custom_disclaimer',
    'deleted_at',
    'estimate_multiplier',
    'widget_logo',
    'rev_link_google_date',
    'rev_link_google_small',
    'rev_link_yelp_date',
    'rev_link_yelp_small',
    'full_name'
];

interface WebNotificationsFormValues {
    notification_bar_automated: boolean;
    notification_bar_message: string;
    notification_bar_color: string;
    notification_bar_background_color: string;
    notification_bar_start_date?: Date | null;
    notification_bar_end_date?: Date | null;
    notification_bar_links: NotificationBarLink[];
}

export const WebNotifications = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, fetchCompanies, companies, features } = store;
    const { companyId } = router.params;
    const company = companies.getItem(parseInt(companyId, 10));

    const fetchRequiredData = async () => {
        await fetchCompanies();
    };
    useAsyncEffect(fetchRequiredData);

    const concessionAutomationEnabled = features[FEATURES.concession_automation];

    const validationSchema = Yup.object().shape({
        notification_bar_message: Yup.string().nullable(),
        notification_bar_color: Yup.string().nullable(),
        notification_bar_background_color: Yup.string().nullable(),
        notification_bar_start_date: Yup.date().notRequired().nullable(),
        notification_bar_end_date: Yup.date()
            .notRequired()
            .when('notification_bar_start_date', (eventStartDate, schema) => {
                if (eventStartDate && eventStartDate instanceof Date && !isNaN(eventStartDate.getTime()))
                    return schema.min(eventStartDate, 'End date must be after start date');
                return schema;
            })
            .nullable(),
        notification_bar_links: Yup.array().of(
            Yup.object().shape({
                caption: Yup.string().nullable(),
                url: Yup.string().nullable()
            })
        )
    });

    const formatDataToSave = (values: WebNotificationsFormValues) => {
        const companyValues = omit(company, companyOmittedValues);
        const { notification_bar_end_date, notification_bar_start_date, ...formValues } = values;

        return {
            ...companyValues,
            ...formValues,

            // Since we are using the `companies` API, we are essentially doing a patch here.
            // The API expects the `logo` field to be a base64 encoded data URL or `null`, so we are setting it to `null` to
            // avoid the "Input buffer contains unsupported image format" API error.
            // This is basically what is happening when you save the main company settings without adding/updating the logo image.
            logo: null,
            notification_bar_active: company?.notification_bar_active,
            notification_bar_start_date: notification_bar_start_date
                ? DateTime.fromJSDate(notification_bar_start_date as unknown as Date)
                      .startOf('day')
                      .toISO()
                : null,
            notification_bar_end_date: notification_bar_end_date
                ? DateTime.fromJSDate(notification_bar_end_date as unknown as Date)
                      .endOf('day')
                      .toISO()
                : null
        };
    };

    const handleSubmit = async (
        values: WebNotificationsFormValues,
        actions: FormikHelpers<WebNotificationsFormValues>
    ) => {
        const data = formatDataToSave(values);

        try {
            await companies.update(data);
            addSnackbar('Web notifications have been updated.', { variant: 'success' });
            actions.resetForm({ values: formatCompanyForForm(data as Company) });
        } catch (error) {
            addSnackbar('Failed to update web notifications.', { variant: 'error' });
            actions.setErrors(error.response.data);
        }
    };

    const handleToggleClick = async () => {
        if (!company) {
            return;
        }

        const data = omit(company, companyOmittedValues);

        try {
            await companies.update({
                ...data,
                notification_bar_active: !data.notification_bar_active
            });
            addSnackbar(`Web notifications has been ${data.notification_bar_active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to ${data.notification_bar_active ? 'deactivate' : 'activate'} web notifications.`, {
                variant: 'error'
            });
        }
    };

    const formatCompanyForForm = (company: Company): WebNotificationsFormValues => ({
        notification_bar_message: company.notification_bar_message || '',
        notification_bar_automated: company.notification_bar_automated || false,
        notification_bar_links: company.notification_bar_links || [],
        notification_bar_color: company.notification_bar_color || '',
        notification_bar_background_color: company.notification_bar_background_color || '',
        notification_bar_start_date: !!company.notification_bar_start_date
            ? DateTime.fromISO(company.notification_bar_start_date).toJSDate()
            : null,
        notification_bar_end_date: !!company.notification_bar_end_date
            ? DateTime.fromISO(company.notification_bar_end_date).toJSDate()
            : null
    });

    const initialValues = (): Partial<Company> | any => (company ? formatCompanyForForm(company) : {});

    return (
        <AppPage loading={!company}>
            <AppHeader title="Web Notifications" icon={{ name: 'bellRing', color: 'blue' }}>
                <ActionList position="end">
                    <InputSwitch
                        labelOn="Active"
                        labelOff="Inactive"
                        labelPlacement="start"
                        onClick={handleToggleClick}
                        checked={company?.notification_bar_active}
                    />
                </ActionList>
            </AppHeader>
            {!!company && (
                <Form
                    validationSchema={validationSchema}
                    initialValues={initialValues()}
                    onSubmit={handleSubmit}
                    confirmUnsavedChanges
                    unsavedChangesConfig={{
                        containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
                    }}
                >
                    {formikProps => (
                        <>
                            <AppContent className="field-group-content">
                                <div className="field-group-col">
                                    <AppSectionHeader title="Notification Banner Message" />
                                    {!!concessionAutomationEnabled && (
                                        <InputSwitch
                                            label="Banner Message Updates via Concession Automation"
                                            labelPlacement="start"
                                            formikProps={formikProps}
                                            name={'notification_bar_automated'}
                                        />
                                    )}

                                    <InputTextarea
                                        rows={3}
                                        name="notification_bar_message"
                                        placeholder="Enter a message"
                                        formikProps={formikProps}
                                    />

                                    <AppSectionHeader title="Primary Colors" />
                                    <div className="field-group-content">
                                        <InputColor
                                            name="notification_bar_color"
                                            label="Text Color"
                                            labelPlacement="above"
                                            formikProps={formikProps}
                                        />
                                        <InputColor
                                            name="notification_bar_background_color"
                                            label="Background Color"
                                            labelPlacement="above"
                                            formikProps={formikProps}
                                        />
                                    </div>
                                    <AppSectionHeader title="Duration" />
                                    <div className="field-group-content">
                                        <InputDate
                                            name="notification_bar_start_date"
                                            label="Start Date"
                                            labelPlacement="above"
                                            formikProps={formikProps}
                                        />
                                        <InputDate
                                            name="notification_bar_end_date"
                                            label="End Date"
                                            labelPlacement="above"
                                            formikProps={formikProps}
                                        />
                                    </div>
                                </div>

                                <div className="field-group-col">
                                    <FieldArray name="notification_bar_links">
                                        {arrayHelpers => (
                                            <>
                                                <AppSectionHeader title="Calls to Action">
                                                    <ButtonLink
                                                        onClick={() => arrayHelpers.push({ caption: '', url: '' })}
                                                    >
                                                        + Add call to action
                                                    </ButtonLink>
                                                </AppSectionHeader>

                                                {formikProps.values.notification_bar_links.map(
                                                    (notificationBarLink: NotificationBarLink, index: number) => {
                                                        const fieldKey = `notification_bar_links.${index}`;

                                                        return (
                                                            <div key={fieldKey}>
                                                                <AppSectionHeader
                                                                    as="Header"
                                                                    title={`Call to Action ${index + 1}`}
                                                                >
                                                                    {index > 0 && (
                                                                        <IconButton
                                                                            icon="trash"
                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                        />
                                                                    )}
                                                                </AppSectionHeader>
                                                                <InputText
                                                                    name={`${fieldKey}.caption`}
                                                                    label="Call to action button text"
                                                                    placeholder="Click here"
                                                                    formikProps={formikProps}
                                                                />
                                                                <InputText
                                                                    name={`${fieldKey}.url`}
                                                                    label="Call to action URL"
                                                                    placeholder="Website address"
                                                                    formikProps={formikProps}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                            </AppContent>

                            <AppFooter>
                                <ActionList position="end">
                                    {formikProps.dirty && (
                                        <Button
                                            onClick={() => formikProps.resetForm()}
                                            disabled={formikProps.isSubmitting}
                                            data-lc-trigger-unsaved-changes={true}
                                            data-test-id="button_cancel"
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                    <ButtonPrimary
                                        type="submit"
                                        disabled={!formikProps.dirty || formikProps.isSubmitting}
                                        data-test-id="button_save"
                                    >
                                        Save
                                    </ButtonPrimary>
                                </ActionList>
                            </AppFooter>
                        </>
                    )}
                </Form>
            )}
        </AppPage>
    );
});
