import React, { useState } from 'react';
import { AppListEmpty, Avatar, Icon, InputText } from '../../components-v2/shared';
import { SmsThread } from '../../types/SmsThread';
import { DateTime } from 'luxon';
import { orderBy } from 'lodash';
import { getCustomerInitials, getCustomerName, MessagesAction, MessagesState, selectThread } from './Messages.helpers';
import classNames from 'classnames';
import { phoneFormatter } from '../../util/formatters';

interface MessagThreadListProps {
    threads: SmsThread[];
    state: MessagesState;
    dispatch: React.Dispatch<MessagesAction>;
}

const MessageThreadsList: React.FC<MessagThreadListProps> = ({ dispatch, state, threads }) => {
    const sortedThreads = orderBy(threads, ['last_msg_at'], ['desc']);
    const [searchValue, setSearchValue] = useState<string>('');

    const filteredThreads = searchValue
        ? sortedThreads.filter(({ customer }) => {
              if (!customer) {
                  return false;
              }

              return getCustomerName(customer)
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
          })
        : sortedThreads;

    return (
        <div className="threads">
            <div className="threads-search">
                <InputText
                    name="searchValue"
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    prefix={<Icon name="search" />}
                    placeholder="Search lead name..."
                />
            </div>

            {filteredThreads.length < 1 && <AppListEmpty title="No messages" className="messages-empty" />}

            {filteredThreads.length > 0 && (
                <div className="threads-list">
                    {filteredThreads.map(thread => {
                        const {
                            client_number,
                            customer,
                            id,
                            last_msg_at,
                            last_message,
                            marked_read,
                            unread_count
                        } = thread;

                        return (
                            <button
                                key={id}
                                className={classNames('threads-list-item', {
                                    selected: state.selectedThread?.id === id,
                                    read: marked_read,
                                    unread: !marked_read
                                })}
                                onClick={() => selectThread(dispatch, thread)}
                            >
                                <Avatar className="threads-list-item-avatar">
                                    {customer ? getCustomerInitials(customer) : <Icon name="user" />}
                                </Avatar>

                                <div className="threads-list-item-top-row">
                                    <div className="threads-list-item-customer-name">
                                        {customer ? getCustomerName(customer) : phoneFormatter(client_number)}
                                    </div>
                                    <div className="threads-list-item-date">
                                        {DateTime.fromISO(last_msg_at || '').toRelative({ style: 'short' })}
                                    </div>
                                </div>
                                <div className="threads-list-item-bottom-row">
                                    <div className="threads-list-item-message">{last_message?.message}</div>
                                    {unread_count > 0 && (
                                        <div className="threads-list-item-unread-count">{unread_count}</div>
                                    )}
                                </div>
                            </button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default MessageThreadsList;
