import { FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ICompanyRoleForm } from '../CompanyRoleForm';
import { InjectedProps } from '../../../types';

export interface CompanyRoleWidgetProps {
    settings: FormikProps<ICompanyRoleForm>;
    defaultSettings: any;
}

export interface CompanyRoleWidgetState {
    timeHover: number;
}

@inject('store')
@observer
export class CompanyRoleWidgetAvailableTime extends React.Component<
    CompanyRoleWidgetProps & Partial<InjectedProps>,
    CompanyRoleWidgetState
> {
    public get injected() {
        return this.props as InjectedProps;
    }
    constructor(props: CompanyRoleWidgetProps) {
        super(props);
        this.state = {
            timeHover: 0
        };
    }

    public toggleTimeHover = (time: number) => () => {
        this.setState({ timeHover: time });
    };
    public toggleTimeLeave = () => {
        this.setState({ timeHover: 0 });
    };

    public render() {
        const { calendars } = this.injected.store;
        const calendar = calendars.values[0];
        const classWidgetPosition = this.props.settings.values.widget_position
            ? `clx-${this.props.settings.values.widget_position}`
            : ``;

        const styleWidgetTime = {
            borderColor:
                this.props.settings.values.color_border_show_hide_button ||
                this.props.defaultSettings.color_border_show_hide_button,
            backgroundColor:
                this.props.settings.values.color_background_default ||
                this.props.defaultSettings.color_background_default
        };
        const getStyleTimeItem = (n: number) => {
            return {
                color:
                    n === this.state.timeHover
                        ? this.props.settings.values.color_default || this.props.defaultSettings.color_default
                        : this.props.settings.values.color_available_times_item ||
                          this.props.defaultSettings.color_available_times_item,
                backgroundColor:
                    n === this.state.timeHover
                        ? this.props.settings.values.color_background_available_times_item_hover ||
                          this.props.defaultSettings.color_background_available_times_item_hover
                        : this.props.settings.values.color_background_available_times_item ||
                          this.props.defaultSettings.color_background_available_times_item
            };
        };
        const submit = {
            backgroundColor:
                this.props.settings.values.color_background_submit_button ||
                this.props.defaultSettings.color_background_submit_button,
            color: this.props.settings.values.color_button || this.props.defaultSettings.color_button
        };
        const defaultColor = {
            color: this.props.settings.values.color_default || this.props.defaultSettings.color_default
        };

        return (
            <div className="available-times">
                <div className={`clx-app-wrap clx-calendar-container ${classWidgetPosition}`} style={styleWidgetTime}>
                    <div>
                        <div className="clx-header-wrap">
                            <i style={defaultColor} className="clxicon clxicon-close" id="clx-close-icon" />
                            <p style={defaultColor} id="clx-schedule-headline">
                                {calendar?.call_to_action}
                            </p>
                            <i style={defaultColor} className="clxicon clxicon-calendar" id="clx-calendar-icon" />
                        </div>
                        <div id="clx-calendar-available-times-container">
                            <div className="clx-calendar-available-times" style={defaultColor}>
                                <span id="clx-calendar-available-times-instruction">Choose a time in EDT for:</span>
                                <h2 id="clx-calendar-available-times-day">March 26, 2019</h2>
                                <div id="clx-available-times-scrollbox">
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(1)}
                                        onMouseEnter={this.toggleTimeHover(1)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>10:00am</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(2)}
                                        onMouseEnter={this.toggleTimeHover(2)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>10:15am</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(3)}
                                        onMouseEnter={this.toggleTimeHover(3)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>10:30am</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(4)}
                                        onMouseEnter={this.toggleTimeHover(4)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>10:45pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(5)}
                                        onMouseEnter={this.toggleTimeHover(5)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>11:00pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(6)}
                                        onMouseEnter={this.toggleTimeHover(6)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>11:15pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(7)}
                                        onMouseEnter={this.toggleTimeHover(7)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>11:30pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(8)}
                                        onMouseEnter={this.toggleTimeHover(8)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>12:45pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(9)}
                                        onMouseEnter={this.toggleTimeHover(9)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>01:00pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(10)}
                                        onMouseEnter={this.toggleTimeHover(10)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>01:15pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(11)}
                                        onMouseEnter={this.toggleTimeHover(11)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>01:30pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(12)}
                                        onMouseEnter={this.toggleTimeHover(12)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>01:45pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(13)}
                                        onMouseEnter={this.toggleTimeHover(13)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>02:00pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(14)}
                                        onMouseEnter={this.toggleTimeHover(14)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>02:15pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(15)}
                                        onMouseEnter={this.toggleTimeHover(15)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>02:30pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(16)}
                                        onMouseEnter={this.toggleTimeHover(16)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>02:45pm</span>
                                    </div>
                                    <div
                                        className="clx-available-time-item"
                                        style={getStyleTimeItem(17)}
                                        onMouseEnter={this.toggleTimeHover(17)}
                                        onMouseLeave={this.toggleTimeLeave}
                                    >
                                        <span>03:00pm</span>
                                    </div>
                                </div>
                            </div>
                            <button style={submit} id="clx-available-time-choose-new-day">
                                <i className="clxicon clxicon-previous" />
                                <span>Choose a new day</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyRoleWidgetAvailableTime;
