import React from 'react';
import { observer } from 'mobx-react';
import {
    IconButton,
    AppHeader,
    AppPage,
    AppContent,
} from '../../components-v2/shared';
import { iconNames } from '../../components-v2/types';

export const IconsDemoPage = observer(() => {

    return (
        <>
            <AppPage>
                <AppHeader title="Icons Demo Page" />
                <AppContent>
                    <div>
                        {iconNames.map((iconName, i) => (
                            <div key={i} style={{ display: 'inline-block', width: '100px', textAlign: 'center', paddingBottom: 20 }}>
                                <IconButton icon={iconName} title={iconName} />
                                <div>{iconName}</div>
                            </div>
                        ))}
                    </div>
                </AppContent>
            </AppPage>
        </>
    );
});
