import { InputSwitch, IconButton, handlePromise } from '@lambdacurry/component-library';
import { pick } from 'lodash';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import React, { Dispatch, FC, Fragment } from 'react';

import {
    AppList,
    AppListItem,
    AppListItemActions,
    AppListItemContent,
    AppListItemDetails
} from '../../components-v2/shared';
import { ClarityChartConfig } from '../../constants';
import useStore from '../../store/useStore';
import { ClarityReducerAction, ClarityReducerState } from './Clarity.helpers';
import {
    ClaritySchedulerForm,
    getScheduledReportDescription,
    unixToLocal,
    unixToWeekday
} from './ScheduleReportModal/ScheduleReportModal.helpers';
import { ClaritySchedulerProps } from './ScheduledReports';
import { ClarityScheduler } from './Clarity.types';

export interface ScheduledReportsList extends Required<Partial<ClaritySchedulerProps>> {
    state: ClarityReducerState;
    dispatch: Dispatch<ClarityReducerAction>;
    setSelectedId: React.Dispatch<React.SetStateAction<number | null>>;
    setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ScheduledReportsList: FC<ScheduledReportsList> = observer(
    ({
        state,
        dispatch,
        createReport,
        fetchClarityScheduledReports,
        schedulerFormikProps,
        setSelectedId,
        setDeleteModalOpen
    }) => {
        const { Api, isCompanyLightRole } = useStore().store;
        const { scheduledReportsTab, preferences } = state;
        const availableCharts = preferences?.availableCharts || [];

        const handleRunNow = async (scheduler: ClarityScheduler) => {
            await createReport({
                name: scheduler.name,
                filter: scheduler.filters,
                config: scheduler.config,
                recipients: scheduler.recipients
            });
        };

        const handleEdit = async (scheduler: ClarityScheduler) => {
            const startDateTime = DateTime.fromSeconds(scheduler.series_start, { zone: DateTime.local().zoneName });
            const diff = startDateTime.diff(startDateTime.startOf('day'), ['years', 'months', 'days', 'hours']);

            const { config, series_end } = scheduler;
            const selectedChartIds = config.charts.map((selectedChart: ClarityChartConfig) => selectedChart.id);

            const formData: ClaritySchedulerForm = {
                ...pick(scheduler, ['id', 'interval', 'name', 'recipients', 'filters']),
                series_start_date: startDateTime.startOf('day').toJSDate(),
                series_start_time: diff.hours,
                end_series_toggle: !!series_end,
                series_end: series_end
                    ? DateTime.fromSeconds(series_end, { zone: DateTime.local().zoneName }).toJSDate()
                    : undefined,
                available_charts: []
            };

            // Pulled this property's logic out of the object above to make it easier to read
            formData.available_charts = availableCharts.map((availableChart: ClarityChartConfig) => ({
                chart: availableChart,
                enabled: selectedChartIds.includes(availableChart.id)
            }));

            schedulerFormikProps.setValues(formData);
            dispatch({ name: 'setEditSchedulerModalOpen', payload: true });
        };

        const handleActiveToggle = async (schedulerId: number) => {
            const [response, error] = await handlePromise(
                Api.client.patch(`/clarity/scheduler/${schedulerId}/active_toggle`)
            );

            if (!response || error) {
                console.error(error);
                return;
            }

            await fetchClarityScheduledReports();
        };

        const sortedScheduleItems = scheduledReportsTab.items?.data;

        if (!sortedScheduleItems) return null;

        return (
            <AppList>
                {sortedScheduleItems.map((scheduleItem: ClarityScheduler) => {
                    const { active, id, interval, name, next_run, series_end, series_start } = scheduleItem;

                    return (
                        <Fragment key={id}>
                            <AppListItem className="clarity-report-list-item">
                                <AppListItemContent
                                    title={name}
                                    icon={{
                                        name: 'schedule',
                                        color: 'secondary_color'
                                    }}
                                    description={getScheduledReportDescription(interval, series_start, series_end)}
                                />

                                <AppListItemDetails>
                                    <div className="app-list-item-description">Next scheduled report</div>
                                    <div>
                                        {next_run > 0
                                            ? `${unixToWeekday(next_run)}, ${unixToLocal(next_run)}`
                                            : 'Not scheduled'}
                                    </div>
                                </AppListItemDetails>

                                <AppListItemActions>
                                    <IconButton
                                        icon="play"
                                        title="Run Now"
                                        onClick={() => handleRunNow(scheduleItem)}
                                    />
                                    <IconButton icon="pencil" title="Edit" onClick={() => handleEdit(scheduleItem)} />
                                    {!isCompanyLightRole && (
                                        <IconButton
                                            icon="trash"
                                            title="Delete"
                                            onClick={() => {
                                                setSelectedId(id);
                                                setDeleteModalOpen(true);
                                            }}
                                        />
                                    )}
                                    <InputSwitch onClick={() => handleActiveToggle(id)} checked={!!active} />
                                </AppListItemActions>
                            </AppListItem>
                        </Fragment>
                    );
                })}
            </AppList>
        );
    }
);
