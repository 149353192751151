export type ConcessionManagerModals = 'none' | 'deleteOffer' | 'copyOffer' | 'selectTemplate';
export type ConcessionManagerReducerNames = 'closeModal' | 'openModal';

export interface ConcessionManagerReducerState {
    modal: {
        active: ConcessionManagerModals;
        data: any;
    };
}

export interface ConcessionManagerReducerAction {
    name: keyof typeof concessionManagerReducers;
    payload?: any;
}

export const concessionManagerReducers = {
    closeModal: (state: ConcessionManagerReducerState) => ({
        ...state,
        modal: { ...state.modal, active: 'none' }
    }),
    openModal: (state: ConcessionManagerReducerState, modal: ConcessionManagerReducerState['modal']) => ({
        ...state,
        modal
    }),
    setModalData: (state: ConcessionManagerReducerState, data: ConcessionManagerReducerState['modal']['data']) => ({
        ...state,
        modal: {
            ...state.modal,
            data
        }
    })
};

export const concessionManagerReducer = (
    state: ConcessionManagerReducerState,
    { name, payload }: ConcessionManagerReducerAction
) => {
    if (!concessionManagerReducers[name]) {
        throw new Error(`reducer ${name} not defined`);
    }

    return concessionManagerReducers[name](state, payload);
};

export const concessionManagerInitialState: ConcessionManagerReducerState = {
    modal: {
        active: 'none',
        data: null
    }
};

export const openModal = (
    dispatch: React.Dispatch<ConcessionManagerReducerAction>,
    name: ConcessionManagerModals,
    data?: any
) => dispatch({ name: 'openModal', payload: { active: name, data } });

export const closeModal = (dispatch: React.Dispatch<ConcessionManagerReducerAction>) =>
    dispatch({ name: 'closeModal' });

export const setModalData = (dispatch: React.Dispatch<ConcessionManagerReducerAction>, data: any) =>
    dispatch({ name: 'setModalData', payload: data });
