import * as Yup from 'yup';
import { DateTime } from 'luxon';

export const CreateScheduleFormValidationSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required(`Please enter a name for the schedule.`)
    });
};

export const ScheduleEditorTimeOffValidationSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required(`Please enter a name.`),
        date: Yup.date()
            .typeError(`Please enter a valid date.`)
            .min(
                DateTime.local()
                    .startOf('day')
                    .toJSDate(),
                `Date cannot be in the past.`
            )
            .required(`Please enter a valid date.`)
    });
};

export const ScheduleEditorRemoteCalendarValidationSchema = () => {
    return Yup.object();
}