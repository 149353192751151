import { observable, action, computed, runInAction } from 'mobx';
import { AccessibleAgency, Agency, AgencyCustomization, ThemeProperty, ThemeProperties, User, Company } from '../types';
import Store from '.';
import { handlePromise } from '../util/async';
import { lightenDarkenColor, getCssVar, hexToRGB, isHexColor, rgbToHex } from '../util/colors';
import { merge } from 'lodash';

interface CompanyOnboardingFormData {
    response?: { user: User; companies: Company[]; agencies: Agency[] }; // TODO: Get type from actual response
    formValues: { user?: Partial<User>; company?: Partial<Company> };
    errors: { user?: { [x: string]: string }; company?: { [x: string]: string } };
}
export class AgencyStore {
    private store: Store;
    @observable public accessibleAgencies: AccessibleAgency[];
    @observable public accessibleAgenciesIndex: Record<number, number>;
    @observable public agencyCustomization: AgencyCustomization;
    @observable public companyOnboardingFormData: CompanyOnboardingFormData = {
        formValues: {},
        errors: {}
    };

    public themeProperties: ThemeProperty[] = [
        'primary_color',
        'secondary_color',
        'primary_button_text_color',
        'secondary_button_text_color'
    ];

    constructor(store: Store) {
        this.store = store;
        // tslint:disable-next-line: no-floating-promises
        this.fetchAgencyCustomizationByHostname();
    }

    get defaultThemeProperties(): ThemeProperties {
        const computedStyle = getComputedStyle(document.documentElement);
        return this.themeProperties.reduce((acc, property) => {
            acc[property] = getCssVar(`clx_default_${property}`, computedStyle);
            return acc;
        }, {});
    }

    @action.bound
    public setAccessibleAgencies(agencies: AccessibleAgency[]) {
        this.accessibleAgencies = [...agencies];
        this.accessibleAgenciesIndex = this.accessibleAgencies.reduce(
            (acc: number[], agency: AccessibleAgency, index) => {
                acc[agency.id] = index;
                return acc;
            },
            []
        );
    }

    @action.bound
    public getAccessibleAgencyById(agencyId: number | string) {
        if (typeof agencyId === 'string') {
            agencyId = parseInt(agencyId, 10);
        }
        return computed(() => this.accessibleAgencies[this.accessibleAgenciesIndex[agencyId]]);
    }

    @computed
    get activeAgency() {
        return this.accessibleAgencies[this.accessibleAgenciesIndex[this.activeAgencyId || 0]];
    }

    @computed
    get activeAgencyId(): number | undefined {
        const params = this.store.router.params;
        return params && params.agencyId ? parseInt(params.agencyId, 10) : undefined;
    }

    @action.bound
    public async fetchAgencyById(id: number): Promise<void | Agency> {
        const [response, err] = await handlePromise(this.store.api.client.get(`agencies/${id}`));
        if (err || !response) {
            return this.store.logout();
        }
        this.setAgencyDataById(id, response.data);
        runInAction(() => {
            this.accessibleAgencies = [response.data];
        });
        return response.data;
    }

    @action.bound
    public setAgencyDataById(id: number, data: Agency) {
        const agencyData = this.accessibleAgencies[this.accessibleAgenciesIndex[id]];
        this.accessibleAgencies[this.accessibleAgenciesIndex[id]] = { ...agencyData, ...data };
    }

    @action.bound
    public async fetchAgencyCustomization(agencyId?: number): Promise<void | AgencyCustomization> {
        if (agencyId) {
            const settings = await this.fetchAgencyCustomizationById(agencyId);
            if (settings) {
                return settings;
            }
        }

        return this.fetchAgencyCustomizationByHostname();
    }

    @action.bound
    public async fetchAgencyCustomizationByHostname(): Promise<void | AgencyCustomization> {
        this.store.pushRequest();
        const [response, err] = await handlePromise(
            this.store.api.client.get(`whitelabel-settings`, { params: { hostname: window.location.hostname } })
        );

        let theme;
        if (err) {
            theme = { ...this.defaultThemeProperties };
        } else {
            theme = { ...this.defaultThemeProperties, ...response.data };
        }

        this.setAgencyCustomization(theme);
        this.store.pullRequest();
        return theme;
    }

    @action.bound
    public async fetchAgencyCustomizationById(agencyId: number): Promise<void | AgencyCustomization> {
        this.store.pushRequest();
        const [response, err] = await handlePromise(
            this.store.api.client.get(`whitelabel-settings/agencies/${agencyId}`)
        );

        if (err) {
            return;
        }

        const theme = { ...this.defaultThemeProperties, ...response.data };

        this.setAgencyCustomization(theme);
        this.store.pullRequest();
        return theme;
    }

    @action.bound
    public async updateAgencyCustomizationById(
        data: Partial<AgencyCustomization>
    ): Promise<void | AgencyCustomization> {
        const [response, err] = await handlePromise(this.store.api.client.patch(`whitelabel-settings`, data));
        if (err) {
            throw new Error(err);
        }

        return response.data;
    }

    @action.bound
    public setAgencyCustomization(data: AgencyCustomization) {
        // Note: We need to set the styles on both the `root` and the `body`:
        // - the `body` for specificity
        // - the `root` for use with components that getComputedStyles based on the documentElement
        const root = document.documentElement;
        const body = document.body;

        this.themeProperties.forEach(property => {
            let baseColor = data[property] as string;
            let darkColor = lightenDarkenColor(rgbToHex(baseColor));

            baseColor = isHexColor(baseColor) ? hexToRGB(baseColor) : baseColor;
            darkColor = isHexColor(darkColor) ? hexToRGB(darkColor) : darkColor;

            if (data[property] !== undefined) {
                root.style.setProperty(`--${property}`, baseColor);
                body.style.setProperty(`--${property}`, baseColor);

                if (property === 'primary_color' || property === 'secondary_color') {
                    root.style.setProperty(`--${property}_dark`, darkColor);
                    body.style.setProperty(`--${property}_dark`, darkColor);
                }
            }
        });

        this.agencyCustomization = data;
    }

    @action.bound
    public updateCompanyOnboardingFormData(data: Partial<CompanyOnboardingFormData>) {
        this.companyOnboardingFormData = merge(this.companyOnboardingFormData, data);
        return this.companyOnboardingFormData;
    }
}
