import React from 'react';
import { Route } from 'mobx-router';
import { dirBasename } from '../constants';
import { UserOnboardingPage } from '../components/UserOnboarding/UserOnboardingPage';

const routeBasename = dirBasename ? dirBasename : '';
// const userRoutesBase = `${routeBasename}/user/:userId`;

// Company Onboarding routes
const onboardingRouteBasename = `${routeBasename}/user/:token/invite`;
const UserOnboardingRoutes = {
    UserOnboardingPage: new Route({
        path: onboardingRouteBasename,
        component: <UserOnboardingPage />
    })
};

export const UserRoutes = {
    ...UserOnboardingRoutes
};
