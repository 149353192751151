import React, { FC, useState } from 'react';
import {
    ActionList,
    AppCardDivider,
    AppCardHeader,
    AppHeader,
    AppList,
    AppListHeader,
    AppListItem,
    AppPage,
    AppSection,
    useSnackbar,
} from '../../components-v2/shared';
import { Filters } from '../Filters/Filters.helpers';
import { FormikHelpers, FormikProps } from 'formik';
import {
    Form,
    ButtonPrimary,
    handlePromise,
    InputText,
    InputSwitch,
    useAsyncEffect,
    InputSelect,
    ButtonDanger,
    ButtonOutline,
    Modal,
    ModalHeader,
    ModalActions,
    Button,
} from '@lambdacurry/component-library';
import useStore from '../../store/useStore';
import './email-sender-profile.scss';
import classNames from 'classnames';
import { DEFAULT_EMAIL_REGEX, EmailSenderBundle } from '../../constants';
import * as Yup from 'yup';

interface EmailSenderProfileForm {
    subdomain?: string;
    base_domain?: string;
    advanced_mode: boolean;
    sender_email?: string;
}

enum modalType {
    deleteProfile = 'deleteProfile',
}

export const EmailSenderProfile: FC<{
    filters?: Filters;
}> = () => {
    const { store } = useStore();
    const { Api, router } = store;
    const { agencyId, companyId } = router.params;
    const [emailSenderBundle, setEmailSenderBundle] = useState<EmailSenderBundle>();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [modal, setModal] = useState<modalType>();

    const { addSnackbar } = useSnackbar();

    const fetchData = async () => {
        if (!companyId && !agencyId) {
            return;
        }
        const url = companyId ? `/company/${companyId}/sender-profile` : `/agency/${agencyId}/sender-profile`;
        const [response, error] = await handlePromise<{ data: EmailSenderBundle }>(Api.client.get(url));

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to fetch sender profile.', { variant: 'error' });
            return;
        }

        setEmailSenderBundle(response.data);
    };

    useAsyncEffect(fetchData);

    const handleDelete = async () => {
        if (!companyId && !agencyId) {
            addSnackbar('No valid company or partner selected.', { variant: 'error' });
            return;
        }
        setIsDeleting(true);
        const url = companyId ? `/company/${companyId}/sender-profile` : `/agency/${agencyId}/sender-profile`;
        const [response, error] = await handlePromise<{ data: EmailSenderBundle }>(Api.client.delete(url));

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to delete sender profile.', { variant: 'error' });
            return;
        }
        await fetchData();
        setIsDeleting(false);
    };

    const handleSubmit = async (
        values: EmailSenderProfileForm,
        formikHelpers: FormikHelpers<EmailSenderProfileForm>
    ) => {
        const subdomain = values.subdomain?.toLowerCase().trim();

        formikHelpers.setSubmitting(true);
        if (!companyId && !agencyId) {
            addSnackbar('No valid company or partner selected.', { variant: 'error' });
            return;
        }
        if (values.advanced_mode && !values.sender_email) {
            formikHelpers.setFieldError('sender_email', 'Email is required for advanced mode.');
            return;
        }
        if (!values.advanced_mode) {
            if (!subdomain) {
                formikHelpers.setFieldError('subdomain', 'Please enter a valid, one word subdomain.');
                return;
            }
            if (subdomain.length > 16 || /\d/.test(subdomain.slice(0, 1))) {
                formikHelpers.setFieldError(
                    'subdomain',
                    'Only letters and numbers allowed. Must begin with a letter and cannot exceed 16 characters.'
                );
                return;
            }
            if (!values.base_domain) {
                addSnackbar('Please select a base domain.', { variant: 'error' });
                return;
            }
        }
        const newEmail = values.advanced_mode
            ? `${values.sender_email}`.toLowerCase()
            : `noreply@${subdomain}.${values.base_domain}`;
        if (!DEFAULT_EMAIL_REGEX.test(newEmail)) {
            addSnackbar(`${newEmail} is not a valid email address.`, { variant: 'error' });
            return;
        }
        const url = companyId ? `/company/${companyId}/sender-profile` : `/agency/${agencyId}/sender-profile`;
        const [response, error] = await handlePromise<{ data: EmailSenderBundle }>(
            Api.client.post(url, { email: newEmail })
        );

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to update sender profile.', { variant: 'error' });
            return;
        }

        await fetchData();
        formikHelpers.setSubmitting(false);
    };

    const canEditProfile = (agencyExists: boolean, companyRoute: boolean): boolean => {
        return !agencyExists || !companyRoute;
    };

    const fqdns = ['theconversioncloud.com', 'clx.email'];

    const parseExistingEmail = (
        email?: string
    ): { base_domain?: string; subdomain?: string; advanced_mode: boolean } => {
        if (!email) {
            return { advanced_mode: false };
        }
        const usesPresetBaseDomain = fqdns.some((fqdn) => {
            return email.endsWith(`.${fqdn}`);
        });
        const usesPresetFQDN = fqdns.some((fqdn) => {
            return email.endsWith(`@${fqdn}`);
        });
        const base_domain = usesPresetBaseDomain && !usesPresetFQDN ? email.split('.').slice(-2).join('.') : undefined;
        const subdomain =
            usesPresetBaseDomain && !usesPresetFQDN ? email.split('@')[1].replace(`.${base_domain}`, '') : undefined;
        return { base_domain, subdomain, advanced_mode: !usesPresetBaseDomain && !usesPresetFQDN };
    };

    const verificationStatus = (values: EmailSenderProfileForm): string => {
        if (!emailSenderBundle || !emailSenderBundle.emailSender || !emailSenderBundle.emailSender.created_at) {
            return 'Draft';
        }

        if (values.advanced_mode) {
            if (emailSenderBundle.emailSender.from_email === values.sender_email) {
                return emailSenderBundle.managedBy === 'global'
                    ? 'Default'
                    : emailSenderBundle.emailSender.verified_at
                    ? 'Verified'
                    : 'Pending';
            }
            return 'Pending';
        }
        if (emailSenderBundle.emailSender.from_email === `noreply@${values.subdomain}.${values.base_domain}`) {
            return emailSenderBundle.managedBy === 'global'
                ? 'Default'
                : emailSenderBundle.emailSender.verified_at
                ? 'Verified'
                : 'Pending';
        }
        return 'Pending';
    };

    return (
        <AppPage loading={!emailSenderBundle}>
            {!emailSenderBundle ? (
                <>Loading...</>
            ) : (
                <Form
                    className="form-holder-block"
                    validationSchema={Yup.object().shape({
                        subdomain: Yup.string().when('advanced_mode', {
                            is: false,
                            then: Yup.string().trim().required('Please add a subdomain.'),
                        }),
                        base_domain: Yup.string().when('advanced_mode', {
                            is: false,
                            then: Yup.string().required('Please select a base domain.'),
                        }),
                    })}
                    initialValues={{
                        sender_email: emailSenderBundle.emailSender?.from_email,
                        ...parseExistingEmail(emailSenderBundle.emailSender?.from_email),
                    }}
                    onSubmit={handleSubmit}
                >
                    {(formikProps: FormikProps<EmailSenderProfileForm>) => (
                        <>
                            <AppHeader title="Email Sender Profile">
                                <ActionList position="end">
                                    {canEditProfile(emailSenderBundle.agencyExists, !!companyId) ? (
                                        verificationStatus(formikProps.values) === 'Draft' && (
                                            <InputSwitch
                                                name="advanced_mode"
                                                label="Advanced Mode"
                                                labelPlacement="start"
                                                formikProps={formikProps}
                                            />
                                        )
                                    ) : (
                                        <div>
                                            <em>
                                                This profile is managed by {emailSenderBundle.agencyName || 'partner'}
                                            </em>
                                        </div>
                                    )}
                                </ActionList>
                            </AppHeader>
                            <AppSection className="field-group">
                                {canEditProfile(emailSenderBundle.agencyExists, !!companyId) ? (
                                    <div>
                                        <AppCardHeader title="Sender"></AppCardHeader>
                                        <>
                                            {verificationStatus(formikProps.values) === 'Draft' ? (
                                                formikProps.values.advanced_mode ? (
                                                    <InputText
                                                        style={{ width: 400 }}
                                                        formikProps={formikProps}
                                                        name="sender_email"
                                                        label="Sender Email"
                                                    />
                                                ) : (
                                                    <>
                                                        <div style={{ marginBottom: 24 }}>
                                                            <span style={{ padding: 12, fontWeight: 'bold' }}>
                                                                Sender Email:
                                                            </span>
                                                            <span>
                                                                {!formikProps.values.subdomain ||
                                                                !formikProps.values.base_domain
                                                                    ? 'Please enter a subdomain and select a base domain'
                                                                    : `noreply@${formikProps.values.subdomain
                                                                          .toLowerCase()
                                                                          .trim()}.${formikProps.values.base_domain}`}
                                                            </span>
                                                        </div>
                                                        <InputText
                                                            hidden={verificationStatus(formikProps.values) !== 'Draft'}
                                                            style={{ width: 200 }}
                                                            formikProps={formikProps}
                                                            name="subdomain"
                                                            label="Email Subdomain"
                                                        />
                                                        <InputSelect
                                                            style={{ width: 500 }}
                                                            hidden={verificationStatus(formikProps.values) !== 'Draft'}
                                                            formikProps={formikProps}
                                                            name="base_domain"
                                                            optionValueKey="fqdn"
                                                            optionLabelKey="fqdn"
                                                            label="Base Domain"
                                                            autocompleteConfig={{
                                                                multiple: false,
                                                                disableClearable: true,
                                                            }}
                                                            contentEditable={false}
                                                            allowCreateOption={false}
                                                            inputProps={{ autoComplete: 'disable' }}
                                                            options={fqdns.map((fqdn) => {
                                                                return { fqdn };
                                                            })}
                                                        />
                                                    </>
                                                )
                                            ) : verificationStatus(formikProps.values) !== 'Verified' ? (
                                                <>
                                                    <div style={{ marginBottom: 18 }}>
                                                        <span style={{ padding: 12, fontWeight: 'bold' }}>
                                                            {verificationStatus(formikProps.values) !== 'Verified' &&
                                                                'Requested '}
                                                            Sender Email:
                                                        </span>
                                                        <span>{emailSenderBundle.emailSender?.from_email}</span>
                                                    </div>
                                                    <div style={{ marginBottom: 18 }}>
                                                        <span style={{ padding: 12, fontWeight: 'bold' }}>
                                                            {emailSenderBundle.emailSender ? 'Temporary' : 'Default'}{' '}
                                                            Sender Email:
                                                        </span>
                                                        <span>{emailSenderBundle.profile.senderEmail}</span>
                                                    </div>
                                                </>
                                            ) : (
                                                <div style={{ marginBottom: 18 }}>
                                                    <span style={{ padding: 12, fontWeight: 'bold' }}>
                                                        Sender Email:
                                                    </span>
                                                    <span>{emailSenderBundle.emailSender?.from_email}</span>
                                                </div>
                                            )}
                                        </>
                                    </div>
                                ) : (
                                    <div>
                                        <AppCardHeader title="Sender" />
                                        <div style={{ marginBottom: 18 }}>
                                            <span style={{ padding: 12, fontWeight: 'bold' }}>Sender Name:</span>
                                            <span>{emailSenderBundle.profile.senderName}</span>
                                        </div>
                                        <div hidden={!emailSenderBundle.emailSender} style={{ marginBottom: 18 }}>
                                            <span style={{ padding: 12, fontWeight: 'bold' }}>
                                                {verificationStatus(formikProps.values) !== 'Verified' && 'Requested '}
                                                Sender Email:
                                            </span>
                                            <span>{emailSenderBundle.emailSender?.from_email}</span>
                                        </div>
                                        {verificationStatus(formikProps.values) !== 'Verified' && (
                                            <div style={{ marginBottom: 18 }}>
                                                <span style={{ padding: 12, fontWeight: 'bold' }}>
                                                    {emailSenderBundle.emailSender ? 'Temporary' : 'Default'} Sender
                                                    Email:
                                                </span>
                                                <span>{emailSenderBundle.profile.senderEmail}</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div>
                                    {verificationStatus(formikProps.values) !== 'Draft' && (
                                        <div style={{ marginBottom: 18 }}>
                                            <span style={{ padding: 12, fontWeight: 'bold' }}>
                                                Verification Status:
                                            </span>
                                            <span>{verificationStatus(formikProps.values)}</span>
                                        </div>
                                    )}
                                </div>
                                {formikProps.values.advanced_mode && (
                                    <div>
                                        <b>IMPORTANT:&nbsp;</b>
                                        <span>
                                            When using Advanced Mode, you will be responsible for updates and management
                                            of the required DNS settings on the domain of the email you provide.
                                            Profiles using DNS settings that are unable to be validated will not be used
                                            and will be removed from the system after 30 days of failed validation
                                            attempts.
                                        </span>
                                    </div>
                                )}
                                <AppCardDivider />
                                {emailSenderBundle.emailSender &&
                                    emailSenderBundle.emailSender.settings &&
                                    verificationStatus(formikProps.values) === 'Pending' && (
                                        <>
                                            <AppList className={classNames('dns-list')}>
                                                <AppListHeader className={classNames('dns-list-header')}>
                                                    <div key="record_type">DNS Record Type</div>
                                                    <div key="record_name">Name</div>
                                                    <div key="record_value">Value</div>
                                                    <div key="record_status">Status</div>
                                                </AppListHeader>
                                                <div className={classNames('dns-list-item-wrapper')}>
                                                    <AppListItem className={classNames('dns-list-item')}>
                                                        <div>TXT</div>
                                                        <div>
                                                            {emailSenderBundle.emailSender.settings.dkim_txt_name}
                                                        </div>
                                                        <div className={classNames('dns-list-txt-box')}>
                                                            {emailSenderBundle.emailSender.settings.dkim_txt_value}
                                                        </div>
                                                        <div>
                                                            {emailSenderBundle.emailSender.settings.dkim_status === 'OK'
                                                                ? 'Verified'
                                                                : 'Not Verified'}
                                                        </div>
                                                    </AppListItem>
                                                    <AppListItem className={classNames('dns-list-item')}>
                                                        <div>TXT</div>
                                                        <div>{emailSenderBundle.emailSender.settings.domain_name}.</div>
                                                        <div className={classNames('dns-list-txt-box')}>
                                                            {emailSenderBundle.emailSender.settings.spf_txt_value}
                                                        </div>
                                                        <div>
                                                            {emailSenderBundle.emailSender.settings.spf_status === 'OK'
                                                                ? 'Verified'
                                                                : 'Not Verified'}
                                                        </div>
                                                    </AppListItem>
                                                    <AppListItem className={classNames('dns-list-item')}>
                                                        <div>TXT</div>
                                                        <div>
                                                            {emailSenderBundle.emailSender.settings.token_txt_name}
                                                        </div>
                                                        <div className={classNames('dns-list-txt-box')}>
                                                            {emailSenderBundle.emailSender.settings.token_txt_value}
                                                        </div>
                                                        <div>
                                                            {!!emailSenderBundle.emailSender.settings.verified_at
                                                                ? 'Verified'
                                                                : 'Not verified'}
                                                        </div>
                                                    </AppListItem>
                                                </div>
                                            </AppList>
                                        </>
                                    )}
                                {canEditProfile(emailSenderBundle.agencyExists, !!companyId) && (
                                    <ActionList position="end">
                                        <ButtonOutline
                                            hidden={verificationStatus(formikProps.values) !== 'Pending'}
                                            disabled={formikProps.isSubmitting}
                                            type="submit"
                                        >
                                            {formikProps.isSubmitting ? 'Requesting' : 'Request'} DNS Verification
                                        </ButtonOutline>
                                        <ButtonDanger
                                            hidden={verificationStatus(formikProps.values) === 'Draft'}
                                            disabled={isDeleting}
                                            onClick={() => {
                                                setModal(modalType.deleteProfile);
                                            }}
                                        >
                                            {isDeleting ? 'Deleting' : 'Delete'} Profile
                                        </ButtonDanger>
                                        <ButtonPrimary
                                            hidden={verificationStatus(formikProps.values) !== 'Draft'}
                                            disabled={!formikProps.dirty || formikProps.isSubmitting}
                                            className="filter-submit"
                                            type="submit"
                                        >
                                            {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                        </ButtonPrimary>
                                    </ActionList>
                                )}
                                <Modal
                                    isOpen={modal === modalType.deleteProfile}
                                    onAfterClose={() => {
                                        setModal(undefined);
                                    }}
                                >
                                    <ModalHeader title="Are you sure you want to permamently delete this sender?" />
                                    <ModalActions>
                                        <ActionList position="end">
                                            <Button onClick={() => setModal(undefined)}>Cancel</Button>
                                            <ButtonPrimary
                                                disabled={isDeleting}
                                                onClick={async () => {
                                                    await handleDelete();
                                                    formikProps.resetForm();
                                                    setModal(undefined);
                                                    setIsDeleting(false);
                                                    await fetchData();
                                                }}
                                            >
                                                {isDeleting ? 'Deleting...' : 'Delete'}
                                            </ButtonPrimary>
                                        </ActionList>
                                    </ModalActions>
                                </Modal>
                            </AppSection>
                        </>
                    )}
                </Form>
            )}
        </AppPage>
    );
};
