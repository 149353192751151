import * as Yup from 'yup';
import { EmailResponse } from './EmailEditor.types';

const sectionValueValidations = {
    _default: Yup.object({ hidden: Yup.boolean(), htmlContent: Yup.string() }),
    header: Yup.object({
        hidden: Yup.boolean(),
        imageWidth: Yup.number().required(),
        imageUrl: Yup.string()
            .url()
            .required(),
        htmlContent: Yup.string()
    }),
    footer: Yup.object({ hidden: Yup.boolean(), poweredByName: Yup.string(), poweredByUrl: Yup.string() })
};

export const CreateTemplateFormValidationSchema = () => {
    return Yup.object({
        app_source_type: Yup.string().required('Please select a App type.'),
        calendar_source_id: Yup.object().nullable(),
        template_preset: Yup.string().required('Please select a template preset.')
    });
};

export const buildEmailEditorValidationSchema = (email?: EmailResponse) => {
    if (!email) {
        return;
    }
    const sectionSchemas = email.sections.reduce((acc, section) => {
        if (section.editable) {
            if (sectionValueValidations[section.type]) {
                acc[section.id] = sectionValueValidations[section.type];
            }

            acc[section.id] = sectionValueValidations._default;
        }

        return acc;
    }, {});

    return Yup.object({
        delivery: Yup.object({
            subject: Yup.string().required('Subject is a required field.'),
            previewText: Yup.string().required('Preview Text is a required field.'),
            smsAlternative: Yup.string().nullable(),
            replyToEmail: Yup.string()
                .email('Please enter a valid email address.')
                .required('Reply To Email is a required field.')
        }),
        ...sectionSchemas
    });
};
