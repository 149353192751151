import { inject, observer } from 'mobx-react';
import * as React from 'react';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { InjectedProps } from '../../types';
import { PropertyManagementCompanyTypes } from '../../types/PropertyManagementCompany';
import {
    ActionList,
    AppContent,
    AppHeader,
    AppList,
    AppListItem,
    AppListItemContent,
    AppPage,
    ButtonPrimary,
    IconButton,
} from '../../components-v2/shared';

interface PropertyManagementCompanyListState {
    open: boolean;
    newItem?: PropertyManagementCompanyTypes;
}

@inject('store')
@observer
export class PropertyManagementCompanyList extends React.Component<{}, PropertyManagementCompanyListState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state: PropertyManagementCompanyListState = {
        open: false,
    };

    public componentDidMount() {
        const { fetchPropertyManagementCompany } = this.injected.store;
        return fetchPropertyManagementCompany();
    }

    public render() {
        const { PropertyManagementCompany } = this.injected.store;
 
        const newItemButtonHandler = async () => {
            const { router } = this.injected.store;
            router.goTo(DefaultRoutes.PropertyManagementCompanyNew, { ...router.params }, this.injected.store);
        }

        const editButtonHandler = async (id: number) => {
            const { router } = this.injected.store;
            router.goTo(DefaultRoutes.PropertyManagementCompanyEdit, { ...router.params, id }, this.injected.store);
        }

        const deleteButtonHandler = async (id: number) => {
            const { deletePropertyManagementCompany } = this.injected.store;
            await deletePropertyManagementCompany(id);
        }

        return (
            <AppPage>
                <AppHeader title="Property Management Companies">
                    <div className="flex-spacer" />
                    <ButtonPrimary onClick={() => newItemButtonHandler()} >Create New Property Management Company</ButtonPrimary>
                </AppHeader>
                <AppContent>
                    <AppList>
                        {PropertyManagementCompany.values.map(({ id, name }: any, index: React.Key | null | undefined) => {
                            return (
                                <>
                                    <AppListItem key={index}>
                                        <AppListItemContent title={name} />
                                        <ActionList position="end">
                                            <IconButton
                                                icon="pencil"
                                                onClick={() =>
                                                    editButtonHandler(id)
                                                }
                                            />
                                            <IconButton
                                                icon="trash"
                                                onClick={() =>
                                                    deleteButtonHandler(id)
                                                }
                                            />
                                        </ActionList>
                                    </AppListItem>
                                </>
                            );
                        })}
                    </AppList>
                </AppContent>
            </AppPage>
        );
    }
}

export default PropertyManagementCompanyList;
