import { InputSelect } from '@lambdacurry/component-library';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { dma, supportedTimezones } from '../../../../constants';
import useStore from '../../../../store/useStore';
import { ScheduleReportModalProps } from '../ScheduleReportModal';
import { chartDateRangeOptions } from '../ScheduleReportModal.helpers';

export const FiltersTab: FC<Partial<ScheduleReportModalProps>> = observer(({ schedulerFormikProps }) => {
    const { store } = useStore();
    const { filterAgencies, filterCompanies, businessCategory, regions, PropertyManagementCompany, ownershipGroup } = store;
    const accessibleDMAValues = filterCompanies.reduce(
        (acc, curr) => (curr.dma ? { ...acc, [curr.dma]: true } : acc),
        {}
    );
    const DMAValues = dma.filter(d => !!accessibleDMAValues[d.code]);
    const showAgenciesFilter = filterAgencies.length > 0;
    const showCompaniesFilter = filterCompanies.length > 0;
    const showBusinessCategoriesFilter = businessCategory.values.length > 0;
    const showDMAValuesFilter = DMAValues.length > 0;
    const showPropertyManagementCompanyFilter = PropertyManagementCompany.values.length > 0;
    const showOwnershipGroupFilter = ownershipGroup.values.length > 0;
    const showRegionsFilter = regions.values.length > 0;
    const filteredActiveCompanies = filterCompanies.filter(company => company.active)

    return (
        <div className="clarity-schedule-report-modal-filters-tab tab-content">
            <div className="field-row">
                <InputSelect
                    label="Time Zone"
                    name="filters.dateRange.timezone"
                    options={supportedTimezones}
                    optionValueKey="key"
                    optionLabelKey="label"
                    formikProps={schedulerFormikProps}
                    inputProps={{ autoComplete: 'disable' }}
                />
                <InputSelect
                    name="filters.dateRange.preset"
                    label="Chart Date Range"
                    optionValueKey="value"
                    formikProps={schedulerFormikProps}
                    options={chartDateRangeOptions}
                />
            </div>

            {showAgenciesFilter && (
                <InputSelect
                    label="Partners"
                    labelPlacement="above"
                    placeholder={!schedulerFormikProps?.values.filters.agency_ids.length ? 'All' : ''}
                    name="filters.agency_ids"
                    options={[...filterAgencies]}
                    optionValueKey="id"
                    optionLabelKey="name"
                    formikProps={schedulerFormikProps}
                    autocompleteConfig={{ multiple: true }}
                    inputProps={{ autoComplete: 'disable' }}
                />
            )}

            {showCompaniesFilter && (
                <InputSelect
                    label="Companies"
                    labelPlacement="above"
                    placeholder={!schedulerFormikProps?.values.filters.company_ids.length ? 'All' : ''}
                    name="filters.company_ids"
                    options={[...filteredActiveCompanies]}
                    optionValueKey="id"
                    optionLabelKey="name"
                    formikProps={schedulerFormikProps}
                    autocompleteConfig={{ multiple: true }}
                    inputProps={{ autoComplete: 'disable' }}
                />
            )}

            {showBusinessCategoriesFilter && (
                <InputSelect
                    label="Business Categories"
                    labelPlacement="above"
                    placeholder={!schedulerFormikProps?.values.filters.business_category_ids.length ? 'All' : ''}
                    name="filters.business_category_ids"
                    options={[...businessCategory.values]}
                    optionValueKey="id"
                    optionLabelKey="title"
                    formikProps={schedulerFormikProps}
                    autocompleteConfig={{ multiple: true }}
                    inputProps={{ autoComplete: 'disable' }}
                />
            )}

            {showDMAValuesFilter && (
                <InputSelect
                    label="Designated Marketing Areas"
                    labelPlacement="above"
                    placeholder={!schedulerFormikProps?.values.filters.dmas.length ? 'All' : ''}
                    name="filters.dmas"
                    options={DMAValues}
                    optionValueKey="code"
                    optionLabelKey="area"
                    formikProps={schedulerFormikProps}
                    autocompleteConfig={{ multiple: true }}
                    inputProps={{ autoComplete: 'disable' }}
                />
            )}

            {showPropertyManagementCompanyFilter && (
                <InputSelect
                    label="Property Management Companies"
                    labelPlacement="above"
                    placeholder={
                        !schedulerFormikProps?.values.filters.property_management_company_ids.length ? 'All' : ''
                    }
                    name="filters.property_management_company_ids"
                    options={[...PropertyManagementCompany.values]}
                    optionValueKey="id"
                    optionLabelKey="name"
                    formikProps={schedulerFormikProps}
                    autocompleteConfig={{ multiple: true }}
                    inputProps={{ autoComplete: 'disable' }}
                />
            )}

            {showOwnershipGroupFilter && (
                <InputSelect
                    label="Ownership Groups"
                    labelPlacement="above"
                    name="filters.ownership_group_ids"
                    placeholder={!schedulerFormikProps?.values.filters.ownership_group_ids.length ? 'All' : ''}
                    options={[...ownershipGroup.values]}
                    optionValueKey="id"
                    optionLabelKey="name"
                    formikProps={schedulerFormikProps}
                    autocompleteConfig={{ multiple: true }}
                    inputProps={{ autoComplete: 'disable' }}
                />
            )}

            {showRegionsFilter && (
                <InputSelect
                    label="Regions"
                    labelPlacement="above"
                    name="filters.region_ids"
                    placeholder={!schedulerFormikProps?.values.filters.region_ids.length ? 'All' : ''}
                    options={[...regions.values]}
                    optionValueKey="id"
                    optionLabelKey="name"
                    formikProps={schedulerFormikProps}
                    autocompleteConfig={{ multiple: true }}
                    inputProps={{ autoComplete: 'disable' }}
                />
            )}
        </div>
    );
});
