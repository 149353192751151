import React from 'react';
import { AppCard, AppCardHeader, AppSectionHeader, Panel } from '../../../../components-v2/shared';
import { DateTime } from 'luxon';
import { Lead } from '../../../../types/Lead';

const tagColors = ['purple', 'red', 'cyan', 'white', 'light-red']

function getRandomElement(array: string[]) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}

export const AppEventChatbotAI: React.FC<{ lead: Lead }> = ({ lead }) => {
    const data = lead.chatbotAi;

    if (!data) {
        return null;
    }

    const UTMTag: React.FC<{ val: string; className: string }> = ({ val, className }) => {
        if (!val || val === '') {
            return null;
        }

        return (
            <div className={className}>
                {val}
            </div>
        );
    };

    return (
        <AppCard>
            <AppCardHeader
                icon={{ name: 'chat' }}
                title="Claire AI+"
                description={DateTime.fromISO(lead.created_at as string).toLocaleString(DateTime.DATETIME_SHORT)}
            />
            <AppSectionHeader title="Chat Topics" as="Header" />
            <Panel className="app-event-card-live-chat-comments customer-lead-journey-labels">
                {
                    lead.chatbotAi.topics.map((tag: any, index: number) => {
                        return (
                            <UTMTag
                                key={tag.step}
                                val={`${tag.step}.  ${tag.val}`}
                                className={`customer-lead-journey-label ${getRandomElement(tagColors) || 'generic-color'}`}
                            />
                        );
                    })
                }
            </Panel>
        </AppCard>
    );
};
