import { ILiveChat } from '../../types';

function returnAsArray(data: ILiveChat | null) {
    const d = JSON.parse(JSON.stringify(data));
    if (Array.isArray(d)) {
        return d;
    }
    return [d];
}

function formatValues(payload: { [key: string]: any }, companyId?: string) {
    const data = {
        active: false,
        company_id: companyId,
        ...payload[0],
        ...payload
    }
    delete data[0];
    delete data.id;

    return data;
}

export {
    returnAsArray,
    formatValues,
}
