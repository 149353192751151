import React, { FC } from 'react';
import {
    ActionList,
    Button,
    ButtonPrimary,
    Modal,
    ModalHeader,
    ModalActions,
    useSnackbar
} from '../../../components-v2/shared';
import Resource from '../../../store/Resource';
import { Crm } from '../../../types/Crm';
import { closeModal, setModalData, CrmReducerAction } from '../Crm.helpers';

export interface DeleteCrmModalProps {
    isOpen: boolean;
    dispatch: React.Dispatch<CrmReducerAction>;
    crms: Resource<Crm>;
    crm: Crm & { in_use_by?: string };
    onAfterDelete?: (crmId: Crm['id']) => void;
}

export const DeleteCrmModal: FC<DeleteCrmModalProps> = ({ isOpen, dispatch, crms, crm, onAfterDelete }) => {
    const { addSnackbar } = useSnackbar();

    const handleConfirmClick = async () => {
        try {
            await crms.delete(crm.id);
            addSnackbar(`"${crm.name}" has been deleted.`, {
                variant: 'success'
            });

            if (typeof onAfterDelete === 'function') {
                onAfterDelete(crm.id);
            }
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(
                `Failed to delete "${crm.name}". ${
                    !crm.in_use_by ? ' Might currently be in use.' : `It is currently used by ${crm.in_use_by}.`
                } Remove from settings and try again.`,
                {
                    variant: 'error'
                }
            );
        }
    };

    const handleAfterClose = () => {
        closeModal(dispatch);
        setModalData(dispatch, null);
    };

    return (
        <Modal id="deleteCrm" isOpen={isOpen} onAfterClose={handleAfterClose}>
            {crm && (
                <>
                    <ModalHeader title="Delete CRM Configuration?" />
                    Are you sure you want to delete "{crm.name}"?
                    <ModalActions>
                        <ActionList position="end">
                            <Button onClick={() => closeModal(dispatch)}>No, don't delete</Button>
                            <ButtonPrimary onClick={handleConfirmClick}>Yes, delete</ButtonPrimary>
                        </ActionList>
                    </ModalActions>
                </>
            )}
        </Modal>
    );
};
