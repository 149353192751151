import React, { Fragment } from 'react';
import { DripEvent, FutureDripEvent } from '../../../../types';
import './drip-event-cards.scss';

import { AppCard, AppCardGrid } from '../../../../components-v2/shared';
import { DripEventCard } from './DripEventCard';
import { FutureDripEventCard } from './FutureDripEventCard';

export const DripEventCards: React.FC<{ data?: DripEvent[], futureData?: FutureDripEvent[] }> = ({ data, futureData }) => {
    if (!data && !futureData) {
        return <AppCard>Loading...</AppCard>;
    }

    return (
        <AppCardGrid>
            {!futureData || futureData.length === 0 ? (
                <AppCard>
                    <div className="drip-event-card-content">
                        <>
                            <div className="field-row">
                                No future Lead Nurturing notifications scheduled.
                            </div></></div>
                </AppCard>
            ) : futureData.map((futureDripEvent, index: number) => (
                <Fragment key={`${futureDripEvent.type}-${index}`}>
                    <FutureDripEventCard futureDripEvent={futureDripEvent} />
                </Fragment>
            ))}
            {!data || data.length === 0 ? (
                <AppCard>
                    <div className="drip-event-card-content">
                        <>
                            <div className="field-row">
                                No Lead Nurturing notifications have been sent yet.
                            </div></></div>
                </AppCard>

            ) : data.map((dripEvent, index: number) => (
                <Fragment key={`${dripEvent.type}-${index}`}>
                    <DripEventCard dripEvent={dripEvent} />
                </Fragment>
            ))}
        </AppCardGrid>
    );
};
