import React, { useState } from 'react';
import { AppCard, AppCardHeader } from '../../../../components-v2/shared';
import { handlePromise, useAsyncEffect } from '@lambdacurry/component-library';
import { DateTime } from 'luxon';
import { Lead } from '../../../../types/Lead';
import useStore from '../../../../store/useStore';
import axios from 'axios';
import { SmsMessage } from '../../../../types';
import { MessagesConversation } from '../../../Messages/MessagesConversation';

export const AppEventTextUs: React.FC<{ lead: Lead }> = ({ lead }) => {
    const { store } = useStore();
    const { Api } = store;
    const cancelToken = axios.CancelToken;
    const cancelTokenSource = cancelToken.source();
    const [smsThread, setSmsThread] = useState<SmsMessage[]>([]);
    const [hasSmsError, setHasSmsError] = useState<boolean>(false);

    const fetchSmsThread = async () => {
        const [response, error] = await handlePromise<{ data: { count: number; sms_messages: SmsMessage[] } }>(
            Api.client.get(`/lead-sms/${lead.id}`, { cancelToken: cancelTokenSource.token })
        );

        if (!response || error) {
            console.error('Error fetching sms thread', error);
            return setHasSmsError(true);
        }

        setHasSmsError(false);
        setSmsThread(response.data.sms_messages);
    };

    useAsyncEffect(fetchSmsThread, cancelTokenSource.cancel, [lead.id]);

    return (
        <AppCard>
            <AppCardHeader
                icon={{ name: 'chat' }}
                title="Text Us Now"
                description={DateTime.fromISO(lead.created_at as string).toLocaleString(DateTime.DATETIME_SHORT)}
            />

            {hasSmsError && <div>Error received when fetching SMS thread</div>}
            {!hasSmsError && smsThread.length === 0 && <div>No SMS thread found</div>}

            {smsThread.length > 0 && (
                <div className="app-event-card-text-us-conversation">
                    <MessagesConversation messages={smsThread} />
                </div>
            )}
        </AppCard>
    );
};
