import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import './checkbox-list.scss';

export interface CheckboxListProps extends HTMLAttributes<HTMLUListElement> {
    columns?: 1 | 2 | 3;
}

export const CheckboxList: FC<CheckboxListProps> = ({ className, columns, ...props }) => (
    <ul
        className={classNames('lc-checkbox-list', { [`lc-checkbox-list--columns-${columns}`]: columns }, className)}
        {...props}
    />
);
