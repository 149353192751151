import React, { FC, ReactNode, useState } from 'react';
import { SideNavMenuContext } from './SideNav.helpers';

export interface SideNavMenuProps {
    children: ReactNode;
}

export const SideNavMenu: FC<SideNavMenuProps> = ({ children }) => {
    const [submenuOpen, setSubmenuOpen] = useState<string | null>(null);

    return (
        <SideNavMenuContext.Provider value={{ submenuOpen, setSubmenuOpen }}>
            <ul className="side-nav-menu">{children}</ul>
        </SideNavMenuContext.Provider>
    );
};
