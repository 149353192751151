import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    ButtonOutlinePrimary
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, RulesActionFactory, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { RuleAction } from '../../../../types';
export interface YardiSippFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface YardiSippCrm extends Crm {
    configuration: {
        username: string;
        password: string;
        server_name: string;
        database: string;
        interface_entity: string;
        interface_license: string;
        soap_endpoint: string;
        property_id: string;
        lead_source: string;
        agent_name: string;
    };
}

const ruleActions: Array<RuleAction> = [
    RulesActionFactory.ReplaceStringAction('lead_source', 'Replace Lead Source with new value'),
    RulesActionFactory.ReplaceStringAction('agent_name', 'Replace Agent Name with new value')
];

export const YardiSippForm: FC<YardiSippFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<YardiSippCrm>) => {
        const updatedValue = formikProps.values;
        if (!updatedValue.ruleset) {
            updatedValue.ruleset = { rules: [] }
        }
        await onValidate(updatedValue, crmConfigTypes.yardi_sipp);
    };

    const handleSubmit = async (values: YardiSippCrm, actions: FormikHelpers<YardiSippCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.yardi_sipp);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<YardiSippCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Yardi Voyager" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader title="Lead Parameters" />
                                <InputText
                                    name="configuration.property_id"
                                    label="Property ID"
                                    helperText="Yardi Voyager Property ID"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.lead_source"
                                    label="Lead Source"
                                    helperText="Yardi Voyager Lead Source"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.agent_name"
                                    label="Agent Name"
                                    helperText="Yardi Voyager Agent Name"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <AppSectionHeader title="Optional License Override Settings" />
                                <InputText
                                    name="configuration.interface_entity"
                                    label="Interface Entity"
                                    helperText="Optional Interface Entity (otherwise default value is used)"
                                    required={false}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.interface_license"
                                    label="Interface License"
                                    helperText="Optional Interface License (otherwise default value is used)"
                                    required={false}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 512 }}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader
                                    title="Connection Settings"
                                    subtitle="Contact Yardi Voyager support for your exact connection settings"
                                />
                                <InputText
                                    name="configuration.soap_endpoint"
                                    label="API Endpoint"
                                    helperText="Yardi Voyager API Endpoint ending with /Webservices"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.server_name"
                                    label="Server Name"
                                    helperText="Yardi Voyager Server Name"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.database"
                                    label="Database"
                                    helperText="Yardi Voyager Database"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.username"
                                    label="Username"
                                    helperText="Yardi Voyager Username"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.password"
                                    label="Password"
                                    helperText="Yardi Voyager Password"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
