import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
    ActionList,
    AppContent,
    AppHeader,
    AppListEmpty,
    AppPage,
    InputSwitch,
    useSnackbar
} from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { useAsyncEffect } from '@lambdacurry/component-library';
import { ReactComponent as EmptyInsiteIllustration } from './EmptyInsiteIllustration.svg';
import { emptyInsite } from '../../store/initial';
import { Insite as InsiteResource } from '../../types/Insite';
import DefaultRoutes from '../../routes/DefaultRoutes';

export const Insite = observer(() => {
    const [loading, setLoading] = useState<boolean>(true);
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, fetchInsites, insites } = store;
    const { companyId } = router.params;
    const insiteItem = getItem();
    const mode = insiteItem?.id ? 'update' : 'create';

    if (!companyId) {
        router.goTo(DefaultRoutes.Home, {}, store);
        return null;
    }

    function getItem(): InsiteResource {
        if (insites.values.length) {
            return insites.values[0];
        }

        return {
            ...emptyInsite,
            active: false,
            company_id: parseInt(companyId, 10)
        };
    }

    const fetchData = async () => {
        await fetchInsites();
        setLoading(false);
    };
    useAsyncEffect(fetchData);

    const handleActiveToggleClick = async () => {
        try {
            await insites[mode]({ ...insiteItem, active: !insiteItem.active });
            addSnackbar(`In-site lead capture ${insiteItem.active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });
        } catch (error) {
            console.error(error);
            addSnackbar(`Failed to ${insiteItem.active ? 'deactivate' : 'activate'} in-site lead capture.`, {
                variant: 'error'
            });
        }
    };

    const handleAutoCreateLeadsToggleClick = async () => {
        try {
            await insites[mode]({ ...insiteItem, auto_create_leads: !insiteItem.auto_create_leads });
            addSnackbar(`Auto create leads ${insiteItem.auto_create_leads ? 'disabled' : 'enabled'}.`, {
                variant: 'success'
            });
        } catch (error) {
            console.error(error);
            addSnackbar(`Failed to ${insiteItem.auto_create_leads ? 'disable' : 'enable'} auto create leads.`, {
                variant: 'error'
            });
        }
    };

    return (
        <AppPage loading={loading}>
            <AppHeader title="In-site Lead Capture" icon={{ name: 'addUser', color: 'blue' }}>
                {mode === 'update' && (
                    <ActionList position="end">
                        <InputSwitch
                            labelOn="Active"
                            labelOff="Inactive"
                            labelPlacement="start"
                            onClick={handleActiveToggleClick}
                            checked={!!insiteItem.active}
                        />
                    </ActionList>
                )}
            </AppHeader>

            <AppContent>
                <AppListEmpty
                    illustration={<EmptyInsiteIllustration />}
                    title="Capture in-site leads"
                    action={
                        <InputSwitch
                            label="Auto create leads"
                            labelPlacement="start"
                            onClick={handleAutoCreateLeadsToggleClick}
                            checked={!!insiteItem.auto_create_leads}
                        />
                    }
                />
            </AppContent>
        </AppPage>
    );
});
