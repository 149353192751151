import React, { FC } from 'react';
import { DefaultIconNames, AliasIconNames, Icon } from '@lambdacurry/component-library';
import { AppCard, AppCardProps } from '..';

import './stat-card.scss';

export interface StatCardProps extends AppCardProps {
    label: string;
    value: string;
    icon: DefaultIconNames | AliasIconNames;
    color: 'green' | 'blue';
    helpText?: string;
}
export const StatCard: FC<StatCardProps> = ({ label, value, icon, color, ...props }) => (
    <AppCard className={`stat-card stat-card--${color}`} {...props}>
        <span className="stat-card__icon">
            <Icon name={icon} />
        </span>

        <div className="stat-card__content">
            <div className="stat-card__value">{value}</div>
            <div className="stat-card__label">{label}</div>
        </div>
    </AppCard>
);
