import React, { FunctionComponent } from 'react';
import { get as _get } from 'lodash';
import useStore from '../../../store/useStore';
import { InputText, InputSwitch, AppSectionHeader, InputColor, InputSelect } from '../../../components-v2/shared';
import { EditEmailSectionProps } from './EditEmailSectionForm';
import { S3FileUploader } from '../../../components-v2/shared/s3-file-uploader';

export const EditEmailLogoForm: FunctionComponent<EditEmailSectionProps> = ({
    section,
    formikProps,
    useEditAppEmailReducer,
    response
}) => {
    const [, dispatch] = useEditAppEmailReducer();
    const { store } = useStore();

    const setUploadingFile = (uploading: boolean) => dispatch({ name: 'setUploadingFile', payload: uploading });

    const setImageUrl = (url: string) => formikProps.setFieldValue(`${section.id}.imageUrl`, url);

    const handleClearImage = () => {
        setImageUrl('');
    };

    const handleCropSave = (fileUrl: string) => {
        setUploadingFile(false);
        setImageUrl(fileUrl);
    };

    const availableLogos = () => {
        return response.picklists?.logos || [];
    };

    const availableColors = (selectedColor: string | null) => {
        const baseColors = response.picklists?.colors || [];
        if (!selectedColor) {
            return baseColors;
        }

        return [null, ...baseColors.filter(color => color !== selectedColor)];
    };

    const activeBorder = (logo: { url: string; width: number }) => {
        if (formikProps.values[`${section.id}`].imageUrl === logo.url) {
            return '2px solid blue';
        }
        return '';
    };

    return (
        <>
            <InputSwitch label="Show Logo" name={`${section.id}.showImage`} formikProps={formikProps} />
            {formikProps.values[section.id].showImage ? (
                <>
                    <S3FileUploader
                        name={`${section.id}.imageFile`}
                        application="mail-template"
                        data={{ companyId: parseInt(store.router.params.companyId, 0) }}
                        formikProps={formikProps}
                        fileUploaderProps={{
                            onDrop: () => setUploadingFile(true),
                            onClearAll: () => handleClearImage(),
                            onRemoveFile: () => handleClearImage()
                        }}
                        imageCropperProps={{ onSave: handleCropSave, onCancel: () => setUploadingFile(false) }}
                    />
                    <InputText
                        id="imageWidth"
                        label="Logo Width"
                        type="number"
                        name={`${section.id}.imageWidth`}
                        formikProps={formikProps}
                        suffix={<>Pixels</>}
                    />
                    {availableLogos().length > 0 && (
                        <>
                            <AppSectionHeader title="Logo Gallery" />
                            {availableLogos().map(logo => (
                                <img
                                    src={logo.url}
                                    width={logo.width}
                                    style={{ margin: '12px', border: activeBorder(logo) }}
                                    onClick={() => {
                                        handleCropSave(logo.url);
                                        formikProps.setFieldValue(`${section.id}.imageWidth`, logo.width);
                                    }}
                                />
                            ))}
                        </>
                    )}
                </>
            ) : (
                <div style={{ marginTop: '20px' }}>
                    <InputText
                        id="companyName"
                        label="Logo Text / Company Name"
                        name={`${section.id}.companyName`}
                        formikProps={formikProps}
                    />
                    <InputSelect
                        name={`${section.id}.textlogoTextColor`}
                        label="Logo Text Color"
                        optionValueKey="value"
                        optionLabelKey="label"
                        options={[
                            { value: '#FFFFFF', label: 'White' },
                            { value: '#000000', label: 'Black' }
                        ]}
                        formikProps={formikProps}
                    />
                    <InputColor
                        name={`${section.id}.textlogoBackgroundColor`}
                        label="Text Logo Background Color"
                        formikProps={formikProps}
                    />
                    {availableColors(formikProps.values[`${section.id}`].textlogoBackgroundColor).length > 0 && (
                        <>
                            <AppSectionHeader title="Color Gallery" />
                            {availableColors(formikProps.values[`${section.id}`].textlogoBackgroundColor).map(
                                (color, i) => (
                                    <div
                                        onClick={() => {
                                            formikProps.setFieldValue(`${section.id}.textlogoBackgroundColor`, color);
                                        }}
                                        style={{
                                            border: '1px solid #dddddd',
                                            borderRadius: '5px',
                                            padding: '12px',
                                            cursor: 'grab',
                                            marginBottom: '8px'
                                        }}
                                    >
                                        <span
                                            style={{
                                                backgroundColor: color || 'transparent',
                                                position: 'absolute',
                                                width: '24px',
                                                height: '24px'
                                            }}
                                        ></span>
                                        <span
                                            style={{
                                                position: 'relative',
                                                left: '32px'
                                            }}
                                        >
                                            {color || 'Remove Custom Color'}
                                        </span>
                                    </div>
                                )
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
};
