import React, { FC } from 'react';
import { FormikHelpers } from 'formik';
import { ButtonPrimary, Form, InputText } from '@lambdacurry/component-library';
import useStore from '../../../../store/useStore';
import { handlePromise } from '../../../../util/async';
import { Title, useSnackbar } from '../../../../components-v2/shared';
import { RealyncIntegration } from './RealyncIntegration.helpers';

interface RealyncConnectFormValues {
    realync_access_code: string;
    realync_property_id: string;
}

interface RealyncSetupFormProps {
    setRealync: React.Dispatch<RealyncIntegration>;
}

export const RealyncSetupForm: FC<RealyncSetupFormProps> = ({ setRealync }) => {
    const { addSnackbar } = useSnackbar();
    const {
        store: {
            api,
            router: { params }
        }
    } = useStore();

    const handleConnection: (
        values: RealyncConnectFormValues,
        formikHelpers: FormikHelpers<RealyncConnectFormValues>
    ) => void = async (values, formikProps) => {
        const [response, error] = await handlePromise(
            api.client.post('/realync/settings', {
                company_id: params.companyId,
                ...values
            })
        );

        if (!response?.data || error) {
            formikProps.setSubmitting(false);
            addSnackbar('Error connecting to Realync, please check your credentials and try again.', {
                variant: 'error'
            });
            return;
        }

        setRealync(response.data);
    };
    return (
        <>
            <Form<RealyncConnectFormValues>
                className="realync__connect-form"
                initialValues={{ realync_access_code: '', realync_property_id: '' }}
                onSubmit={handleConnection}
            >
                {formikProps => (
                    <>
                        <Title>Setup</Title>
                        <p>Enter your Property Management Company API Token below to configure your account.</p>
                        <InputText
                            label="Property Management Company API Token"
                            name="realync_access_code"
                            formikProps={formikProps}
                        />
                        <div className="flex-row">
                            <InputText
                                className="realync__connect-form__property-id"
                                label="Property ID"
                                name="realync_property_id"
                                formikProps={formikProps}
                            />
                            <ButtonPrimary type="submit" disabled={formikProps.isSubmitting}>
                                Connect
                            </ButtonPrimary>
                        </div>
                    </>
                )}
            </Form>
        </>
    );
};
