import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Formik, FormikHelpers, FormikProps, FormikState } from 'formik';
import { Col, PageHeader } from 'react-bootstrap';
import { InjectedProps } from '../../types';
import { Category } from '../../types/Category';
import ControlButtonGroup from '../misc/ControlButtonGroup';
import CategoryForm, { ICategoryForm } from './CategoryForm';
import { emptyCategory } from '../../store/initial';
import DefaultRoutes from '../../routes/DefaultRoutes';

interface CategoryEditState {
    editing: boolean;
}

@inject('store')
@observer
export class CategoryEdit extends React.Component<{}, CategoryEditState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state = {
        editing: false
    };

    public initialValues = (category: Category) => {
        const { isLoading } = this.injected.store;
        return isLoading ? emptyCategory : category;
    };

    public get id() {
        return this.injected.store.router.params.id ? parseInt(this.injected.store.router.params.id, 10) : 0;
    }

    public getCategory() {
        return this.injected.store.businessCategory.getItem(this.id);
    }

    public fetchData() {
        const { fetchCategory } = this.injected.store;
        return fetchCategory(this.id);
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public render() {
        const category = this.getCategory();
        const initialValues = this.initialValues(category);

        if (!initialValues) {
            return null;
        }

        return (
            <>
                <PageHeader>Business Category</PageHeader>
                <Col className="fixed-controls">
                    <Formik initialValues={initialValues} enableReinitialize onSubmit={this.handleSubmit}>
                        {this.renderForm()}
                    </Formik>
                </Col>
            </>
        );
    }

    public renderForm = () => {
        return CategoryForm({
            editing: this.state.editing,
            controls: (bag: FormikProps<ICategoryForm>) => (
                <ControlButtonGroup
                    editing={this.state.editing}
                    canEdit
                    canDelete={this.state.editing}
                    handleEdit={this.toggleEdit}
                    handleCancel={this.handleCancel(bag)}
                    handleBack={this.handleBack}
                    handleDelete={this.handleDelete}
                    dirty={bag.dirty}
                />
            )
        });
    };

    public toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    public handleCancel = (bag: FormikProps<ICategoryForm>) => () => {
        this.setState({ editing: false });
        bag.resetForm(bag.initialValues as Partial<FormikState<ICategoryForm>>);
    };

    public handleBack = () => {
        const { router } = this.injected.store;
        const { activeAgencyId } = this.injected.store.agencyStore;
        router.goTo(
            activeAgencyId ? DefaultRoutes.AgencyCategoryList : DefaultRoutes.CategoryList,
            router.params,
            this.injected.store
        );
    };

    public handleSubmit = async (values: Category, actions: FormikHelpers<Category>) => {
        actions.setSubmitting(true);
        const { businessCategory } = this.injected.store;
        return await businessCategory
            .update(values)
            .then(() => {
                actions.setSubmitting(false);
                this.setState({ editing: false });
            })
            .catch(error => {
                actions.setErrors(error.response.data);
                actions.setSubmitting(false);
            });
    };

    protected handleDelete = async () => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
            await this.injected.store.deleteCategory(this.id);
            this.handleBack();
        }
        return;
    };
}

export default CategoryEdit;
