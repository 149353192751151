import { dirBasename } from '../constants/env';

/**
 * Checking url start path, if path is finished with char '/' remove it, and reload
 */
export const detectRoutePath = (): { pathFlag: boolean, companyId: string } => {
    const href = window.location.href;
    const path = window.location.pathname;
    const basename = dirBasename ? dirBasename.replace(/\/$/, '') : '';

    // if project have been opened without basedir, please redirect
    if (basename && path.indexOf(basename) < 0) {
        window.location.href = basename;
        return { pathFlag: false, companyId: '' };
    }

    let projectPath = path;
    if (basename) {
        projectPath = projectPath.replace(basename, '');
    }

    // if project url is finished with / , please replace / into ? and redirect
    if (projectPath && path !== '/' && href.indexOf('?') < 0 && projectPath.slice(-1) === '/') {
        window.location.href = path.replace(/\/$/, '?');
        return { pathFlag: false, companyId: '' };
    }

    let companyId = '';
    if (path) {
        const match = path.match(/\d+/);
        companyId = match ? match[0] : ''
    }

    return { pathFlag: true, companyId };
};
