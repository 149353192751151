import React, { useEffect, useReducer } from 'react';
import { observer } from 'mobx-react';
import {
    ActionList,
    AppContent,
    AppFooter,
    AppHeader,
    AppPage,
    Button,
    ButtonPrimary,
    ButtonOutlinePrimary,
    Form,
    InputCheckbox,
    InputSelect,
    InputSwitch,
    InputText,
    NotificationSettingsFieldGroup,
    useSnackbar
} from '../../components-v2/shared';
import { emptyChat } from '../../store/initial';
import useStore from '../../store/useStore';
import { FormikHelpers, FormikProps } from 'formik';
import { Chat } from '../../types';
import { EditChatFormValidationSchema } from './LiveChatLegacy.validation';
import {
    liveChatReducer,
    liveChatInitialState,
    openModal,
    closeModal,
    setLoading,
    setChatActive
} from './LiveChatLegacy.helpers';

import { useAsyncEffect } from '@lambdacurry/component-library';
import { CreateChatLeadModal } from './components/CreateChatLeadModal/CreateChatLeadModal';
import { unsavedChangesContainerSelectorDefault } from '../../constants';

export const LiveChatLegacy = observer(() => {
    const [state, dispatch] = useReducer(liveChatReducer, liveChatInitialState);
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, chats, schedules, fetchSchedules, fetchChats } = store;
    const { companyId } = router.params;
    const chatItem = chats.values.length ? chats.values[0] : null;
    const mode = chatItem?.id ? 'update' : 'create';

    const scheduleOptions = schedules.values.map(({ id, name }) => ({
        value: id,
        label: name
    }));

    const fetchData = async () => {
        await fetchChats();
        await fetchSchedules();
        setLoading(dispatch, false);
    };
    useAsyncEffect(fetchData);

    // Change the active state when switching between modes
    useEffect(() => {
        setChatActive(dispatch, mode === 'update' ? !!chatItem?.active : true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    const handleToggleClick = async () => {
        if (mode !== 'update') {
            return;
        }

        try {
            await chats.update({ ...chatItem, active: !state.isChatActive });
            setChatActive(dispatch, !state.isChatActive);
            addSnackbar(`Chat has been ${state.isChatActive ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to ${state.isChatActive ? 'deactivate' : 'activate'} chat.`, {
                variant: 'error'
            });
        }
    };

    const handleSubmit = async (values: Chat, actions: FormikHelpers<Chat>) => {
        const data = { ...values, active: state.isChatActive, crm_config_id: values.crm_config_id || null };

        try {
            await chats[mode](data);
            addSnackbar('Chat has been saved.', { variant: 'success' });
            actions.resetForm({ values });
        } catch (error) {
            actions.setErrors(error.response.data);
            addSnackbar('Failed to save chat.', { variant: 'error' });
        }
    };

    const initialValues = () => {
        return {
            ...emptyChat,
            company_id: parseInt(companyId, 10),
            ...chatItem
        };
    };

    return (
        <>
            <AppPage loading={state.loading}>
                <AppHeader title="Live Chat" icon={{ name: 'chat', color: 'blue' }}>
                    {mode === 'update' && (
                        <ActionList position="end">
                            <InputSwitch
                                labelOn="Active"
                                labelOff="Inactive"
                                labelPlacement="start"
                                onClick={handleToggleClick}
                                checked={state.isChatActive}
                            />
                            <ButtonOutlinePrimary
                                onClick={() => openModal(dispatch, 'createChatLead')}
                                data-test-id="button_chat_create_lead"
                            >
                                Create Lead
                            </ButtonOutlinePrimary>
                        </ActionList>
                    )}
                </AppHeader>

                <Form
                    initialValues={initialValues()}
                    onSubmit={handleSubmit}
                    validationSchema={EditChatFormValidationSchema}
                    confirmUnsavedChanges
                    unsavedChangesConfig={{
                        containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
                    }}
                >
                    {(formikProps: FormikProps<Chat>) => (
                        <>
                            <AppContent className="field-group-content">
                                <div className="field-group-col">
                                    <InputText name="title_name" label="Title" formikProps={formikProps} />
                                    <InputSelect
                                        name="schedule_id"
                                        label="Schedule"
                                        optionValueKey="value"
                                        options={scheduleOptions}
                                        formikProps={formikProps}
                                    />
                                    <InputText name="account_id" label="Account ID" formikProps={formikProps} />
                                    <InputText name="website_id" label="Website ID" formikProps={formikProps} />
                                    <InputText name="bd_id" label="Live Chat ID" formikProps={formikProps} />
                                    <div>
                                        <InputCheckbox
                                            name="show_floating_invite"
                                            label="Show floating invite"
                                            formikProps={formikProps}
                                        />
                                    </div>
                                </div>
                                <div className="field-group-col">
                                    <NotificationSettingsFieldGroup formikProps={formikProps} crmBlacklist={[]} />
                                </div>
                            </AppContent>

                            <AppFooter>
                                <ActionList position="end">
                                    {formikProps.dirty && (
                                        <Button
                                            onClick={() => formikProps.resetForm()}
                                            disabled={formikProps.isSubmitting}
                                            data-lc-trigger-unsaved-changes={true}
                                            data-test-id="button_bpn_cancel"
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                    <ButtonPrimary
                                        type="submit"
                                        disabled={(!!chatItem?.id && !formikProps.dirty) || formikProps.isSubmitting}
                                        data-test-id="button_chat_submit"
                                    >
                                        {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                    </ButtonPrimary>
                                </ActionList>
                            </AppFooter>
                        </>
                    )}
                </Form>
            </AppPage>

            {chatItem && (
                <CreateChatLeadModal
                    chat={chatItem}
                    isOpen={state.modal.active === 'createChatLead'}
                    dispatch={dispatch}
                    onAfterClose={() => closeModal(dispatch)}
                />
            )}
        </>
    );
});
