import {
    EmailResponse,
    EmailSection,
    EditAppEmailFormValues,
    EmailSectionValues,
    EmailOverrides,
    LeadNurtureTemplate,
} from './EmailEditor.types';
import { IconNames } from '../../components-v2/types';

export interface EditAppEmailReducerState {
    emailResponse?: EmailResponse;
    editing?: EmailSection;
    isEditingName: boolean;
    testEmailStatus: ServerRequestStatus;
    activeModal: EditAppEmailModals;
    revertStatus: ServerRequestStatus;
    uploadingFile?: boolean;
}

export type ServerRequestStatus = 'waiting' | 'sending' | 'sent' | 'error';

export type EditAppEmailModals = 'none' | 'revertInboxDetails' | 'revertEmailSection';

export interface EditAppEmailReducerAction {
    name: keyof typeof editAppEmailReducers;
    payload?: any;
}

const editAppEmailReducers = {
    setEmailResponse: (state: EditAppEmailReducerState, emailResponse: EmailResponse) => {
        if (state.editing) {
            state.editing = { ...emailResponse.sections[state.editing.id] };
        }

        return { ...state, emailResponse };
    },
    setEditing: (state: EditAppEmailReducerState, editing: EmailSection) => ({ ...state, editing }),
    cancelEditing: (state: EditAppEmailReducerState, _: any) => ({ ...state, editing: undefined }),
    updateTestEmailStatus: (state: EditAppEmailReducerState, testEmailStatus: ServerRequestStatus) => ({
        ...state,
        testEmailStatus
    }),
    updateRevertStatus: (state: EditAppEmailReducerState, revertStatus: ServerRequestStatus) => ({
        ...state,
        revertStatus
    }),
    setIsEditingName: (state: EditAppEmailReducerState, isEditingName: boolean) => ({
        ...state,
        isEditingName
    }),
    closeModal: (state: EditAppEmailReducerState, _: any) => ({ ...state, activeModal: 'none' as EditAppEmailModals }),
    openModal: (state: EditAppEmailReducerState, activeModal: EditAppEmailModals) => ({ ...state, activeModal }),
    setUploadingFile: (state: EditAppEmailReducerState, uploadingFile: boolean) => ({ ...state, uploadingFile })
};

export const editAppEmailReducer = (state: EditAppEmailReducerState, action: EditAppEmailReducerAction) => {
    if (!editAppEmailReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: EditAppEmailReducerState = editAppEmailReducers[action.name](state, action.payload);

    return nextState;
};

export const formatInitialValues: (emailResponse: EmailResponse) => EditAppEmailFormValues = emailResponse => {
    return emailResponse.sections.reduce(
        (acc: EditAppEmailFormValues, section: EmailSection) => {
            if (section.editable) {
                acc[section.id] = {
                    ...section.values,
                    imageFile: section.overrideValues?.imageUrl ? [{ preview: section.overrideValues.imageUrl }] : null
                } as EmailSectionValues;
            } else if (section.hideable) {
                acc[section.id] = {
                    ...section.defaultValues,
                    hidden: section.values?.hidden
                } as EmailSectionValues;
            }
            return acc;
        },
        {
            delivery: emailResponse.delivery.values
        },
    );
};

export const formatEmailEditorValues = (
    values: EditAppEmailFormValues | { [x: string]: null },
    initialValues: EditAppEmailFormValues,
    overrides: EmailOverrides
) => {
    const formattedValues = overrides;

    for (const sectionName in values) {
        if (!values.hasOwnProperty(sectionName)) {
            continue;
        }

        // NOTE: We can revert email data to the default data by sending a section back with null
        if (values[sectionName] === null) {
            if (sectionName === 'delivery') {
                formattedValues[sectionName] = null;
                continue;
            }

            if (!formattedValues.sections) {
                formattedValues.sections = {};
            }

            formattedValues.sections[sectionName] = null;
            continue;
        }

        for (const property in values[sectionName]) {
            if (!values[sectionName].hasOwnProperty(property)) {
                continue;
            }

            if (initialValues[sectionName] && values[sectionName][property] === initialValues[sectionName][property]) {
                continue;
            }

            if (sectionName === 'delivery') {
                if (!formattedValues.delivery) {
                    formattedValues.delivery = {};
                }

                formattedValues.delivery[property] = (values as EditAppEmailFormValues)[sectionName][property];
            } else {
                if (!formattedValues.sections) {
                    formattedValues.sections = {};
                }

                if (!formattedValues.sections[sectionName]) {
                    formattedValues.sections[sectionName] = {};
                }

                formattedValues.sections[sectionName][property] = values[sectionName][property];
            }
        }
    }

    return formattedValues;
};

export const AppEmailDetailsMap: {
    [x: string]: {
        color: string;
        icon: IconNames;
    };
} = {
    default: {
        color: 'primary_color',
        icon: 'apps'
    },
    schedule_genie: {
        color: 'app-red',
        icon: 'schedule'
    },
    concession_manager: {
        color: 'app-green',
        icon: 'concessionManager'
    },
    best_price_now: {
        color: 'app-green',
        icon: 'deal'
    },
    income_calculator: {
        color: 'app-green',
        icon: 'income'
    }
};

export interface AppEmail {
    id: string;
    name: string;
    appName: string;
    leadNurtureTemplate?: LeadNurtureTemplate;
    status: { active: boolean };
}

export const appEmailIsActive = ({ status }: AppEmail | EmailResponse) => status.active;
