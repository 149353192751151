import React from 'react';
import { observer } from 'mobx-react';
import { Bengal } from './Bengal';

import posthog from 'posthog-js';
import { posthogKey } from '../constants';
import useStore from '../store/useStore';

export const App = observer(() => {
    const { store } = useStore();

    React.useEffect(() => {
        if (store.activeUser?.id && posthogKey) {
            posthog.init(posthogKey,
                {
                    api_host: 'https://us.i.posthog.com',
                }
            );
            posthog.identify(`${store.activeUser?.id}`, {
                email: store.activeUser.email
            });
        }
        return () => {
            if (posthog.__loaded) {
                posthog.sessionRecording?.stopRecording();
            }
        }
    }, [store.activeUser]);

    return <Bengal />;
});
