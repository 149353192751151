import React, { useState, useEffect } from 'react';
import { Modal, Title } from '../../../../components-v2/shared';
import { ReactComponent as Illustration } from '../assets/DashboardIllustration.svg';
import './onboarding-success-modal.scss';

const getOnboardingModalType = (queryParams: Object) => {
    if (!queryParams) return false;
    if (queryParams['companyOnboardingSuccess'] === 'true') return 'company';
    if (queryParams['userOnboardingSuccess'] === 'true') return 'user';
    return false;
};

export const OnboardingSuccessModal: React.FC<{
    queryParams: any;
    onAfterClose: (queryParam: string) => void;
}> = ({ queryParams, onAfterClose }) => {
    const [open, setOpen] = useState<boolean>();

    const onboardingModalType = getOnboardingModalType(queryParams);

    useEffect(() => {
        if (!onboardingModalType) {
            return;
        }
        setOpen(true);
    }, [queryParams]);

    if (open === undefined) {
        return <></>;
    }

    return (
        <Modal
            isOpen={open}
            onAfterClose={() => {
                onAfterClose(`${onboardingModalType}OnboardingSuccess`);
                setOpen(false);
            }}
        >
            <div className="onboarding-success-modal">
                <Illustration />

                {onboardingModalType === 'company' && (
                    <>
                        <Title>Welcome, let’s get started!</Title>
                        <p className="text">
                            Start by configuring your applications, customizing your app, and finishing setup.
                        </p>
                    </>
                )}

                {onboardingModalType === 'user' && (
                    <>
                        <Title>Welcome to the team!</Title>
                        <p className="text">
                            Now that your account is ready, let's explore your dashboard to get started.
                        </p>
                    </>
                )}
            </div>
        </Modal>
    );
};
