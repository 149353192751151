export interface FeatureFlag {
    id: number;
    slug: string;
}

export interface AllFeatureFlag {
    id: number;
    flags_id: number;
    name: string;
    allow_global: boolean;
    company_has_flag: boolean;
}

export enum FEATURE_FLAGS {
    ENABLE_GMB_YELP_BUTTONS = 'enable_gmb_yelp_buttons',
    ENABLE_BEST_PRICE_NOW = 'enable_best_price_now',
    ENABLE_INCOME_CALC = 'enable_income_calc',
    ENABLE_SMS_PROVISIONING = 'enable_sms_provisioning',
    ENABLE_CHAT_NOW = 'enable_chat_now',
    ENABLE_TEXT_US = 'enable_text_us',
    ENABLE_LEAD_NOTES = 'enable_lead_notes',
    ENABLE_LEAD_REMINDERS = 'enable_lead_reminders',
    ENABLE_LEAD_EMAILS = 'enable_lead_emails',
    ENABLE_LEAD_TEXT_US_NOW = 'enable_lead_text_us_now',
    ENABLE_SMS_MESSAGES = 'enable_sms_messages',
    ENABLE_SMS_FORWARDING = 'enable_sms_forwarding',
    ENABLE_CHATBOT = 'chatbot',
    ENABLE_CHATBOT_AI = 'chatbot_ai',
}
