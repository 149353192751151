export const dma = [
    { code: 0, area: 'Not Applicable' },
    { code: 500, area: '500 - Portland-Auburn' },
    { code: 501, area: '501 - New York' },
    { code: 502, area: '502 - Binghamton' },
    { code: 503, area: '503 - Macon' },
    { code: 504, area: '504 - Philadelphia' },
    { code: 505, area: '505 - Detroit' },
    { code: 506, area: '506 - Boston (Manchester)' },
    { code: 507, area: '507 - Savannah' },
    { code: 508, area: '508 - Pittsburgh' },
    { code: 509, area: '509 - Ft. Wayne' },
    { code: 510, area: '510 - Cleveland-Akron (Canton)' },
    { code: 511, area: '511 - Washington, DC (Hagrstwn)' },
    { code: 512, area: '512 - Baltimore' },
    { code: 513, area: '513 - Flint-Saginaw-Bay City' },
    { code: 514, area: '514 - Buffalo' },
    { code: 515, area: '515 - Cincinnati' },
    { code: 516, area: '516 - Erie' },
    { code: 517, area: '517 - Charlotte' },
    { code: 518, area: '518 - Greensboro-H.Point-W.Salem' },
    { code: 519, area: '519 - Charleston, SC' },
    { code: 520, area: '520 - Augusta-Aiken' },
    { code: 521, area: '521 - Providence-New Bedford' },
    { code: 522, area: '522 - Columbus, GA (Opelika, Al)' },
    { code: 523, area: '523 - Burlington-Plattsburgh' },
    { code: 524, area: '524 - Atlanta' },
    { code: 525, area: '525 - Albany, GA' },
    { code: 526, area: '526 - Utica' },
    { code: 527, area: '527 - Indianapolis' },
    { code: 528, area: '528 - Miami-Ft. Lauderdale' },
    { code: 529, area: '529 - Louisville' },
    { code: 530, area: '530 - Tallahassee-Thomasville' },
    { code: 531, area: '531 - Tri-Cities, TN-VA' },
    { code: 532, area: '532 - Albany-Schenectady-Troy' },
    { code: 533, area: '533 - Hartford & New Haven' },
    { code: 534, area: '534 - Orlando-Daytona Bch-Melbrn' },
    { code: 535, area: '535 - Columbus, OH' },
    { code: 536, area: '536 - Youngstown' },
    { code: 537, area: '537 - Bangor' },
    { code: 538, area: '538 - Rochester, NY' },
    { code: 539, area: '539 - Tampa-St. Pete (Sarasota)' },
    { code: 540, area: '540 - Traverse City-Cadillac' },
    { code: 541, area: '541 - Lexington' },
    { code: 542, area: '542 - Dayton' },
    { code: 543, area: '543 - Springfield-Holyoke' },
    { code: 544, area: '544 - Norfolk-Portsmth-Newpt Nws' },
    { code: 545, area: '545 - Greenville-N.Bern-Washngtn' },
    { code: 546, area: '546 - Columbia, SC' },
    { code: 547, area: '547 - Toledo' },
    { code: 548, area: '548 - West Palm Beach-Ft. Pierce' },
    { code: 549, area: '549 - Watertown' },
    { code: 550, area: '550 - Wilmington' },
    { code: 551, area: '551 - Lansing' },
    { code: 552, area: '552 - Presque Isle' },
    { code: 553, area: '553 - Marquette' },
    { code: 554, area: '554 - Wheeling-Steubenville' },
    { code: 555, area: '555 - Syracuse' },
    { code: 556, area: '556 - Richmond-Petersburg' },
    { code: 557, area: '557 - Knoxville' },
    { code: 558, area: '558 - Lima' },
    { code: 559, area: '559 - Bluefield-Beckley-Oak Hill' },
    { code: 560, area: '560 - Raleigh-Durham (Fayetvlle)' },
    { code: 561, area: '561 - Jacksonville' },
    { code: 563, area: '563 - Grand Rapids-Kalmzoo-B.Crk' },
    { code: 564, area: '564 - Charleston-Huntington' },
    { code: 565, area: '565 - Elmira (Corning)' },
    { code: 566, area: '566 - Harrisburg-Lncstr-Leb-York' },
    { code: 567, area: '567 - Greenvll-Spart-Ashevll-And' },
    { code: 569, area: '569 - Harrisonburg' },
    { code: 570, area: '570 - Myrtle Beach-Florence' },
    { code: 571, area: '571 - Ft. Myers-Naples' },
    { code: 573, area: '573 - Roanoke-Lynchburg' },
    { code: 574, area: '574 - Johnstown-Altoona-St Colge' },
    { code: 575, area: '575 - Chattanooga' },
    { code: 576, area: '576 - Salisbury' },
    { code: 577, area: '577 - Wilkes Barre-Scranton-Hztn' },
    { code: 581, area: '581 - Terre Haute' },
    { code: 582, area: '582 - Lafayette, IN' },
    { code: 583, area: '583 - Alpena' },
    { code: 584, area: '584 - Charlottesville' },
    { code: 588, area: '588 - South Bend-Elkhart' },
    { code: 592, area: '592 - Gainesville' },
    { code: 596, area: '596 - Zanesville' },
    { code: 597, area: '597 - Parkersburg' },
    { code: 598, area: '598 - Clarksburg-Weston' },
    { code: 600, area: '600 - Corpus Christi' },
    { code: 602, area: '602 - Chicago' },
    { code: 603, area: '603 - Joplin-Pittsburg' },
    { code: 604, area: '604 - Columbia-Jefferson City' },
    { code: 605, area: '605 - Topeka' },
    { code: 606, area: '606 - Dothan' },
    { code: 609, area: '609 - St. Louis' },
    { code: 610, area: '610 - Rockford' },
    { code: 611, area: '611 - Rochestr-Mason City-Austin' },
    { code: 612, area: '612 - Shreveport' },
    { code: 613, area: '613 - Minneapolis-St. Paul' },
    { code: 616, area: '616 - Kansas City' },
    { code: 617, area: '617 - Milwaukee' },
    { code: 618, area: '618 - Houston' },
    { code: 619, area: '619 - Springfield, MO' },
    { code: 622, area: '622 - New Orleans' },
    { code: 623, area: '623 - Dallas-Ft. Worth' },
    { code: 624, area: '624 - Sioux City' },
    { code: 625, area: '625 - Waco-Temple-Bryan' },
    { code: 626, area: '626 - Victoria' },
    { code: 627, area: '627 - Wichita Falls & Lawton' },
    { code: 628, area: '628 - Monroe-El Dorado' },
    { code: 630, area: '630 - Birmingham (Ann And Tusc)' },
    { code: 631, area: '631 - Ottumwa-Kirksville' },
    { code: 632, area: '632 - Paducah-Cape Girard-Harsbg' },
    { code: 633, area: '633 - Odessa-Midland' },
    { code: 634, area: '634 - Amarillo' },
    { code: 635, area: '635 - Austin' },
    { code: 636, area: '636 - Harlingen-Wslco-Brnsvl-Mca' },
    { code: 637, area: '637 - Cedar Rapids-Wtrlo-Iwc &Dub' },
    { code: 638, area: '638 - St. Joseph' },
    { code: 639, area: '639 - Jackson, TB' },
    { code: 640, area: '640 - Memphis' },
    { code: 641, area: '641 - San Antonio' },
    { code: 642, area: '642 - Lafayette, LA' },
    { code: 643, area: '643 - Lake Charles' },
    { code: 644, area: '644 - Alexandria, LA' },
    { code: 647, area: '647 - Greenwood-Greenville' },
    { code: 648, area: '648 - Champaign&Sprngfld-Decatur' },
    { code: 649, area: '649 - Evansville' },
    { code: 650, area: '650 - Oklahoma City' },
    { code: 651, area: '651 - Lubbock' },
    { code: 652, area: '652 - Omaha' },
    { code: 656, area: '656 - Panama City' },
    { code: 657, area: '657 - Sherman-Ada' },
    { code: 658, area: '658 - Green Bay-Appleton' },
    { code: 659, area: '659 - Nashville' },
    { code: 661, area: '661 - San Angelo' },
    { code: 662, area: '662 - Abilene-Sweetwater' },
    { code: 669, area: '669 - Madison' },
    { code: 670, area: '670 - Ft. Smith-Fay-Sprngdl-Rgrs' },
    { code: 671, area: '671 - Tulsa' },
    { code: 673, area: '673 - Columbus-Tupelo-W Pnt-Hstn' },
    { code: 675, area: '675 - Peoria-Bloomington' },
    { code: 676, area: '676 - Duluth-Superior' },
    { code: 678, area: '678 - Wichita-Hutchinson Plus' },
    { code: 679, area: '679 - Des Moines-Ames' },
    { code: 682, area: '682 - Davenport-R.Island-Moline' },
    { code: 686, area: '686 - Mobile-Pensacola (Ft Walt)' },
    { code: 687, area: '687 - Minot-Bsmrck-Dcknsn(Wlstn)' },
    { code: 691, area: '691 - Huntsville-Decatur (Flor)' },
    { code: 692, area: '692 - Beaumont-Port Arthur' },
    { code: 693, area: '693 - Little Rock-Pine Bluff' },
    { code: 698, area: '698 - Montgomery-Selma' },
    { code: 702, area: '702 - La Crosse-Eau Claire' },
    { code: 705, area: '705 - Wausau-Rhinelander' },
    { code: 709, area: '709 - Tyler-Longview(Lfkn&Ncgd)' },
    { code: 710, area: '710 - Hattiesburg-Laurel' },
    { code: 711, area: '711 - Meridian' },
    { code: 716, area: '716 - Baton Rouge' },
    { code: 717, area: '717 - Quincy-Hannibal-Keokuk' },
    { code: 718, area: '718 - Jackson, MS' },
    { code: 722, area: '722 - Lincoln & Hastings-Krny' },
    { code: 724, area: '724 - Fargo-Valley City' },
    { code: 725, area: '725 - Sioux Falls(Mitchell)' },
    { code: 734, area: '734 - Jonesboro' },
    { code: 736, area: '736 - Bowling Green' },
    { code: 737, area: '737 - Mankato' },
    { code: 740, area: '740 - North Platte' },
    { code: 743, area: '743 - Anchorage' },
    { code: 744, area: '744 - Honolulu' },
    { code: 745, area: '745 - Fairbanks' },
    { code: 746, area: '746 - Biloxi-Gulfport' },
    { code: 747, area: '747 - Juneau' },
    { code: 749, area: '749 - Laredo' },
    { code: 751, area: '751 - Denver' },
    { code: 752, area: '752 - Colorado Springs-Pueblo' },
    { code: 753, area: '753 - Phoenix (Prescott)' },
    { code: 754, area: '754 - Butte-Bozeman' },
    { code: 755, area: '755 - Great Falls' },
    { code: 756, area: '756 - Billings' },
    { code: 757, area: '757 - Boise' },
    { code: 758, area: '758 - Idaho Fals-Pocatllo(Jcksn)' },
    { code: 759, area: '759 - Cheyenne-Scottsbluff' },
    { code: 760, area: '760 - Twin Falls' },
    { code: 762, area: '762 - Missoula' },
    { code: 764, area: '764 - Rapid City' },
    { code: 765, area: '765 - El Paso (Las Cruces)' },
    { code: 766, area: '766 - Helena' },
    { code: 767, area: '767 - Casper-Riverton' },
    { code: 770, area: '770 - Salt Lake City' },
    { code: 771, area: '771 - Yuma-El Centro' },
    { code: 773, area: '773 - Grand Junction-Montrose' },
    { code: 789, area: '789 - Tucson (Sierra Vista)' },
    { code: 790, area: '790 - Albuquerque-Santa Fe' },
    { code: 798, area: '798 - Glendive' },
    { code: 800, area: '800 - Bakersfield' },
    { code: 801, area: '801 - Eugene' },
    { code: 802, area: '802 - Eureka' },
    { code: 803, area: '803 - Los Angeles' },
    { code: 804, area: '804 - Palm Springs' },
    { code: 807, area: '807 - San Francisco-Oak-San Jose' },
    { code: 810, area: '810 - Yakima-Pasco-Rchlnd-Knnwck' },
    { code: 811, area: '811 - Reno' },
    { code: 813, area: '813 - Medford-Klamath Falls' },
    { code: 819, area: '819 - Seattle-Tacoma' },
    { code: 820, area: '820 - Portland, OR' },
    { code: 821, area: '821 - Bend, OR' },
    { code: 825, area: '825 - San Diego' },
    { code: 828, area: '828 - Monterey-Salinas' },
    { code: 839, area: '839 - Las Vegas' },
    { code: 855, area: '855 - Santabarbra-Sanmar-Sanluob' },
    { code: 862, area: '862 - Sacramnto-Stkton-Modesto' },
    { code: 866, area: '866 - Fresno-Visalia' },
    { code: 868, area: '868 - Chico-Redding' },
    { code: 881, area: '881 - Spokane' }
];
