import React, { FC } from 'react';
import { PaginationPrimary, IconButton } from '@lambdacurry/component-library';
import { AppSection, Title, useSnackbar } from '../../../../components-v2/shared';
import useStore from '../../../../store/useStore';
import { handlePromise } from '../../../../util/async';
import { RealyncReducerAction, RealyncState, RealyncVideosResponse } from './RealyncIntegration.helpers';

export interface RealyncIntegrationVideoListProps {
    state: RealyncState;
    dispatch: React.Dispatch<RealyncReducerAction>;
}

export interface RealyncSetupFormValues {
    description: string;
    realync_video_id: string;
}

export const RealyncIntegrationVideoList: FC<RealyncIntegrationVideoListProps> = ({
    state: { realync, videos },
    dispatch
}) => {
    const {
        store: {
            api,
            router: { params }
        }
    } = useStore();

    const { addSnackbar } = useSnackbar();

    if (!realync) return <>Loading</>;
    if (realync.status !== 'authenticated')
        return <>This integration needs to be setup again, please delete it and try again.</>;

    const handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void = async (_, page) => {
        const [videosResponse, videosError] = await handlePromise<{ data: RealyncVideosResponse }>(
            api.client.get<RealyncVideosResponse>(
                `/realync/company/${params.companyId}/property/${realync.realync_property_id}/videos`,
                {
                    params: {
                        page
                    }
                }
            )
        );
        if (!videosResponse?.data || videosError) {
            addSnackbar('Failed to fetch Realync videos.', { variant: 'error' });
            return;
        }

        dispatch({ name: 'setVideos', payload: videosResponse.data });
    };

    if (!realync) return <>Loading...</>;

    return (
        <>
            <AppSection>
                <Title>Realync Property</Title>
                {realync.realync_property_id && (
                    <p>
                        {realync.realync_property.name} - {realync.realync_property_id}
                    </p>
                )}

                {videos && videos.records.length > 0 && (
                    <>
                        <Title>Available Videos</Title>
                        <p>These are the videos you are able to attach to customer email templates.</p>
                        <div className='row'>
                            {videos?.records.map(video => (
                                <div key={video.id} className='col-12 col-sm-6 col-md-4 col-lg-3 custom_margin'>
                                    <div className="realync__videos-list-item-main-wrapper">
                                        <div className='realync__videos-list-item-inner'>
                                            <img className="realync__videos-list-item__thumbnail" src={video.videoImageURL} />
                                            <a href={video.videoURL} target="_blank" aria-label="View video on Realync" className='play-button-link'>
                                                <IconButton icon="play" />
                                            </a>
                                        </div>
                                        <div className='card_body'>
                                            <span className='card_title'>{video.title}</span>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="realync__videos-list__pagination">
                            {(videos.count || 0) > 20 && (
                                <PaginationPrimary
                                    pagesCount={videos.pages || 1}
                                    page={videos.currentPage}
                                    onChange={handlePageChange}
                                />
                            )}
                        </div>
                    </>
                )}
            </AppSection>
        </>
    );
};
