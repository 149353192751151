import { DateTime } from 'luxon';
import React, { FC, useEffect } from 'react';
import { ChatMessage } from '../../../types';
import { MessageBubble } from './MessageBubble';
import './message-conversation.scss';

export const MessagesConversation: FC<{ messages: ChatMessage[] }> = ({ messages }) => {
    const sortedMessages = messages.sort((a: ChatMessage, b: ChatMessage) => {
        const aTime = DateTime.fromISO(a.sent_at || a.created_at).toMillis();
        const bTime = DateTime.fromISO(b.sent_at || b.created_at).toMillis();

        if (aTime !== bTime) {
            return bTime < aTime ? 1 : -1;
        }

        return 0;
    });

    const scrollToBottom = () => {
        document.querySelectorAll('.message-conversation').forEach(c => c.scrollTo(0, Number.MAX_SAFE_INTEGER));
    };

    useEffect(() => scrollToBottom());

    return (
        <div className="message-conversation">
            {sortedMessages.map(message => (
                <MessageBubble key={message.id} message={message} />
            ))}
        </div>
    );
};
