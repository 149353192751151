import { apiServerURL } from '../constants';

export const downloadFile = (content: string, filename: string) => {
    const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        link.download = filename;
        link.href = URL.createObjectURL(blob);
        link.click();
    }
};

const fileTypeToPath = {
    agencyAvatar: `/agency/avatar/`
};

export function getFileUrl(fileName?: string | null, type?: keyof typeof fileTypeToPath) {
    if (!fileName) {
        return undefined;
    }

    return `${apiServerURL}/images/${type && fileTypeToPath[type] ? fileTypeToPath[type] : ''}${fileName}`;
}
