import React, { useState } from 'react';
import { FormikHelpers } from 'formik';
import { first } from 'lodash';
import classNames from 'classnames';
import {
    Modal,
    ModalHeader,
    ModalActions,
    ButtonPrimary,
    Form,
    Button,
    InputText,
    handlePromise,
    useAsyncEffect,
    Tooltip,
    Menu,
    MenuItems,
    IconButton
} from '@lambdacurry/component-library';
import {
    clearThread,
    commandOutputs,
    getCustomerInitials,
    getCustomerName,
    MessagesAction,
    messagesFormSchema,
    MessagesFormValues,
    MessagesState,
    newMessageFormSchema,
    NewMessageFormValues,
    toggleMessagePanel,
    UpdateThreadCommand
} from './Messages.helpers';
import useStore from '../../store/useStore';
import { LeadStatus, LeadType } from '../../types';
import { ActionList, Avatar, Icon, useSnackbar } from '../../components-v2/shared';
import { SmsThread, SmsThreadResponse } from '../../types/SmsThread';
import { phoneFormatter } from '../../util/formatters';
import { MessagesConversation } from './MessagesConversation';
import { CircularProgress } from '@material-ui/core';
import { isMinScreenSize } from '../../util/responsive-helpers';
import { getRouteWithContext } from '../../routes/routeUtils';

interface MessageThreadProps {
    state: MessagesState;
    dispatch: React.Dispatch<MessagesAction>;
    saveBlobFromCsvData: (csvData: any, fileName: string) => void;
    updateThreads: () => Promise<void>;
}

const MessageThread: React.FC<MessageThreadProps> = ({ state, dispatch, saveBlobFromCsvData, updateThreads }) => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const [isCreateLeadModalOpen, setCreateLeadModalOpen] = useState<boolean>(false);
    const {
        archived,
        client_number,
        customer,
        host_number,
        id,
        is_agree_sms_notification,
        marked_read
    } = state.selectedThread!;
    const canReceiveSms =
        (customer ? customer.is_agree_sms_notification : is_agree_sms_notification)?.toLowerCase() === 'yes' || false;

    const initialFormValues = {
        email: '',
        firstName: '',
        lastName: '',
        phone: client_number
    };

    const initialNewMessageValues = {
        newMessage: ''
    };

    const createLead = async ({ email, firstName, lastName, phone }: MessagesFormValues): Promise<void> => {
        const { leads, activeCompanyId, fetchTextUs } = store;

        try {
            await fetchTextUs();
            const textUsId = first(store.textUs.values)?.id;
            const leadId: number = await leads.create({
                first_name: firstName,
                last_name: lastName,
                email,
                phone_number: phone,
                comments: '',
                lead_type: LeadType.TU,
                company_id: activeCompanyId,
                status: LeadStatus.OPEN_OPPORTUNITY,
                textus_id: textUsId,
                is_agree_sms_notification: is_agree_sms_notification || ''
            } as any);

            if (!leadId) {
                addSnackbar('Error creating lead', { variant: 'error' });
                return;
            }

            const lead = leads.getItem(leadId);

            await assignLead(lead.lead_customer_id);
        } catch (error) {
            return;
        }
    };

    const assignLead = async (customerId: number): Promise<void> => {
        const { Api, smsThreads } = store;
        const [response, error] = await handlePromise<SmsThreadResponse>(
            Api.client.post(`sms-threads/assign/${id}`, { lead_customer_id: customerId })
        );

        if (!response || error) {
            addSnackbar('Error assigning lead', { variant: 'error' });
            return;
        }

        const data = response.data;

        smsThreads.replace(data);
        const selectedMessage = smsThreads.values.find(({ id }) => id === data.id)!;

        dispatch({ name: 'setSelectedThread', payload: selectedMessage });
        setCreateLeadModalOpen(false);
    };

    const sendSmsMessage = async (
        { newMessage }: NewMessageFormValues,
        formikHelpers: FormikHelpers<NewMessageFormValues>
    ) => {
        const { sendSms, activeCompanyId } = store;
        const response = await sendSms(activeCompanyId, host_number, client_number, newMessage);

        if (!response.data.id) {
            addSnackbar(`System couldn't send the message.`, { variant: 'error' });
            return;
        }

        fetchMessagesInThread();
        updateThreads();
        formikHelpers.resetForm();
    };

    const exportThread = async () => {
        try {
            const csvFile = await store.Api.getCsvThreadExport(id);

            if (!csvFile) {
                addSnackbar(`System couldn't export the message thread.`, { variant: 'error' });
                return;
            }

            saveBlobFromCsvData(csvFile.data, 'sms-tread.csv');
        } catch (error) {
            return;
        }
    };

    const updateThread = async (command: UpdateThreadCommand) => {
        const { activeCompanyId, smsThreads, calcUnreadSmsMessages } = store;
        const commandOutput = commandOutputs(id, activeCompanyId, command);

        if (!commandOutput) {
            return;
        }

        await smsThreads.update(commandOutput.data);

        const updatedThread = { ...state.selectedThread, ...commandOutput.data } as SmsThread;
        dispatch({ name: 'setSelectedThread', payload: updatedThread });

        if (commandOutput.success) {
            addSnackbar(commandOutput.success, { variant: 'success' });
        }

        if (['archive', 'inbox'].includes(command)) {
            clearThread(dispatch);
            toggleMessagePanel(dispatch, state, false);
        }

        calcUnreadSmsMessages();
    };

    const fetchMessagesInThread = async () => {
        dispatch({ name: 'setIsLoading', payload: true });
        toggleMessagePanel(dispatch, state, true);

        // Allow panel open animation to complete before dispatch to avoid jitter.
        setTimeout(
            async () => {
                await store.fetchSms(undefined, id);
                if (state.selectedThread && !state.selectedThread?.marked_read) {
                    updateThread('read'); // Mark as read when thread is opened
                }

                dispatch({ name: 'setIsLoading', payload: false });
            },
            isMinScreenSize('lg') ? 0 : 300
        );
    };

    const goToLeadDetails = async () => {
        const thread = state.selectedThread!;
        const { activeCompanyId, agencyStore, router, setActiveCompanyId } = store;

        if (thread.company_id !== activeCompanyId) {
            setActiveCompanyId(thread.company_id);
        }

        const LeadDetailsRoute = getRouteWithContext('LeadDetailsByCustomer', router);

        router.goTo(
            LeadDetailsRoute,
            {
                agencyId: agencyStore.activeAgencyId,
                companyId: activeCompanyId,
                customerId: customer?.id,
                messageId: thread.id,
                backRoute: router.currentView
            },
            store
        );
    };

    useAsyncEffect(fetchMessagesInThread, undefined, [id]);

    const handleBackClick = () => {
        toggleMessagePanel(dispatch, state, false);
        setTimeout(() => clearThread(dispatch), 200);
    };

    const menuItems: MenuItems = [
        {
            name: 'menu-item',
            children: customer ? 'Lead details' : 'Create lead',
            className: 'thread-actions-menu-item thread-actions-menu-item-lead',
            startIcon: <Icon name={customer ? 'details' : 'addUser'} />,
            onClick: () => {
                if (customer) {
                    return goToLeadDetails();
                }

                return setCreateLeadModalOpen(true);
            },
            'aria-label': customer ? 'Go to lead details' : 'Add new lead'
        },
        {
            name: 'menu-item',
            children: archived ? 'Move to inbox' : 'Move to archive',
            className: 'thread-actions-menu-item thread-actions-menu-item-archive',
            startIcon: <Icon name={archived ? 'inbox' : 'archive'} />,
            onClick: () => updateThread(archived ? 'inbox' : 'archive'),
            'aria-label': archived ? 'Move to inbox' : 'Move to archive'
        },
        {
            name: 'menu-item',
            children: marked_read ? 'Mark unread' : 'Mark read',
            className: 'thread-actions-menu-item thread-actions-menu-item-unread',
            startIcon: <Icon name="messageUnread" />,
            onClick: () => updateThread(marked_read ? 'unread' : 'read'),
            'aria-label': marked_read ? 'Mark message unread' : 'Mark message read'
        },
        {
            name: 'menu-item',
            children: 'Download message',
            className: 'thread-actions-menu-item thread-actions-menu-item-download',
            startIcon: <Icon name="download" />,
            onClick: exportThread,
            'aria-label': 'Download current message thread'
        }
    ];

    return (
        <>
            <div className="thread-header">
                <div className="thread-header-back">
                    <IconButton icon="arrowLeft" onClick={handleBackClick} />
                </div>

                <div className={classNames('thread-header-customer', { 'no-name': !customer })}>
                    <Avatar className="thread-header-customer-avatar">
                        {customer ? getCustomerInitials(customer) : <Icon name="user" />}
                    </Avatar>
                    {customer && <div className="thread-header-customer-name">{getCustomerName(customer)}</div>}
                    <div className="thread-header-customer-phone">{phoneFormatter(client_number)}</div>
                </div>

                <ActionList position="end">
                    {!customer && (
                        <ButtonPrimary
                            onClick={() => setCreateLeadModalOpen(true)}
                            className="thread-create-lead-button"
                            icon={<Icon name="addUser" />}
                            aria-label="Create Lead"
                        >
                            Create Lead
                        </ButtonPrimary>
                    )}

                    {customer && (
                        <div className="thread-actions-item">
                            <Tooltip title="Lead Details">
                                <IconButton icon="details" onClick={goToLeadDetails} aria-label="Go to lead details" />
                            </Tooltip>
                        </div>
                    )}

                    <div className="thread-actions-item">
                        <Tooltip title={archived ? 'Move to inbox' : 'Move to archive'}>
                            <IconButton
                                icon={archived ? 'inbox' : 'archive'}
                                onClick={() => updateThread(archived ? 'inbox' : 'archive')}
                                aria-label={archived ? 'Move to inbox' : 'Move to archive'}
                            />
                        </Tooltip>
                    </div>

                    <div>
                        <Menu
                            className="thread-actions-menu"
                            align="end"
                            menuButton={
                                <div>
                                    <IconButton icon="ellipsis" aria-label="Open dropdown menu" />
                                </div>
                            }
                            menuItems={menuItems}
                        />
                    </div>
                </ActionList>
            </div>

            <div className={classNames('new-messages', { 'no-consent': !canReceiveSms })}>
                {!state.isLoading && !canReceiveSms && (
                    <div className="no-consent-warning">
                        <Icon name="phoneWarning" />
                        <div>Lead has not confirmed messaging consent.</div>
                    </div>
                )}

                {state.isLoading ? (
                    <div className="thread-loading">
                        <CircularProgress size="48px" />
                        <p>Loading messages...</p>
                    </div>
                ) : (
                    <MessagesConversation messages={store.sms.values.filter(s => s.thread_id === id)} />
                )}
            </div>

            <Form
                initialValues={initialNewMessageValues}
                validationSchema={newMessageFormSchema}
                onSubmit={sendSmsMessage}
            >
                {formikProps => (
                    <div className="compose-message-form">
                        <InputText
                            name="newMessage"
                            placeholder={canReceiveSms ? 'Type message...' : 'Messaging disabled'}
                            formikProps={formikProps}
                            disabled={!canReceiveSms}
                        />
                        <ButtonPrimary
                            type="submit"
                            disabled={!formikProps.dirty || formikProps.isSubmitting}
                            className={classNames('compose-message-form-send-btn', { 'no-consent': !canReceiveSms })}
                        >
                            {formikProps.isSubmitting ? 'Sending' : 'Send'}
                        </ButtonPrimary>
                    </div>
                )}
            </Form>

            <Modal isOpen={isCreateLeadModalOpen} onAfterClose={() => setCreateLeadModalOpen(false)}>
                <ModalHeader title="Create Lead" />

                <Form initialValues={initialFormValues} validationSchema={messagesFormSchema} onSubmit={createLead}>
                    {formikProps => (
                        <>
                            <div className="messages-modal-name-fields">
                                <InputText label="First Name" name="firstName" formikProps={formikProps} />
                                <InputText label="Last Name" name="lastName" formikProps={formikProps} />
                            </div>

                            <InputText label="Email" name="email" formikProps={formikProps} />
                            <InputText label="Phone Number" name="phone" formikProps={formikProps} disabled={true} />

                            <ModalActions className="messages-modal-actions">
                                <Button
                                    onClick={() => {
                                        formikProps.resetForm();
                                        setCreateLeadModalOpen(false);
                                    }}
                                    disabled={formikProps.isSubmitting}
                                >
                                    Cancel
                                </Button>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Creating...' : 'Create'}
                                </ButtonPrimary>
                            </ModalActions>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default MessageThread;
