import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { SideNavMenu } from '../../SideNavMenu';
import { SideNavMenuItem } from '../../SideNavMenuItem';
import DefaultRoutes from '../../../../../routes/DefaultRoutes';
import useStore from '../../../../../store/useStore';
import { getSupportUrl } from '../../SideNav.helpers';

export interface MenuChatProps {}

export const MenuChat: FC<MenuChatProps> = observer(props => {
    const { store } = useStore();
    const { hasAccess } = store;

    return (
        <SideNavMenu>
            {hasAccess('live_chat.view') && (
                <SideNavMenuItem
                    name="liveChat"
                    label="Live Chat"
                    icon="chat"
                    route={DefaultRoutes.LiveChatManualLeadSubmission}
                />
            )}

            <li className="side-nav-divider" />

            <SideNavMenuItem
                name="support"
                label="Support"
                icon="support"
                onClick={() => window.open(getSupportUrl(store), '_blank')}
            />
        </SideNavMenu>
    );
});
