import React, { FC, useEffect } from 'react';
import { Drawer, IconButton, useDrawer } from '@lambdacurry/component-library';
import { observer } from 'mobx-react';
import { ActionList, AppSectionHeader } from '../../components-v2/shared';
import './help-drawer.scss';
import { contextHelpBaseURL } from '../../constants';

export const HelpDrawerName = 'HelpDrawer';

export interface HelpDrawerData { }

export interface HelpSettings {
    title: string;
    embedFile: string; // relative to gs://static.clxp.us/tcc/context-help
}

export const HelpDrawer: FC<{
    settings: HelpSettings;
}> = observer(({ settings }) => {
    const {
        drawerActions: { toggleDrawer, setDrawerData }
    } = useDrawer();

    useEffect(() => {
        setDrawerData({ name: 'help-drawer', data: {} });
    }, []);

    return (
        <Drawer className="settings-drawer" name={HelpDrawerName} options={{ width: 400 }}>
            <AppSectionHeader title={settings.title}>
                <ActionList position='end'>
                    <IconButton
                        icon="close"
                        onClick={() => toggleDrawer({ name: HelpDrawerName })}
                    />
                </ActionList>
            </AppSectionHeader>
            <embed style={{ fontFamily: 'sans-serif', height: '100%', margin: 2 }} src={`${contextHelpBaseURL}${settings.embedFile}`} />
        </Drawer>
    );
});
