import React, { ChangeEvent, Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface ImageUploadProps {
    imagePreviewUrl: any;
    background: string;
    onChange: (value: string) => void;
    onError: (message: string) => void;
    validate?: (img: HTMLImageElement) => boolean;
    enabled?: boolean;
    maxWidth?: number;
    maxHeight?: number;
}

export class ImageUpload extends Component<ImageUploadProps> {
    public input: React.RefObject<HTMLInputElement> = React.createRef();

    public render() {
        return (
            <div className="image-cropper col-md-6 col-xs-12">
                <div className="widget-holder-image">
                    <button className="wrap-widget-hold" style={{ background: this.props.background }}>
                        <div className="box-widget">
                            {this.props.imagePreviewUrl ? <img src={this.props.imagePreviewUrl} alt="" /> : null}
                        </div>
                    </button>
                </div>
                <div className="holder-crop">
                    <div className="image-cropper-sizes">
                        <span>Logo size: {this.renderHelpIcon()}</span>
                        <span>Width: 24px</span>
                        <span>Height: 24px</span>
                    </div>
                    <div className={`modal-crop-input btn-choose ${this.props.enabled ? '' : 'disabled'}`}>
                        <label htmlFor="image_uploads">Choose File</label>
                        <input
                            id="image_uploads"
                            type="file"
                            accept="image/x-png,image/jpg,image/jpeg,image/svg"
                            disabled={!this.props.enabled}
                            onChange={this.handleImageChange}
                        />
                    </div>
                </div>
            </div>
        );
    }

    protected renderHelpIcon = () => {
        const iconOptions = {
            delay: 100,
            delayHide: 1000,
            trigger: 'click',
            placement: 'right'
        };
        return (
            <OverlayTrigger
                {...iconOptions}
                overlay={
                    <Popover id="popover-basic">
                        <div>Maximum Logo Size: 256x256 pixels</div>
                        <div>Logo File Types: .PNG, .JPG, .JPEG, .SVG</div>
                    </Popover>
                }
            >
                <span>
                    <span className="glyphicon glyphicon-question-sign" />
                </span>
            </OverlayTrigger>
        );
    };

    protected validate = (file: File) => {
        const img = new Image();
        const DEFAULT_MAX_SIZE = 256;
        const maxWidth = this.props.maxWidth || DEFAULT_MAX_SIZE;
        const maxHeight = this.props.maxHeight || DEFAULT_MAX_SIZE;

        img.src = window.URL.createObjectURL(file);
        return new Promise((resolve, reject) => {
            img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;
                window.URL.revokeObjectURL(img.src);

                if (width > maxWidth) {
                    reject('Invalid image size. Width too big.');
                } else if (height > maxHeight) {
                    reject('Invalid image size. Height too big.');
                } else if (this.props.validate && !this.props.validate(img)) {
                    reject('Invalid image.');
                } else {
                    resolve(true);
                }
            };
        });
    };

    protected onError = (message: string) => {
        if (this.input.current) {
            this.input.current.value = '';
        }

        this.props.onError(message);
    };

    protected handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const reader = new FileReader();

        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];
        return this.validate(file)
            .then(() => {
                reader.onloadend = () => {
                    const imagePreviewUrl = String(reader.result);
                    this.props.onChange(imagePreviewUrl);
                };

                reader.readAsDataURL(file);
            })
            .catch(this.onError);
    };
}

export default ImageUpload;
