import {
    ButtonUnstyled,
    IconButton,
    useDrawer,
    ButtonOutline,
    Icon,
    Menu,
    MenuItems
} from '@lambdacurry/component-library';
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useContext } from 'react';
import { Avatar } from '../../../components-v2/shared';
import { dirBasename, tccLogo } from '../../../constants';
import { getRouteWithContext } from '../../../routes/routeUtils';
import useStore from '../../../store/useStore';
import { getFileUrl } from '../../../util/file';
import { LayoutContext } from '../LayoutWrapper';
import { goToAgency, goToCompany, goToHome } from '../SideNav/SideNav.helpers';

import './top-bar.scss';

export interface TopBarProps {
    className?: string;
}

export const TopBar: FC<TopBarProps> = observer(({ className }) => {
    const { loading, activeUser, activeAgencyId, activeCompanyId, activeAgency, agencyCustomization } = useContext(
        LayoutContext
    );
    const {
        drawerState,
        drawerActions: { toggleDrawer }
    } = useDrawer();
    const { store } = useStore();
    const { router, hasAccess, logout } = store;

    const params: { [x: string]: any } = { ...router.params, companyId: activeCompanyId };
    if (!params.companyId) {
        params.agencyId = activeAgencyId;
    }

    const goHome = () => {
        if (activeCompanyId) {
            return goToCompany(activeCompanyId, store);
        }

        if (activeAgencyId) {
            return goToAgency(activeAgencyId, store);
        }

        return goToHome(store);
    };

    const defaultLogo = `${dirBasename}/images/${tccLogo}`;

    const menuItems: MenuItems = [
        { className: 'top-bar-menu-item', name: 'menu-item', children: 'Logout', onClick: logout }
    ];

    if (activeUser && hasAccess('users.view')) {
        menuItems.unshift({
            className: 'top-bar-menu-item',
            name: 'menu-item',
            children: 'Edit Profile',
            onClick: () => {
                const userEditRoute = getRouteWithContext('UserEdit', router);
                router.goTo(userEditRoute, { ...router.params, id: activeUser.id }, store);
            }
        });
    }

    return (
        <nav className={classNames('top-bar', className)}>
            <IconButton
                className="top-bar-menu-button"
                icon={drawerState.active === 'side-nav-drawer' ? 'close' : 'hamburger'}
                onClick={() => toggleDrawer({ name: 'side-nav-drawer' })}
            />

            <ButtonUnstyled onClick={goHome} className="top-bar-branding">
                {!activeAgency && <img className="top-bar-logo" src={defaultLogo} alt="The Conversion Cloud" />}

                {activeAgency && !activeAgency?.logo && !agencyCustomization.agency_logo && (
                    <div className="top-bar-agency-name">
                        <Icon name="partner" /> {activeAgency?.name}
                    </div>
                )}

                {activeAgency && (activeAgency?.logo || agencyCustomization?.agency_logo) && (
                    <img
                        className="top-bar-logo"
                        src={getFileUrl(activeAgency?.logo || agencyCustomization?.agency_logo)}
                        alt={activeAgency?.name ?? 'logo'}
                        onClick={goHome}
                    />
                )}
            </ButtonUnstyled>

            {loading && (
                <div className="top-bar-loading">
                    <CircularProgress size="16px" />
                </div>
            )}

            <a href="#main-content" id="skip-to-main-button">
                <ButtonUnstyled aria-label="Skip to main content">Skip to main</ButtonUnstyled>
            </a>

            <div className="flex-spacer" />

            {activeUser && (
                <Menu
                    align="end"
                    className="top-bar-menu"
                    menuButton={
                        <ButtonOutline
                            className="top-bar-profile"
                            icon={<Icon name="chevronDown" />}
                            iconPlacement="end"
                        >
                            <Avatar>
                                {`${(activeUser.first_name || '').charAt(0).toUpperCase()}${(activeUser.last_name || '')
                                    ?.charAt(0)
                                    .toUpperCase()}`}
                            </Avatar>
                            <span className="top-bar-profile-name">{activeUser.first_name}</span>
                        </ButtonOutline>
                    }
                    menuItems={menuItems}
                />
            )}
        </nav>
    );
});
