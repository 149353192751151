import React, { FC } from 'react';
import useStore from '../../../store/useStore';

export interface ClarityDataGridButtonLinkProps {
    buttonText: string;
    route: Route;
    params: any;
}

export const ClarityDataGridButtonLink: FC<ClarityDataGridButtonLinkProps> = ({ buttonText, route, params }) => {
    const { store } = useStore();
    const { router } = store;
    const go = () => {
        router.goTo(route, params);
    };
    return (
        <>
            <span style={{ textDecorationLine: 'underline', cursor: 'grab' }} onClick={go}>
                {buttonText}
            </span>
        </>
    );
};
