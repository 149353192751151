import React, { FC } from 'react';
import {
    ActionList,
    Button,
    ButtonPrimary,
    Modal,
    ModalActions,
    ModalHeader,
    Form,
    useSnackbar,
    InputText
} from '../../../components-v2/shared';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import useStore from '../../../store/useStore';
import Resource from '../../../store/Resource';
import { Offer } from '../../../types/Offer';
import { ConcessionManagerReducerAction } from '../ConcessionManager.helpers';
import { closeModal, setModalData } from '../ConcessionManager.helpers';
import { EditOfferFormValidationSchema } from '../ConcessionManager.validation';

export interface CopyOfferModalProps {
    isOpen: boolean;
    onAfterDelete?: (offerId: Offer['id']) => void;
    dispatch: React.Dispatch<ConcessionManagerReducerAction>;
    offer: Offer;
    offers: Resource<Offer>;
}

export const CopyOfferModal: FC<CopyOfferModalProps> = ({ isOpen, offer, offers, dispatch }) => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    if (!offer) {
        return null;
    }

    const handleSubmit = async (values: Offer) => {
        try {
            const offerId = await offers.create(values);
            router.goTo(DefaultRoutes.ConcessionManagerEdit, { companyId, offerId }, store);
        } catch (error) {
            console.error(error);
            addSnackbar(`Failed to copy ${values.offer_text} offer.`, { variant: 'error' });
        }
    };

    const handleAfterClose = () => {
        closeModal(dispatch);
        setModalData(dispatch, null);
    };

    const initialValues = {
        ...offer,
        id: 0,
        company_id: parseInt(companyId, 10),
        offer_text: `${offer.offer_text} - Copy`
    };

    return (
        <Modal id="copyOffer" isOpen={isOpen} onAfterClose={handleAfterClose}>
            <ModalHeader title="Copy Offer" />
            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={EditOfferFormValidationSchema}
            >
                {formikProps => {
                    return (
                        <>
                            <InputText
                                name="offer_text"
                                label="Title"
                                formikProps={formikProps}
                                inputProps={{ maxLength: 25 }}
                            />
                            <ModalActions>
                                <ActionList position="end">
                                    <Button 
                                        onClick={() => closeModal(dispatch)}
                                        data-test-id="button_cm_copy_offer_modal_cancel"
                                    >
                                        Cancel
                                    </Button>
                                    <ButtonPrimary 
                                        type="submit"
                                        data-test-id="button_cm_copy_offer_modal_create_offer"
                                    >
                                        Create Offer
                                    </ButtonPrimary>
                                </ActionList>
                            </ModalActions>
                        </>
                    );
                }}
            </Form>
        </Modal>
    );
};
