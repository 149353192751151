import { FormikHelpers, Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import { RemoteCalendarData } from '../../types';
import RemoteCalendarForm from './RemoteCalendarForm';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { remoteAuthTypeNames } from '../../constants';
import ControlButtonGroup from '../misc/ControlButtonGroup';
import RCAFactory, { RemoteCalendarAdapter } from './Adapters/RemoteCalendarAdapter';
import useStore from '../../store/useStore';
import { useAsyncEffect } from '@lambdacurry/component-library';
import { useSnackbar } from '../../components-v2/shared';

const resState = {
    ok: 200,
    error: 400
};

const emptyRemoteCalendarData = {
    id: 0,
    subscription_guid: '',
    calendar_guid: '',
    remoteCalendarList: [],
    availableAuthKeys: [],
    state: {
        load_complete: false,
        needs_auth_key: true,
        needs_key_assignment: true,
        needs_calendar_assignment: true,
        subscription_enabled: false
    }
};

const RemoteCalendar = observer(() => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const params = store.router.params;
    const calendarId = params.calendar_id
        ? parseInt(params.calendar_id, 10)
        : params.calendarId
            ? parseInt(params.calendarId, 10)
            : 0;
    const scheduleId = params.schedule_id
        ? parseInt(params.scheduleId, 10)
        : params.scheduleId
            ? parseInt(params.scheduleId, 10)
            : 0;
    const initialValues = () => {
        const { isLoading, remoteCalendarData } = store;
        if (isLoading || !remoteCalendarData) {
            return emptyRemoteCalendarData;
        }

        return remoteCalendarData;
    };

    let rca: RemoteCalendarAdapter;

    const getRemoteCalendarAdapter = () => {
        if (!rca) {
            rca = RCAFactory.getAdapter(store.Api, {
                scheduleId: scheduleId,
                calendarId: calendarId
            });
        }
        return rca;
    };
    const fetchRemoteCalendarData = async () => {
        const { Api, setRemoteCalendarData } = store;
        const activeCompany = store.activeCompanyId;
        const remoteCalendarData: RemoteCalendarData = { ...emptyRemoteCalendarData };

        try {
            const data = await getRemoteCalendarAdapter().getExternalCalendar();
            if (data && data.status === resState.ok) {
                remoteCalendarData.id = data.data.id;
                remoteCalendarData.subscription_guid = data.data.subscription_guid;
                remoteCalendarData.connected_calendar = {
                    guid: data.data.calendar_guid,
                    name: data.data.name
                };
                remoteCalendarData.externalAuth = {
                    id: data.data.external_auth_id,
                    key: data.data.externalAuth.key,
                    name: data.data.externalAuth.name,
                    type: remoteAuthTypeNames[data.data.externalAuth.type_id]
                };
            }

            // const remoteCalendars = await this.getRemoteCalendarAdapter().fetchRemoteCalendars();
            const remoteCalendars = await Api.getRemoteCalendars(remoteCalendarData.id)!;
            remoteCalendarData.remoteCalendarList = remoteCalendars.map((calendar: any) => {
                return { id: calendar.id, name: calendar.name, inUse: calendar.inUse };
            });
        } catch (e) {
            addSnackbar(e.message);
            // Calendar does not yet exist
            remoteCalendarData.id = emptyRemoteCalendarData.id;
            remoteCalendarData.subscription_guid = '';
            remoteCalendarData.connected_calendar = undefined;
            remoteCalendarData.remoteCalendarList = [];
        }

        try {
            const availableAuthKeys = await Api.getExternalCalendarAuthTokens(activeCompany)!;

            remoteCalendarData.availableAuthKeys = availableAuthKeys.map((key: any) => {
                return { id: key.id, name: key.name, type: remoteAuthTypeNames[key.type_id] };
            });
        } catch (e) {
            addSnackbar(e.message);
            // No auth keys available
        }

        setRemoteCalendarData(deriveRemoteCalendarState(remoteCalendarData));
    };
    useAsyncEffect(fetchRemoteCalendarData);

    const handleVoid = () => {
        // do nothing
    };

    const handleSubmit = (values: RemoteCalendarData, actions: FormikHelpers<RemoteCalendarData>) => {
        // do nothing
    };

    const handleConnectCalendar = async (name: string, guid: string) => {
        const { Api, remoteCalendarData } = store;
        await Api.updateExternalCalendar(remoteCalendarData.id, { name, calendar_guid: guid })!;
        await fetchRemoteCalendarData();
    };

    const handleSubscriptionOn = async () => {
        const { Api, remoteCalendarData } = store;
        await Api.remoteCalendarSubscriptionEnable(remoteCalendarData.id);
        await fetchRemoteCalendarData();
    };

    const handleSubscriptionOff = async () => {
        const { Api, remoteCalendarData } = store;
        await Api.remoteCalendarSubscriptionDisable(remoteCalendarData.id);
        await fetchRemoteCalendarData();
    };

    const handleAssignToken = async (tokenId: number) => {
        await getRemoteCalendarAdapter().assignToken(tokenId)!;
        await fetchRemoteCalendarData();
    };

    const handleUnlinkToken = async () => {
        await getRemoteCalendarAdapter().unlinkToken();
        await fetchRemoteCalendarData();
    };

    const handleDisconnectCalendar = async () => {
        const { Api, remoteCalendarData } = store;
        if (remoteCalendarData.subscription_guid) {
            await Api.remoteCalendarSubscriptionDisable(remoteCalendarData.id);
        }
        await Api.updateExternalCalendar(remoteCalendarData.id, { name: '', calendar_guid: '' })!;
        await fetchRemoteCalendarData();
    };

    const goToRemoteAuthList = () => {
        const { activeCompanyId, router } = store;
        const routerParams = {
            ...router.params,
            companyId: activeCompanyId,
            backRoute: router.currentView
        };

        router.goTo(DefaultRoutes.RemoteAuthList, routerParams, store);
    };

    const deriveRemoteCalendarState = (data: RemoteCalendarData) => {
        data.state.needs_auth_key = !data.availableAuthKeys || data.availableAuthKeys.length === 0;
        data.state.needs_key_assignment = data.id === 0 || !data.externalAuth;
        data.state.needs_calendar_assignment = !data.connected_calendar || !data.connected_calendar.guid;
        data.state.subscription_enabled = !!data.subscription_guid && data.subscription_guid.length > 0;
        data.state.load_complete = true;
        return data;
    };

    const handleBack = () => {
        const { router, activeCompanyId } = store;

        if (!scheduleId) {
            return router.goTo(DefaultRoutes.ScheduleGenieAppPage, { companyId: activeCompanyId }, store);
        }

        return router.goTo(
            DefaultRoutes.ScheduleEditorEditSchedulePage,
            { companyId: activeCompanyId, scheduleId: scheduleId },
            store
        );
    };

    return (
        <Formik initialValues={initialValues()} enableReinitialize onSubmit={handleSubmit}>
            {RemoteCalendarForm({
                store,
                pageTitle: getRemoteCalendarAdapter().title,
                pushDescription: getRemoteCalendarAdapter().pushDescription,
                goToRemoteAuthList: goToRemoteAuthList,
                handleAssignToken: handleAssignToken,
                handleUnlinkToken: handleUnlinkToken,
                handleDisconnectCalendar: handleDisconnectCalendar,
                handleConnectCalendar: handleConnectCalendar,
                handleSubscriptionOn: handleSubscriptionOn,
                handleSubscriptionOff: handleSubscriptionOff,
                controls: (bag: FormikProps<RemoteCalendarData>) => (
                    <ControlButtonGroup
                        editing={false}
                        canEdit={false}
                        canDelete={false}
                        hideEdit={true}
                        handleEdit={handleVoid}
                        handleBack={handleBack}
                        handleCancel={handleVoid}
                        handleDelete={handleVoid}
                    />
                )
            })}
        </Formik>
    );
});

export default RemoteCalendar;
