import { Formik, FormikHelpers, FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import Store from '../../store';
import { emptyPropertyManagementCompany } from '../../store/initial';
import { InjectedProps } from '../../types';
import ControlButtonSave from '../misc/ControlButtonSave';
import PropertyManagementCompanyForm, { IPropertyManagementCompany } from './PropertyManagementCompanyForm';
import React, { Fragment } from 'react';
import {
    AppContent,
    AppHeader,
    AppPage,
} from '../../components-v2/shared';
import DefaultRoutes from '../../routes/DefaultRoutes';

interface PropertyManagementCompanyNewProps {
    store?: Store;
}

@inject('store')
@observer
export class PropertyManagementCompanyNew extends React.Component<PropertyManagementCompanyNewProps> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public initialValues = () => ({ ...emptyPropertyManagementCompany });

    public render() {

        const onBackButtonHandler = () => {
            const { router } = this.injected.store;
            router.goTo(DefaultRoutes.PropertyManagementCompanyList, { ...router.params }, this.injected.store);
        }

        return (
            <AppPage>
                <AppHeader onBack={() => onBackButtonHandler()} title="Property Management Company: Add new"> </AppHeader>
                <AppContent>
                    <Fragment>
                        <div className="col-lg-12">
                            <Formik
                                initialValues={{
                                    name: ''
                                }}
                                enableReinitialize
                                onSubmit={this.handleSubmit}
                            >{this.renderForm()}</Formik>
                        </div>
                    </Fragment>
                </AppContent>
            </AppPage>
        );
    }

    public renderForm = () => {
        return PropertyManagementCompanyForm({
            editing: true,
            controls: (bag: FormikProps<IPropertyManagementCompany>) => <ControlButtonSave />
        });
    };

    public handleSubmit = (values: any, actions: FormikHelpers<any>) => {
        actions.setSubmitting(true);
        const { PropertyManagementCompany, router } = this.injected.store;
        return PropertyManagementCompany.create(values)
            .then(() => {
                actions.resetForm();
                router.goTo(DefaultRoutes.PropertyManagementCompanyList, { ...router.params }, this.injected.store);
            })
            .catch(error => actions.setErrors(error.response.data))
            .finally(() => {
                actions.setSubmitting(false);
            })
    };
}

export default PropertyManagementCompanyNew;
