import { dirBasename } from '../../constants';

export const notAuthorizedRoutes = [
    `${dirBasename}/login`,
    `${dirBasename}/clarity-viewer`,
    `${dirBasename}/reset-password`,
    `${dirBasename}/change-password/:hash`,
    `${dirBasename}/company/:token/invite`,
    `${dirBasename}/user/:token/invite`,
    `${dirBasename}/reset-your-password`
];

export const fullPageRoutes = [`${dirBasename}/company/:token/invite`, `${dirBasename}/user/:token/invite`];

export const isUnauthorizedView = (router: RouterStore) =>
    router && router.currentView
        ? !!notAuthorizedRoutes.find(path => router.currentView.originalPath.includes(path))
        : !!notAuthorizedRoutes.find(path => router.currentPath.includes(path));

export const isFullPageView = (router: RouterStore): boolean =>
    router && router.currentView
        ? !!fullPageRoutes.find(path => router.currentView.originalPath.includes(path))
        : !!fullPageRoutes.find(path => router.currentPath.includes(path));
