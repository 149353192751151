import { observer } from 'mobx-react';
import React, { ChangeEvent, Dispatch, FC, useCallback, useState } from 'react';

import { AppCard, AppListEmpty, AppListLoading, InputText } from '../../components-v2/shared';
import {
    handlePromise,
    Button,
    ButtonPrimary,
    Icon,
    Modal,
    ModalActions,
    ModalHeader,
    InputSelect,
    PaginationPrimary,
    useAsyncEffect
} from '@lambdacurry/component-library';
import useStore from '../../store/useStore';
import { ActionList } from '../../components-v2/shared';
import { FormikProps } from 'formik';
import { ClaritySchedulerForm } from './ScheduleReportModal/ScheduleReportModal.helpers';
import { ScheduledReportsList } from './ScheduledReportsList';
import { clarityPaginationOptions, ClarityReducerAction, ClarityReducerState } from './Clarity.helpers';
import { ClarityCreateReportConfig } from './Clarity.types';
import { ReactComponent as EmptyReportsIllustration } from './EmptyReportsIllustration.svg';

import './clarity-reports.scss';
import { debounce } from 'lodash';
export interface ClaritySchedulerProps {
    state: ClarityReducerState;
    dispatch: Dispatch<ClarityReducerAction>;
    schedulerFormikProps: FormikProps<ClaritySchedulerForm>;
    createReport: (config: ClarityCreateReportConfig) => Promise<void>;
    handleScheduleNewReport: () => void;
    fetchClarityScheduledReports: (pageNumber?: number, newLimit?: number, search?: string) => Promise<void>;
}

export const ScheduledReports: FC<ClaritySchedulerProps> = observer(props => {
    const { state, dispatch, handleScheduleNewReport, fetchClarityScheduledReports } = props;
    const { loading, page, limit, items } = state.scheduledReportsTab;
    const { Api, isCompanyLightRole } = useStore().store;

    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');

    useAsyncEffect(
        async () => {
            await fetchClarityScheduledReports(page, limit, searchValue);
        },
        undefined,
        [page, limit]
    );

    const debounceSearch = useCallback(
        debounce(async (inputValue: string) => {
            await fetchClarityScheduledReports(page, limit, inputValue);
        }, 1000),
        []
    );

    const handleDelete = async (schedulerId: number) => {
        const [response, error] = await handlePromise<{}>(Api.client.delete(`/clarity/scheduler/${schedulerId}`));

        if (!response) {
            alert(JSON.stringify(error));
            return;
        }

        await fetchClarityScheduledReports();
    };

    const handlePageChange = (event: ChangeEvent<any>, page: number) =>
        dispatch({ name: 'setScheduledReportsTabPage', payload: page });

    const handleLimitChange = (event: ChangeEvent<any>, { value }: { value: number }) => {
        dispatch({ name: 'setScheduledReportsTabPage', payload: 1 });
        dispatch({ name: 'setScheduledReportsTabLimit', payload: value });
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        debounceSearch(event.target.value);
    };

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
        if (e.key === 'Enter') {
            debounceSearch.cancel();
            await fetchClarityScheduledReports(page, limit, searchValue);
        }
    };

    return (
        <div>
            <>
                <AppCard>
                    {loading && <AppListLoading title="Retrieving scheduled reports" />}

                    <div className="clarity-search-reports">
                        <InputText
                            name="searchValue"
                            value={searchValue}
                            onChange={handleInputChange}
                            prefix={<Icon name="search" />}
                            onKeyDown={handleKeyDown}
                            placeholder="Search report name..."
                        />
                    </div>

                    {!loading && items && (
                        <>
                            {items.totalRecords < 1 && (
                                <AppListEmpty
                                    illustration={<EmptyReportsIllustration />}
                                    title="No scheduled reports"
                                    description="Schedule reports auto-generate based on custom parameters and timelines."
                                    action={
                                        <>
                                            {!isCompanyLightRole && (
                                                <ButtonPrimary
                                                    icon={<Icon name="schedule" />}
                                                    onClick={() => handleScheduleNewReport()}
                                                >
                                                    Schedule a Report
                                                </ButtonPrimary>
                                            )}
                                        </>
                                    }
                                />
                            )}

                            {items.totalRecords > 0 && (
                                <>
                                    <ScheduledReportsList
                                        {...props}
                                        setSelectedId={setSelectedId}
                                        setDeleteModalOpen={setDeleteModalOpen}
                                    />

                                    <div className="pagination-controls clx-margin-top-16">
                                        <InputSelect
                                            name="rowCount"
                                            label="Rows"
                                            options={clarityPaginationOptions}
                                            value={{ label: `${limit}`, value: limit }}
                                            onChange={handleLimitChange}
                                        />

                                        <PaginationPrimary
                                            pagesCount={Math.ceil(items.totalRecords / limit)}
                                            page={page}
                                            onChange={handlePageChange}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </AppCard>

                <Modal isOpen={deleteModalOpen} closeButton={false}>
                    <ModalHeader
                        title={
                            <>
                                <Icon name="error" className="color-danger" />
                                Are you sure you want to delete this report?
                            </>
                        }
                    />
                    <p>If you delete this report, you will not be able to retrieve it.</p>
                    <ModalActions>
                        <ActionList position="end">
                            <Button
                                onClick={() => {
                                    setSelectedId(null);
                                    setDeleteModalOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <ButtonPrimary
                                onClick={() => {
                                    handleDelete(selectedId!);
                                    setSelectedId(null);
                                    setDeleteModalOpen(false);
                                }}
                            >
                                Confirm
                            </ButtonPrimary>
                        </ActionList>
                    </ModalActions>
                </Modal>
            </>
        </div>
    );
});
