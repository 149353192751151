import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    ButtonOutlinePrimary,
    useSnackbar
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../../constants'

export interface AlisFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface AlisCrm extends Crm {
    configuration: {
        webservice_api_username: string;
        webservice_api_password: string;
        community_id: string;
    };
}

const numberCheck = /^[0-9]+$/;

export const AlisForm: FC<AlisFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;
    const { addSnackbar } = useSnackbar();

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<AlisCrm>) => {
        await onValidate(formikProps.values, crmConfigTypes.alis);
    };

    const handleSubmit = async (values: AlisCrm, actions: FormikHelpers<AlisCrm>) => {
        const { webservice_api_username, webservice_api_password, community_id } = values.configuration;

        if (!community_id.match(numberCheck)) {
            addSnackbar(`Community Id should be number only.`, { variant: 'error' });
            return;
        }

        if (!webservice_api_username || !webservice_api_password || !community_id) {
            addSnackbar(`Please provide values to all fields.`, { variant: 'error' });
            return
        }
        await onSubmit(values, actions, crmConfigTypes.alis);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<AlisCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Alis" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 64 }}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader
                                    title="Web Services API"
                                    subtitle="Contact Alis support for your exact connection settings"
                                />
                                <InputText
                                    name="configuration.webservice_api_username"
                                    label="API Username"
                                    helperText="Alis API Username"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.webservice_api_password"
                                    label="API Password"
                                    helperText="Alis API Password"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.community_id"
                                    label="API Community ID"
                                    helperText="Alis API Community ID"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                        </AppSection>

                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
