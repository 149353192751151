import * as Yup from 'yup';
import { QuestionBundleValidator } from '../../types/QuestionSettings';

export const ScheduleGenieFormValidationSchema = () =>
    Yup.object({
        call_to_action: Yup.string()
            .max(40)
            .required('Please enter a call to action.'),
        slot_length: Yup.number().min(0),
        question_bundle: QuestionBundleValidator
    });
