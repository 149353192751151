import { DraggableLocation } from 'react-beautiful-dnd';

export function reOrder(list: string[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

export function move(
    source: string[],
    destination: string[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
) {
    const sourceClone = [...source];
    const destClone = [...destination];
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    if (droppableDestination.droppableId === 'enabled_droppable') {
        destClone.splice(droppableDestination.index, 0, removed);
    } else {
        destClone.splice(droppableSource.index, 1);
    }
    return destClone;
}

export function getItemStyle(isDragging: boolean, draggableStyle: any) {
    return {
        userSelect: 'none',
        padding: 16,
        margin: `0 0 8px 0`,
        color: '#555555',
        background: isDragging ? '#337ab7' : '#ffffff',
        border: '1px solid #cccccc',
        borderRadius: '4px',
        ...draggableStyle
    };
}
