import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import './description-list.scss';

export interface DescriptionListProps extends HTMLAttributes<HTMLDListElement> {
    columns?: 1 | 2 | 3 | 4;
}

export const DescriptionList: FC<DescriptionListProps> = ({ className, columns, ...props }) => (
    <dl
        className={classNames(
            'lc-description-list',
            { [`lc-description-list--columns-${columns}`]: columns },
            className
        )}
        {...props}
    />
);
