import React from 'react';
import * as Yup from 'yup';
import { LeadCustomer } from '../../types';
import { SmsThread } from '../../types/SmsThread';
import { fieldSchemas } from '../../util/validations/formSchemas';

export interface MessagesState {
    isLoading: boolean;
    selectedThread?: SmsThread | null;
    messagePanelActive: boolean;
    selectedTab: number;
}

export const MessagesInitialState: MessagesState = {
    isLoading: false,
    selectedThread: null,
    messagePanelActive: false,
    selectedTab: 0
};

const MessagesReducers = {
    setIsLoading: (state: MessagesState, payload: boolean) => ({
        ...state,
        isLoading: payload
    }),
    setSelectedThread: (state: MessagesState, payload: SmsThread | null) => {
        return { ...state, selectedThread: payload };
    },
    setMessagePanelActive: (state: MessagesState, payload: boolean) => {
        return { ...state, messagePanelActive: payload };
    },
    setSelectedTab: (state: MessagesState, payload: number) => {
        return { ...state, selectedTab: payload };
    }
};

export type MessagesAction =
    | {
          name: 'setIsLoading';
          payload: boolean;
      }
    | {
          name: 'setSelectedThread';
          payload: SmsThread | null;
      }
    | {
          name: 'setMessagePanelActive';
          payload: boolean;
      }
    | {
          name: 'setSelectedTab';
          payload: number;
      };

export const MessagesReducer = (state: MessagesState, action: MessagesAction) => {
    if (!MessagesReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: MessagesState = (MessagesReducers[action.name] as any)(state, action.payload);
    return nextState;
};

export const selectThread = (dispatch: React.Dispatch<MessagesAction>, thread: SmsThread | null) => {
    dispatch({ name: 'setSelectedThread', payload: thread });

    const newHref = window.location.href.replace(
        /\/messages\/?(\d|\?)*/,
        `/messages${thread?.id ? `/${thread.id}` : ''}`
    );
    window.history.replaceState(null, '', newHref);
};

export const clearThread = (dispatch: React.Dispatch<MessagesAction>) => selectThread(dispatch, null);
export interface ToggleMessagePanelOptions {
    beforeToggle: () => void;
    afterToggle: () => void;
    active?: boolean;
}

export const toggleMessagePanel = (
    dispatch: React.Dispatch<MessagesAction>,
    state: MessagesState,
    active?: boolean
) => {
    dispatch({
        name: 'setMessagePanelActive',
        payload: typeof active === 'boolean' ? active : !state.messagePanelActive
    });
};

export const getCustomerInitials = (customer: LeadCustomer): string =>
    `${customer?.first_name[0]}${customer?.last_name[0]}`.toUpperCase();
export const getCustomerName = (customer: LeadCustomer): string => `${customer?.first_name} ${customer?.last_name}`;

export interface NewMessageFormValues {
    newMessage: string;
}

export const newMessageFormSchema = Yup.object().shape({
    newMessage: Yup.string()
        .max(500, 'Maximum message length is 500 characters.')
        .required('Please enter a message.')
});

export interface MessagesFormValues {
    email?: string | null;
    firstName: string;
    lastName: string;
    phone: string;
}

export const messagesFormSchema = Yup.object().shape({
    email: fieldSchemas.email.standard,
    firstName: Yup.string().required('Please enter a first name.'),
    lastName: Yup.string().required('Please enter a last name.'),
    phone: Yup.string().required('Please enter a phone number.')
});

export type UpdateThreadCommand = 'read' | 'unread' | 'archive' | 'inbox';

type CommandOutput = {
    success: string | null;
    data: object;
};

export const commandOutputs = (
    id: number,
    activeCompanyId: number,
    command: UpdateThreadCommand
): CommandOutput | undefined => {
    const outputs = {
        read: {
            success: null,
            data: { id, company_id: activeCompanyId, marked_read: true }
        },
        unread: {
            success: null,
            data: { id, company_id: activeCompanyId, marked_read: false }
        },
        archive: {
            success: 'Message archived',
            data: { id, company_id: activeCompanyId, archived: true }
        },
        inbox: {
            success: 'Message moved to inbox',
            data: { id, company_id: activeCompanyId, archived: false }
        }
    };

    return outputs[command];
};
