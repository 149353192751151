import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { DripEvent, FutureDripEvent, Lead, LeadJourney } from '../../../types';
import { fieldSchemas } from '../../../util/validations/formSchemas';
import { crmConfigTypes } from '../../../constants';

export interface LeadCustomer {
    id: number;
    company_id: number;
    first_name: string;
    last_name: string;
    first_name_secondary?: string | null;
    last_name_secondary?: string | null;
    phone_number: string;
    email: string;
    is_agree_sms_notification: 'yes' | 'no';
    external_id: any;
    relationship: string;
}

export interface LeadCustomerUpdate {
    email: string;
    first_name: string;
    last_name: string;
    first_name_secondary?: string | null;
    last_name_secondary?: string | null;
    phone_number: string;
    relationship: string;
}

export interface LeadMetrics {
    devices: ('iOS' | 'Android' | 'Windows' | 'Mac OS' | 'Linux')[];
    interaction_count: number;
    last_active_date: string; // ISO
    last_location: { city: string; region: string };
}

export interface LeadCustomerResponse {
    data: {
        customer: LeadCustomer;
        metrics: LeadMetrics;
    };
}

export interface LeadCustomerLeadListResponse {
    data: { data: Lead[] };
}

export interface LeadCustomerLeadJourneyResponse {
    data: { data: LeadJourney[] };
}

export interface LeadCustomerDripsResponse {
    data: { data: DripEvent[] };
}

export interface LeadCustomerFutureDripsResponse {
    data: { data: FutureDripEvent[] };
}

export enum CustomerCardStatuses {
    VIEWING = 'viewing',
    EDITING = 'editing'
}

export interface LeadDetailsState {
    customerCardStatus: CustomerCardStatuses;
    customer?: LeadCustomer;
    metrics?: LeadMetrics;
    leads?: Lead[];
    leadJourney?: LeadJourney[];
    drips?: DripEvent[];
    futureDrips?: FutureDripEvent[];
}

export const selectLastLocation = (state: LeadDetailsState) => {
    if (!state.metrics?.last_location) {
        return 'Unknown';
    }
    const city =
        state.metrics?.last_location.city === '?' || state.metrics?.last_location.city === '-'
            ? 'Unknown'
            : state.metrics?.last_location.city;
    const region =
        state.metrics?.last_location.region === '?' || state.metrics?.last_location.region === '-'
            ? 'Unknown'
            : state.metrics?.last_location.region;
    const cityAndRegion = `${city}, ${region}`;
    return cityAndRegion === 'Unknown, Unknown' ? 'Unknown' : cityAndRegion;
};
export const selectSMSOptIn = (state: LeadDetailsState) => state.customer?.is_agree_sms_notification || 'No';
export const selectDeviceIcon = (state: LeadDetailsState) => {
    const devices = state.metrics?.devices;
    if (devices?.includes('Mac OS') || devices?.includes('iOS')) {
        return 'apple';
    }
    if (devices?.includes('Android')) {
        return 'android';
    }
    return 'laptop';
};
export const selectDevices = (state: LeadDetailsState) => {
    const devices = state.metrics?.devices;
    if (!devices?.length) {
        return 'unknown';
    }

    return devices?.reduce((acc, device, index) => {
        if (['Android', 'iOS'].includes(device)) {
            acc = acc + `Mobile • ${device}`;
        } else {
            acc = acc + `Computer • ${device}`;
        }

        if (index !== devices.length - 1) {
            acc = acc + ', ';
        }
        return acc;
    }, '');
};
export const selectInteraction = (state: LeadDetailsState) => {
    const interactionCount = state.metrics?.interaction_count;
    const lastActiveDate = DateTime.fromISO(state.metrics?.last_active_date || '').toFormat('MM/dd/yy');
    return `${interactionCount}${!state.metrics?.last_active_date ? '' : ` • Active ` + lastActiveDate}`;
};

export const selectExternalId = (state: LeadDetailsState) => {
    const externalId = state.customer?.external_id;
    if (!externalId) {
        return;
    }
    return externalId['yardi_sipp'] || externalId['anyone_home'] || externalId['funnel'] || externalId['rentcafe_v2'];
};

export const hasExternalId = (state: LeadDetailsState) => {
    const externalId = state.customer?.external_id;
    if (!externalId) {
        return null;
    }
    if (externalId['yardi_sipp']) {
        return 'Yardi Prospect ID';
    }
    if (externalId['anyone_home']) {
        return 'Anyone Home Prospect ID';
    }
    if (externalId['funnel']) {
        return 'Funnel Prospect ID';
    }
    if (externalId[crmConfigTypes.rentcafe_v2]) {
        return 'RentCafe v2 Prospect ID';
    }
    return null;
};

const LeadDetailsReducers = {
    storeCustomerData: (state: LeadDetailsState, { data: { customer, metrics } }: any) => ({
        ...state,
        customer,
        metrics
    }),
    storeCustomerLeads: (state: LeadDetailsState, { data: { data: leads } }: any) => ({
        ...state,
        leads
    }),
    storeCustomerLeadJourney: (state: LeadDetailsState, { data: { data: leadJourney } }: any) => ({
        ...state,
        leadJourney
    }),
    storeCustomerDrips: (state: LeadDetailsState, { data: { data: drips } }: any) => ({
        ...state,
        drips
    }),
    storeCustomerFutureDrips: (state: LeadDetailsState, { data: { data: futureDrips } }: any) => ({
        ...state,
        futureDrips
    }),
    toggleCustomerCardEditingStatus: (state: LeadDetailsState) => ({
        ...state,
        customerCardStatus:
            state.customerCardStatus !== CustomerCardStatuses.EDITING
                ? CustomerCardStatuses.EDITING
                : CustomerCardStatuses.VIEWING
    }),
    updateLead: (state: LeadDetailsState, payload: Lead) => {
        if (!state.leads) {
            return state;
        }

        const leadIndex = state.leads.findIndex(lead => payload.id === lead.id);

        if (leadIndex > -1) {
            state.leads[leadIndex] = payload;
        }

        return { ...state, leads: [...state.leads] };
    }
};

export type LeadDetailsAction =
    | {
        name: 'storeCustomerData';
        payload: LeadCustomerResponse;
    }
    | { name: 'storeCustomerLeads'; payload: LeadCustomerLeadListResponse }
    | { name: 'storeCustomerLeadJourney'; payload: LeadCustomerLeadJourneyResponse }
    | { name: 'storeCustomerDrips'; payload: LeadCustomerDripsResponse }
    | { name: 'storeCustomerFutureDrips'; payload?: LeadCustomerFutureDripsResponse }
    | { name: 'toggleCustomerCardEditingStatus'; payload?: undefined }
    | { name: 'updateLead'; payload: Lead };

export const LeadDetailsReducer = (state: LeadDetailsState, action: LeadDetailsAction) => {
    if (!LeadDetailsReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }
    const nextState: LeadDetailsState = LeadDetailsReducers[action.name](state, action.payload as any);
    return nextState;
};

export const LeadDetailsCustomerValidationSchema = Yup.object().shape({
    first_name: Yup.string().required(`Please enter a first name.`),
    last_name: Yup.string().required(`Please enter a last name.`),
    email: fieldSchemas.email.standard
});

export const underscoreToCapitalize = (text: string) => {
    let str = text.replace(/_/g, ' ');
    str = str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });
    return str;
}
