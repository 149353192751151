import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { SideNavMenu } from '../../SideNavMenu';
import { SideNavMenuItem } from '../../SideNavMenuItem';
import DefaultRoutes from '../../../../../routes/DefaultRoutes';
import { getSupportUrl } from '../../SideNav.helpers';
import useStore from '../../../../../store/useStore';

export interface MenuCompanyLightProps {}

export const MenuCompanyLight: FC<MenuCompanyLightProps> = observer(props => {
    const { store } = useStore();

    return (
        <SideNavMenu>
            <SideNavMenuItem name="dashboard" icon="home" route={DefaultRoutes.CompanyDashboard} />

            <SideNavMenuItem name="leads" label="Leads" icon="user" route={DefaultRoutes.CompanyLeadView} />

            <li className="side-nav-divider" />

            <SideNavMenuItem
                name="support"
                label="Support"
                icon="support"
                onClick={() => window.open(getSupportUrl(store), '_blank')}
            />
        </SideNavMenu>
    );
});
