export interface RealyncIntegration {
    id: number;
    company_id: number;
    realync_property: { id: string; name: string };
    realync_property_name: string;
    realync_property_id: string;
    realync_video_id: string;
    description: string;
    created_at: string;
    updated_at: string;
    status: 'authenticated' | 'unauthenticated';
}

export interface RealyncVideo {
    id: string;
    title: string;
    duration: string;
    videoURL: string;
    h264StreamURL: string;
    videoImageURL: string;
    mobileVideoImageURL: string;
    createdAt: string;
}

export interface RealyncVideosResponse {
    status: string;
    count?: number;
    pages?: number;
    currentPage?: number;
    records: RealyncVideo[];
}

export interface RealyncProperty {
    id: string;
    name: string;
    sourceId: string;
}

export type RealyncReducerAction =
    | { name: 'setRealync'; payload?: RealyncIntegration }
    | { name: 'setVideos'; payload: RealyncVideosResponse }
    | { name: 'setProperties'; payload: RealyncProperty[] }
    | { name: 'setSelectedPropertyId'; payload: string };

export interface RealyncState {
    realync?: RealyncIntegration;
    properties: RealyncProperty[];
    videos?: RealyncVideosResponse;
    selected_property_id?: string;
}

const RealyncReducers = {
    setRealync: (state: RealyncState, realync?: RealyncIntegration) => ({ ...state, realync }),
    setProperties: (state: RealyncState, properties: RealyncProperty[]) => ({ ...state, properties }),
    setVideos: (state: RealyncState, videos: RealyncVideosResponse) => ({ ...state, videos }),
    setSelectedPropertyId: (state: RealyncState, selected_property_id: string) => ({ ...state, selected_property_id })
};

export const RealyncReducer = (state: RealyncState, action: RealyncReducerAction) => {
    if (!RealyncReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: RealyncState = RealyncReducers[action.name](state, action.payload as any);
    return nextState;
};
