import React from 'react';

interface TogglePasswordProps {
    showHide: boolean
    handleClick: () => void
}

export const ToggleShowPassword = (props: TogglePasswordProps) => {

    const style = {
        cursor: 'pointer'
    };

    return props.showHide ? (
        <svg style={style} onClick={props.handleClick} width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 4.49969C13.76 4.49969 16 6.73969 16 9.49969C16 10.0097 15.9 10.4997 15.76 10.9597L18.82 14.0197C20.21 12.7897 21.31 11.2497 22 9.48969C20.27 5.10969 16 1.99969 11 1.99969C9.73 1.99969 8.51 2.19969 7.36 2.56969L9.53 4.73969C10 4.59969 10.49 4.49969 11 4.49969ZM1.71 1.15969C1.32 1.54969 1.32 2.17969 1.71 2.56969L3.68 4.53969C2.06 5.82969 0.77 7.52969 0 9.49969C1.73 13.8897 6 16.9997 11 16.9997C12.52 16.9997 13.97 16.6997 15.31 16.1797L18.03 18.8997C18.42 19.2897 19.05 19.2897 19.44 18.8997C19.83 18.5097 19.83 17.8797 19.44 17.4897L3.13 1.15969C2.74 0.769688 2.1 0.769688 1.71 1.15969ZM11 14.4997C8.24 14.4997 6 12.2597 6 9.49969C6 8.72969 6.18 7.99969 6.49 7.35969L8.06 8.92969C8.03 9.10969 8 9.29969 8 9.49969C8 11.1597 9.34 12.4997 11 12.4997C11.2 12.4997 11.38 12.4697 11.57 12.4297L13.14 13.9997C12.49 14.3197 11.77 14.4997 11 14.4997ZM13.97 9.16969C13.82 7.76969 12.72 6.67969 11.33 6.52969L13.97 9.16969Z" fill="#0054FE"/>
        </svg>
        ) : (
        <svg style={style} onClick={props.handleClick} width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0ZM11 12.5C8.24 12.5 6 10.26 6 7.5C6 4.74 8.24 2.5 11 2.5C13.76 2.5 16 4.74 16 7.5C16 10.26 13.76 12.5 11 12.5ZM11 4.5C9.34 4.5 8 5.84 8 7.5C8 9.16 9.34 10.5 11 10.5C12.66 10.5 14 9.16 14 7.5C14 5.84 12.66 4.5 11 4.5Z" fill="#B3B3B3"/>
        </svg>
    );
}