import { FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ICompanyRoleForm } from '../CompanyRoleForm';
import { InjectedProps } from '../../../types';

export interface CompanyRoleWidgetProps {
    settings: FormikProps<ICompanyRoleForm>;
    defaultSettings: any;
}

export interface CompanyRoleWidgetState {
    dayHover: number;
}

@inject('store')
@observer
export class CompanyRoleWidgetCalendar extends React.Component<
    CompanyRoleWidgetProps & Partial<InjectedProps>,
    CompanyRoleWidgetState
> {
    public get injected() {
        return this.props as InjectedProps;
    }

    constructor(props: CompanyRoleWidgetProps) {
        super(props);
        this.state = {
            dayHover: 0
        };
    }

    public toggleDayHover = (day: number) => () => {
        this.setState({ dayHover: day });
    };
    public toggleDayLeave = () => {
        this.setState({ dayHover: 0 });
    };

    public render() {
        const { calendars } = this.injected.store;
        const calendar = calendars.values[0];
        const classWidgetPosition = this.props.settings.values.widget_position
            ? `clx-${this.props.settings.values.widget_position}`
            : ``;

        const styleWidgetCalendarTitle = {
            color: this.props.settings.values.color_month || this.props.defaultSettings.color_month
        };

        const styleWidgetCalendar = {
            borderColor:
                this.props.settings.values.color_border_show_hide_button ||
                this.props.defaultSettings.color_border_show_hide_button,
            backgroundColor:
                this.props.settings.values.color_background_default ||
                this.props.defaultSettings.color_background_default
        };

        const styleWidgetCalendar2 = {
            backgroundColor: this.props.settings.values.color_back_month || this.props.defaultSettings.color_back_month
        };
        const colorBackgroundChevron = {
            backgroundColor:
                this.props.settings.values.color_background_chevron ||
                this.props.defaultSettings.color_background_chevron
        };
        const calendarPastDay = {
            color:
                this.props.settings.values.color_calendar_past_day ||
                this.props.defaultSettings.color_calendar_past_day,
            backgroundColor:
                this.props.settings.values.color_back_calendar_past_day ||
                this.props.defaultSettings.color_back_calendar_past_day
        };
        const defaultColor = {
            color: this.props.settings.values.color_default || this.props.defaultSettings.color_default
        };
        const colorActiveDaysNames = {
            color: this.props.settings.values.color_calendar_day || this.props.defaultSettings.color_calendar_day
        };

        const getColorActiveDays = (n: number) => {
            return {
                color: this.props.settings.values.color_calendar_day || this.props.defaultSettings.color_calendar_day,
                backgroundColor:
                    n === this.state.dayHover
                        ? this.props.settings.values.color_back_calendar_day_hover ||
                          this.props.defaultSettings.color_back_calendar_day_hover
                        : 'white'
            };
        };
        const calendarBackground = {
            backgroundColor:
                this.props.settings.values.color_calendar_background ||
                this.props.defaultSettings.color_calendar_background
        };

        return (
            <div className="clx-widget-calendar">
                <div
                    className={`clx-app-wrap clx-calendar-container ${classWidgetPosition}`}
                    style={styleWidgetCalendar}
                >
                    <div>
                        <div className="clx-header-wrap">
                            <i style={defaultColor} className="clxicon clxicon-close" id="clx-close-icon" />
                            <p style={defaultColor} id="clx-schedule-headline">
                                {calendar?.call_to_action}
                            </p>
                            <i style={defaultColor} className="clxicon clxicon-calendar" id="clx-calendar-icon" />
                        </div>
                        <div>
                            <div className="clx-calendar-month" style={styleWidgetCalendar2}>
                                <span style={styleWidgetCalendarTitle}>March 2019</span>
                                <i
                                    style={colorBackgroundChevron}
                                    className="clxicon clxicon-next clx-calendar-month-next"
                                />
                                <i
                                    style={colorBackgroundChevron}
                                    className="clxicon clxicon-previous clx-calendar-month-previous"
                                />
                            </div>
                            <div id="clx-calendar">
                                <div style={calendarBackground} id="clx-calendar-days-container">
                                    <div style={colorActiveDaysNames} className="clx-calendar-weekday-label Sunday">
                                        Su
                                    </div>
                                    <div style={colorActiveDaysNames} className="clx-calendar-weekday-label Monday">
                                        Mo
                                    </div>
                                    <div style={colorActiveDaysNames} className="clx-calendar-weekday-label Tuesday">
                                        Tu
                                    </div>
                                    <div style={colorActiveDaysNames} className="clx-calendar-weekday-label Wednesday">
                                        We
                                    </div>
                                    <div style={colorActiveDaysNames} className="clx-calendar-weekday-label Thursday">
                                        Th
                                    </div>
                                    <div style={colorActiveDaysNames} className="clx-calendar-weekday-label Friday">
                                        Fr
                                    </div>
                                    <div style={colorActiveDaysNames} className="clx-calendar-weekday-label Saturday">
                                        Sa
                                    </div>
                                    <div className="Sunday clx-calendar-day-div clx-calendar-day-empty" />
                                    <div className="Monday clx-calendar-day-div clx-calendar-day-empty" />
                                    <div className="Tuesday clx-calendar-day-div clx-calendar-day-empty" />
                                    <div className="Wednesday clx-calendar-day-div clx-calendar-day-empty" />
                                    <div className="Thursday clx-calendar-day-div clx-calendar-day-empty" />
                                    <div className="Friday clx-calendar-past-day" style={calendarPastDay}>
                                        1
                                    </div>
                                    <div className="Saturday clx-calendar-past-day" style={calendarPastDay}>
                                        2
                                    </div>
                                    <div className="Sunday clx-calendar-past-day" style={calendarPastDay}>
                                        3
                                    </div>
                                    <div className="Monday clx-calendar-past-day" style={calendarPastDay}>
                                        4
                                    </div>
                                    <div className="Tuesday clx-calendar-past-day" style={calendarPastDay}>
                                        5
                                    </div>
                                    <div className="Wednesday clx-calendar-past-day" style={calendarPastDay}>
                                        6
                                    </div>
                                    <div className="Thursday clx-calendar-past-day" style={calendarPastDay}>
                                        7
                                    </div>
                                    <div className="Friday clx-calendar-past-day" style={calendarPastDay}>
                                        8
                                    </div>
                                    <div className="Saturday clx-calendar-past-day" style={calendarPastDay}>
                                        9
                                    </div>
                                    <div className="Sunday clx-calendar-past-day" style={calendarPastDay}>
                                        10
                                    </div>
                                    <div className="Monday clx-calendar-past-day" style={calendarPastDay}>
                                        11
                                    </div>
                                    <div className="Tuesday clx-calendar-past-day" style={calendarPastDay}>
                                        12
                                    </div>
                                    <div className="Wednesday clx-calendar-past-day" style={calendarPastDay}>
                                        13
                                    </div>
                                    <div className="Thursday clx-calendar-past-day" style={calendarPastDay}>
                                        14
                                    </div>
                                    <div className="Friday clx-calendar-past-day" style={calendarPastDay}>
                                        15
                                    </div>
                                    <div className="Saturday clx-calendar-past-day" style={calendarPastDay}>
                                        16
                                    </div>
                                    <div className="Sunday clx-calendar-past-day" style={calendarPastDay}>
                                        17
                                    </div>
                                    <div className="Monday clx-calendar-past-day" style={calendarPastDay}>
                                        18
                                    </div>
                                    <div className="Tuesday clx-calendar-past-day" style={calendarPastDay}>
                                        19
                                    </div>
                                    <div className="Wednesday clx-calendar-past-day" style={calendarPastDay}>
                                        20
                                    </div>
                                    <div className="Thursday clx-calendar-past-day" style={calendarPastDay}>
                                        21
                                    </div>
                                    <div className="Friday clx-calendar-past-day" style={calendarPastDay}>
                                        22
                                    </div>
                                    <div className="Saturday clx-calendar-past-day" style={calendarPastDay}>
                                        23
                                    </div>
                                    <div className="Sunday clx-calendar-past-day" style={calendarPastDay}>
                                        24
                                    </div>
                                    <div className="Monday clx-calendar-past-day" style={calendarPastDay}>
                                        25
                                    </div>
                                    <div
                                        style={getColorActiveDays(26)}
                                        className="Tuesday clx-calendar-day-div"
                                        onMouseEnter={this.toggleDayHover(26)}
                                        onMouseLeave={this.toggleDayLeave}
                                    >
                                        26
                                    </div>
                                    <div
                                        style={getColorActiveDays(27)}
                                        className="Wednesday clx-calendar-day-div"
                                        onMouseEnter={this.toggleDayHover(27)}
                                        onMouseLeave={this.toggleDayLeave}
                                    >
                                        27
                                    </div>
                                    <div
                                        style={getColorActiveDays(28)}
                                        className="Thursday clx-calendar-day-div"
                                        onMouseEnter={this.toggleDayHover(28)}
                                        onMouseLeave={this.toggleDayLeave}
                                    >
                                        28
                                    </div>
                                    <div
                                        style={getColorActiveDays(29)}
                                        className="Friday clx-calendar-day-div"
                                        onMouseEnter={this.toggleDayHover(29)}
                                        onMouseLeave={this.toggleDayLeave}
                                    >
                                        29
                                    </div>
                                    <div className="Saturday clx-calendar-past-day" style={calendarPastDay}>
                                        30
                                    </div>
                                    <div className="Sunday clx-calendar-past-day" style={calendarPastDay}>
                                        31
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyRoleWidgetCalendar;
