import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import './list-group.scss';

export interface ListGroupProps extends HTMLAttributes<HTMLUListElement> {
    columns?: 1 | 2 | 3;
}

export const ListGroup: FC<ListGroupProps> = ({ className, columns, ...props }) => (
    <ul
        className={classNames('lc-list-group', { [`lc-list-group--columns-${columns}`]: columns }, className)}
        {...props}
    />
);
