import React, { FC, useState } from 'react';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { ReactComponent as Illustration } from '../Crm/EmptyCrmsIllustration.svg';
import {
    ActionList,
    AppContent,
    AppHeader,
    AppList,
    AppListItem,
    AppListItemContent,
    AppPage,
    ButtonPrimary,
    IconButton,
    AppListEmpty,
    Button,
    Icon,
    Modal,
    ModalActions,
    ModalHeader,
    useSnackbar
} from '../../components-v2/shared';
import { OptOutQueue } from '../../types/OptOutQueue';
import useStore from '../../store/useStore';
import { handlePromise, useAsyncEffect } from '@lambdacurry/component-library';
import moment from 'moment';

export const OptOutQueueList: FC<{}> = ({}) => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { Api } = store;

    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [processModalOpen, setProcessModalOpen] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number>();

    const [optOutQueues, setOptOutQueues] = useState<Array<OptOutQueue>>();

    const fetchOptOutList = async () => {
        const [response, err] = await handlePromise(Api.client.get(`opt_out_queue`));

        if (err) {
            // TODO: handle error
            return;
        }
        setOptOutQueues(response.data.opt_out_queue);
    };

    useAsyncEffect(fetchOptOutList);

    const deleteButtonHandler = async (id?: number) => {
        const { deleteOptOutQueue } = store;
        if (id) {
            await deleteOptOutQueue(id);
            addSnackbar(`Customer has been removed from the opt out list and will not be processed.`, {
                variant: 'success'
            });
        }
        await fetchOptOutList();
    };

    const processButtonHandler = async (id?: number) => {
        const { processOptOutQueue } = store;
        if (id) {
            await processOptOutQueue(id);
            addSnackbar(`Customer has been removed from the database.`, {
                variant: 'success'
            });
        }

        await fetchOptOutList();
    };

    const newItemButtonHandler = async () => {
        const { router } = store;
        router.goTo(DefaultRoutes.OptOutQueueCreate, { ...router.params }, store);
    };

    const createNewOptOutQueueButton = (
        <ButtonPrimary onClick={() => newItemButtonHandler()}>Add Email to Opt Out Queue</ButtonPrimary>
    );

    return (
        <AppPage>
            <AppHeader title="CCPA Opt Out Queue">
                <ActionList position="end">{createNewOptOutQueueButton}</ActionList>
            </AppHeader>
            <AppContent>
                The emails listed in the CCPA Opt Out Queue are the individuals who have requested to be removed from
                the database. These emails are inserted on this list automatically when a new Opt Out request comes in.
            </AppContent>
            <AppContent>
                {Array.isArray(optOutQueues) &&
                    (!optOutQueues?.length ? (
                        <AppListEmpty
                            illustration={<Illustration />}
                            title="Add email to Opt Out Queue"
                            action={createNewOptOutQueueButton}
                        />
                    ) : (
                        <AppList>
                            {optOutQueues.map(({ id, email, created_at }: OptOutQueue, index: number) => (
                                <AppListItem key={index}>
                                    <AppListItemContent title={email} />
                                    <AppListItemContent title={moment(created_at).format('llll')} />
                                    <ActionList position="end">
                                        <IconButton
                                            icon="archive"
                                            onClick={() => {
                                                setSelectedId(id);
                                                setProcessModalOpen(true);
                                            }}
                                        />
                                        <IconButton
                                            icon="trash"
                                            onClick={() => {
                                                setSelectedId(id);
                                                setDeleteModalOpen(true);
                                            }}
                                        />
                                    </ActionList>
                                </AppListItem>
                            ))}
                        </AppList>
                    ))}
            </AppContent>
            <Modal isOpen={deleteModalOpen} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="color-danger" />
                            Are you sure you want to remove this opt-out request?
                        </>
                    }
                />
                <p>This action cannot be undone.</p>
                <ModalActions>
                    <ActionList position="end">
                        <Button
                            onClick={() => {
                                setDeleteModalOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <ButtonPrimary
                            onClick={() => {
                                deleteButtonHandler(selectedId);
                                setDeleteModalOpen(false);
                            }}
                        >
                            Confirm
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>
            <Modal isOpen={processModalOpen} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="color-danger" />
                            Are you sure you want to process this opt-out request?
                        </>
                    }
                />
                <p>This action cannot be undone.</p>
                <ModalActions>
                    <ActionList position="end">
                        <Button
                            onClick={() => {
                                setProcessModalOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <ButtonPrimary
                            onClick={() => {
                                processButtonHandler(selectedId);
                                setProcessModalOpen(false);
                            }}
                        >
                            Confirm
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>
            <AppContent>
                <IconButton icon="archive" title="Process the request" />
                process the request
                <br />
                <IconButton icon="trash" />
                delete the request (if it is invalid or not needed)
            </AppContent>
        </AppPage>
    );
};
