import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Alert, Col, PageHeader, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import CommonList from '../__deprecated/Filter/CommonList';
import CommonFilter from '../__deprecated/Filter/CommonFilter';
import { FilterCommon, ListCommon } from '../../types';
import DefaultRoutes from '../../routes/DefaultRoutes';
import ButtonSpinner from '../misc/ButtonSpinner';

interface InsitePageListState {
    data: never[];
    loading: boolean;
    total: number;
    addData: string[];
    deleteData: string[];
    stored?: boolean;
    isSaving: boolean;
    isLoading: boolean;
}

@inject('store')
@observer
class InsitePageList extends CommonList {
    public listCode = 'leads-insite-page';
    public filterCode = '';
    public listDefault: ListCommon = {
        page: 1,
        pageSize: 10,
        order: 'widget_metric_data_events.host_uri.asc'
    };

    public state: InsitePageListState = {
        data: [],
        loading: true,
        total: 0,
        addData: [],
        deleteData: [],
        isSaving: true,
        isLoading: false
    };

    public fetchData(listCode: string, filterCode: string, listDefault: ListCommon, filterDefault: FilterCommon) {
        const { fetchInsitePages } = this.injected.store;

        return fetchInsitePages(listCode, filterCode, listDefault, filterDefault).then((response: any) => {
            this.setState({
                data: response.data.widget_insite_page_exception,
                total: response.data.count,
                loading: false,
                isSaving: false
            });
        });
    }

    public getCsvFile = (args: any) => {
        const { Api } = this.injected.store;

        return Api.getCSVPagesInsite(args);
    };

    public render() {
        const { activeCompanyId, isAgencyRole } = this.injected.store;
        const hasActiveCompany = !!activeCompanyId;

        return (
            <>
                <PageHeader>Insite Pages</PageHeader>
                <Col className="col-lg-12 wrapper-holder__tible-leads">
                    {this.state.stored === true && (
                        <Alert bsStyle="success" onDismiss={this.handleDismissAlert}>
                            Insite Page Exceptions have been changed successfully.
                        </Alert>
                    )}
                    {this.state.stored === false && (
                        <Alert bsStyle="error" onDismiss={this.handleDismissAlert}>
                            Insite Page Exceptions have not been changed.
                        </Alert>
                    )}
                    <Row>
                        <div className="colors-legend-wrapper holder-colors-legend">
                            <button className="btn btn-primary btn-right" onClick={this.goToInsiteLeadView}>
                                Insite Metric
                            </button>
                        </div>

                        <CommonFilter
                            applyFilter={this.applyFilter}
                            hideDateFilter={true}
                            hidePMCFilter={true}
                            hideLeadStateFilter={true}
                            hideBCFilter={hasActiveCompany}
                            hideDMAFilter={hasActiveCompany}
                            hideOGFilter={hasActiveCompany}
                            hideRegionFilter={hasActiveCompany || isAgencyRole}
                            hideCompanyFilter={hasActiveCompany}
                            showSearchFilter={true}
                            listCode={this.listCode}
                            filterCode={this.filterCode}
                        />
                        {!this.state.loading ? (
                            <div className="wrap-hold__table">
                                <BootstrapTable {...this.tableOptions()}>
                                    <TableHeaderColumn
                                        dataField="company_id"
                                        dataFormat={this.keyFormat}
                                        isKey
                                        hidden
                                    />
                                    <TableHeaderColumn
                                        dataField="company_id"
                                        dataFormat={this.companyNameFormatter}
                                        width="180"
                                        dataSort
                                        hidden={hasActiveCompany}
                                    >
                                        Company
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="host_uri" dataSort>
                                        Host
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="row"
                                        dataAlign="center"
                                        width="150px"
                                        dataFormat={this.checkboxFormat}
                                    >
                                        Enabled
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        ) : null}
                    </Row>
                    <Row className="btn__save-exceptions">
                        <ButtonSpinner isSaving={this.state.isSaving} onClick={this.saveExceptions}>
                            Save Enable Settings
                        </ButtonSpinner>
                    </Row>
                </Col>
            </>
        );
    }

    public goToInsiteLeadView = () => {
        const { activeCompanyId, router } = this.injected.store;
        const { activeAgencyId } = this.injected.store.agencyStore;

        if (activeCompanyId) {
            router.goTo(DefaultRoutes.CompanyInsiteMetricDataList, { companyId: activeCompanyId }, this.injected.store);
        } else if (activeAgencyId) {
            router.goTo(DefaultRoutes.AgencyInsiteMetricDataList, { agencyId: activeAgencyId }, this.injected.store);
        } else {
            router.goTo(DefaultRoutes.InsiteMetricDataList, {}, this.injected.store);
        }
    };

    public saveExceptions = () => {
        const { saveExceptions } = this.injected.store;

        this.setState({
            isSaving: true,
            stored: undefined
        });

        return saveExceptions(this.state.addData, this.state.deleteData).then(response => {
            this.setState({
                stored: response.data,
                addData: [],
                deleteData: []
            });

            return this.updateData();
        });
    };

    public keyFormat = (cell: any, row: any) => {
        return `${row.company_id}_${row.host_uri}`;
    };

    public checkboxFormat = (cell: any, row: any) => {
        let v = `${row.company_id}_${row.host_uri}`;
        if (row.id) {
            v = row.id;
        }

        return (
            <input
                className="BootstrapTableHeaderCheckbox"
                type="checkbox"
                onClick={this.checkException}
                defaultChecked={!row.id}
                value={v}
            />
        );
    };

    public checkException = (e: any) => {
        const checked = e.target.checked;
        const value = e.target.value.toString();

        const { deleteData, addData } = this.state;

        if (!checked) {
            if (value.indexOf('_') >= 0) {
                // need to add element for insert
                addData.push(value);
                this.setState({ addData });
            } else {
                // need to add element for removal
                const index = deleteData.indexOf(value);
                deleteData.splice(index, 1);
                this.setState({ deleteData });
            }
        } else {
            if (value.indexOf('_') >= 0) {
                // need to delete element from add tasks
                const index = addData.indexOf(value);
                addData.splice(index, 1);
                this.setState({ addData });
            } else {
                // need to delete element from delete tasks
                deleteData.push(value);
                this.setState({ deleteData });
            }
        }
    };

    private handleDismissAlert = () => {
        this.setState({ stored: undefined });
    };
}

export default InsitePageList;
