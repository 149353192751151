import React, { FC, useContext, useEffect, useState } from 'react';
import * as constants from '../../../constants';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Icon } from '@lambdacurry/component-library';
import useStore from '../../../store/useStore';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import {
    Breadcrumbs,
    ViewSwitcher,
    ViewSwitcherOption,
    MenuAdmin,
    MenuAgency,
    MenuCompany,
    MenuCompanyLight,
    MenuChat
} from './components';
import { LayoutContext } from '../LayoutWrapper';
import { goToAgency, goToCompany, goToHome } from './SideNav.helpers';
import { getFileUrl } from '../../../util/file';

import './side-nav.scss';
import { USER_PROFILE_DEFAULT_LIMIT } from '../../../constants';

export interface SideNavProps {
    className?: string;
}

export const SideNav: FC<SideNavProps> = observer(({ className }) => {
    const { store } = useStore();
    const {
        router,
        hasAccess,
        isAdminRole,
        isAgencyRole,
        isCompanyRole,
        isCompanyLightRole,
        isChatRole,
        setActiveCompanyId,
        fetchActiveUser,
    } = store;
    const { loading, activeCompanyId, activeAgencyId, activeAgency, accessibleCompanies, accessibleAgencies } =
        useContext(LayoutContext);
    const [agencyOptions, setAgencyOptions] = useState<ViewSwitcherOption[]>([]);
    const [companyOptions, setCompanyOptions] = useState<ViewSwitcherOption[]>([]);
    const [limit, setLimit] = useState<number>(USER_PROFILE_DEFAULT_LIMIT);

    const unsetCompany = async () => {
        await setActiveCompanyId(0);
    };

    const handleAgencyChange = async (newValue?: ViewSwitcherOption) => {
        if (newValue) {
            return goToAgency(newValue.id, store);
        }
        setLimit(USER_PROFILE_DEFAULT_LIMIT);
        await fetchActiveUser();
        return goToHome(store);
    };

    const handleCompanyChange = async (newValue?: ViewSwitcherOption, oldValue?: ViewSwitcherOption) => {
        await unsetCompany();

        if (newValue) {
            return goToCompany(newValue.id, store);
        }

        // IMPORTANT: Only allow going to agency view if the user is not a company user.
        if (!isCompanyRole && oldValue) {
            // If the company is deselected and it was part of an agency, return the the agency view.
            const previousCompany = accessibleCompanies.find(company => company.id === oldValue.id);

            if (previousCompany && previousCompany.agency_id) {
                return goToAgency(previousCompany.agency_id, store);
            }
        }
        setLimit(USER_PROFILE_DEFAULT_LIMIT);
        await fetchActiveUser();
        return goToHome(store);
    };

    useEffect(() => {
        setAgencyOptions(
            accessibleAgencies.map(({ id, logo, avatar, name, active }) => ({
                id,
                icon: avatar ? getFileUrl(avatar, 'agencyAvatar') : getFileUrl(logo),
                label: name,
                active
            }))
        );
    }, [accessibleAgencies, activeAgency]);

    useEffect(() => {
        setCompanyOptions(
            accessibleCompanies
                // IMPORTANT: Don't filter the accessibleCompanies by activeUserAgencyId if the user
                // has a company role since company users cannot change the active agency.
                .map(({ id, logo, displayName, name, active, label, ...company }) => ({
                    ...company,
                    id,
                    icon: getFileUrl(logo),
                    label: displayName || name || label,
                    active
                }))
        );
    }, [accessibleCompanies, activeAgencyId]);

    return (
        <nav className={classNames('side-nav', { 'side-nav-is-disabled': loading }, className)}>
            {(hasAccess('agencies.view') || hasAccess('companies.view')) && <Breadcrumbs />}

            {/* TODO: Keeping this here for now, in case we don't ultimately like the direction we went with the back button in the breadcrumbs.
            <Button className="side-nav-back" icon={<Icon name="arrowLeft" />} onClick={handleBackClick}>
                Back to {companyId && activeCompany.agency_id ? 'Partner' : 'Admin'} View
            </Button>

            <div className="side-nav-divider" /> */}

            {(isCompanyRole || isAdminRole || isAgencyRole) && (
                <ViewSwitcher
                    loading={loading}
                    label="Partner"
                    name="partnerSearch"
                    options={agencyOptions}
                    defaultIcon={<Icon name="partner" />}
                    onChange={handleAgencyChange}
                    onCreateNewClick={() => router.goTo(DefaultRoutes.AgencyCreate, {}, store)}
                    value={activeAgencyId}
                    readonly={!isAdminRole && !isAgencyRole && isCompanyRole}
                    hasCreateNew={isAdminRole || !isAgencyRole}
                    limit={limit}
                    setLimit={setLimit}
                />
            )}

            {hasAccess('companies.view') && (
                <ViewSwitcher
                    loading={loading}
                    label="Company"
                    name="companySearch"
                    options={companyOptions}
                    defaultIcon={<Icon name="company" />}
                    onChange={handleCompanyChange}
                    onCreateNewClick={() => {
                        return activeAgency
                            ? router.goTo(DefaultRoutes.AgencyCompanyCreate, { agencyId: activeAgency.id }, store)
                            : router.goTo(DefaultRoutes.CompanyCreate, {}, store);
                    }}
                    value={activeCompanyId}
                    readonly={isCompanyRole && accessibleCompanies?.length === 1}
                    hasCreateNew={!isCompanyRole}
                    limit={limit}
                    setLimit={setLimit}
                />
            )}

            {!isChatRole && <div className="side-nav-divider" />}

            {!activeAgencyId && !activeCompanyId && isChatRole && <MenuChat />}
            {!!activeCompanyId && <>{isCompanyLightRole ? <MenuCompanyLight /> : <MenuCompany />}</>}
            {!!activeAgencyId && !activeCompanyId && (isAdminRole || isAgencyRole) && <MenuAgency />}
            {!activeAgencyId && !activeCompanyId && (isAdminRole || isAgencyRole || isCompanyRole) && <MenuAdmin />}

            <div className="side-nav-release">
                {constants.versionNumber && constants.versionRelease && (
                    <div className="side-nav-release-version">
                        {`v${constants.versionNumber} - ${constants.versionRelease}`}
                    </div>
                )}
            </div>
        </nav>
    );
});
