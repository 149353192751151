import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Alert, Button, Col, Collapse, Glyphicon, PageHeader, Row, Well } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { InjectedProps } from '../../types';
import { Category } from '../../types/Category';
import CategoryNew from './CategoryNew';

interface CategoryListState {
    open: boolean;
    newItem?: Category;
}

@inject('store')
@observer
export class CategoryList extends React.Component<{}, CategoryListState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state: CategoryListState = {
        open: false
    };

    public componentDidMount() {
        const { fetchCategory } = this.injected.store;
        return fetchCategory();
    }

    public render() {
        const { activeAgencyId } = this.injected.store.agencyStore;
        const { newItem } = this.state;
        const options = {
            onRowClick: this.handleRowClick
        };
        const { businessCategory } = this.injected.store;
        const businessCategories = activeAgencyId
            ? businessCategory.values.filter((item: Category) => {
                  return item.agency_id === activeAgencyId;
              })
            : businessCategory.values.filter((item: Category) => {
                  return item.agency_id === null;
              });

        return (
            <>
                <PageHeader>Business Categories</PageHeader>
                <Col className="col-lg-12">
                    {newItem && (
                        <Alert bsStyle="success" onDismiss={this.handleDismissAlert}>
                            New category "{newItem.title}" was created successfully.
                        </Alert>
                    )}
                    <Row>
                        <Button bsStyle="primary" onClick={this.toggleNewForm}>
                            Create Category <Glyphicon glyph="plus" />
                        </Button>
                    </Row>
                    <Collapse in={this.state.open}>
                        <Row>
                            <Well>
                                <CategoryNew handleClose={this.itemAdded} />
                            </Well>
                        </Row>
                    </Collapse>
                    <Row>
                        <BootstrapTable data={businessCategories} options={options} pagination search striped>
                            <TableHeaderColumn dataField="id" isKey width="60" dataSort>
                                ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="title">Title</TableHeaderColumn>
                        </BootstrapTable>
                    </Row>
                </Col>
            </>
        );
    }

    public handleRowClick = (row: any) => {
        const { router } = this.injected.store;
        const { activeAgencyId } = this.injected.store.agencyStore;

        router.goTo(
            activeAgencyId ? DefaultRoutes.AgencyCategoryEdit : DefaultRoutes.CategoryEdit,
            { ...router.params, id: row.id },
            this.injected.store
        );
    };

    public toggleNewForm = () => {
        this.setState({ open: !this.state.open });
    };

    public itemAdded = (item: any) => {
        this.setState({ newItem: item });
        this.toggleNewForm();
    };

    public handleDismissAlert = () => {
        this.setState({ newItem: undefined });
    };
}

export default CategoryList;
