export const getSession = (key: string): any => {
    try {
        return sessionStorage.getItem(key);
    } catch (e) {
        global.console.log('getSessionStorage error', e);
    }
};

export const getSessionObject = (key: string): object | undefined => {
    const value = getSession(key);

    try {
        return JSON.parse(value);
    } catch (e) {
        global.console.log('JSON.parse error', e);
    }

    return undefined;
};

export const setSession = (key: string, value: string): void => {
    try {
        sessionStorage.setItem(key, value);
    } catch (e) {
        global.console.log('setSessionStorage error', e);
    }
};

export const clearSessionItem = (key: string): void => {
    try {
        sessionStorage.removeItem(key);
    } catch (e) {
        global.console.log('clearSessionItem error', e);
    }
};

export const setSessionObject = (key: string, obj: object): void => {
    const value = JSON.stringify(obj);
    return setSession(key, value);
};
