import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputSelect,
    ButtonOutlinePrimary
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
import { RuleAction } from '../../../../types';

export interface RealPageLead2LeaseFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface RealPageLead2LeaseCrm extends Crm {
    configuration: {
        api_endpoint: string;
        api_key: string;
        property_id: string;
    };
}

const ruleActions: Array<RuleAction> = [];

export const RealPageLead2LeaseForm: FC<RealPageLead2LeaseFormProps> = observer(
    ({ onSubmit, onValidate, ...props }) => {
        const { store } = useStore();
        const { router } = store;
        const { companyId } = router.params;

        const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

        const handleValidate = async (formikProps: FormikProps<RealPageLead2LeaseCrm>) => {
            await onValidate(formikProps.values, crmConfigTypes.realpage_lead2lease);
        };

        const handleSubmit = async (values: RealPageLead2LeaseCrm, actions: FormikHelpers<RealPageLead2LeaseCrm>) => {
            await onSubmit(values, actions, crmConfigTypes.realpage_lead2lease);
        };

        return (
            <Form
                {...props}
                onSubmit={handleSubmit}
                confirmUnsavedChanges
                unsavedChangesConfig={{
                    containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
                }}
            >
                {(formikProps: FormikProps<RealPageLead2LeaseCrm>) => {
                    return (
                        <>
                            <AppSection className="field-group-content">
                                <div className="field-group-col">
                                    <AppSectionHeader title="CRM Configuration: Lead2Lease by RealPage" />
                                    <InputText
                                        name="name"
                                        label="Name"
                                        helperText="Unique name for this CRM Configuration"
                                        formikProps={formikProps}
                                        required={true}
                                        inputProps={{ maxLength: 64 }}
                                    />
                                    <AppSectionHeader
                                        title="Connection Settings"
                                        subtitle="Contact RealPage Lead2Lease support for your exact connection settings"
                                    />
                                    <InputSelect
                                        label="API Environment"
                                        name="configuration.api_endpoint"
                                        options={[
                                            { val: 'https://uat-api.realpage.com/lead', label: 'Test/Staging' },
                                            { val: 'https://api.realpage.com/lead', label: 'Production' }
                                        ]}
                                        optionValueKey="val"
                                        optionLabelKey="label"
                                        helperText="Lead2Lease API Environment"
                                        required={true}
                                        autocompleteConfig={{ disableClearable: true }}
                                        formikProps={formikProps}
                                    />
                                    <InputText
                                        name="configuration.api_key"
                                        label="API Key"
                                        required={true}
                                        helperText="Lead2Lease API Key"
                                        formikProps={formikProps}
                                        inputProps={{ maxLength: 255 }}
                                    />
                                    <InputText
                                        name="configuration.property_id"
                                        label="Property ID"
                                        required={true}
                                        helperText="Lead2Lease Property ID"
                                        formikProps={formikProps}
                                        inputProps={{ maxLength: 255 }}
                                    />
                                </div>
                            </AppSection>
                            <RuleEditor
                                formikProps={formikProps as FormikProps<JSONWithRuleset>}
                                availableRuleActions={ruleActions}
                                availableRuleConditionSubjects={CrmRuleConditionSubjects}
                                predicatePicklists={CrmRulePredicatePicklists}
                            />
                            <AppFooter sticky={true}>
                                <ActionList position="end">
                                    {formikProps.dirty && (
                                        <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                            Cancel
                                        </Button>
                                    )}
                                    <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                        Validate
                                    </ButtonOutlinePrimary>
                                    <ButtonPrimary
                                        type="submit"
                                        disabled={!formikProps.dirty || formikProps.isSubmitting}
                                    >
                                        {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                    </ButtonPrimary>
                                </ActionList>
                            </AppFooter>
                        </>
                    );
                }}
            </Form>
        );
    }
);
