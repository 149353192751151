import classNames from 'classnames';
import React, { CSSProperties, FC, forwardRef, memo, ReactElement, Ref, useEffect, useState } from 'react';
import { AppListItem, AppListItemContent, AppListItemDetails, AppListItemActions } from '../../../components-v2/shared';
import { Handle } from '../../../components-v2/shared/dnd';
import { Transform } from '@dnd-kit/utilities';
import { Sortable } from '../../../components-v2/shared/dnd/Sortable';
import { ButtonUnstyled, DecorativeIconProps, IconButton } from '@lambdacurry/component-library';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { openModal } from '../Question.helpers';
import { ToggleQuestionSwitch } from './ToggleQuestionSwitch';
import { dateFormatter } from '../../../util/formatters';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import { Question } from '../../../types/Question';
import Store from '../../../store';

interface ActiveApp {
    id: number;
    company_id: number;
    name: string;
    display_name: string;
    description?: string;
    enabled: boolean;
    behavior: string;
    action: string;
    created_at: string | null;
    updated_at: string | null;
    chatbot_id: number;
    order: number;
}
interface ActiveAppItem extends ActiveApp {
    icon: DecorativeIconProps;
}
interface ActiveAppOrderListItemProps extends ActiveAppItem {
    questionsList: Question[];
    index: number;
    handleMoveInArray: (index: number, direction: string) => void;
    storeQuestions: any;
    dispatch: any;
    storeQuestion: any;
    handleEditClick: any;
    setLoadingTopics: any;
}
interface AppOrderSortableProps {
    className: string;
    dragOverlay?: boolean;
    color?: string;
    disabled?: boolean;
    dragging?: boolean;
    handle?: boolean;
    height?: number;
    index?: number;
    fadeIn?: boolean;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    sorting?: boolean;
    style?: CSSProperties;
    transition?: string | null;
    wrapperStyle?: CSSProperties;
    value: ActiveAppOrderListItemProps;
    onRemove?(): void;
    renderItem?(args: {
        dragOverlay: boolean;
        dragging: boolean;
        sorting: boolean;
        index: number | undefined;
        fadeIn: boolean;
        listeners: DraggableSyntheticListeners;
        ref: Ref<HTMLElement>;
        style: CSSProperties | undefined;
        transform: AppOrderSortableProps['transform'];
        transition: AppOrderSortableProps['transition'];
        value: AppOrderSortableProps['value'];
    }): ReactElement;
}

const ActiveAppOrderListItem = memo(
    forwardRef<HTMLLIElement, AppOrderSortableProps>(
        (
            {
                className,
                color,
                dragOverlay,
                dragging,
                disabled,
                fadeIn,
                handle,
                height,
                index,
                listeners,
                onRemove,
                renderItem,
                sorting,
                style,
                transition,
                transform,
                value,
                wrapperStyle,
                ...props
            },
            ref
        ) => {
            useEffect(() => {
                if (!dragOverlay) {
                    return;
                }

                document.body.style.cursor = 'grabbing';

                return () => {
                    document.body.style.cursor = '';
                };
            }, [dragOverlay]);
            return (
                <li
                    className={classNames('Wrapper', className, {
                        fadeIn,
                        sorting,
                        dragOverlay
                    })}
                    style={
                        {
                            ...wrapperStyle,
                            transition,
                            '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
                            '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                            '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
                            '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
                            '--index': index,
                            '--color': color
                        } as CSSProperties
                    }
                    ref={ref}
                >
                    <AppListItem
                        className={classNames('Item', {
                            dragging,
                            withHandle: handle,
                            dragOverlay,
                            disabled,
                            color
                        })}
                        style={style}
                        {...props}
                        tabIndex={!handle ? 0 : undefined}
                    >
                        <AppListItemContent
                            icon={value.icon}
                            title={
                                <ButtonUnstyled
                                    onClick={() => value.handleEditClick(value.storeQuestion)}
                                    data-test-id={'button_cb_edit_question_' + value.storeQuestion.id}
                                >
                                    {value.storeQuestion.name}
                                </ButtonUnstyled>
                            }
                            description={`ID: ${value.storeQuestion.id} - Created ${dateFormatter(
                                value.storeQuestion.created_at || ''
                            )}`}
                        />
                        {/* This empty component is used to push the actions to the end of the list item */}
                        <AppListItemDetails />
                        <AppListItemActions position="end">
                            <IconButton
                                icon="chevronUp"
                                disabled={index === 0}
                                onClick={() => value.handleMoveInArray(value.index, 'up')}
                            />
                            <IconButton
                                icon="chevronDown"
                                disabled={index === value.questionsList.length - 1}
                                onClick={() => value.handleMoveInArray(value.index, 'down')}
                            />
                            {handle && <Handle className="app-list-item-handle" {...listeners} />}
                            <ToggleQuestionSwitch
                                question={value.storeQuestion}
                                questions={value.storeQuestions}
                                index={Number(value.storeQuestion.id)}
                            />
                            <IconButton
                                icon="trash"
                                onClick={() =>
                                    openModal(value.dispatch, 'deleteQuestion', { question: value.storeQuestion })
                                }
                                data-test-id={'button_cb_delete_question_' + Number(value.storeQuestion.id)}
                            />
                        </AppListItemActions>
                    </AppListItem>
                </li>
            );
        }
    )
);

export const QuestionsOrderList: FC<{
    questions: Array<Question>;
    store: Store;
    dispatch: any;
    companyId: number | string;
    setLoadingTopics: (value: boolean) => void;
}> = ({ questions, store, dispatch, companyId, setLoadingTopics }) => {
    const updateStoreQuestions = async (questions: Array<Question> | any, storeQuestions: { updateAll: (arg0: any) => any }) => {
        if (questions?.[0]?.questionsList) {
            const updatedQuestionsList = questions.map((item: { [x: string]: any; id: number; company_id: number }, index: number) => ({
                id: item.id,
                company_id: item.company_id,
                name: item.name,
                display_name: item.display_name,
                description: item.description,
                enabled: item.enabled,
                behavior: item.behavior,
                action: item.action,
                chatbot_id: item.chatbot_id,
                order: index,
            }));
            await storeQuestions.updateAll(updatedQuestionsList);
        }

        if (!questions?.[0]?.questionsList) {
            const updatedQuestionsList = questions.map((item: { [x: string]: any; id: number; company_id: number }, index: number) => ({
                id: item.id,
                company_id: item.company_id,
                name: item.name,
                display_name: item.display_name,
                description: item.description,
                enabled: item.enabled,
                behavior: item.behavior,
                action: item.action,
                chatbot_id: item.chatbot_id,
                order: index,
            }));
            await storeQuestions.updateAll(updatedQuestionsList);
        }
    };
    const { questions: storeQuestions, router } = store;
    const [questionsList, setQuestionsList] = useState<Array<Question | any>>(questions || []);
    const handleMoveInArray = (currentIndex: any, direction: 'up' | 'down') => {
        const newArray = [...questionsList];
        const removedItem = newArray.splice(currentIndex, 1)[0];
        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
        newArray.splice(newIndex, 0, removedItem);
        setQuestionsList([...newArray]);
        updateStoreQuestions([...newArray], storeQuestions);
    };

    useEffect(() => {
        setQuestionsList(questions)
    }, [questions]);

    const handleEditClick = (question: Question) => {
        router.goTo(DefaultRoutes.ChatBotQuestionEdit, { companyId, questionId: question.id }, store);
    };

    if (!questionsList || !questionsList.length) {
        return <p className="clx-margin-top-0">No questions to display.</p>;
    }
    return (
        <Sortable
            items={questionsList.map((appItem: { id: number | string }, index: number) => ({
                ...appItem,
                index,
                handleMoveInArray,
                questionsList,
                id: `${appItem?.id}`,
                storeQuestions,
                storeQuestion: appItem,
                dispatch,
                handleEditClick,
                setLoadingTopics
            }))}
            Container={(props: any) => <ul className="app-order-list-container" {...props} />}
            renderItem={(props: any) => {
                return <ActiveAppOrderListItem key={props.value.id} {...props} />;
            }}
            handle={true}
            useDragOverlay={false}
            onDragEnd={newArray => {
                setQuestionsList([...newArray]);
                updateStoreQuestions([...newArray], storeQuestions);
            }}
        />
    );
};