import React, { FC, useReducer, Reducer, useState } from 'react';
import { ArrayHelpers, FastField, FieldArray, FieldProps, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import {
    AppSectionHeader,
    AppList,
    AppListItem,
    ButtonLink,
    IconButton,
    InputSwitch,
    Modal,
    ModalActions,
    ModalHeader,
    Form,
    InputText,
    InputCheckbox,
    Button,
    ButtonDanger,
    ButtonPrimary,
    TimeRangeSlider,
    InputSelect,
    InputDate,
    useSnackbar,
    ActionList,
    Icon
} from '../../components-v2/shared';
import {
    ScheduleEditorListReducerState,
    ScheduleEditorListReducerAction,
    scheduleEditorListReducer
} from './ScheduleEditor.helpers';
import { TimeRange, TimeOffAny, TimeOffHoliday, TimeOffCustom, TimeOffExternal } from './ScheduleEditor.types';
import { ScheduleEditorTimeOffValidationSchema } from './ScheduleEditor.validation';
import useStore from '../../store/useStore';
import { handlePromise, useAsyncEffect } from '../../util/async';

import styles from './schedule-editor.module.scss';
import classNames from 'classnames';
import { shouldUpdateFastField } from '../../util/form';
import { CompanyRoutes } from '../../routes/CompanyRoutes';
import { ExternalCalendarBundle, LinkRemoteCalendarForm } from '../ExternalCalendar/ExternalCalendarHelpers';

interface EditScheduleTimeOffProps {
    className?: string;
}

interface EditScheduleTimeOffForm {
    id?: string;
    name: string;
    date: DateTime;
    times: TimeRange[];
    allDay: boolean;
    repeatYearly: boolean;
}

const getFormattedTime = (time: DateTime | string) =>
    DateTime.fromISO(typeof time !== 'string' ? time.toLocaleString() : time).toFormat('t');

const getFormattedDate = (dateTime: string, format: string) => DateTime.fromISO(dateTime).toFormat(format);

export const getFormattedTimeRanges = (times: TimeRange[]) =>
    times.reduce((acc, curr, index) => {
        acc += `${getFormattedTime(curr.startTime)}&ndash;${getFormattedTime(curr.endTime)}`;

        if (index < times.length - 1) {
            acc += `, `;
        }

        return acc;
    }, '');

export const getTimeOffDuration = (timeOff: TimeOffAny) => {
    // See: https://www.typescriptlang.org/docs/handbook/advanced-types.html
    const internalTimeOff = timeOff as TimeOffHoliday | TimeOffCustom;
    const externalTimeOff = timeOff as TimeOffExternal;
    let duration = '';

    if (internalTimeOff.allDay) {
        return 'All Day';
    }

    if (internalTimeOff.times) {
        duration = getFormattedTimeRanges(internalTimeOff.times);
    } else if (externalTimeOff.start) {
        duration = `${getFormattedTime(externalTimeOff.start)}&ndash;${getFormattedTime(externalTimeOff.end)}`;
    }

    return duration;
};

export const EditScheduleTimeOff: FC<EditScheduleTimeOffProps> = ({ className }) => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, Api } = store;
    const { companyId, scheduleId } = router.params;

    const [timesOff, setTimesOff] = useState<TimeOffAny[]>([]);
    const [unlinkModalOpen, setUnlinkModalOpen] = useState<boolean>(false);

    const [externalCalendarBundle, setExternalCalendarBundle] = useState<ExternalCalendarBundle>({
        availableAuthTokens: [],
        availableCalendars: []
    });
    const [state, dispatch] = useReducer<Reducer<ScheduleEditorListReducerState, ScheduleEditorListReducerAction>>(
        scheduleEditorListReducer,
        {
            modal: {
                active: 'none',
                data: {}
            }
        }
    );
    const { modal } = state;

    const fetchData = async () => {
        await fetchTimesOff();
        await fetchExternalCalendars();
    };

    const unlinkButtonHandler = async () => {
        await deleteLinkedCalendar();
    };

    const fetchTimesOff = async () => {
        const [getTimeOffResponse, getTimeOffError] = await handlePromise<{ data: { data: TimeOffAny[] } }>(
            Api.client.get(`company/${companyId}/availability-schedules/${scheduleId}/timeoff`)
        );

        if (getTimeOffError || !getTimeOffResponse) {
            // TODO: handle error
            return;
        }

        setTimesOff(getTimeOffResponse.data.data);
    };

    const fetchExternalCalendars = async () => {
        const [getExternalCalendarBundleResponse, getExternalCalendarBundleError] = await handlePromise<{
            data: any[];
        }>(Api.client.get(`external/calendars/bundle/${companyId}/schedule/${scheduleId}`));

        if (getExternalCalendarBundleError || !getExternalCalendarBundleResponse) {
            // TODO: handle error
            return;
        }
        setExternalCalendarBundle(getExternalCalendarBundleResponse.data as any);
    };

    useAsyncEffect(fetchData);

    const deleteLinkedCalendar = async () => {
        // We have everything we need to link up the selected external calendar with this Schedule
        const [, unlinkExternalCalendarError] = await handlePromise(
            Api.client.delete(`external/calendars/schedule/${scheduleId}`)
        );
        if (unlinkExternalCalendarError) {
            console.error(unlinkExternalCalendarError);
            addSnackbar(`Unlinking ExternalCalendar from Schedule failed.`, { variant: 'error' });
            closeModal();
            return;
        }
        closeModal();
        addSnackbar(`ExternalCalendar has been unlinked from schedule.`, { variant: 'success' });
        await fetchData();
    };

    const createTimeOff = async (data: Partial<TimeOffCustom>) => {
        const [createTimeOffResponse, createTimeOffError] = await handlePromise<{ data: TimeOffCustom }>(
            Api.client.post(`company/${companyId}/availability-schedules/${scheduleId}/timeoff/custom`, data)
        );

        if (createTimeOffError || !createTimeOffResponse) {
            console.error(createTimeOffError || createTimeOffResponse);
            addSnackbar(`Create time off "${data.name}" failed.`, { variant: 'error' });
            return;
        }

        closeModal();
        addSnackbar(`Time off "${data.name}" created.`, { variant: 'success' });
        await fetchTimesOff();
    };

    const updateTimeOff = async (data: Partial<TimeOffCustom>) => {
        const [updateTimeOffResponse, updateTimeOffError] = await handlePromise<{ data: TimeOffCustom }>(
            Api.client.patch(`company/${companyId}/availability-schedules/${scheduleId}/timeoff/custom`, data)
        );

        if (updateTimeOffError || !updateTimeOffResponse) {
            console.error(updateTimeOffError || updateTimeOffResponse);
            addSnackbar(`Update time off "${data.name}" failed.`, { variant: 'error' });
            return;
        }

        closeModal();
        addSnackbar(`Time off "${data.name}" updated.`, { variant: 'success' });
        await fetchTimesOff();
    };

    const deleteTimeOff = async (timeOff?: TimeOffCustom) => {
        if (!timeOff) {
            return;
        }

        const [deleteTimeOffResponse, deleteTimeOffError] = await handlePromise<{ data: TimeOffCustom }>(
            Api.client.delete(`company/${companyId}/availability-schedules/${scheduleId}/timeoff/custom/${timeOff.id}`)
        );

        if (deleteTimeOffError || !deleteTimeOffResponse) {
            console.error(deleteTimeOffError || deleteTimeOffResponse);
            addSnackbar(`Delete time off "${timeOff.name}" failed.`, { variant: 'error' });
            return;
        }

        closeModal();
        addSnackbar(`Time off "${timeOff.name}" deleted.`, { variant: 'success' });
        await fetchTimesOff();
    };

    const toggleTimeOffSwitch = (timeOff: TimeOffAny) => {
        const updatedTimesOff = timesOff.map(item => {
            if (item.id !== timeOff.id) {
                return item;
            }
            return {
                ...item,
                enabled: !item.enabled
            };
        });
        setTimesOff(updatedTimesOff);
    };

    const toggleTimeOff = async (timeOff: TimeOffAny, checked: boolean) => {
        // Note: We will switch the toggle assuming a success, in the error we can switch back if failed.
        toggleTimeOffSwitch(timeOff);

        const internalTimeOff = timeOff as TimeOffHoliday | TimeOffCustom;

        const data: Partial<TimeOffAny> = {
            id: timeOff.id,
            enabled: checked,
            allDay: internalTimeOff.allDay
        };

        const [toggleTimeOffResponse, toggleTimeOffError] = await handlePromise<{
            data: TimeOffHoliday | TimeOffCustom;
        }>(Api.client.patch(`company/${companyId}/availability-schedules/${scheduleId}/timeoff/${timeOff.type}`, data));

        if (toggleTimeOffError || !toggleTimeOffResponse) {
            console.error(toggleTimeOffError || toggleTimeOffResponse);
            addSnackbar(`Toggle time off "${timeOff.name}" failed.`, { variant: 'error' });
            toggleTimeOffSwitch(timeOff);
            return;
        }

        addSnackbar(`Time off "${timeOff.name}" ${toggleTimeOffResponse.data.enabled ? 'enabled' : 'disabled'}.`, {
            variant: 'success'
        });
    };

    const handleSubmitRemoteCalendarForm = async ({ available_calendar }: LinkRemoteCalendarForm) => {
        if (!available_calendar) {
            closeModal();
            return;
        }
        if (available_calendar.inUse) {
            addSnackbar(`Calendar is already in use and cannot be linked again here.`, { variant: 'error' });
            closeModal();
            return;
        }
        if (available_calendar && available_calendar.externalId && available_calendar.authId) {
            // We have everything we need to link up the selected external calendar with this Schedule
            const [linkExternalCalendarResponse, linkExternalCalendarError] = await handlePromise<{
                data: ExternalCalendarBundle;
            }>(Api.client.post(`external/calendars/schedule/${scheduleId}`, { available_calendar }));
            if (linkExternalCalendarError || !linkExternalCalendarResponse) {
                console.error(linkExternalCalendarError || linkExternalCalendarResponse);
                addSnackbar(`Linking ExternalCalendar to Schedule failed.`, { variant: 'error' });
                closeModal();
                return;
            }
        }
        addSnackbar(`Loading external calendar entries...`, { variant: 'info' });
        setTimeout(async () => {
            await fetchData();
            addSnackbar(`External calendar linking complete.`, { variant: 'success' });
        }, 2000);
        closeModal();
    };

    const handleSubmitEditTimeOffForm = async ({
        id,
        name,
        date,
        times,
        allDay,
        repeatYearly
    }: EditScheduleTimeOffForm) => {
        const { day, month, year } = date instanceof Date ? DateTime.fromJSDate(date) : date;

        const data = {
            name,
            times,
            allDay,
            date: {
                day,
                month,
                year: repeatYearly ? year : null
            }
        };

        if (id) {
            return await updateTimeOff({ ...data, id });
        }

        return createTimeOff(data);
    };

    const openRemoteCalendarModal = async () => {
        let title = 'Link External Calendar';
        let formInitialValues: LinkRemoteCalendarForm = {};
        openModal('editRemoteCalendar', {
            title,
            formInitialValues
        });
    };

    const openEditTimeOffModal = (timeOff?: TimeOffCustom) => {
        let title = 'New Blocked Time';
        let formInitialValues: EditScheduleTimeOffForm = {
            name: '',
            date: DateTime.local(),
            allDay: false,
            times: [{ startTime: '09:00', endTime: '17:00' }],
            repeatYearly: false
        };

        if (timeOff) {
            const { id, name, allDay, times, date, nextDate } = timeOff;

            title = 'Edit Blocked Time';
            formInitialValues = {
                id,
                name,
                date: DateTime.local(date.year || DateTime.fromISO(nextDate).year, date.month, date.day),
                allDay,
                times,
                repeatYearly: !!date.year
            };
        }

        openModal('editTimeOff', {
            title,
            formInitialValues
        });
    };

    const openModal = (name: string, data?: object) => {
        dispatch({ name: 'openModal', payload: name });

        if (data) {
            dispatch({ name: 'setModalData', payload: data });
        }
    };

    const closeModal = (clearData: boolean = true) => {
        dispatch({ name: 'closeModal' });

        if (clearData) {
            dispatch({ name: 'setModalData', payload: {} });
        }
    };

    const calendarsAvailableForLinking = externalCalendarBundle.availableCalendars
        .filter(c => {
            return !c.inUse;
        })
        .map(ac => {
            return {
                ...ac,
                display_name: `${ac.authName}: ${ac.name}`
            };
        });

    const hasCalendarsAvailableForLinking = calendarsAvailableForLinking.length > 0;

    return (
        <div className={className}>
            <AppSectionHeader title="Holidays &amp; Blocked Time">
                <ButtonLink
                    className={styles['schedule-editor-time-off-button-link']}
                    onClick={event => openEditTimeOffModal()}
                >
                    + Add Blocked Time
                </ButtonLink>
                <ButtonLink
                    hidden={!externalCalendarBundle || externalCalendarBundle.availableCalendars.length === 0}
                    className={styles['schedule-editor-time-off-button-link']}
                    onClick={event => openRemoteCalendarModal()}
                >
                    + Link External Calendar
                </ButtonLink>
                <ButtonLink
                    hidden={!externalCalendarBundle || !externalCalendarBundle.currentExternalCalendarLink?.id}
                    className={styles['schedule-editor-time-off-button-link']}
                    onClick={event => setUnlinkModalOpen(true)}
                >
                    - Unlink External Calendar: {externalCalendarBundle.currentExternalCalendarLink?.name}
                </ButtonLink>
                <ButtonLink
                    hidden={!externalCalendarBundle || externalCalendarBundle.availableAuthTokens.length > 0}
                    className={styles['schedule-editor-time-off-button-link']}
                    onClick={event => router.goTo(CompanyRoutes.RemoteAuthList, { companyId }, store)}
                >
                    + Add Remote Auth To Link External Calendar
                </ButtonLink>
            </AppSectionHeader>

            {timesOff.length > 0 && (
                <AppList>
                    {timesOff.map(timeOff => {
                        return (
                            <AppListItem key={timeOff.id} className={styles['edit-schedule-time-off-item']}>
                                <div className={styles['edit-schedule-time-off-item-date']}>
                                    {getFormattedDate(timeOff.nextDate, 'LLL dd')}
                                </div>

                                <div>
                                    {timeOff.name && (
                                        <div className={styles['edit-schedule-time-off-item-title']}>
                                            {timeOff.name}
                                        </div>
                                    )}
                                    <div
                                        className={styles['edit-schedule-time-off-item-duration']}
                                        dangerouslySetInnerHTML={{ __html: getTimeOffDuration(timeOff) }}
                                    />
                                </div>

                                <ActionList position="end">
                                    {timeOff.type === 'custom' && (
                                        <IconButton icon="pencil" onClick={event => openEditTimeOffModal(timeOff)} />
                                    )}
                                    {timeOff.type === 'custom' && (
                                        <IconButton
                                            icon="trash"
                                            onClick={event => openModal('deleteTimeOff', { timeOff })}
                                        />
                                    )}
                                    {timeOff.type !== 'custom' && (
                                        <InputSwitch
                                            checked={timeOff.enabled}
                                            onChange={(event, checked) => toggleTimeOff(timeOff, checked)}
                                        />
                                    )}
                                </ActionList>
                            </AppListItem>
                        );
                    })}
                </AppList>
            )}

            <Modal
                id="editRemoteCalendarModal"
                isOpen={modal.active === 'editRemoteCalendar'}
                onAfterClose={() => closeModal()}
            >
                <ModalHeader title={modal.data.title || ''} />
                <Form initialValues={modal.data.formInitialValues || {}} onSubmit={handleSubmitRemoteCalendarForm}>
                    {(formikProps: FormikProps<LinkRemoteCalendarForm>) => {
                        return (
                            <>
                                <InputSelect
                                    hidden={!hasCalendarsAvailableForLinking}
                                    name="available_calendar"
                                    optionLabelKey="display_name"
                                    placeholder="Select an external calendar…"
                                    options={calendarsAvailableForLinking}
                                    formikProps={formikProps}
                                />
                                <div hidden={hasCalendarsAvailableForLinking}>
                                    <Icon name="error" className="color-danger" />
                                    All available calendars are already linked.
                                </div>
                                <ModalActions>
                                    <div className="flex-spacer" />
                                    <Button onClick={() => closeModal()}>Cancel</Button>
                                    <ButtonPrimary
                                        type="submit"
                                        hidden={!hasCalendarsAvailableForLinking}
                                        disabled={!formikProps.values.available_calendar}
                                    >
                                        Save
                                    </ButtonPrimary>
                                </ModalActions>
                            </>
                        );
                    }}
                </Form>
            </Modal>

            <Modal id="editTimeOffModal" isOpen={modal.active === 'editTimeOff'} onAfterClose={() => closeModal()}>
                <ModalHeader title={modal.data.title || ''} />
                <Form
                    initialValues={modal.data.formInitialValues || {}}
                    onSubmit={handleSubmitEditTimeOffForm}
                    validationSchema={ScheduleEditorTimeOffValidationSchema}
                >
                    {(formikProps: FormikProps<EditScheduleTimeOffForm>) => {
                        const fieldArrayKey = 'times';
                        const timeRanges: TimeRange[] = formikProps.values[fieldArrayKey];
                        const defaultTime = { startTime: '09:00', endTime: '17:00' };
                        const addTimeRange = (arrayHelpers: ArrayHelpers) => arrayHelpers.push(defaultTime);

                        return (
                            <>
                                <InputText autoFocus={true} name="name" label="Name" formikProps={formikProps} />

                                <InputDate
                                    name="date"
                                    label="Date"
                                    placeholder="Select Date"
                                    disablePast={true}
                                    formikProps={formikProps}
                                />

                                <AppSectionHeader as="Header" title="Time Off">
                                    <InputSwitch
                                        name="allDay"
                                        label="All Day"
                                        labelPlacement="start"
                                        formikProps={formikProps}
                                    />
                                </AppSectionHeader>

                                {!formikProps.values.allDay && (
                                    <div className={styles['time-range-array']}>
                                        <FieldArray name={fieldArrayKey}>
                                            {arrayHelpers => {
                                                return timeRanges.map((timeRange, index) => {
                                                    const timeRangeKey = `${fieldArrayKey}.${index}`;

                                                    return (
                                                        <FastField
                                                            key={timeRangeKey}
                                                            name={timeRangeKey}
                                                            shouldUpdate={shouldUpdateFastField}
                                                        >
                                                            {(fieldProps: FieldProps) => {
                                                                const handleTimeRangeChange: (
                                                                    event: React.ChangeEvent<{}>,
                                                                    value: any
                                                                ) => void = (_, value) => {
                                                                    if (value) {
                                                                        formikProps.setFieldValue(timeRangeKey, value);
                                                                        arrayHelpers.replace(index, value);
                                                                    }
                                                                };

                                                                return (
                                                                    <>
                                                                        {
                                                                            <div
                                                                                className={classNames(
                                                                                    styles['edit-schedule-slider-row'],
                                                                                    index === 0
                                                                                        ? styles[
                                                                                              'edit-schedule-slider-row-first'
                                                                                          ]
                                                                                        : ''
                                                                                )}
                                                                            >
                                                                                <TimeRangeSlider
                                                                                    className={classNames(
                                                                                        styles['edit-schedule-slider'],
                                                                                        styles[
                                                                                            'edit-schedule-slider-time-off'
                                                                                        ]
                                                                                    )}
                                                                                    {...fieldProps.field}
                                                                                    onChange={handleTimeRangeChange}
                                                                                    onBlur={formikProps.handleBlur}
                                                                                />

                                                                                {index > 0 && (
                                                                                    <IconButton
                                                                                        className={
                                                                                            styles[
                                                                                                'edit-schedule-slider-delete-button'
                                                                                            ]
                                                                                        }
                                                                                        icon="close"
                                                                                        onClick={() =>
                                                                                            arrayHelpers.remove(index)
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        }
                                                                        {timeRanges.length === index + 1 && (
                                                                            <ButtonLink
                                                                                className={
                                                                                    styles['edit-schedule-add-button']
                                                                                }
                                                                                onClick={() =>
                                                                                    addTimeRange(arrayHelpers)
                                                                                }
                                                                            >
                                                                                + Add time slot
                                                                            </ButtonLink>
                                                                        )}
                                                                    </>
                                                                );
                                                            }}
                                                        </FastField>
                                                    );
                                                });
                                            }}
                                        </FieldArray>
                                    </div>
                                )}

                                <div>
                                    <InputCheckbox
                                        name="repeatYearly"
                                        label="Keep year-to-year"
                                        formikProps={formikProps}
                                    />
                                </div>

                                <ModalActions>
                                    <div className="flex-spacer" />
                                    <Button onClick={() => closeModal()}>Cancel</Button>
                                    <ButtonPrimary type="submit">Save</ButtonPrimary>
                                </ModalActions>
                            </>
                        );
                    }}
                </Form>
            </Modal>

            <Modal
                id="deleteTimeOffModal"
                isOpen={modal.active === 'deleteTimeOff'}
                onAfterClose={() => dispatch({ name: 'setModalData', payload: {} })}
                closeButton={false}
            >
                <ModalHeader title="Delete Time Off" />
                <p className="text">
                    Are you sure you want to delete{' '}
                    <strong className={styles['bold']}>{modal.data.timeOff?.name}</strong>?
                </p>

                <ModalActions>
                    <div className="flex-spacer" />
                    <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                    <ButtonDanger onClick={() => deleteTimeOff(modal.data.timeOff)}>Delete Time Off</ButtonDanger>
                </ModalActions>
            </Modal>
            <Modal isOpen={unlinkModalOpen} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="color-danger" />
                            Are you sure you want to unlink this remote calendar from your schedule?
                        </>
                    }
                />
                <p>This action cannot be undone.</p>
                <ModalActions>
                    <ActionList position="end">
                        <Button
                            onClick={() => {
                                setUnlinkModalOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <ButtonPrimary
                            onClick={() => {
                                unlinkButtonHandler();
                                setUnlinkModalOpen(false);
                            }}
                        >
                            Confirm
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>
        </div>
    );
};
