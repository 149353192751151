import React, { FC } from 'react';
import { AppSectionHeader, AppCardGrid, StatCard } from '../../components-v2/shared';

import './clarity-dashboard.scss';
import { ClarityReducerState } from './Clarity.helpers';
import { ClarityLoadingStatus } from './Clarity.types';
import { dashboardApps, ulmDashboardApps } from '../Dashboard/HomeDashboard.helpers';
import { AppIcons } from '../../constants/apps';
import { numberFormatter } from '../../util/formatters';
import { ClarityDashboardCharts } from './ClarityDashboardCharts';

export const ClarityDashboardViewerTab: FC<{
    state: ClarityReducerState;
}> = ({ state }) => {
    return (
        <>
            <AppSectionHeader title="Leads Per Application"></AppSectionHeader>

            {state.data && (
                <>
                    <AppCardGrid className="clarity-dashboard-stat-grid">
                        {dashboardApps.map(({ type, label }) => {
                            const value: number | undefined = state.data?.totals.leadsByModule[type];

                            if (value === undefined) {
                                return null;
                            }

                            return (
                                <StatCard
                                    key={type}
                                    label={label}
                                    value={numberFormatter(value)}
                                    color="blue"
                                    icon={AppIcons[type]}
                                />
                            );
                        })}
                        {ulmDashboardApps.map(({ type, label }) => {
                            const value: number | undefined = state.data?.totals?.ulmEventTotals![type];

                            if (value === undefined || value === 0) {
                                return null;
                            }

                            return (
                                <StatCard
                                    key={type}
                                    label={label}
                                    value={numberFormatter(value)}
                                    color="blue"
                                    icon={AppIcons[type]}
                                />
                            );
                        })}
                    </AppCardGrid>
                </>
            )}

            {state.loadingStatus === ClarityLoadingStatus.complete && (
                <ClarityDashboardCharts state={state} onDragEnd={undefined} />
            )}
        </>
    );
};
