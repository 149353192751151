import React from 'react';
import styles from './typography.module.scss';
import { HeadingTags } from './typography.types';
import classNames from 'classnames';

export const Header = ({
    children,
    as: Component = 'h3',
    className,
    ...rest
}: React.PropsWithChildren<{ as?: keyof typeof HeadingTags; className?: string }>) => (
    <Component className={classNames(styles['header'], 'header', className)} {...rest}>
        {children}
    </Component>
);
