import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Alert, Col, PageHeader, Row } from 'react-bootstrap';
import { InjectedProps } from '../../types';
import MatchbackImportList from './MatchbackImportList';
import MatchbackImportDelete from './MatchbackImportDelete';
import ButtonSpinner from '../misc/ButtonSpinner';
import FieldFileUpload from '../misc/FieldFileUpload';

interface MatchbackState {
    imported?: number;
    file?: File;
    errorMessage: string;
    importId: number;
    list: any;
    settings?: { [id: number]: string };
    availableSetting: any;
    step: number;
    bundleId: number;
    waitImported: number;
    uploading: boolean;
}

@inject('store')
@observer
export class MatchbackImport extends React.Component<{}, MatchbackState> {
    private ImportStep1 = 1;
    private ImportStep2 = 2;
    private ImportStep3 = 3;
    private ImportStep4 = 4;

    public get injected() {
        return this.props as InjectedProps;
    }

    public state: MatchbackState = {
        file: undefined,
        step: this.ImportStep1,
        importId: 0,
        errorMessage: '',
        list: [],
        settings: [],
        availableSetting: [],
        waitImported: 0,
        imported: undefined,
        bundleId: 0,
        uploading: false
    };

    public render() {
        return (
            <React.Fragment>
                <React.Fragment>
                    <PageHeader>Matchback Import</PageHeader>
                    <Col className="col-lg-12 holder-import-box">
                        {this.state.errorMessage ? (
                            <Alert bsStyle="danger" onDismiss={this.clearResult}>
                                {this.state.errorMessage}
                            </Alert>
                        ) : null}
                        {this.state.step === this.ImportStep2 ? (
                            <Alert bsStyle="warning">Please, associate columns with imported fields.</Alert>
                        ) : null}
                        {this.state.step === this.ImportStep3 ? (
                            <Alert bsStyle={this.state.imported === this.state.list.length - 1 ? 'success' : 'danger'}>
                                Checked: {this.state.imported} from {this.state.list.length - 1} records.
                            </Alert>
                        ) : null}
                        {this.state.step === this.ImportStep4 ? (
                            <Alert bsStyle={this.state.imported === this.state.waitImported ? 'success' : 'danger'}>
                                Imported: {this.state.imported} from {this.state.waitImported} records.
                            </Alert>
                        ) : null}
                        <Row>
                            <form>
                                <div className={`modal-crop-input group-choose-file`}>
                                    <FieldFileUpload onClearFile={this.clearFile} onChangeFile={this.onSelected} />
                                </div>
                                <div className="btn-toolbar">
                                    {this.state.step === this.ImportStep1 ? (
                                        <ButtonSpinner
                                            isSaving={this.state.uploading}
                                            onClick={this.handleSubmit(this.ImportStep2)}
                                        >
                                            Upload CSV
                                        </ButtonSpinner>
                                    ) : null}

                                    {this.state.step === this.ImportStep2 ? (
                                        <ButtonSpinner
                                            isSaving={this.state.uploading}
                                            onClick={this.handleSubmit(this.ImportStep3)}
                                        >
                                            Next step: Check records
                                        </ButtonSpinner>
                                    ) : null}

                                    {this.state.step === this.ImportStep3 && !this.state.imported ? (
                                        <ButtonSpinner
                                            isSaving={this.state.uploading}
                                            onClick={this.handleSubmit(this.ImportStep3)}
                                        >
                                            Next step: Check records
                                        </ButtonSpinner>
                                    ) : null}

                                    {this.state.step === this.ImportStep3 && this.state.imported ? (
                                        <ButtonSpinner
                                            isSaving={this.state.uploading}
                                            onClick={this.handleSubmit(this.ImportStep4)}
                                        >
                                            Final step: Save {this.state.imported} from {this.state.list.length - 1}{' '}
                                            records
                                        </ButtonSpinner>
                                    ) : (
                                        <div className="csv-none-button" />
                                    )}
                                </div>
                            </form>
                        </Row>
                    </Col>
                    {this.state.list.length ? (
                        <MatchbackImportList
                            availableSetting={this.state.availableSetting}
                            list={this.state.list}
                            returnImportSettings={this.returnImportSettings}
                        />
                    ) : null}
                </React.Fragment>
                <div>
                    {this.injected.store.isSuperAdmin() && <MatchbackImportDelete importId={this.state.importId} />}
                </div>
            </React.Fragment>
        );
    }

    public returnImportSettings = (settings: { [id: number]: string }) => {
        this.setState({ settings });
    };

    private onSelected = (file: File) => {
        this.setState({
            file,
            step: this.ImportStep1,
            list: [],
            settings: [],
            availableSetting: [],
            errorMessage: '',
            imported: undefined,
            bundleId: 0,
            waitImported: 0
        });
    };

    private handleSubmit = (nextStep: number) => () => {
        const { Api, activeCompanyId } = this.injected.store;

        if (!this.state.file) {
            this.setError('Select a matchback file.');
            return;
        }

        if (this.state.step === this.ImportStep2) {
            const waitImported = this.state.imported || 0;
            this.setState({ waitImported });
        }

        const saveResult = nextStep === this.ImportStep4;
        const settings = nextStep === this.ImportStep2 ? undefined : this.state.settings;

        this.setState({ uploading: true });

        Api.importMatchbackFile(activeCompanyId, this.state.file!, saveResult, settings)
            .then((content: any) => {
                this.setState({
                    step: nextStep,
                    list: content.data.list,
                    availableSetting: content.data.availableSetting,
                    imported: content.data.imported,
                    bundleId: content.data.bundleId
                });
            })
            .catch(() => '')
            .finally(() => {
                this.setState({ uploading: false });
            });
    };

    private setError = (errorMessage: string) => {
        this.setState({
            errorMessage,
            file: undefined
        });
    };

    private clearResult = () => {
        this.setError('');
    };

    private clearFile = () => {
        this.setState({
            file: undefined,
            step: this.ImportStep1,
            importId: 0,
            errorMessage: '',
            list: [],
            settings: [],
            availableSetting: [],
            waitImported: 0,
            imported: undefined,
            bundleId: 0
        });
    };
}

export default MatchbackImport;
