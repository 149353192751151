import * as Yup from 'yup';

export const TextUsNowFormValidationSchema = () =>
    Yup.object({
        call_to_action_text: Yup.string().required('Please enter the call to action text.'),
        twilio_id: Yup.string()
            .nullable()
            .required('Please select a Twilio phone number.'),
        notification_start_time: Yup.string().notRequired().nullable(),
        notification_end_time: Yup.string()
            .notRequired()
            .nullable()
            .test(
                'is-after-start',
                'End time must be after start time',
                function (value) {
                    const { notification_start_time } = this.parent;
                    if (!notification_start_time) { return true }
                    return value > notification_start_time;
                }
            ),
        forward_phone_number: Yup.string()
            .notRequired()
            .nullable()
            .matches(
                /^(\+?1\s?)?(\(\d{3}\)|\d{3})([-.\s]?)\d{3}([-.\s]?)\d{4}$/,
                'Invalid Phone number'
            ),
    });
