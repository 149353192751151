export const breakpoints = {
    xs: 480,
    sm: 790,
    md: 960,
    lg: 1280,
    xl: 1440
};

export const isMinScreenSize = (size: keyof typeof breakpoints) => window.outerWidth >= breakpoints[size];

export const isMaxScreenSize = (size: keyof typeof breakpoints) => window.outerWidth < breakpoints[size];
