import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Illustration } from './OnboardingIllustration2.svg';
import { ReactComponent as SupportIllustration } from './OnboardingIllustration3.svg';
import styles from './company-onboarding.module.scss';
import './company-onboarding.global.scss';
import { ButtonLink, ButtonPrimary, StepChecklist, Title, useSnackbar } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { observer } from 'mobx-react';
import { handlePromise } from '../../util/async';

import DefaultRoutes from '../../routes/DefaultRoutes';
import { AgencyLogo } from '../Agency/AgencyLogo';

export const CompanyOnboardingSMSPage: React.FC<{}> = observer(() => {
    const [status, setStatus] = useState<'waiting' | 'loading'>('waiting');
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const {
        router,
        Api: {
            client: { post }
        },
        activeCompany,
        getCompanyFeatures,
        saveCompanyFeatures
    } = store;

    const continueOn = () => router.goTo(DefaultRoutes.CompanyOnboardingEmbedPage, router.params, store);

    const onError: (note: string) => void = note => {
        setStatus('waiting');
        addSnackbar(note, {
            variant: 'error'
        });
    };

    const enableSMS = async () => {
        setStatus('loading');

        const [response, error] = await handlePromise(post(`/twilio-numbers/${activeCompany.id}/auto-assign`));

        if (!response || error) {
            return onError(`An error occurred when trying to provision your number.`);
        }

        getCompanyFeatures().then(async ({ data: features }) => {
            const currentFeatures = Object.entries(features)
                .map(([key, value]) => (value ? key : ''))
                .filter(key => key !== '');

            await saveCompanyFeatures([...currentFeatures, 'sms_messaging']);
        });

        return continueOn();
    };

    return (
        <div className={classNames('company-onboarding', styles.companyOnboarding, styles.companyOnboardingSMS)}>
            <header>
                <div className={styles.companyOnboardingAgencyLogo}>
                    <AgencyLogo />
                </div>

                <StepChecklist
                    className={styles.companyOnboardingStepChecklist}
                    steps={['Credentials', 'Company Info', 'Enable SMS', 'Embed App', 'Invite Team']}
                    currentStepIndex={2}
                />

                <Illustration className={classNames(styles.companyOnboardingIllustration)} />
            </header>
            <main className={styles.companyOnboardingContent}>
                <SupportIllustration className={styles.companyOnboardingSMSIllustration} />
                <Title>Enable customer text messaging</Title>
                <p className="text">
                    Enable a Twilio SMS number to text leads valuable information and appointment details. Only
                    customers who opt in will receive messages. Adding an SMS Twilio number starts a monthly
                    subscription.
                </p>
                <div className={styles.companyOnboardingActions}>
                    <ButtonLink onClick={continueOn}>Continue without text messaging</ButtonLink>
                    <ButtonPrimary onClick={enableSMS} disabled={status === 'loading'}>
                        {status === 'loading' ? 'Enabling...' : 'Enable an SMS Number'}
                    </ButtonPrimary>
                </div>
            </main>
        </div>
    );
});
