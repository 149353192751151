import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputCheckbox
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import * as Yup from 'yup';
import { crmConfigTypes, RulesActionFactory, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { RuleAction } from '../../../../types';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';

export interface AdfFormProps {
    initialValues: AdfCrm;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

const AdfFormValidationSchema = () =>
    Yup.object({
        name: Yup.string().max(250, 'The CRM name may be no more than 250 characters.'),
        configuration: Yup.object({
            contact_email: Yup.string().email('Must be a valid email address')
        })
    });

export interface AdfCrm extends Crm {
    configuration: {
        crm_emails: string[] | string;
        contact_first_name: string;
        contact_last_name: string;
        contact_email: string;
        send_xml_in_email_body: boolean;
        send_xml_attachment: boolean;
    };
}

const ruleActions: Array<RuleAction> = [RulesActionFactory.ReplaceCrmEmailsAction];

export const AdfForm: FC<AdfFormProps> = observer(({ onSubmit, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleSubmit = async (values: AdfCrm, actions: FormikHelpers<AdfCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.adf);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            validationSchema={AdfFormValidationSchema}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {formikProps => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: ADF" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader title="Contact" />
                                <InputText
                                    name="configuration.contact_first_name"
                                    label="Contact First Name"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.contact_last_name"
                                    label="Contact Last Name"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.contact_email"
                                    label="Contact Email"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader title="Delivery Options" />
                                <InputText
                                    name="configuration.crm_emails"
                                    label="CRM Email"
                                    helperText="The email address that gets the XML-formatted content"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputCheckbox
                                    name="configuration.send_xml_in_email_body"
                                    label="Include XML in email body"
                                    formikProps={formikProps}
                                />
                                <InputCheckbox
                                    name="configuration.send_xml_attachment"
                                    label="Include XML as attachment"
                                    formikProps={formikProps}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
