import React from 'react';
import { Route } from 'mobx-router';
import { dirBasename } from '../constants';
import { FEATURES } from '../types/Features';
import { checkUserPermissions, checkFeatures, checkUserHome, checkPasswordReset, routeCheckAll, checkNotUniversal } from './routeUtils';
import { EmailEditorListPage } from '../components/EmailEditor/EmailEditorListPage';
import { EmailEditorEditAppEmailPage } from '../components/EmailEditor/EmailEditorEditAppEmailPage';
import { ScheduleEditorListPage } from '../components/ScheduleEditor/ScheduleEditorListPage';
import { ScheduleEditorEditSchedulePage } from '../components/ScheduleEditor/ScheduleEditorEditSchedulePage';
import { ConcessionManagerList, ConcessionManagerEdit, ConcessionManagerNew } from '../components/ConcessionManager';
import { ScheduleGenieAppPage } from '../components/ScheduleGenie/ScheduleGenieAppPage';
import {
    CompanyOnboardingCredentialsPage,
    CompanyOnboardingCompanyDetailsPage,
    CompanyOnboardingSMSPage,
    CompanyOnboardingEmbedPage,
    CompanyOnboardingInviteTeamPage
} from '../components/CompanyOnboarding';
import { TextUsNowNew } from '../components/TextUsNow';
import { WebNotifications } from '../components/WebNotifications/WebNotifications';
import { IncomeCalculatorNew } from '../components/IncomeCalculator/IncomeCalculatorNew';
import { LiveChatLegacy } from '../components/LiveChatLegacy/LiveChatLegacy';
import { Insite } from '../components/Insite/Insite';
import { CallUsNow } from '../components/CallUsNow/CallUsNow';
import { HomeDashboard } from '../components/Dashboard/HomeDashboard';
import { CrmEdit, CrmList, CrmNew } from '../components/Crm';
import { CrmSelectType } from '../components/Crm/CrmSelectType';
import { RemoteAuthList } from '../components/RemoteAuth/RemoteAuthList';
import { CompanyDetails } from '../components/Company/CompanyDetails';
import CompanyList from '../components/Company/CompanyList';
import { CompanyCreate } from '../components/Company/CompanyCreate';
import InsiteMetricDataList from '../components/InsiteMetricData/InsiteMetricDataList';
import InsiteDefinedFieldsList from '../components/InsiteMetricData/InsiteDefinedFieldsList';
import InsitePageList from '../components/InsiteMetricData/InsitePageList';
import FeatureFlags from '../components/FeatureFlags/FeatureFlags';
import { Messages } from '../components/Messages';
import UserEdit from '../components/User/UserEdit';
import { LeadView } from '../components/Lead/LeadView/LeadView';
import { ApplicationSettingsWrapper } from '../components/ApplicationSettings';
import { ClarityDashboard } from '../components/Clarity/ClarityDashboard';
import CompanyRoleList from '../components/CompanyRole/CompanyRoleList';
import { IntegrationsPage } from '../components/Company/CompanyIntegrations/IntegrationsPage/IntegrationsPage';
import { RealyncIntegration } from '../components/Company/CompanyIntegrations/RealyncIntegration/RealyncIntegration';
import Features from '../components/Features/Features';
import { LeadDetails } from '../components/Lead/LeadDetails/LeadDetails';
import { ChatBotEdit, QuestionList, QuestionNew, QuestionEdit } from '../components/ChatBot';
import { ApiKeyList } from '../components/ApiKeyManager/ApiKeyList';
import { DripScheduleEditorListPage } from '../components/DripScheduleEditor/DripScheduleEditorListPage';
import { DripScheduleEditorEditSchedulePage } from '../components/DripScheduleEditor/DripScheduleEditorEditSchedulePage';
import { DripScheduleReportPage } from '../components/DripScheduleEditor/DripScheduleReportPage';
import { QuestionDefinitionPresets } from '../components/QuestionDefinitionPresets/QuestionDefinitionPresets';
import { LeadImport } from '../components/Lead/ContactImport/ContactImport';
import { AdvancedCustomerSearch } from '../components/Lead/AdvancedSearch/AdvancedSearch';
import { ListSegmentEditorListPage } from '../components/ListSegmentEditor/ListSegmentEditorListPage';
import { ListSegmentEditorEditSegmentPage } from '../components/ListSegmentEditor/ListSegmentEditorEditListPage';
import { BlastScheduleEditorListPage } from '../components/BlastScheduleEditor/BlastScheduleEditorListPage';
import { BlastScheduleEditorEditSchedulePage } from '../components/BlastScheduleEditor/BlastScheduleEditorEditSchedulePage';
import { BlastScheduleReportPage } from '../components/BlastScheduleEditor/BlastScheduleReportPage';
import { EmailViewer } from '../components/EmailViewer/EmailViewer';
import { OptOutQueueList } from '../components/OptOutQueue/OptOutList';
import AuditLogs from '../components/Audit/AuditLog';
import CssToolList from '../components/CssTool/CssToolList';
import CsvImport from '../components/CsvImport/CsvImport';
import { EmailSenderProfile } from '../components/EmailSenderProfile/EmailSenderProfile';
import { LiveChat } from '../components/LiveChat/LiveChat';
import { ChatBotAIComponent } from '../components/ChatBotAI';
import { UpdateWidgetCode } from '../components/ChatBotAI/WidgetCode';
import { CompanyLists } from '../components/CompanyLists/CompanyLists';
import MatchbackImport from '../components/Matchback/MatchbackImport';
import { InterceptionsList } from '../components/TextUsNow/InterceptionList';

const routeBasename = dirBasename ? dirBasename : '';
const companyRoutesBase = `${routeBasename}/company/:companyId`;

// Company Onboarding routes
const onboardingRouteBasename = `${routeBasename}/company/:token/invite`;
const OnboardingRoutes = {
    CompanyOnboardingCredentialsPage: new Route({
        path: onboardingRouteBasename,
        component: <CompanyOnboardingCredentialsPage />
    }),
    CompanyOnboardingCompanyDetailsPage: new Route({
        path: `${onboardingRouteBasename}/details`,
        component: <CompanyOnboardingCompanyDetailsPage />
    }),
    CompanyOnboardingSMSPage: new Route({
        path: `${onboardingRouteBasename}/sms`,
        component: <CompanyOnboardingSMSPage />
    }),
    CompanyOnboardingEmbedPage: new Route({
        path: `${onboardingRouteBasename}/embed`,
        component: <CompanyOnboardingEmbedPage />
    }),
    CompanyOnboardingInviteTeamPage: new Route({
        path: `${onboardingRouteBasename}/team`,
        component: <CompanyOnboardingInviteTeamPage />
    })
};

// Dashboard routes
const dashboardRouteBasename = `${companyRoutesBase}/dashboard`;
const DashboardRoutes = {
    CompanyDashboard: new Route({
        displayName: 'Dashboard',
        path: dashboardRouteBasename,
        component: <HomeDashboard />,
        beforeEnter: routeCheckAll([checkUserHome(), checkPasswordReset()])
    })
};

// Lead Routes
const LeadRoutes = {
    CompanyLeadView: new Route({
        displayName: 'Leads',
        path: `${companyRoutesBase}/leads`,
        component: <LeadView />,
        beforeEnter: routeCheckAll([checkUserPermissions('leads', 'view'), checkPasswordReset(), checkNotUniversal()])
    }),
    CompanyContactImport: new Route({
        displayName: 'Import Contacts',
        path: `${companyRoutesBase}/import-contacts`,
        component: <LeadImport />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    }),
    CompanyAdvancedCustomerSearch: new Route({
        displayName: 'Advanced Search',
        path: `${companyRoutesBase}/advanced-search`,
        component: <AdvancedCustomerSearch />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'view'), checkPasswordReset()])
    }),
    CompanyInsiteMetricDataList: new Route({
        displayName: 'Insite Metric',
        path: `${companyRoutesBase}/insite-metric-data`,
        component: <InsiteMetricDataList />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    }),
    CompanyInsiteDefinedFieldsList: new Route({
        displayName: 'Insite Metric',
        path: `${companyRoutesBase}/insite-defined-fields`,
        component: <InsiteDefinedFieldsList />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    }),
    CompanyInsitePageList: new Route({
        displayName: 'Insite Metric',
        path: `${companyRoutesBase}/insite-page`,
        component: <InsitePageList />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    })
};

// Schedule Editor routes
const scheduleEditorRouteBasename = `${companyRoutesBase}/availability-schedules`;
const ScheduleEditorRoutes = {
    ScheduleEditorListPage: new Route({
        displayName: 'Availability',
        path: scheduleEditorRouteBasename,
        component: <ScheduleEditorListPage />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    }),
    ScheduleEditorEditSchedulePage: new Route({
        displayName: 'Availability',
        path: `${scheduleEditorRouteBasename}/:scheduleId`,
        component: <ScheduleEditorEditSchedulePage />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    })
};

// Question Definition Presets route
const questionDefinitionPresetRouteBasename = `${companyRoutesBase}/question-definition-presets`;
const QuestionDefinitionPresetRoutes = {
    QuestionDefinitionPresetPage: new Route({
        displayName: 'Question Presets',
        path: questionDefinitionPresetRouteBasename,
        component: <QuestionDefinitionPresets />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    })
};

// Drip Schedule Editor routes
const listSegmentEditorRouteBasename = `${companyRoutesBase}/list-segments`;
const ListSegmentEditorRoutes = {
    ListSegmentEditorListPage: new Route({
        displayName: 'List Segments',
        path: listSegmentEditorRouteBasename,
        component: <ListSegmentEditorListPage />,
        beforeEnter: routeCheckAll([
            checkUserPermissions('companies', 'update'),
            checkPasswordReset(),
            checkFeatures(FEATURES.lead_nurturing)
        ])
    }),
    EditListSegmentPage: new Route({
        displayName: 'List Segments',
        path: `${listSegmentEditorRouteBasename}/:listSegmentId`,
        component: <ListSegmentEditorEditSegmentPage />,
        beforeEnter: routeCheckAll([
            checkUserPermissions('companies', 'update'),
            checkPasswordReset(),
            checkFeatures(FEATURES.lead_nurturing)
        ])
    }),
};

// Drip Schedule Editor routes
const dripScheduleEditorRouteBasename = `${companyRoutesBase}/drip-schedules`;
const DripScheduleEditorRoutes = {
    DripScheduleEditorListPage: new Route({
        displayName: 'Drip Schedules',
        path: dripScheduleEditorRouteBasename,
        component: <DripScheduleEditorListPage />,
        beforeEnter: routeCheckAll([
            checkUserPermissions('companies', 'update'),
            checkPasswordReset(),
            checkFeatures(FEATURES.lead_nurturing)
        ])
    }),
    EditDripSchedulePage: new Route({
        displayName: 'Drip Schedules',
        path: `${dripScheduleEditorRouteBasename}/:scheduleId`,
        component: <DripScheduleEditorEditSchedulePage />,
        beforeEnter: routeCheckAll([
            checkUserPermissions('companies', 'update'),
            checkPasswordReset(),
            checkFeatures(FEATURES.lead_nurturing)
        ])
    }),
    DripScheduleReportPage: new Route({
        displayName: 'Drip Schedule Report',
        path: `${dripScheduleEditorRouteBasename}/:scheduleId/report`,
        component: <DripScheduleReportPage />,
        beforeEnter: routeCheckAll([
            checkUserPermissions('companies', 'view'),
            checkPasswordReset(),
            checkFeatures(FEATURES.lead_nurturing)
        ])
    })
};

// Scheduled Blast Editor routes
const blastScheduleEditorRouteBasename = `${companyRoutesBase}/blast-schedules`;
const BlastScheduleEditorRoutes = {
    BlastScheduleEditorListPage: new Route({
        displayName: 'Scheduled Blasts',
        path: blastScheduleEditorRouteBasename,
        component: <BlastScheduleEditorListPage />,
        beforeEnter: routeCheckAll([
            checkUserPermissions('companies', 'update'),
            checkPasswordReset(),
            checkFeatures(FEATURES.lead_nurturing)
        ])
    }),
    EditBlastSchedulePage: new Route({
        displayName: 'Scheduled Blasts',
        path: `${blastScheduleEditorRouteBasename}/:scheduleId`,
        component: <BlastScheduleEditorEditSchedulePage />,
        beforeEnter: routeCheckAll([
            checkUserPermissions('companies', 'update'),
            checkPasswordReset(),
            checkFeatures(FEATURES.lead_nurturing)
        ])
    }),
    BlastScheduleReportPage: new Route({
        displayName: 'Scheduled Blast Report',
        path: `${blastScheduleEditorRouteBasename}/:scheduleId/report`,
        component: <BlastScheduleReportPage />,
        beforeEnter: routeCheckAll([
            checkUserPermissions('companies', 'view'),
            checkPasswordReset(),
            checkFeatures(FEATURES.lead_nurturing)
        ])
    }),
    EmailSenderProfile: new Route({
        displayName: 'Email Sender Profile',
        path: `${companyRoutesBase}/email-sender-profile`,
        component: <EmailSenderProfile />,
        beforeEnter: routeCheckAll([
            checkUserPermissions('companies', 'update'),
            checkPasswordReset(),
            checkFeatures(FEATURES.lead_nurturing)
        ])
    })
};

// Email Editor routes
const emailEditorRouteBase = `${companyRoutesBase}/email-templates`;
const EmailEditorRoutes = {
    EmailTemplatesList: new Route({
        displayName: 'Email Templates',
        path: emailEditorRouteBase,
        component: <EmailEditorListPage />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    }),
    EmailEditorEditAppEmailPage: new Route({
        displayName: 'Email Templates',
        path: `${emailEditorRouteBase}/:appEmailId`,
        component: <EmailEditorEditAppEmailPage />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    })
};

// Concession Manager/Spotlight routes
const concessionManagerRouteBase = `${companyRoutesBase}/spotlight`;
const ConcessionManagerRoutes = {
    ConcessionManagerList: new Route({
        displayName: 'Spotlight',
        path: concessionManagerRouteBase,
        component: <ConcessionManagerList />,
        beforeEnter: () =>
            routeCheckAll([
                checkUserPermissions('offers', 'view'),
                checkFeatures(FEATURES.concession_manager),
                checkPasswordReset()
            ])
    }),
    ConcessionManagerNew: new Route({
        displayName: 'Spotlight',
        path: `${concessionManagerRouteBase}/new`,
        component: <ConcessionManagerNew />,
        beforeEnter: () =>
            routeCheckAll([
                checkUserPermissions('offers', 'create'),
                checkFeatures(FEATURES.concession_manager),
                checkPasswordReset()
            ])
    }),
    ConcessionManagerEdit: new Route({
        displayName: 'Spotlight',
        path: `${concessionManagerRouteBase}/:offerId`,
        component: <ConcessionManagerEdit />,
        beforeEnter: () =>
            routeCheckAll([
                checkUserPermissions('offers', 'update'),
                checkFeatures(FEATURES.concession_manager),
                checkPasswordReset()
            ])
    })
};

// CRM routes
const crmRouteBase = `${companyRoutesBase}/crm`;
const CrmRoutes = {
    CrmList: new Route({
        displayName: 'CRM Configurations',
        path: crmRouteBase,
        component: <CrmList />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'view'), checkPasswordReset()])
    }),
    CrmSelectType: new Route({
        displayName: 'CRM Configurations',
        path: `${crmRouteBase}/new`,
        component: <CrmSelectType />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    }),
    CrmNew: new Route({
        displayName: 'CRM Configurations',
        path: `${crmRouteBase}/new/:crmType`,
        component: <CrmNew />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    }),
    CrmEdit: new Route({
        displayName: 'CRM Configurations',
        path: `${crmRouteBase}/:crmId`,
        component: <CrmEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    })
};

const scheduleGenieRouteBase = `${companyRoutesBase}/schedule-genie`;
const ScheduleGenieRoutes = {
    ScheduleGenieAppPage: new Route({
        displayName: 'Schedule Genie',
        path: `${scheduleGenieRouteBase}`,
        component: <ScheduleGenieAppPage />,
        beforeEnter: () =>
            routeCheckAll([
                checkUserPermissions('calendars', 'view'),
                checkFeatures(FEATURES.schedule_genie),
                checkPasswordReset()
            ])
    })
};

const textUsNowRouteBase = `${companyRoutesBase}/text-us-now`;
const TextUsNowRoutes = {
    TextUsNow: new Route({
        displayName: 'Text Us Now',
        path: `${textUsNowRouteBase}`,
        component: <TextUsNowNew />,
        beforeEnter: () =>
            routeCheckAll([
                checkUserPermissions('callus', 'create'),
                checkFeatures(FEATURES.text_us_now),
                checkPasswordReset()
            ])
    })
};

const WebNotificationsRoutes = {
    WebNotifications: new Route({
        displayName: 'Web Notifications',
        path: `${companyRoutesBase}/web-notifications`,
        component: <WebNotifications />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'view'), checkPasswordReset()])
    })
};

const ChatBotAiRoutes = {
    LiveChatAIManualLeadSubmission: new Route({
        displayName: 'Claire AI+',
        path: `${companyRoutesBase}/claire-ai`,
        component: <ChatBotAIComponent />,
        beforeEnter: routeCheckAll([checkUserPermissions('chatbot_ai', 'view'), checkPasswordReset()])
    }),

    ChatBotAIWidgetCode: new Route({
        displayName: 'Claire AI+',
        path: `${companyRoutesBase}/update-widget-code`,
        component: <UpdateWidgetCode />,
        beforeEnter: routeCheckAll([checkFeatures(FEATURES.chatbot_AI), checkPasswordReset()])
    }),
};

const IncomeCalculatorRoutes = {
    IncomeCalculator: new Route({
        displayName: 'Income Calculator',
        path: `${companyRoutesBase}/income-calculator`,
        component: <IncomeCalculatorNew />,
        beforeEnter: routeCheckAll([checkFeatures(FEATURES.income_calculator), checkPasswordReset()])
    })
};

const LiveChatLegacyRoutes = {
    LiveChatLegacy: new Route({
        displayName: 'Live Chat Legacy',
        path: `${companyRoutesBase}/live-chat-legacy`,
        component: <LiveChatLegacy />,
        beforeEnter: () =>
            routeCheckAll([
                checkUserPermissions('live_chat_legacy', 'view'),
                checkFeatures(FEATURES.chat),
                checkPasswordReset()
            ])
    })
};

const LiveChatRoutes = {
    LiveChat: new Route({
        displayName: 'Live Chat',
        path: `${companyRoutesBase}/live-chat`,
        component: <LiveChat />,
        beforeEnter: () =>
            routeCheckAll([
                checkUserPermissions('live_chat', 'view'),
                checkFeatures(FEATURES.live_chat),
                checkPasswordReset()
            ])
    })
};

const InsiteRoutes = {
    Insite: new Route({
        displayName: 'Insite',
        path: `${companyRoutesBase}/insite`,
        component: <Insite />,
        // Note: The deprecated `Insite` route has the following `beforeEnter` check:
        // checkUserPermissions('calendars', 'view') && checkFeatures(FEATURES.insite)
        // Question: Should we be checking for `calendars` or `insite`? `calendars` seems wrong to me...
        beforeEnter: () =>
            routeCheckAll([
                checkUserPermissions('insite', 'view'),
                checkFeatures(FEATURES.insite),
                checkPasswordReset()
            ])
    })
};

const CallUsNowRoutes = {
    CallUsNow: new Route({
        displayName: 'Call Us Now',
        path: `${companyRoutesBase}/call-us-now`,
        component: <CallUsNow />,
        beforeEnter: () =>
            routeCheckAll([
                checkUserPermissions('callus', 'view'),
                checkFeatures(FEATURES.call_us_now),
                checkPasswordReset()
            ])
    })
};


const chatBotRouteBase = `${companyRoutesBase}/virtual-assistant`;
const ChatBotRoutes = {
    ChatBot: new Route({
        displayName: 'Virtual Assistant',
        path: chatBotRouteBase,
        component: <ChatBotEdit />,
        beforeEnter: routeCheckAll([checkFeatures(FEATURES.chatbot), checkPasswordReset()])
    }),

    ChatBotTopicList: new Route({
        displayName: 'Virtual Assistant',
        path: `${chatBotRouteBase}/topics`,
        component: <QuestionList />,
        beforeEnter: routeCheckAll([checkFeatures(FEATURES.chatbot), checkPasswordReset()])
    }),

    ChatBotQuestionNew: new Route({
        displayName: 'New Vistual Assistant Question',
        path: `${chatBotRouteBase}/:chatbotId/new`,
        component: <QuestionNew />,
        beforeEnter: routeCheckAll([
            // checkUserPermissions('offers', 'update'),
            checkFeatures(FEATURES.chatbot),
            checkPasswordReset()
        ])
    }),

    ChatBotQuestionEdit: new Route({
        displayName: 'Edit Virtual Assistant Question',
        path: `${chatBotRouteBase}/:questionId`,
        component: <QuestionEdit />,
        beforeEnter: () =>
            routeCheckAll([
                // checkUserPermissions('offers', 'update'),
                checkFeatures(FEATURES.chatbot),
                checkPasswordReset()
            ])
    })
};

const RemoteAuthRoutes = {
    RemoteAuthList: new Route({
        displayName: 'Remote Auths',
        path: routeBasename + `${companyRoutesBase}/remote-auths`,
        component: <RemoteAuthList />,
        beforeEnter: routeCheckAll([checkUserPermissions('schedules', 'view'), checkPasswordReset()])
    })
};

const CompanyDetailsRoutes = {
    CompanyEdit: new Route({
        displayName: 'Company Settings',
        path: `${companyRoutesBase}/settings`,
        component: <CompanyDetails />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'view'), checkPasswordReset()])
    }),
    CompanyList: new Route({
        path: routeBasename + '/companies',
        component: <CompanyList />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'view'), checkPasswordReset()])
    }),
    CompanyCreate: new Route({
        path: routeBasename + '/company/new',
        component: <CompanyCreate />,
        displayName: 'New Company',
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'create'), checkPasswordReset()])
    }),
    CompanyIntegrations: new Route({
        path: `${companyRoutesBase}/integrations`,
        component: <IntegrationsPage />,
        displayName: 'Integrations',
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'view'), checkPasswordReset()])
    }),
    RealyncIntegration: new Route({
        path: `${companyRoutesBase}/integrations/realync`,
        component: <RealyncIntegration />,
        displayName: 'Integrations',
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'view'), checkPasswordReset()])
    })
};

const CompanyToolsRoutes = {
    CompanyLists: new Route({
        displayName: 'Company Lists',
        path: routeBasename + '/company-lists',
        component: <CompanyLists />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'view'), checkPasswordReset()])
    }),
    CompanyEmailViewer: new Route({
        displayName: 'Email Viewer',
        path: `${companyRoutesBase}/email-viewer`,
        component: <EmailViewer />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'view'), checkPasswordReset()])
    }),
    CompanyOptOutQueueList: new Route({
        displayName: 'CCPA Opt Out Queue',
        path: `${companyRoutesBase}/opt-out-queue`,
        component: <OptOutQueueList />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'view'), checkPasswordReset()])
    }),
    CompanyAuditLog: new Route({
        path: `${companyRoutesBase}/audit`,
        component: <AuditLogs />,
        displayName: 'Audit Logs',
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'dashboard'), checkPasswordReset()])
    }),
    CompanyCssToolsList: new Route({
        displayName: 'CSS Tools',
        path: companyRoutesBase + '/css-tool',
        component: <CssToolList />,
        beforeEnter: routeCheckAll([checkUserPermissions('roles', 'view'), checkPasswordReset()])
    }),
    CompanyCsvImport: new Route({
        displayName: 'CSV Import',
        path: companyRoutesBase + '/csv-import',
        component: <CsvImport />,
        beforeEnter: routeCheckAll([checkUserPermissions('roles', 'view'), checkPasswordReset()])
    }),
    TextUsNowinterception: new Route({
        displayName: 'TUN Interceptions',
        path: companyRoutesBase + '/text-us-now-interception',
        component: <InterceptionsList />,
        beforeEnter: routeCheckAll([checkUserPermissions('roles', 'view'), checkPasswordReset()])
    }),

}

export const CompanyRoutes = {
    ...OnboardingRoutes,
    ...DashboardRoutes,
    ...LeadRoutes,
    ...EmailEditorRoutes,
    ...ScheduleEditorRoutes,
    ...DripScheduleEditorRoutes,
    ...BlastScheduleEditorRoutes,
    ...ListSegmentEditorRoutes,
    ...ConcessionManagerRoutes,
    ...ScheduleGenieRoutes,
    ...QuestionDefinitionPresetRoutes,
    ...TextUsNowRoutes,
    ...WebNotificationsRoutes,
    ...ChatBotAiRoutes,
    ...IncomeCalculatorRoutes,
    ...LiveChatRoutes,
    ...InsiteRoutes,
    ...CallUsNowRoutes,
    ...CrmRoutes,
    ...RemoteAuthRoutes,
    ...CompanyDetailsRoutes,
    ...ChatBotRoutes,
    ...CompanyToolsRoutes,
    ...LiveChatLegacyRoutes,
    PublicRolesList: new Route({
        displayName: 'Customize',
        path: `${companyRoutesBase}/public-roles`,
        component: <CompanyRoleList />,
        beforeEnter: routeCheckAll([checkUserPermissions('public_roles', 'view'), checkPasswordReset()])
    }),

    ApiKeyList: new Route({
        displayName: 'API Keys',
        path: `${companyRoutesBase}/api-keys`,
        component: <ApiKeyList />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    }),

    ApplicationSettings: new Route({
        displayName: 'Customize',
        path: `${companyRoutesBase}/application-settings`,
        component: <ApplicationSettingsWrapper />,
        beforeEnter: routeCheckAll([checkUserPermissions('public_roles', 'view'), checkPasswordReset()])
    }),

    CompanyFeatureFlags: new Route({
        displayName: 'Beta Features',
        path: `${companyRoutesBase}/FeatureFlags`,
        component: <FeatureFlags />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'view'), checkPasswordReset()])
    }),

    CompanyFeatures: new Route({
        displayName: 'Features',
        path: `${companyRoutesBase}/Features`,
        component: <Features />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'view'), checkPasswordReset()])
    }),

    CompanyMessages: new Route({
        displayName: 'Messages',
        path: `${companyRoutesBase}/messages/:messageId?`,
        component: <Messages />,
        beforeEnter: routeCheckAll([checkUserPermissions('callus', 'view'), checkPasswordReset()])
    }),

    CompanyUserEdit: new Route({
        displayName: 'Users',
        path: `${companyRoutesBase}/users/:id`,
        component: <UserEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('users', 'view'), checkPasswordReset()])
    }),

    CompanyLeadDetailsByCustomer: new Route({
        displayName: 'Lead Details',
        path: `${companyRoutesBase}/leads/:customerId`,
        component: <LeadDetails />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    }),

    CompanyClarityDashboard: new Route({
        displayName: 'CLarity Attribution',
        path: `${companyRoutesBase}/clarity/dashboard`,
        component: <ClarityDashboard />,
        beforeEnter: routeCheckAll([checkUserPermissions('stat', 'view'), checkPasswordReset()])
    }),

    MatchbackImport: new Route({
        displayName: 'Matchback Import',
        path: `${companyRoutesBase}/matchback-import`,
        component: <MatchbackImport />,
        beforeEnter: routeCheckAll([checkUserPermissions('calendars', 'view'), checkPasswordReset()])
    }),
};
