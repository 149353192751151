import React, { FC, ReactNode, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { ActionList, DecorativeIcon, Title } from '../..';
import { HeadingTags } from '../../branding/typography.types';

export interface AppCardHeaderProps extends Omit<HTMLAttributes<HTMLElement>, 'title'> {
    title?: ReactNode;
    titleAs?: keyof typeof HeadingTags;
    description?: ReactNode;
    icon?: { name: string; color?: string };
}

export const AppCardHeader: FC<AppCardHeaderProps> = ({
    className,
    title,
    titleAs = 'h3',
    description,
    icon,
    children,
    ...props
}) => (
    <div className={classNames('app-card__header', className)} {...props}>
        {icon ? <DecorativeIcon className="app-card__header__icon" color="blue" {...icon} /> : null}
        <div className="app-card-header-content">
            {title && (
                <Title as={titleAs} className="app-card__header__title">
                    {title}
                </Title>
            )}
            {description && <div className="app-card__header__description">{description}</div>}
        </div>

        {children && (
            <ActionList className="app-card__header__actions" position="end">
                {children}
            </ActionList>
        )}
    </div>
);
