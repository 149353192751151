import { DateTime } from 'luxon';
import { ClarityChartConfig, ClarityConfig } from '../../constants';
import { LeadType } from '../../types';
import { FilterDateRange, Filters } from '../Filters/Filters.helpers';

export enum ClarityReportRequestStatus {
    requested = 'requested',
    ready = 'ready',
    failed = 'failed',
    deleted = 'deleted',
    expired = 'expired'
}

export interface ClarityDateRange {
    start: DateTime;
    end: DateTime;
    timezone: string;
}

export interface ExplanationListItem {
    id: number;
    name: string;
}

export interface ExplanationList {
    name: string;
    items: Array<ExplanationListItem>;
}

export interface ClarityFilterExplanation {
    dateRange: ClarityDateRange;
    lists: Array<ExplanationList>;
}

export interface ClarityReportRequestData {
    name: string;
    report_guid: string;
    status: ClarityReportRequestStatus;
    download_url: string;
    filter_explanation: ClarityFilterExplanation;
    created_at: DateTime;
    expires_at: DateTime;
    report_num: number;
}

export interface ClarityReportsItems {
    data: ClarityReportRequestData[];
    limit: number;
    offset: number;
    totalRecords: number;
}

export type ClarityScheduleInterval = 'day' | 'week' | 'month';

export interface ClarityScheduler {
    id: number;
    name: string;
    series_start: number;
    series_end: number | null;
    interval: ClarityScheduleInterval;
    next_run: number;
    active: boolean;
    recipients: string;
    config: ClarityConfig;
    filters: Filters;
}

export interface ClarityScheduledReportsItems {
    data: ClarityScheduler[];
    limit: number;
    offset: number;
    totalRecords: number;
}

export interface ClarityDashboardTotals {
    leadsByModule: { [key in keyof typeof LeadType]: number };
}

export interface BaseClarityData {
    totals: ClarityDashboardTotals;
    sessionsPerSource: Array<{ utm_source: string; source_count: number }>;
    leadsPerModuleTypeTrend: any;
}

type AnalyticsUtmSessions = { utm_source: string; source_count: number }[];

export interface AnalyticsClarityReports {
    sessionsPerSource: AnalyticsUtmSessions;
}
export interface ClarityTimeSeries {
    t: string;
    y: number;
}

export type ClarityDailyLeads = Array<ClarityTimeSeries>;

export type ClarityDailyLeadsByModule = { [key in keyof typeof LeadType]: ClarityDailyLeads };

export interface FilterExplain {
    dateRange: FilterDateRange;
}

export interface ClaritySettings {
    leads_per_application: Array<string>;
}

export type ClarityDisplayMode = 'screen' | 'print';

export interface ClarityUserPreferences {
    id?: number;
    name: string;
    config: ClarityConfig;
    settings: ClaritySettings;
    availableCharts: Array<ClarityChartConfig>;
    filters?: Filters;
}

export enum ClarityLoadingStatus {
    loading = 'loading',
    error = 'error',
    complete = 'complete'
}

export enum ClarityTabName {
    default = 'default',
    reports = 'reports',
    scheduler = 'scheduler'
}

export interface ClarityCreateReportConfig {
    name: string;
    filter?: Filters;
    config?: ClarityConfig;
    recipients?: string;
}
