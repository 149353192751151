import * as _ from 'lodash';
import { first, keyBy, keys } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import Api from '../util/api';
import { AvailabilitySchedule, FilterCommon } from '../types';
import { DripSchedule } from '../types/DripSchedule';

export interface QueryFilters extends FilterCommon {
    view?: string;
    lead_id?: number;
    active?: boolean;
    partner_id?: number;
}
export interface QueryParams {
    id?: number;
    search?: string;
    filters?: QueryFilters;
    pageSize?: number;
    page?: number;
    order?: string;
    idCompaniesCount?: boolean;
}

class Resource<T extends { id?: number; company_id?: number; agency_id?: number, active?: boolean }> {
    @observable public dataSize: number = 0;
    @observable protected storage: Record<number, T> = {};

    constructor(protected readonly api: Api, protected readonly name: string, protected readonly dataKey?: string) { }

    public get values() {
        return _.values(this.storage);
    }

    public get data() {
        return this.storage;
    }

    public get length() {
        return Object.keys(this.storage).length;
    }

    public has(id: number): boolean {
        return !!this.storage[id];
    }

    public getItem(id: number): T {
        return this.storage[id];
    }

    public getFirstItemId(): number {
        const id = first(keys(this.storage));
        return id ? +id : 0;
    }

    @action.bound
    public clear() {
        this.dataSize = 0;
        this.storage = {};
    }

    public fetch(params?: QueryParams): Promise<void> {
        if (params?.id) {
            const id = params.id;
            return this.fetchOne(id);
        } else {
            return this.fetchAll(params);
        }
    }

    @action.bound
    public create(data: T) {
        return this.api.client.post(this.name, data).then(response =>
            runInAction(() => {
                this.storage[response.data.id] = response.data;
                return response.data.id;
            })
        );
    }

    public update(data: Partial<T>) {
        // We don't allow resources to switch company or agency after they have been created. If you need to do this, use a custom api call.
        if (data.company_id) {
            delete data.company_id;
        }

        const canEditAgencyId = ['companies'];
        if (data?.agency_id && !canEditAgencyId.includes(this.name)) {
            delete data.agency_id;
        }

        return this.api.client.patch(this.name, data).then(response => {
            runInAction(() => {
                this.storage[data.id!] = response.data;
                return response.data.id;
            })
        }
        );
    }

    public updateChatWidget(data: Partial<T>) {
        if (data.id) {
            delete data.id;
        }
        return this.api.client.post(this.name, data).then(response => {
            runInAction(() => {
                this.storage[data.id!] = response.data;
                return response.data.id;
            })
        }
        );
    }

    @action.bound
    public updateAll(data: Partial<T>) {
        return this.api.client.patch(`${this.name}/all`, data).then(response =>
            runInAction(() => this.replaceAll(response))
        );
    }

    @action.bound
    public delete(id: number) {
        return this.api.client.delete(`${this.name}/${id}`).then(() =>
            runInAction(() => {
                delete this.storage[id];
            })
        );
    }

    @action.bound
    public refresh(id: number) {
        return this.api.client.get(`${this.name}/${id}`).then(response =>
            runInAction(() => {
                this.storage[id] = response.data;
            })
        );
    }

    @action.bound
    public replace(data: T) {
        if (data && data.id) {
            this.storage[data.id] = data;
        }
    }

    @action.bound
    public disableAll(companyId: number, params: QueryParams) {
        return this.api.client.get(`${this.name}/disableAll/company/${companyId}`).then(() => this.fetchAll(params));
    }

    @action.bound
    public enableLast(companyId: number, params: QueryParams) {
        return this.api.client.get(`${this.name}/enableLast/company/${companyId}`).then(() => this.fetchAll(params));
    }

    @action.bound
    protected fetchOne(id: number): Promise<void> {
        if (!this.storage[id]) {
            return this.refresh(id);
        }

        return Promise.resolve();
    }

    @action.bound
    protected fetchAll(params?: QueryParams) {
        return this.api.fetch(this.name, params).then(response => {
            return runInAction(() => this.replaceAll(response));
        });
    }

    @action.bound
    public replaceAll(response: { data: AvailabilitySchedule[] }) {
        const dataKey = this.dataKey || this.name;
        const items = response.data[dataKey] || response.data;
        this.storage = keyBy(items, 'id');
        this.dataSize = items ? items.length : 0;
    }

    @action.bound
    public replaceAllDrips(response: { data: DripSchedule[] }) {
        const dataKey = this.dataKey || this.name;
        const items = response.data[dataKey] || response.data;
        this.storage = keyBy(items, 'id');
        this.dataSize = items ? items.length : 0;
    }

}

export default Resource;
