import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Title, Header, ActionList } from '../..';

export interface AppSectionHeaderProps {
    title?: ReactNode;
    subtitle?: string;
    className?: string;
    as?: 'Title' | 'Header';
}

export const AppSectionHeader: FC<AppSectionHeaderProps> = ({ title, subtitle, children, className, as = 'Title' }) => {
    const titleComponents = {
        Title,
        Header
    };
    const TitleComponent = titleComponents[as] || Title;

    return (
        <>
            <header className={classNames('app-section-header', className)}>
                <div className={'app-section-header-content'}>
                    {title && <TitleComponent className={'app-section-title'}>{title}</TitleComponent>}
                    {subtitle && <p className={'app-section-subtitle'}>{subtitle}</p>}
                </div>
                {children && (
                    <ActionList className={'app-section-actions'} position="end">
                        {children}
                    </ActionList>
                )}
            </header>
        </>
    );
};
