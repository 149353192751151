export const replaceErrorLabel = (error: string, label: string): string => {
    if (!error) {
        return '';
    }

    if (!label) {
        return error;
    }

    return error.replace(/('|")[\w]+('|")/g, `"${label}"`);
};
