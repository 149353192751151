import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    ButtonOutlinePrimary,
    useSnackbar
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../../constants'

export interface HubSpotFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface HubSpotCrm extends Crm {
    configuration: {
        client_access_token: string;
        workflow_id?: number;
    };
}


export const HubSpotForm: FC<HubSpotFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;
    const { addSnackbar } = useSnackbar();

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<HubSpotCrm>) => {
        await onValidate(formikProps.values, crmConfigTypes.hubspot);
    };

    const handleSubmit = async (values: HubSpotCrm, actions: FormikHelpers<HubSpotCrm>) => {
        const { client_access_token } = values.configuration;

        if (!client_access_token) {
            addSnackbar(`Please provide a HubSpot access token.`, { variant: 'error' });
            return
        }
        await onSubmit(values, actions, crmConfigTypes.hubspot);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<HubSpotCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Hubspot" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 64 }}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader
                                    title="HubSpot Configuration"
                                    subtitle="Contact HubSpot support for the access token and workflow ID."
                                />
                                <InputText
                                    name="configuration.client_access_token"
                                    label="Access Token"
                                    helperText="HubSpot Access Token"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.workflow_id"
                                    label="Workflow Id"
                                    helperText="Hubspot Workflow Id"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                        </AppSection>

                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
