import React, { FunctionComponent } from 'react';
import { get as _get } from 'lodash';
import { EditEmailSectionProps } from './EditEmailSectionForm';

const logoImage = (logoKey: string) => `https://tcc.sfo2.cdn.digitaloceanspaces.com/images/overlays/${logoKey}.png`;

const availableLogos = ['ada', 'pet', 'eho', 'special_person'];

export const EditEmailOverlayForm: FunctionComponent<EditEmailSectionProps> = ({ section, formikProps }) => {
    return (
        <>
            <div style={{ marginBottom: 18 }}>Select which optional logos to include:</div>
            {availableLogos.map(logo => (
                <span key={logo} style={{ padding: 10, cursor: 'pointer' }}>
                    <img
                        src={logoImage(logo)}
                        width={60}
                        height={60}
                        alt={logo}
                        style={formikProps.values[section.id].logos.includes(logo) ? { opacity: 1 } : { opacity: .4 }}
                        onClick={() => {
                            if (formikProps.values[section.id].logos.includes(logo)) {
                                formikProps.setFieldValue(
                                    `${section.id}.logos`,
                                    formikProps.values[section.id].logos.filter(item => item !== logo)
                                );
                            } else {
                                formikProps.setFieldValue(`${section.id}.logos`, [...formikProps.values[section.id].logos, logo]);
                            }
                        }}
                    />
                </span>
            ))}
        </>

    );
};
