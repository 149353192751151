import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DefaultRoutes from '../../../../../routes/DefaultRoutes';
import { SideNavMenu } from '../../SideNavMenu';
import { SideNavMenuItem } from '../../SideNavMenuItem';
import { getSupportUrl } from '../../SideNav.helpers';
import useStore from '../../../../../store/useStore';
import { getRouteWithContext } from '../../../../../routes/routeUtils';

export interface MenuAgencyProps {}

export const MenuAgency: FC<MenuAgencyProps> = observer(props => {
    const { store } = useStore();
    const { hasAccess, router } = store;

    const leadRoutes = [{ route: DefaultRoutes.AgencyLeadView }];
    const leadsItems = [...leadRoutes, { route: DefaultRoutes.AgencyInsiteMetricDataList }];

    const clarityV2 = { route: getRouteWithContext('ClarityDashboard', router) };

    const agencySettingsItems = [
        { route: DefaultRoutes.AgencyDetails },
        { route: DefaultRoutes.AgencyEmailSenderProfile }
    ];

    const settingsItems = [
        { route: DefaultRoutes.AgencyCategoryList },
        { route: DefaultRoutes.AgencyHolidaysList },
        { route: DefaultRoutes.AgencyQuestionDefinitionPresetPage }
    ];

    const agencyToolsItems = [
        { route: DefaultRoutes.AgencyCompanyLists },
        { route: DefaultRoutes.AgencyEmailViewer },
        { route: DefaultRoutes.AgencyMessages },
        { route: DefaultRoutes.AgencyOptOutQueueList },
        { route: DefaultRoutes.AgencyAuditLog },
        { route: DefaultRoutes.AgencyCssToolsList },
        { route: DefaultRoutes.AgencyCsvImport }
    ];

    return (
        <SideNavMenu>
            <SideNavMenuItem name="dashboard" icon="home" route={DefaultRoutes.AgencyDashboard} />

            <SideNavMenuItem
                name="leads"
                label="Leads"
                icon="user"
                route={leadsItems[0].route}
                submenu={{ items: leadsItems }}
            />

            {hasAccess('admin.dashboard') && (
                <SideNavMenuItem
                    name="clarity_v2"
                    label="CLarity Attribution"
                    icon="analytics"
                    route={clarityV2.route}
                />
            )}

            <SideNavMenuItem
                name="partner"
                label="Partner"
                icon="partner"
                route={agencySettingsItems[0].route}
                submenu={{ items: agencySettingsItems }}
            />

            <li className="side-nav-divider" />

            <SideNavMenuItem name="users" label="Users" icon="users" route={DefaultRoutes.AgencyUsers} />

            {hasAccess('admin.view') && (
                <SideNavMenuItem
                    name="settings"
                    label="Settings"
                    icon="settings"
                    route={settingsItems[0].route}
                    submenu={{ items: settingsItems }}
                />
            )}

            {hasAccess('admin.dashboard') && (
                <SideNavMenuItem
                    name="tools"
                    label="Tools"
                    icon="filter"
                    route={agencyToolsItems[0].route}
                    submenu={{ items: agencyToolsItems }}
                />
            )}

            <SideNavMenuItem
                name="support"
                label="Support"
                icon="support"
                onClick={() => window.open(getSupportUrl(store), '_blank')}
            />
        </SideNavMenu>
    );
});
