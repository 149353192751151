import React, { useState, useReducer, useMemo } from 'react';
import { observer } from 'mobx-react';
import {
    AppPage,
    AppHeader,
    AppContent,
    AppList,
    ButtonPrimary,
    AppListEmpty,
    AppSection,
    ActionList
} from '../../components-v2/shared';
import { useAsyncEffect } from '@lambdacurry/component-library';
import useStore from '../../store/useStore';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { closeModal, questionReducer, questionInitialState } from './Question.helpers';
import { DeleteQuestionModal } from './components';
import { QuestionsOrderList } from './components/QuestionsOrderList';

export const QuestionList = observer(() => {
    const [loading, setLoading] = useState(true);
    const [state, dispatch] = useReducer(questionReducer, questionInitialState);
    const { store } = useStore();
    const { router, questions, chatbots, fetchChatbotQuestions, fetchChatbot } = store;
    const { companyId } = router.params;
    const sortedTopicsbyOrder = useMemo(
        () =>
            questions?.values?.sort((a, b) => {
                if (a.id !== b.id) {
                    return a.order - b.order;
                }
                if (a.name === b.name) {
                    return 0;
                }
                return a.name > b.name ? 1 : -1;
            }),
        [questions?.values]
    );

    const fetchRequiredData = async () => {
        await fetchChatbotQuestions();
        await fetchChatbot();
        setLoading(false);
    };
    useAsyncEffect(fetchRequiredData);

    const createNewQuestionButton = (
        <ButtonPrimary
            data-test-id="button_cm_create_new_offer"
            onClick={() => {
                const chatbotId = chatbots.values[0]?.id;
                router.goTo(DefaultRoutes.ChatBotQuestionNew, { companyId, chatbotId }, store);
            }}
        >
            Create New Topic
        </ButtonPrimary>
    );

    return (
        <>
            <AppPage loading={loading}>
                <AppHeader
                    title="Virtual Assistant"
                    icon={{ name: 'chat', color: 'green' }}
                    onBack={() => router.goTo(DefaultRoutes.ChatBot, { companyId }, store)}
                >
                    <ActionList position="end">{createNewQuestionButton}</ActionList>
                </AppHeader>
                <AppContent>
                    <AppSection>
                        <AppList>
                            {questions?.length < 1 && (
                                <AppListEmpty
                                    title="Virtual Assistant"
                                    description="Create Virtual Assistant topics to get started with Virtual Assistant."
                                    action={createNewQuestionButton}
                                />
                            )}
                            <QuestionsOrderList
                                questions={sortedTopicsbyOrder}
                                store={store}
                                dispatch={dispatch}
                                companyId={companyId}
                                setLoadingTopics={(value: boolean | ((prevState: boolean) => boolean)) =>
                                    setLoading(value)
                                }
                            />
                        </AppList>
                    </AppSection>
                    <AppSection>{createNewQuestionButton}</AppSection>
                </AppContent>
            </AppPage>

            <DeleteQuestionModal
                isOpen={state.modal.active === 'deleteQuestion'}
                dispatch={dispatch}
                questions={questions}
                question={state.modal.data?.question}
                onAfterDelete={() => {
                    setLoading(true);
                    closeModal(dispatch);
                    setLoading(false);
                }}
            />
        </>
    );
});
