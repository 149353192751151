import React, { FC } from 'react';
import { ButtonPrimary, Form, handlePromise, InputText } from '@lambdacurry/component-library';
import { FormikHelpers } from 'formik';
import { AgencyHostSettingsFormProps } from '../AgencyHostSettings.types';
import useStore from '../../../../store/useStore';
import { AgencyEmailSettingsValidationSchema } from '../AgencyHostSettings.validation';
import { useSnackbar } from '../../../../components-v2/shared';
import { AgencyEmailDnsSettingsTable } from './AgencyEmailDnsSettingsTable';
import { unsavedChangesContainerSelectorDefault } from '../../../../constants';

interface EmailSettingsFormValues {
    reply_to_email: string;
    sender_email: string;
}

export const EmailSettingsForm: FC<AgencyHostSettingsFormProps> = ({ data, onSuccess }) => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();

    const handleSubmit = async (
        { reply_to_email, sender_email }: EmailSettingsFormValues,
        actions: FormikHelpers<EmailSettingsFormValues>
    ) => {
        if (!data) {
            return;
        }

        const { updateAgencyCustomizationById } = store.agencyStore;

        const updateData = { ...data, reply_to_email, sender_email };

        const [agencyCustomization, err] = await handlePromise(updateAgencyCustomizationById(updateData));

        if (err) {
            if (err.response?.data) {
                actions.setErrors(err.response.data);
            }
            return;
        }

        if (!agencyCustomization) {
            return;
        }

        if (onSuccess) {
            onSuccess(agencyCustomization);
        }

        return addSnackbar('Email settings saved.', { variant: 'success' });
    };

    if (!data) {
        return <>Loading ...</>;
    }

    const initialValues = {
        reply_to_email: data.reply_to_email || undefined,
        sender_email: data.sender_email || undefined
    };

    return (
        <>
            <Form
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={AgencyEmailSettingsValidationSchema}
                validateOnBlur={false}
                confirmUnsavedChanges={true}
                unsavedChangesConfig={{
                    containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
                }}
            >
                {formikProps => (
                    <>
                        <InputText
                            name="reply_to_email"
                            label="Reply-to Email"
                            formikProps={formikProps}
                            helperText="Replies to automated emails will be sent to this email address."
                        />
                        <InputText
                            name="sender_email"
                            label="Sender Email"
                            formikProps={formikProps}
                            helperText="Automated emails will be sent from this email address."
                        />
                        <ButtonPrimary
                            type="submit"
                            disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}
                        >
                            {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                        </ButtonPrimary>

                        <hr />

                        <AgencyEmailDnsSettingsTable senderEmailDns={data.sender_email_dns} />
                    </>
                )}
            </Form>
        </>
    );
};
