export type LiveChatModals = undefined | 'deleteChat' | 'createChatLead';
export type LiveChatReducerNames = 'closeModal' | 'openModal' | 'setChatActive';

export interface LiveChatReducerState {
    isChatActive: boolean;
    loading: boolean;
    modal: {
        active: LiveChatModals;
        data: any;
    };
}

export interface LiveChatReducerAction {
    name: keyof typeof liveChatReducers;
    payload?: any;
}

export const liveChatReducers = {
    closeModal: (state: LiveChatReducerState) => ({
        ...state,
        modal: { ...state.modal, active: 'none' }
    }),
    openModal: (state: LiveChatReducerState, modal: LiveChatReducerState['modal']) => ({
        ...state,
        modal
    }),
    setModalData: (state: LiveChatReducerState, data: LiveChatReducerState['modal']['data']) => ({
        ...state,
        modal: {
            ...state.modal,
            data
        }
    }),
    setChatActive: (state: LiveChatReducerState, isChatActive: LiveChatReducerState['isChatActive']) => ({
        ...state,
        isChatActive
    }),
    setLoading: (state: LiveChatReducerState, loading: LiveChatReducerState['loading']) => ({
        ...state,
        loading
    })
};

export const liveChatReducer = (state: LiveChatReducerState, { name, payload }: LiveChatReducerAction) => {
    if (!liveChatReducers[name]) {
        throw new Error(`reducer ${name} not defined`);
    }

    return liveChatReducers[name](state, payload);
};

export const liveChatInitialState: LiveChatReducerState = {
    isChatActive: true,
    loading: true,
    modal: {
        active: undefined,
        data: null
    }
};

export const openModal = (dispatch: React.Dispatch<LiveChatReducerAction>, name: LiveChatModals, data?: any) =>
    dispatch({ name: 'openModal', payload: { active: name, data } });

export const closeModal = (dispatch: React.Dispatch<LiveChatReducerAction>) => dispatch({ name: 'closeModal' });

export const setModalData = (dispatch: React.Dispatch<LiveChatReducerAction>, data: any) =>
    dispatch({ name: 'setModalData', payload: data });

export const setChatActive = (dispatch: React.Dispatch<LiveChatReducerAction>, data: any) =>
    dispatch({ name: 'setChatActive', payload: data });

export const setLoading = (dispatch: React.Dispatch<LiveChatReducerAction>, data: any) =>
    dispatch({ name: 'setLoading', payload: data });
