import React, { useReducer, useState } from 'react';
import {
    ActionList,
    AppCard,
    AppCardDivider,
    AppCardHeader,
    AppSectionHeader,
    DescriptionList,
    DescriptionListItem,
    ListGroup,
    ListGroupItem,
    useSnackbar
} from '../../../../components-v2/shared';
import {
    Icon,
    InputDate,
    Form,
    InputSelect,
    ButtonPrimary,
    ButtonUnstyled,
    Modal,
    ModalHeader,
    ModalActions,
    Button,
    handlePromise,
    useAsyncEffect
} from '@lambdacurry/component-library';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import { Lead, LeadStatus, LeadType } from '../../../../types/Lead';
import { observer } from 'mobx-react';
import useStore from '../../../../store/useStore';
import {
    AppEventScheduleGenieReducer,
    selectScheduleGenieMessage,
    selectIsDatePast
} from './AppEventScheduleGenie.helpers';
import { FormikHelpers, FormikProps } from 'formik';
import { AxiosResponse } from 'axios';
import { QuestionDefinitionAdHocTag } from '../../../../types/QuestionSettings';

export const AppEventScheduleGenie: React.FC<{ lead: Lead; updateLead: (lead: Lead) => void }> = observer(
    ({ lead, updateLead }) => {
        const data = lead.calendar;
        const {
            store: { Api }
        } = useStore();
        const { addSnackbar } = useSnackbar();
        const [showUpdateAppointmentButton, setShowUpdateAppointmentButton] = useState<boolean>(false);
        const leadDate = DateTime.fromSeconds(data?.lead_time || 0);
        const timeSimple = leadDate.toLocaleString(DateTime.TIME_SIMPLE).replace(' ', '');
        const isCanceled = lead.status === LeadStatus.APPOINTMENT_CANCELLED;
        const [state, dispatch] = useReducer(AppEventScheduleGenieReducer, {
            originalDate: leadDate,
            date: leadDate,
            timeSlots: [{ label: `${timeSimple}`, value: timeSimple }],
            confirmCancelModal: false
        });

        const fetchAvailableTimeSlotsByDate = async () => {
            const [response, error] = await handlePromise(
                Api.client.get(`/leads/${lead.id}/available-date-slots/${state.date.toISODate()}`)
            );

            if (!response || error) {
                addSnackbar('Failed to fetch available time slots for the selected date.', { variant: 'error' });
                return new Error('error fetching response');
            }

            return dispatch({ name: 'updateTimeSlots', payload: response.data });
        };

        useAsyncEffect(fetchAvailableTimeSlotsByDate, undefined, [state.date]);

        if (!data) {
            return null;
        }

        const cancelAppointment = async () => {
            dispatch({ name: 'toggleConfirmCancelModal' });
            const { lead_id } = data;
            const [response, error] = await handlePromise(
                Api.client.patch('/leads', { id: lead_id, status: LeadStatus.APPOINTMENT_CANCELLED })
            );

            if (!response || error) {
                addSnackbar('Failed to cancel the appointment.', { variant: 'error' });
                return new Error('error fetching response');
            }

            return updateLead(response.data);
        };

        const updateAppointment: (
            values: {
                date: Date;
                timeSlot: string;
            },
            formikHelpers: FormikHelpers<{
                date: Date;
                timeSlot: string;
            }>
        ) => void | Promise<any> = async ({ date, timeSlot: lead_time }, formikHelpers) => {
            const { lead_id } = data;
            const lead_date = DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT);
            const [response, error] = await handlePromise<AxiosResponse<Lead>>(
                Api.client.patch('/leads', { id: lead_id, lead_date, lead_time, timezone: DateTime.local().zoneName })
            );

            formikHelpers.setSubmitting(false);

            if (!response || error) {
                return new Error('error fetching response');
            }
            // have to comment it out as no need for this code reseting form when updating
            // const timeSlot = `0${DateTime.fromSeconds(response.data.calendar?.lead_time || 0)
            //     .toLocaleString(DateTime.TIME_SIMPLE)
            //     .replace(' ', '')}`;
            // formikHelpers.resetForm({ values: { date, timeSlot } });
            setShowUpdateAppointmentButton(false);
            addSnackbar('The appointment was successfully updated.', { variant: 'success' });

            return updateLead(response.data);
        };

        return (
            <AppCard>
                <AppCardHeader
                    title="Schedule Genie"
                    description={DateTime.fromISO(data.created_at).toLocaleString(DateTime.DATETIME_SHORT)}
                    icon={{ name: 'schedule' }}
                />

                <Form
                    initialValues={{ date: state.date.toJSDate(), timeSlot: timeSimple }}
                    validationSchema={() =>
                        Yup.object().shape({
                            date: Yup.string()
                                .nullable()
                                .required('please select a date.'),
                            timeSlot: Yup.string()
                                .nullable()
                                .required('please select a time.')
                        })
                    }
                    onSubmit={updateAppointment}
                >
                    {(formikProps: FormikProps<{ date: Date; timeSlot: string }>) => (
                        <>
                            <AppSectionHeader
                                title={
                                    <>
                                        {isCanceled && (
                                            <span className="color-danger clx-margin-right-8">
                                                <Icon name="warning" />
                                            </span>
                                        )}
                                        {selectScheduleGenieMessage(state, isCanceled)}
                                    </>
                                }
                                as="Header"
                            />

                            <div className="field-row">
                                <InputDate
                                    disablePast
                                    label="Appointment Date"
                                    id={`date-${data.id}`}
                                    name="date"
                                    onChange={(value: any) => {
                                        dispatch({ name: 'setDate', payload: DateTime.fromJSDate(value) });
                                        setShowUpdateAppointmentButton(true);
                                        formikProps.setFieldValue('timeSlot', null);
                                    }}
                                    disabled={selectIsDatePast(state) || isCanceled}
                                    formikProps={formikProps}
                                />
                                <InputSelect
                                    id={`timeSlot-${data.id}`}
                                    name="timeSlot"
                                    optionValueKey="value"
                                    options={state.timeSlots}
                                    onChange={(value: any) => {
                                        setShowUpdateAppointmentButton(true);
                                    }}
                                    disabled={!state.timeSlots.length || selectIsDatePast(state) || isCanceled}
                                    formikProps={formikProps}
                                    placeholder="Select a time slot"
                                    helperText={
                                        !state.timeSlots.length
                                            ? 'There are no available time slots for this day.'
                                            : `(${leadDate.zoneName})`
                                    }
                                />
                                <ActionList position="end">
                                    <ButtonUnstyled
                                        disabled={isCanceled}
                                        className={!isCanceled ? 'color-danger' : ''}
                                        onClick={() => {
                                            dispatch({ name: 'toggleConfirmCancelModal' });
                                            setShowUpdateAppointmentButton(false);
                                        }}
                                    >
                                        Cancel Appointment
                                    </ButtonUnstyled>
                                </ActionList>
                            </div>

                            {!selectIsDatePast(state) && showUpdateAppointmentButton && (
                                <ActionList position="end">
                                    <ButtonPrimary
                                        type="submit"
                                        disabled={!state.timeSlots.length || !formikProps.dirty || !formikProps.isValid}
                                    >
                                        Update Appointment
                                    </ButtonPrimary>
                                </ActionList>
                            )}

                            {data.appointment_type && (
                                <>
                                    <AppCardDivider />
                                    <AppSectionHeader title="Appointment Type" as="Header" />
                                    {data.appointment_type}
                                </>
                            )}

                            {lead.questionResponses && lead.questionResponses?.length > 0 && (
                                <>
                                    <AppCardDivider />
                                    <AppSectionHeader title="Questions" as="Header" />
                                    <DescriptionList columns={2}>
                                        {lead.questionResponses.map(val => {
                                            return (
                                                <DescriptionListItem
                                                    label={`${val.tag && val.tag !== QuestionDefinitionAdHocTag ? `${val.tag}: ` : ''}${val.text}`}
                                                    value={val.response || `No Response Provided`}
                                                />
                                            );
                                        })}
                                    </DescriptionList>
                                </>
                            )}
                        </>
                    )}
                </Form>
                {lead.opened_from_chatbot && (
                    <>
                        <AppCardDivider />
                        <AppCard>
                            <AppCardHeader
                                icon={{ name: 'chat' }}
                                title="Virtual Assistant"
                                description={DateTime.fromISO(lead.created_at as string).toLocaleString(
                                    DateTime.DATETIME_SHORT
                                )}
                            />
                            <ListGroup>
                                <ListGroupItem icon={{ name: 'deal' }} label="Lead created by">
                                    {lead.opened_from_chatbot_topic}
                                </ListGroupItem>
                            </ListGroup>
                            <AppCardDivider />
                            <ListGroup>
                                <ListGroupItem icon={{ name: 'deal' }} label="Virtual Assistant Interactions before lead creation">
                                    {lead?.chatbot_interactions?.map((item: string, index: number) => (
                                        <div>{item}</div>
                                    ))}
                                </ListGroupItem>
                            </ListGroup>
                        </AppCard>
                    </>
                )}
                {lead.opened_from_chatbot_ai && (
                    <>
                        <AppCardDivider />
                        <AppCard>
                            <AppCardHeader
                                icon={{ name: 'chat' }}
                                title="Claire AI+"
                                description={DateTime.fromISO(lead.created_at as string).toLocaleString(
                                    DateTime.DATETIME_SHORT
                                )}
                            />
                            <ListGroup>
                                <ListGroupItem icon={{ name: 'deal' }} label="Lead created by">
                                    {(LeadType.SG === lead.lead_type) && 'Schedule Genie'}
                                </ListGroupItem>
                            </ListGroup>
                        </AppCard>
                    </>
                )}

                <Modal id="sg-cancel-appointment-modal" isOpen={state.confirmCancelModal} closeButton={false}>
                    <ModalHeader title="Cancel appointment" />
                    <p>Are you sure you want to cancel this appointment?</p>
                    <ModalActions>
                        <ActionList position="end">
                            <Button onClick={() => dispatch({ name: 'toggleConfirmCancelModal' })}>
                                Keep Appointment
                            </Button>
                            <ButtonPrimary onClick={cancelAppointment}>Yes, Cancel</ButtonPrimary>
                        </ActionList>
                    </ModalActions>
                </Modal>
            </AppCard>
        );
    }
);
