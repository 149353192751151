import {
    ButtonOutlineAccent,
    ButtonOutlinePrimary,
    Form,
    IconButton,
    InputCheckbox,
    InputRadio,
    InputRadioGroup,
    InputSelect,
    InputText
} from '@lambdacurry/component-library';
import { FormikHelpers, FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent } from 'react';
import { AppSection, AppSectionHeader } from '../../components-v2/shared';
import { Rule, RuleAction, RuleSet, UniverseCondition, UniverseLocator, UniverseValue } from '../../types';

import './rule-editor.scss';

export interface JSONWithRuleset {
    ruleset: RuleSet;
}

export interface PredicatePicklists {
    [x: string]: Array<{ val: string; label: string }>;
}

export const RuleEditor: FunctionComponent<{
    formikProps: FormikProps<JSONWithRuleset>;
    availableRuleActions: Array<RuleAction>;
    availableRuleConditionSubjects: Array<UniverseLocator>;
    predicatePicklists: PredicatePicklists;
}> = ({ formikProps, availableRuleActions, availableRuleConditionSubjects, predicatePicklists }) => {
    const handleAddRule = (values: { rule_action_id: number }, actions: FormikHelpers<{ rule_action_id: number }>) => {
        if (!formikProps.values.ruleset) {
            formikProps.values.ruleset = { rules: [] };
        }
        const ruleset = cloneDeep(formikProps.values.ruleset);
        const ruleAction = availableRuleActions[values.rule_action_id];
        if (ruleAction) {
            const subject: UniverseLocator = { key: '', type: 'string' };
            const predicate: UniverseValue = { type: 'string', data: '' };
            const emptyCondition: UniverseCondition = { subject, predicate, comparison: 'equals' };
            const newRule: Rule = { conditions: [emptyCondition], actions: [ruleAction], active: true };
            ruleset.rules.push(newRule);
            formikProps.setFieldValue('ruleset', ruleset);
        }
    };

    const deprecatedRuleConditions = [
        { val: 'utm_source', label: '**Last Touch UTM Source' },
        { val: 'utm_medium', label: '**Last Touch UTM Medium' },
        { val: 'first_touch_utm_source', label: '**First Touch UTM Source' },
        { val: 'first_touch_utm_medium', label: '**First Touch UTM Medium' },
        { val: 'multi_touch_utm_source', label: '**Multi Touch UTM Source' },
        { val: 'multi_touch_utm_medium', label: '**Multi Touch UTM Medium' },
    ];

    const handleDeleteRule = (ruleNumber: number) => {
        const ruleset = cloneDeep(formikProps.values.ruleset);
        if (
            ruleset.rules &&
            ruleset.rules.length > ruleNumber &&
            window.confirm('Are you sure you want to remove this rule?')
        ) {
            ruleset.rules.splice(ruleNumber, 1);
            formikProps.setFieldValue('ruleset', ruleset);
        }
    };

    const handleMoveRuleDown = (ruleNumber: number) => {
        if (formikProps.values.ruleset.rules.length > ruleNumber) {
            handleMoveRuleUp(ruleNumber + 1);
        }
    };

    const handleMoveRuleUp = (ruleNumber: number) => {
        const ruleset = cloneDeep(formikProps.values.ruleset);
        const moved = ruleset.rules[ruleNumber - 1];
        ruleset.rules[ruleNumber - 1] = ruleset.rules[ruleNumber];
        ruleset.rules[ruleNumber] = moved;
        formikProps.setFieldValue('ruleset', ruleset);
    };

    const handleDeleteRuleCondition = (ruleNumber: number, conditionNumber: number) => {
        const ruleset = cloneDeep(formikProps.values.ruleset);
        if (
            ruleset.rules &&
            ruleset.rules.length > ruleNumber &&
            window.confirm('Are you sure you want to remove this condition?')
        ) {
            ruleset.rules[ruleNumber].conditions.splice(conditionNumber, 1);
            formikProps.setFieldValue('ruleset', ruleset);
        }
    };

    const handleAddRuleCondition = (ruleNumber: number) => {
        const ruleset = cloneDeep(formikProps.values.ruleset);
        const subject: UniverseLocator = { key: '', type: 'string' };
        const predicate: UniverseValue = { type: 'string', data: '' };
        const emptyCondition: UniverseCondition = { subject, predicate, comparison: 'equals' };
        ruleset.rules[ruleNumber].conditions.push(emptyCondition);
        formikProps.setFieldValue('ruleset', ruleset);
    };

    const ruleActionsToPicklist = (): Array<{ val: number; label: string }> => {
        return availableRuleActions.map((ra: RuleAction, i: number) => {
            return { val: i, label: `${ra.name}` };
        });
    };

    const ruleConditionsToPicklist = () => {
        return availableRuleConditionSubjects.map((ul: UniverseLocator, i: number) => {
            return { val: ul.key, label: ul.label || ul.key };
        });
    };

    const updateRuleCondition = (
        _event: React.ChangeEvent<HTMLInputElement>,
        formikProps: FormikProps<JSONWithRuleset>,
        ruleNumber: number,
        conditionNumber: number,
        selected: any
    ) => {
        const conditionForm = `ruleset.rules[${ruleNumber}].conditions[${conditionNumber}]`;
        const conditionType =
            availableRuleConditionSubjects.find((ul: UniverseLocator) => {
                return ul.key === selected;
            })?.type || 'string';

        const comparisonType = conditionType.endsWith('_array') ? 'includes' : 'equals';

        formikProps.setFieldValue(`${conditionForm}.subject.type`, conditionType);
        formikProps.setFieldValue(`${conditionForm}.comparison`, comparisonType);
        formikProps.setFieldValue(`${conditionForm}.predicate.data`, '');
        if (selected === 'utm_record_array') {
            formikProps.setFieldValue(`${conditionForm}.predicate.type`, 'utm_record');
        }
    };

    return availableRuleActions.length === 0 ? <></> : (
        <AppSection>
            <AppSectionHeader title="Rule Editor" />
            <Form onSubmit={handleAddRule} initialValues={{ rule_action_id: 0 }}>
                {formikRuleProps => {
                    return (
                        <div className="rule-editor-new">
                            <InputSelect
                                label="Rule Action Type"
                                name="rule_action_id"
                                options={ruleActionsToPicklist()}
                                optionValueKey="val"
                                optionLabelKey="label"
                                helperText="Select the type of rule you want to add"
                                className="rule-editor-new-select"
                                required={true}
                                autocompleteConfig={{ disableClearable: true }}
                                formikProps={formikRuleProps}
                            />
                            <ButtonOutlinePrimary type="submit" className="add-rule-submit">
                                Add Rule
                            </ButtonOutlinePrimary>
                        </div>
                    );
                }}
            </Form>
            {formikProps.values.ruleset &&
                formikProps.values.ruleset.rules &&
                formikProps.values.ruleset.rules.map((rule: Rule, r: number) => {
                    return (
                        <>
                            <div className="rule-wrapper">
                                <div className="rule-conditions">
                                    {rule.conditions &&
                                        rule.conditions.map((condition: UniverseCondition, c: number) => {
                                            return (
                                                <div className="rule-condition-row-wrapper" key={`ruleset.rules[${r}].conditions[${c}].condition`}>
                                                    <div className="rule-condition-row">
                                                        <span className="rule-layout">WHEN</span>
                                                        <InputSelect
                                                            className="rule-subject"
                                                            label="Subject"
                                                            name={`ruleset.rules[${r}].conditions[${c}].subject.key`}
                                                            options={[...ruleConditionsToPicklist(), ...deprecatedRuleConditions]}
                                                            optionValueKey="val"
                                                            optionLabelKey="label"
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>,
                                                                selected: any
                                                            ) => {
                                                                updateRuleCondition(event, formikProps, r, c, selected);
                                                            }}
                                                            required={true}
                                                            autocompleteConfig={{ disableClearable: true }}
                                                            formikProps={formikProps}
                                                        />
                                                        <span className="rule-layout">
                                                            {condition.comparison && condition.comparison.toUpperCase()}
                                                        </span>
                                                        {rule.conditions[c].subject['key'] === 'utm_record_array' ? (
                                                            <div style={{ padding: '0px', margin: '0px' }}>
                                                                <InputRadioGroup formikProps={formikProps} name={`ruleset.rules[${r}].conditions[${c}].predicate.data.touch`}
                                                                    style={{ padding: '0px', margin: '0px' }}>
                                                                    <table>
                                                                        <tr>
                                                                            <td><InputRadio value={'last'} label='Last-Touch' /></td>
                                                                            <td><InputRadio value={'first'} label='First-Touch' style={{ display: 'inline' }} /></td>
                                                                            <td><InputRadio value={'multi'} label='Multi-Touch' style={{ display: 'inline' }} /></td>
                                                                        </tr>
                                                                    </table>
                                                                </InputRadioGroup>
                                                                <InputText name={`ruleset.rules[${r}].conditions[${c}].predicate.type`} defaultValue={'utm_record'} hidden={true} formikProps={formikProps} />
                                                                <InputText style={{ width: '370px', margin: '8px' }}
                                                                    name={`ruleset.rules[${r}].conditions[${c}].predicate.data.source`}
                                                                    label="Source"
                                                                    required={false}
                                                                    formikProps={formikProps}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                                <InputText style={{ width: '370px', margin: '8px' }}
                                                                    name={`ruleset.rules[${r}].conditions[${c}].predicate.data.medium`}
                                                                    label="Medium"
                                                                    required={false}
                                                                    formikProps={formikProps}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                                <InputText style={{ width: '370px', margin: '8px' }}
                                                                    name={`ruleset.rules[${r}].conditions[${c}].predicate.data.campaign`}
                                                                    label="Campaign"
                                                                    required={false}
                                                                    formikProps={formikProps}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                                <InputText style={{ width: '370px', margin: '8px' }}
                                                                    name={`ruleset.rules[${r}].conditions[${c}].predicate.data.content`}
                                                                    label="Content"
                                                                    required={false}
                                                                    formikProps={formikProps}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                                <InputText style={{ width: '370px', margin: '8px', marginBottom: '20px' }}
                                                                    name={`ruleset.rules[${r}].conditions[${c}].predicate.data.term`}
                                                                    label="Term"
                                                                    required={false}
                                                                    formikProps={formikProps}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            predicatePicklists[rule.conditions[c].subject['key']] ? (
                                                                <InputSelect
                                                                    className="rule-predicate-picklist"
                                                                    label="Predicate"
                                                                    name={`ruleset.rules[${r}].conditions[${c}].predicate.data`}
                                                                    options={predicatePicklists[condition.subject['key']]}
                                                                    optionValueKey="val"
                                                                    optionLabelKey="label"
                                                                    required={true}
                                                                    autocompleteConfig={{ disableClearable: true }}
                                                                    formikProps={formikProps}
                                                                />
                                                            ) : (
                                                                <InputText
                                                                    name={`ruleset.rules[${r}].conditions[${c}].predicate.data`}
                                                                    label="Predicate"
                                                                    required={true}
                                                                    formikProps={formikProps}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )
                                                        )
                                                        }

                                                        <IconButton
                                                            icon="trash"
                                                            title="Remove Condition"
                                                            className="rule-editor-delete"
                                                            disabled={rule.conditions.length < 2}
                                                            onClick={() => handleDeleteRuleCondition(r, c)}
                                                        />
                                                    </div>
                                                    {rule.conditions.length - 1 > c && (
                                                        <span className="rule-condition-and">AND</span>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    <ButtonOutlineAccent
                                        className="rule-button"
                                        onClick={() => handleAddRuleCondition(r)}
                                    >
                                        Add Rule Condition
                                    </ButtonOutlineAccent>
                                </div>

                                <div className="rule-actions">
                                    <div className="rule-condition-row-wrapper">
                                        <div className="rule-condition-row">
                                            <span className="rule-layout">THEN</span>
                                            {rule.actions[0].actionType === 'ReplaceStringArray' && (
                                                <InputText
                                                    className="rule-action-data"
                                                    name={`ruleset.rules[${r}].actions[0].options.newValues`}
                                                    label={`${rule.actions[0].name}`}
                                                    required={true}
                                                    formikProps={formikProps}
                                                    inputProps={{ maxLength: 255 }}
                                                />
                                            )}
                                            {rule.actions[0].actionType === 'ReplaceString' &&
                                                (rule.actions[0].picklist ? (
                                                    <InputSelect
                                                        className="rule-action-data"
                                                        label={`${rule.actions[0].name}`}
                                                        name={`ruleset.rules[${r}].actions[0].options.newValue`}
                                                        options={rule.actions[0].picklist || []}
                                                        optionValueKey="id"
                                                        optionLabelKey="description"
                                                        disabled={!rule.actions[0].picklist}
                                                        required={true}
                                                        autocompleteConfig={{ disableClearable: true }}
                                                        formikProps={formikProps}
                                                    />
                                                ) : (
                                                    <InputText
                                                        className="rule-action-data"
                                                        name={`ruleset.rules[${r}].actions[0].options.newValue`}
                                                        label={`${rule.actions[0].name}`}
                                                        required={true}
                                                        formikProps={formikProps}
                                                        inputProps={{ maxLength: 255 }}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rule-commands">
                                <div>
                                    <InputCheckbox
                                        className="rule-action-active"
                                        title="Rule Active"
                                        label="Rule Active"
                                        name={`ruleset.rules[${r}].active`}
                                        formikProps={formikProps}
                                    />
                                </div>
                                <div className="trash-button">
                                    <IconButton icon="trash" title="Remove Rule" onClick={() => handleDeleteRule(r)} />
                                </div>
                                <div className="trash-button">
                                    <IconButton
                                        icon="chevronDown"
                                        className="rotate-button"
                                        title="Move Rule Up"
                                        disabled={r === 0 || formikProps.values.ruleset.rules.length <= r}
                                        onClick={() => handleMoveRuleUp(r)}
                                    />
                                </div>
                                <div className="trash-button">
                                    <IconButton
                                        icon="chevronDown"
                                        title="Move Rule Down"
                                        disabled={formikProps.values.ruleset.rules.length <= r + 1}
                                        onClick={() => handleMoveRuleDown(r)}
                                    />
                                </div>
                            </div>
                        </>
                    );
                })}
        </AppSection>
    );
};
