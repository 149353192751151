export interface FieldGroupColSizes {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
}

const COL_WIDTH_ONE = 12;
const COL_WIDTH_TWO = 6;
const COL_WIDTH_THREE = 4;
const COL_WIDTH_FOUR = 3;
const COL_WIDTH_SIX = 2;
const COL_WIDTH_TWELVE = 1;

export const Columns = {
    One: COL_WIDTH_ONE,
    Two: COL_WIDTH_TWO,
    Three: COL_WIDTH_THREE,
    Four: COL_WIDTH_FOUR,
    Six: COL_WIDTH_SIX,
    Twelve: COL_WIDTH_TWELVE
};

export const COLUMNS_ONE: FieldGroupColSizes = {
    xs: Columns.One
};

export const COLUMNS_TWO: FieldGroupColSizes = {
    xs: Columns.One,
    md: Columns.Two
};

export const COLUMNS_THREE: FieldGroupColSizes = {
    xs: Columns.One,
    md: Columns.Two,
    lg: Columns.Three
};

export const COLUMNS_FOUR: FieldGroupColSizes = {
    xs: Columns.One,
    sm: Columns.Two,
    md: Columns.Three,
    lg: Columns.Four
};

export const COLUMNS_SIX: FieldGroupColSizes = {
    xs: Columns.One,
    sm: Columns.Three,
    md: Columns.Six
};
