import * as moment from 'moment';
import * as React from 'react';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { FormControl, InputGroup, InputGroupAddon } from 'react-bootstrap';

interface IDateTimeIntervalPickerProps {
    onChange?: (start: moment.Moment, end: moment.Moment) => void;
    handleClear?: () => void;
    start?: moment.Moment;
    end?: moment.Moment;
}

interface IDateTimeIntervalPickerState {
    _start: moment.Moment;
    _end: moment.Moment;
    start?: moment.Moment;
    end?: moment.Moment;
}

export class DateTimeIntervalPicker extends React.Component<
    IDateTimeIntervalPickerProps,
    IDateTimeIntervalPickerState
> {
    constructor(props: IDateTimeIntervalPickerProps) {
        super(props);

        this.state = {
            start: props.start,
            end: props.end,
            _start: props.start || moment().startOf('day'),
            _end: props.end || moment().endOf('day')
        };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        global.console.log({ error, errorInfo });
    }

    public render() {
        const last30days = 30;
        const ranges = {
            'Today Only': [moment().startOf('day'), moment().endOf('day')],
            'Last 7 Days': [
                moment()
                    .subtract(1, 'week')
                    .startOf('day'),
                moment().endOf('day')
            ],
            'Last 14 Days': [
                moment()
                    .subtract(1, 'week')
                    .subtract(1, 'week')
                    .startOf('day'),
                moment().endOf('day')
            ],
            'Last 30 Days': [
                moment()
                    .subtract(last30days, 'days')
                    .startOf('day'),
                moment().endOf('day')
            ],
            'Month to Date': [moment().startOf('month'), moment().endOf('day')],
            'Last 90 Days': [
                moment()
                    .subtract(last30days, 'days')
                    .subtract(last30days, 'days')
                    .subtract(last30days, 'days')
                    .startOf('day'),
                moment().endOf('day')
            ]
        };
        const local = {
            format: 'MM/DD/YYYY hh:mm A',
            sundayFirst: true
        };
        const { start, end } = this.state;
        let range = '';
        if (start && end) {
            range = `${start.format('MM/DD/YYYY')} - ${end.format('MM/DD/YYYY')}`;
        }

        return (
            <>
                <DateTimeRangeContainer
                    ranges={ranges}
                    start={this.state._start}
                    end={this.state._end}
                    local={local}
                    applyCallback={this.handleChange}
                >
                    <InputGroup>
                        <FormControl type="text" placeholder="Choose date interval..." value={range} readOnly />

                        <InputGroup.Addon
                            onClick={e =>
                                this.handleClear as (event: React.MouseEvent<InputGroupAddon, MouseEvent>) => void
                            }
                        >
                            <span className="glyphicon glyphicon-remove-circle" />
                        </InputGroup.Addon>
                    </InputGroup>
                </DateTimeRangeContainer>
            </>
        );
    }

    public clear = () => {
        this.setState({
            start: undefined,
            end: undefined
        });
    };

    protected handleChange = (startDate: moment.Moment, endDate: moment.Moment) => {
        this.setState({
            start: startDate,
            end: endDate,
            _start: startDate,
            _end: endDate
        });
        if (this.props.onChange) {
            this.props.onChange(startDate, endDate);
        }
    };

    protected handleClear = (e: React.MouseEvent<InputGroupAddon, MouseEvent>) => {
        this.setState({
            start: undefined,
            end: undefined
        });
        if (this.props.handleClear) {
            this.props.handleClear();
        }
        e.stopPropagation();
        e.preventDefault();
    };
}

export default DateTimeIntervalPicker;
