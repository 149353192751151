import React, { FC } from 'react';
import { AppSection, Title } from '../../../../components-v2/shared';
import { RealyncIntegration } from './RealyncIntegration.helpers';
import { RealyncSetupForm } from './RealyncSetupForm';

export interface RealyncIntegrationSetupProps {
    setRealync: React.Dispatch<RealyncIntegration>;
}

export const RealyncIntegrationSetup: FC<RealyncIntegrationSetupProps> = ({ setRealync }) => {
    return (
        <AppSection>
            <Title>About</Title>
            <p>
                Realync is multifamily's leading video leasing and engagement solution enabling live video tours, live
                virtual open houses, and DIY pre-recorded videos of properties.
            </p>
            <p>
                Through Realync, property touring is finally mobile, real time, and highly interactive. Utilizing
                Realync's digital dashboard, users can communicate in real time via live video, capture photos, take
                notes, view interactive maps, and more. Tour files are all recorded and saved on the cloud for future
                review/comparison and can be shared with family, friends, and key decision makers.
            </p>
            <RealyncSetupForm setRealync={setRealync} />
        </AppSection>
    );
};
