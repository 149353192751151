import * as React from 'react';
import { Col, Row, Table, OverlayTrigger, Popover, FormControl } from 'react-bootstrap';

interface MatchbackImportListProps {
    list: any;
    availableSetting: any;
    returnImportSettings: (settings: { [id: number]: string }) => any;
}

interface MatchbackImportListState {
    importSettings: { [id: number]: string };
}

export class MatchbackImportList extends React.Component<MatchbackImportListProps, MatchbackImportListState> {
    public state = {
        importSettings: {}
    };

    public render() {
        return (
            <Col className="col-lg-12 holder-import-matchback">
                <Row className="hold-csv-table">
                    <Table className="csv-table matchback-holder-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                {this.props.list[0].data.map((item: string, i: number) => {
                                    const definedSettings = Object.values(this.state.importSettings);
                                    return (
                                        <th key={i} className="head-th">
                                            <div className="text-title">{item}</div>
                                            <FormControl
                                                id={`csvConfig${i}`}
                                                componentClass="select"
                                                onChange={this.changeImportSettings(i)}
                                            >
                                                <option value="">...</option>
                                                {this.props.availableSetting.map((config: any) => {
                                                    if (
                                                        this.state.importSettings[i] !== config.id &&
                                                        definedSettings.indexOf(config.id) >= 0
                                                    ) {
                                                        return null;
                                                    }
                                                    return (
                                                        <option value={config.id} key={`config${config.id}`}>
                                                            {config.name}
                                                        </option>
                                                    );
                                                })}
                                            </FormControl>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.list.map((row: any, i: number) => {
                                if (!i) {
                                    return null;
                                }

                                let recordClass = 'csv-row-sucess';
                                if (row.result !== undefined && !row.result) {
                                    recordClass = 'csv-row-error';
                                }

                                const OverlayTriggerDelay = 100;
                                const OverlayTriggerDelayHide = 100;

                                return (
                                    <tr className={`csv-row ${recordClass}`} key={`csv-row${i}`}>
                                        <td>
                                            {row.result !== undefined && !row.result ? (
                                                <OverlayTrigger
                                                    trigger="click"
                                                    delay={OverlayTriggerDelay}
                                                    delayHide={OverlayTriggerDelayHide}
                                                    placement="bottom"
                                                    overlay={this.renderPopover(row.error)}
                                                >
                                                    <span className="csv-glyphicon">
                                                        <span className="glyphicon glyphicon-question-sign" />
                                                    </span>
                                                </OverlayTrigger>
                                            ) : null}
                                            {i}
                                        </td>
                                        {row.data.map((v: string, j: number) => {
                                            return <td key={`csv-column${i}${j}`}>{v}</td>;
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Row>
            </Col>
        );
    }

    public changeImportSettings = (index: number) => (e: any) => {
        const v = e.target.value;
        const importSettings = this.state.importSettings;
        importSettings[index] = v;

        this.setState({ importSettings });
        this.props.returnImportSettings(importSettings);
    };

    protected renderPopover = (content: string) => {
        return <Popover id="popover-basic">{content}</Popover>;
    };
}

export default MatchbackImportList;
