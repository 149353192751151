import {
    ButtonPrimary,
    InputText,
    InputTextarea,
    InputCheckbox,
    InputColor,
    useCopyToClipboard
} from '@lambdacurry/component-library';
import { FormikProps } from 'formik';
import React, { FC } from 'react';

import { AppCard, AppSectionHeader } from '../../../../../components-v2/shared';
import { ApplicationSettingsFormValues, positioningInputs } from '../../../ApplicationSettings.helpers';

export interface PositioningTabProps {
    formikProps: FormikProps<ApplicationSettingsFormValues>;
    disableCustomCss: boolean;
    disableForm: boolean;
    showAppSpacer: boolean;
}

export const PositioningTab: FC<PositioningTabProps> = ({ formikProps, disableCustomCss, disableForm, showAppSpacer }) => {
    const { widget_code_css = '', widget_code_mobile_css = '' } = formikProps.values;

    const copyTarget = '.custom-css-copy';
    const copyOptions = { copyable: 'Copy CSS', copied: 'Copied!' };
    const [copyDesktopText, copyDesktopState] = useCopyToClipboard(widget_code_css, copyTarget, copyOptions);
    const [copyMobileText, copyMobileState] = useCopyToClipboard(widget_code_mobile_css, copyTarget, copyOptions);
    return (
        <AppCard className="advanced-settings-positioning">
            <AppSectionHeader title="Desktop Position Adjustment" />
            <div className="field-row">
                {positioningInputs.slice(0, 4).map(({ name, label }) => (
                    <InputText
                        key={name}
                        type="number"
                        name={`widget_adjustments.${name}`}
                        label={label}
                        formikProps={formikProps}
                        disabled={disableForm}
                    />
                ))}
            </div>

            <AppSectionHeader title="Custom Desktop CSS" />
            <div className="embed-code">
                <InputTextarea
                    name="widget_code_css"
                    className="embed-code-content"
                    placeholder={disableCustomCss ? 'Insufficient permissions to edit' : 'Insert code here'}
                    formikProps={formikProps}
                    disabled={disableCustomCss}
                />
                <ButtonPrimary className="custom-css-copy" disabled={copyDesktopState === 'copied'}>
                    {copyDesktopText}
                </ButtonPrimary>
            </div>

            <AppSectionHeader title="Mobile Position Adjustment" />
            <div className="field-row">
                {positioningInputs.slice(4, 8).map(({ name, label }) => (
                    <InputText
                        type="number"
                        key={name}
                        name={`widget_adjustments.${name}`}
                        label={label}
                        formikProps={formikProps}
                        disabled={disableForm}
                    />
                ))}
            </div>

            <AppSectionHeader title="Custom Mobile CSS" />
            <div className="embed-code">
                <InputTextarea
                    name="widget_code_mobile_css"
                    className="embed-code-content clx-margin-bottom-16"
                    placeholder={disableCustomCss ? 'Insufficient permissions to edit' : 'Insert code here'}
                    formikProps={formikProps}
                    disabled={disableCustomCss}
                />
                <ButtonPrimary className="custom-css-copy" disabled={copyMobileState === 'copied'}>
                    {copyMobileText}
                </ButtonPrimary>
            </div>

            {showAppSpacer && (<>
                <AppSectionHeader title="App Spacer" />
                <div className="field-row">
                    <InputCheckbox
                        name="widget_spacer_enabled"
                        className="clx-margin-bottom-16"
                        label="Enable app spacer"
                        labelPlacement="end"
                        formikProps={formikProps}
                    />
                </div>
                <div className="field-row">
                    <InputColor
                        name="widget_spacer_color"
                        label="Spacer background color"
                        formikProps={formikProps}
                    />
                </div>
            </>)}
        </AppCard>
    );
};
