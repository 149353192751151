import { ChangeEvent, FocusEvent } from 'react';
import { ButtonProps } from '@lambdacurry/component-library';

export interface FormikFieldProps {
    field?: {
        name: string;
        value: string | number;
        checked?: boolean;
        onChange: (event: ChangeEvent) => void;
        onBlur: (event: FocusEvent) => void;
    };
    form?: any;
    meta?: any;
    label?: string;
}

export interface IconButtonProps extends ButtonProps {
    icon: IconNames;
}

export const iconNames = [
    'addUser',
    'analytics',
    'apps',
    'arrowLeft',
    'bell',
    'chat',
    'checkbox',
    'checkboxFilled',
    'chevronDown',
    'clock',
    'clockFilled',
    'close',
    'company',
    'concessionManager',
    'copy',
    'deal',
    'download',
    'drag',
    'error',
    'expand',
    'filter',
    'flame',
    'hamburger',
    'help',
    'home',
    'income',
    'laptop',
    'location',
    'menu',
    'navigation',
    'paintBrush',
    'pencil',
    'phone',
    'plus',
    'profile',
    'radio',
    'radioFilled',
    'resize',
    'schedule',
    'search',
    'settings',
    'sms',
    'trash',
    'user',
    'users',
    'video'
] as const;

export type IconNames = typeof iconNames[number];
