import React, { FC } from 'react';
import { Modal, ModalHeader, AppList, AppListItem, AppListItemContent } from '../../../components-v2/shared';
import { ConcessionManagerReducerAction } from '../ConcessionManager.helpers';
import { closeModal, setModalData } from '../ConcessionManager.helpers';
import { SpotlightTemplate } from '../../../types/Offer';
import useStore from '../../../store/useStore';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import { emptyOffer } from '../../../store/initial';

export interface SelectTemplateModalProps {
    isOpen: boolean;
    dispatch: React.Dispatch<ConcessionManagerReducerAction>;
}
// added icon field for certain template for spotlight
export const spotlightTemplates: Array<SpotlightTemplate> = [
    {
        name: 'concession_manager',
        displayName: 'Concession Manager',
        templateValues: {
            ...emptyOffer,
            template: 'concession_manager',
            offer_menu_title: 'Get a Special Offer',
            offer_text: 'Get a Special Offer',
            offer_description: 'Take advantage of this special offer.',
            call_to_action: 'Submit For Offer',
            submission_button_text: 'Submit For Offer',
            order: 0
        }
    },
    {
        name: 'opportunities_promotions',
        displayName: 'Opportunities/Promotions',
        templateValues: {
            ...emptyOffer,
            template: 'opportunities_promotions',
            offer_menu_title: 'Get a Special Opportunity',
            offer_text: 'Get a Special Opportunity',
            offer_description: 'Take advantage of this special opportunity.',
            call_to_action: 'Submit For Opportunity',
            submission_button_text: 'Submit For Offer',
            order: 0
        }
    },
    {
        name: 'tour_now',
        displayName: 'Tour Now',
        templateValues: {
            ...emptyOffer,
            template: 'tour_now',
            offer_menu_title: 'Take a Virtual Tour Now!',
            offer_text: 'Take a Virtual Tour Now!',
            offer_description: 'Submit your email and we will send you a link to tour virtually.',
            call_to_action: 'Send Me The Tour Link',
            widget_icon: 'clxicon-icons_info',
            order: 0
        }
    },
    {
        name: 'floor_plans',
        displayName: 'Floor Plans',
        templateValues: {
            ...emptyOffer,
            template: 'floor_plans',
            offer_menu_title: 'Featured Floor Plan',
            offer_text: 'Featured Floor Plan',
            offer_description: 'Submit your email to learn more!',
            call_to_action: 'Learn More Now',
            widget_icon: 'clxicon-icons_info',
            order: 0
        }
    },
    {
        name: 'event_rsvp',
        displayName: 'Event RSVP',
        templateValues: {
            ...emptyOffer,
            template: 'event_rsvp',
            offer_menu_title: 'RSVP',
            offer_text: 'RSVP',
            offer_description: 'Join Us!',
            call_to_action: 'RSVP Today',
            widget_icon: 'clxicon-icons_info',
            order: 0
        }
    },
    {
        name: 'brochures',
        displayName: 'Brochures',
        templateValues: {
            ...emptyOffer,
            template: 'brochures',
            offer_menu_title: 'Request an E-Brochure!',
            offer_text: 'Request an E-Brochure!',
            offer_description: 'Click below to receive a link to our E-Brochure.',
            call_to_action: 'Send Me The Link',
            order: 0
        }
    },
    {
        name: 'stay_in_touch',
        displayName: 'Stay in Touch',
        templateValues: {
            ...emptyOffer,
            template: 'stay_in_touch',
            offer_menu_title: 'Receive Updates!',
            offer_text: 'Receive Updates!',
            offer_description: 'Join our email list to stay in the know.',
            call_to_action: 'Sign Up Now',
            widget_icon: 'clxicon-icons_info',
            order: 0
        }
    },
    {
        name: 'job_openings',
        displayName: 'Job Openings',
        templateValues: {
            ...emptyOffer,
            template: 'job_openings',
            offer_menu_title: 'Explore Job Openings',
            offer_text: 'Explore Job Openings',
            offer_description: 'Contact us to learn more about career opportunities.',
            call_to_action: 'Get In Touch',
            widget_icon: 'clxicon-icons_info',
            order: 0
        }
    },
    {
        name: 'schedule_interview',
        displayName: 'Schedule Interview',
        templateValues: {
            ...emptyOffer,
            template: 'schedule_interview',
            offer_menu_title: 'Schedule an Interview',
            offer_text: 'Schedule an Interview',
            offer_description: 'Visit with our team today to learn more about us.',
            call_to_action: 'Learn More',
            widget_icon: 'clxicon-icons_info',
            order: 0
        }
    },
    {
        name: 'questions',
        displayName: 'Questions',
        templateValues: {
            ...emptyOffer,
            template: 'questions',
            offer_menu_title: 'Contact Us',
            offer_text: 'Contact Us',
            offer_description: 'Submit an inquiry today to connect with our leasing team.',
            call_to_action: 'Get In Touch',
            widget_icon: 'clxicon-icons_info',
            order: 0
        }
    },
    {
        name: 'survey',
        displayName: 'Survey',
        templateValues: {
            ...emptyOffer,
            template: 'survey',
            offer_menu_title: 'Take This Survey',
            offer_text: 'Take This Survey',
            offer_description: 'Complete This Survey and Receive Your Personal Guide.',
            call_to_action: 'Receive Guidebook',
            widget_icon: 'clxicon-icons_info',
            order: 0
        }
    },
    {
        name: 'custom',
        displayName: 'Custom',
        templateValues: { ...emptyOffer, template: 'custom', order: 0 }
    }
];

export const SelectTemplateModal: FC<SelectTemplateModalProps> = ({ isOpen, dispatch }) => {
    const { store } = useStore();
    const { router, setSpotlightTemplate } = store;
    const { companyId } = router.params;

    const handleAfterClose = () => {
        closeModal(dispatch);
        setModalData(dispatch, null);
    };

    const handleTemplateClick = (index: number) => {
        setSpotlightTemplate(spotlightTemplates[index]);
        router.goTo(DefaultRoutes.ConcessionManagerNew, { companyId }, store);
    };

    return (
        <Modal isOpen={isOpen} onAfterClose={handleAfterClose}>
            <ModalHeader title="Select Spotlight Template" />
            <AppList>
                {spotlightTemplates.map((template, i) => {
                    return (
                        <AppListItem key={i}>
                            <AppListItemContent onClick={() => handleTemplateClick(i)} title={template.displayName} />
                        </AppListItem>
                    );
                })}
            </AppList>
        </Modal>
    );
};
