import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useAsyncEffect } from '@lambdacurry/component-library';
import { AppHeader, AppPage, AppSection, AppSectionHeader } from '../../../components-v2/shared';
import { EmailSettingsForm } from './components';
import useStore from '../../../store/useStore';
import { AgencyCustomization } from '../../../types';
import { NotFound } from '../../misc/NotFound';

import './agency-host-settings.scss';

export const AgencyHostSettings = observer(() => {
    const {
        store,
        agencyStore: { fetchAgencyCustomizationById, activeAgencyId, accessibleAgencies: agencies = [] }
    } = useStore();
    const { router } = store;

    const agency = agencies.find(a => a.id === activeAgencyId);

    const [agencyCustomizationData, setAgencyData] = useState<AgencyCustomization>();

    const setAgencyDataState = async () => {
        if (agency && agency.id) {
            const fetchedAgencyCustomizationData = await fetchAgencyCustomizationById(agency.id);

            if (fetchedAgencyCustomizationData) {
                setAgencyData(fetchedAgencyCustomizationData);
            }
        }
    };
    useAsyncEffect(setAgencyDataState, undefined, [activeAgencyId, router.currentPath]);

    if (activeAgencyId && !agency) {
        return <NotFound />;
    }

    return (
        <AppPage loading={!agencyCustomizationData}>
            <AppHeader title="Partner Email Sender Profile" />

            {/* <AppSection>
                <AppSectionHeader title="Subdomain" subtitle="Subdomain for theconversioncloud.com" />
                <SubdomainForm data={agencyCustomizationData} onSuccess={setAgencyData} />
            </AppSection> */}

            <AppSection>
                <AppSectionHeader title="Email settings" />
                <EmailSettingsForm data={agencyCustomizationData} onSuccess={setAgencyData} />
            </AppSection>

            {/* <AppSection>
                <AppSectionHeader title="Configure your own domain (optional)" />
                <ConfigureDomainForm data={agencyCustomizationData} onSuccess={setAgencyData} />
            </AppSection> */}
        </AppPage>
    );
});
