import { Formik, FormikHelpers, FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import Store from '../../store';
import { emptyOwnershipGroup } from '../../store/initial';
import { InjectedProps } from '../../types';
import { OwnershipGroup } from '../../types/ownershipGroup';
import ControlButtonSave from '../misc/ControlButtonSave';
import OwnershipGroupForm, { IOwnershipGroup } from './OwnershipGroupForm';

interface OwnershipGroupNewProps {
    handleClose: (ownershipGroup: OwnershipGroup) => any;
    store?: Store;
}

@inject('store')
@observer
export class OwnershipGroupNew extends React.Component<OwnershipGroupNewProps> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public initialValues = () => ({ ...emptyOwnershipGroup });

    public render() {
        return (
            <>
                <Col>
                    <Formik
                        initialValues={this.initialValues()}
                        enableReinitialize
                        onSubmit={this.handleSubmit}
                    >{this.renderForm()}</Formik>
                </Col>
            </>
        );
    }

    public renderForm = () => {
        return OwnershipGroupForm({
            editing: true,
            controls: (bag: FormikProps<IOwnershipGroup>) => <ControlButtonSave />
        });
    };

    public handleSubmit = (values: any, actions: FormikHelpers<any>) => {
        actions.setSubmitting(true);
        const { ownershipGroup } = this.injected.store;
        const { handleClose } = this.props;
        actions.setSubmitting(false);
        return ownershipGroup
            .create(values)
            .then(() => {
                actions.resetForm();
                handleClose(values);
            })
            .catch(error => actions.setErrors(error.response.data));
    };
}

export default OwnershipGroupNew;
