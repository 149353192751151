import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface ControlTooltipProps {
    text: string;
}

const ControlTooltip = (props: ControlTooltipProps) => {
    const tooltipOptions = {
        delay: 100,
        delayHide: 1000,
        // delayShow: 5000,
        // trigger: 'click',
        placement: 'top'
    };

    return (
        <OverlayTrigger {...tooltipOptions} overlay={<Popover id="popover-basic">{props.text}</Popover>}>
            <span className="margin-left">
                <span className="glyphicon glyphicon-question-sign" />
            </span>
        </OverlayTrigger>
    );
};

export default ControlTooltip;
