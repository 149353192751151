import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import './panel.scss';

export interface PanelProps extends HTMLAttributes<HTMLDivElement> {
    type?: 'default' | 'info' | 'success' | 'warning' | 'danger';
}

export const Panel: FC<PanelProps> = ({ className, type = 'default', ...props }) => (
    <div className={classNames('lc-panel', { [`lc-panel--${type}`]: type }, className)} {...props} />
);
