import axios, { AxiosInstance, AxiosPromise } from 'axios';
import { map } from 'lodash';
import { apiServerURL, pushDebug } from '../constants';
import Store from '../store';
import { BAD_REQUEST, UNAUTHORIZED } from './../constants/status';
import { QueryParams } from './../store/Resource';
import moment from 'moment';

interface LoginArgs {
    email: string;
    password: string;
}

interface ApiArgs {
    client?: AxiosInstance;
    token?: string;
    refreshToken?: string;
    store: Store;
}

class Api {
    public readonly client: AxiosInstance;
    protected token: string | null;
    protected refreshToken: string | null;
    protected refreshRequest?: AxiosPromise<any>;
    protected store: Store;

    constructor(options: ApiArgs) {
        this.client = options.client || axios.create({ baseURL: apiServerURL });
        this.token = options.token || localStorage.getItem('token');
        this.refreshToken = options.refreshToken || localStorage.getItem('refreshToken');
        this.store = options.store;

        this.client.interceptors.request.use(
            config => {
                if (!this.token) {
                    return config;
                }

                if (!config.baseURL) {
                    // Allow overriding the default config when the baseURL is overwritten
                    return config;
                }

                const newConfig = { headers: {}, ...config };
                newConfig.headers.Authorization = `Bearer ${this.token}`;
                return newConfig;
            },
            e => Promise.reject(e)
        );

        this.client.interceptors.response.use(
            r => r,
            async error => {
                this.storeError(error);
                this.ensureToken(error);

                if (!this.refreshRequest) {
                    this.refreshRequest = this.client.post('/refresh', {
                        token: this.refreshToken
                    });
                }
                try {
                    const { data } = await this.refreshRequest;
                    this.token = data.token;
                    this.refreshToken = data.refresh_token;
                    this.store.setTokens(data.token, data.refresh_token);
                    delete this.refreshRequest;
                } catch (err) {
                    this.store.logout();
                }
                const newRequest = {
                    ...error.config,
                    retry: true
                };
                return this.client(newRequest, { baseURL: apiServerURL });
            }
        );
    }

    public async login({ email, password }: LoginArgs) {
        const { data } = await this.client.post('/login', { email, password });
        return { data };
    }

    public setAuth(auth: { token: string; refreshToken: string }) {
        this.token = auth.token;
        this.refreshToken = auth.refreshToken;
    }

    public getCSVLeads(params?: QueryParams) {
        return this.getCSV('/leads/csv', 'leads', params);
    }

    public getMatchbackBundles(companyId: number) {
        return this.client.get(`/import/csv/matchback/${companyId}`);
    }

    public deleteMatchbackBundle(id: number) {
        return this.client.delete(`/import/csv/matchback/${id}`);
    }

    public getCSVLeadsInsite(params?: QueryParams) {
        return this.getCSV('/insite-meta-data/csv', 'insite_leads', params);
    }

    public getCSVPagesInsite(params?: QueryParams) {
        return this.getCSV('/insite-pages/csv', 'insite_pages', params);
    }

    public async getCsvThreadExport(threadId: number) {
        return await this.client.get(`/sms-threads/csv/${threadId}`);
    }

    public async getCsvAllThreadExport(companyId: number, smsArchiveState: boolean) {
        return await this.client.get(
            `/sms-threads/csv/all/${companyId}?time_zone=${moment.tz.guess()}&archive=${smsArchiveState}`
        );
    }

    public async getCSV(apiEntryPoint: string, exportFilename: string, params?: QueryParams) {
        const args: any = {};

        if (params && params.order) {
            args.order = params.order;
        }

        if (params && params.filters) {
            map(params.filters, (value: string, key: string) => (args[key] = value));
        }

        const url = `${apiEntryPoint}?${map(args, (value: string, key: string) => `${key}=${value ? value : ''}`).join(
            '&'
        )}`;
        const csvFile = await this.client.get(url);

        const blob = new Blob([csvFile.data], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, exportFilename);
        } else {
            const link = document.createElement('a');
            link.download = `${exportFilename}.csv`;
            link.href = URL.createObjectURL(blob);
            link.click();
        }
    }

    public async getCsvThread() {
        return await this.client.get(`/companies/csv`);
    }

    public async getCsvAllEmails() {
        return await this.client.get(`/companies/csv`);
    }

    public async getCsvAllUsers(agency_id?: number) {
        return await this.client.get(`/users/csv?${agency_id ? `agency_id=${agency_id}` : ''}`);
    }

    public async changePassword(args: { pass: string; confirm_pass: string; hash: string }) {
        const { data } = await this.client.post(
            '/change-password',
            { pass: args.pass, confirm_pass: args.confirm_pass },
            { headers: { Authorization: `Bearer ${args.hash}` } }
        );
        this.token = data.token;
        this.refreshToken = data.refresh_token;
        return { data };
    }

    public logout() {
        this.token = null;
        this.refreshToken = null;
    }

    public async getAssignedUsers(id: number) {
        const response = await this.client.get(`/companies/assign/${id}`);
        return response.data;
    }

    public async getHideAlertsUsers(id: number) {
        const response = await this.client.get(`/companies/hide-alert-users/${id}`);
        return response.data;
    }

    public async getAssignedTwilioNums(companyId: number) {
        const response = await this.client.get(`/twilio-numbers?company_id=${companyId}`);
        return response.data;
    }

    public async queryAvailableTwilio(data: {}) {
        const response = await this.client.post(`/twilio-numbers/queryAvailable`, data);
        return response.data;
    }

    public async provisionTwilioForSms(data: {}) {
        const response = await this.client.post(`/twilio-numbers/provision`, data);
        return response.data;
    }

    public async fetch(uri: string, params?: QueryParams) {
        const args: any = {};
        if (params) {
            if (params.search) {
                args.filter = params.search;
            }
            if (params.filters) {
                map(params.filters, (value: string, key: string) => {
                    if (value !== undefined) {
                        args[key] = value;
                    }
                });
            }
            if (params.pageSize) {
                const defaultPageSize = 10;
                args.limit = params.pageSize || defaultPageSize;
                args.offset = ((params.page || 1) - 1) * args.limit;
            }
            if (params.order) {
                args.order = params.order;
            }
        }
        return this.client.get(`/${uri}?${map(args, (value: string, key: string) => `${key}=${value}`).join('&')}`);
    }

    public async getUsersForAssign(email: string) {
        const response = await this.client.get(`/users/assign/${email}`);
        return response.data.users;
    }

    public async getUsersForHideAlert(email: string) {
        const response = await this.client.get(`/users/accessible-assign/${email}`);
        return response.data;
    }

    public async rebuildAllCss() {
        const response = await this.client.get(`/public_roles/rebuildAllCss`);
        return response.data;
    }

    public async getExternalCalendar(id: number) {
        return await this.client.get(`/calendars/${id}/external-calendar`);
    }

    public async getExternalCalendarForSchedule(id: number) {
        return await this.client.get(`/schedules/${id}/external-calendar`);
    }

    public async getExternalCalendarAuthTokens(id: number) {
        const response = await this.client.get(`/external/auth/tokens/${id}`);
        return response.data;
    }

    public async unlinkExternalAuthToken(id: number) {
        return await this.client.delete(`/external/auth/${id}`);
    }

    public async updateExternalCalendar(id: number, data: {}) {
        const response = await this.client.post(`/external/calendars/${id}`, data);
        return response;
    }

    public async assignTokenToCalendar(id: number, data: {}) {
        const response = await this.client.post(`/calendars/${id}/token`, data);
        return response;
    }

    public async assignTokenToSchedule(id: number, data: {}) {
        const response = await this.client.post(`/schedules/${id}/token`, data);
        return response;
    }

    public async unlinkTokenFromCalendar(id: number) {
        const response = await this.client.delete(`/calendars/${id}/token`);
        return response;
    }

    public async unlinkTokenFromSchedule(id: number) {
        const response = await this.client.delete(`/schedules/${id}/token`);
        return response;
    }

    public async getRemoteCalendars(id: number) {
        const response = await this.client.get(`/external/calendars/${id}/remote`);
        return response.data;
    }

    public async getRemoteCalendarsByScheduleId(id: number) {
        const response = await this.client.get(`/external/schedules/${id}/remote`);
        return response.data;
    }

    public async getOutlookAssign(options: any) {
        const response = await this.client.post(`/external/calendars/assign`, options);
        return response.data;
    }
    public async getExternalCalendarRegLinks(companyId: number) {
        const response = await this.client.get(`/external/auth/generate-registration-link/${companyId}`);
        return response;
    }

    public async getSubscriptionState(id: number) {
        const response = await this.client.get(`/external/calendars/${id}/subscription/enable`);
        return response.data;
    }

    public async remoteCalendarSubscriptionEnable(id: number) {
        await this.client.post(`/external/calendars/${id}/subscription`);
    }

    public async remoteCalendarSubscriptionDisable(id: number) {
        await this.client.delete(`/external/calendars/${id}/subscription`);
    }

    public async getIncomeLeadQualifications(leadId: number) {
        return this.client.get(`/income_calculators/${leadId}/calculate`);
    }

    public async getTemplateContent(companyId: number, templateCode: string, widgetId: number) {
        const response = await this.client.get(`/templates/${companyId}/template/${templateCode}/widget/${widgetId}`);
        return response.data;
    }

    public async importFile(url: string, file: File, saveResult: boolean, data?: any) {
        const formData = new FormData();
        formData.append('save', saveResult ? '1' : '0');
        formData.append('file', file);

        if (data) {
            this.appendFormdata(formData, data);
        }

        const response = await this.client.post(url, formData);
        return response.data;
    }

    private appendFormdata(formData: any, data: any, name?: any) {
        const self = this;
        if (typeof data === 'object' && !name) {
            Object.keys(data).forEach((k: any) => {
                self.appendFormdata(formData, data[k], k);
            });
        } else if (typeof data === 'object' && name) {
            self.appendFormdata(formData, JSON.stringify(data), name);
        } else {
            if (data !== undefined) {
                formData.append(name, data);
            }
        }
    }

    public async importCsvFile(file: File, saveResult: boolean) {
        return this.importFile('/import/csv', file, saveResult);
    }

    public importMatchbackFile(companyId: number, file: File, saveResult: boolean, settings: any) {
        return this.importFile('/import/csv/matchback', file, saveResult, { company_id: companyId, settings });
    }

    public async downloadCsvResult(importId: number) {
        const response = await this.client.get(`/import/csv/${importId}`);
        return response.data;
    }

    public async getCompany(id: number) {
        const response = await this.client.get(`/companies/${id}`);
        return response.data;
    }

    public async sendCompanyReviewRequest(companyId: number, requestType: string) {
        return await this.client.get(`/companies/${companyId}/request/${requestType}/send`);
    }

    public async getLatestThreadLead(threadId: number) {
        const response = await this.client.get(`/sms-threads/${threadId}/latest-lead`);
        return response.data;
    }

    public async subscribe(subscription: PushSubscription, userId: number) {
        if (pushDebug) {
            global.console.log('client.post(/subscribe/)', userId);
        }

        const response = await this.client.post('/subscribe', {
            user_id: userId,
            subscription
        });

        return response.data;
    }

    public async unsubscribe(userId: number) {
        if (pushDebug) {
            global.console.log('client.post(/subscribe/cancel)', userId);
        }

        const response = await this.client.post('/subscribe/cancel', {
            user_id: userId
        });

        return response.data;
    }

    protected ensureToken(error: { response: { status: number }; config: { retry: boolean } }) {
        if (!this.refreshToken || (error.response && error.response.status !== UNAUTHORIZED) || error.config.retry) {
            throw error;
        }
    }

    protected storeError(error: { response: any }) {
        const res = error.response;
        if (res) {
            let e = {};

            if (typeof res.data === 'string') {
                if (res.data.indexOf('authorization token') < 0 && res.data.indexOf('jwt expired') < 0) {
                    e[res.status] = res.data;
                }
            } else if (res.status >= BAD_REQUEST) {
                e = Object.assign({}, res.data);
            }

            this.store.setErrors(e);
        }
    }
}

export default Api;
