import React, { FC } from 'react';
import classNames from 'classnames';

import './app-section.scss';

export interface AppSectionProps {
    className?: string;
}

export const AppSection: FC<AppSectionProps> = ({ className, ...props }) => {
    return <section className={classNames('app-section', className)} {...props} />;
};
