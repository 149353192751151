import React, { FunctionComponent } from 'react';
import { get as _get } from 'lodash';
import useStore from '../../../store/useStore';
import { Wysiwyg, InputText, InputSwitch } from '../../../components-v2/shared';
import { EditEmailSectionProps } from './EditEmailSectionForm';
import { S3FileUploader } from '../../../components-v2/shared/s3-file-uploader';

export const EditEmailHeaderForm: FunctionComponent<EditEmailSectionProps> = ({
    section,
    formikProps,
    useEditAppEmailReducer,
    response
}) => {
    const [, dispatch] = useEditAppEmailReducer();
    const { store } = useStore();

    const setUploadingFile = (uploading: boolean) => dispatch({ name: 'setUploadingFile', payload: uploading });

    const setImageUrl = (url: string) => formikProps.setFieldValue(`${section.id}.imageUrl`, url);

    const handleClearImage = () => {
        setImageUrl('');
    };

    const handleCropSave = (fileUrl: string) => {
        setUploadingFile(false);
        setImageUrl(fileUrl);
    };

    return (
        <>
            <InputSwitch label="Show Header Image" name={`${section.id}.showImage`} formikProps={formikProps} />
            {formikProps.values[section.id].showImage && (
                <>
                    <S3FileUploader
                        name={`${section.id}.imageFile`}
                        application="mail-template"
                        data={{ companyId: parseInt(store.router.params.companyId, 0) }}
                        formikProps={formikProps}
                        fileUploaderProps={{
                            onDrop: () => setUploadingFile(true),
                            onClearAll: () => handleClearImage(),
                            onRemoveFile: () => handleClearImage()
                        }}
                        imageCropperProps={{ onSave: handleCropSave, onCancel: () => setUploadingFile(false) }}
                    />
                    <InputText
                        id="imageWidth"
                        label="Logo Width"
                        type="number"
                        name={`${section.id}.imageWidth`}
                        formikProps={formikProps}
                        suffix={<>Pixels</>}
                    />
                </>
            )}
            <Wysiwyg
                templateVariables={response.templateVariables}
                name={`${section.id}.htmlContent`}
                formikProps={formikProps}
            />
        </>
    );
};
