import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { MobxRouter } from 'mobx-router';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';
import useStore from '../../store/useStore';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { CompanyTrialEndingAlert, DemoModeAlert } from '../Alerts';
import { isFullPageView, isUnauthorizedView } from './Bengal.helpers';
import Spinner from '../misc/Spinner';
import { GlobalModals } from '../Modals';
import { LayoutWrapper } from '../Layout/LayoutWrapper';
import { IconRegistry } from './IconRegistry';
import { QueryParamProvider } from 'use-query-params';

const LoadingView = () => (
    <div className="bengal">
        <Spinner />
    </div>
);

export const Bengal = observer(() => {
    const { store, agencyStore } = useStore();
    const { loggedIn, isChatRole, router, activeUserFetched, isLoading } = store;
    const { agencyCustomization } = agencyStore;

    useEffect(() => {
        if (!loggedIn && !isUnauthorizedView(router)) {
            router.goTo(DefaultRoutes.Login);
        }
    }, [loggedIn]);

    if (isUnauthorizedView(router)) {
        return (
            <IconRegistry>
                <div className={classNames('bengal', !agencyCustomization ? 'loading' : '')}>
                    <MobxRouter />
                </div>
            </IconRegistry>
        );
    }

    if (!loggedIn || !activeUserFetched) {
        return <LoadingView />;
    }

    return (
        <QueryParamProvider>
            <IconRegistry>
                <div className={classNames('bengal', 'bengal--new-layout', !agencyCustomization ? 'loading' : '')}>
                    {isLoading && <Spinner />}
                    <ToastContainer hideProgressBar={true} newestOnTop={true} />
                    <LayoutWrapper isFullPage={isFullPageView(router)}>
                        {!isFullPageView(router) && !isChatRole && (
                            <>
                                <CompanyTrialEndingAlert store={store} />
                                <DemoModeAlert />
                            </>
                        )}
                        <MobxRouter />
                    </LayoutWrapper>
                    <GlobalModals />
                </div>
            </IconRegistry>
        </QueryParamProvider>
    );
});
