import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Alert, Col, PageHeader, Row, Well } from 'react-bootstrap';
import { InjectedProps, PublicRole } from '../../types';
import CompanyRoleNew from './CompanyRoleNew';
import CompanyRoleEdit from './CompanyRoleEdit';

interface CompanyRoleListState {
    fetchedData: boolean;
    newItem?: PublicRole;
    countCssFiles?: number;
}

@inject('store')
@observer
export class CompanyRoleList extends React.Component<{}, CompanyRoleListState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state: CompanyRoleListState = {
        fetchedData: false
    };

    public componentDidMount() {
        return this.fetchData();
    }

    public fetchData() {
        const { fetchCssPaths, fetchActiveFeatures } = this.injected.store;
        return fetchCssPaths()
            .then(() => fetchActiveFeatures())
            .then(() => this.setState({ fetchedData: true }));
    }

    public render() {
        const { newItem } = this.state;
        const { publicRoles } = this.injected.store;

        if (!this.state.fetchedData) {
            return null;
        }

        if (publicRoles.length > 0) {
            const firstItemId = publicRoles.getFirstItemId();
            if (firstItemId) {
                return <CompanyRoleEdit firstItemId={firstItemId} />;
            }
        }

        return (
            <>
                <PageHeader>Company Public Roles</PageHeader>
                <Col className="col-lg-12">
                    {newItem && (
                        <Alert bsStyle="success" onDismiss={this.handleDismissAlert}>
                            New company public role created successfully.
                        </Alert>
                    )}
                    {this.state.countCssFiles !== undefined && (
                        <Alert bsStyle="success" onDismiss={this.handleDismissAlertCss}>
                            Css files ({this.state.countCssFiles}) were rebuilt successfully.
                        </Alert>
                    )}
                    <Row>
                        <Well>
                            <CompanyRoleNew handleClose={this.itemAdded} opened />
                        </Well>
                    </Row>
                </Col>
            </>
        );
    }

    private itemAdded = (item: PublicRole) => {
        this.setState({ newItem: item });
    };

    private handleDismissAlert = () => {
        this.setState({ newItem: undefined });
    };

    private handleDismissAlertCss = () => {
        this.setState({ countCssFiles: undefined });
    };
}

export default CompanyRoleList;
