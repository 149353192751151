import React, { createContext, FC, ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Drawer } from '@lambdacurry/component-library';
import { TopBar } from './TopBar';
import { SideNav } from './SideNav';
import { useAsyncEffect } from '../../util/async';
import useStore from '../../store/useStore';
import { AccessibleAgency, AccessibleCompany, ActiveUser, AgencyCustomization } from '../../types';

import './layout.scss';

export interface LayoutWrapperProps {
    className?: string;
    children: ReactNode;
    isFullPage?: boolean;
}

export interface LayoutContextType {
    loading: boolean;
    activeUser: ActiveUser | null;
    activeAgencyId: number;
    activeCompanyId: number;
    activeAgency?: AccessibleAgency;
    activeCompany?: AccessibleCompany;
    agencyCustomization: AgencyCustomization;
    accessibleAgencies: AccessibleAgency[];
    accessibleCompanies: AccessibleCompany[];
}

export const LayoutContext = createContext({} as LayoutContextType);

export const LayoutWrapper: FC<LayoutWrapperProps> = observer(({ className, children, isFullPage }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const { store, agencyStore } = useStore();
    const {
        loggedIn,
        router,
        activeUser,
        isCompanyRole,
        accessibleCompanies,
        fetchActiveUser,
        fetchFeatures,
        fetchFeatureFlags,
        fetchSmsThreads
    } = store;
    const { accessibleAgencies, fetchAgencyById, fetchAgencyCustomization, agencyCustomization } = agencyStore;
    const { activeUserFetched } = store;
    const { companyId, agencyId } = router.params || {};

    const activeCompanyId = parseInt(`${store.activeCompanyId || companyId}`, 10);

    const activeCompany = store.activeCompany || accessibleCompanies.find(company => company.id === activeCompanyId);

    // IMPORTANT: Don't set the activeUserAgencyId if the user has a company role to avoid
    // filtering out accessible companies from the ViewSwitcher options.
    const activeUserAgencyId =
        !isCompanyRole && activeUser?.accessibleAgencyIds?.length === 1
            ? activeUser?.accessibleAgencyIds[0]
            : undefined;

    const activeAgencyId = parseInt(
        `${agencyId || agencyStore.activeAgency?.id || activeCompany?.agency_id || activeUserAgencyId || '0'}`,
        10
    );
    const activeAgency = agencyStore.activeAgency || accessibleAgencies.find(agency => agency.id === activeAgencyId);

    const fetchLayoutData = async () => {
        if (!loggedIn) {
            return;
        }

        setLoading(true);

        if (activeAgencyId && (!activeAgency || activeAgency.id !== activeAgencyId)) {
            await fetchAgencyById(activeAgencyId);
        }

        if (agencyCustomization?.agency_id !== activeAgencyId) {
            await fetchAgencyCustomization(activeAgencyId);
        }

        if (activeCompanyId) {
            await fetchFeatureFlags();
            await fetchFeatures();
            await fetchSmsThreads();
        }

        if (!activeUserFetched) {
            await fetchActiveUser();
        }

        setLoading(false);
    };
    useAsyncEffect(fetchLayoutData, undefined, [activeAgencyId, activeCompanyId]);

    return (
        <LayoutContext.Provider
            value={{
                loading,
                activeUser,
                activeAgencyId,
                activeCompanyId,
                activeAgency,
                activeCompany,
                agencyCustomization,
                accessibleAgencies,
                accessibleCompanies
            }}
        >
            <main className={classNames('layout-wrapper', { 'layout-wrapper-full-page': isFullPage }, className)}>
                {!isFullPage && (
                    <>
                        <div className="layout-header">
                            <TopBar />
                        </div>

                        <div className="layout-sidebar">
                            <SideNav />
                        </div>

                        <Drawer className="layout-drawer" name="side-nav-drawer" options={{ anchor: 'left' }}>
                            <SideNav />
                        </Drawer>
                    </>
                )}
                <div className="layout-content">{children}</div>
            </main>
        </LayoutContext.Provider>
    );
});
