export const dateTimeFormats = {
    timeDefault: 'hh:mma',
    dateDefault: 'MMM Do, YYYY', // Feb 21st, 2020
    reminderDate: 'dddd, MMM Do YYYY, HH:mm', // Thursday, Jan 30th 2020, 15:00
    dateApi: 'YYYY-M-D',
    timeApi: 'hh:mm a',
    date: 'YYYY-MM-DD HH:mm',
    leadDateTime: 'MM/DD/YYYY hh:mm A', // 02/18/2020 02:37 PM
    auditTime: 'MM-D-YYYY : hh:mm A', // 02:37 PM 18-02-2020
    alertDate: 'MMM Do, hh:mma' // Jan 30th, 15:00
};
