import {
    ButtonOutlinePrimary,
    Form,
    handlePromise,
    IconButton,
    InputCheckbox,
    InputSelect,
    InputText,
    useAsyncEffect
} from '@lambdacurry/component-library';
import { FormikHelpers, FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { AppSectionHeader } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { QuestionDefinition, QuestionDefinitionAdHocTag, QuestionResponseFormat } from '../../types/QuestionSettings';

import './question-editor.scss';

export interface JSONWithQuestionBundle {
    question_bundle: {
        questions: QuestionDefinition[];
    };
}

export interface PredicatePicklists {
    [x: string]: Array<{ val: string; label: string }>;
}

interface QuestionDefinitionBundle {
    presets: QuestionDefinition[];
    tags: { value: string }[];
}

export const QuestionEditor: FunctionComponent<{
    formikProps: FormikProps<JSONWithQuestionBundle>;
    sectionTitle?: string;
    questionLimit?: number;
}> = ({ formikProps, sectionTitle, questionLimit }) => {
    const { store } = useStore();
    const { router, Api } = store;
    const { companyId } = router.params;
    const [questionDefinitionBundle, setQuestionDefinitionBundle] = useState<QuestionDefinitionBundle>({
        presets: [],
        tags: []
    });

    const questions = formikProps.values.question_bundle?.questions;
    const shouldLimitQuestions = questionLimit && questions && questions.length >= questionLimit;

    const handleAddQuestion = (values: { preset: QuestionDefinition }, actions: FormikHelpers<{}>) => {
        if (!formikProps.values.question_bundle?.questions) {
            formikProps.values.question_bundle = { questions: [] };
        }
        const question_bundle = cloneDeep(formikProps.values.question_bundle);
        question_bundle.questions.push(values.preset);
        formikProps.setFieldValue('question_bundle', question_bundle);
    };

    const loadPresets = async () => {
        const [response, error] = await handlePromise<{ data: QuestionDefinitionBundle }>(
            Api.client.get(`/company/${companyId}/question-definitions`)
        );
        if (!response?.data || error) {
            return;
        }

        setQuestionDefinitionBundle(response.data);
    };

    useAsyncEffect(loadPresets);

    const handleDeleteQuestion = (questionNumber: number) => {
        const question_bundle = cloneDeep(formikProps.values.question_bundle);
        if (
            question_bundle.questions &&
            question_bundle.questions.length > questionNumber &&
            window.confirm('Are you sure you want to remove this question?')
        ) {
            question_bundle.questions.splice(questionNumber, 1);
            formikProps.setFieldValue('question_bundle', question_bundle);
        }
    };

    const handleMoveQuestionDown = (questionNumber: number) => {
        if (formikProps.values.question_bundle.questions.length > questionNumber) {
            handleMoveQuestionUp(questionNumber + 1);
        }
    };

    const handleMoveQuestionUp = (questionNumber: number) => {
        const question_bundle = cloneDeep(formikProps.values.question_bundle);
        const moved = question_bundle.questions[questionNumber - 1];
        question_bundle.questions[questionNumber - 1] = question_bundle.questions[questionNumber];
        question_bundle.questions[questionNumber] = moved;
        formikProps.setFieldValue('question_bundle', question_bundle);
    };

    const changeResponseFormat = (questionNumber: number, response_format: QuestionResponseFormat) => {
        const question_bundle = cloneDeep(formikProps.values.question_bundle);
        question_bundle.questions[questionNumber].response_format = response_format;
        formikProps.setFieldValue('question_bundle', question_bundle);
    };

    const filterBySelectedItems = (source: QuestionDefinition[]): QuestionDefinition[] => {
        const usedLabels = formikProps?.values?.question_bundle?.questions?.map(q => {
            return q?.tag;
        });
        return source.filter(s => {
            // Filter out the used labels but we always want to be able to ad Ad hoc questions
            return s.tag === QuestionDefinitionAdHocTag || !usedLabels?.includes(s?.tag);
        });
    };

    return (
        <>
            <AppSectionHeader title={sectionTitle || 'Question Editor'} />
            {formikProps.values.question_bundle?.questions &&
                formikProps.values.question_bundle.questions &&
                formikProps.values.question_bundle.questions.map((question: QuestionDefinition, q: number) => {
                    return (
                        <div className="question-editor">
                            <div className="question-editor-entry">
                                <div className="question-editor-entry-toolbar">
                                    <div>
                                        <InputCheckbox
                                            className="question-editor-entry-toolbar-checkbox"
                                            title="Required"
                                            label="Required"
                                            labelPlacement="start"
                                            name={`question_bundle.questions[${q}].required`}
                                            formikProps={formikProps}
                                        />
                                    </div>
                                    <div className="question-editor-entry-toolbar-trash-button">
                                        <IconButton
                                            icon="trash"
                                            title="Remove Rule"
                                            onClick={() => handleDeleteQuestion(q)}
                                        />
                                    </div>
                                    <div className="question-editor-entry-toolbar-trash-button">
                                        <IconButton
                                            icon="chevronDown"
                                            className="rotate-button"
                                            title="Move Rule Up"
                                            disabled={
                                                q === 0 || formikProps.values.question_bundle.questions.length <= q
                                            }
                                            onClick={() => handleMoveQuestionUp(q)}
                                        />
                                    </div>
                                    <div className="question-editor-entry-toolbar-trash-button">
                                        <IconButton
                                            icon="chevronDown"
                                            title="Move Rule Down"
                                            disabled={formikProps.values.question_bundle.questions.length <= q + 1}
                                            onClick={() => handleMoveQuestionDown(q)}
                                        />
                                    </div>
                                    <div className="question-editor-entry-toolbar-response-type-button">
                                        <span
                                            onClick={() => changeResponseFormat(q, QuestionResponseFormat.text)}
                                            className={
                                                question.response_format === QuestionResponseFormat.text
                                                    ? 'label-active'
                                                    : ''
                                            }
                                        >
                                            Free form text
                                        </span>
                                        <span
                                            onClick={() => changeResponseFormat(q, QuestionResponseFormat.select)}
                                            className={
                                                question.response_format === QuestionResponseFormat.select
                                                    ? 'label-active'
                                                    : ''
                                            }
                                        >
                                            Select list
                                        </span>
                                        <span
                                            onClick={() => changeResponseFormat(q, QuestionResponseFormat.date)}
                                            className={
                                                question.response_format === QuestionResponseFormat.date
                                                    ? 'label-active'
                                                    : ''
                                            }
                                        >
                                            Date
                                        </span>
                                    </div>
                                </div>
                                <div className="question-editor-entry-content">
                                    <InputText
                                        label={question.tag || 'Ad Hoc Question'}
                                        name={`question_bundle.questions[${q}].text`}
                                        className="question-editor-entry-content-textbox"
                                        formikProps={formikProps}
                                    />
                                    {question.response_format === QuestionResponseFormat.select && (
                                        <div className="question-editor-entry-select-section">
                                            <InputSelect
                                                label="Answer Options"
                                                className="question-editor-entry-content-textbox"
                                                name={`question_bundle.questions[${q}].picklist`}
                                                options={question.picklist || []}
                                                formikProps={formikProps}
                                                autocompleteConfig={{ multiple: true, disableClearable: false }}
                                                contentEditable={false}
                                                allowCreateOption={true}
                                                inputProps={{ autoComplete: 'disable' }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            {shouldLimitQuestions ? (
                <p>You have added the maximum allowed number of questions.</p>
            ) : (
                <Form onSubmit={handleAddQuestion} initialValues={{}}>
                    {formikRuleProps => {
                        return (
                            <div className="question-editor-new">
                                <InputSelect
                                    label="Question Presets"
                                    name="preset"
                                    options={filterBySelectedItems(questionDefinitionBundle.presets || [])}
                                    optionLabelKey="text"
                                    helperText="Select the question you want to add"
                                    className="question-editor-new-select"
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikRuleProps}
                                    inputProps={{ autoComplete: 'disable' }}
                                />
                                <ButtonOutlinePrimary type="submit" className="add-question-submit">
                                    Add Question
                                </ButtonOutlinePrimary>
                            </div>
                        );
                    }}
                </Form>
            )}
        </>
    );
};
