import { FormikProps } from 'formik';
import * as React from 'react';
import { ICompanyRoleForm } from '../CompanyRoleForm';
import Common from './CompanyRoleWidgetMenuCommon';
import Wallet from './CompanyRoleWidgetMenuWallet';

export interface CompanyRoleWidgetProps {
    settings: FormikProps<ICompanyRoleForm>;
    defaultSettings: any;
}

const WidgetMenus = {
    default: Common,
    skin1: Wallet
};

export const CompanyRoleWidgetMenu: React.FC<CompanyRoleWidgetProps> = props => {
    const { settings } = props;
    const Menu = WidgetMenus[settings.values.skin];
    return <Menu {...props} />;
};

export default CompanyRoleWidgetMenu;
