import { ChatbotAI } from "../../types";

export function returnAsArray(data: ChatbotAI | null) {
    const d = JSON.parse(JSON.stringify(data));
    if (Array.isArray(d)) {
        return d;
    }
    return [d];
}

export function getWidgetCode(id: string) {
    return `<!-- Start of ChatBot (www.chatbot.com) code -->
    <script type="text/javascript">
        window.__be = window.__be || {};
        window.__be.id =  "${id}";
        (function() {
            var be = document.createElement('script'); be.type = 'text/javascript'; be.async = true;
            be.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.chatbot.com/widget/plugin.js';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(be, s);
        })();
    </script>
    <noscript>You need to <a href="https://www.chatbot.com/help/chat-widget/enable-javascript-in-your-browser/" rel="noopener nofollow">enable JavaScript</a> in order to use the AI chatbot tool powered by <a href="https://www.chatbot.com/" rel="noopener nofollow" target="_blank">ChatBot</a></noscript>
    <!-- End of ChatBot code -->`
}

export function getChatbotWidgetId(script: string) {
    const windowBeIdRegex = /window\.__be\.id\s*=\s*"([^"]+)"/;
    const match = script.match(windowBeIdRegex);
    const scriptContent = match ? match[1].trim() : null;

    return scriptContent ?? '';
}

export function formatValues(payload: { [key: string]: any }, companyId?: string) {
    const data = {
        active: false,
        company_id: companyId,
        ...payload[0],
        ...payload
    }
    delete data[0];
    delete data.id;

    return data;
}
