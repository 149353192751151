import * as React from 'react';
import { FormControl } from 'react-bootstrap';

export interface ControlSelectProps {
    optionData: any;
    id?: string;
    value?: string | number;
    optionKeyValue?: string;
    optionKeyLabel?: string;
    optionEmptyTitle?: string;
    onChange?: (e: string, v: any) => void;
    className?: string;
    disabled?: boolean;
}

export default function ControlSelect(props: ControlSelectProps) {
    const keyLabel = props.optionKeyLabel || 'name';
    const keyValue = props.optionKeyValue || 'id';
    const emptyTitle = props.optionEmptyTitle || '...';

    const onChange = (e: any) => {
        if (props.onChange) {
            props.onChange(e.target.id, e.target.value);
        }
    };

    const value = props.value !== undefined ? props.value.toString() : '';
    const className = `col-md-4 col-sm-6 ${props.className || ''}`;

    return (
        <FormControl
            id={props.id}
            componentClass="select"
            value={value}
            className={className}
            onChange={onChange}
            disabled={props.disabled}
        >
            <option value="">{emptyTitle}</option>
            {props.optionData.map((v: any, i: number) => (
                <option key={i} value={v[keyValue]}>
                    {v[keyLabel]}
                </option>
            ))}
        </FormControl>
    );
}
