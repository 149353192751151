import React, { useReducer } from 'react';
import { ButtonPrimary, useAsyncEffect } from '@lambdacurry/component-library';
import useStore from '../../store/useStore';
import { AppPage, AppHeader, useSnackbar, AppListEmpty } from '../../components-v2/shared';
import { ReactComponent as Illustration } from '../ConcessionManager/EmptyOffersIllustration.svg';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { emptyIncomeCalculator } from '../../store/initial';
import { incomeCalculatorInitialState, incomeCalculatorReducer, setLoading } from './IncomeCalculator.helpers';

import styles from './IncomeCalculator.module.scss';
import { IncomeCalculatorEdit } from './IncomeCalculatorEdit';

export const IncomeCalculatorNew = () => {
    const [state, dispatch] = useReducer(incomeCalculatorReducer, incomeCalculatorInitialState);
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, fetchIncomeCalculators, incomeCalculators } = store;
    const { companyId } = router.params;

    const fetchData = async () => {
        await fetchIncomeCalculators();
        setLoading(dispatch, false);
    };
    useAsyncEffect(fetchData);

    if (!companyId) {
        router.goTo(DefaultRoutes.Home, {}, store);
        return null;
    }

    if (incomeCalculators.length > 0) {
        return <IncomeCalculatorEdit incomeCalculatorId={incomeCalculators.getFirstItemId()} />;
    }

    const handleSubmit = async () => {
        const initialValues = {
            ...emptyIncomeCalculator,
            company_id: companyId,
            active: false,
        };
        try {
            setLoading(dispatch, true);
            const response = await incomeCalculators.create(initialValues);
            if (response) setLoading(dispatch, false);
            addSnackbar(`Income calculator has been created.`, {
                variant: 'success',
            });
        } catch (error) {
            addSnackbar(`Failed to initialize Income Calculator`, {
                variant: 'error',
            });
        }
    };



    const enableIncomeCalcButton = (
        <ButtonPrimary data-test-id="button_ic_create_new_calc" onClick={handleSubmit}>
            Configure Income Calculator
        </ButtonPrimary>
    );

    return (
        <AppPage loading={state.loading} className={styles.incomeCalculator}>
            <AppHeader title="Income Calculator" icon={{ name: 'income', color: 'purple' }} />
            <AppListEmpty
                illustration={<Illustration />}
                title="Capture more leads with prequalifications!"
                description="Offer potential customers a way to calculate their income and see if they qualify for your community."
                action={enableIncomeCalcButton}
            />
        </AppPage>
    );
};
