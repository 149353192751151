import React, { ChangeEvent, Dispatch, FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import ClarityReportRequest from './ClarityReportRequest';

import './clarity-reports.scss';
import { AppCard, AppListEmpty, AppListLoading, InputText, Icon } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { ButtonPrimary, InputSelect, PaginationPrimary, useAsyncEffect } from '@lambdacurry/component-library';
import { ClarityReportRequestData } from './Clarity.types';
import { clarityPaginationOptions, ClarityReducerAction, ClarityReducerState } from './Clarity.helpers';
import { ReactComponent as EmptyReportsIllustration } from './EmptyReportsIllustration.svg';
import { debounce } from 'lodash';
export interface ClarityReportsProps {
    state: ClarityReducerState;
    dispatch: Dispatch<ClarityReducerAction>;
    fetchClarityReportsData: (pageNumber?: number, newLimit?: number, search?: string) => Promise<any>;
}

const ClarityReports: FC<ClarityReportsProps> = observer(({ state, dispatch, fetchClarityReportsData }) => {
    const { store } = useStore();
    const { isCompanyLightRole, deleteClarityReportRequest } = store;
    const { loading, page, limit, items } = state.reportsTab;
    const [searchValue, setSearchValue] = useState<string>('');

    useAsyncEffect(
        async () => {
            await fetchClarityReportsData(page, limit, searchValue);
        },
        undefined,
        [page, limit]
    );
    const debounceSearch = useCallback(
        debounce(async (inputValue: string) => {
            await fetchClarityReportsData(page, limit, inputValue);
        }, 1000),
        []
    );

    const handleDelete = async (guid: string) => {
        if (window.confirm('Are you sure you wish to delete this report?')) {
            await deleteClarityReportRequest(guid);
            await fetchClarityReportsData(page, limit);
        }
        return;
    };

    const handlePageChange = (event: ChangeEvent<any>, pageNumber: number) =>
        dispatch({ name: 'setReportsTabPage', payload: pageNumber });

    const handleLimitChange = (event: ChangeEvent<any>, { value }: { value: number }) => {
        dispatch({ name: 'setReportsTabPage', payload: 1 });
        dispatch({ name: 'setReportsTabLimit', payload: value });
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        debounceSearch(event.target.value);
    };

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
        if (e.key === 'Enter') {
            debounceSearch.cancel();
            await fetchClarityReportsData(page, limit, searchValue);
        }
    };

    return (
        <AppCard>
            {loading && <AppListLoading title="Retrieving created reports" />}

            {!loading && items && (
                <>
                    <div className="clarity-search-reports">
                        <InputText
                            name="searchValue"
                            value={searchValue}
                            onChange={handleInputChange}
                            prefix={<Icon name="search" />}
                            onKeyDown={handleKeyDown}
                            placeholder="Search report name..."
                        />
                    </div>
                    {items.totalRecords < 1 && (
                        <AppListEmpty
                            illustration={<EmptyReportsIllustration />}
                            title="No reports created"
                            description="Create a filtered report to review conversions, leads, and performance."
                            action={
                                <>
                                    {!isCompanyLightRole && (
                                        <ButtonPrimary
                                            onClick={() =>
                                                dispatch({ name: 'setCreateReportModalOpen', payload: true })
                                            }
                                        >
                                            Create Report
                                        </ButtonPrimary>
                                    )}
                                </>
                            }
                        />
                    )}

                    {items.totalRecords > 0 && (
                        <>
                            {items.data.map((reportRequest: ClarityReportRequestData, index: number) => (
                                <ClarityReportRequest
                                    key={index}
                                    handleDelete={handleDelete}
                                    reportRequest={reportRequest}
                                />
                            ))}

                            <div className="pagination-controls clx-margin-top-16">
                                <InputSelect
                                    name="rowCount"
                                    label="Rows"
                                    options={clarityPaginationOptions}
                                    value={{ label: `${limit}`, value: limit }}
                                    onChange={handleLimitChange}
                                />

                                <PaginationPrimary
                                    pagesCount={Math.ceil(items.totalRecords / limit)}
                                    page={page}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </AppCard>
    );
});

export default ClarityReports;
