import React, { FC, useState } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputSelect,
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { ButtonOutlinePrimary, handlePromise, useAsyncEffect } from '@lambdacurry/component-library';
import { RemoteAuth } from '../../../../types/RemoteAuth';
import { runInAction } from 'mobx';

export interface RentCafeFormV2Props {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface ZohoCrm extends Crm {
    name: string;
    configuration: {
        api_key: string;
    };
}


export const ZohoForm: FC<RentCafeFormV2Props> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { Api, activeCompany, router } = store;
    const { companyId } = router.params;
    const [remoteAuths, setRemoteAuths] = useState<Array<RemoteAuth>>();

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleSubmit = async (values: ZohoCrm, actions: FormikHelpers<ZohoCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.zoho);
    };

    const handleValidate = async (formikProps: FormikProps<ZohoCrm>) => {

        const updatedValue = formikProps.values;

        if (!updatedValue.ruleset) {
            updatedValue.ruleset = { rules: [] }
        }

        if (remoteAuths && remoteAuths.length > 0) {
            const { key } = remoteAuths[0];
            if (updatedValue && updatedValue.configuration) {
                runInAction(() => {
                    updatedValue.configuration.api_key = key;
                })
            }
            await onValidate(updatedValue, crmConfigTypes.zoho);
        }

    };


    const fetchCompanyAuthTokens = async () => {
        if (!activeCompany || !activeCompany.id) {
            router.goTo(DefaultRoutes.Home, { ...router.params }, store);
            return Promise.resolve();
        }

        const [response, err] = await handlePromise(Api.client.get(`external/auth/tokens/${activeCompany?.id}`));

        if (err) {
            return;
        }
        setRemoteAuths(response.data);
    };

    useAsyncEffect(fetchCompanyAuthTokens);

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<ZohoCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Zoho" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputSelect
                                    label="Zoho Access Token"
                                    name="configuration.api_key"
                                    optionLabelKey="name"
                                    optionValueKey="key"
                                    options={remoteAuths || []}
                                    formikProps={formikProps}
                                    autocompleteConfig={{
                                        disableClearable: true,
                                    }}
                                />
                            </div>
                        </AppSection>
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)} >
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
