import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { ButtonUnstyled, DecorativeIconProps, IconButton } from '@lambdacurry/component-library';
import classNames from 'classnames';
import React, { CSSProperties, Ref, ReactElement, FC, useState, memo, forwardRef, useEffect } from 'react';
import Store from '../../../store';
import { Offer } from '../../../types/Offer';
import { dateFormatter } from '../../../util/formatters';
import { Transform } from '@dnd-kit/utilities';
import { AppListItem, AppListItemContent, AppListItemDetails, AppListItemActions } from '../../../components-v2/shared';
import { Handle } from '../../../components-v2/shared/dnd';
import { Sortable } from '../../../components-v2/shared/dnd/Sortable';
import { ToggleOfferSwitch } from './ToggleOfferSwitch';

interface ActiveApp {
    id: number;
    company_id: number;
    offer_text: string;
    offer_description: string;
    offer_menu_title: string;
    active: boolean;
    created_at: string | null;
    updated_at: string | null;
    order: number;
}
interface ActiveAppItem extends ActiveApp {
    icon: DecorativeIconProps;
}
interface ActiveAppOrderListItemProps extends ActiveAppItem {
    offersList: Offer[];
    index: number;
    handleMoveInArray: (index: number, direction: string) => void;
    storeOffers: any;
    dispatch: any;
    storeOffer: Offer;
    handleEditClick: (value: Offer) => void;
    handleDeleteOffer: (value: Offer) => void;
    handleCopyClick: (value: Offer) => void;
    setLoadingTopics: (value: boolean) => void;
}
interface AppOrderSortableProps {
    className: string;
    dragOverlay?: boolean;
    color?: string;
    disabled?: boolean;
    dragging?: boolean;
    handle?: boolean;
    height?: number;
    index?: number;
    fadeIn?: boolean;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    sorting?: boolean;
    style?: CSSProperties;
    transition?: string | null;
    wrapperStyle?: CSSProperties;
    value: ActiveAppOrderListItemProps;
    onRemove?(): void;
    renderItem?(args: {
        dragOverlay: boolean;
        dragging: boolean;
        sorting: boolean;
        index: number | undefined;
        fadeIn: boolean;
        listeners: DraggableSyntheticListeners;
        ref: Ref<HTMLElement>;
        style: CSSProperties | undefined;
        transform: AppOrderSortableProps['transform'];
        transition: AppOrderSortableProps['transition'];
        value: AppOrderSortableProps['value'];
    }): ReactElement;
}

export const OfferList: FC<{
    offers: Array<Offer>;
    store: Store;
    dispatch: any;
    handleEditClick: (value: Offer) => void;
    handleCopyClick: (value: Offer) => void;
    handleDeleteOffer: (value: Offer) => void;
    setLoadingTopics: (value: boolean) => void;
}> = ({ offers, store, dispatch, handleCopyClick, handleEditClick, handleDeleteOffer, setLoadingTopics }) => {
    const { offers: storeOffers } = store;
    const [offersList, setoffersList] = useState<Array<Offer>>(offers || []);
    const handleMoveInArray = (currentIndex: any, direction: 'up' | 'down') => {
        const newArray = [...offersList];
        const removedItem = newArray.splice(currentIndex, 1)[0];
        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
        newArray.splice(newIndex, 0, removedItem);
        setoffersList([...newArray]);
        updateStoreOffers([...newArray], storeOffers);
    };

    useEffect(() => {
        setoffersList(offers)
    }, [offers])

    const updateStoreOffers = async (offers: Array<Offer> | any, storeOffers: { updateAll: (arg: any) => any }) => {
        if (!!offers?.length) {
            const updatedQuestionsList = offers.map((item: Offer, index: number) => {
                return {
                    id: item.id,
                    company_id: item.company_id,
                    offer_text: item.offer_text,
                    offer_description: item.offer_description,
                    active: item.active,
                    order: index
                };
            });
            await storeOffers.updateAll(updatedQuestionsList);
        }
    };
    return (
        <Sortable
            items={offersList.map((appItem: { id: number | string }, index: number) => ({
                ...appItem,
                index,
                handleMoveInArray,
                offersList,
                id: `${appItem?.id}`,
                storeOffers,
                storeOffer: appItem,
                dispatch,
                handleEditClick,
                handleCopyClick,
                handleDeleteOffer,
                setLoadingTopics
            }))}
            Container={(props: any) => <ul className="app-order-list-container" {...props} />}
            renderItem={(props: any) => {
                return <ActiveOfferOrderListItem key={props.value.id} {...props} />;
            }}
            handle={true}
            useDragOverlay={false}
            onDragEnd={newArray => {
                setoffersList([...newArray]);
                updateStoreOffers([...newArray], storeOffers);
            }}
        />
    );
};
const ActiveOfferOrderListItem = memo(
    forwardRef<HTMLLIElement, AppOrderSortableProps>(
        (
            {
                className,
                color,
                dragOverlay,
                dragging,
                disabled,
                fadeIn,
                handle,
                height,
                index,
                listeners,
                onRemove,
                renderItem,
                sorting,
                style,
                transition,
                transform,
                value,
                wrapperStyle,
                ...props
            },
            ref
        ) => {
            useEffect(() => {
                if (!dragOverlay) {
                    return;
                }

                document.body.style.cursor = 'grabbing';

                return () => {
                    document.body.style.cursor = '';
                };
            }, [dragOverlay]);
            return (
                <li
                    className={classNames('Wrapper', className, {
                        fadeIn,
                        sorting,
                        dragOverlay
                    })}
                    style={
                        {
                            ...wrapperStyle,
                            transition,
                            '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
                            '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                            '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
                            '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
                            '--index': index,
                            '--color': color
                        } as CSSProperties
                    }
                    ref={ref}
                >
                    <AppListItem
                        key={index}
                        className={classNames('Item', {
                            dragging,
                            withHandle: handle,
                            dragOverlay,
                            disabled,
                            color
                        })}
                        style={style}
                        {...props}
                        tabIndex={!handle ? 0 : undefined}
                    >
                        <AppListItemContent
                            title={
                                <ButtonUnstyled
                                    onClick={() => value.handleEditClick(value.storeOffer)}
                                    data-test-id={'button_cm_edit_offer_' + index}
                                >
                                    {value.storeOffer?.offer_text}
                                </ButtonUnstyled>
                            }
                            description={`ID: ${value?.storeOffer?.id} - Created ${dateFormatter(
                                value?.storeOffer?.created_at || ''
                            )}`}
                        />
                        {!!value?.storeOffer?.concession_automation_enabled && <span style={{ fontSize: '14px' }}>Updates via Concession Automation</span>}
                        <AppListItemDetails />

                        <AppListItemActions position="end">
                            <IconButton
                                icon="chevronUp"
                                disabled={index === 0}
                                onClick={() => value.handleMoveInArray(value.index, 'up')}
                            />
                            <IconButton
                                icon="chevronDown"
                                disabled={index === value.offersList.length - 1}
                                onClick={() => value.handleMoveInArray(value.index, 'down')}
                            />
                            {handle && <Handle className="app-list-item-handle" {...listeners} />}

                            <ToggleOfferSwitch
                                offer={value?.storeOffer}
                                offers={value?.storeOffers}
                                index={index}
                            />
                            {/* TODO: Features for future development
                        <IconButton icon="archive" />
                        */}
                            <IconButton
                                icon="copy"
                                onClick={() => value.handleCopyClick(value.storeOffer)}
                                data-test-id={'button_cm_copy_offer_' + index}
                            />
                            <IconButton
                                icon="pencil"
                                onClick={() => value.handleEditClick(value.storeOffer)}
                                data-test-id={'button_icon_cm_edit_offer_' + index}
                            />
                            <IconButton icon="trash" onClick={() => value.handleDeleteOffer(value.storeOffer)} />
                        </AppListItemActions>
                    </AppListItem>
                </li>
            );
        }
    )
);
