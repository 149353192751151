import React, { FC } from 'react';
import { Action, ActionProps } from '../Action';
import classNames from 'classnames';

import './handle.scss';

export interface HandleActionProps extends Omit<ActionProps, 'icon'> {
    className?: string;
}

export const Handle: FC<HandleActionProps> = ({ className, ...props }) => (
    <Action
        icon="drag"
        className={classNames('dnd-action-handle', className)}
        cursor="grab"
        data-cypress="draggable-handle"
        {...props}
    />
);
