import { LeadType } from '../../types';
import { ClarityDailyLeads } from '../Clarity/Clarity.types';

export enum DateRangePreset {
    LAST7 = 'last7',
    LAST30 = 'last30',
    LAST90 = 'last90',
    YTD = 'ytd',
    CURRENT_WEEK = 'current_week',
    CURRENT_MONTH = 'current_month',
    CURRENT_QUARTER = 'current_quarter',
    PREVIOUS_WEEK = 'previous_week',
    PREVIOUS_MONTH = 'previous_month',
    PREVIOUS_QUARTER = 'previous_quarter',
    PREVIOUS_YEAR = 'previous_year',
    CUSTOM = 'custom'
}

export interface DashboardTotals {
    sessions: number;
    leads: number;
    interactions: number;
    leadsByModule: { [key in keyof typeof LeadType]: number };
    ulmEventTotals?: { [key in keyof typeof LeadType]: number };
    conversionRate: number;
}

type AnalyticsUtmSessions = { utm_source: string; source_count: number }[];

export interface AnalyticsClarityReports {
    sessionsPerSource: AnalyticsUtmSessions;
}

export interface DashboardData {
    totals: DashboardTotals;
    dailyLeads: ClarityDailyLeads[];
    clarityReports: AnalyticsClarityReports;
}

export enum HomeDashboardLoadingStatus {
    loading = 'loading',
    error = 'error',
    complete = 'complete'
}

export interface HomeDashboardReducerState {
    loadingStatus: HomeDashboardLoadingStatus;
    agencyId?: number;
    companyId?: number;
    data?: DashboardData;
}

export const dashboardApps = [
    { type: 'SG', label: 'Schedule Genie' },
    { type: 'CM', label: 'Spotlight' },
    { type: 'TU', label: 'Text Us Now' },
    { type: 'CH', label: 'Live Chat Legacy' },
    { type: 'IC', label: 'Income Calculator' },
    { type: 'CU', label: 'Call Us Now' },
    { type: 'IN', label: 'Insite' },
    { type: 'CBAI', label: 'Claire AI+' },
    { type: 'LC', label: 'Live Chat' },
];

export const ulmDashboardApps = [
    { type: 'USG', label: 'ULM Schedule Genie' },
    { type: 'UCM', label: 'ULM Spotlight' }
];

export const DashboardInitialState: HomeDashboardReducerState = {
    loadingStatus: HomeDashboardLoadingStatus.loading
};

export const HomeDashboardReducers = {
    setLoadingStatus: (state: HomeDashboardReducerState, loadingStatus: HomeDashboardLoadingStatus) => ({
        ...state,
        loadingStatus
    }),
    setPageData: (state: HomeDashboardReducerState, data: DashboardData) => {
        return { ...state, data };
    }
};

export interface HomeDashboardReducerAction {
    name: keyof typeof HomeDashboardReducers;
    payload?: any;
}

export const HomeDashboardReducer = (state: HomeDashboardReducerState, action: HomeDashboardReducerAction) => {
    if (!HomeDashboardReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: HomeDashboardReducerState = HomeDashboardReducers[action.name](state, action.payload);
    return nextState;
};

export const getArrayItemFromNumber = (n: number, array: any[]) => array[(n % array.length) - 1];

export const dashboardPieChartColors = [
    '#0054FE',
    '#20BF6B',
    '#FC5C65',
    '#FD9644',
    '#FED330',
    '#A65EEA',
    '#10B9B1',
    '#7854F6',
    '#45AAF2',
    '#EB3B5A',
    '#FA8231',
    '#1A237E',
    '#F7B731',
    '#2BCBBA',
    '#8854D0',
    '#26DE81',
    '#1E1EBE'
];
