import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { map } from 'lodash';
import { Alert, Button, Col, Collapse, Glyphicon, PageHeader, Row, Well } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { User } from '../../types/User';
import { relationFormatter } from '../../util/formatters';
import NewUserItem from './UserNew';
import { InjectedProps } from '../../types';
import DefaultRoutes from '../../routes/DefaultRoutes';

interface UserListState {
    open: boolean;
    newItem?: User;
}

@inject('store')
@observer
class UserList extends React.Component<{}, UserListState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state: UserListState = {
        open: false
    };

    public fetchData() {
        const { fetchUsers, fetchRoles } = this.injected.store;
        return fetchUsers().then(() => fetchRoles());
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public render() {
        const { hasAccess, users, roles, isAgencyRole, agencyStore } = this.injected.store;
        const { activeAgency } = agencyStore;
        const { newItem } = this.state;
        const options = {
            onRowClick: this.handleRowClick
        };
        return (
            <>
                <PageHeader>Users</PageHeader>
                <Col className="col-lg-12 holder__tible-user">
                    {newItem && (
                        <Alert bsStyle="success" onDismiss={this.handleDismissAlert}>
                            New user: "{newItem.email}", created successfully.
                        </Alert>
                    )}
                    <Row>
                        <div className="btn-toolbar">
                            <Button
                                bsStyle="primary"
                                onClick={this.toggleNewForm}
                                disabled={!hasAccess('users.create')}
                            >
                                Create User <Glyphicon glyph="plus" />
                            </Button>
                            {!isAgencyRole && !activeAgency && (
                                <Button
                                    bsStyle="primary"
                                    onClick={this.exportAllCompanyEmails}
                                    disabled={!hasAccess('users.create')}
                                >
                                    Export All Unique Emails
                                </Button>
                            )}
                            <Button
                                bsStyle="primary"
                                onClick={this.exportAllUsersData}
                                disabled={!hasAccess('users.create')}
                            >
                                Export All Users
                            </Button>
                        </div>
                    </Row>
                    <Collapse in={this.state.open}>
                        <Row>
                            <Well>
                                <NewUserItem handleClose={this.itemAdded} />
                            </Well>
                        </Row>
                    </Collapse>
                    <Row>
                        <BootstrapTable data={users.values} options={options} pagination search striped>
                            <TableHeaderColumn dataField="id" isKey width="60">
                                ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="email" dataSort>
                                Email
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="first_name" dataSort>
                                First Name
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="last_name" dataSort>
                                Last Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="role_id"
                                dataFormat={relationFormatter(roles.data, 'name')}
                                dataSort
                            >
                                Role
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="company" dataFormat={this.companyFormatter}>
                                Company (if applicable)
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Row>
                </Col>
            </>
        );
    }

    public itemAdded = (item: User) => {
        this.setState({ newItem: item });
        this.toggleNewForm();
    };

    public handleRowClick = (user: User) => {
        const { router } = this.injected.store;
        const UserEditRoute = router.params.agencyId ? DefaultRoutes.AgencyUserEdit : DefaultRoutes.UserEdit;
        router.goTo(UserEditRoute, { ...router.params, id: user.id }, this.injected.store);
    };

    public handleDismissAlert = () => {
        this.setState({ newItem: undefined });
    };

    public toggleNewForm = () => {
        this.setState({ open: !this.state.open });
    };

    public companyFormatter = (cell: any, row: any) => {
        const compaines = map(row.companies, 'name');
        if (compaines.length) {
            return (
                <div>
                    {compaines.map((name, index) => (
                        <div key={index}>{name}</div>
                    ))}
                </div>
            );
        }
        return <b>NA</b>;
    };

    public exportAllCompanyEmails = () => {
        this.getCsv();

        return true;
    };

    public exportAllUsersData = () => {
        this.getUserCsv();

        return true;
    };

    private getCsv() {
        const { Api } = this.injected.store;

        Api.getCsvAllEmails().then(
            csvFile => {
                const blob = new Blob([csvFile.data], { type: 'text/csv;charset=utf-8;' });

                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, 'company-emails');
                } else {
                    const link = document.createElement('a');
                    link.download = 'company-emails.csv';
                    link.href = URL.createObjectURL(blob);
                    link.click();
                }
            },
            error => {
                global.console.log('error -->', error);
            }
        );
    }
    private getUserCsv() {
        const { Api } = this.injected.store;
        const { activeAgency } = this.injected.store.agencyStore;

        Api.getCsvAllUsers(activeAgency?.id).then(
            csvFile => {
                const blob = new Blob([csvFile.data], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, 'company-emails');
                } else {
                    const link = document.createElement('a');
                    link.download = 'company-users.csv';
                    link.href = URL.createObjectURL(blob);
                    link.click();
                }
            },
            error => {
                global.console.log('error -->', error);
            }
        );
    }
}

export default UserList;
