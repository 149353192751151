import { FormikHelpers, FormikProps } from 'formik';
import React, { FC } from 'react';
import {
    Modal,
    ModalHeader,
    ModalActions,
    Form,
    ActionList,
    Button,
    ButtonPrimary,
    InputTextarea,
    LeadCustomerFieldGroup,
    useSnackbar
} from '../../../../components-v2/shared';
import useStore from '../../../../store/useStore';
import { ChatKeys, LeadStatus, LeadType } from '../../../../types';
import { FEATURES } from '../../../../types/Features';
import { closeModal, LiveChatReducerAction } from '../../LiveChatLegacy.helpers';
import { CreateChatLeadFormValidationSchema } from '../../LiveChatLegacy.validation';

import './create-chat-lead-modal.scss';

export interface CreateChatLeadModalProps {
    chat: ChatKeys;
    isOpen: boolean;
    dispatch: React.Dispatch<LiveChatReducerAction>;
    onAfterClose: () => void;
}

export interface ChatLead {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    comments: string;
    status: LeadStatus;
    is_agree_sms_notification: string;
}

export const CreateChatLeadModal: FC<CreateChatLeadModalProps> = ({ chat, isOpen, dispatch, onAfterClose }) => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { leads, features } = store;

    const handleSubmit = async (values: ChatLead, actions: FormikHelpers<ChatLead>) => {
        try {
            await leads.create({
                ...values,
                lead_type: LeadType.CH,
                company_id: chat.company_id,
                chat_id: chat.id
            } as any);
            addSnackbar(`Live chat lead created for "${values.first_name} ${values.last_name}".`, {
                variant: 'success'
            });
            closeModal(dispatch);
        } catch (error) {
            actions.setErrors(error.response.data);
            addSnackbar(`Failed to create live chat lead.`, { variant: 'error' });
        }
    };

    const initialValues: ChatLead = {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        status: LeadStatus.OPEN_OPPORTUNITY,
        comments: '',
        is_agree_sms_notification: ''
    };

    const enableSmsMessages = features[FEATURES.sms_messages];

    return (
        <Modal id="createChatLead" className="create-chat-lead-modal" isOpen={isOpen} onAfterClose={onAfterClose}>
            <ModalHeader title="Create Live Chat Lead" />
            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={CreateChatLeadFormValidationSchema}
            >
                {(formikProps: FormikProps<ChatLead>) => {
                    return (
                        <>
                            <LeadCustomerFieldGroup formikProps={formikProps} enableSmsMessages={enableSmsMessages} />
                            <InputTextarea name="comments" label="Comments" formikProps={formikProps} />
                            <ModalActions>
                                <ActionList position="end">
                                    <Button onClick={() => closeModal(dispatch)} disabled={formikProps.isSubmitting}>
                                        Cancel
                                    </Button>
                                    <ButtonPrimary type="submit" disabled={formikProps.isSubmitting}>
                                        {formikProps.isSubmitting ? 'Creating lead...' : 'Create Lead'}
                                    </ButtonPrimary>
                                </ActionList>
                            </ModalActions>
                        </>
                    );
                }}
            </Form>
        </Modal>
    );
};
