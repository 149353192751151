import React from 'react';
import { AppCard, AppCardHeader, DescriptionList, DescriptionListItem } from '../../../../components-v2/shared';
import { DateTime } from 'luxon';
import { Lead } from '../../../../types/Lead';

export const AppEventInsite: React.FC<{ lead: Lead }> = ({ lead }) => {
    if (!lead.insite) {
        return null;
    }

    const data = lead.insite?.data || {};

    return (
        <AppCard>
            <AppCardHeader
                icon={{ name: 'schedule', color: 'green' }}
                title="Insite Details"
                description={DateTime.fromISO(lead.created_at as string).toLocaleString(DateTime.DATETIME_SHORT)}
            />
            <DescriptionList>
                {Object.keys(data).map((key, index: number) => (
                    <DescriptionListItem key={index} label={key} value={data[key] || 'None'} />
                ))}
            </DescriptionList>
        </AppCard>
    );
};
