import React, { FC } from 'react';
import {
    AppList,
    AppListEmpty,
    IconButton,
    AppSection,
    AppSectionHeader,
    ButtonLink,
    ButtonPrimary,
    InputText,
    AppListItem,
    InputSwitch
} from '../../../../components-v2/shared';
import { FormikProps } from 'formik';
import { IncomeAdjustedRent, IncomeCalculator, IncomeCalculatorFloorPlan } from '../../../../types/IncomeCalculator';
import { ReactComponent as EmptyFloorplansIllustration } from './EmptyFloorplansIllustration.svg';

import './floorplans-list.scss';
import { cloneDeep } from 'lodash';
import classNames from 'classnames';

export interface FloorplansListProps {
    formikProps: FormikProps<IncomeCalculator>;
}

export const FloorplansList: FC<FloorplansListProps> = ({ formikProps }) => {
    const { configuration } = formikProps.values || { configuration: { floorplans: [] } };

    const addNewFloorplan = () => {
        const floorplans = configuration?.floorplans ? cloneDeep(configuration?.floorplans) : [];
        floorplans.push({ floor_plan_name: '', allow_non_restricted_income: true });
        formikProps.setFieldValue('configuration', { ...configuration, floorplans });
    }

    const removeFloorplan = (index: number) => {
        if (!configuration?.floorplans || configuration.floorplans.length < 1) {
            return;
        }
        const floorplans = cloneDeep(configuration.floorplans);
        floorplans.splice(index, 1);
        formikProps.setFieldValue('configuration', { ...configuration, floorplans });
    }

    const addIncomeRestriction = (floorplanIndex: number) => {
        if (!configuration || !configuration.ami4Person) {
            return;
        }
        if (!configuration?.floorplans || configuration.floorplans.length < 1) {
            return;
        }
        const floorplans = cloneDeep(configuration.floorplans);
        const flooplan = floorplans[floorplanIndex];
        if (!flooplan) {
            return;
        }
        if (!flooplan.income_adjusted_rents) {
            flooplan.income_adjusted_rents = [];
        }

        flooplan.income_adjusted_rents.push({ ami: 30, rent: flooplan.market_rent || 0 });
        formikProps.setFieldValue('configuration', { ...configuration, floorplans });
    }

    const removeFloorplanRestriction = (floorplanIndex: number, restrictionIndex: number) => {
        if (!configuration?.floorplans || configuration.floorplans.length < 1) {
            return;
        }
        const floorplans = cloneDeep(configuration.floorplans);
        const flooplan = floorplans[floorplanIndex];
        if (!flooplan) {
            return;
        }
        if (!flooplan.income_adjusted_rents) {
            flooplan.income_adjusted_rents = [];
        }

        if (flooplan.income_adjusted_rents.length === 0) {
            flooplan.allow_non_restricted_income = true;
        }

        flooplan.income_adjusted_rents.splice(restrictionIndex, 1);
        formikProps.setFieldValue('configuration', { ...configuration, floorplans });
    }

    const hasNoFloorplans = !configuration?.floorplans || configuration.floorplans.length === 0;

    const isNonRestrictedIncomeAllowed = (floorplan: IncomeCalculatorFloorPlan) => {
        if (!floorplan.income_adjusted_rents || floorplan.income_adjusted_rents.length === 0) {
            return true;
        }
        return floorplan.allow_non_restricted_income;
    };

    return (
        <>
            <AppSection>
                <AppSectionHeader title="Floorplans">
                    <ButtonLink hidden={hasNoFloorplans} onClick={() => addNewFloorplan()}>+ Add New Floorplan</ButtonLink>
                </AppSectionHeader>

                {hasNoFloorplans && (
                    <AppListEmpty
                        illustration={<EmptyFloorplansIllustration />}
                        title="No flooplan has been added yet!"
                        description="Add a floorplan to showcase your inventory and pricepoints"
                        action={<ButtonPrimary onClick={() => addNewFloorplan()}>Add Floorplan</ButtonPrimary>}
                    />
                )}

                {configuration?.floorplans && configuration.floorplans.length > 0 && (
                    <AppList className="floorplans-list">
                        {configuration.floorplans.map((floorplan: IncomeCalculatorFloorPlan, index: number) => {
                            return (
                                <AppListItem key={index} className={classNames('floorplans-list-item', {})}>
                                    <div className="floorplans-list-item-field"><InputText name={`configuration.floorplans[${index}].floor_plan_name`} required={true} label="Floorplan Name" formikProps={formikProps} /></div>
                                    <div className="floorplans-list-item-field"><InputText name={`configuration.floorplans[${index}].market_rent`} disabled={!isNonRestrictedIncomeAllowed(floorplan)} label="Market Rent" prefix="$" formikProps={formikProps} /></div>
                                    <div className="floorplans-list-item-field"><InputText name={`configuration.floorplans[${index}].minimum_income_ratio`} disabled={!isNonRestrictedIncomeAllowed(floorplan)} label="Income To Rent Variable" suffix="X" formikProps={formikProps} /></div>
                                    <div className="floorplans-list-item-field"><IconButton icon="trash" style={{ position: 'relative', top: -16 }} onClick={() => removeFloorplan(index)} /></div>
                                    <div className="floorplans-list-item-field"><span hidden={!floorplan.income_adjusted_rents || floorplan.income_adjusted_rents.length === 0} style={{ position: 'relative', top: -16 }}><InputSwitch
                                        label='Allow Non-Restricted Income'
                                        formikProps={formikProps}
                                        name={`configuration.floorplans[${index}].allow_non_restricted_income`}
                                    /></span></div>
                                    {floorplan.income_adjusted_rents && floorplan.income_adjusted_rents.length > 0 && floorplan.income_adjusted_rents.map((iar: IncomeAdjustedRent, iarIndex: number) => {
                                        return (
                                            <>
                                                <div className="floorplans-list-item-field" style={{ width: 90, justifySelf: 'end' }}><InputText name={`configuration.floorplans[${index}].income_adjusted_rents[${iarIndex}].ami`} required={true} label="AMI" suffix="%" formikProps={formikProps} /></div>
                                                <div className="floorplans-list-item-field"><InputText name={`configuration.floorplans[${index}].income_adjusted_rents[${iarIndex}].rent`} label="AMI Reduced Rent" prefix="$" formikProps={formikProps} /></div>
                                                <div className="floorplans-list-item-field"><InputText name={`configuration.floorplans[${index}].income_adjusted_rents[${iarIndex}].minimum_income_ratio`} label="Income To Rent Variable" suffix="X" formikProps={formikProps} /></div>
                                                <div className="floorplans-list-item-field"><IconButton icon="trash" style={{ position: 'relative', top: -16 }} onClick={() => removeFloorplanRestriction(index, iarIndex)} /></div>
                                                <div></div>
                                            </>
                                        )
                                    }
                                    )}
                                    <ButtonLink hidden={!configuration.ami4Person} onClick={() => addIncomeRestriction(index)}>+ Add Income Restriction</ButtonLink>
                                </AppListItem>
                            );
                        })}
                    </AppList>
                )}
            </AppSection>
        </>
    );
};
