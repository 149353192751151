import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import styles from './public.module.scss';
import { observer } from 'mobx-react';
import useStore from '../../store/useStore';
import { Formik, FormikProps, FormikHelpers, Form } from 'formik';
import { ButtonPrimary, InputText } from '../../components-v2/shared';
import { apiServerURL, tccLogo } from '../../constants';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { formSchemas } from '../../util/validations/formSchemas';
import { ToggleShowPassword } from './TogglePassword';
import zxcvbn from 'zxcvbn';

interface ChangePasswordFormValues {
    password: string;
    confirmPassword: string;
}

export const ChangePasswordPage = observer(() => {
    const { store, agencyStore } = useStore();
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [password, setPassword] = useState('');
    const initialPasswordInfo = zxcvbn('');
    const [suggestions, setPasswordSuggestions] = useState(initialPasswordInfo.feedback.suggestions);
    const [warning, setPasswordWarning] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const MINIMUM_PASSWORD_STRENGTH = 3;

    const initialValues = {
        password: '',
        confirmPassword: ''
    };

    const handleChange = (e: any) => {
        handlePasswordChange(e.target.value);
    }

    const handlePaste = (e: any) => {
        handlePasswordChange(e.clipboardData.getData('Text'));
    }

    const handlePasswordChange = (passwordString: string) => {
        setPassword(passwordString);
        const passInfo = zxcvbn(passwordString);
        setPasswordStrength(passInfo.score);
        setPasswordWarning(passInfo.feedback.warning);
        setPasswordSuggestions(passInfo.feedback.suggestions || []);
    }

    const handleSubmit = (values: ChangePasswordFormValues, actions: FormikHelpers<ChangePasswordFormValues>) => {
        actions.setSubmitting(true);
        store.clearErrors();
        const { router, changePassword } = store;
        return changePassword({ ...values, hash: store.router.params.hash })
            .then(() => router.goTo(DefaultRoutes.Home, {}, store))
            .finally(() => actions.setSubmitting(false));
    };

    const renderPasswordStrength = () => {
        if (password.length === 0) {
            return null;
        }
        switch (passwordStrength) {
            case 4:
                return (<span>Strong</span>);
            case 3:
                return (<span>Good</span>);
            case 2:
                return (<span>OK</span>);
            case 1:
            case 0:
            default:
                return (<span className={styles['public-password-weak']}>Weak</span>);
        }
    }

    const renderPasswordStrengthBar = () => {
        switch (passwordStrength) {
            case 4:
                return (<div className={styles['public-password-bar-strong']}></div>);
            case 3:
            case 2:
                return (<div className={styles['public-password-bar-ok']}></div>);
            case 1:
                return (<div className={styles['public-password-bar-weak']}></div>);
            case 0:
            default:
                return (<div className={styles['public-password-bar-empty']}></div>);
        }
    }

    const renderPasswordHints = () => {
        if (!warning && suggestions.length === 0) {
            return null;
        }
        // Join the various warnings and suggestions together into one string.
        const ws = `${warning}${warning ? '. ': ''}${suggestions.join('. ')}${suggestions.length ? '.' : ''}`;
        // We have to do this weird substring thing because some of the suggestions end with a period and some do not.
        return (<p>{ws.substring(ws.length - 2, ws.length) !== '..' ? ws : ws.substring(0, ws.length - 1)}</p>);
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <main className={styles['public-page']}>
            <Card className={styles['public-card']}>
                <div className={styles['public-branding']}>
                    {agencyStore.agencyCustomization?.agency_name ? (
                        agencyStore.agencyCustomization.agency_logo ? (
                            <img
                                src={`${apiServerURL}/images/${agencyStore.agencyCustomization.agency_logo}`}
                                alt="The Conversion Cloud"
                            />
                        ) : (
                                agencyStore.agencyCustomization.agency_name
                            )
                    ) : (
                            <img src={`/images/${tccLogo}`} alt="The Conversion Cloud" />
                        )}
                </div>

                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={formSchemas.changPasswordPage.changePasswordValidationSchema}
                >
                    {(formikProps: FormikProps<ChangePasswordFormValues>) => (
                        <Form className={styles['public-form']}>
                            <InputText
                                onChange={handleChange}
                                onPaste={handlePaste}
                                name="password"
                                type={showPassword ? "text" : "password"}
                                label="Password"
                                formikProps={formikProps}
                                suffix={<ToggleShowPassword showHide={showPassword} handleClick={toggleShowPassword}/>}
                            />
                            <InputText
                                name="confirmPassword"
                                type="password"
                                label="Confirm Password"
                                formikProps={formikProps}
                            />
                            <div className={styles['public-password-strength-indicator']}><span className={styles['public-password-strength-label']}>Password Strength</span>{renderPasswordStrength()}</div>
                            <div className={styles['public-password-strength-bar']}>
                                {renderPasswordStrengthBar()}
                            </div>
                            {renderPasswordHints()}
                            {store.errors['401'] ? (
                                <div className={styles['public-form-error']}>{store.errors['401']}</div>
                            ) : null}
                            <ButtonPrimary type="submit" className="full-width" disabled={formikProps.isSubmitting || passwordStrength < MINIMUM_PASSWORD_STRENGTH}>
                                {formikProps.isSubmitting ? 'Updating...' : 'Update Password'}
                            </ButtonPrimary>
                        </Form>
                    )}
                </Formik>
            </Card>
        </main>
    );
});
