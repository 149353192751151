import React, { FC, useReducer } from 'react';
import { observer } from 'mobx-react';
import { Tabs, useAsyncEffect } from '@lambdacurry/component-library';

import { AppHeader } from '../../components-v2/shared';
import { ReactComponent as ClarityLogo } from './ClarityLogo.svg';
import {
    DashboardInitialState,
    ClarityReducer,
    formatClarityDateRange,
    getClarityTabIndexFromName
} from './Clarity.helpers';
import { ClarityLoadingStatus, ClarityTabName } from './Clarity.types';

import './clarity-dashboard.scss';
import { ClarityDashboardViewerTab } from './ClarityDashboardViewerTab';

export const ClarityDashboardViewer: FC<{}> = observer(() => {
    const [state, dispatch] = useReducer(ClarityReducer, {
        ...DashboardInitialState,
        activeTab: ClarityTabName.default
    });
    const { filterExplanation } = state;

    const setClaritySettings = async () => {
        const preferences = JSON.parse(localStorage.staticClaritySettings);
        preferences.config.displayMode = 'print';
        dispatch({ name: 'setInitialSelectedCharts', payload: preferences.config.charts });
        dispatch({ name: 'setFilterExplanation', payload: preferences.filter_explanation });
        dispatch({ name: 'setSelectedCharts', payload: preferences.config.charts });
        dispatch({ name: 'setPreferences', payload: preferences });
        dispatch({ name: 'setPageData', payload: { ...preferences.analyticsData } });
        dispatch({ name: 'setLoadingStatus', payload: ClarityLoadingStatus.complete });
    };

    let activeCompany: string | undefined = undefined;

    if (filterExplanation?.lists?.length === 1) {
        activeCompany = filterExplanation?.lists[0]?.items[0]?.name;
    }

    useAsyncEffect(setClaritySettings, undefined, []);
    return (
        <>
            <div className="clarity-dashboard">
                <AppHeader>
                    <div className="clarity-logo">
                        <ClarityLogo />
                    </div>
                    {filterExplanation && (
                        <>
                            <div className="flex-spacer" />
                            <div className='clx-display-none pr:clx-display-block'>{activeCompany !== undefined ? activeCompany : ''}</div>
                            <div className="flex-spacer" />
                            <div className="clarity-dashboard-daterange clx-display-none pr:clx-display-block">
                                {formatClarityDateRange(filterExplanation.dateRange)}
                            </div>
                        </>
                    )}
                </AppHeader>

                <div className="clarity-dashboard-tabs">
                    <Tabs
                        value={getClarityTabIndexFromName(state.activeTab)}
                        tabs={[
                            {
                                label: 'Clarity Attribution',
                                render: <ClarityDashboardViewerTab state={state} />
                            }
                        ]}
                    />
                </div>
            </div>
        </>
    );
});
