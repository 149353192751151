import React, { Reducer, useReducer, useState } from 'react';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import {
    AppHeader,
    AppPage,
    IconButton,
    Form,
    AppFooter,
    ButtonPrimary,
    Button,
    AppSection,
    AppContent,
    AppSectionHeader,
    InputSelect,
    useSnackbar,
    InputSwitch
} from '../../components-v2/shared';

import useStore from '../../store/useStore';
import styles from './drip-schedule-editor.module.scss';
import { handlePromise, useAsyncEffect } from '../../util/async';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { FormikHelpers, FormikProps } from 'formik';
import { DripSchedule } from './DripScheduleEditor.types';
import { EditDripScheduleNameForm } from './EditDripScheduleNameForm';
import { DateTime } from 'luxon';
import {
    dripScheduleEditorEditScheduleReducer,
    DripScheduleEditorEditScheduleReducerAction,
    DripScheduleEditorEditScheduleReducerState
} from './DripScheduleEditor.helpers';
import { LeadNurtureAppSource } from '../EmailEditor/EmailEditorList.helpers';
import { LeadNurtureTemplate } from '../EmailEditor/EmailEditor.types';
import { EditDripEntryRowForm } from './EditDripEntryRowForm';
import classNames from 'classnames';

export interface DripScheduleFormEntry {
    id?: number;
    drip_schedule_id: number;
    active: boolean;
    hours_from_lead_event: number;
    allow_catchup?: boolean;
    send_via_email: boolean;
    send_via_sms: boolean;
    lead_nurture_template_id: number;
    deleted_at?: DateTime;
}

export interface EditDripScheduleForm {
    entries: DripScheduleFormEntry[];
    lead_nurture_template_selector: number;
}

export const DripScheduleEditorEditSchedulePage = observer(() => {
    const { store } = useStore();
    const { router, Api } = store;
    const { addSnackbar } = useSnackbar();
    const { companyId, scheduleId } = router.params;
    const [templates, setTemplates] = useState<LeadNurtureTemplate[]>([]);

    const [state, dispatch] = useReducer<
        Reducer<DripScheduleEditorEditScheduleReducerState, DripScheduleEditorEditScheduleReducerAction>
    >(dripScheduleEditorEditScheduleReducer, {
        modal: {
            active: 'none',
            data: {}
        },
        schedule: {} as DripSchedule
    });
    const {
        isEditingName,
        schedule
        // modal
    } = state;

    const fetchSchedule = async () => {
        const [response, err] = await handlePromise<{ data: DripSchedule }>(
            Api.client.get(`company/${companyId}/drip-schedules/${scheduleId}`)
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setDripSchedule', payload: response.data });
    };

    const fetchTemplates = async () => {
        const [response, err] = await handlePromise<{ count: number; data: { data: LeadNurtureTemplate[] } }>(
            Api.client.get(`company/${companyId}/lead-nurture-templates`)
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }

        setTemplates(response.data.data);
    };

    const DripEntryFormValidationSchema = Yup.object({
        entries: Yup.array().of(
            Yup.object({
                active: Yup.boolean().required(),
                lead_nurture_template_id: Yup.number().required('Please select an email template.'),
                send_via_email: Yup.boolean().when('send_via_sms', {
                    is: false,
                    then: Yup.boolean()
                        .oneOf([true])
                        .required('Must send via email if SMS not selected')
                })
            })
        )
    });

    const fetchData = async () => {
        await fetchSchedule();
        await fetchTemplates();
    };

    useAsyncEffect(fetchData);

    const handleEditScheduleName: (
        values: { name: string },
        formikHelpers: FormikHelpers<{ name: string }>
    ) => void = async values => {
        const { usedBy, company_id, ...updatedSchedule } = schedule;
        const [response, err] = await handlePromise<{ data: DripSchedule }>(
            Api.client.patch(`company/${companyId}/drip-schedules`, { ...updatedSchedule, name: values.name })
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setIsEditingName', payload: false });
        dispatch({ name: 'setDripSchedule', payload: response.data });
    };

    const handleActiveToggle = async (selectedDripScheduleId?: number) => {
        if (!selectedDripScheduleId) {
            return;
        }

        const [toggleDripScheduleResponse, toggleDripScheduleError] = await handlePromise<{
            data: DripSchedule;
        }>(Api.client.patch(`company/${companyId}/drip-schedules/${selectedDripScheduleId}/toggle`));

        if (toggleDripScheduleError || !toggleDripScheduleResponse) {
            addSnackbar(toggleDripScheduleError.response.data || 'Failed to toggle Drip Schedule', {
                variant: 'error'
            });
            return;
        }

        dispatch({ name: 'setDripSchedule', payload: toggleDripScheduleResponse.data });
    };

    const handleFormSubmit: (
        values: EditDripScheduleForm,
        formikHelpers: FormikHelpers<EditDripScheduleForm>
    ) => void = async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        const data = {
            ...schedule,
            entries: values.entries
        };
        const [response, err] = await handlePromise<{ data: DripSchedule }>(
            Api.client.patch(`company/${companyId}/drip-schedules`, data)
        );

        formikHelpers.setSubmitting(false);

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setDripSchedule', payload: response.data });

        formikHelpers.setFieldValue('entries', response.data.entries);
        formikHelpers.resetForm();
        addSnackbar(`Drip Schedule has been updated.`, {
            variant: 'success'
        });
    };

    const handleAddEntry = async (
        hoursFromLeadEvent: number,
        templateId: number,
        formikProps: FormikProps<EditDripScheduleForm>
    ) => {
        formikProps.setFieldValue('entries', [
            ...formikProps.values.entries,
            {
                active: false,
                hours_from_lead_event: hoursFromLeadEvent,
                send_via_email: true,
                send_via_sms: false,
                lead_nurture_template_id: templateId,
                drip_schedule_id: schedule.id
            }
        ]);
        await formikProps.submitForm();
        await fetchData();
    };

    const handleDeleteEntry = (i: number, formikProps: FormikProps<EditDripScheduleForm>) => {
        formikProps.setFieldValue(`entries[${i}].deleted_at`, DateTime.local());
    };

    const filterTemplates = (lntArray: LeadNurtureTemplate[], appSourceType: LeadNurtureAppSource) => {
        return lntArray.filter(lnt => {
            return lnt.app_source_type === appSourceType || lnt.app_source_type === LeadNurtureAppSource.customer;
        });
    };

    const friendlyAppSourceName = (appSource: LeadNurtureAppSource) => {
        switch (appSource) {
            case LeadNurtureAppSource.customer:
                return 'Customer';
            case LeadNurtureAppSource.schedule_genie:
                return 'Schedule Genie';
            case LeadNurtureAppSource.spotlight:
                return 'Spotlight';
            default:
                return '';
        }
    };

    return (
        <AppPage loading={!schedule.name}>
            <AppHeader
                title={`${!isEditingName ? schedule.name : ''}`}
                onBack={
                    !isEditingName
                        ? () => router.goTo(DefaultRoutes.DripScheduleEditorListPage, { companyId }, store)
                        : undefined
                }
            >
                {isEditingName ? (
                    <EditDripScheduleNameForm
                        name={schedule.name}
                        onSubmit={handleEditScheduleName}
                        onCancel={() => dispatch({ name: 'setIsEditingName', payload: false })}
                    />
                ) : (
                    <IconButton
                        className={styles['schedule-editor-edit-name-button']}
                        icon="pencil"
                        onClick={() => dispatch({ name: 'setIsEditingName', payload: true })}
                    />
                )}
                <div className="flex-spacer" />
                <InputSwitch
                    onClick={() => handleActiveToggle(schedule.id)}
                    checked={schedule.active}
                    label="Enabled"
                />
            </AppHeader>
            <AppSection>
                <AppSectionHeader title={`${friendlyAppSourceName(schedule.app_source_type)} Drip Schedule`} />
            </AppSection>
            <Form
                enableReinitialize
                validateOnMount
                confirmUnsavedChanges={true}
                unsavedChangesConfig={{
                    containerQuerySelectorAll: `[class*="app-header "] ~ *`
                }}
                initialValues={{ entries: schedule.entries, lead_nurture_template_selector: 0 }}
                onSubmit={handleFormSubmit}
                validationSchema={DripEntryFormValidationSchema}
            >
                {(formikProps: FormikProps<EditDripScheduleForm>) => {
                    return (
                        <>
                            {schedule.app_source_type === LeadNurtureAppSource.schedule_genie && (
                                <AppSection>
                                    <AppSectionHeader title="Drips Prior To Appointment">
                                        <InputSelect
                                            name={`lead_nurture_template_selector`}
                                            label="Add New Drip Entry"
                                            className={classNames(styles['edit-drip-schedule-template-row-select'])}
                                            optionValueKey="id"
                                            optionLabelKey="name"
                                            onChange={(evt, val) => handleAddEntry(-24, val, formikProps)}
                                            contentEditable={false}
                                            allowCreateOption={false}
                                            formikProps={formikProps}
                                            options={filterTemplates(templates, schedule.app_source_type)}
                                        />
                                    </AppSectionHeader>
                                    <AppContent>
                                        {formikProps.values.entries.filter(entry => {
                                            return entry.hours_from_lead_event < 0 && !entry.deleted_at;
                                        }).length === 0 && (
                                            <div>
                                                No drips defined. Use the "Add New Drip Entry" selector to add one.
                                            </div>
                                        )}
                                        {formikProps.values.entries.map(
                                            (entry, i) =>
                                                entry.hours_from_lead_event < 0 &&
                                                !entry.deleted_at && (
                                                    <EditDripEntryRowForm
                                                        i={i}
                                                        anchorName="Appointment"
                                                        formikProps={formikProps}
                                                        timeRange={'before'}
                                                        handleDeleteEntry={handleDeleteEntry}
                                                        templates={filterTemplates(templates, schedule.app_source_type)}
                                                    />
                                                )
                                        )}
                                    </AppContent>
                                </AppSection>
                            )}

                            <AppSection>
                                <AppSectionHeader
                                    title={`${
                                        schedule.app_source_type === LeadNurtureAppSource.schedule_genie
                                            ? 'Drips After Appointment'
                                            : 'Drips'
                                    }`}
                                >
                                    <InputSelect
                                        name={`lead_nurture_template_selector`}
                                        label="Add New Drip Entry"
                                        className={classNames(styles['edit-drip-schedule-template-row-select'])}
                                        optionValueKey="id"
                                        optionLabelKey="name"
                                        onChange={(evt, val) => handleAddEntry(72, val, formikProps)}
                                        contentEditable={false}
                                        allowCreateOption={false}
                                        formikProps={formikProps}
                                        options={filterTemplates(templates, schedule.app_source_type)}
                                    />
                                </AppSectionHeader>
                                <AppContent>
                                    {formikProps.values.entries.filter(entry => {
                                        return entry.hours_from_lead_event >= 0 && !entry.deleted_at;
                                    }).length === 0 && (
                                        <div>No drips defined. Use the "Add New Drip Entry" selector to add one.</div>
                                    )}
                                    {formikProps.values.entries.map(
                                        (entry, i) =>
                                            entry.hours_from_lead_event >= 0 &&
                                            !entry.deleted_at && (
                                                <EditDripEntryRowForm
                                                    i={i}
                                                    anchorName={
                                                        schedule.app_source_type === LeadNurtureAppSource.schedule_genie
                                                            ? 'Appointment'
                                                            : 'Conversion'
                                                    }
                                                    timeRange={'after'}
                                                    formikProps={formikProps}
                                                    handleDeleteEntry={handleDeleteEntry}
                                                    templates={filterTemplates(templates, schedule.app_source_type)}
                                                />
                                            )
                                    )}
                                </AppContent>
                            </AppSection>
                            <AppFooter className={styles['drip-schedule-editor-footer']} sticky={true}>
                                <div className="flex-spacer" />
                                {formikProps.dirty && formikProps.isValid && (
                                    <Button
                                        onClick={() => formikProps.resetForm()}
                                        className={styles['drip-schedule-editor-footer-cancel']}
                                        disabled={formikProps.isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary
                                    type="submit"
                                    disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}
                                >
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </AppFooter>
                        </>
                    );
                }}
            </Form>
        </AppPage>
    );
});
