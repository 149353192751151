import * as Yup from 'yup';

export const EditChatFormValidationSchema = () =>
    Yup.object().shape({
        title_name: Yup.string().required('Please enter a title.'),
        schedule_id: Yup.number()
            .nullable()
            .required('Please select a schedule.'),
        account_id: Yup.string().required('Please enter an account ID.'),
        website_id: Yup.string().required('Please enter a website ID.'),
        bd_id: Yup.string().required('Please enter a live chat ID.')
    });

export const CreateChatLeadFormValidationSchema = () =>
    Yup.object().shape({
        first_name: Yup.string().required('Please enter a first name.'),
        last_name: Yup.string().required('Please enter a last name.'),
        email: Yup.string().required('Please enter an email.'),
        phone_number: Yup.string().required('Please enter a phone number.'),
        status: Yup.string().required('Please select a status.')
    });
