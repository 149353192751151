import React from 'react';
import {
    AppCard,
    AppCardDivider,
    AppCardHeader,
    AppSectionHeader,
    DescriptionList,
    DescriptionListItem,
    Panel,
    ListGroup,
    ListGroupItem,
} from '../../../../components-v2/shared';
import { Icon } from '@lambdacurry/component-library';

import { DateTime } from 'luxon';
import { Lead, LeadType } from '../../../../types/Lead';
import { moneyFormatter } from '../../../../util/formatters';
import { QuestionDefinitionAdHocTag } from '../../../../types/QuestionSettings';

export const AppEventIncomeCalculator: React.FC<{ lead: Lead }> = ({ lead }) => {
    const data = lead.calculator;
    if (!data) {
        return null;
    }
    return (
        <AppCard>
            <AppCardHeader
                icon={{ name: 'incomeCalculator', color: 'green' }}
                title="Income Calculator"
                description={DateTime.fromISO(data.created_at).toLocaleString(DateTime.DATETIME_SHORT)}
            />

            <DescriptionList columns={3}>
                {data.answer1 && <DescriptionListItem label="Household Size" value={data.household_size} />}
                {data.answer2 && (
                    <DescriptionListItem label="Annual Income" value={moneyFormatter(data.annual_income)} />
                )}
                {data.answer3 && <DescriptionListItem label="Unit Type" value={data.desired_unit_type} />}
            </DescriptionList>

            {data.automated_decision ? (
                <Panel type="success">
                    <div className="color-success clx-text-align-center clx-font-size-16 clx-font-weight-700">
                        <Icon name="radioFilled" /> Qualified
                    </div>
                </Panel>
            ) : (
                <Panel type="danger">
                    <div className="color-danger clx-text-align-center clx-font-size-16 clx-font-weight-700">
                        <Icon name="error" /> Unqualified{data.automated_decision_reason && `: ${data.automated_decision_reason}`}
                    </div>
                </Panel>
            )}

            {lead.questionResponses && lead.questionResponses?.length > 0 && (
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Questions" as="Header" />
                    <DescriptionList columns={2}>
                        {lead.questionResponses.map(val => {
                            return (
                                <DescriptionListItem
                                    label={`${val.tag && val.tag !== QuestionDefinitionAdHocTag ? `${val.tag}: ` : ''}${val.text}`}
                                    value={val.response || `No Response Provided`}
                                />
                            );
                        })}
                    </DescriptionList>
                </>
            )}

            {lead.opened_from_chatbot_ai && (
                <>
                    <AppCardDivider />
                    <AppCard>
                        <AppCardHeader
                            icon={{ name: 'chat' }}
                            title="Claire AI+"
                            description={DateTime.fromISO(lead.created_at as string).toLocaleString(
                                DateTime.DATETIME_SHORT
                            )}
                        />
                        <ListGroup>
                            <ListGroupItem icon={{ name: 'deal' }} label="Lead created by">
                                {(LeadType.IC === lead.lead_type) && 'Income Calculator'}
                            </ListGroupItem>
                        </ListGroup>
                    </AppCard>
                </>
            )}
        </AppCard>
    );
};
