import React, { FunctionComponent } from 'react';
import { get as _get } from 'lodash';
import useStore from '../../../store/useStore';
import { EditEmailSectionProps } from './EditEmailSectionForm';
import { S3FileUploader } from '../../../components-v2/shared/s3-file-uploader';
import { AppSectionHeader } from '../../../components-v2/shared';

export const EditEmailBannerForm: FunctionComponent<EditEmailSectionProps> = ({
    section,
    formikProps,
    useEditAppEmailReducer,
    response
}) => {
    const [, dispatch] = useEditAppEmailReducer();
    const { store } = useStore();

    const setUploadingFile = (uploading: boolean) => dispatch({ name: 'setUploadingFile', payload: uploading });

    const setImageUrl = (url: string) => formikProps.setFieldValue(`${section.id}.imageUrl`, url);

    const handleClearImage = () => {
        setImageUrl('');
    };

    const handleCropSave = (fileUrl: string) => {
        setUploadingFile(false);
        setImageUrl(fileUrl);
    };

    const availableBannerImages = () => {
        return response.picklists?.banners || [];
    };

    const activeBorder = (url: string) => {
        if (formikProps.values[`${section.id}`].imageUrl === url) {
            return '2px solid blue';
        }
        return '';
    };

    return (
        <>
            <>
                <S3FileUploader
                    name={`${section.id}.imageFile`}
                    application="mail-template"
                    data={{ companyId: parseInt(store.router.params.companyId, 0) }}
                    formikProps={formikProps}
                    fileUploaderProps={{
                        onDrop: () => setUploadingFile(true),
                        onClearAll: () => handleClearImage(),
                        onRemoveFile: () => handleClearImage()
                    }}
                    imageCropperProps={{ onSave: handleCropSave, onCancel: () => setUploadingFile(false) }}
                />

                {availableBannerImages().length > 0 && (
                    <>
                        <AppSectionHeader title="Banner Gallery" />
                        {availableBannerImages().map(url => (
                            <img
                                src={url}
                                width={200}
                                style={{ margin: '12px', border: activeBorder(url) }}
                                onClick={() => {
                                    handleCropSave(url);
                                }}
                            />
                        ))}
                    </>
                )}
            </>
        </>
    );
};
