import React, { useReducer, useState } from 'react';
import { AppHeader, AppPage, useSnackbar } from '../../../../components-v2/shared';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import useStore from '../../../../store/useStore';
import { RealyncIntegrationSetup } from './RealyncIntegrationSetup';
import { RealyncIntegrationVideoList } from './RealyncIntegrationVideoList';
import { Menu, IconButton } from '@lambdacurry/component-library';
import { handlePromise, useAsyncEffect } from '../../../../util/async';
import './realync.scss';
import { RealyncReducer, RealyncVideosResponse } from './RealyncIntegration.helpers';

export const RealyncIntegration = () => {
    const [initialLoading, setInitialLoading] = useState(true);
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { router, api } = store;
    const { goTo, params } = router;
    const [state, dispatch] = useReducer(RealyncReducer, { properties: [] });

    const isSetup = !!state.realync?.id;

    useAsyncEffect(async () => {
        const [response, error] = await handlePromise(api.client.get(`/realync/company/${params.companyId}/settings`));
        if (!response?.data || error) {
            setInitialLoading(false);

            if (error?.status === 404 || error.toString().includes('404')) {
                return;
            }
            addSnackbar('Failed to fetch Realync data, please try again later.', { variant: 'error' });
            return;
        }

        dispatch({ name: 'setRealync', payload: response.data });
        setInitialLoading(false);
    });

    useAsyncEffect(
        async () => {
            if (!isSetup) {
                return;
            }

            if (state.realync?.realync_property_id) {
                dispatch({ name: 'setSelectedPropertyId', payload: state.realync.realync_property_id });
                const [videosResponse, videosError] = await handlePromise<{ data: RealyncVideosResponse }>(
                    api.client.get<RealyncVideosResponse>(
                        `/realync/company/${params.companyId}/property/${state.realync.realync_property_id}/videos`
                    )
                );
                if (!videosResponse?.data || videosError) {
                    addSnackbar('Failed to fetch Realync videos, please try again later.', { variant: 'error' });
                    return;
                }

                dispatch({ name: 'setVideos', payload: videosResponse.data });
            }
        },
        undefined,
        [state.realync]
    );

    const removeIntegration = async () => {
        if (!state.realync?.id) {
            return goTo(DefaultRoutes.CompanyIntegrations, { companyId: params.companyId }, store);
        }
        const [_, error] = await handlePromise<{ data: {} }>(
            api.client.delete(`/realync/company/${params.companyId}/settings/${state.realync.id}`)
        );

        if (error) {
            addSnackbar('Failed to remove Realync integration.', { variant: 'error' });
            return;
        }

        dispatch({ name: 'setRealync', payload: undefined });
        return goTo(DefaultRoutes.CompanyIntegrations, { companyId: params.companyId }, store);
    };

    if (initialLoading) {
        return <>Loading...</>;
    }

    return (
        <AppPage className="realync">
            <AppHeader
                title="Realync Integration"
                onBack={() => {
                    goTo(DefaultRoutes.CompanyIntegrations, { companyId: params.companyId }, store);
                }}
            >
                <>
                    <div className="flex-spacer" />
                    {
                        <Menu
                            align="end"
                            menuButton={<IconButton icon="settings" />}
                            menuItems={[
                                {
                                    name: 'menu-item',
                                    children: 'Remove Integration',
                                    onClick: async () => {
                                        removeIntegration();
                                    }
                                }
                            ]}
                        />
                    }
                </>
            </AppHeader>
            {!isSetup && (
                <RealyncIntegrationSetup setRealync={realync => dispatch({ name: 'setRealync', payload: realync })} />
            )}
            {isSetup && <RealyncIntegrationVideoList state={state} dispatch={dispatch} />}
        </AppPage>
    );
};
