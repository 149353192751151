import React, { Dispatch, FC, ChangeEventHandler } from 'react';
import { Button, ButtonPrimary, InputText, Modal, ModalActions, ModalHeader } from '@lambdacurry/component-library';

import { ActionList, useSnackbar } from '../../components-v2/shared';
import { ClarityReducerAction, ClarityReducerState } from './Clarity.helpers';
import { ClarityCreateReportConfig } from './Clarity.types';
import { Filters } from '../Filters/Filters.helpers';

export interface ClarityCreateReportModalProps {
    state: ClarityReducerState;
    dispatch: Dispatch<ClarityReducerAction>;
    createReport: (config: ClarityCreateReportConfig) => Promise<void>;
    filters?: Filters;
}

export const ClarityCreateReportModal: FC<ClarityCreateReportModalProps> = ({
    createReport,
    state,
    dispatch,
    filters
}) => {
    const { preferences, createReportModal } = state;
    const { addSnackbar } = useSnackbar();

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        dispatch({ name: 'setCreateReportModalReportName', payload: target.value });
    };

    const handleCancelClick = () => {
        dispatch({ name: 'setCreateReportModalOpen', payload: false });
        dispatch({ name: 'setCreateReportModalReportName', payload: undefined });
        dispatch({ name: 'setCreateReportModalReportNameError', payload: undefined });
    };

    const handleCloseModal = () => {
        dispatch({ name: 'setCreateReportModalOpen', payload: false });
    }

    const handleCreateClick = async () => {
        if (!preferences) {
            addSnackbar('There are no charts defined to export.', { variant: 'error' });
            return;
        }

        await createReport({
            name: createReportModal.reportName || '',
            filter: filters,
            config: preferences?.config
        });
    };

    return (
        <Modal
            isOpen={createReportModal.open}
            closeButton={false}
            onRequestClose={handleCloseModal}    
        >
            <ModalHeader title="Create Report" />

            <InputText
                name="reportName"
                label="Report Name"
                value={createReportModal.reportName || ''}
                onChange={handleInputChange}
                error={!!createReportModal.reportNameError}
                helperText={createReportModal.reportNameError || ''}
            />

            <ModalActions>
                <ActionList position="end">
                    <Button onClick={handleCancelClick}>Cancel</Button>
                    <ButtonPrimary onClick={handleCreateClick}>Create</ButtonPrimary>
                </ActionList>
            </ModalActions>
        </Modal>
    );
};
