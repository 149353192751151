import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { AppListLoading } from '..';

import './app-page.scss';

export const AppPage: FunctionComponent<{ className?: string; loading?: boolean }> = ({
    className,
    loading,
    children
}) => {
    return (
        <div className={classNames('app-page-wrapper', className)}>
            <main id="main-content" className={classNames('app-page', loading ? 'loading' : '')}>
                {/* TODO: add a better loading state for app content */}
                {loading ? <AppListLoading title="Loading page data" /> : children}
            </main>
        </div>
    );
};
