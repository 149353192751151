import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Glyphicon, Button, PageHeader, Panel } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { InjectedProps } from '../../types';
import { dateFormatter } from '../../util/formatters';

interface Bundle {
    id: number;
    name: string;
    owner: {
        id: number;
        first_name: string;
        last_name: string;
    };
    created_at: number;
}

interface MatchbackImportDeleteProps {
    importId: number;
}

interface MatchbackImportDeleteState {
    bundles: Bundle[];
}

@inject('store')
@observer
export class MatchbackImportDelete extends React.Component<
    MatchbackImportDeleteProps & Partial<InjectedProps>,
    MatchbackImportDeleteState
> {
    public state: MatchbackImportDeleteState = {
        bundles: []
    };

    public get injected() {
        return this.props as InjectedProps;
    }

    public componentDidMount() {
        return this.getData();
    }

    // TODO: look at converting this to componentDidUpdate before React v17
    public UNSAFE_componentWillReceiveProps() {
        return this.getData();
    }

    protected getData() {
        const { store } = this.injected;

        return store.Api.getMatchbackBundles(store.activeCompanyId)
            .then(response => response.data)
            .then(data => this.setState(data));
    }

    public render() {
        return (
            <React.Fragment>
                <PageHeader>
                    <Panel.Title toggle>
                        <h3>Matchback Import Log</h3>
                    </Panel.Title>
                </PageHeader>
                <BootstrapTable data={this.state.bundles} striped>
                    <TableHeaderColumn dataField="id" isKey width="60" dataSort>
                        ID
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="name" dataSort>
                        Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="owner"
                        dataFormat={owner => `${owner.first_name} ${owner.last_name || ''}`}
                        dataSort
                    >
                        Owner
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="created_at" dataFormat={dateFormatter} dataSort>
                        Created
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataFormat={(id: number) => (
                            <Button bsStyle="danger" onClick={() => this.handleDelete(id)}>
                                Delete <Glyphicon glyph="trash" />
                            </Button>
                        )}
                        dataSort
                    />
                </BootstrapTable>
            </React.Fragment>
        );
    }

    protected handleDelete = (id: number) => {
        const bundle = this.state.bundles.find(b => b.id === id)!;

        if (window.confirm(`Do you really want to delete imported bundle from '${bundle.name}'?`)) {
            return this.injected.store.Api.deleteMatchbackBundle(bundle.id)
                .then(response => response.data)
                .then(data => {
                    this.setState(state => ({
                        bundles: state.bundles.filter(b => b.id !== data.id)
                    }));
                });
        }

        return;
    };
}

export default MatchbackImportDelete;
