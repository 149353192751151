import { action, computed, observable, runInAction } from 'mobx';
import { RouterStore } from 'mobx-router';
import { LoginFormValues } from '../components/Login/LoginPage';
import { FILTER_METRIC_CODE, USER_PROFILE_DEFAULT_LIMIT, USER_PROFILE_DEFAULT_SKIP } from '../constants';
import {
    App,
    Agency,
    Company,
    PublicRole,
    RemoteAuth,
    RemoteCalendarData,
    SmsMessage,
    TwilioNumber,
    User,
    UserRole,
    FilterCommon,
    FiltersCommon,
    ListCommon,
    ListsCommon,
    AppointmentTypes,
    FilterAgency,
} from '../types';
import { AuditLogs } from '../types/AuditLogs';
import { Bpn } from '../types/Bpn';
import { Calendar } from '../types/Calendar';
import { SelectedAppointmentType } from '../types/SelectedAppointmentTypes';
import { CalendarDayoff } from '../types/CalendarDayoff';
import { CallUs } from '../types/CallUs';
import { Category } from '../types/Category';
import { Chat, Chatbot, ChatbotAI, ILiveChat } from '../types';
import { AccessibleCompany, FilterCompany } from '../types/Company';
import { CssPath } from '../types/CssPath';
import { AllFeatureFlag } from '../types/FeatureFlag';
import { Insite } from '../types/Insite';
import { Lead } from '../types/Lead';
import { Offer, SpotlightTemplate } from '../types/Offer';
import { Question } from '../types/Question';
import { OwnershipGroup } from '../types/ownershipGroup';
import { PropertyManagementCompanyTypes } from '../types/PropertyManagementCompany';
import { ProspectQuestion } from '../types/ProspectQuestion';
import { Regions } from '../types/Regions';
import { AvailabilitySchedule } from '../types/Schedule';
import { SmsThread } from '../types/SmsThread';
import { TUNInterceptions, TextUs } from '../types/TextUs';
import { ActiveUser } from '../types/User';
import Api from '../util/api';
import { clearSessionItem, getSessionObject, setSessionObject } from '../util/session';
import Resource from './Resource';
import { detectRoutePath } from './routePath';
import { cloneDeep, get } from 'lodash';
import { AgencyStore } from './agencyStore';
import { ApiKeyManager } from '../types/ApiKeyManager';
import { handlePromise } from '../util/async';
import Axios from 'axios';
import { Crm, CrmConfigOption } from '../types/Crm';
import { OptOutQueue } from '../types/OptOutQueue';
import { DripSchedule } from '../types/DripSchedule';

class Store {
    public agencyStore: AgencyStore;

    @observable
    public auth: {
        token: string | null;
        refreshToken: string | null;
    };
    @observable public activeUser: ActiveUser | null;
    @observable public isSidebarActive: boolean = true;
    @observable public pendingRequests = 0;
    @observable public activeUserFetched = false;
    @observable public errors: object = {};
    @observable public _activeCompanyId: number = 0;
    @observable public accessibleCompanies: AccessibleCompany[];
    @observable public filterCompanies: FilterCompany[] = [];
    @observable public filterAgencies: FilterAgency[] = [];
    @observable public remoteAuths: RemoteAuth[];
    @observable public remoteAuthKeys: Array<{ name: string; api_key: string; key_type_id: number }>;
    @observable public featureFlags: AllFeatureFlag[];
    @observable public companyApiKeys: ApiKeyManager[];
    @observable public features: { [name: string]: boolean } = {};
    @observable public crmConfigOptionList: Array<CrmConfigOption>;
    @observable public remoteCalendarData: RemoteCalendarData;
    @observable public enquireProperties: Array<{ name: string; exactPropertyName: string }>;
    @observable public sherpaLeasingCounselors: Array<{ id: string; description: string }>;
    @observable public eldermarkFormKeys: Array<{ label: string; key: string }>;
    @observable public filter: FiltersCommon;
    @observable public draft: any;
    @observable public list: ListsCommon;
    @observable public activeFeatures: object = {};
    @observable public activeFeatureList: string[];
    @observable.struct public windowDimensions: {
        width: number;
        height: number;
    };
    @observable public amountUnreadSmsMessages: number = 0;
    @observable public selectedSpotlightTemplate: SpotlightTemplate | null;

    public router: RouterStore;
    public accessibleCompaniesIndex: Record<number, number>;
    public calendars: Resource<Calendar>;
    public selectedAppointmentTypes: Resource<SelectedAppointmentType>;
    public agencies: Resource<Agency>;
    public apps: Resource<App>;
    public companies: Resource<Company>;
    public insites: Resource<Insite>;
    public users: Resource<User>;
    public roles: Resource<UserRole>;
    public publicRoles: Resource<PublicRole>;
    public cssPaths: Resource<CssPath>;
    public leads: Resource<Lead>;
    public offers: Resource<Offer>;
    public questions: Resource<Question>;
    public crms: Resource<Crm>;
    public chats: Resource<Chat>;
    public chat: Resource<Chat>;
    public chatbots: Resource<Chatbot>;
    public chatbotAI: Resource<ChatbotAI>;
    public liveChat: Resource<ILiveChat>;
    public bpn: Resource<Bpn>;
    public callUs: Resource<CallUs>;
    public textUs: Resource<TextUs>;
    public calendarsDaysoff: Resource<CalendarDayoff>;
    public incomeCalculators: Resource<any>;
    public schedules: Resource<AvailabilitySchedule>;
    public dripSchedules: Resource<DripSchedule>;
    public sms: Resource<SmsMessage>;
    public smsThreads: Resource<SmsThread>;
    public twilioNumbers: Resource<TwilioNumber>;
    public auditLogs: Resource<AuditLogs>;
    public auditCompanies: Resource<AuditLogs>;
    public auditApps: Resource<AuditLogs>;
    public appointmentTypes: Resource<AppointmentTypes>;
    public businessCategory: Resource<Category>;
    public PropertyManagementCompany: Resource<PropertyManagementCompanyTypes>;
    public prospectQuestions: Resource<ProspectQuestion>;
    public optOutQueues: Resource<OptOutQueue>;
    public ownershipGroup: Resource<OwnershipGroup>;
    public regions: Resource<Regions>;
    public forwardNumbers: Resource<any>;
    public readonly api: Api;
    public crmValidationResponse: string = '';
    public propertyManagementCompanies: any;

    constructor() {
        this.api = new Api({ store: this });
        this.agencyStore = new AgencyStore(this);
        this.auth = {
            token: localStorage.getItem('token'),
            refreshToken: localStorage.getItem('refreshToken'),
        };
        this.router = new RouterStore();
        this.agencies = new Resource(this.api, 'agencies');
        this.apps = new Resource(this.api, 'apps');
        this.bpn = new Resource(this.api, 'bpn');
        this.companies = new Resource(this.api, 'companies');
        this.publicRoles = new Resource(this.api, 'public_roles');
        this.calendars = new Resource(this.api, 'calendars');
        this.roles = new Resource(this.api, 'roles');
        this.users = new Resource(this.api, 'users');
        this.cssPaths = new Resource(this.api, 'css_paths');
        this.offers = new Resource(this.api, 'offers');
        this.leads = new Resource(this.api, 'leads');
        this.questions = new Resource(this.api, 'questions');
        this.crms = new Resource(this.api, 'crm_configs');
        this.chats = new Resource(this.api, 'chats');
        this.chat = new Resource(this.api, 'chat');
        this.chatbots = new Resource(this.api, 'chatbot');
        this.chatbotAI = new Resource(this.api, 'chatbot-ai');
        this.liveChat = new Resource(this.api, 'live-chat');
        this.callUs = new Resource(this.api, 'call_us');
        this.textUs = new Resource(this.api, 'text-us', 'text_us');
        this.smsThreads = new Resource(this.api, 'sms-threads', 'sms_threads');
        this.selectedAppointmentTypes = new Resource(this.api, 'calendar_appointment_types');
        this.calendarsDaysoff = new Resource(this.api, 'calendars_daysoff');
        this.incomeCalculators = new Resource(this.api, 'income_calculators');
        this.schedules = new Resource(this.api, 'schedules');
        this.dripSchedules = new Resource(this.api, 'drip_schedules');
        this.sms = new Resource(this.api, 'sms', 'sms_messages');
        this.twilioNumbers = new Resource(this.api, 'twilio-numbers', 'twilio_provisioned_numbers');
        this.businessCategory = new Resource(this.api, 'business_categories');
        this.appointmentTypes = new Resource(this.api, 'appointment_types');
        this.PropertyManagementCompany = new Resource(this.api, 'management_companies');
        this.prospectQuestions = new Resource(this.api, 'prospect_questions');
        this.optOutQueues = new Resource(this.api, 'opt_out_queue');
        this.ownershipGroup = new Resource(this.api, 'ownership_groups');
        this.regions = new Resource(this.api, 'regions');
        this.insites = new Resource(this.api, 'insite');
        this.forwardNumbers = new Resource(this.api, 'forward_sms_numbers');
        this.init();
    }

    public init() {
        const { pathFlag, companyId } = detectRoutePath();
        if (pathFlag) {
            this.runInQueue(this.fetchActiveUser(undefined, companyId) as Promise<void>);
        }

        // Get initial size
        this.getWindowDimensions();

        // Add window resize listener
        window.addEventListener('resize', () => {
            this.getWindowDimensions();
        });

        this.filter = {};
        this.list = {};
        this.draft = {};

        ['list', 'filter', 'draft'].forEach((v) => {
            const s = getSessionObject(v);
            if (s) {
                this[v] = s;
            }
        });
    }

    public get activeCompanyId() {
        return this.router.params?.companyId ? parseInt(this.router.params.companyId, 10) : this._activeCompanyId;
    }

    public get adminRoles() {
        return ['admin', 'super_admin'];
    }

    public get agencyRoles() {
        return ['agency_super_admin', 'agency_admin'];
    }

    public get companyRoles() {
        return ['company_light', 'company_admin'];
    }

    public isSuperAdmin() {
        return get(this, 'activeUser.role.slug') === 'super_admin';
    }

    @computed
    get activeCompany(): AccessibleCompany {
        return this.accessibleCompanies[this.accessibleCompaniesIndex[this.activeCompanyId]];
    }

    @computed
    public get isAdminRole() {
        return this.activeUser ? this.adminRoles.indexOf(this.activeUser.role.slug) > -1 : false;
    }

    @computed
    public get isSuperAdminRole() {
        return this.activeUser ? this.activeUser.role.slug === 'super_admin' : false;
    }

    @computed
    public get isAgencySuperAdminRole() {
        return this.activeUser ? this.activeUser.role.slug === 'agency_super_admin' : false;
    }

    @computed
    public get isAgencyRole() {
        return this.activeUser ? this.agencyRoles.indexOf(this.activeUser.role.slug) > -1 : false;
    }

    @computed
    public get isCompanyRole() {
        return this.activeUser ? this.companyRoles.indexOf(this.activeUser.role.slug) > -1 : false;
    }

    @computed
    public get isCompanyLightRole() {
        return this.activeUser ? this.activeUser.role.slug === 'company_light' : false;
    }

    @computed
    public get isChatRole() {
        return this.activeUser ? this.activeUser.role.slug === 'chat' : false;
    }

    @computed
    public get Api() {
        return this.api;
    }

    @computed
    public get loggedIn(): boolean {
        return !!this.auth.token;
    }

    @computed
    public get isLoading(): boolean {
        return this.pendingRequests > 0;
    }

    @computed
    public get hasLegacyWidget(): boolean {
        const publicRole = this.publicRoles.getItem(this.publicRoles.getFirstItemId());
        console.log('>>>>>', !publicRole?.beta_widget_enabled);
        return !publicRole?.beta_widget_enabled;
    }

    @action.bound
    public async calcUnreadSmsMessages() {
        this.amountUnreadSmsMessages = this.smsThreads.values.reduce((previousValue: any, thread: any) => {
            if (!thread.marked_read) {
                return ++previousValue;
            }
            return previousValue;
        }, 0);
    }

    @action.bound
    public getWindowDimensions() {
        this.windowDimensions = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }

    @action.bound
    public setActiveCompanyId(id: number) {
        this._activeCompanyId = id;
        localStorage.setItem('activeCompanyId', `${id}`);
        this.fetchPublicRoles();
    }

    @action.bound
    public deleteCompanyFromList(id: any) {
        const index = id ? this.accessibleCompaniesIndex[id] : -1;
        if (index >= 0) {
            this.accessibleCompanies.splice(index, 1);
            this.rebuildCompaniesIndex();
            if (this.activeCompanyId === parseInt(id, 10)) {
                this.setActiveCompanyId(0);
            }
            // if there are no more companies - logout
            if (!this.accessibleCompanies.length) {
                this.logout();
            }
        }
    }

    @action.bound
    public setTokens(token: string, refreshToken: string) {
        this.auth = { token, refreshToken };
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
    }

    @action.bound
    public hasAccess(request: string) {
        const user = this.activeUser;
        return user && this.loggedIn && user.role.permissions[request];
    }

    @action.bound
    public setErrors(e: object) {
        runInAction(() => {
            this.errors = e;
        });
    }

    @action.bound
    public setRemoteCalendarData(d: RemoteCalendarData) {
        runInAction(() => {
            this.remoteCalendarData = d;
        });
    }

    @action.bound
    public clearErrors() {
        this.errors = {};
    }

    @action.bound
    public toggleSitebar() {
        this.isSidebarActive = !this.isSidebarActive;
    }

    @action.bound
    public login(args: LoginFormValues) {
        this.pendingRequests = 0;
        return this.runInQueue(this.api.login(args).then(this.authenticate));
    }

    @action.bound
    public authenticate(response: { data: { token: string; refresh_token: string } }) {
        if (!response) {
            return;
        }

        const { token, refresh_token } = response.data;
        this.setTokens(token, refresh_token);
        this.setActiveUserData(response.data as any);
        this.api.setAuth({ token, refreshToken: refresh_token });
    }

    @action.bound
    public changePassword(args: { password: string; confirmPassword: string; hash: string }) {
        this.pendingRequests = 0;
        return this.runInQueue(
            this.api
                .changePassword({ pass: args.password, confirm_pass: args.confirmPassword, hash: args.hash })
                .then((response) =>
                    runInAction(() => {
                        this.auth = {
                            token: response.data.token,
                            refreshToken: response.data.refresh_token,
                        };
                        this.setActiveUserData(response.data);
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('refreshToken', response.data.refresh_token);
                    })
                )
        );
    }

    @action.bound
    public logout() {
        const userEmail = localStorage.getItem('rememberEmail');
        const staticClaritySettings = localStorage.getItem('staticClaritySettings');
        localStorage.clear();
        if (userEmail) {
            localStorage.setItem('rememberEmail', userEmail);
        }
        if (staticClaritySettings) {
            localStorage.setItem('staticClaritySettings', staticClaritySettings);
        }
        this.clearAllSessionSetting();
        this.setActiveCompanyId(0);
        this.accessibleCompanies = [];
        this.accessibleCompaniesIndex = [];
        this.activeUserFetched = false;

        if (this.activeUser) {
            this.api.unsubscribe(this.activeUser.id);
        }

        if (this.agencyStore.activeAgencyId) {
            this.agencyStore.fetchAgencyCustomizationByHostname();
        }

        this.activeUser = null;
        this.auth = { token: '', refreshToken: '' };
        this.api.logout();
        this.pendingRequests = 0;
    }

    @action.bound
    public fetchUsers(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.users.fetch(params));
    }

    @action.bound
    public fetchRoles(id?: number) {
        return this.runInQueue(this.roles.fetch({ id }));
    }

    @action.bound
    public fetchCompanies(id?: number, active?: boolean) {
        const params = this.getDefaultParams(id);
        params.filters.active = active;
        return this.runInQueue(this.companies.fetch(params));
    }

    @action.bound
    public refreshCompany(id: number) {
        return this.runInQueue(this.companies.refresh(id));
    }

    @action.bound
    public fetchIncomeCalculators(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.incomeCalculators.fetch(params));
    }

    @action.bound
    public fetchCalendars(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.calendars.fetch(params));
    }

    @action.bound
    public fetchBpn(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.bpn.fetch(params));
    }

    @action.bound
    public fetchApps(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.apps.fetch(params));
    }

    @action.bound
    public fetchInsites(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.insites.fetch(params));
    }

    @action.bound
    public fetchPublicRoles(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.publicRoles.fetch(params));
    }

    @action.bound
    public fetchActiveFeatures() {
        return this.api.client.get(`/company-features/${this.activeCompanyId}/app-configs`).then((result) => {
            runInAction(() => {
                this.activeFeatures = result.data;
                this.activeFeatureList = Object.keys(result.data);
            });
        });
    }

    @action.bound
    public fetchCssPaths(id?: number) {
        return this.runInQueue(this.cssPaths.fetch({ id }));
    }

    @action.bound
    public fetchDefineFields(id: number) {
        return this.Api.fetch(`insite-defined-fields/${id}`);
    }

    @action.bound
    public updateDefineFields(id: number, data: object) {
        return this.Api.client.patch(`insite-defined-fields/${id}`, data);
    }

    @action.bound
    public fetchMetricsCompaniesCount(range: { start: string; end: string }) {
        return this.fetchMetrics('companies-count', range);
    }

    @action.bound
    public fetchMetricsCalendarsCount(range: { start: string; end: string }) {
        return this.fetchMetrics('calendars-count', range);
    }

    @action.bound
    public fetchMetricsCallUsNowCount(range: { start: string; end: string }) {
        return this.fetchMetrics('call-us-now-count', range);
    }

    @action.bound
    public fetchMetricsOffersCount(range: { start: string; end: string }) {
        return this.fetchMetrics('offers-count', range);
    }

    @action.bound
    public fetchMetricsBestPriceNowCount(range: { start: string; end: string }) {
        return this.fetchMetrics('bpn-count', range);
    }

    @action.bound
    public fetchMetricsIncomeCalculatorCount(range: { start: string; end: string }) {
        return this.fetchMetrics('ic-count', range);
    }

    @action.bound
    public fetchMetricsChatCount(range: { start: string; end: string }) {
        return this.fetchMetrics('chat-count', range);
    }

    @action.bound
    public fetchMetricsTextUsNowCount(range: { start: string; end: string }) {
        return this.fetchMetrics('tun-count', range);
    }

    @action.bound
    public fetchMetricsLeadsCount(range: { start: string; end: string }) {
        return this.fetchMetrics('leads-count', range);
    }

    @action.bound
    public fetchTopNCompanies(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('top-n-companies-by-leads', range, numCompanies);
    }

    @action.bound
    public fetchBottomNCompanies(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('bottom-n-companies-by-leads', range, numCompanies);
    }

    @action.bound
    public fetchMatchbacksByCompany(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('matchbacks-count-by-company', range, numCompanies);
    }

    @action.bound
    public fetchMatchbacksByPMC(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('matchbacks-count-by-pmc', range, numCompanies);
    }

    @action.bound
    public fetchMatchbacksByWeek(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('matchbacks-count-by-week', range, numCompanies);
    }

    @action.bound
    public fetchCrms(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.crms.fetch(params));
    }

    @action.bound
    public fetchOffers(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.offers.fetch(params));
    }

    @action.bound
    public fetchChatbot(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.chatbots.fetch(params));
    }

    @action.bound
    public fetchChatbotAI(id?: number) {
        const params = this.getDefaultParams(id)

        return this.runInQueue(this.chatbotAI.fetch(params));
    }

    @action.bound
    public fetchLivechat(id?: number) {
        const params = this.getDefaultParams(id)

        return this.runInQueue(this.liveChat.fetch(params));
    }

    @action.bound
    public fetchChatbotQuestions(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.questions.fetch(params));
    }

    @action.bound
    public fetchChats(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.chats.fetch(params));
    }

    @action.bound
    public fetchChat(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.chat.fetch(params));
    }

    @action.bound
    public fetchCallUs(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.callUs.fetch(params));
    }

    @action.bound
    public fetchTextUs(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.textUs.fetch(params));
    }

    @action.bound
    public async fetchTextUsInterceptions() {
        const [getTUNInterceptionsList, getTUNError] = await handlePromise<{
            data: TUNInterceptions[];
        }>(this.api.client.get(`text-us/interceptions/${this.activeCompanyId}`));
        if (!getTUNError && getTUNInterceptionsList) {
            return (getTUNInterceptionsList.data);
        }

        return Promise.resolve([getTUNInterceptionsList, getTUNError]);
    }

    @action.bound
    public fetchCalendarDaysOff(id?: number) {
        const params = this.getDefaultParams(id);
        // Note: LC-452 we want to fetch all holidays even when in the context of an agency, but users will still only
        // be able to edit holidays if they were created by the agency - Jake
        delete params.filters.agency_id;
        return this.runInQueue(this.calendarsDaysoff.fetch(params));
    }

    @action.bound
    public async fetchSchedules() {
        const [getSchedulesResponse, getSchedulesError] = await handlePromise<{
            data: { count: number; data: AvailabilitySchedule[] };
        }>(this.api.client.get(`company/${this.activeCompanyId}/availability-schedules`));

        if (!getSchedulesError && getSchedulesResponse) {
            this.schedules.replaceAll(getSchedulesResponse.data);
        }

        return Promise.resolve([getSchedulesResponse, getSchedulesError]);
    }

    @action.bound
    public async fetchDripSchedules() {
        const [getDripSchedulesResponse, getDripSchedulesError] = await handlePromise<{
            data: { count: number; data: DripSchedule[] };
        }>(this.api.client.get(`company/${this.activeCompanyId}/drip-schedules`));

        if (!getDripSchedulesError && getDripSchedulesResponse) {
            this.dripSchedules.replaceAllDrips(getDripSchedulesResponse.data);
        }

        return Promise.resolve([getDripSchedulesResponse, getDripSchedulesError]);
    }

    @action.bound
    public fetchTwilioNumbers(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.twilioNumbers.fetch(params));
    }

    @action.bound
    public generateNewCompanyApiKey() {
        const url = `/api-key-manager/${this.activeCompanyId}`;
        return this.api.client.post(url);
    }

    @action.bound
    public fetchCrmConfigOptions(id?: number) {
        const companyId = id ? id : this.getDefaultParams().filters.company_id;

        const url = `crm_configs?company_id=${companyId}`;

        return this.runInQueue(
            this.api.client.get(url).then((response) =>
                runInAction(() => {
                    this.crmConfigOptionList = response.data.crm_configs.map((cfg: Crm) => {
                        return {
                            id: cfg.id,
                            name: `${cfg.name} [${cfg.crm_type}]`,
                            type: cfg.crm_type,
                        };
                    });
                })
            )
        );
    }

    @action.bound
    public validateCrmConfiguration(data: Crm, snack: any) {
        interface CRMValidation {
            endpoint: string;
            status: string;
            message: string;
        }
        return this.runInQueue(
            this.Api.client
                .post('crm_configs/validate', data)
                .then((response) =>
                    runInAction(() => {
                        const results: CRMValidation = response.data;

                        snack(results.message, {
                            variant: results.status,
                            persist: true,
                        });
                    })
                )
                .catch((err) =>
                    runInAction(() => {
                        snack(`FAILED: ${JSON.stringify(err.response?.data)}`, { variant: 'error', persist: true });
                    })
                )
        );
    }

    @action.bound
    public fetchOwnershipGroup(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.ownershipGroup.fetch(params));
    }

    @action.bound
    public fetchRegions(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.regions.fetch(params));
    }

    @action.bound
    public fetchPropertyManagementCompany(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.PropertyManagementCompany.fetch(params));
    }

    @action.bound
    public deleteOwnershipGroup(id: number) {
        return this.runInQueue(this.ownershipGroup.delete(id));
    }

    @action.bound
    public deleteRegions(id: number) {
        return this.runInQueue(this.regions.delete(id));
    }

    @action.bound
    public fetchSms(companyId?: number, threadId?: number) {
        const params = this.getDefaultParams(companyId);

        if (threadId) {
            params.filters.thread_id = threadId;
        }

        return this.runInQueue(this.sms.fetch(params));
    }

    @action.bound
    public sendSms(companyId: number, fromNumber: string, toNumber: string, message: string) {
        return this.Api.client.post<{ id: number }>('/sms/send', {
            company_id: companyId,
            from_number: fromNumber,
            to_number: toNumber,
            message,
        });
    }

    @action.bound
    public fetchSmsThreads(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(
            this.smsThreads.fetch(params).then(() => {
                this.calcUnreadSmsMessages();
            })
        );
    }

    @action.bound
    public fetchCategory(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.businessCategory.fetch(params));
    }

    @action.bound
    public fetchAppointmentType(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.appointmentTypes.fetch(params));
    }

    @action.bound
    public fetchSelectedAppointmentTypesForCompany(companyId: number) {
        const params = this.getDefaultParams(companyId);
        return this.runInQueue(this.selectedAppointmentTypes.fetch(params));
    }

    @action.bound
    public fetchForwardNumbers(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.forwardNumbers.fetch(params));
    }

    @action.bound
    public fetchCategoryFilter() {
        return this.api.client
            .get(
                `business_categories/filterList?agency_id=${this.agencyStore.activeAgencyId}&company_id=${this.activeCompanyId}`
            )
            .then((response) => response.data);
    }

    @action.bound
    public getAllFeatureFlags() {
        return this.api.client.get('/feature_flags/' + this.activeCompanyId);
    }

    @action.bound
    public saveAllFeatureFlags(data: AllFeatureFlag[]) {
        return this.api.client.post('/feature_flags/' + this.activeCompanyId, {
            data,
        });
    }

    @action.bound
    public getCompanyFeatures(id?: number) {
        const companyId = id ? id : this.activeCompanyId;
        return this.api.client.get(`/company-features/${companyId}`);
    }

    @action.bound
    public getCompanyFeatureSlugs(id?: number) {
        const companyId = id ? id : this.activeCompanyId;
        return this.api.client.get(`/company-features/${companyId}/slugs`);
    }

    @action.bound
    public getFeatures() {
        return this.api.client.get(`/features/`);
    }

    @action.bound
    public saveCompanyFeatures(featureSlugs: string[]) {
        return this.api.client
            .patch('/company-features/' + this.activeCompanyId, {
                feature_slugs: featureSlugs,
            })
            .then(() => {
                this.fetchFeatures();
            });
    }

    @action.bound
    public saveAppsAuditProcessed(data: {}) {
        return this.api.client.post('/auditlogs/apps/', {
            data,
        });
    }

    @action.bound
    public saveExceptions(add: string[], del: string[]) {
        return this.api.client.post('/insite-pages/exceptions/', {
            add,
            del,
        });
    }

    @action.bound
    public deleteApp(id: number) {
        return this.runInQueue(this.apps.delete(id));
    }

    @action.bound
    public deleteCompany(id: number) {
        return this.runInQueue(this.companies.delete(id)).then(() => {
            this.deleteCompanyFromList(id);
        });
    }

    @action.bound
    public deleteIncomeCalculator(id: number) {
        return this.runInQueue(this.incomeCalculators.delete(id));
    }

    @action.bound
    public deleteUser(id: number) {
        return this.runInQueue(this.users.delete(id));
    }

    @action.bound
    public deleteCompanyRole(id: number) {
        return this.runInQueue(this.publicRoles.delete(id));
    }

    @action.bound
    public deleteOffer(id: number) {
        return this.runInQueue(this.offers.delete(id));
    }

    @action.bound
    public deleteOptOutQueue(id: number) {
        return this.runInQueue(this.optOutQueues.delete(id));
    }

    @action.bound
    public processOptOutQueue(id: number) {
        const url = `/opt_out_queue/${id}/process`;
        return this.api.client.post(url);
    }

    @action.bound
    public deleteChat(id: number) {
        return this.runInQueue(this.chats.delete(id));
    }

    @action.bound
    public deleteSchedule(id: number) {
        return this.runInQueue(this.schedules.delete(id));
    }

    @action.bound
    public deleteCategory(id: number) {
        return this.runInQueue(this.businessCategory.delete(id));
    }

    @action.bound
    public deletePropertyManagementCompany(id: number) {
        return this.runInQueue(this.PropertyManagementCompany.delete(id));
    }

    @action.bound
    public deleteAppointmentType(id: number) {
        return this.runInQueue(this.appointmentTypes.delete(id));
    }

    @action.bound
    public runInQueue(operation: Promise<void>) {
        this.pushRequest();
        return operation.then(this.pullRequest).catch(this.pullRequestError);
    }

    @action.bound
    public fetchAuditUsers(queryParams?: { agency_id: number | undefined }) {
        return this.Api.client.get(`/auditlogs?agency_id=${queryParams?.agency_id}`).then((response) => {
            return (this.auditLogs = response.data);
        });
    }

    @action.bound
    public fetchAuditCompanies(queryParams?: { agency_id: number | undefined }) {
        return this.Api.client.get(`/auditlogs/companies?agency_id=${queryParams?.agency_id}`).then((response) => {
            return (this.auditCompanies = response.data);
        });
    }

    @action.bound
    public fetchAuditApps(queryParams?: { agency_id: number | undefined }) {
        return this.Api.client.get(`/auditlogs/apps?agency_id=${queryParams?.agency_id}`).then((response) => {
            return (this.auditApps = response.data);
        });
    }

    @action.bound
    public getActiveCompanies(): AccessibleCompany[] {
        if (this.activeCompanyId) {
            const accessibleCompany = this.accessibleCompanies[this.accessibleCompaniesIndex[this.activeCompanyId]];
            return [accessibleCompany];
        }
        return this.accessibleCompanies;
    }

    @action.bound
    public fetchFeatureFlags() {
        this.api.client
            .get(`feature_flags/company/${this.activeCompanyId}`)
            .then((response) => {
                runInAction(() => {
                    this.featureFlags = response.data;
                });
            })
            .catch((e) => {
                // Do nothing.
            });
    }

    @action.bound
    public fetchFeatures(companyId?: number) {
        this.getCompanyFeatures(companyId || this.activeCompanyId).then((result) => {
            runInAction(() => {
                if (result) {
                    this.features = result.data;
                }
            });
        });
    }

    @action.bound
    public fetchEnquireProperties() {
        this.api.client
            .get(`crm/enquire/${this.activeCompanyId}/properties`)
            .then((response) => {
                runInAction(() => {
                    this.enquireProperties = response.data;
                });
            })
            .catch((e) => {
                // Do nothing.
            });
    }

    @action.bound
    public fetchEldermarkFormKeys(apiKey: string, formId: string) {
        const options = {
            headers: { 'x-api-key': apiKey },
        };
        Axios.get(`https://api.activedemand.com/v1/forms/fields.json?form_id=${formId}`, options)
            .then((response) => {
                runInAction(() => {
                    this.eldermarkFormKeys = response.data;
                });
            })
            .catch((e) => {
                alert('Error fetching form keys');
            });
    }

    @action.bound
    public fetchSherpaLeasingCounselors(api_key: string, endpoint: string, company_id: string, community_id: string) {
        this.api.client
            .post(`crm/sherpa/leasing-counselors`, { company_id, community_id, api_key, endpoint })
            .then((response) => {
                runInAction(() => {
                    this.sherpaLeasingCounselors = response.data;
                });
            })
            .catch((e) => {
                // Do nothing.
            });
    }

    @action.bound
    public changeListSetting(listCode: string, list: ListCommon) {
        this.changeSessionObject('list', listCode, list);
    }

    @action.bound
    public changeFilterSetting(filterCode: string, filter: FilterCommon) {
        if (!filterCode) {
            filterCode = FILTER_METRIC_CODE;
        }

        this.changeSessionObject('filter', filterCode, filter);
    }

    private changeSessionObject(sessionCode: 'list' | 'filter' | 'draft', valueCode: string, values: any) {
        this[sessionCode][valueCode] = {
            ...this[sessionCode][valueCode],
            ...values,
        };

        Object.keys(this[sessionCode][valueCode]).forEach((k) => {
            if (
                this[sessionCode][valueCode][k] === undefined ||
                this[sessionCode][valueCode][k] === null ||
                this[sessionCode][valueCode][k] === ''
            ) {
                delete this[sessionCode][valueCode][k];
            }
        });

        setSessionObject(sessionCode, this[sessionCode]);
    }

    @action.bound
    public getListSetting(listCode: string, listDefault?: ListCommon): ListCommon {
        return this.getSessionObject('list', listCode, listDefault) as ListCommon;
    }

    @action.bound
    public getFilterSetting(filterCode: string, filterDefault?: FilterCommon): FilterCommon {
        if (!filterCode) {
            filterCode = FILTER_METRIC_CODE;
        }

        return this.getSessionObject('filter', filterCode, filterDefault) as FilterCommon;
    }

    private getSessionObject(
        sessionCode: 'draft' | 'list' | 'filter',
        valueCode: string,
        defaultValues?: FilterCommon | ListCommon
    ): FilterCommon | ListCommon {
        if (!this[sessionCode][valueCode]) {
            this[sessionCode][valueCode] = {};

            if (defaultValues) {
                this.changeSessionObject(sessionCode, valueCode, defaultValues);
            }
        }

        return this[sessionCode][valueCode];
    }

    @action.bound
    public clearFilterSetting(filterCode: string) {
        if (!filterCode) {
            filterCode = FILTER_METRIC_CODE;
        }
        this.filter[filterCode] = {};
        setSessionObject('filter', this.filter);
    }

    @action.bound
    public clearAllSessionSetting() {
        this.filter = {};
        this.list = {};
        this.draft = {};
        clearSessionItem('filter');
        clearSessionItem('list');
        clearSessionItem('draft');
    }

    @action.bound
    public fetchClarityReportRequests(limit?: number, offset?: number, search?: string) {
        return this.Api.client.get(`/clarity/report-requests`, {
            params: { limit, offset, search, status: 'ready,requested' },
        });
    }

    @action.bound
    public deleteClarityReportRequest(report_guid: string) {
        return this.Api.client.delete(`/clarity/report/${report_guid}`);
    }

    @action.bound
    public fetchInsiteMetaData(
        listCode: string,
        filterCode: string,
        listDefault: ListCommon,
        filterDefault?: FilterCommon
    ) {
        const customFilterSettings = this.getInsiteMetaDataFilterSettings();

        return this.fetchPoint(
            'insite-meta-data',
            listCode,
            filterCode,
            listDefault,
            filterDefault,
            customFilterSettings
        );
    }

    public getInsiteMetaDataFilterSettings() {
        let customFilterSettings: string[] = [
            'start',
            'end',
            'business_category_id',
            'dma',
            'ownership_group_id',
            'property_management_company_id',
            'region_id',
            'search',
        ];

        if (this.activeCompanyId) {
            customFilterSettings = ['start', 'end', 'search'];
        }

        return customFilterSettings;
    }

    @action.bound
    public fetchInsitePages(
        listCode: string,
        filterCode: string,
        listDefault: ListCommon,
        filterDefault?: FilterCommon
    ) {
        let customFilterSettings: string[] = [
            'business_category_id',
            'region_id',
            'ownership_group_id',
            'dma',
            'search',
        ];

        if (this.activeCompanyId) {
            customFilterSettings = ['search'];
        }

        return this.fetchPoint('insite-pages', listCode, filterCode, listDefault, filterDefault, customFilterSettings);
    }

    @action.bound
    public fetchInsiteDefinedFields(
        listCode: string,
        filterCode: string,
        listDefault: ListCommon,
        filterDefault?: FilterCommon
    ) {
        let customFilterSettings: string[] = [
            'business_category_id',
            'region_id',
            'ownership_group_id',
            'dma',
            'search',
        ];

        if (this.activeCompanyId) {
            customFilterSettings = ['search'];
        }

        return this.fetchPoint(
            'insite-defined-fields',
            listCode,
            filterCode,
            listDefault,
            filterDefault,
            customFilterSettings
        );
    }

    @action.bound
    public createInsiteLead(id: number, companyId?: number | undefined) {
        return this.Api.client.post('insite-meta-data', {
            id,
            company_id: companyId || this.activeCompanyId,
        });
    }

    public fetchPoint(
        entryPoint: string,
        listCode: string,
        filterCode: string,
        listDefault?: ListCommon,
        filterDefault?: FilterCommon,
        filterFieldsOnly?: string[],
        extendedData?: { [key: string]: string | number }
    ) {
        const list = this.getListSetting(listCode, listDefault);
        const filter = this.getFilterSetting(filterCode, filterDefault);

        let filters: FilterCommon = {
            company_id: this.activeCompanyId ? this.activeCompanyId : filter.company_id ? filter.company_id : undefined,
            agency_id: this.agencyStore.activeAgencyId
                ? this.agencyStore.activeAgencyId
                : filter.agency_id
                    ? filter.agency_id
                    : undefined,
            ...extendedData,
        };

        if (
            !filters.company_id &&
            ['insite-meta-data', 'insite-defined-fields', 'insite-pages'].indexOf(entryPoint) >= 0
        ) {
            filters.is_companies_count = true;
        }

        let filterSettings = [
            'start',
            'end',
            'search',
            'status',
            'lead_type',
            'business_category_id',
            'dma',
            'ownership_group_id',
            'property_management_company_id',
            'region_id',
        ];

        if (filterFieldsOnly && filterFieldsOnly.length) {
            filterSettings = filterFieldsOnly;
        }

        filterSettings.forEach((k) => {
            if (filter[k]) {
                filters[k] = filter[k];
            }
        });

        return this.Api.fetch(entryPoint, {
            page: list.page ? list.page : 1,
            pageSize: list.pageSize ? list.pageSize : 0,
            order: list.order ? list.order : '',
            filters,
        });
    }

    @action.bound
    public fetchActiveUser(params: { limit?: string, skip?: string, type?: string, search?: string } = { skip: `${USER_PROFILE_DEFAULT_SKIP}`, limit: `${USER_PROFILE_DEFAULT_LIMIT}` }, getById?: string) {
        let query = new URLSearchParams(params).toString();
        return this.api.client
            .get(`users/profile${getById ? `/${getById}` : params ? `?${query}` : ''}`)
            .then((response) =>
                runInAction(() => {
                    return this.setActiveUserData(response.data);
                })
            )
            .catch((e) =>
                runInAction(() => {
                    this.logout();
                })
            );
    }

    @action.bound
    public fetchFiltersData() {
        return this.api.client
            .get(`users/profile`)
            .then((response) =>
                runInAction(() => {
                    return this.setFiltersData(response.data);
                })
            )
            .catch((e) =>
                runInAction(() => {
                    this.logout();
                })
            );
    }

    @action.bound
    public fetchUserData() {
        return this.api.client
            .get(`users/profile`)
            .then((response) =>
                runInAction(() => {
                    return (response.data);
                })
            )
            .catch((e) =>
                runInAction(() => {
                    this.logout();
                })
            );
    }

    @action.bound
    public updateAccessibleCompany(company: Company) {
        if (this.accessibleCompaniesIndex[company.id] !== undefined) {
            this.accessibleCompanies[this.accessibleCompaniesIndex[company.id]] = company;
            this.accessibleCompanies = [...this.accessibleCompanies];
        }
    }

    @action.bound
    public pushRequest() {
        this.pendingRequests++;
    }

    @action.bound
    public pullRequest() {
        if (this.pendingRequests > 0) {
            this.pendingRequests--;
        }
    }

    @action.bound
    protected pullRequestError(err?: Error) {
        this.pullRequest();
        throw err;
    }

    @action.bound
    protected setActiveUserData(data: { companies: Company[]; user: ActiveUser; agencies: Agency[], count?: { agencies: number, companies: number } }) {
        this.activeUser = data.user;
        this.activeUserFetched = true;
        this.accessibleCompanies = [];
        this.accessibleCompaniesIndex = [];

        data.companies.forEach((company: Company, index: number) => {
            if (
                data.companies.filter((dataItem) => dataItem.name.toLowerCase() === company.name.toLowerCase()).length >
                1
            ) {
                company.displayName = `${company.name} (${company.state})`;
            }

            this.accessibleCompanies.push(cloneDeep(company));
            this.accessibleCompaniesIndex[company.id] = index;
        });

        const currentActiveCompany = JSON.parse(localStorage.getItem('activeCompany')!)
        if (currentActiveCompany) {
            const companyExists = this.accessibleCompanies.find(comp => comp.id === currentActiveCompany.id)
            if (!companyExists) {
                this.accessibleCompanies.push(cloneDeep(currentActiveCompany));
                this.accessibleCompaniesIndex[currentActiveCompany.id] = this.accessibleCompanies.length - 1;
            }
        }

        if (
            data.companies.length === 1 &&
            (data.user.role.slug === 'company_admin' || data.user.role.slug === 'company_light')
        ) {
            this.activeUser.companies = data.companies;
            this.setActiveCompanyId(data.companies[0].id);
        } else {
            const activeCompany = Number(localStorage.getItem('activeCompanyId'));
            if (activeCompany && this.accessibleCompaniesIndex[activeCompany] !== undefined) {
                this.setActiveCompanyId(activeCompany);
            }
        }
        this.agencyStore.setAccessibleAgencies(data.agencies);
        return data;
    }

    @action.bound
    protected setFiltersData(data: { companies: FilterCompany[]; user: ActiveUser; agencies: Agency[], count?: { agencies: number, companies: number } }) {
        this.filterCompanies = [];
        this.filterAgencies = [];

        data.companies.forEach((company: FilterCompany) => {
            this.filterCompanies.push({
                id: company.id,
                name: company.name,
                dma: company.dma,
                active: company.active,
                is_test: company.is_test,
            });
        });

        data.agencies.forEach((agency: FilterAgency) => {
            this.filterAgencies.push({
                id: agency.id,
                name: agency.name,
            });
        });

        return data;
    }

    protected rebuildCompaniesIndex() {
        this.accessibleCompaniesIndex = [];
        this.accessibleCompanies.forEach((company: Company, index: number) => {
            this.accessibleCompaniesIndex[company.id] = index;
        });
    }

    private getDefaultParams(id?: number) {
        const filters: any = {};

        if (this.activeCompanyId) {
            filters.company_id = this.activeCompanyId;
        }

        if (this.agencyStore.activeAgencyId) {
            filters.agency_id = this.agencyStore.activeAgencyId;
        }

        return { id, filters };
    }

    private fetchMetrics(api: string, range: { start: string; end: string }, numCompanies?: number) {
        let start = '-1';
        let end = '-1';

        if (range) {
            start = range.start;
            end = range.end;
        }

        const filters: any = {
            start,
            end,
            ...(this.activeCompanyId && { company_id: this.activeCompanyId }),
        };

        if (numCompanies) {
            filters.numCompanies = numCompanies;
        }

        return this.Api.fetch(`metrics/${api}`, { filters });
    }

    @action.bound
    public deleteHoliday(id: number) {
        return this.api.client.delete(`calendars_daysoff/holiday/${id}`);
    }

    @action.bound
    public setSpotlightTemplate(template: SpotlightTemplate) {
        this.selectedSpotlightTemplate = template;
    }
}

export default Store;
