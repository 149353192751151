import { DEFAULT_TEXT } from '../constants/strings';
import { AccessibleCompany, Company } from '../types';

export const jsonFormatter = (value: object) => {
    const space = 2;
    return JSON.stringify(value, null, space);
};

export const relationFormatter = <T>(data: Record<number, T>, field: string) => (value: string | number) =>
    data[value] ? data[value][field] : DEFAULT_TEXT;

export const dateFormatter = (dateString: string) => {
    if (!dateString) {
        return DEFAULT_TEXT;
    }

    return new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(
        new Date(dateString)
    );
};

export const defaultFormatter = (cellValue: any) => {
    return cellValue || DEFAULT_TEXT;
};

export const numericFormatter = (cellValue: any) => {
    return cellValue || 0;
};

export const companyFormatter = (getCompanyNameFunc: any) => (value: number) => {
    let name = getCompanyNameFunc(value);
    if (!name) {
        name = DEFAULT_TEXT;
    }
    return name;
};

export const authTypeLookup = (getAuthTypeFunc: any) => (value: number) => {
    return getAuthTypeFunc(value);
};

export const parseDateToString = (d: Date) => {
    const valueWithoutZero = 10;
    const month = d.getMonth() + 1;
    const day = d.getDate();

    return `${d.getFullYear()}-${month < valueWithoutZero ? '0' : ''}${month}-${
        day < valueWithoutZero ? '0' : ''
    }${day}`;
};

export const numberFormatter = (number: number, minimumFractionDigits = 0) =>
    new Intl.NumberFormat('en-US', { minimumFractionDigits }).format(number);

/**
 * phoneFormatter works for all U.S. numbers.
 * If a country code is entered that does not start with a 1.
 * it will return the phone number as it was input.
 */
export const phoneFormatter = (phone: string): string => {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        // tslint:disable-next-line:no-magic-numbers
        return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return phone;
};

export const moneyFormatter = (value: number, digits: number = 0) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digits
    });

    return formatter.format(value);
};

export const checkFloatValue = (value: string | number) =>
    value
        .toString()
        .replace(/,/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const stripTags = (value: string) => value.replace(/(<([^>]+)>)/gi, '');

export const addressFromCompanyFormatter = ({
    address1,
    address2,
    city,
    state,
    zipcode
}: Company | AccessibleCompany) => `${address1}${address2 ? ' ' + address2 : ''}, ${city}, ${state} ${zipcode}`;
