import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Alert, Button, Col, PageHeader, Row } from 'react-bootstrap';
import { InjectedProps } from '../../types';
import CsvImportList from './CsvImportList';
import { downloadFile } from '../../util/file';
import ButtonSpinner from '../misc/ButtonSpinner';
import FieldFileUpload from '../misc/FieldFileUpload';

interface CsvImportState {
    imported?: number;
    file?: File;
    errorMessage: string;
    importId: number;
    list: any;
    step: number;
    bundleId: number;
    waitImported: number;
    isSaving: boolean;
}

@inject('store')
@observer
export class CsvImport extends React.Component<{}, CsvImportState> {
    private ImportStep1 = 1;
    private ImportStep2 = 2;
    private ImportStep3 = 3;

    public get injected() {
        return this.props as InjectedProps;
    }

    public state = {
        file: undefined,
        step: this.ImportStep1,
        importId: 0,
        errorMessage: '',
        list: [],
        waitImported: 0,
        imported: undefined,
        bundleId: 0,
        isSaving: false
    };

    public render() {
        return (
            <React.Fragment>
                <PageHeader>CSV Import</PageHeader>
                <Col className="col-lg-12">
                    {this.state.errorMessage ? (
                        <Alert bsStyle="danger" onDismiss={this.clearResult}>
                            {this.state.errorMessage}
                        </Alert>
                    ) : null}
                    {this.state.step === this.ImportStep2 ? (
                        <Alert bsStyle={this.state.imported === this.state.list.length - 1 ? 'success' : 'danger'}>
                            Checked: {this.state.imported} from {this.state.list.length - 1} records.
                        </Alert>
                    ) : null}
                    {this.state.step === this.ImportStep3 ? (
                        <Alert bsStyle={this.state.imported === this.state.waitImported ? 'success' : 'danger'}>
                            Imported: {this.state.imported} from {this.state.waitImported} records.
                        </Alert>
                    ) : null}
                    <Row>
                        <form>
                            <div className={`modal-crop-input group-choose-file`}>
                                <FieldFileUpload onChangeFile={this.onSelected} />
                            </div>
                            <div className="btn-toolbar">
                                {this.state.step === this.ImportStep1 ? (
                                    <ButtonSpinner isSaving={this.state.isSaving} onClick={this.handleSubmit(false)}>
                                        Upload CSV
                                    </ButtonSpinner>
                                ) : null}

                                {this.state.step === this.ImportStep2 && this.state.imported ? (
                                    <ButtonSpinner isSaving={this.state.isSaving} onClick={this.handleSubmit(true)}>
                                        Save {this.state.imported} from {this.state.list.length - 1} records
                                    </ButtonSpinner>
                                ) : (
                                        <div className="csv-none-button" />
                                    )}

                                {this.state.step === this.ImportStep3 ? (
                                    <Button bsStyle="success" type="button" onClick={this.downloadImport}>
                                        Downloaded imported companies
                                    </Button>
                                ) : null}
                            </div>
                        </form>
                    </Row>
                </Col>
                {this.state.list.length ? <CsvImportList list={this.state.list} /> : null}
            </React.Fragment>
        );
    }

    private onSelected = (file: File) => {
        this.setState({
            file,
            step: this.ImportStep1,
            list: [],
            errorMessage: '',
            imported: undefined,
            bundleId: 0,
            waitImported: 0
        });
        this.setState({ isSaving: false });
    };

    public handleSubmit = (saveResult: boolean) => () => {
        const { Api } = this.injected.store;
        this.setState({ isSaving: true });

        if (!this.state.file) {
            this.setError('Select a csv file.');
            return;
        }

        if (this.state.step === this.ImportStep2) {
            const waitImported = this.state.imported || 0;
            this.setState({ waitImported });
        }

        Api.importCsvFile(this.state.file!, saveResult)
            .then((content: any) => {
                this.setState({
                    step: saveResult ? this.ImportStep3 : this.ImportStep2,
                    list: content.data.list,
                    imported: content.data.imported,
                    bundleId: content.data.bundleId,
                    isSaving: false
                });
            })
            .catch(() => '');
    };

    private downloadImport = (e: any) => {
        const { Api } = this.injected.store;

        if (this.state.bundleId) {
            Api.downloadCsvResult(this.state.bundleId)
                .then(csvFile => downloadFile(csvFile, 'imported_companies.csv'))
                .catch(() => {
                    return;
                });
        }
    };

    private setError = (errorMessage: string) => {
        this.setState({
            errorMessage,
            file: undefined
        });
    };

    private clearResult = () => {
        this.setError('');
    };
}

export default CsvImport;
