import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import { dateTimeFormats } from '../../constants';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { InjectedProps } from '../../types';

export interface AuditState {
    auditApps: any;
    isSaving: boolean;
}

@inject('store')
@observer
export default class AppsActivation extends React.Component<{}, AuditState> {
    public isSaving: boolean;

    public myRef: React.RefObject<HTMLInputElement>;

    constructor(props: any) {
        super(props);

        this.myRef = React.createRef();

        this.state = {
            auditApps: [],
            isSaving: false
        };
    }

    public get injected() {
        return this.props as InjectedProps;
    }

    public async componentDidMount() {
        return this.fetchData();
    }

    public async fetchData() {
        const { fetchAuditApps, agencyStore } = this.injected.store;
        const { activeAgencyId } = agencyStore;
        const data = await fetchAuditApps({ agency_id: activeAgencyId });
        this.setState({ auditApps: data });
    }

    public get AppsTableOptions() {
        return {
            pagination: true,
            search: true,
            striped: true,
            exportCSV: true
        };
    }

    public boolFormatter = (cell: any, row: any): any => {
        return (
            <div className="BootstrapTableDiv">
                <label className="BootstrapTableHeader">
                    <input
                        className="BootstrapTableHeaderCheckbox"
                        type="checkbox"
                        ref={this.myRef}
                        onChange={() => this.checkCellEnable(row)}
                        checked={!!row.processed}
                    />
                    <span className="checkmark" />
                </label>
            </div>
        );
    };

    public render() {
        const columnClassNameFormat = () => {
            return 'customBootStrapTable';
        };

        const isAdminRole = this.injected.store.isAdminRole;

        return (
            <>
                <div>
                    <Col className="col-lg-10">
                        <h3 style={{ textAlign: 'center', padding: '20px' }}>Apps</h3>
                        <Row>
                            <BootstrapTable data={this.state.auditApps} {...this.AppsTableOptions}>
                                <TableHeaderColumn dataField="id" isKey hidden />
                                <TableHeaderColumn
                                    dataField="app_id"
                                    width="50"
                                    dataAlign="center"
                                    hidden
                                    columnClassName={columnClassNameFormat}
                                >
                                    ID
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="time_of_action"
                                    width="200"
                                    dataSort
                                    dataFormat={this.dateFormat}
                                >
                                    Date
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="company_name"
                                    width="200"
                                    columnClassName={columnClassNameFormat}
                                >
                                    Company Name
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="feature_name" width="150">
                                    Feature Name
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="active" width="100" dataAlign="center">
                                    Active
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="updated_by" width="150">
                                    Updated By
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="operation" width="85" hidden>
                                    Operation
                                </TableHeaderColumn>
                                {isAdminRole ? (
                                    <TableHeaderColumn
                                        dataField="processed"
                                        dataFormat={this.boolFormatter}
                                        dataAlign="center"
                                        editable={{ type: 'checkbox', options: { values: 'Y:N' } }}
                                        width="150px"
                                        dataSort
                                        columnClassName={columnClassNameFormat}
                                    >
                                        Processed
                                    </TableHeaderColumn>
                                ) : null}
                            </BootstrapTable>
                        </Row>
                    </Col>
                </div>
            </>
        );
    }
    public dateFormat = (cell: any, row: any) => {
        return moment(cell).format(dateTimeFormats.auditTime);
    };

    @action
    private checkCellEnable = (row: any) => {
        const { saveAppsAuditProcessed } = this.injected.store;

        this.setState({
            isSaving: true,
            auditApps: this.state.auditApps.map((obj: any) => {
                if (obj.id === row.id) {
                    obj.processed = !row.processed;
                }

                return obj;
            })
        });

        const body: any = {
            id: row.id,
            processed: row.processed
        };

        saveAppsAuditProcessed(body)
            .then(() => this.setState({ isSaving: false }))
            .catch(() => this.setState({ isSaving: false }));

        return row;
    };
}
