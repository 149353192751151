import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    ButtonOutlinePrimary,
    useSnackbar
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, RulesActionFactory, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
import { RuleAction } from '../../../../types';
export interface RentCafeFormV2Props {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface RentCafeCrmV2 extends Crm {
    configuration: {
        property_code: string;
        source_name: string;
        property_id: string;
        company_code: string;
        company_api_key: string;
    };
}

const ruleActions: Array<RuleAction> = [
    RulesActionFactory.ReplaceStringAction('source_name', 'Replace Source Name with new value')
];

export const RentCafeFormV2: FC<RentCafeFormV2Props> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;
    const { addSnackbar } = useSnackbar();

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<RentCafeCrmV2>) => {
        await onValidate(formikProps.values, crmConfigTypes.rentcafe_v2);
    };

    const handleSubmit = async (values: RentCafeCrmV2, actions: FormikHelpers<RentCafeCrmV2>) => {
        const { property_code, property_id } = values.configuration;
        if (!property_code && !property_id) {
            addSnackbar(`Either property_code or property_id is required.`, { variant: 'error' });
            return
        }
        await onSubmit(values, actions, crmConfigTypes.rentcafe_v2);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<RentCafeCrmV2>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: RENTCaféV2" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader title="Lead Settings" className="leadContent" />
                                <InputText
                                    name="configuration.source_name"
                                    label="Source Name"
                                    helperText="RENTCafé Source Name"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader
                                    title="Connection Settings"
                                    subtitle="Contact RENTCafé support for your exact connection settings"
                                />
                                <InputText
                                    name="configuration.company_code"
                                    label="Company Code"
                                    helperText="RENTCafé Company Code"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.company_api_key"
                                    label="Company API Key"
                                    helperText="RENTCafé Company API Key"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.property_id"
                                    label="Property ID"
                                    helperText="RENTCafé Property ID"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.property_code"
                                    label="Property Code"
                                    helperText="RENTCafé Property Code (optional if providing Property ID)"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
