import { Form, FormikProps } from 'formik';
import * as React from 'react';
import { Panel } from 'react-bootstrap';
import { FieldGroup } from '../misc/FieldGroup';

export interface IAppointmentType {
    id?: number;
    name: string;
    slug: string;
}

export const labels = {
    name: 'Name',
    slug: 'Slug'
};

interface ParentProps {
    controls: (bag: FormikProps<IAppointmentType>) => React.ReactNode;
    editing: boolean;
}

export default (props: ParentProps) => {
    return (bag: FormikProps<IAppointmentType>) => {
        const fieldProps = {
            ...bag,
            labels,
            disabled: !props.editing,
            className: 'col-sm-6'
        };
        return (
            <Form className="form-holder-block">
                <Panel bsStyle="primary" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle>
                            <h4>Schedule Genie Appointment Type Details</h4>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible className="none-indent">
                        <div className="indent-outside">
                            <FieldGroup id="name" {...fieldProps} />
                            <FieldGroup id="slug" {...fieldProps} />
                        </div>
                    </Panel.Body>
                </Panel>
                {props.controls(bag)}
            </Form>
        );
    };
};
