import * as React from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';

export default () => {
    return (
        <>
            <PageHeader>Permission Denied</PageHeader>
            <Col className="col-lg-12">
                <Row>
                    <div className="btn-toolbar">
                        <p>You do not have permission to access to page, please refer to your administrator.</p>
                    </div>
                </Row>
            </Col>
        </>
    );
};
