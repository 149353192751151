import {
    Button,
    ButtonPrimary,
    Icon,
    InputSelect,
    Modal,
    ModalActions,
    ModalHeader,
} from '@lambdacurry/component-library';
import React, { FC, useEffect, useState } from 'react';
import { ActionList, AppCard, AppSectionHeader, Panel } from '../../../../components-v2/shared';
import {
    MAX_WIDGET_FONT_SIZE,
    MIN_WIDGET_FONT_SIZE,
    SectionProps,
    widgetFontFamliyList,
    widgetFontSizeList,
    widgetFontWeightList,
} from '../../ApplicationSettings.helpers';
import './font-section.scss';
import useStore from '../../../../store/useStore';

export const FontSection: FC<SectionProps> = ({ formikProps, formIsDisabled }) => {
    const { store } = useStore();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [fontWarningMessage, setFontWarningMessage] = useState<string>('');

    const revertToLegacy = async () => {
        try {
            formikProps.setFieldValue('contrast', 0);
            formikProps.setFieldValue('beta_widget_enabled', false);
            formikProps.setFieldValue('widget_style', 'light');
            await formikProps.validateForm();
            formikProps.submitForm(); // Triggers parent Form `handleSubmit` function
        } catch (error) {
            console.log('Error reverting to legacy settings:', error);
        }
    };

    useEffect(() => {
        if (formikProps.values.widget_font_size) {
            const fontSize = parseInt(formikProps.values.widget_font_size.replace('px', ''), 10)
            setFontWarningMessage('');
            if (fontSize < MIN_WIDGET_FONT_SIZE || fontSize > MAX_WIDGET_FONT_SIZE) {
                const message = fontSize < MIN_WIDGET_FONT_SIZE ? 'at least' : 'at max';
                setFontWarningMessage(`The selected font size does not meet accessibility standards. The recommended font size is ${message} ${fontSize < MIN_WIDGET_FONT_SIZE ? MIN_WIDGET_FONT_SIZE : MAX_WIDGET_FONT_SIZE}px.`);
            }
        }
    }, [formikProps.values.widget_font_size])

    return (
        <>
            <AppCard className="font-section">
                {!store.isCompanyLightRole && (
                    <Panel type="default" className="clx-margin-top-0">
                        You're using the updated configuration.{' '}
                        <span className="legacy-link" onClick={() => setShowModal(true)}>
                            Use legacy config instead?
                        </span>
                    </Panel>
                )}

                <AppSectionHeader title="Font Settings" />

                <InputSelect
                    label="Font Family"
                    name="widget_font"
                    optionLabelKey="label"
                    options={widgetFontFamliyList}
                    formikProps={formikProps}
                    disabled={formIsDisabled}
                    optionValueKey="value"
                />
                <div>
                    <InputSelect
                        label="Font Size"
                        name="widget_font_size"
                        options={widgetFontSizeList}
                        formikProps={formikProps}
                        disabled={formIsDisabled}
                        optionValueKey="value"
                    />
                    {fontWarningMessage && <div style={{ color: '#FF5F15', marginBottom: '10px', position: 'relative', top: '-16px' }}>{fontWarningMessage}</div>}
                </div>
                <InputSelect
                    label="Font Weight"
                    name="widget_font_weight"
                    options={widgetFontWeightList}
                    formikProps={formikProps}
                    disabled={formIsDisabled}
                    optionValueKey="label"
                />
            </AppCard>

            <Modal isOpen={showModal} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="icon color-danger" />
                            Are you sure you want to use the legacy application?
                        </>
                    }
                />
                <p>
                    Your updated application settings will be saved. You can switch back to the updated application at
                    any time. Please note: Legacy application users will be upgraded to the updated application in the
                    future.
                </p>
                <ModalActions>
                    <ActionList position="end">
                        <Button aria-label="Cancel" onClick={() => setShowModal(false)}>
                            Keep using updated config
                        </Button>
                        <ButtonPrimary aria-label="Confirm" onClick={revertToLegacy}>
                            Take me to legacy
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>
        </>
    );
};
