import React from 'react';
import {
    AppCard,
    AppCardDivider,
    AppCardHeader,
    AppSectionHeader,
    DescriptionList,
    DescriptionListItem,
    ListGroup,
    ListGroupItem
} from '../../../../components-v2/shared';
import { DateTime } from 'luxon';
import { Lead, LeadType } from '../../../../types/Lead';
import { QuestionDefinitionAdHocTag } from '../../../../types/QuestionSettings';

export const AppEventConcessionManager: React.FC<{ lead: Lead }> = ({ lead }) => {
    if (!lead.offer) {
        return null;
    }

    const getDateISOString = (date: string | Date): string => {
        if (typeof date === 'object') {
            return date.toISOString();
        }

        return date;
    };

    const getDurationStringFromObj = (durationObj: { years?: number; months?: number; days?: number }): string => {
        let duration = '';

        Object.keys(durationObj).forEach(unit => {
            const value = Math.floor(durationObj[unit]);
            unit = value > 1 ? unit : unit.slice(0, -1); // Remove 's' from end of unit name for singular version

            if (value > 0) {
                duration += `${value} ${unit}, `;
            }
        });

        duration = duration.slice(0, -2); // Remove last ', ' from string
        return duration;
    };

    const offer = lead.offer;
    const data = offer.offer;
    const startDateTime = data.active_time_start ? DateTime.fromISO(getDateISOString(data.active_time_start)) : null;
    const endDateTime = data.active_time_end ? DateTime.fromISO(getDateISOString(data.active_time_end)) : null;
    const formattedStartDate = startDateTime ? startDateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) : null;
    const formattedEndDate = endDateTime ? endDateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) : null;
    const duration =
        startDateTime && endDateTime
            ? getDurationStringFromObj(endDateTime.diff(startDateTime, ['years', 'months', 'days']).toObject())
            : null;

    return (
        <AppCard>
            <AppCardHeader
                icon={{ name: 'concessionManager', color: 'green' }}
                title="Spotlight"
                description={DateTime.fromISO(lead.created_at as string).toLocaleString(DateTime.DATETIME_SHORT)}
            />

            <ListGroup>
                <ListGroupItem icon={{ name: 'deal' }} label="Offer">
                    {data.offer_text}
                </ListGroupItem>
                <ListGroupItem icon={{ name: 'details' }} label="Offer Description">
                    {data.offer_description}
                </ListGroupItem>
                <ListGroupItem icon={{ name: 'clock' }} label="Offer Duration">
                    {startDateTime && endDateTime
                        ? `${formattedStartDate} - ${formattedEndDate} (${duration})`
                        : startDateTime && !endDateTime
                            ? `${formattedStartDate} - Present`
                            : 'Ongoing'}
                </ListGroupItem>
            </ListGroup>

            {lead.questionResponses && lead.questionResponses?.length > 0 && (
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Questions" as="Header" />
                    <DescriptionList columns={2}>
                        {lead.questionResponses.map(val => {
                            return (
                                <DescriptionListItem
                                    label={`${val.tag && val.tag !== QuestionDefinitionAdHocTag ? `${val.tag}: ` : ''}${val.text}`}
                                    value={val.response || `No Response Provided`}
                                />
                            );
                        })}
                    </DescriptionList>
                </>
            )}
            {lead.opened_from_chatbot && (
                <>
                    <AppCardDivider />
                    <AppCard>
                        <AppCardHeader
                            icon={{ name: 'chat' }}
                            title="Virtual Assistant"
                            description={DateTime.fromISO(lead.created_at as string).toLocaleString(
                                DateTime.DATETIME_SHORT
                            )}
                        />
                        <ListGroup>
                            <ListGroupItem icon={{ name: 'deal' }} label="Lead created by">
                                {lead.opened_from_chatbot_topic}
                            </ListGroupItem>
                        </ListGroup>
                        <AppCardDivider />
                        <ListGroup>
                            <ListGroupItem icon={{ name: 'deal' }} label="Virtual Assistant Interactions before lead creation">
                                {lead?.chatbot_interactions?.map((item: string, index: number) => (
                                    <div>{item}</div>
                                ))}
                            </ListGroupItem>
                        </ListGroup>
                    </AppCard>
                </>
            )}
            {lead.opened_from_chatbot_ai && (
                <>
                    <AppCardDivider />
                    <AppCard>
                        <AppCardHeader
                            icon={{ name: 'chat' }}
                            title="Claire AI+"
                            description={DateTime.fromISO(lead.created_at as string).toLocaleString(
                                DateTime.DATETIME_SHORT
                            )}
                        />
                        <ListGroup>
                            <ListGroupItem icon={{ name: 'deal' }} label="Lead created by">
                                {(LeadType.CM === lead.lead_type) && 'Concession Manager'}
                            </ListGroupItem>
                        </ListGroup>
                    </AppCard>
                </>
            )}
        </AppCard>
    );
};
