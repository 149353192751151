import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    ButtonOutlinePrimary,
    useSnackbar
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../../constants'

export interface FortressFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface FortressCrm extends Crm {
    configuration: {
        client_api_key: string;
        property_id: string;
        organization_id: string;
    };
}


export const FortressForm: FC<FortressFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;
    const { addSnackbar } = useSnackbar();

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<FortressCrm>) => {
        await onValidate(formikProps.values, crmConfigTypes.fortress);
    };

    const handleSubmit = async (values: FortressCrm, actions: FormikHelpers<FortressCrm>) => {
        const { property_id, organization_id } = values.configuration;

        if (!property_id || !organization_id) {
            addSnackbar(`Please provide values to all fields.`, { variant: 'error' });
            return
        }
        await onSubmit(values, actions, crmConfigTypes.fortress);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<FortressCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Fortress" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 64 }}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader
                                    title="Client API Key"
                                    subtitle="Contact Fortress support for your exact connection settings"
                                />
                                <InputText
                                    name="configuration.client_api_key"
                                    label="Fortress Api Key"
                                    helperText="Fortress Api Key"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.property_id"
                                    label="Property ID"
                                    helperText="Fortress Property ID"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.organization_id"
                                    label="Organization ID"
                                    helperText="Fortress Organization ID"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                        </AppSection>

                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
