import Api from '../../../util/api';
import CalendarRCA from './CalendarRCA';
import ScheduleRCA from './ScheduleRCA';

export interface RemoteCalendarAdapter {
    title: string;
    pushDescription: string;
    getExternalCalendar: () => Promise<any>;
    assignToken: (tokenId: number) => Promise<void>;
    unlinkToken: () => Promise<void>;
}

interface InternalIds {
    scheduleId: number;
    calendarId: number;
}

class RCAFactory {
    public static getAdapter = (internalApi: Api, { scheduleId, calendarId }: InternalIds) =>
        scheduleId ? new ScheduleRCA(internalApi, scheduleId) : new CalendarRCA(internalApi, calendarId);
}

export default RCAFactory;
