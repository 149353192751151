import { defaultWidgetColorTheme, defaultWidgetColorValues } from '../constants';

export function getCssVar(cssVar: string, computedStyle?: CSSStyleDeclaration) {
    const styles = computedStyle ? computedStyle : getComputedStyle(document.documentElement);
    return styles.getPropertyValue(`--${cssVar}`).replace(' ', '');
}

export function isColor(color: string) {
    const s = new Option().style;
    s.color = color;
    return s.color !== '';
}

export function getHexColor(colorStr: string) {
    if (!colorStr) {
        return '';
    }

    const a = document.createElement('div');
    a.style.color = colorStr;
    const colorArray = window.getComputedStyle(document.body.appendChild(a)).color?.match(/\d+/g);
    if (!colorArray) {
        return '';
    }
    const colors = colorArray.map(c => parseInt(c, 10));
    document.body.removeChild(a);
    return colors.length >= 3
        ? // tslint:disable-next-line: no-bitwise
        '#' + ((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2]).toString(16).substr(1)
        : '';
}

export function isHexColor(colorStr: string): boolean {
    return /^#[0-9a-fA-F]{6}$/i.test(colorStr);
}

export function isRgbColor(color: string): boolean {
    return new RegExp(['rgb', 'rgba', ','].join('|')).test(color);
}

export function stripRgb(color: string): string {
    return color.replace(/[^\d,.]/g, '');
}

export function rgbToHex(color: string) {
    if (isHexColor(color) || !isRgbColor(color)) return color;

    color = stripRgb(color);
    const colorArray = color.replace(' ', '').split(',');
    const colors = colorArray.map(c => parseInt(c, 10));

    return '#' + ((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2]).toString(16).slice(1);
}

export function hexToRGB(hex: string) {
    if (!hex || isRgbColor(hex)) {
        return hex;
    }

    let r = `0`;
    let g = `0`;
    let b = `0`;

    if (hex.length === 4) {
        // 3 digits
        r = `0x${hex[1]}${hex[1]}`;
        g = `0x${hex[2]}${hex[2]}`;
        b = `0x${hex[3]}${hex[3]}`;
    } else if (hex.length === 7) {
        // 6 digits
        r = `0x${hex[1]}${hex[2]}`;
        g = `0x${hex[3]}${hex[4]}`;
        b = `0x${hex[5]}${hex[6]}`;
    }

    return `${+r}, ${+g}, ${+b}`;
}

export function pickTextColorBasedOnBgColorSimple(
    bgColor: string,
    lightColor: string = '#ffffff',
    darkColor: string = '#000000'
) {
    if (!bgColor) {
        return '';
    }

    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
}

// Src: https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
export function luminance(r: number, g: number, b: number) {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// Src: https://www.sitepoint.com/javascript-generate-lighter-darker-color/
export function lightenDarkenColor(hex: string, lum: number = -0.1) {
    if (!hex) {
        return hex;
    }

    if (isRgbColor(hex)) {
        hex = rgbToHex(hex);
    }

    if (!isHexColor(hex)) {
        return hex;
    }

    // validate hex string
    hex = String(hex).replace(/[^0-9a-fA-F]/gi, '');

    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    // tslint:disable-next-line: one-variable-per-declaration
    let rgb = '#',
        c,
        i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }

    return rgb;
}

export function prepareColorValue(values: any, colorKey: string) {
    let color = values[colorKey] as string;

    if (color) {
        if (color.indexOf('#') > -1) {
            const colorHexRegexReplace = /([^0-9A-Fa-f]+)/g;
            color = '#' + color.replace(colorHexRegexReplace, '');
            if (color.length !== 7) {
                color = '';
            }
        } else {
            const colorHexRegex = /^[0-9A-Fa-f]{6}$/;
            if (color.match(colorHexRegex)) {
                color = '#' + color;
            } else {
                const colorRegex = /([^A-Za-z]+)/g;
                color = color.replace(colorRegex, '');

                if (color.match(colorHexRegex)) {
                    color = '#' + color;
                }
            }
        }
    }

    if (!color) {
        color = defaultWidgetColorValues[defaultWidgetColorTheme][colorKey];
    }

    return color;
}

export function getColorValues(values: any) {
    return {
        color_default: prepareColorValue(values, 'color_default'),
        color_white: prepareColorValue(values, 'color_default'),
        color_background_default: prepareColorValue(values, 'color_background_default'),
        color_background_icon_small: prepareColorValue(values, 'color_background_icon_small'),
        color_error: prepareColorValue(values, 'color_error'),
        color_error_field: prepareColorValue(values, 'color_error_field'),
        color_border_show_hide_button: prepareColorValue(values, 'color_border_show_hide_button'),
        color_button: prepareColorValue(values, 'color_button'),
        color_month: prepareColorValue(values, 'color_month'),
        color_back_month: prepareColorValue(values, 'color_back_month'),
        color_calendar_day: prepareColorValue(values, 'color_calendar_day'),
        color_calendar_background: prepareColorValue(values, 'color_calendar_background'),
        color_calendar_past_day: prepareColorValue(values, 'color_calendar_past_day'),
        color_back_calendar_past_day: prepareColorValue(values, 'color_back_calendar_past_day'),
        color_back_calendar_day_hover: prepareColorValue(values, 'color_back_calendar_day_hover'),
        color_background_submit_button: prepareColorValue(values, 'color_background_submit_button'),
        color_available_times_item: prepareColorValue(values, 'color_available_times_item'),
        color_background_available_times_item: prepareColorValue(values, 'color_background_available_times_item'),
        color_background_available_times_item_hover: prepareColorValue(
            values,
            'color_background_available_times_item_hover'
        ),
        color_border_focus: prepareColorValue(values, 'color_border_focus'),
        color_background_chevron: prepareColorValue(values, 'color_background_chevron')
    };
}
