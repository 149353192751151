import React, { FC, useEffect, useState } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputSelect,
    ButtonOutlinePrimary,
    useSnackbar
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { Clearfix } from 'react-bootstrap';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { RuleAction } from '../../../../types';
import { handlePromise, InputCheckbox } from '@lambdacurry/component-library';

export interface SherpaFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface SherpaCrm extends Crm {
    configuration: {
        api_key: string;
        api_endpoint: string;
        sherpa_company_id: string;
        community_id: string;
        leasing_counselor_id: string;
        create_prospect:boolean;

    };
}

const ruleActions: Array<RuleAction> = [];

export const SherpaForm: FC<SherpaFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleSubmit = async (values: SherpaCrm, actions: FormikHelpers<SherpaCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.sherpa);
    };

    const { Api } = store;
    const { addSnackbar } = useSnackbar();
    const emptyOptionList: Array<{ id: number; description: string }> = [];
    const [sherpaLeasingCounselors, setSherpaLeasingCounselors] = useState(emptyOptionList);

    const connectionSettingsComplete = (configuration: any): boolean => {
        return (
            configuration &&
            ['sherpa_company_id', 'community_id', 'api_endpoint', 'api_key'].every((attr: string) => {
                return configuration[attr] && configuration[attr] !== '';
            })
        );
    };

    const preloadLeasingCounselors = () => {
        if (connectionSettingsComplete(props.initialValues.configuration)) {
            fetchSherpaLeasingCounselors(props.initialValues.configuration);
        }
    };

    useEffect(() => {
        preloadLeasingCounselors();
    }, []);

    const handleValidate = async (formikProps: FormikProps<SherpaCrm>) => {
        await onValidate(formikProps.values, crmConfigTypes.sherpa);
    };

    const fetchSherpaLeasingCounselors = async (configuration: any) => {
        if (!connectionSettingsComplete(configuration)) {
            alert('Please complete the Connection Settings section before loading the Leasing Counselors list.');
            return;
        }
        const [response, error] = await handlePromise<{ data: any }>(
            Api.client.post(`crm/sherpa/leasing-counselors`, {
                company_id: configuration.sherpa_company_id,
                community_id: configuration.community_id,
                api_key: configuration.api_key,
                endpoint: configuration.api_endpoint
            })
        );

        if (!response?.data || response?.data.length === 0 || error) {
            addSnackbar('Failed to fetch leasing counselor data.', { variant: 'error' });
            return console.error('error: ', error);
        }

        setSherpaLeasingCounselors(response.data);

        if (
            configuration.leasing_counselor_id &&
            response.data.filter((lc: { id: number }) => {
                return lc.id === configuration.leasing_counselor_id;
            }).length === 0
        ) {
            addSnackbar('The currently configured Leasing Counselor is no longer available. Please choose another.', {
                variant: 'error'
            });
            return console.error('error: ', error);
        }
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<SherpaCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Sherpa" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader
                                    title="Connection Settings"
                                    subtitle="Contact Sherpa support for your exact connection settings"
                                />
                                <InputText
                                    name="configuration.api_endpoint"
                                    label="API Endpoint"
                                    helperText="Sherpa API Endpoint"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.api_key"
                                    label="API Key"
                                    helperText="Sherpa API Key"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.sherpa_company_id"
                                    label="Company ID"
                                    helperText="Sherpa Company ID"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 25 }}
                                />
                                <InputText
                                    name="configuration.community_id"
                                    label="Community ID"
                                    helperText="Sherpa Community ID"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 25 }}
                                />
                                <InputCheckbox
                                    name="configuration.create_prospect"
                                    label="Create Prospect"
                                    required={false}
                                    formikProps={formikProps}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader title="Lead Settings" />
                                <InputSelect
                                    label="Leasing Counselor"
                                    name="configuration.leasing_counselor_id"
                                    options={sherpaLeasingCounselors || []}
                                    optionValueKey="id"
                                    optionLabelKey="description"
                                    disabled={!sherpaLeasingCounselors}
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                    helperText="The list of leasing counselors is loaded dynamically from the Sherpa servers. Please verify your Connection Settings if the Load Leasing Counselors button fails to populate the drop-down list."
                                />
                                <Clearfix />{' '}
                                <Button
                                    onClick={() => fetchSherpaLeasingCounselors(formikProps.values.configuration)}
                                    disabled={!connectionSettingsComplete(formikProps.values.configuration)}
                                >
                                    Load Leasing Counselors
                                </Button>
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
