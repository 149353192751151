import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { FormikHelpers } from 'formik';
import {
    AppSection,
    AppFooter,
    ActionList,
    Button,
    ButtonPrimary,
    Form,
    InputText,
    InputTextarea,
    InputSelect,
    AppSectionHeader,
    InputCheckbox
} from '../../components-v2/shared';
import { InputSwitch } from '@lambdacurry/component-library';

import Resource from '../../store/Resource';
import { AvailabilitySchedule, TwilioNumber } from '../../types';
import { TextUsNowFormValidationSchema } from './TextUsNow.validation';
import { unsavedChangesContainerSelectorDefault } from '../../constants';

export interface TextUsNowFormProps {
    className?: string;
    initialValues: any;
    onSubmit: (values: any, actions: FormikHelpers<any>) => void;
    textUsId?: number;
    companyId: number;
    schedules: Resource<AvailabilitySchedule>;
    twilioNumbers: Resource<TwilioNumber>;
}

const TimeRangeList = Array.from({ length: 24 }, (_, i) => ({
    time: i.toString().padStart(2, '0') + ':00'
}));

export const TextUsNowForm: FC<TextUsNowFormProps> = observer(
    ({ initialValues, onSubmit, textUsId, companyId, schedules, twilioNumbers, ...props }) => {
        const scheduleOptions = schedules.values.map(({ id, name }) => ({
            value: id,
            label: name
        }));

        const twilioNumberOptions = twilioNumbers.values.map(({ id, phone_num_friendly }) => ({
            value: id,
            label: phone_num_friendly
        }));

        return (
            <Form
                {...props}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize
                validationSchema={TextUsNowFormValidationSchema}
                confirmUnsavedChanges
                unsavedChangesConfig={{
                    containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
                }}
            >
                {formikProps => (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="Schedule" />
                                <InputSelect
                                    name="schedule_id"
                                    label="Schedule"
                                    options={scheduleOptions}
                                    formikProps={formikProps}
                                    optionValueKey="value"
                                />

                                <AppSectionHeader title="App" />
                                <InputText
                                    name="call_to_action_text"
                                    label="Call to action"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 25 }}
                                />
                                <div className="field-group-content">
                                    <AppSectionHeader title="Message Forwarding" />
                                    <InputSwitch
                                        labelOn="Enabled"
                                        labelOff="Disabled"
                                        labelPlacement="start"
                                        formikProps={formikProps}
                                        name={'is_forward_sms_enable'}
                                        checked={formikProps.values.is_forward_sms_enable}
                                        onChange={() => {
                                            formikProps.setFieldValue('is_forward_sms_enable', !formikProps.values.is_forward_sms_enable);
                                        }}
                                    />
                                </div>
                                {
                                    formikProps.values.is_forward_sms_enable &&
                                    <>
                                        <InputText
                                            name="forward_phone_number"
                                            label="Forward Phone Number"
                                            formikProps={formikProps}
                                            inputProps={{ maxLength: 25 }}
                                        />

                                        <div className="field-group-content">
                                            <InputSelect
                                                label="Start Time"
                                                name="notification_start_time"
                                                optionLabelKey="time"
                                                optionValueKey="time"
                                                options={TimeRangeList}
                                                formikProps={formikProps}
                                            />

                                            <InputSelect
                                                label="End Time"
                                                name="notification_end_time"
                                                optionLabelKey="time"
                                                optionValueKey="time"
                                                options={TimeRangeList}
                                                formikProps={formikProps}
                                            />
                                        </div>
                                    </>
                                }

                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader title="Twilio SMS Number" />
                                <input type="hidden" name="twilio_id" value={twilioNumberOptions[0].value} />
                                <InputText
                                    name="twilio_phone"
                                    label="Twilio number"
                                    value={twilioNumberOptions[0].label}
                                    inputProps={{ readOnly: true }}
                                    disabled={true}
                                />
                                <InputTextarea
                                    name="auto_response_message"
                                    label="Auto response message"
                                    formikProps={formikProps}
                                />
                                <InputCheckbox
                                    name="enable_auto_response_message"
                                    label="Send auto response message (if it's set)"
                                    formikProps={formikProps}
                                />
                            </div>
                        </AppSection>

                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button
                                        onClick={() => formikProps.resetForm()}
                                        disabled={formikProps.isSubmitting}
                                        data-lc-trigger-unsaved-changes={true}
                                        data-test-id="button_cancel"
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary
                                    type="submit"
                                    disabled={(textUsId && !formikProps.dirty) || formikProps.isSubmitting}
                                    data-test-id="button_save"
                                >
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                )}
            </Form>
        );
    }
);
