import { FormikProps } from 'formik';
import * as React from 'react';
import { map } from 'lodash';
import { Button, PageHeader, Panel } from 'react-bootstrap';
import Store from '../../store';
import { RemoteCalendarData } from '../../types';

interface RemoteCalendarFormProps {
    store: Store;
    pageTitle: string;
    pushDescription: string;
    controls: (bag: FormikProps<RemoteCalendarData>) => React.ReactNode;
    goToRemoteAuthList: () => void;
    handleAssignToken: (el: any) => Promise<void>;
    handleUnlinkToken: () => Promise<void>;
    handleDisconnectCalendar: () => Promise<void>;
    handleConnectCalendar: (name: string, guid: string) => Promise<void>;
    handleSubscriptionOn: () => Promise<void>;
    handleSubscriptionOff: (guid: string) => Promise<void>;
}

export default (props: RemoteCalendarFormProps) => {
    return (bag: FormikProps<RemoteCalendarData>) => (
        <div>
            <PageHeader>Remote Calendar Configuration: {props.pageTitle}</PageHeader>
            <div hidden={bag.values.state.load_complete}>Loading...</div>
            <div hidden={!bag.values.state.load_complete}>
                <div hidden={!bag.values.state.needs_auth_key}>
                    <Panel bsStyle="primary" defaultExpanded={true}>
                        <Panel.Heading>
                            <Panel.Title toggle>
                                <h4>Remote Auth Needed</h4>
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible={false}>
                            <p>
                                Before you can configure your Conversion Cloud apps to sync with a remote calendar
                                (Microsoft Outlook or Google Calendar), you must first create a "Remote Auth" API key.
                            </p>
                            <p>
                                <button className="btn btn-primary" onClick={props.goToRemoteAuthList}>
                                    Remote Auth Config
                                </button>
                            </p>
                        </Panel.Body>
                    </Panel>
                </div>
                <div hidden={bag.values.state.needs_auth_key}>
                    <Panel bsStyle="primary" defaultExpanded={true}>
                        <Panel.Heading>
                            <Panel.Title toggle>
                                <h4>Remote Auths</h4>
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible={false} hidden={bag.values.state.needs_key_assignment}>
                            <h4>
                                Token Assigned To Calendar: {bag.values.externalAuth && bag.values.externalAuth.name} [
                                {(bag.values.externalAuth && bag.values.externalAuth.type) || `None`}]
                            </h4>
                            <Button bsStyle="default" onClick={props.handleUnlinkToken}>
                                Unlink Token
                            </Button>
                        </Panel.Body>
                        <Panel.Body
                            collapsible={false}
                            hidden={!bag.values.state.needs_key_assignment || bag.values.state.needs_auth_key}
                        >
                            <h4>Select which Remote Auth to use for the remote calendar:</h4>
                            <div>
                                {map(
                                    bag.values.availableAuthKeys,
                                    (item: { name: string; id: string; type: string }) => (
                                        <p>
                                            <Button
                                                bsStyle="default"
                                                value={item.id}
                                                onClick={() => props.handleAssignToken(item.id)}
                                                hidden={false}
                                            >
                                                Use This Key
                                            </Button>{' '}
                                            {item.name} [{item.type}]
                                        </p>
                                    )
                                )}
                            </div>
                            <p>
                                <button className="btn btn-primary" onClick={props.goToRemoteAuthList}>
                                    Remote Auth Config
                                </button>
                            </p>
                        </Panel.Body>
                    </Panel>
                    <div hidden={bag.values.state.needs_key_assignment}>
                        <div hidden={bag.values.remoteCalendarList && bag.values.remoteCalendarList.length > 0}>
                            <Panel bsStyle="primary" defaultExpanded={true}>
                                <Panel.Heading>
                                    <Panel.Title toggle>
                                        <h4>Admin Consent</h4>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body collapsible={false}>
                                    <h4>
                                        There are currently no calendars available for linking. This could indicate
                                        insufficient access rights.
                                    </h4>

                                    <p
                                        hidden={
                                            bag.values.externalAuth &&
                                            bag.values.externalAuth.type !== 'Microsoft Outlook'
                                        }
                                    >
                                        <p>
                                            Please provide this link to your Microsoft Azure Cloud Administrator to
                                            grant access rights your calendar integration:
                                        </p>

                                        <code>
                                            https://login.microsoftonline.com/common/adminconsent?client_id=0ddd2e44-a086-4a3b-afed-583bd6afb614&redirect_uri=
                                            {process.env.REACT_APP_API_SERVER_URL}/external/register/verify
                                        </code>
                                    </p>
                                </Panel.Body>
                            </Panel>
                        </div>

                        <div hidden={!bag.values.remoteCalendarList || bag.values.remoteCalendarList.length === 0}>
                            <Panel bsStyle="primary" defaultExpanded={true}>
                                <Panel.Heading>
                                    <Panel.Title toggle>
                                        <h4>Remote Calendars</h4>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body collapsible={false} hidden={bag.values.state.needs_calendar_assignment}>
                                    <h4>
                                        Currently Connected To Calendar:{' '}
                                        {bag.values.connected_calendar && bag.values.connected_calendar.name}
                                    </h4>
                                    <Button bsStyle="default" onClick={props.handleDisconnectCalendar}>
                                        Disconnect Calendar
                                    </Button>
                                    <h4>Remote Calendar Options</h4>
                                    <div>{props.pushDescription}</div>
                                    <div>
                                        <span hidden={bag.values.state.subscription_enabled}>
                                            <Button
                                                bsStyle="default toggle-button-off"
                                                onClick={props.handleSubscriptionOn}
                                            >
                                                Off
                                            </Button>
                                        </span>
                                        <span hidden={!bag.values.state.subscription_enabled}>
                                            <Button
                                                bsStyle="default toggle-button-on"
                                                onClick={() =>
                                                    props.handleSubscriptionOff(bag.values.subscription_guid)
                                                }
                                            >
                                                On
                                            </Button>
                                        </span>{' '}
                                        Get real-time{' '}
                                        <b>{bag.values.connected_calendar && bag.values.connected_calendar.name}</b>{' '}
                                        Remote Calendar updates
                                    </div>
                                </Panel.Body>
                                <Panel.Body collapsible={false} hidden={!bag.values.state.needs_calendar_assignment}>
                                    <h4>Select which remote calendar to connect:</h4>
                                    <div>
                                        {map(
                                            bag.values.remoteCalendarList,
                                            (item: { name: string; id: string; inUse: boolean }) => (
                                                <p>
                                                    <Button
                                                        bsStyle="default"
                                                        value={item.id}
                                                        onClick={() => props.handleConnectCalendar(item.name, item.id)}
                                                        hidden={false}
                                                        disabled={item.inUse}
                                                    >
                                                        Connect Calendar
                                                    </Button>{' '}
                                                    {item.name}{' '}
                                                    {item.inUse && ' [Calendar already connected elsewhere] '}
                                                </p>
                                            )
                                        )}
                                    </div>
                                </Panel.Body>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
            {props.controls(bag)}
        </div>
    );
};
