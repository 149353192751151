import React, { FC } from 'react';
import { InputSwitch, useSnackbar } from '../../../components-v2/shared';
import Resource from '../../../store/Resource';
import { Question } from '../../../types/Question';
export interface ToggleOfferSwitchProps {
    question: Question;
    questions: Resource<Question>;
    index?: Number;
}

export const ToggleQuestionSwitch: FC<ToggleOfferSwitchProps> = ({ question, questions, index }) => {
    const { addSnackbar } = useSnackbar();
    const TOO_MANY_TOPICS_ERROR = 'No more than 12 topics may be enabled at a time.';

    const handleClick = async () => {
        await questions
            .update({ ...question, enabled: !question.enabled })
            .then(() => {
                addSnackbar(`Topic "${question.name}" has been ${question.enabled ? 'deactivated' : 'activated'}.`, {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.error(error.response.data.action);
                if (error.response.data.action) {
                    addSnackbar(error.response.data.action, {
                        variant: 'error'
                    });
                } else if (error.response.data === TOO_MANY_TOPICS_ERROR) {
                    addSnackbar(TOO_MANY_TOPICS_ERROR, {
                        variant: 'error'
                    });
                } else {
                    addSnackbar(
                        `Failed to ${question.enabled ? 'deactivate' : 'activate'} "${question.name}" question.`,
                        {
                            variant: 'error'
                        }
                    );
                }
            });
    };

    return (
        <InputSwitch
            labelOn=""
            labelOff=""
            labelPlacement="start"
            onClick={handleClick}
            checked={question.enabled}
            // Index can be undefined and that's okay.
            data-test-id={'button_cb_toggle_question_' + index}
        />
    );
};
