import React, { FC } from 'react';
import classNames from 'classnames';
import { Button, ButtonPrimary, ModalActions, ModalHeader, Tabs } from '@lambdacurry/component-library';
import { ActionList, Modal } from '../../../components-v2/shared';
import { ApplicationSettingsFormValues } from '../ApplicationSettings.helpers';
import { FormikProps } from 'formik';
import './advanced-settings.scss';
import { EmbedTab, PositioningTab, TrackingTab } from './Tabs';
import { observer } from 'mobx-react';
import useStore from '../../../store/useStore';

interface AdvancedSettingsProps {
    className?: string;
    formikProps: FormikProps<ApplicationSettingsFormValues>;
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
}

export const AdvancedSettings: FC<AdvancedSettingsProps> = observer(({ className, formikProps, isOpen, setOpen }) => {
    const { isCompanyLightRole, isSuperAdminRole } = useStore().store;

    const tabs = [
        {
            label: 'Position',
            render: (
                <PositioningTab
                    formikProps={formikProps}
                    disableForm={isCompanyLightRole}
                    disableCustomCss={!isSuperAdminRole}
                    showAppSpacer={isSuperAdminRole}
                />
            )
        }
    ];

    if (!isCompanyLightRole) {
        tabs.push(
            {
                label: 'Tracking',
                render: <TrackingTab formikProps={formikProps} />
            },
            {
                label: 'Embed',
                render: <EmbedTab formikProps={formikProps} />
            }
        );
    }

    return (
        <Modal
            isOpen={isOpen}
            onAfterClose={() => setOpen(false)}
            className={classNames('advanced-settings-modal', className)}
        >
            <ModalHeader title="Advanced Settings" />

            <div className="advanced-settings-modal-content">
                <Tabs variant="fullWidth" tabs={tabs} />
            </div>

            <ModalActions>
                <ActionList position="end">
                    <Button
                        onClick={() => {
                            formikProps.resetForm();
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <ButtonPrimary
                        onClick={() => {
                            formikProps.submitForm();
                            setOpen(false);
                        }}
                    >
                        Save
                    </ButtonPrimary>
                </ActionList>
            </ModalActions>
        </Modal>
    );
});
