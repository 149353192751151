const widgetUrlDir = process.env.REACT_APP_WIDGET_URL_DIR || '';
const widgetUrlFilename = process.env.REACT_APP_WIDGET_FILENAME || 'apikeywidget.js';

export const getWidgetPublicScript = ({ api_key }: { api_key: string; }) => {
    const widgetSrc = widgetUrlDir + widgetUrlFilename;

    return `<!-- DO NOT REMOVE SCRIPT: Beginning of embed script -->
    <div id="clx-drawer"></div>
    <script src="${widgetSrc}"></script>
    <script>TheConversionCloud.launch("${api_key}");</script>
<!-- DO NOT REMOVE SCRIPT: End of embed script -->`;
};

export const animationStyleList = {
    '': '...',
    bounce: 'Bounce',
    pulse: 'Pulse',
    rubberBand: 'Rubber Band',
    shake: 'Shake',
    swing: 'Swing',
    tada: 'Tada',
    wobble: 'Wobble',
    jello: 'Jello',
    heartBeat: 'Heart Beat'
};

export const widgetPositionList = {
    '': '...',
    'top-left': 'Top Left',
    'top-right': 'Top Right',
    'bottom-left': 'Bottom Left',
    'bottom-middle': 'Bottom Middle',
    'bottom-right': 'Bottom Right'
};

export const widgetSkinList = ['default', 'skin1'];

export const defaultWidgetColorTheme = 'red';

export interface ColorValues {
    color_background_default: string;
    color_background_icon_small: string;
    color_error: string;
    color_error_field: string;
    color_border_show_hide_button: string;
    color_button: string;
    color_month: string;
    color_back_month: string;
    color_calendar_day: string;
    color_calendar_background: string;
    color_calendar_past_day: string;
    color_back_calendar_past_day: string;
    color_back_calendar_day_hover: string;
    color_background_submit_button: string;
    color_available_times_item: string;
    color_background_available_times_item: string;
    color_background_available_times_item_hover: string;
    color_border_focus: string;
    color_background_chevron: string;
}
export interface DefaultWidgetColorValues {
    red: ColorValues;
    blue: ColorValues;
    lightblue: ColorValues;
    gray: ColorValues;
    green: ColorValues;
    teal: ColorValues;
    orange: ColorValues;
    purple: ColorValues;
    black: ColorValues;
}

export const defaultWidgetColorValues = {
    red: {
        color_default: '#ffffff',
        color_background_default: '#E51523',
        color_background_icon_small: '#B01721',
        color_error: '#FFFFA3',
        color_error_field: '#ed0606',
        color_border_show_hide_button: '#B01721',
        color_button: '#ffffff',
        color_month: '#cf202c',
        color_back_month: '#fcfafa',
        color_calendar_day: '#787676',
        color_calendar_background: '#ffffff',
        color_calendar_past_day: '#4b4848',
        color_back_calendar_past_day: '#9b9393',
        color_back_calendar_day_hover: '#FBE5E7',
        color_background_submit_button: '#871219',
        color_available_times_item: '#696969',
        color_background_available_times_item: '#fafafa',
        color_background_available_times_item_hover: '#5d000a',
        color_border_focus: '#000099',
        color_background_chevron: '#f4caca'
    },
    blue: {
        color_default: '#ffffff',
        color_background_default: '#006BD6',
        color_background_icon_small: '#0b5394',
        color_error: '#FAFA00',
        color_error_field: '#ed0c0c',
        color_border_show_hide_button: '#0b5394',
        color_button: '#ffffff',
        color_month: '#006BD6',
        color_back_month: '#FAFAFA',
        color_calendar_day: '#696969',
        color_calendar_background: '#ffffff',
        color_calendar_past_day: '#4d4747',
        color_back_calendar_past_day: '#a19191',
        color_back_calendar_day_hover: '#DBEEFF',
        color_background_submit_button: '#094377',
        color_available_times_item: '#696969',
        color_background_available_times_item: '#FAFAFA',
        color_background_available_times_item_hover: '#242323',
        color_border_focus: '#00E0E0',
        color_background_chevron: '#bdcbe2'
    },
    lightblue: {
        color_default: '#102B71',
        color_background_default: '#00CCFF',
        color_background_icon_small: '#00A6D3',
        color_error: '#444419',
        color_error_field: '#9d9d9d',
        color_border_show_hide_button: '#00A6D3',
        color_button: '#102B71',
        color_month: '#1c2b2e',
        color_back_month: '#999898',
        color_calendar_day: '#00CCFF',
        color_calendar_background: '#454c51',
        color_calendar_past_day: '#1c2b2e',
        color_back_calendar_past_day: '#999898',
        color_back_calendar_day_hover: '#BDF2FF',
        color_background_submit_button: '#06a8d3',
        color_available_times_item: '#bcbbba',
        color_background_available_times_item: '#534949',
        color_background_available_times_item_hover: '#656464',
        color_border_focus: '#516970',
        color_background_chevron: '#5d6060'
    },
    gray: {
        color_default: '#ffffff',
        color_background_default: '#707070',
        color_background_icon_small: '#545454',
        color_error: '#FFFF00',
        color_error_field: '#ed0c0c',
        color_border_show_hide_button: '#545454',
        color_button: '#ffffff',
        color_month: '#6a6a6a',
        color_back_month: '#f4f4f4',
        color_calendar_day: '#696969',
        color_calendar_background: '#ffffff',
        color_calendar_past_day: '#494646',
        color_back_calendar_past_day: '#939292',
        color_back_calendar_day_hover: '#EBEBEB',
        color_background_submit_button: '#404040',
        color_available_times_item: '#696969',
        color_background_available_times_item: '#f4f4f4',
        color_background_available_times_item_hover: '#262626',
        color_border_focus: '#B6CDE7',
        color_background_chevron: '#cdcdcd'
    },
    green: {
        color_default: '#ffffff',
        color_background_default: '#008000',
        color_background_icon_small: '#006600',
        color_error: '#FFFF38',
        color_error_field: '#eb1212',
        color_border_show_hide_button: '#006600',
        color_button: '#ffffff',
        color_month: '#027c02',
        color_back_month: '#f6efef',
        color_calendar_day: '#696969',
        color_calendar_background: '#ffffff',
        color_calendar_past_day: '#494848',
        color_back_calendar_past_day: '#959494',
        color_back_calendar_day_hover: '#9EFF9E',
        color_background_submit_button: '#003300',
        color_available_times_item: '#696969',
        color_background_available_times_item: '#f2f2f2',
        color_background_available_times_item_hover: '#242424',
        color_border_focus: '#FFBA52',
        color_background_chevron: '#c1cdc1'
    },
    teal: {
        color_default: '#ffffff',
        color_background_default: '#008080',
        color_background_icon_small: '#075a5a',
        color_error: '#FFFF7A',
        color_error_field: '#eb0e0e',
        color_border_show_hide_button: '#075a5a',
        color_button: '#ffffff',
        color_month: '#008080',
        color_back_month: '#fafafa',
        color_calendar_day: '#696969',
        color_calendar_background: '#ffffff',
        color_calendar_past_day: '#494848',
        color_back_calendar_past_day: '#999393',
        color_back_calendar_day_hover: '#61FFFF',
        color_background_submit_button: '#003300',
        color_available_times_item: '#696969',
        color_background_available_times_item: '#fafafa',
        color_background_available_times_item_hover: '#2a2a2a',
        color_border_focus: '#00F000',
        color_background_chevron: '#d1d7d1'
    },
    orange: {
        color_default: '#4B0201',
        color_background_default: '#FF8F48',
        color_background_icon_small: '#FDAB82',
        color_error: '#030303',
        color_error_field: '#e16262',
        color_border_show_hide_button: '#FDAB82',
        color_button: '#000000',
        color_month: '#FF8F48',
        color_back_month: '#5d3535',
        color_calendar_day: '#FF8F48',
        color_calendar_background: '#4b3d3d',
        color_calendar_past_day: '#4b4848',
        color_back_calendar_past_day: '#979494',
        color_back_calendar_day_hover: '#FFE6B8',
        color_background_submit_button: '#ffbe9f',
        color_available_times_item: '#caa9a9',
        color_background_available_times_item: '#424040',
        color_background_available_times_item_hover: '#8b461b',
        color_border_focus: '#814722',
        color_background_chevron: '#705336'
    },
    purple: {
        color_default: '#ffffff',
        color_background_default: '#B800F5',
        color_background_icon_small: '#800080',
        color_error: '#FFFF47',
        color_error_field: '#df3030',
        color_border_show_hide_button: '#800080',
        color_button: '#ffffff',
        color_month: '#B800F5',
        color_back_month: '#f8f8f8',
        color_calendar_day: '#696969',
        color_calendar_background: '#ffffff',
        color_calendar_past_day: '#474747',
        color_back_calendar_past_day: '#939393',
        color_back_calendar_day_hover: '#F9E5FF',
        color_background_submit_button: '#4d004d',
        color_available_times_item: '#696969',
        color_background_available_times_item: '#f8f7f7',
        color_background_available_times_item_hover: '#4d004d',
        color_border_focus: '#99E600',
        color_background_chevron: '#d8cdd9'
    },
    black: {
        color_default: '#ffffff',
        color_background_default: '#4F4F4F',
        color_background_icon_small: '#333333',
        color_error: '#DBDB00',
        color_error_field: '#e52626',
        color_border_show_hide_button: '#333333',
        color_button: '#ffffff',
        color_month: '#4F4F4F',
        color_back_month: '#ebebeb',
        color_calendar_day: '#696969',
        color_calendar_background: '#ffffff',
        color_calendar_past_day: '#474747',
        color_back_calendar_past_day: '#939393',
        color_back_calendar_day_hover: '#EBEBEB',
        color_background_submit_button: '#1a1a1a',
        color_available_times_item: '#696969',
        color_background_available_times_item: '#c2c2c2',
        color_background_available_times_item_hover: '#000000',
        color_border_focus: '#00A7FA',
        color_background_chevron: '#a8a5a8'
    }
};
