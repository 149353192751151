import React, { FC } from 'react';
import {
    ActionList,
    Button,
    ButtonPrimary,
    Modal,
    ModalHeader,
    ModalActions,
    useSnackbar
} from '../../../components-v2/shared';
import Resource from '../../../store/Resource';
import { Question } from '../../../types/Question';
import { closeModal, setModalData, QuestionReducerAction } from '../Question.helpers';

export interface DeleteQuestionModalProps {
    isOpen: boolean;
    dispatch: React.Dispatch<QuestionReducerAction>;
    questions: Resource<Question>;
    question: Question;
    onAfterDelete?: (questionId: Question['id']) => void;
}

export const DeleteQuestionModal: FC<DeleteQuestionModalProps> = ({
    isOpen,
    dispatch,
    questions: questions,
    question: question,
    onAfterDelete
}) => {
    const { addSnackbar } = useSnackbar();

    const handleConfirmClick = async () => {
        try {
            await questions.delete(question.id);
            addSnackbar(`Question "${question.name}" has been deleted.`, {
                variant: 'success'
            });

            if (typeof onAfterDelete === 'function') {
                onAfterDelete(question.id);
            }
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to delete "${question.name}" question.`, {
                variant: 'error'
            });
        }
    };

    const handleAfterClose = () => {
        closeModal(dispatch);
        setModalData(dispatch, null);
    };

    return (
        <Modal id="deleteQuestion" isOpen={isOpen} onAfterClose={handleAfterClose}>
            {question && (
                <>
                    <ModalHeader title="Delete question?" />
                    Are you sure you want to delete the question "{question.name}"?
                    <ModalActions>
                        <ActionList position="end">
                            <Button onClick={() => closeModal(dispatch)}>No, don't delete</Button>
                            <ButtonPrimary onClick={handleConfirmClick}>Yes, delete</ButtonPrimary>
                        </ActionList>
                    </ModalActions>
                </>
            )}
        </Modal>
    );
};
