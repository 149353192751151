import React from 'react';
import { DateTime } from 'luxon';
import { AppCard } from '../../../../components-v2/shared';
import './customer-lead-journey.scss';
import { LeadJourney, friendlyAppNames, friendlyModuleNames } from '../../../../types';
import { findLastIndex } from 'lodash';

enum LeadJourneyEventType {
    lead_customer_log = 'lead_customer_log',
    smsMessage = 'sms_message',
    sessionTouch = 'session_touch',
    matchback = 'matchback'
}

const labels = {
    lead_customer_log: 'Event Log',
    session_touch: 'Session/Touch',
    sms_message: 'SMS Message',
    matchback: 'Matchback'
};

const tagLabels = {
    matchback: 'Matchback',
    referrer: 'Referrer',
    interactions: 'Interactions',
    device_type: 'Device Type',
    device_location: 'Device Location',
    os_name: 'Device OS',
    utm_source: 'UTM Source',
    utm_medium: 'UTM Medium',
    utm_campaign: 'UTM Campaign',
    utm_term: 'UTM Term',
    utm_content: 'UTM Content'
};

export const CustomerLeadJourney: React.FC<{ data?: LeadJourney[] }> = ({ data }) => {
    if (!data) {
        return <AppCard className="app-event-card">Loading...</AppCard>;
    }

    const UTMTag: React.FC<{ tag: string; val: string; className: string }> = ({ tag, val, className }) => {
        if (!val || val === '') {
            return null;
        }

        return (
            <div className={className}>
                <span>{tagLabels[tag] || tag}: </span>
                {val}
            </div>
        );
    };

    // Logic is flipped because data array is sorted most recent events first
    const firstTouchIndex = findLastIndex(data, ({ type }) => type === LeadJourneyEventType.sessionTouch);
    const lastTouchIndex = data.findIndex(({ type }) => type === LeadJourneyEventType.sessionTouch);

    return (
        <AppCard className="customer-lead-journey">
            {data.map((journey, index: number) => {
                const key = `${journey.data.id}-${journey.data.created_at}`;
                const tags = journey.data;
                const hasMultipleApps = tags.apps_opened ? tags.apps_opened.split(',').length > 1 : false;

                return (
                    <div key={key} className="customer-lead-journey-row">
                        <div className="customer-lead-journey-header">
                            <span>{labels[journey.type]}</span>
                            <span>
                                {journey.timestamp &&
                                    DateTime.fromISO(journey.timestamp).toLocaleString(DateTime.DATETIME_SHORT)}
                                {!journey.timestamp &&
                                    journey.type === LeadJourneyEventType.smsMessage &&
                                    'Message not sent'}
                            </span>
                        </div>
                        <hr className="customer-lead-journey-divider" />
                        <div className="customer-lead-journey-labels">
                            {journey.type === LeadJourneyEventType.matchback && (
                                <div className="customer-lead-journey-label matchback">
                                    <span>Matchback: </span>A client list was uploaded containing a match for this
                                    customer.
                                </div>
                            )}
                            {journey.type === LeadJourneyEventType.smsMessage && (
                                <>
                                    {tags.status === 'Sent' && tags.sent_at && (
                                        <div className="customer-lead-journey-label">Message Sent</div>
                                    )}
                                    {tags.status !== 'Sent' && tags.message_error && (
                                        <div className="customer-lead-journey-label">
                                            <span>Delivery Error Message: </span> {tags.message_error}
                                        </div>
                                    )}
                                    <div className="customer-lead-journey-label">
                                        <span>Notification Type: </span>
                                        {tags.notification_type}
                                    </div>
                                    <div className="customer-lead-journey-label">
                                        <span>Message: </span>
                                        {tags.message}
                                    </div>
                                </>
                            )}
                            {journey.type === LeadJourneyEventType.lead_customer_log && (
                                <div className="customer-lead-journey-label">
                                    <span>Notification: </span>
                                    {tags.description}
                                </div>
                            )}
                            {journey.type === LeadJourneyEventType.sessionTouch && (
                                <>
                                    {index === firstTouchIndex && (
                                        <div className="customer-lead-journey-label first-touch">First Touch</div>
                                    )}
                                    {index === lastTouchIndex && index !== firstTouchIndex && (
                                        <div className="customer-lead-journey-label last-touch">Last Touch</div>
                                    )}
                                    {tags.lead_via_apps && (
                                        <>
                                            {tags.lead_via_apps.split(',').map(app => {
                                                return (
                                                    <div key={app} className="customer-lead-journey-label conversion">
                                                        Conversion: {friendlyModuleNames[app]}
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                    {tags.leads_data && (
                                        <>
                                            {tags.leads_data.split(',').map(app => {
                                                const ld = app.split('|');
                                                const ldDisp = (ld.length > 1) ? `${friendlyAppNames[ld[0]]}: ${ld[1]}` : `Conversion: ${friendlyAppNames[ld[0]]}`;
                                                return (
                                                    <div key={app} className="customer-lead-journey-label conversion">
                                                        {ldDisp}
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                    {tags.apps_opened && (
                                        <div className="customer-lead-journey-label apps">
                                            {`${hasMultipleApps ? 'Apps' : 'App'} Opened: `}
                                            {tags.apps_opened
                                                .split(',')
                                                .map(app => {
                                                    return friendlyModuleNames[app];
                                                })
                                                .join(', ')}
                                        </div>
                                    )}
                                    {!!tags.interactions && (
                                        <UTMTag
                                            tag="interactions"
                                            val={`${tags.interactions}`}
                                            className="customer-lead-journey-label"
                                        />
                                    )}
                                    {Object.keys(tags)
                                        .filter((key: string) => {
                                            return ![
                                                'id',
                                                'created_at',
                                                'lead_via_apps',
                                                'apps_opened',
                                                'leads_data',
                                                'interactions'
                                            ].includes(key);
                                        })
                                        .map((tag: any) => {
                                            return (
                                                <UTMTag
                                                    key={tag}
                                                    tag={tag}
                                                    val={tags[tag]}
                                                    className={`customer-lead-journey-label ${tag}`}
                                                />
                                            );
                                        })}
                                </>
                            )}
                        </div>
                    </div>
                );
            })}
        </AppCard>
    );
};
