import React, { FC, useState } from 'react';
import { FastField, FieldArray, FieldProps, FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import { Link } from 'mobx-router';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import {
    AppSection,
    AppSectionHeader,
    Icon,
    InputText,
    InputSelect,
    InputSwitch,
    InputCheckbox,
    Form,
    ButtonOutline,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputDate,
    IconButton,
    InputPhone
} from '../../../components-v2/shared';

import useStore from '../../../store/useStore';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import { handlePromise, useAsyncEffect } from '../../../util/async';
import { EditOfferFormValidationSchema } from '../ConcessionManager.validation';

import styles from '../concession-manager.module.scss';
import { Offer } from '../../../types/Offer';
import { phoneFormatter } from '../../../util/formatters';
import { filterCRMList } from '../../../types/Crm';
import { isString } from 'lodash';
import { unsavedChangesContainerSelectorDefault } from '../../../constants';
import { FEATURES } from '../../../types/Features';
import { LeadNurtureAppSource } from '../../EmailEditor/EmailEditorList.helpers';
import { JSONWithQuestionBundle, QuestionEditor } from '../../QuestionEditor/QuestionEditor';
export interface EditOfferFormProps {
    initialValues: Offer;
    onSubmit: (values: Offer, actions: FormikHelpers<Offer>) => Promise<void>;
    enableReinitialize?: boolean;
    attachmentLink?: string | null | undefined;
}

interface SelectListItem {
    label: string;
    value: number;
    linked_by: number | null;
}

export const EditOfferForm: FC<EditOfferFormProps> = observer(({ onSubmit, ...props }) => {
    const { store } = useStore();
    const [ulmParentOffers, setUlmParentOffers] = useState<SelectListItem[]>([]);
    const {
        router,
        Api,
        fetchCrmConfigOptions,
        fetchDripSchedules,
        dripSchedules,
        activeCompanyId,
        crmConfigOptionList,
        activeCompany,
        features,
        isSuperAdminRole,
        hasLegacyWidget
    } = store;
    const { companyId } = router.params;

    const [showPathFilters, setShowPathFilters] = useState(
        !!((props.initialValues.pathnames || []).length && props.initialValues.pathnames[0])
    );

    const fetchULMParentOffers = async () => {
        const [response, error] = await handlePromise(
            Api.client.get(`offers/ulm-parent-spotlights/${activeCompanyId}`)
        );

        if (!response?.data || error) {
            return;
        }

        setUlmParentOffers(response.data);
    };

    const fetchRequiredData = async () => {
        await fetchULMParentOffers();
        await fetchDripSchedules();
        await fetchCrmConfigOptions();
    };
    useAsyncEffect(fetchRequiredData, undefined, []);

    const handleCancel = () => router.goTo(DefaultRoutes.ConcessionManagerList, { companyId }, store);

    const leadNurturingEnabled = features[FEATURES.lead_nurturing];
    const concessionAutomationEnabled = features[FEATURES.concession_automation];

    const filterSpotlights = (spotlights: SelectListItem[]) => {
        return spotlights.filter(s => !s.linked_by || s.linked_by === props.initialValues.id);
    };

    // We need to make a reasonable attempt to set the time for midnight of whatever timezone the company is.
    // Failing that, fall back on the active user (West Coast support might be working on an East Coast company).
    const fixTimeStamp = (ts?: Date | string | null): Date | null => {
        // If nothing is set, return nothing.
        if (!ts) {
            return null;
        }

        // We don't care about the company timezone yet. We only care about what the datepicker UI component
        // thinks the date is...which is based on local().zone. We will apply the company timezone later.
        // https://clxmedia.atlassian.net/browse/TCCM-2029
        const newDateTime: DateTime = isString(ts)
            ? DateTime.fromISO(ts, { zone: DateTime.local().zone }).startOf('day')
            : DateTime.fromJSDate(ts, { zone: DateTime.local().zone }).startOf('day');

        // Strip the date of all time/timezone info and just make a new object using the date and company timezone.
        return DateTime.fromISO(newDateTime.toFormat('yyyy-MM-dd')).toJSDate();
    };

    const handleSubmit = async (values: Offer, actions: FormikHelpers<Offer>) => {
        // Strip out any empty pathnames before submitting
        const newPathnames = values.pathnames.filter(pathname => !!pathname);
        const newActiveTimeStart = fixTimeStamp(values.active_time_start);
        const newActiveTimeEnd = fixTimeStamp(values.active_time_end);

        await onSubmit(
            {
                ...values,
                pathnames: newPathnames,
                active_time_start: newActiveTimeStart,
                active_time_end: newActiveTimeEnd
            },
            actions
        );
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            validationSchema={EditOfferFormValidationSchema}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
            validateOnChange={false}
        >
            {(formikProps: FormikProps<Offer>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="Offer" />
                                {concessionAutomationEnabled && (
                                    <InputCheckbox
                                        name="concession_automation_enabled"
                                        label="Update this offer automatically from Concession Automation"
                                        formikProps={formikProps}
                                        disabled={showPathFilters}
                                    />
                                )}
                                <InputText
                                    name="offer_text"
                                    label="Title"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 25 }}
                                />
                                <InputText
                                    id="offer_description"
                                    name="offer_description"
                                    label="Description"
                                    placeholder="Reserve a unit within the next week and get 1 month free!"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 70 }}
                                />
                                <InputText
                                    id="disclaimer"
                                    name="disclaimer"
                                    label="Disclaimer Link Title"
                                    placeholder="Type title…"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 70 }}
                                />
                                <InputText
                                    id="fine_print"
                                    name="fine_print"
                                    label="Disclaimer Text"
                                    placeholder="Type additional details here…"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 500 }}
                                />
                                <InputText
                                    id="attachment_link"
                                    name="attachment_link"
                                    label="Link"
                                    placeholder="Attachment Link"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <AppSectionHeader title="App" />
                                <InputText
                                    name="offer_menu_title"
                                    label="App Offer Title"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 25 }}
                                />
                                <InputText
                                    name="call_to_action"
                                    label="Call To Action"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 25 }}
                                />
                                <InputText
                                    name="submission_button_text"
                                    label="Lead submission button text"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 22 }}
                                />
                                <InputText
                                    name="create_success_message"
                                    label="Confirmation Message"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 50 }}
                                />
                                <InputSelect
                                    name="widget_icon"
                                    label="App Icon"
                                    optionValueKey="value"
                                    options={[
                                        {
                                            label: 'Default Style',
                                            value: 'clxicon-icons_concession_manager'
                                        },
                                        {
                                            label: 'Info Style',
                                            value: 'clxicon-icons_info'
                                        }
                                    ]}
                                    formikProps={formikProps}
                                    autocompleteConfig={{
                                        disableClearable: true
                                    }}
                                />
                                <div>
                                    <InputCheckbox
                                        name="suggest_schedule_appointment"
                                        label="Include Schedule Genie link in confirmation"
                                        formikProps={formikProps}
                                    />
                                </div>
                                <div>
                                    <InputCheckbox
                                        name="hide_offer_on_specific_pages"
                                        label="Hide this offer on pages with specific offers"
                                        formikProps={formikProps}
                                        disabled={showPathFilters}
                                    />
                                </div>
                            </div>

                            <div className="field-group-col">
                                <QuestionEditor
                                    questionLimit={hasLegacyWidget ? 3 : Infinity}
                                    sectionTitle="Qualification Questions"
                                    formikProps={formikProps as FormikProps<JSONWithQuestionBundle>}
                                />
                                <AppSectionHeader title="Offer Duration" />
                                <div className={styles['concession-manager-duration-row']}>
                                    <InputDate
                                        label="Start Date"
                                        name="active_time_start"
                                        disablePast={true}
                                        formikProps={formikProps}
                                        placeholder="MM/DD/YYYY"
                                    />
                                    <InputDate
                                        label="End Date"
                                        name="active_time_end"
                                        disablePast={true}
                                        formikProps={formikProps}
                                        placeholder="MM/DD/YYYY"
                                        onChange={(date: any) =>
                                            formikProps.setFieldValue('active_time_ongoing', !!!date)
                                        }
                                    />
                                </div>
                                <InputCheckbox
                                    label="Ongoing"
                                    name="active_time_ongoing"
                                    formikProps={formikProps}
                                    onChange={(_, checked) =>
                                        checked
                                            ? (() => {
                                                  formikProps.setFieldValue('active_time_start', null);
                                                  formikProps.setFieldValue('active_time_end', null);
                                              })()
                                            : null
                                    }
                                    className={styles['concession-manager-offer-ongoing-checkbox']}
                                />

                                <AppSectionHeader
                                    title="Display on specific site pages"
                                    subtitle="Adding paths will set this offer to only display on specific site pages."
                                >
                                    <InputSwitch
                                        checked={showPathFilters}
                                        onChange={() => setShowPathFilters(!showPathFilters)}
                                    />
                                </AppSectionHeader>

                                {showPathFilters && (
                                    <div className={styles['concession-manager-paths']}>
                                        <FieldArray name="pathnames">
                                            {arrayHelpers => {
                                                const pathnames: string[] = formikProps.values['pathnames'];
                                                if (pathnames.length < 1) {
                                                    arrayHelpers.push('');
                                                    return null; // Make sure we return null the first time to avoid console warnings.
                                                }

                                                return pathnames.map((_, index) => {
                                                    const inputName = `pathnames.${index}`;
                                                    return (
                                                        <div
                                                            key={inputName}
                                                            className={styles['concession-manager-paths-row']}
                                                        >
                                                            <FastField name={inputName}>
                                                                {(fieldProps: FieldProps) => (
                                                                    <>
                                                                        <InputText
                                                                            label="Website Path"
                                                                            {...fieldProps.field}
                                                                            error={!!fieldProps.meta.error}
                                                                            helperText={
                                                                                fieldProps.meta.touched &&
                                                                                fieldProps.meta.error
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                            </FastField>
                                                            <IconButton
                                                                icon="plus"
                                                                onClick={() => arrayHelpers.push('')}
                                                            />
                                                            {index > 0 && (
                                                                <IconButton
                                                                    icon="trash"
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                />
                                                            )}
                                                        </div>
                                                    );
                                                });
                                            }}
                                        </FieldArray>
                                    </div>
                                )}
                            </div>
                        </AppSection>

                        {leadNurturingEnabled && (
                            <AppSection>
                                <div className="field-group-content">
                                    <div className="field-group-col">
                                        <AppSectionHeader title="Lead Nurturing" />
                                        <div className={styles['schedule-genie-availability-row']}>
                                            <InputSelect
                                                className={styles['schedule-genie-availability-row-select']}
                                                label="Select Lead Nurturing Drip Schedule"
                                                name="drip_schedule_id"
                                                options={dripSchedules.values.filter(d => {
                                                    return LeadNurtureAppSource.spotlight === d.app_source_type;
                                                })}
                                                optionValueKey="id"
                                                optionLabelKey="name"
                                                formikProps={formikProps}
                                                autocompleteConfig={{
                                                    disableClearable: false
                                                }}
                                            />

                                            <ButtonOutline
                                                className={styles['schedule-genie-availability-row-link']}
                                                as={buttonProps => (
                                                    <Link
                                                        {...buttonProps}
                                                        view={DefaultRoutes.DripScheduleEditorListPage}
                                                        params={router.params}
                                                        store={store}
                                                    />
                                                )}
                                            >
                                                Edit Drip Schedules
                                            </ButtonOutline>
                                        </div>
                                    </div>
                                </div>
                            </AppSection>
                        )}

                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="Confirmation Email" />
                                <ButtonOutline
                                    className={classNames(
                                        'field-group-button-full-width',
                                        styles['concession-manager-edit-email-button']
                                    )}
                                    data-lc-trigger-unsaved-changes={true}
                                    icon={<Icon name="confirmationEmail" />}
                                    as={buttonProps => (
                                        <Link
                                            {...buttonProps}
                                            view={DefaultRoutes.EmailTemplatesList}
                                            params={{ companyId: activeCompanyId }}
                                            store={store}
                                        />
                                    )}
                                >
                                    Edit Email
                                </ButtonOutline>
                            </div>

                            <div className="field-group-col">
                                <AppSectionHeader
                                    title="Override Lead Notification Settings"
                                    subtitle="Change company email notifications for a new lead from Spotlight."
                                >
                                    <InputSwitch name="override_lead_notification" formikProps={formikProps} />
                                </AppSectionHeader>

                                {formikProps.values['override_lead_notification'] &&
                                    filterCRMList(crmConfigOptionList) && (
                                        <>
                                            <InputText
                                                label="Include Emails for Lead Notifications"
                                                name="lead_notification_primary_emails"
                                                formikProps={formikProps}
                                            />
                                            <InputText
                                                label="Reply-to Email"
                                                name="reply_to_email"
                                                formikProps={formikProps}
                                            />
                                            <InputPhone
                                                label="Phone Number"
                                                name="phone"
                                                placeholder={`Default: ${phoneFormatter(activeCompany.phone)}`}
                                                inputConfig={{ shrinkLabel: true }}
                                                formikProps={formikProps}
                                            />
                                            <InputSelect
                                                label="CRM Configuration"
                                                name="crm_config_id"
                                                optionLabelKey="name"
                                                optionValueKey="id"
                                                options={[
                                                    { id: 0, name: 'No CRM' },
                                                    ...filterCRMList(crmConfigOptionList)
                                                ]}
                                                formikProps={formikProps}
                                                autocompleteConfig={{
                                                    disableClearable: true
                                                }}
                                            />
                                        </>
                                    )}
                            </div>
                        </AppSection>
                        {isSuperAdminRole && (
                            <AppSection className="field-group-content">
                                {ulmParentOffers && ulmParentOffers.length > 0 ? (
                                    <div className="field-group-col">
                                        <AppSectionHeader title="ULM" />
                                        <InputSelect
                                            label="Linked ULM Spotlight"
                                            name="ulm_parent_offer_id"
                                            optionLabelKey="label"
                                            optionValueKey="value"
                                            options={[
                                                { value: null, label: 'Not Linked' },
                                                ...filterSpotlights(ulmParentOffers)
                                            ]}
                                            formikProps={formikProps}
                                            autocompleteConfig={{
                                                disableClearable: true
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className="field-group-col">
                                        <AppSectionHeader title="ULM" />
                                        <InputCheckbox
                                            label="Is ULM offer"
                                            name="is_ulm_offer"
                                            formikProps={formikProps}
                                        />
                                    </div>
                                )}
                            </AppSection>
                        )}

                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button
                                        onClick={handleCancel}
                                        data-lc-trigger-unsaved-changes={true}
                                        data-test-id="button_cancel"
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary
                                    type="submit"
                                    disabled={!formikProps.dirty || formikProps.isSubmitting}
                                    data-test-id="button_save"
                                >
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
