import React, { FC, useState } from 'react';
import {
    ActionList,
    AppHeader,
    AppSection,
    Button,
    Modal,
    ModalActions,
    ModalHeader,
    useSnackbar
} from '../../../components-v2/shared';
import { Filters } from '../../Filters/Filters.helpers';
import { BaseRow, Importer, ImporterField } from '@clxmedia/react-csv-importer';

// include the widget CSS file whichever way your bundler supports it
import '@clxmedia/react-csv-importer/dist/index.css';
import { handlePromise } from '@lambdacurry/component-library';
import useStore from '../../../store/useStore';

interface LeadCustomerData {
    firstname: string;
    lastname: string;
    email: string;
    phone?: string;
}

interface ImportResult {
    imported: number;
    duplicates: LeadCustomerData[];
    errors: string[];
}

export const LeadImport: FC<{
    filters?: Filters;
}> = () => {
    const { store } = useStore();
    const { Api, router } = store;
    const { addSnackbar } = useSnackbar();
    const [resultModal, setResultModal] = useState(false);
    const [importResults, setImportResults] = useState<ImportResult[]>([]);

    async function doContactsUpload(rows: BaseRow[]) {
        const [response, error] = await handlePromise(
            Api.client.post(`company/${router.params.companyId}/contacts/import`, rows)
        );

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to import leads.', { variant: 'error' });
            return;
        }

        setImportResults([...importResults, response.data]);
    }

    const dupsToListItems = (resultArray: ImportResult[]) => {
        const dupSet: Set<LeadCustomerData> = new Set();
        resultArray.forEach(resEntry => {
            resEntry.duplicates.forEach(dup => {
                dupSet.add(dup);
            });
        });
        if (dupSet.size === 0) {
            return <></>;
        }

        return (
            <ul>
                {Array.from(dupSet).map(dup => {
                    return (
                        <li>
                            {dup.firstname} {dup.lastname} ({dup.email})
                        </li>
                    );
                })}
            </ul>
        );
    };

    const errorItems = (resultArray: ImportResult[]) => {
        const errSet: Set<string> = new Set();
        resultArray.forEach(errEntry => {
            errEntry.errors.forEach(err => {
                errSet.add(err);
            });
        });
        if (errSet.size === 0) {
            return <>No errors</>;
        }
        return (
            <ul>
                {Array.from(errSet).map(err => {
                    return <li>{err}</li>;
                })}
            </ul>
        );
    };

    return (
        <>
            <AppHeader title="Import Contacts"></AppHeader>
            <AppSection>
                <Importer
                    dataHandler={async (rows, { startIndex }) => {
                        // required, may be called several times
                        // receives a list of parsed objects based on defined fields and user column mapping;
                        // (if this callback returns a promise, the widget will wait for it before parsing more data)
                        await doContactsUpload(rows);
                    }}
                    defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                    restartable={true} // optional, lets user choose to upload another file when import is complete
                    onStart={({ file, preview, fields, columnFields }) => {
                        // optional, invoked when user has mapped columns and started import
                    }}
                    onComplete={({ file, preview, fields, columnFields }) => {
                        // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                        setResultModal(true);
                    }}
                >
                    <ImporterField name="firstname" label="First Name" />
                    <ImporterField name="lastname" label="Last Name" />
                    <ImporterField name="email" label="Email" />
                    <ImporterField name="phone" label="Phone" optional />
                </Importer>
            </AppSection>
            <Modal isOpen={resultModal} closeButton={false}>
                <ModalHeader title="Import Results" />
                {importResults && importResults.length > 0 && (
                    <div>
                        <p>
                            <b>Imported Records:</b>{' '}
                            {importResults
                                .map(result => {
                                    return result.imported;
                                })
                                .reduce((acc: number, cur: number) => {
                                    acc += cur;
                                    return acc;
                                }, 0)}
                        </p>
                        <p>
                            <b>Duplicate Records:</b>{' '}
                            {importResults
                                .map(result => {
                                    return result.duplicates.length;
                                })
                                .reduce((acc: number, cur: number) => {
                                    acc += cur;
                                    return acc;
                                }, 0)}
                            {dupsToListItems(importResults)}
                        </p>
                        <p>
                            <b>Errors:</b> <ul>{errorItems(importResults)}</ul>
                        </p>
                    </div>
                )}
                <ModalActions>
                    <ActionList position="end">
                        <Button
                            onClick={() => {
                                setResultModal(false);
                                setImportResults([]);
                            }}
                        >
                            Close
                        </Button>
                    </ActionList>
                </ModalActions>
            </Modal>
        </>
    );
};
