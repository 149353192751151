import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputSelect
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, RulesActionFactory, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';

export interface RealPageFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface RealPageCrm extends Crm {
    configuration: {
        crm_emails: string[] | string;
        lead_email_source_name: string;
        lead_email_source_type: string;
    };
}

const ruleActions = [
    RulesActionFactory.ReplaceStringAction('lead_email_source_name', 'Replace Source Name with new value'),
    RulesActionFactory.ReplaceCrmEmailsAction
];

export const RealPageForm: FC<RealPageFormProps> = observer(({ onSubmit, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleSubmit = async (values: RealPageCrm, actions: FormikHelpers<RealPageCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.realpage);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {formikProps => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: RealPage" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader title="Email Parameters" />
                                <InputText
                                    name="configuration.lead_email_source_name"
                                    label="Lead Email Source Name"
                                    helperText="RealPage Lead Email Source Name"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputSelect
                                    label="Source Type"
                                    name="configuration.lead_email_source_type"
                                    options={[
                                        { val: 'm', label: 'Multi-Family Housing' },
                                        { val: 's', label: 'Senior Living' }
                                    ]}
                                    optionValueKey="val"
                                    optionLabelKey="label"
                                    helperText="Lead Source Type"
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader title="Delivery Options" />
                                <InputText
                                    name="configuration.crm_emails"
                                    label="CRM Email"
                                    helperText="The email address that gets the CRM-formatted content"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
