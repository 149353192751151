import { Icon } from '@lambdacurry/component-library';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { ChatMessage } from '../../../types';

export const MessageBubble: FC<{ message: ChatMessage }> = ({ message }) => {
    const {
        created_at,
        is_outgoing,
        sent_at,
        delivery_status,
        message_error,
        attachment_url,
        has_attachment,
        message: messageText
    } = message;
    const hasError = message.hasError || !!message_error || delivery_status === 'failed';
    const errMsg = !!message_error ? message_error : 'Failed to send message';
    const hasCaption = !!messageText;
    const altText = hasCaption ? messageText : 'MMS attachment';

    const renderContent = () => (
        <>
            <div className="message-text">{messageText}</div>
            <div className="message-timestamp">
                {is_outgoing ? (sent_at ? 'Sent' : 'Pending') : 'Received'}:{' '}
                {DateTime.fromISO(created_at!).toLocaleString(DateTime.DATETIME_SHORT)}
            </div>
        </>
    );

    return (
        <div
            className={classNames('message', {
                'has-error': hasError,
                'is-outgoing': is_outgoing,
                'is-incoming': !is_outgoing
            })}
        >
            <div className="message-bubble">
                {has_attachment && !!attachment_url && (
                    <div className={classNames('message-image-wrapper', { 'has-caption': hasCaption })}>
                        <a href={attachment_url} target="_blank" rel="noopener noreferrer" title={altText}>
                            <img src={attachment_url} alt={altText} />
                        </a>

                        {hasCaption && <div className="message-caption">{renderContent()}</div>}
                    </div>
                )}

                {!has_attachment && <>{renderContent()}</>}
            </div>

            {hasError && (
                <div className="message-error">
                    <Icon name="error" />
                    {errMsg}
                </div>
            )}
        </div>
    );
};
