export enum ChartType {
    custom = 'custom',
    doughnut = 'doughnut',
    stats = 'stats',
    combo = 'combo',
    bubble = 'bubble',
    table = 'table',
    line = 'line',
    bar = 'bar',
    pie = 'pie',
    number = 'number'
}

export const friendlyModuleNames = {
    MODULE_BPN: 'Best Price Now',
    MODULE_CALLUS: 'Call Us',
    MODULE_CHAT: 'Chat',
    MODULE_CONCESSIONMANAGER: 'Spotlight',
    MODULE_INCOMECALCULATOR: 'Income Calculator',
    MODULE_SCHEDULEGENIE: 'Schedule Genie',
    MODULE_INSITE: 'Insite',
    MODULE_TEXTUS: 'Text Us Now',
    MODULE_CHATBOT: 'Virtual Assistant',
    CM: 'Spotlight',
    IC: 'Income Calculator',
    SG: 'Schedule Genie',
    INSITE: 'Insite',
    CHAT: 'Chat',
    BPN: 'Best Price Now',
    TEXT_US: 'Text Us Now',
    TOTAL: 'All Applications',
    CB: 'ChatBot',
    CBAI: 'Claire AI+',
    LC: 'Live Chat',
    ULM_CONCESSION_MANAGER: 'ULM Spotlight',
    ULM_SCHEDULE_GENIE: 'ULM Schedule Genie',
    MODULE_CHATBOT_AI: 'Claire AI+',
    MODULE_LIVE_CHAT: 'Live Chat',
};

export const friendlyAppNames = {
    BPN: 'Best Price Now',
    CONCESSION_MANAGER: 'Spotlight',
    INCOME_CALCULATOR: 'Income Calculator',
    SCHEDULE_GENIE: 'Schedule Genie',
    CHAT: 'Chat',
    TOTAL_UNIQUE: 'Total Unique',
    CHATBOT_AI: 'Claire AI+',
    LIVE_CHAT: 'Live Chat',
};
