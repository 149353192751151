import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Alert, Button, Col, Glyphicon, PageHeader, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { dateFormatter, defaultFormatter } from '../../util/formatters';
import { Company, InjectedProps } from '../../types';
import DefaultRoutes from '../../routes/DefaultRoutes';

interface CompanyListState {
    open: boolean;
    newItem?: Company;
}

@inject('store')
@observer
class CompanyList extends React.Component<{}, CompanyListState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state: CompanyListState = {
        open: false
    };

    public fetchData() {
        const { fetchCompanies } = this.injected.store;
        return fetchCompanies();
    }

    public componentDidMount() {
        const { router } = this.injected.store;

        if (router.params && router.params.addform) {
            this.setState({ open: true });
        }
        return this.fetchData();
    }

    public render() {
        const { companies, hasAccess } = this.injected.store;
        const { newItem } = this.state;
        const options = {
            onRowClick: this.handleRowClick
        };

        return (
            <React.Fragment>
                <PageHeader>Companies</PageHeader>
                <Col className="col-lg-12">
                    {newItem && (
                        <Alert bsStyle="success" onDismiss={this.handleDismissAlert}>
                            New company: "{newItem.name}", created successfully.
                        </Alert>
                    )}
                    <Row>
                        <Button
                            bsStyle="primary"
                            onClick={this.handleCreateClick}
                            disabled={!hasAccess('users.create')}
                        >
                            Create Company <Glyphicon glyph="plus" />
                        </Button>
                    </Row>
                    <Row>
                        <BootstrapTable data={companies.values} options={options} pagination search striped>
                            <TableHeaderColumn dataField="id" isKey width="60" dataSort>
                                ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="created_at" width="80" dataFormat={dateFormatter}>
                                Created
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="name" width="320" dataSort>
                                Company Name
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="website_url" width="190" dataFormat={this.urlFormatter}>
                                Website Url
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="crm_email" width="190" dataFormat={defaultFormatter}>
                                CRM Emails
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Row>
                </Col>
            </React.Fragment>
        );
    }

    public urlFormatter = (url: string) => {
        return (
            <a target="_blank" rel="noopener noreferrer" href={url} onClick={this.handleUrlFormatterClick}>
                {url}
            </a>
        );
    };

    public handleUrlFormatterClick = (e: React.SyntheticEvent) => {
        e.stopPropagation();
    };

    public handleRowClick = (row: Company) => {
        const { router } = this.injected.store;
        return router.goTo(DefaultRoutes.CompanyEdit, { companyId: row.id }, this.injected.store);
    };

    public handleCreateClick = () => {
        const { router } = this.injected.store;
        return router.goTo(DefaultRoutes.CompanyCreate, {}, this.injected.store);
    };

    public toggleNewForm = () => {
        this.setState({ open: !this.state.open });
    };

    public itemAdded = (company: Company) => {
        this.setState({ newItem: company });
        const { setActiveCompanyId } = this.injected.store;
        setActiveCompanyId(company.id);
        this.toggleNewForm();
    };

    public handleDismissAlert = () => {
        this.setState({ newItem: undefined });
    };
}

export default CompanyList;
