import React, { FC } from 'react';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { BarChart, PieChart, TimeChart } from '@lambdacurry/component-library';
import ChartRenderer from 'react-google-charts';
import classNames from 'classnames';
import { AppList, AppListItemContent, ChartCard } from '../../components-v2/shared';
import { Handle } from '../../components-v2/shared/dnd';
import { ClarityChartConfig, ClarityChartData, ClarityDataSource } from '../../constants';
import { ChartType } from '../../types';
import { ClarityDataGridChart } from './ClarityDataGridChart';
import { ClarityNumberReport } from './ClarityNumberReport';
import { ClarityDisplayMode } from './Clarity.types';
import './clarity-dashboard.scss';
import { ChartDataSets } from 'chart.js';
import { ClarityStatChart } from './ClarityStatChart';
import { customTooltip } from './Clarity.helpers';

export interface ClarityChartCardProps {
    config: ClarityChartConfig;
    displayMode: ClarityDisplayMode;
    data?: ClarityChartData;
    listeners?: DraggableSyntheticListeners;
    handle?: boolean;
    dragging?: boolean;
    dragOverlay?: boolean;
}

//Get value upto two decimal number without rounding off
const truncateTwoDecimals = (value: number, decNum: number = 1) => {
    if (!value) return 0;
    if (Math.floor(value) === value) return value;
    //splitnig the value on . and returning first two decimal number
    return (
        value?.toString().split('.')[0] +
        '.' +
        value
            ?.toString()
            .split('.')[1]
            .substring(0, decNum)
    );
};

const LegendComponent = ({ data }: { data: { labels: string[]; datasets: ChartDataSets | any } }) => {
    const total: number = data?.datasets[0]?.data?.reduce((previousValue: number, currentValue: number) => {
        return previousValue + currentValue;
    }, 0);
    return (
        <div className=" legend-component lc-pie-chart-legend">
            <ul className="lc-pie-chart-legend-list">
                {data?.labels?.map((label: string, index: number) => (
                    <li className="lc-pie-chart-legend-item" key={index}>
                        <div
                            className="lc-pie-chart-legend-color"
                            style={{ backgroundColor: data?.datasets[0]?.backgroundColor[index] }}
                        ></div>
                        <div className="lc-pie-chart-legend-label">
                            <span className="lc-pie-chart-legend-label-name">{label}</span>
                            <span className="lc-pie-chart-legend-label-percentage">
                                {`${truncateTwoDecimals((data?.datasets[0]?.data[index] / total) * 100)} %`}
                            </span>
                            <span className="lc-pie-chart-legend-label-total">{`(${data?.datasets[0]?.data[index] ? data?.datasets[0]?.data[index] : 0
                                })`}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const ClarityChartCard: FC<ClarityChartCardProps> = ({
    config,
    displayMode,
    data,
    listeners,
    handle,
    dragging,
    dragOverlay
}) => {

    if (config.dataSource === "live-chat-peak-hours" || config.dataSource === "chatbot-ai-peak-hours") {
        data?.datasets.forEach(({ data }) => {
            data.sort((a, b) => {
                // 'x':'00:00' should be at the zero index
                if (a.x === '00:00') {
                    return -1;
                } else if (b.x === '00:00') {
                    return 1;
                }

                // For other values, sort based on the 'x' property
                return a.x.localeCompare(b.x);
            });
        })
        const tooltips = {
            enabled: false,
            intersect: true,
            mode: 'index',
            position: 'average',
            custom: function (tooltipModel) {
                const position = (this as any)._chart.canvas.getBoundingClientRect();
                customTooltip(tooltipModel, position);
            }
        }
        config.options.tooltips = {
            ...tooltips
        }
    }
    return (
        <ChartCard
            title={data?.title || config.title}
            className={classNames('clarity-chart-card', { dragging, dragOverlay })}
            helpText={config.helpText}
            type={config.chartType}
            isEmpty={
                [ChartType.doughnut, ChartType.pie, ChartType.combo, ChartType.bubble].includes(config.chartType) &&
                Array.isArray(data?.datasets) &&
                !data?.datasets[0]
            }
        >
            {handle && <Handle {...listeners} />}

            {config.chartType === ChartType.custom && config.dataSource === ClarityDataSource.surveySummary ? (
                <div style={{ width: '100%' }}>
                    <AppList>
                        {Array.isArray(data?.datasets) && data?.datasets[0]
                            ? data?.datasets[0]?.map(
                                (
                                    data: {
                                        label: string;
                                        value: string;
                                        answer: [{ count: string; answer: string }];
                                    },
                                    index: React.Key | null | undefined
                                ) => {
                                    return (
                                        <AppListItemContent
                                            style={{ textAlign: 'left', width: '740px' }}
                                            key={index}
                                            title={`${data.label} (${data.value})`}
                                            description={data?.answer?.map(
                                                (item: { answer: string; count: string }) => {
                                                    return (
                                                        <>
                                                            <span>{`${item.answer} ${truncateTwoDecimals(
                                                                (Number(item.count) / Number(data.value)) * 100
                                                            )}% (${item.count})`}</span>
                                                            <br></br>
                                                        </>
                                                    );
                                                }
                                            )}
                                        />
                                    );
                                }
                            )
                            : 'No Data Found'}
                    </AppList>
                </div>
            ) : (
                ''
            )}

            {config.chartType === ChartType.combo &&
                config.useLegacyEngine &&
                Array.isArray(data?.datasets) &&
                data?.datasets[0] && (
                    <div style={{ width: '100%' }}>
                        <ChartRenderer
                            chartType="ComboChart"
                            width="100%"
                            height="400px"
                            rows={data.datasets}
                            columns={data.labels}
                            options={config.legacyOptions}
                            formatters={config.legacyOptions.formatters}
                        />
                    </div>
                )}

            {config.chartType === ChartType.bubble &&
                config.useLegacyEngine &&
                Array.isArray(data?.datasets) &&
                data?.datasets[0] && (
                    <div style={{ width: '100%' }}>
                        <ChartRenderer
                            chartType="BubbleChart"
                            width="100%"
                            height="400px"
                            rows={data.datasets}
                            columns={data.labels}
                            options={config.legacyOptions}
                            formatters={config.legacyOptions.formatters}
                        />
                    </div>
                )}

            {config.chartType === ChartType.doughnut && Array.isArray(data?.datasets) && data?.datasets[0] && (
                <>
                    <PieChart legendComponent={LegendComponent} type="doughnut" data={data.datasets[0]} />
                    {!!data?.totalValue && (
                        <div className="chart-card__total__value">{`${data?.totalValue?.label}:${data?.totalValue?.count}`}</div>
                    )}
                </>
            )}

            {config.chartType === ChartType.line && data?.datasets && (
                <TimeChart width={200} height={50} datasets={data.datasets} options={{ ...config.options }} />
            )}

            {config.chartType === ChartType.bar && data?.datasets && (
                <BarChart
                    width={200}
                    height={50}
                    datasets={data.datasets}
                    // NOTE: Bar charts require labels to be set, or else they throw and error and break the page.
                    // TODO: Consider handling this in the component library so that consuming projects aren't required to pass in labels separately.
                    labels={data.labels || data.datasets.map(({ label }) => label)}
                    options={{
                        borderRadius: 0,
                        barThickness: 30,
                        maxBarThickness: 30,
                        ...config.options,
                        indexAxis: 'y' as const
                    }}
                />
            )}

            {config.chartType === ChartType.table && data?.datasets && config.tableConfig?.columns && (
                <ClarityDataGridChart data={data.datasets} config={config} displayMode={displayMode} />
            )}

            {config.chartType === ChartType.number && data?.datasets && <ClarityNumberReport data={data.datasets} />}
            {config.chartType === ChartType.stats && data?.stats && <ClarityStatChart stats={data.stats} />}
        </ChartCard>
    );
};
