import React, { Ref, useEffect, useState, CSSProperties, ReactElement, memo, forwardRef } from 'react';
import { handlePromise, useAsyncEffect } from '@lambdacurry/component-library';
import { ClarityChartData, ClarityChartConfig } from '../../constants/clarity';
import useStore from '../../store/useStore';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import classNames from 'classnames';
import { Transform } from '@dnd-kit/utilities';
import axios from 'axios';
import { Filters } from '../Filters/Filters.helpers';
import { ClarityChartCard } from './ClarityChartCard';
import { ClarityDisplayMode } from './Clarity.types';

export interface Props {
    className: string;
    displayMode: ClarityDisplayMode;
    filters: Filters;
    dragOverlay?: boolean;
    color?: string;
    disabled?: boolean;
    dragging?: boolean;
    handle?: boolean;
    height?: number;
    index?: number;
    fadeIn?: boolean;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    sorting?: boolean;
    style?: CSSProperties;
    transition?: string | null;
    wrapperStyle?: CSSProperties;
    value: ClarityChartConfig;
    onRemove?(): void;
    renderItem?(args: {
        dragOverlay: boolean;
        dragging: boolean;
        sorting: boolean;
        index: number | undefined;
        fadeIn: boolean;
        listeners: DraggableSyntheticListeners;
        ref: Ref<HTMLElement>;
        style: CSSProperties | undefined;
        transform: Props['transform'];
        transition: Props['transition'];
        value: Props['value'];
    }): ReactElement;
}

export const ClarityDashboardChartCard = memo(
    forwardRef<HTMLLIElement, Props>(
        (
            {
                className,
                displayMode,
                filters,
                color,
                dragOverlay,
                dragging,
                disabled,
                fadeIn,
                handle,
                height,
                index,
                listeners,
                onRemove,
                renderItem,
                sorting,
                style,
                transition,
                transform,
                value,
                wrapperStyle,
                ...props
            },
            ref
        ) => {
            const [data, setData] = useState<ClarityChartData>();
            const { store } = useStore();
            const { Api } = store; // Note: we don't need an observer on this component, because we don't need to listen to changes for the Api client
            const cancelToken = axios.CancelToken;
            const cancelTokenSource = cancelToken.source();

            const fetchCardData = async () => {
                if (value.staticData) {
                    setData(value.staticData);
                    return;
                }

                const [response, error] = await handlePromise<{ data: ClarityChartData }>(
                    Api.client.post('/clarity/chart', {
                        filter: filters,
                        chartConfig: value,
                        cancelToken: cancelTokenSource.token
                    })
                );
                if (!response || error) {
                    return console.error('Error fetching charts', error);
                }

                setData(response.data);
            };
            useAsyncEffect(fetchCardData, cancelTokenSource.cancel, [filters]);

            useEffect(() => {
                if (!dragOverlay) {
                    return;
                }

                document.body.style.cursor = 'grabbing';

                return () => {
                    document.body.style.cursor = '';
                };
            }, [dragOverlay]);

            return (
                <li
                    className={classNames('clarity-dashboard-chart-grid-item', 'Wrapper', className, {
                        fadeIn,
                        sorting,
                        dragOverlay
                    })}
                    style={
                        {
                            ...wrapperStyle,
                            transition,
                            '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
                            '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                            '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
                            '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
                            '--index': index,
                            '--color': color
                        } as CSSProperties
                    }
                    ref={ref}
                >
                    <div
                        className={classNames('Item', {
                            dragging,
                            withHandle: handle,
                            dragOverlay,
                            disabled,
                            color
                        })}
                        style={style}
                        data-cypress="draggable-item"
                        {...props}
                        tabIndex={!handle ? 0 : undefined}
                    >
                        <ClarityChartCard
                            config={value}
                            data={data}
                            displayMode={displayMode}
                            listeners={listeners}
                            handle={handle}
                            dragging={dragging}
                            dragOverlay={dragOverlay}
                        />
                    </div>
                </li>
            );
        }
    )
);
