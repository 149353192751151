import React from 'react';
import { AppSectionHeader, ButtonPrimary } from '../../components-v2/shared';
import Resource from '../../store/Resource';
import { TwilioNumber } from '../../types';
import { CompanyDetailsState, EnablingSMSStatus } from './CompanyDetailsForm.helpers';
import { DateTime } from 'luxon';

export const CompanyDetailsSMSNumber: React.FC<{
    isCreateCompanyForm: boolean;
    state: CompanyDetailsState;
    twilioNumbers: Resource<TwilioNumber>;
    enableSMS: () => Promise<void>;
}> = ({ isCreateCompanyForm, state, twilioNumbers, enableSMS }) => {
    const twilioNumber: TwilioNumber | undefined = twilioNumbers.values.length ? twilioNumbers.values[0] : undefined;

    return isCreateCompanyForm ? (
        <>
            <AppSectionHeader title="SMS Number" />
            <p className="text">You can enable a Twilio SMS number here once the company has been created.</p>
        </>
    ) : (
        <>
            {state.enablingSMSStatus === EnablingSMSStatus.LOADING && (
                <>
                    <AppSectionHeader title="SMS Number" />
                    <p className="text">Loading...</p>
                </>
            )}
            {twilioNumber && (
                <>
                    <AppSectionHeader title="SMS Number" />
                    <p className="text">{twilioNumber.phone_num_friendly}</p>
                    {!twilioNumber.active && twilioNumber.deleted_at && (
                        <p className="text">
                            Will be deleted after{' '}
                            <span className="color-dark-red">
                                {DateTime.fromISO(twilioNumber.deleted_at).toLocaleString(DateTime.DATETIME_FULL)}
                            </span>
                        </p>
                    )}
                </>
            )}
            {twilioNumbers.values.length === 0 &&
                (state.enablingSMSStatus === EnablingSMSStatus.WAITING ||
                    state.enablingSMSStatus === EnablingSMSStatus.ENABLING) && (
                    <>
                        <AppSectionHeader title="SMS Number">
                            <ButtonPrimary
                                onClick={enableSMS}
                                disabled={state.enablingSMSStatus === EnablingSMSStatus.ENABLING}
                            >
                                {state.enablingSMSStatus === EnablingSMSStatus.ENABLING
                                    ? 'Enabling...'
                                    : 'Enable an SMS Number'}
                            </ButtonPrimary>
                        </AppSectionHeader>
                        <p className="text">
                            Enable a Twilio SMS number to text leads valuable information and appointment details. Only
                            customers who opt in will receive messages. Adding an SMS Twilio number starts a monthly
                            subscription.
                        </p>
                    </>
                )}
        </>
    );
};
