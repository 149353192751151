import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import './avatar.scss';

export interface AvatarProps extends HTMLAttributes<HTMLDivElement> {
    img?: string | null;
}

export const Avatar: FC<AvatarProps> = ({ img, className, ...props }) => (
    <div className={classNames('avatar', { 'avatar-has-img': img }, className)} {...props}>
        {img && <img src={img} alt="avatar" />}
        {!img && <>{props.children}</>}
    </div>
);

export const MenuItemAvatar: FC<AvatarProps> = ({ img, className, ...props }) => (
    <div className={classNames('avatar', { 'avatar-has-img': img }, className)} {...props}>
        {props.children}
    </div>
);