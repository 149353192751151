import classNames from 'classnames';
import React from 'react';
import './step-checklist.scss';
import { ReactComponent as EmptyCheck } from './empty-check.svg';
import { ReactComponent as CompletedCheck } from './completed-check.svg';

export const StepChecklist: React.FC<{ className?: string; steps: string[]; currentStepIndex: number }> = ({
    className,
    steps,
    currentStepIndex
}) => {
    return (
        <ul className={classNames('step-checklist', className)}>
            {steps.map((step, index) => {
                const current = currentStepIndex === index;
                const completed = index < currentStepIndex;

                return (
                    <li
                        className={classNames('step-checklist-item', {
                            current,
                            completed
                        })}
                        key={`${step}_${index}`}
                    >
                        <div className="step-checklist-item-check">
                            {completed ? <CompletedCheck /> : <EmptyCheck />}
                        </div>
                        {step}
                    </li>
                );
            })}
        </ul>
    );
};
