import * as React from 'react';
import { FormControl, FormControlProps, InputGroup } from 'react-bootstrap';
import { ChromePicker } from 'react-color';
import getContrastRatio from 'get-contrast-ratio';

interface ColorPickerProps extends FormControlProps {
    color: string;
    name: string;
    handleChange: (value: string) => void;
    handleOnFocus?: () => any;
    contrastColor?: string;
    contrastRatio?: number;
}

const DEFAULT_COLOR = '#FFF';
export class ColorPicker extends React.Component<ColorPickerProps, { open: boolean }> {
    public state = {
        open: false
    };
    protected wrapperRef: any;

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public onFocus = () => {
        if (this.props.handleOnFocus) {
            this.props.handleOnFocus();
        }
    };

    public render() {
        const { label, name, disabled, color, contrastColor, contrastRatio } = this.props;
        const fullPercent = 100;
        const twentyPercent = 20;
        let RatioSuccess = 4.5;

        if (contrastRatio) {
            RatioSuccess = contrastRatio;
        }

        const RatioWarning = RatioSuccess - (RatioSuccess * twentyPercent) / fullPercent;
        let currentRatio: number = 1;
        let ratioClassname = 'contract-ok';

        if (contrastColor) {
            try {
                currentRatio = getContrastRatio(contrastColor, color);
            } catch {
            }

            if (currentRatio >= RatioWarning && currentRatio < RatioSuccess) {
                ratioClassname = 'contract-warning';
            } else if (currentRatio < RatioWarning) {
                ratioClassname = 'contract-error';
            }
        }

        return (
            <div className="form-group col-xs-12" ref={this.setWrapperRef}>
                <label>{label}</label>
                <InputGroup disabled={disabled}>
                    <FormControl
                        name={name}
                        value={color}
                        onChange={this.handleChange}
                        onFocus={this.onFocus}
                        disabled={disabled}
                    />
                    <InputGroup.Addon onClick={this.togglePicker} disabled={disabled}>
                        <div className="color-picker-preview" style={{ background: color }} />
                    </InputGroup.Addon>
                </InputGroup>
                {contrastColor ? (
                    <label>
                        Ratio: <span className={ratioClassname}>{currentRatio}</span> (check with: {contrastColor})
                    </label>
                ) : null}

                <div className={this.state.open ? 'popover-picker-open' : 'popover-picker-closed'}>
                    <ChromePicker color={color || DEFAULT_COLOR} onChange={this.handleChangePickerColor} />
                </div>
            </div>
        );
    }

    public handleChange = (e: any) => {
        this.props.handleChange(e.target.value);
    };

    public handleChangePickerColor = (colorData: any) => {
        this.props.handleChange(colorData.hex);
    };

    public togglePicker = () => {
        if (!this.props.disabled) {
            this.onFocus();
            this.setState({ open: !this.state.open });
        }
    };

    public setWrapperRef = (node: any) => {
        this.wrapperRef = node;
    };

    public handleClickOutside = (event: any) => {
        if (this.state.open && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ open: false });
        }
    };
}

export default ColorPicker;
