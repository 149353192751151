import React, { FC, HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { ButtonProps, ButtonUnstyled } from '@lambdacurry/component-library';
import useStore from '../../../store/useStore';

export interface SideNavSubmenuItemProps extends HTMLAttributes<HTMLLIElement> {
    label?: string;
    onClick?: ButtonProps['onClick'];
    route?: any;
}

export const SideNavSubmenuItem: FC<SideNavSubmenuItemProps> = observer(
    ({ className, label, onClick, route, hidden, ...props }) => {
        const { store } = useStore();
        const { router } = store;
        const { params, goTo, currentView } = router;

        const isActiveRoute = () => (route && currentView) && (route.displayName === currentView.displayName || currentView.displayName === (route.displayName));

        const handleClick: ButtonProps['onClick'] = event => {
            if (route) {
                goTo(route, params, store);
            }

            if (onClick) {
                onClick(event);
            }
        };

        if (hidden) {
            return null;
        }

        const displayName = label || route?.displayName;

        return (
            <li
                className={classNames(
                    'side-nav-submenu-item',
                    { 'side-nav-submenu-item-is-active': isActiveRoute() },
                    className
                )}
                {...props}
            >
                <ButtonUnstyled className="side-nav-submenu-item-button" onClick={handleClick} data-test-id={displayName + '_subMenuItemButton'}>
                    <span className="side-nav-submenu-item-label">{displayName}</span>
                </ButtonUnstyled>
            </li>
        );
    }
);
