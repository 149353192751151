import React, { useState } from 'react';
import {
    ActionList,
    AppContent,
    AppFooter,
    AppHeader,
    AppSectionHeader,
    Button,
    ButtonPrimary,
    Form,
    AppPage,
    InputTextarea,
    useSnackbar,
} from '../../components-v2/shared';
import { ChatBotAIWidgetCodeValidationSchema } from './ChatBotAI.validation';
import useStore from '../../store/useStore';
import { getChatbotWidgetId, returnAsArray, getWidgetCode } from './ChatbotAI.helpers';
import { FormikHelpers } from 'formik';
import { unsavedChangesContainerSelectorDefault } from '../../constants';
import { CompanyRoutes } from '../../routes/CompanyRoutes';
import { useAsyncEffect } from '../../util/async';

interface ChatbotAIFormValues {
    widget_id: string;
}

export const UpdateWidgetCode = () => {
    const [loading, setLoading] = useState<boolean>(true);

    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, chatbotAI, fetchChatbotAI } = store;
    const { companyId } = router.params;
    const chatBotAiItems = chatbotAI.values.length ? chatbotAI.values[0] : null;

    const fetchRequiredData = async () => {
        setLoading(false);
        await fetchChatbotAI();
        setLoading(true);
    };
    useAsyncEffect(fetchRequiredData);

    const initialValues = () => {
        const [item] = returnAsArray(chatBotAiItems);

        if (item && item.widget_id) {
            return {
                ...item,
                widget_id: item?.widget_id && getWidgetCode(item.widget_id),
            }
        }
        return {
            ...chatbotAI.values[0]
        };
    };

    const formatDataToSave = (values: ChatbotAIFormValues) => {
        const items = returnAsArray(chatBotAiItems);
        const id = getChatbotWidgetId(values.widget_id);

        return {
            active: items[0]?.active,
            company_id: items[0]?.company_id,
            greeting: items[0]?.greeting,
            call_to_action: items[0]?.call_to_action,
            widget_id: id,
        }

    }

    const handleWidgetCodeUpdate = async (
        values: ChatbotAIFormValues,
        actions: FormikHelpers<ChatbotAIFormValues>
    ) => {
        try {
            const data = formatDataToSave(values);
            await chatbotAI.updateChatWidget(data);
            addSnackbar('Claire AI+ widget code has been updated.', { variant: 'success' });
            router.goTo(CompanyRoutes.LiveChatAIManualLeadSubmission, { companyId }, store);
        } catch (error) {
            addSnackbar(error.message, { variant: 'error' });
            actions.setErrors(error.response.data);
        }
    };

    return (
        <AppPage>
            <AppHeader
                title="Chat With Claire AI+"
                icon={{ name: 'chat', color: 'blue' }}
                onBack={() => router.goTo(CompanyRoutes.LiveChatAIManualLeadSubmission, { companyId }, store)}
            />
            {
                loading
                &&
                <Form
                    validationSchema={ChatBotAIWidgetCodeValidationSchema}
                    initialValues={initialValues()}
                    onSubmit={handleWidgetCodeUpdate}
                    confirmUnsavedChanges
                    unsavedChangesConfig={{
                        containerQuerySelectorAll: unsavedChangesContainerSelectorDefault,
                    }}
                >
                    {(formikProps) => (
                        <>
                            <AppContent className="field-group-content">
                                <AppSectionHeader title="Update Claire AI+ Widget Code" />
                                <InputTextarea
                                    name="widget_id"
                                    multiline
                                    rows={4}
                                    draggable={false}
                                    formikProps={formikProps}
                                />
                            </AppContent>
                            <AppFooter>
                                <ActionList position="end">
                                    {formikProps.dirty && (
                                        <Button
                                            onClick={() => formikProps.resetForm()}
                                            disabled={formikProps.isSubmitting}
                                            data-lc-trigger-unsaved-changes={true}
                                            data-test-id="button_cancel"
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                    <ButtonPrimary
                                        type="submit"
                                        disabled={!formikProps.dirty || formikProps.isSubmitting}
                                        data-test-id="button_save"
                                    >
                                        Save
                                    </ButtonPrimary>
                                </ActionList>
                            </AppFooter>
                        </>
                    )}
                </Form>
            }
        </AppPage>
    )
}
