import React, { FC, useState } from 'react';
import { ReactComponent as Illustration } from '../Crm/EmptyCrmsIllustration.svg';
import {
    ActionList,
    AppContent,
    AppHeader,
    AppList,
    AppListItem,
    AppListItemContent,
    AppPage,
    ButtonPrimary,
    IconButton,
    AppListEmpty,
    Button,
    Icon,
    Modal,
    ModalActions,
    ModalHeader,
    useSnackbar
} from '../../components-v2/shared';
import { ApiKeyManager } from '../../types/ApiKeyManager';
import useStore from '../../store/useStore';
import { handlePromise, useAsyncEffect } from '@lambdacurry/component-library';
import moment from 'moment';
import DefaultRoutes from '../../routes/DefaultRoutes';

export const ApiKeyList: FC<{}> = ({}) => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { Api, activeCompany, router } = store;

    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [selectedKey, setSelectedKey] = useState<string>();

    const [apiKeys, setApiKeys] = useState<Array<ApiKeyManager>>();

    const fetchCompanyApiKeys = async () => {
        if (!activeCompany || !activeCompany.id) {
            addSnackbar(`No company selected. Cannot load company API Keys.`, { variant: 'warning' });
            router.goTo(DefaultRoutes.Home, { ...router.params }, store);

            return Promise.resolve();
        }
        const [response, err] = await handlePromise(Api.client.get(`api-key-manager/${activeCompany?.id}`));

        if (err) {
            // TODO: handle error
            return;
        }
        setApiKeys(response.data);
    };

    useAsyncEffect(fetchCompanyApiKeys);

    const deleteButtonHandler = async (key?: string) => {
        if (!activeCompany || !activeCompany.id) {
            addSnackbar(`No company selected. Cannot delete API Keys.`, { variant: 'warning' });
            router.goTo(DefaultRoutes.Home, { ...router.params }, store);

            return Promise.resolve();
        }
        const [result, err] = await handlePromise(Api.client.delete(`api-key-manager/${activeCompany?.id}/key/${key}`));
        if (err) {
            addSnackbar(`Failed to remove API Key.`, {
                variant: 'error'
            });
            return;
        }
        if (result) {
            addSnackbar(`API Key has been removed.`, {
                variant: 'success'
            });
        }
        await fetchCompanyApiKeys();
    };

    const newItemButtonHandler = async () => {
        const { generateNewCompanyApiKey } = store;
        const result = await generateNewCompanyApiKey();
        if (result) {
            addSnackbar(`New API Key has been generated.`, {
                variant: 'success'
            });
        }

        await fetchCompanyApiKeys();
    };

    const createNewApiKeyButton = (
        <ButtonPrimary onClick={() => newItemButtonHandler()}>Generate new API Key</ButtonPrimary>
    );

    return (
        <AppPage>
            <AppHeader title="API Key Manager">
                <ActionList position="end">{createNewApiKeyButton}</ActionList>
            </AppHeader>
            <AppContent>API Keys give 3rd party applications and integration access to your TCC data.</AppContent>
            <AppContent>
                {Array.isArray(apiKeys) &&
                    (!apiKeys?.length ? (
                        <AppListEmpty
                            illustration={<Illustration />}
                            title="Generate New API Key"
                            action={createNewApiKeyButton}
                        />
                    ) : (
                        <AppList>
                            {apiKeys.map(({ api_key, created_at }: ApiKeyManager, index: number) => (
                                <AppListItem key={index}>
                                    <AppListItemContent title={api_key} />
                                    <AppListItemContent title={moment(created_at).format('llll')} />
                                    <ActionList position="end">
                                        <IconButton
                                            icon="trash"
                                            onClick={() => {
                                                setSelectedKey(api_key);
                                                setDeleteModalOpen(true);
                                            }}
                                        />
                                    </ActionList>
                                </AppListItem>
                            ))}
                        </AppList>
                    ))}
            </AppContent>
            <Modal isOpen={deleteModalOpen} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="color-danger" />
                            Are you sure you want to remove this API Key?
                        </>
                    }
                />
                <p>This action cannot be undone.</p>
                <ModalActions>
                    <ActionList position="end">
                        <Button
                            onClick={() => {
                                setDeleteModalOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <ButtonPrimary
                            onClick={() => {
                                deleteButtonHandler(selectedKey);
                                setDeleteModalOpen(false);
                            }}
                        >
                            Confirm
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>
        </AppPage>
    );
};
