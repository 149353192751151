import * as React from 'react';
import moment from 'moment';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { BootstrapTable, BootstrapTableProps, TableHeaderColumn } from 'react-bootstrap-table';
import { dateTimeFormats } from '../../../constants';
import { QueryParams } from '../../../store/Resource';
import { InjectedProps, TableOrderSort, FilterCommon, ListCommon } from '../../../types';
import CommonFilter from './CommonFilter';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

abstract class CommonList extends React.Component {
    public abstract getCsvFile(args: QueryParams): Promise<any>;
    public abstract fetchData(
        listCode: string,
        filterCode: string,
        listDefault: ListCommon,
        filterDefault: FilterCommon
    ): Promise<void>;

    public abstract filterCode: string; // leave empty value '' to use the same filter for project
    public abstract listCode: string;

    public listDefault: ListCommon = {
        page: 1,
        pageSize: 10,
        order: 'created_at.desc'
    };

    public filterDefault: FilterCommon = {};

    public state = {
        data: [],
        loading: true,
        total: 0,
        isLoading: false
    };

    public get injected() {
        return this.props as InjectedProps;
    }

    public componentDidMount() {
        return this.updateData();
    }

    public async updateData() {
        this.setState({ loading: true });

        return this.fetchData(this.listCode, this.filterCode, this.listDefault, this.filterDefault);
    }

    public tableOptions(args?: any): BootstrapTableProps {
        const { getListSetting } = this.injected.store;
        const list = getListSetting(this.listCode, this.listDefault);
        const [orderSort, ...orderNameArray] = list.order ? list.order.split('.').reverse() : ['', undefined];
        const orderName = orderNameArray.reverse().join('.');

        return {
            pagination: true,
            remote: true,
            search: false,
            multiColumnSearch: true,
            striped: true,
            data: this.state.data,
            fetchInfo: {
                dataTotalSize: this.state.total
            },
            options: {
                page: list.page,
                sizePerPage: list.pageSize,
                defaultSortName: orderName,
                defaultSortOrder: orderSort ? (orderSort as TableOrderSort) : undefined,
                onPageChange: this.onPageChange,
                onSizePerPageList: this.sizePerPageListChange,
                onSortChange: this.onSortChange,
                onRowClick: args && args.handleRowClick
            }
        };
    }

    public render() {
        return (
            <>
                <PageHeader>Common List</PageHeader>
                <Col className="col-lg-12">
                    <Row>
                        {this.createCustomExportCSVButton()}
                        <CommonFilter
                            filterCode={this.filterCode}
                            applyFilter={this.applyFilter}
                            hidePMCFilter={true}
                        />
                        {!this.state.loading ? (
                            <BootstrapTable {...this.tableOptions()}>
                                <TableHeaderColumn dataField="id" isKey hidden />
                            </BootstrapTable>
                        ) : null}
                    </Row>
                </Col>
            </>
        );
    }

    protected companyNameFormatter = (cell: any) => {
        const { accessibleCompanies, accessibleCompaniesIndex } = this.injected.store;
        return accessibleCompanies[accessibleCompaniesIndex[cell]].name;
    };

    public dateFormat = (cell: any) => {
        return moment(cell).format(dateTimeFormats.leadDateTime);
    };

    protected sizePerPageListChange = (pageSize: number) => {
        const { changeListSetting } = this.injected.store;
        changeListSetting(this.listCode, { pageSize, page: 1 });

        return this.updateData();
    };

    protected onPageChange = (page: number, pageSize: number) => {
        const { changeListSetting } = this.injected.store;
        changeListSetting(this.listCode, { pageSize, page });

        return this.updateData();
    };

    protected applyFilter = () => {
        return this.updateData();
    };

    protected onSortChange = (orderName: string, orderSort: 'asc' | 'desc' | undefined) => {
        const { changeListSetting } = this.injected.store;

        changeListSetting(this.listCode, { page: 1, order: `${orderName}.${orderSort}` });

        return this.updateData();
    };

    protected createCustomExportCSVButton = () => {
        return (
            <div className="leads-list-buttons-wrapper">
                <div>
                    <button
                        onClick={this.handleExportCSVButtonClick}
                        className="btn btn-success react-bs-table-csv-btn hidden-print"
                        disabled={this.state.isLoading}
                    >
                        <span>
                            {this.state.isLoading ? (
                                <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                            ) : (
                                <>
                                    <i className="fa glyphicon glyphicon-export fa-download" /> Export to CSV
                                </>
                            )}
                        </span>
                    </button>
                </div>
            </div>
        );
    };

    protected handleExportCSVButtonClick = () => {
        if (this.state.isLoading) {
            alert('You cannot export data until the page has finished loading.');
            return;
        }
        if (this.state.total > 1000) {
            alert(
                'You may only export up to 1,000 leads at a time. Please adjust your filters and date range accordingly.'
            );
            return;
        }
        const { activeCompanyId, getFilterSetting, getListSetting } = this.injected.store;
        this.setState({ isLoading: true });

        const list = getListSetting(this.listCode, this.listDefault);
        const args = {
            filters: {
                timeZone: moment.tz.guess(),
                ...getFilterSetting(this.filterCode, this.filterDefault)
            },
            order: list.order
        } as QueryParams;

        if (activeCompanyId && args.filters) {
            args.filters.company_id = activeCompanyId;
        }
        const res = this.getCsvFile(args);
        res.then(() => {
            this.setState({ isLoading: false });
        }).catch(() => {
            return;
        });
        return res;
    };
}

export default CommonList;
