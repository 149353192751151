import * as Yup from 'yup';
import { fieldSchemas } from '../../../util/validations/formSchemas';

export const AgencySubdomainValidationSchema = () =>
    Yup.object().shape({
        subdomain: Yup.string().required(`Please enter a valid subdomain.`)
    });

export const AgencyEmailSettingsValidationSchema = () =>
    Yup.object().shape({
        reply_to_email: fieldSchemas.email.nonRequired,
        sender_email: fieldSchemas.email.nonRequired
    });

export const AgencyConfigureDomainFormValidationSchema = () =>
    Yup.object().shape({
        domain: Yup.string()
            .nullable()
            .trim()
            .matches(
                /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+\.*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/,
                'Please enter a valid domain.'
            )
            .required(`Please enter a valid domain.`),
        dashboard_subdomain: Yup.string()
            .nullable()
            .required('Please enter a dashboard subdomain.'),
        widget_subdomain: Yup.string()
            .nullable()
            .required('Please enter a app subdomain.')
    });
