export type IncomeCalculatorModals = 'none' | 'deleteIncomeCalculator' | 'floorplanDetails' | string;
export type IncomeCalculatorReducerNames = 'closeModal' | 'openModal';

export interface IncomeCalculatorReducerState {
    loading: boolean;
    modal: {
        active: IncomeCalculatorModals;
        data: any;
    };
}

export interface IncomeCalculatorReducerAction {
    name: keyof typeof incomeCalculatorReducers;
    payload?: any;
}

export const incomeCalculatorReducers = {
    closeModal: (state: IncomeCalculatorReducerState) => ({
        ...state,
        modal: { ...state.modal, active: 'none' }
    }),
    openModal: (state: IncomeCalculatorReducerState, modal: IncomeCalculatorReducerState['modal']) => ({
        ...state,
        modal
    }),
    setModalData: (state: IncomeCalculatorReducerState, data: IncomeCalculatorReducerState['modal']['data']) => ({
        ...state,
        modal: {
            ...state.modal,
            data
        }
    }),
    setLoading: (state: IncomeCalculatorReducerState, loading: IncomeCalculatorReducerState['loading']) => ({
        ...state,
        loading
    })
};

export const incomeCalculatorReducer = (
    state: IncomeCalculatorReducerState,
    { name, payload }: IncomeCalculatorReducerAction
) => {
    if (!incomeCalculatorReducers[name]) {
        throw new Error(`reducer ${name} not defined`);
    }

    return incomeCalculatorReducers[name](state, payload);
};

export const incomeCalculatorInitialState: IncomeCalculatorReducerState = {
    loading: true,
    modal: {
        active: 'none',
        data: null
    }
};

export const openModal = (
    dispatch: React.Dispatch<IncomeCalculatorReducerAction>,
    name: IncomeCalculatorModals,
    data?: IncomeCalculatorReducerState['modal']['data']
) => dispatch({ name: 'openModal', payload: { active: name, data } });

export const closeModal = (dispatch: React.Dispatch<IncomeCalculatorReducerAction>) => dispatch({ name: 'closeModal' });

export const setModalData = (
    dispatch: React.Dispatch<IncomeCalculatorReducerAction>,
    data: IncomeCalculatorReducerState['modal']['data']
) => dispatch({ name: 'setModalData', payload: data });

export const clearModalData = (dispatch: React.Dispatch<IncomeCalculatorReducerAction>) =>
    dispatch({ name: 'setModalData', payload: null });

export const setLoading = (
    dispatch: React.Dispatch<IncomeCalculatorReducerAction>,
    loading: IncomeCalculatorReducerState['loading']
) => dispatch({ name: 'setLoading', payload: loading });
