import {
    InputCheckbox,
    InputColor,
    InputSelect,
    InputText,
    InputTextarea,
} from '@lambdacurry/component-library';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { AppCard, AppSectionHeader } from '../../../../components-v2/shared';
import {
    colorPickerDefaults,
    labels,
    SectionProps,
    widgetSizeOptions,
    widgetSkinOptions,
} from '../../ApplicationSettings.helpers';
import './styling-section.scss';

export const StylingSection: FC<SectionProps> = observer(({ formikProps, formIsDisabled }) => {

    return (
        <>
            <AppCard className="styling-section">
                <AppSectionHeader title="Brand Colors" />

                <div className="field-row">
                    {colorPickerDefaults.map(({ fieldName }) => (
                        <InputColor
                            key={fieldName}
                            name={fieldName}
                            label={labels[fieldName]}
                            formikProps={formikProps}
                            disabled={formIsDisabled}
                        />
                    ))}
                </div>
                {formikProps.errors['contrast'] && (
                    <div className="styling-section-contrast-error">{formikProps.errors['contrast']}</div>
                )}

                <AppSectionHeader title="Style" />
                <InputSelect
                    label="Desktop: Container Theme"
                    name="skin"
                    options={widgetSkinOptions}
                    formikProps={formikProps}
                    disabled={formIsDisabled}
                    optionValueKey="value"
                />
                <InputSelect
                    label="Mobile: Container Theme"
                    name="skin_mobile"
                    options={widgetSkinOptions}
                    formikProps={formikProps}
                    disabled={formIsDisabled}
                    optionValueKey="value"
                />
                {[formikProps.values.skin, formikProps.values.skin_mobile].includes('default') && (
                    <InputSelect
                        label="App Size (only applies to Default Skin)"
                        name="widget_size"
                        options={widgetSizeOptions}
                        formikProps={formikProps}
                        disabled={formIsDisabled}
                        optionValueKey="value"
                        value={widgetSizeOptions.filter((size) => size.value === 'large')[0]}
                    />
                )}
                <InputSelect
                    label="Desktop: Collapse Threshold"
                    name="collapse_threshhold"
                    optionLabelKey="label"
                    options={[
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                        { label: '3', value: 3 },
                        { label: '4', value: 4 },
                    ]}
                    formikProps={formikProps}
                    disabled={formIsDisabled}
                    optionValueKey="value"
                />
                <InputSelect
                    label="Mobile: Collapse Threshold"
                    name="collapse_threshhold_mobile"
                    optionLabelKey="label"
                    options={[
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                        { label: '3', value: 3 },
                        { label: '4', value: 4 },
                    ]}
                    formikProps={formikProps}
                    disabled={formIsDisabled}
                    optionValueKey="value"
                />
                <em>The Collapse Threshhold is the maximum number of apps to display before stacking them.</em>
                <hr />
                <InputCheckbox
                    name="widgetbar_expanded"
                    className="clx-margin-bottom-16"
                    label="Expanded by default"
                    labelPlacement="end"
                    formikProps={formikProps}
                    disabled={formIsDisabled}
                />

                {formikProps.values.skin === 'skin1' && (
                    <>
                        <AppSectionHeader title="Application Message" />
                        <InputText
                            name="wallet_greeting_heading"
                            label="Greeting"
                            formikProps={formikProps}
                            disabled={formIsDisabled}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Hello!"
                        />
                        <InputTextarea
                            name="wallet_greeting_subheading"
                            label="Message to Customer"
                            formikProps={formikProps}
                            disabled={formIsDisabled}
                            characterLimit={100}
                            InputLabelProps={{ shrink: true }}
                            placeholder="We're committed to making you feel at home before you've even stepped through the door. Use the tools below to get started."
                        />
                    </>
                )}
            </AppCard>
        </>
    );
});
