import * as React from 'react';
import { Button, ButtonToolbar, Glyphicon } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export interface ControlButtonGroupProps {
    children?: React.ReactNode;
    editing: boolean;
    canEdit: boolean;
    hideEdit?: boolean;
    canDelete: boolean;
    handleEdit?: () => void;
    handleBack?: () => void;
    handleValidate?: () => void;
    hideBack?: boolean;
    handleDelete?: () => void;
    handleCancel?: () => void;
    isSaving?: boolean;
    dirty?: boolean;
    showReturnToMsg?: boolean;
    handleReturnToMsg?: () => void;
}

export function ControlButtonGroup(props: ControlButtonGroupProps) {
    const disableSave = props.dirty === undefined ? false : !props.dirty;

    return (
        <ButtonToolbar className="control-button-group">
            {!props.editing && (
                <>
                    {props.showReturnToMsg && props.handleReturnToMsg ? (
                        <Button bsStyle="default-gray" onClick={props.handleReturnToMsg} disabled={!props.canEdit}>
                            Return to Messages
                        </Button>
                    ) : null}
                    {!props.hideEdit ? (
                        <Button bsStyle="primary" onClick={props.handleEdit} disabled={!props.canEdit}>
                            Edit
                        </Button>
                    ) : null}
                    {props.handleValidate && (
                        <Button bsStyle="success" onClick={props.handleValidate}>
                            Validate
                        </Button>
                    )}
                    {!props.hideBack ? (
                        <Button bsStyle="default" onClick={props.handleBack}>
                            Back
                        </Button>
                    ) : null}
                </>
            )}
            {props.editing && (
                <>
                    <Button bsStyle="success" type="submit" disabled={disableSave}>
                        {props.isSaving ? <FontAwesomeIcon icon={faSpinner} spin size="lg" /> : 'Save'}
                    </Button>
                    {props.handleCancel ? (
                        <Button bsStyle="warning" onClick={props.handleCancel}>
                            Cancel
                        </Button>
                    ) : (
                            <Button bsStyle="warning" type="reset">
                                Cancel
                            </Button>
                        )}
                </>
            )}
            {props.children}
            <Button bsStyle="danger" onClick={props.handleDelete} hidden={!props.canDelete} className="pull-right">
                Delete <Glyphicon glyph="remove" />
            </Button>
        </ButtonToolbar>
    );
}

export default ControlButtonGroup;
