import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Illustration } from './OnboardingIllustration1.svg';
import * as Yup from 'yup';
import styles from './company-onboarding.module.scss';
import './company-onboarding.animation.scss';
import './company-onboarding.global.scss';
import { ButtonPrimary, Form, InputText, Title } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { observer } from 'mobx-react';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { merge } from 'lodash';
import { handlePromise } from '../../util/async';
import { FormikHelpers, FormikProps } from 'formik';
export interface CompanyOnboardingCredentials {
    user: {
        first_name: string;
        last_name: string;
        email: string;
        pass: string;
        confirm_pass: string;
    };
}

export const CompanyOnboardingCredentialsPage: React.FC<{}> = observer(() => {
    const [unavailableEmails, setUnavailableEmails] = useState<string[]>([]);
    const [checkingEmail, setCheckingEmail] = useState(false);
    const { agencyStore, store } = useStore();
    const { companyOnboardingFormData, updateCompanyOnboardingFormData } = agencyStore;
    const { router } = store;
    const {
        params: { token }
    } = router;

    const checkEmailAvailability = async (
        email: string,
        formikProps: FormikProps<CompanyOnboardingCredentials> | FormikHelpers<CompanyOnboardingCredentials>
    ) => {
        // Make sure we don't call this more once at a time.
        if (checkingEmail) {
            return;
        }

        // Don't call the API if we already have the email in the list.
        if (!unavailableEmails.includes(email)) {
            setCheckingEmail(true);

            const [response, errors] = await handlePromise(
                store.Api.client.post('/company-invite/check-email', { email, token })
            );

            setCheckingEmail(false);

            if (errors) {
                console.error(errors);
                throw new Error('Email check failed.');
            }

            // If user exists, update the unavailable emails list.
            if (response.data.user_exists) {
                setUnavailableEmails([...unavailableEmails, email]);
            }
        }

        // Trigger form validation so that the field error will show.
        formikProps.validateForm();
    };

    const handleSubmit = async (
        formValues: CompanyOnboardingCredentials,
        formikHelpers: FormikHelpers<CompanyOnboardingCredentials>
    ) => {
        await checkEmailAvailability(formValues.user.email, formikHelpers);
        updateCompanyOnboardingFormData({ formValues });
        router.goTo(DefaultRoutes.CompanyOnboardingCompanyDetailsPage, router.params, store);
    };

    const handleEmailInputBlur = async (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
        formikProps: FormikProps<CompanyOnboardingCredentials>
    ) => {
        try {
            const email = event.target.value;
            await checkEmailAvailability(email, formikProps);
        } catch (error) {
            console.error(error);
        }
    };

    const companyOnboardingCredentialsFormSchema = Yup.object().shape({
        user: Yup.object().shape({
            first_name: Yup.string().required('Please enter your first name.'),
            last_name: Yup.string().required('Please enter your last name.'),
            email: Yup.string()
                .email('Please enter a valid email address.')
                .test({
                    name: 'email-is-available',
                    test: value => !unavailableEmails.length || !unavailableEmails.includes(value),
                    message:
                        `The email address is already in use. Please use a different email address.`
                })
                .required('Please enter your email.'),
            pass: Yup.string().required('Please enter a password.'),
            confirm_pass: Yup.string().oneOf([Yup.ref('pass'), null], 'Your passwords do not match.')
        })
    });

    return (
        <div
            className={classNames('company-onboarding', styles.companyOnboarding, styles.companyOnboardingCredentials)}
        >
            <header>
                <h1>You’re a few clicks away from converting more customers.</h1>
                <p>We just need a little bit more information to get started!</p>
                <Illustration className={classNames(styles.companyOnboardingIllustration)} />
            </header>
            <main className={styles.companyOnboardingContent}>
                <Title>Create your account</Title>
                <Form
                    enableReinitialize
                    initialValues={{
                        user: merge(
                            {
                                first_name: '',
                                last_name: '',
                                email: '',
                                pass: '',
                                confirm_pass: ''
                            },
                            companyOnboardingFormData.formValues.user
                        )
                    }}
                    validationSchema={companyOnboardingCredentialsFormSchema}
                    onSubmit={handleSubmit}
                    initialErrors={companyOnboardingFormData.errors.user}
                >
                    {(formikProps: FormikProps<CompanyOnboardingCredentials>) => (
                        <>
                            <div className={styles.companyOnboardingFormRow}>
                                <InputText
                                    labelPlacement="above"
                                    label="First Name"
                                    name="user.first_name"
                                    formikProps={formikProps}
                                />
                                <InputText
                                    labelPlacement="above"
                                    label="Last Name"
                                    name="user.last_name"
                                    formikProps={formikProps}
                                />
                            </div>

                            <InputText
                                labelPlacement="above"
                                label="Email"
                                name="user.email"
                                formikProps={formikProps}
                                onBlur={event => handleEmailInputBlur(event, formikProps)}
                            />

                            <div className={styles.companyOnboardingFormRow}>
                                <InputText
                                    labelPlacement="above"
                                    label="Password"
                                    type="password"
                                    name="user.pass"
                                    formikProps={formikProps}
                                />
                                <InputText
                                    labelPlacement="above"
                                    label="Repeat Password"
                                    type="password"
                                    name="user.confirm_pass"
                                    formikProps={formikProps}
                                />
                            </div>

                            <div className={styles.companyOnboardingFormActions}>
                                <ButtonPrimary type="submit" disabled={checkingEmail}>
                                    Continue
                                </ButtonPrimary>
                            </div>
                        </>
                    )}
                </Form>
            </main>
        </div>
    );
});
