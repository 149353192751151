import * as Yup from 'yup';

export const AgencyValidationSchema = {
    create: () =>
        Yup.object().shape({
            agency: Yup.object().shape({
                name: Yup.string().nullable().required(`Please enter a partner name.`)
            })
        }),
    update: () =>
        Yup.object().shape({
            agency: Yup.object().shape({
                name: Yup.string().nullable().required(`Please enter a partner name.`)
            })
        })
};
