import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Alert, Col, PageHeader, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import CommonList from '../__deprecated/Filter/CommonList';
import CommonFilter from '../__deprecated/Filter/CommonFilter';
import { FilterCommon, ListCommon } from '../../types';
import DefaultRoutes from '../../routes/DefaultRoutes';

interface InsiteDefinedFieldsState {
    data: never[];
    loading: boolean;
    isLoading: boolean;
    total: number;
    stored?: boolean;
    show: boolean;
    content: any;
    titleModal: string;
}

interface DefinedFieldsHostData {
    id: number;
    company_id: number;
    host_uri: string;
    defined_fields: {};
    names_fields: string;
    created_at: string;
}

@inject('store')
@observer
class InsiteDefinedFieldsList extends CommonList {
    public listCode = 'leads-insite';
    public filterCode = '';
    public listDefault: ListCommon = {
        page: 2,
        pageSize: 11,
        order: 'host_uri.asc'
    };

    public state: InsiteDefinedFieldsState = {
        data: [],
        loading: true,
        isLoading: false,
        total: 0,
        show: false,
        content: '',
        titleModal: ''
    };

    public fetchData(listCode: string, filterCode: string, listDefault: ListCommon, filterDefault: FilterCommon) {
        const { fetchInsiteDefinedFields } = this.injected.store;

        return fetchInsiteDefinedFields(listCode, filterCode, listDefault, filterDefault).then((response: any) => {
            this.setState({
                data: response.data.widget_insite_defined_fields,
                total: response.data.count,
                loading: false
            });
        });
    }

    public getCsvFile = (args: any) => {
        const { Api } = this.injected.store;

        return Api.getCSVPagesInsite(args);
    };

    public render() {
        const { activeCompanyId, isAgencyRole } = this.injected.store;
        const hasActiveCompany = !!activeCompanyId;

        return (
            <>
                <PageHeader>Insite Defined Fields</PageHeader>
                <Col className="col-lg-12 wrapper-holder__tible-leads">
                    {this.state.stored === true && (
                        <Alert bsStyle="success" onDismiss={this.handleDismissAlert}>
                            Insite Page Exceptions have been changed successfully.
                        </Alert>
                    )}
                    {this.state.stored === false && (
                        <Alert bsStyle="error" onDismiss={this.handleDismissAlert}>
                            Insite Page Exceptions have not been changed.
                        </Alert>
                    )}
                    <Row>
                        <div className="colors-legend-wrapper holder-colors-legend">
                            <button className="btn btn-primary btn-right" onClick={this.goToInsiteMetricDataList}>
                                Insite Metric
                            </button>
                            <span>&nbsp;</span>
                            <button className="btn btn-primary btn-right" onClick={this.goToInsitePageView}>
                                Insite Pages
                            </button>
                        </div>

                        <CommonFilter
                            applyFilter={this.applyFilter}
                            hideDateFilter={true}
                            hidePMCFilter={true}
                            hideBCFilter={hasActiveCompany}
                            hideDMAFilter={hasActiveCompany}
                            hideOGFilter={hasActiveCompany}
                            hideRegionFilter={hasActiveCompany || isAgencyRole}
                            hideCompanyFilter={hasActiveCompany}
                            showSearchFilter={true}
                            listCode={this.listCode}
                            filterCode={this.filterCode}
                            hideLeadStateFilter={true}
                        />
                        {!this.state.loading ? (
                            <div className="insite-defined-holder">
                                <BootstrapTable {...this.tableOptions({ handleRowClick: this.handleRowClick })}>
                                    <TableHeaderColumn
                                        dataField="company_id"
                                        dataFormat={this.keyFormat}
                                        isKey
                                        hidden
                                    />
                                    <TableHeaderColumn
                                        dataField="company_id"
                                        dataFormat={this.companyNameFormatter}
                                        width="180"
                                        dataSort
                                        hidden={hasActiveCompany}
                                    >
                                        Company
                                    </TableHeaderColumn>
                                    <TableHeaderColumn className="str-table" dataField="host_uri" dataSort>
                                        Host
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="defined_fields" dataFormat={this.definedFieldsFormat}>
                                        Defined Fields
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        ) : null}
                    </Row>
                </Col>
            </>
        );
    }

    public goToInsiteMetricDataList = () => {
        const { activeCompanyId, router } = this.injected.store;
        const { activeAgencyId } = this.injected.store.agencyStore;

        if (activeCompanyId) {
            router.goTo(DefaultRoutes.CompanyInsiteMetricDataList, { companyId: activeCompanyId }, this.injected.store);
        } else if (activeAgencyId) {
            router.goTo(DefaultRoutes.AgencyInsiteMetricDataList, { agencyId: activeAgencyId }, this.injected.store);
        } else {
            router.goTo(DefaultRoutes.InsiteMetricDataList, {}, this.injected.store);
        }
    };

    public goToInsitePageView = () => {
        const { activeCompanyId, router } = this.injected.store;
        const { activeAgencyId } = this.injected.store.agencyStore;

        if (activeCompanyId) {
            router.goTo(DefaultRoutes.CompanyInsitePageList, { companyId: activeCompanyId }, this.injected.store);
        } else if (activeAgencyId) {
            router.goTo(DefaultRoutes.AgencyInsitePageList, { agencyId: activeAgencyId }, this.injected.store);
        } else {
            router.goTo(DefaultRoutes.InsitePageList, {}, this.injected.store);
        }
    };

    public keyFormat = (cell: any, row: any) => {
        return `${row.company_id}_${row.host_uri}`;
    };

    public definedFieldsFormat = (cell: any) => {
        if (!cell) {
            return '';
        }
        const list = Object.keys(cell).map((key: string) => {
            return (
                <li key={key}>
                    {key}: {cell[key] ? cell[key].join(', ') : null}
                </li>
            );
        });
        return <ul className="list-unstyled components">{list}</ul>;
    };

    protected onClose = () => {
        this.setState({ show: false });
    };

    protected handleRowClick = (row: DefinedFieldsHostData) => {
        const { router } = this.injected.store;
        const { activeAgencyId } = this.injected.store.agencyStore;

        if (activeAgencyId) {
            router.goTo(
                DefaultRoutes.AgencyInsiteDefinedFieldsEdit,
                { agencyId: activeAgencyId, id: row.id },
                this.injected.store
            );
        } else {
            router.goTo(DefaultRoutes.InsiteDefinedFieldsEdit, { id: row.id }, this.injected.store);
        }
    };

    private handleDismissAlert = () => {
        this.setState({ stored: undefined });
    };
}

export default InsiteDefinedFieldsList;
