import React, { Reducer, useReducer } from 'react';
import { observer } from 'mobx-react';
import {
    IconButton,
    AppHeader,
    AppPage,
    AppContent,
    AppList,
    AppListItem,
    AppListItemIcon,
    AppListItemContent,
    Button,
    Title,
    ButtonPrimary,
    Modal,
    ModalHeader,
    ModalActions,
    ButtonDanger,
    Form,
    InputText,
    ButtonUnstyled,
    ActionList,
    InputSwitch,
    useSnackbar
} from '../../components-v2/shared';
import { Link } from 'mobx-router';
import useStore from '../../store/useStore';
import styles from './list-segment-editor.module.scss';
import { handlePromise, useAsyncEffect } from '../../util/async';
import {
    ListSegmentEditorListReducerAction,
    ListSegmentEditorListReducerState,
    listSegmentEditorListReducer
} from './ListSegmentEditor.helpers';
import { FormikHelpers, FormikProps } from 'formik';
import { CreateListSegmentFormValidationSchema } from './ListSegmentEditor.validation';
import { ListSegment } from './ListSegmentEditor.types';
import DefaultRoutes from '../../routes/DefaultRoutes';

interface CreateListSegmentForm {
    name: string;
    active: boolean;
}

export const ListSegmentEditorListPage = observer(() => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { router, Api } = store;
    const { companyId } = router.params;

    const [state, dispatch] = useReducer<
        Reducer<ListSegmentEditorListReducerState, ListSegmentEditorListReducerAction>
    >(listSegmentEditorListReducer, {
        modal: {
            active: 'none',
            data: {}
        }
    });
    const { listSegments, modal } = state;

    const fetchListSegments = async () => {
        const [getListSegmentsResponse, getListSegmentsError] = await handlePromise<{
            data: { count: number; data: ListSegment[] };
        }>(Api.client.get(`company/${companyId}/list-segments`));

        if (getListSegmentsError || !getListSegmentsResponse) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setListSegments', payload: getListSegmentsResponse.data.data });
    };

    useAsyncEffect(fetchListSegments);

    const handleOpenModal = (modalName: string, modalData?: object) => {
        dispatch({ name: 'openModal', payload: modalName });

        if (modalData) {
            dispatch({ name: 'setModalData', payload: modalData });
        }
    };

    const handleCreateListSegment: (
        values: CreateListSegmentForm,
        formikHelpers: FormikHelpers<CreateListSegmentForm>
    ) => void = async ({ name }, formikHelpers) => {
        const newListSegment = {
            name,
            active: true,
            company_id: Number.parseInt(companyId, 10)
        };

        const [createListSegmentResponse, createListSegmentError] = await handlePromise<{ data: ListSegment }>(
            Api.client.post(`company/${companyId}/list-segments`, newListSegment)
        );

        if (createListSegmentError || !createListSegmentResponse) {
            // TODO: handle error
            return;
        }

        formikHelpers.resetForm();

        router.goTo(
            DefaultRoutes.EditListSegmentPage,
            { companyId, listSegmentId: createListSegmentResponse.data.id },
            store
        );
    };

    const handleDeleteListSegment = async (selectedListSegment?: ListSegment) => {
        if (!selectedListSegment) {
            return;
        }

        const [deleteListSegmentResponse, deleteListSegmentError] = await handlePromise<{
            data: ListSegment;
        }>(Api.client.delete(`company/${companyId}/list-segments/${selectedListSegment.id}`));

        if (deleteListSegmentError || !deleteListSegmentResponse) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'closeModal', payload: 'deleteListSegment' });

        await fetchListSegments();
    };

    const handleActiveToggle = async (selectedListSegmentId?: number) => {
        if (!selectedListSegmentId) {
            return;
        }

        const [toggleListSegmentResponse, toggleListSegmentError] = await handlePromise<{
            data: ListSegment;
        }>(Api.client.patch(`company/${companyId}/list-segments/${selectedListSegmentId}/toggle`));

        if (toggleListSegmentError || !toggleListSegmentResponse) {
            addSnackbar(toggleListSegmentError.response.data || 'Failed to toggle List Segment', {
                variant: 'error'
            });
            return;
        }

        //dispatch({ name: 'closeModal', payload: 'toggleListSegment' });

        await fetchListSegments();
    };

    return (
        <>
            <AppPage>
                <AppHeader title="List Segments">
                    <div className="flex-spacer" />
                    <ButtonPrimary onClick={() => handleOpenModal('createListSegment')}>
                        Create List Segment
                    </ButtonPrimary>
                </AppHeader>
                <AppContent loading={!listSegments} className={styles['segment-editor-list-page-content']}>
                    {listSegments && listSegments?.length > 0 && (
                        <AppList>
                            {listSegments
                                ?.map(listSegment => {
                                    const { id, name, active, list_size } = listSegment;

                                    return (
                                        <AppListItem key={id}>
                                            <AppListItemIcon color="green" name="schedule" />

                                            <AppListItemContent
                                                description={`List entries: ${list_size}`}
                                                title={
                                                    <ButtonUnstyled
                                                        as={buttonProps => (
                                                            <Link
                                                                {...buttonProps}
                                                                view={DefaultRoutes.EditListSegmentPage}
                                                                params={{ companyId, listSegmentId: id }}
                                                                store={store}
                                                            />
                                                        )}
                                                    >
                                                        {name}
                                                    </ButtonUnstyled>
                                                }
                                            />

                                            <ActionList position="end">
                                                <InputSwitch
                                                    onClick={() => handleActiveToggle(id)}
                                                    checked={!!active}
                                                />
                                                <IconButton
                                                    icon="pencil"
                                                    as={buttonProps => (
                                                        <Link
                                                            {...buttonProps}
                                                            view={DefaultRoutes.EditListSegmentPage}
                                                            params={{ companyId, listSegmentId: id }}
                                                            store={store}
                                                        />
                                                    )}
                                                />
                                                <IconButton
                                                    icon="trash"
                                                    onClick={() =>
                                                        handleOpenModal('deleteListSegment', {
                                                            selectedListSegment: listSegment
                                                        })
                                                    }
                                                />
                                            </ActionList>
                                        </AppListItem>
                                    );
                                })}
                        </AppList>
                    )}

                    {listSegments?.length === 0 && (
                        <div className={styles['segment-editor-list-page-empty']}>
                            <Title className={styles['segment-editor-list-page-empty-title']}>
                                Create your first Lead Nurturing List Segment!
                            </Title>
                        </div>
                    )}
                </AppContent>
            </AppPage>
            <Modal
                isOpen={modal.active === 'createListSegment'}
                onAfterClose={() => dispatch({ name: 'closeModal' })}
                closeButton={false}
            >
                <ModalHeader title="New List Segment" />
                <Form
                    initialValues={{
                        name: '',
                        active: true,
                    }}
                    onSubmit={handleCreateListSegment}
                    validationSchema={CreateListSegmentFormValidationSchema}
                >
                    {(formikProps: FormikProps<CreateListSegmentForm>) => {
                        return (
                            <>
                                <InputText
                                    autoFocus={true}
                                    name="name"
                                    label="List Segment Name"
                                    formikProps={formikProps}
                                />
                                <ModalActions>
                                    <div className="flex-spacer" />
                                    <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                                    <ButtonPrimary type="submit">Create List Segment</ButtonPrimary>
                                </ModalActions>
                            </>
                        );
                    }}
                </Form>
            </Modal>
            <Modal
                isOpen={modal.active === 'deleteListSegment'}
                onAfterClose={() => dispatch({ name: 'setModalData', payload: {} })}
                closeButton={false}
            >
                <ModalHeader title={`Delete List Segment`} />

                <p className="text">
                    Are you sure you want to delete{' '}
                    <strong className={styles['bold']}>{modal.data.selectedListSegment?.name}</strong>?
                </p>
                <ModalActions>
                    <div className="flex-spacer" />
                    <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                    <ButtonDanger onClick={() => handleDeleteListSegment(modal.data.selectedListSegment)}>
                        Delete List Segment
                    </ButtonDanger>
                </ModalActions>
            </Modal>
        </>
    );
});
