import React, { useReducer } from 'react';
import classNames from 'classnames';
import { ButtonOutlinePrimary, Icon, Tabs, useAsyncEffect } from '@lambdacurry/component-library';
import { ActionList, AppHeader, AppListEmpty, AppPage } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import MessageThreadsList from './MessageThreadsList';
import MessageThread from './MessageThread';
import { MessagesInitialState, MessagesReducer, selectThread } from './Messages.helpers';
import { useSnackbar } from '../../components-v2/shared';
import { observer } from 'mobx-react';
import './messages.scss';

export const Messages = observer(() => {
    const { store } = useStore();
    const [state, dispatch] = useReducer(MessagesReducer, MessagesInitialState);
    const { activeCompanyId, Api, router, smsThreads } = store;
    const messageIdFromRoute = router.params.messageId ? parseInt(router.params.messageId, 10) : undefined;
    const { addSnackbar } = useSnackbar();

    const fetchThreads = async () => {
        const { fetchSmsThreads, smsThreads } = store;
        await fetchSmsThreads();

        if (messageIdFromRoute) {
            const selectedThread = smsThreads.values.find(thread => thread.id === messageIdFromRoute) || null;

            selectThread(dispatch, selectedThread);

            if (selectedThread?.archived) {
                dispatch({ name: 'setSelectedTab', payload: 1 });
            }
        }
    };

    useAsyncEffect(fetchThreads);

    const saveBlobFromCsvData = (csvData: any, fileName: string): void => {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, 'sms-thread');
        } else {
            const link = document.createElement('a');
            link.download = fileName;
            link.href = URL.createObjectURL(blob);
            link.click();
        }
    };

    const exportAllMessages = async () => {
        try {
            const csvFile = await Api.getCsvAllThreadExport(activeCompanyId, false);

            if (!csvFile) {
                addSnackbar(`System couldn't export the message threads.`, { variant: 'error' });
                return;
            }

            saveBlobFromCsvData(csvFile.data, 'all-sms-thread.csv');
        } catch (error) {
            return;
        }
    };

    const isArchiveTab = state.selectedTab === 1;
    const isDownloadAllDisabled = smsThreads.values.filter(thread => !thread.archived).length === 0;

    return (
        <AppPage className="messages-page">
            <AppHeader title="Messages">
                <ActionList position="end">
                    {!isArchiveTab && activeCompanyId !== 0 && (
                        <ButtonOutlinePrimary
                            icon={<Icon name="download" />}
                            onClick={exportAllMessages}
                            disabled={isDownloadAllDisabled}
                            className="sms-download-all"
                            aria-label="Download All Messages"
                        >
                            Download all
                        </ButtonOutlinePrimary>
                    )}
                </ActionList>
            </AppHeader>

            <div className={classNames('messages', { 'messages-message-selected': state.messagePanelActive })}>
                <div className="messages-list">
                    <div className="messages-tabs">
                        <Tabs
                            value={state.selectedTab}
                            onChange={(_, value) => dispatch({ name: 'setSelectedTab', payload: value })}
                            tabs={[
                                {
                                    label: (
                                        <div>
                                            <Icon name="inbox" /> Inbox
                                        </div>
                                    ),
                                    render: (
                                        <MessageThreadsList
                                            threads={smsThreads.values.filter(thread => !thread.archived)}
                                            state={state}
                                            dispatch={dispatch}
                                        />
                                    )
                                },
                                {
                                    label: (
                                        <div>
                                            <Icon name="archive" /> Archived
                                        </div>
                                    ),
                                    render: (
                                        <MessageThreadsList
                                            threads={smsThreads.values.filter(thread => thread.archived)}
                                            state={state}
                                            dispatch={dispatch}
                                        />
                                    )
                                }
                            ]}
                        />
                    </div>
                </div>
                <div className="messages-thread">
                    {state.selectedThread ? (
                        <MessageThread
                            state={state}
                            dispatch={dispatch}
                            saveBlobFromCsvData={saveBlobFromCsvData}
                            updateThreads={fetchThreads}
                        />
                    ) : (
                        <AppListEmpty title="Select a message" className="messages-empty" />
                    )}
                </div>
            </div>
        </AppPage>
    );
});
