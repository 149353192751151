import React from 'react';
import {
    AppCard,
    AppCardDivider,
    AppCardHeader,
    AppSectionHeader,
    DescriptionList,
    DescriptionListItem,
    ListGroup,
    ListGroupItem,
    Panel
} from '../../../../components-v2/shared';
import { Icon } from '@lambdacurry/component-library';
import { DateTime } from 'luxon';
import { Lead } from '../../../../types/Lead';
import { moneyFormatter } from '../../../../util/formatters';
import { QuestionDefinitionAdHocTag } from '../../../../types/QuestionSettings';

export const AppEventBPN: React.FC<{ lead: Lead }> = ({ lead }) => {
    const data = lead.bpn;

    if (!data) {
        return null;
    }

    const ConditionIcon: React.FC<{ condition: string; className: string }> = ({ condition, className }) => {
        const conditionIconMap = {
            average: 'conditionAverage',
            'below average': 'conditionBelowAverage',
            excellent: 'conditionExcellent'
        };

        const iconName = conditionIconMap[condition.toLowerCase()];

        if (!iconName) {
            console.error('icon for condition not found');
            return null;
        }

        return <Icon viewBox="0 0 24 20" name={iconName} className={className} />;
    };

    return (
        <AppCard>
            <AppCardHeader
                icon={{ name: 'deal', color: 'green' }}
                title="Trade-In Details"
                description={DateTime.fromISO(lead.created_at as string).toLocaleString(DateTime.DATETIME_SHORT)}
            />

            <DescriptionList columns={2}>
                {data.year && <DescriptionListItem label="Year" value={data.year} />}
                {data.make && <DescriptionListItem label="Make" value={data.make} />}
                {data.model && <DescriptionListItem label="Model" value={data.model} />}
                {data.trim_name && <DescriptionListItem label="Trim" value={data.trim_name} />}
                {data.state_name && <DescriptionListItem label="State" value={data.state_name} />}
                {data.mileage && <DescriptionListItem label="Mileage" value={data.mileage} />}
                {data.condition && (
                    <DescriptionListItem label="Condition">
                        <ConditionIcon condition={data.condition} className="clx-margin-right-8" />
                        {data.condition}
                    </DescriptionListItem>
                )}
                {data.owner_amount && <DescriptionListItem label="Previous Owners" value={data.owner_amount} />}
            </DescriptionList>

            {(data.estimate_low || data.estimate_average || data.estimate_high) && (
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Trade-In Estimate" as="Header" />
                    <Panel>
                        <ListGroup columns={3}>
                            {data.estimate_low && (
                                <ListGroupItem label="Low Estimate">
                                    <span className="clx-font-size-20">
                                        {moneyFormatter(parseInt(data.estimate_low))}
                                    </span>
                                </ListGroupItem>
                            )}
                            {data.estimate_average && (
                                <ListGroupItem label="Average Estimate">
                                    <span className="clx-font-size-20">
                                        {moneyFormatter(parseInt(data.estimate_average))}
                                    </span>
                                </ListGroupItem>
                            )}
                            {data.estimate_high && (
                                <ListGroupItem label="High Estimate">
                                    <span className="clx-font-size-20">
                                        {moneyFormatter(parseInt(data.estimate_high))}
                                    </span>
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    </Panel>
                </>
            )}

            {lead.questionResponses && lead.questionResponses?.length > 0 && (
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Questions" as="Header" />
                    <DescriptionList columns={2}>
                        {lead.questionResponses.map(val => {
                            return (
                                <DescriptionListItem
                                    label={`${val.tag && val.tag !== QuestionDefinitionAdHocTag ? `${val.tag}: ` : ''}${val.text}`}
                                    value={val.response || `No Response Provided`}
                                />
                            );
                        })}
                    </DescriptionList>
                </>
            )}
        </AppCard>
    );
};
