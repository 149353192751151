import { get } from 'lodash';

// TODO: Note this implementation can go away when https://github.com/formium/formik/issues/1238 gets resolved.
export const shouldUpdateFastField = (nextProps: any, currentProps: any) => {
    const key = currentProps.name.split('.')[0];
    const inputUpdated = get(nextProps.formik.values, key).length !== get(currentProps.formik.values, key).length;

    return inputUpdated ||
        nextProps.name !== currentProps.name ||
        get(nextProps.formik.values, currentProps.name) !== get(currentProps.formik.values, currentProps.name) ||
        get(nextProps.formik.errors, currentProps.name) !== get(currentProps.formik.errors, currentProps.name) ||
        get(nextProps.formik.touched, currentProps.name) !== get(currentProps.formik.touched, currentProps.name) ||
        Object.keys(currentProps).length !== Object.keys(nextProps).length ||
        nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ? true
        : false;
};

export const createNumericalInputOptions = (count: number) =>
    [...Array(count).keys()].map(item => ({
        label: `${item + 1}`,
        value: item + 1
    }));
