import React from 'react';
import * as Yup from 'yup';
import { ActionList, Modal, useSnackbar } from '../../../components-v2/shared';
import {
    Form,
    ButtonOutlinePrimary,
    InputText,
    ModalHeader,
    ModalActions,
    ButtonPrimary
} from '../../../components-v2/shared';
import useStore from '../../../store/useStore';
import { handlePromise } from '../../../util/async';
import { Link } from 'mobx-router';
import './invite-new-company-modal.scss';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import { fieldSchemas } from '../../../util/validations/formSchemas';

const InviteNewCompanyModalContent: React.FC<{ agencyId: number; setOpen: (open: boolean) => void }> = ({
    agencyId,
    setOpen
}) => {
    const { store, agencyStore } = useStore();
    const { Api } = store;
    const { activeAgency } = agencyStore;
    const { addSnackbar } = useSnackbar();

    const handleSubmit = async (formValues: { email: string }) => {
        const email = formValues.email;

        const [response, error] = await handlePromise<{ data: { token: string } }>(
            Api.client.post('/company-invite/create-invite', { email, agency_id: agencyId })
        );

        if (!response || error) {
            addSnackbar(`Error sending email invite.`, {
                variant: 'error'
            });
            return;
        }

        setOpen(false);
        addSnackbar(`Company invite email has been sent to ${email}.`, {
            variant: 'success'
        });
    };

    return (
        <div className="invite-new-company-modal">
            <ModalHeader title="Send invite to setup company" />
            <Form
                validationSchema={Yup.object().shape({ email: fieldSchemas.email.standard })}
                initialValues={{ email: '' }}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <div className="invite-new-company-modal-content">
                        <InputText
                            label="Email"
                            placeholder="example@email.com"
                            name="email"
                            formikProps={formikProps}
                        />
                        <ButtonOutlinePrimary type="submit" disabled={!formikProps.isValid}>
                            Send
                        </ButtonOutlinePrimary>
                    </div>
                )}
            </Form>
            <ModalActions>
                <ActionList position="end">
                    <Link
                        view={activeAgency ? DefaultRoutes.AgencyCompanyCreate : DefaultRoutes.CompanyCreate}
                        params={store.router.params}
                        store={store}
                    >
                        <span className="invite-new-company-modal-content-or">or</span>
                        <ButtonPrimary onClick={() => setOpen(false)}>Create a new company</ButtonPrimary>
                    </Link>
                </ActionList>
            </ModalActions>
        </div>
    );
};

export const InviteNewCompanyModal: React.FC<{
    agencyId: number;
    open: boolean | undefined;
    setOpen: (open: boolean) => void;
}> = ({ agencyId, open, setOpen }) => {
    if (open === undefined) {
        return <></>;
    }

    return (
        <Modal isOpen={open} onAfterClose={() => setOpen(false)}>
            <InviteNewCompanyModalContent agencyId={agencyId} setOpen={setOpen} />
        </Modal>
    );
};
