import React, { useReducer } from 'react';
import { observer } from 'mobx-react';
import { FormikHelpers } from 'formik';

import { AppPage, AppHeader, ActionList, useSnackbar } from '../../components-v2/shared';
import { DeleteOfferModal, EditOfferForm, ToggleOfferSwitch } from './components';

import useStore from '../../store/useStore';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { useAsyncEffect } from '@lambdacurry/component-library';
import { concessionManagerReducer, concessionManagerInitialState } from './ConcessionManager.helpers';
import { Offer } from '../../types/Offer';

export const ConcessionManagerEdit = observer(() => {
    const [state, dispatch] = useReducer(concessionManagerReducer, concessionManagerInitialState);
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, offers, fetchOffers, fetchCrmConfigOptions } = store;
    const { companyId, offerId } = router.params;
    const offer = offers.getItem(parseInt(offerId, 10));

    const fetchRequiredData = async () => {
        if (!companyId) {
            return;
        }

        await fetchOffers(parseInt(offerId, 10));
        await fetchCrmConfigOptions();
    };
    useAsyncEffect(fetchRequiredData);

    const initialValues = () => {
        if (!offer) {
            return {};
        }

        return {
            ...offer,
            pathnames: offer.pathnames && offer.pathnames.length > 0 ? offer.pathnames : [''], // Make sure we have a pathname to avoid console errors
            active: !!offer.active,
            active_time_start: offer?.active_time_start ? new Date(offer.active_time_start) : undefined,
            active_time_end: offer?.active_time_end ? new Date(offer.active_time_end) : undefined,
            active_time_ongoing: !!!offer.active_time_end,
            override_lead_notification: !!offer.override_lead_notification
        };
    };

    const handleSubmit = async ({ active_time_ongoing, ...values }: Offer, actions: FormikHelpers<Partial<Offer>>) => {
        try {
            await offers.update(values);
            addSnackbar(`Offer "${offer.offer_text}" has been updated.`, { variant: 'success' });
        } catch (error) {
            actions.setErrors(error.response.data);
        }
    };

    return (
        <>
            <AppPage loading={!offer}>
                {offer && (
                    <>
                        <AppHeader
                            title={`Spotlight: ${offer.offer_text}`}
                            icon={{ name: 'concessionManager', color: 'green' }}
                            onBack={() => router.goTo(DefaultRoutes.ConcessionManagerList, { companyId }, store)}
                        >
                            <ActionList position="end">
                                <ToggleOfferSwitch offer={offer} offers={offers} />
                            </ActionList>
                        </AppHeader>
                        <EditOfferForm
                            enableReinitialize={true}
                            initialValues={initialValues() as Offer}
                            onSubmit={handleSubmit}
                        />

                        <DeleteOfferModal
                            isOpen={state.modal.active === 'deleteOffer'}
                            dispatch={dispatch}
                            offers={offers}
                            offer={offer}
                            onAfterDelete={() => router.goTo(DefaultRoutes.ConcessionManagerList, { companyId }, store)}
                        />
                    </>
                )}
            </AppPage>
        </>
    );
});
