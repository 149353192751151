import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store/useStore';
import { OnboardingSuccessModal } from './OnboardingSuccessModal/OnboardingSuccessModal';
import DefaultRoutes from '../../../routes/DefaultRoutes';

export const GlobalModals: React.FC<{}> = observer(() => {
    const { store } = useStore();
    const { router } = store;
    const { queryParams } = router;

    const findCurrentRoute = () =>
        Object.values(DefaultRoutes).find(route => {
            return route.path === router.currentView.originalPath;
        });

    const onAfterClose = (searchParam: string) => {
        const newQueryParams = { ...queryParams };
        delete newQueryParams[searchParam];
        router.goTo(findCurrentRoute(), router.params, store, newQueryParams);
    };

    return (
        <>
            <OnboardingSuccessModal queryParams={queryParams} onAfterClose={onAfterClose} />
        </>
    );
});
