import React, { FC } from 'react';
import { IconRegistry as LCIconRegistry } from '@lambdacurry/component-library';
import { ReactComponent as partner } from '../../assets/icons/partner.svg';
import { ReactComponent as scoreboard } from '../../assets/icons/scoreboard.svg';
import { ReactComponent as incomeCalculator } from '../../assets/icons/incomeCalculator.svg';
import { ReactComponent as conditionExcellent } from '../../assets/icons/conditionExcellent.svg';
import { ReactComponent as conditionAverage } from '../../assets/icons/conditionAverage.svg';
import { ReactComponent as conditionBelowAverage } from '../../assets/icons/conditionBelowAverage.svg';
import { ReactComponent as details } from '../../assets/icons/details.svg';
import { ReactComponent as pieChart } from '../../assets/icons/pieChart.svg';
import { ReactComponent as ellipsis } from '../../assets/icons/ellipsis.svg';
import { ReactComponent as messageUnread } from '../../assets/icons/messageUnread.svg';
import { ReactComponent as phoneWarning } from '../../assets/icons/phoneWarning.svg';
import { ReactComponent as radioFilledCancel } from '../../assets/icons/radioFilledCancel.svg';
import { ReactComponent as play } from '../../assets/icons/play.svg';
import { ReactComponent as scheduleReport } from '../../assets/icons/scheduleReport.svg';
import { ReactComponent as positionBr } from '../../assets/icons/position-br.svg';
import { ReactComponent as positionBm } from '../../assets/icons/position-bm.svg';
import { ReactComponent as positionBl } from '../../assets/icons/position-bl.svg';
import { ReactComponent as positionTr } from '../../assets/icons/position-tr.svg';
import { ReactComponent as positionTl } from '../../assets/icons/position-tl.svg';
import { ReactComponent as preview } from '../../assets/icons/preview.svg';
import { ReactComponent as demoMode } from '../../assets/icons/demoMode.svg';
import { ReactComponent as clxIcon } from '../../assets/icons/clxIcon.svg';

export const clxIcons = {
    partner,
    scoreboard,
    incomeCalculator,
    conditionExcellent,
    conditionAverage,
    conditionBelowAverage,
    details,
    pieChart,
    ellipsis,
    messageUnread,
    phoneWarning,
    radioFilledCancel,
    play,
    scheduleReport,
    positionBr,
    positionBm,
    positionBl,
    positionTr,
    positionTl,
    preview,
    demoMode,
    clxIcon
};

export type CLXIconNames = keyof typeof clxIcons;

export const IconRegistry: FC<any> = props => <LCIconRegistry icons={clxIcons} {...props} />;
