import * as moment from 'moment';

export const DEFAULT_DAY_RANGE = 7;
export const FILTER_METRIC_CODE = 'metric';
export const FILTER_ACTIVE_CSS = 'filter-active';
export const FILTER_PASSIVE_CSS = 'filter-passive';

export const filterDefault = (startSubstract?: number) => {
    if (startSubstract === undefined) {
        startSubstract = DEFAULT_DAY_RANGE;
    }

    return {
        start: moment()
            .subtract(startSubstract, 'days')
            .unix(),
        end: moment().unix()
    };
};
