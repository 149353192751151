import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { dateTimeFormats } from '../../constants';
import { Col, PageHeader, Row, Button } from 'react-bootstrap';
import { BootstrapTable, BootstrapTableProps, TableHeaderColumn } from 'react-bootstrap-table';
import { InjectedProps } from '../../types';
import moment from 'moment';
import CompaniesAudit from './CompaniesAudit';
import AppsActivation from './AppsActivationAudit';
import { ActionList, AppHeader, AppPage, InputSelect } from '../../components-v2/shared';
import { LayoutContext } from '../../components/Layout/LayoutWrapper';
import './audit_log.scss';

@inject('store')
@observer
class AuditLogs extends React.Component {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state = {
        fetchedData: false,
        audit: [],
        auditCompanies: [],
        auditApps: [],
        usersSelected: true,
        companiesSelected: false,
        appsSelected: false,
        agencyIdIsAlreadySelected: false,
        updatedAgencyId: 0,
    };

    public async componentDidMount() {
        const { fetchAuditUsers, fetchAuditCompanies, fetchAuditApps, router, agencyStore } = this.injected.store;
        const { activeAgencyId } = agencyStore;
        const audit = await fetchAuditUsers({ agency_id: activeAgencyId });
        const auditCompanies = await fetchAuditCompanies({ agency_id: activeAgencyId });
        const auditApps = await fetchAuditApps({ agency_id: activeAgencyId });

        const params = router.queryParams || {};

        if (audit.error || auditCompanies.error) {
            return;
        }
        this.setState({
            audit,
            auditCompanies,
            auditApps,
            fetchedData: true,
            search: params.search || '',
            agencyIdIsAlreadySelected: activeAgencyId ? true : false
        });
    }

    public get tableOptions(): BootstrapTableProps {
        return {
            pagination: true,
            search: true,
            striped: true,
            exportCSV: true,
            data: this.state.audit,
            options: {
                defaultSortName: 'time_of_action',
                defaultSortOrder: 'asc'
            }
        };
    }

    public get CompaniesTableOptions(): BootstrapTableProps {
        return {
            pagination: true,
            search: true,
            striped: true,
            exportCSV: true,
            data: this.state.auditCompanies,
            options: {
                defaultSortName: 'time_of_action',
                defaultSortOrder: 'asc'
            }
        };
    }

    public toggleUsers() {
        this.setState({
            usersSelected: !this.state.usersSelected,
            companiesSelected: false,
            appsSelected: false
        });
    }

    public toggleCompanies() {
        this.setState({
            usersSelected: false,
            appsSelected: false,
            companiesSelected: !this.state.companiesSelected
        });
    }

    public toggleApps() {
        this.setState({
            usersSelected: false,
            companiesSelected: false,
            appsSelected: !this.state.appsSelected
        });
    }
    public render() {
        if (!this.state.fetchedData) {
            return null;
        }
        return (
            <AppPage className="messages-page">
                <AppHeader title="Audit Logs" className="py-0">
                    <ActionList className='drop-down-partner' position="end">
                        {!this.state.agencyIdIsAlreadySelected && (
                            <LayoutContext.Consumer>
                                {({ accessibleAgencies }) => {
                                    const options = accessibleAgencies.map(item => ({ label: item.name, value: item.id }))
                                    const { fetchAuditUsers, fetchAuditCompanies, fetchAuditApps } = this.injected.store;
                                    return (<InputSelect
                                        style={{ width: 240 }}
                                        label='Partner'
                                        name='partners'
                                        options={options}
                                        onChange={async (event: React.ChangeEvent<any>, arg: { value: string | '' }) => {
                                            const { value } = arg || ''
                                            if (value) {
                                                const updatedAgencyId = Number(value)
                                                const audit = await fetchAuditUsers({ agency_id: updatedAgencyId });
                                                const auditCompanies = await fetchAuditCompanies({ agency_id: updatedAgencyId });
                                                const auditApps = await fetchAuditApps({ agency_id: updatedAgencyId });
                                                this.setState({
                                                    audit,
                                                    auditCompanies,
                                                    auditApps,
                                                    updatedAgencyId: updatedAgencyId
                                                });
                                            }
                                            else {
                                                this.setState({
                                                    updatedAgencyId: 0
                                                });
                                            }
                                        }}
                                        inputProps={{ maxLength: 60, maxWidth: 100 }}
                                        allowCreateOption
                                    />)
                                }
                                }
                            </LayoutContext.Consumer>
                        )}

                    </ActionList>
                </AppHeader>
                {this.state.agencyIdIsAlreadySelected || this.state.updatedAgencyId ? (<>
                    <Button className='audit-logs-btn'
                        onClick={() => {
                            this.toggleUsers();
                        }}
                    >
                        Users
                    </Button>
                    <Button className='audit-logs-btn'
                        onClick={() => {
                            this.toggleCompanies();
                        }}
                    >
                        Companies
                    </Button>
                    <Button className='audit-logs-btn'
                        onClick={() => {
                            this.toggleApps();
                        }}
                    >
                        Apps Activation
                    </Button>
                    {this.state.usersSelected && (
                        <>
                            <PageHeader>Users</PageHeader>
                            <Col className="col-lg-12">
                                <Row>
                                    <BootstrapTable {...this.tableOptions}>
                                        <TableHeaderColumn dataField="old_id" isKey width="50">
                                            ID
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField="old_email" width="140" dataSort>
                                            Email
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField="old_first_name" width="120">
                                            First Name
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField="old_last_name" width="120">
                                            Last Name
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField="operation" width="100">
                                            Operation
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="time_of_action"
                                            width="150"
                                            dataSort
                                            dataFormat={this.dateFormat}
                                        >
                                            Date
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField="updated_by" width="140" dataSort>
                                            Updated by
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </Row>
                            </Col>
                        </>
                    )}
                    {this.state.companiesSelected && <CompaniesAudit CompaniesTableOptions={this.CompaniesTableOptions} />}
                    {this.state.appsSelected && <AppsActivation />}
                </>) : (
                    <div><h3 className="audit-log-error-msg">Select a partner to view audit logs.</h3></div>
                )}

            </AppPage>

        );
    }

    public dateFormat = (cell: any, row: any) => {
        return moment(cell).format(dateTimeFormats.auditTime);
    };
}

export default AuditLogs;
