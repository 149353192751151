import * as React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { dateTimeFormats } from '../../constants';
import CommonList from '../__deprecated/Filter/CommonList';
import CommonFilter from '../__deprecated/Filter/CommonFilter';
import { FilterCommon, ListCommon } from '../../types';
import DefaultRoutes from '../../routes/DefaultRoutes';
import ButtonSpinner from '../misc/ButtonSpinner';
import { getRouteWithContext } from '../../routes/routeUtils';

export interface DefinedFields {
    first_name: string[];
    last_name: string[];
    email: string[];
    phone: string[];
}

@inject('store')
@observer
class InsiteMetricDataList extends CommonList {
    public listCode = 'leads-insite';
    public filterCode = '';

    public state = {
        data: [],
        loading: true,
        total: 0,
        show: false,
        content: '',
        isLoading: false,
        isSaving: {},
        isError: {},
        counterCompanies: 0
    };

    public fetchData(listCode: string, filterCode: string, listDefault: ListCommon, filterDefault: FilterCommon) {
        const { fetchInsiteMetaData } = this.injected.store;

        return fetchInsiteMetaData(listCode, filterCode, listDefault, filterDefault).then((response: any) => {
            this.setState({
                data: response.data.widget_metric_data_events,
                total: response.data.count,
                counterCompanies: response.data.count_companies,
                loading: false
            });
        });
    }

    public getCsvFile = (args: any) => {
        const { Api } = this.injected.store;

        return Api.getCSVLeadsInsite(args);
    };

    public render() {
        const { activeCompanyId, isAgencyRole } = this.injected.store;
        const hasActiveCompany = !!activeCompanyId;

        return (
            <>
                <PageHeader>Insite Metric</PageHeader>
                <Col className="col-lg-12 wrapper-holder__tible-leads">
                    <Row>
                        <div className="colors-legend-wrapper">
                            {this.createCustomExportCSVButton()}
                            <div className="colors-legend-wrapper">
                                <button className="btn btn-primary btn-right" onClick={this.goToInsiteDefineFieldsView}>
                                    Define insite fields
                                </button>
                                <span>&nbsp;</span>
                                <button className="btn btn-primary btn-right" onClick={this.goToInsitePageView}>
                                    Insite Pages
                                </button>
                            </div>
                        </div>

                        <CommonFilter
                            applyFilter={this.applyFilter}
                            hidePMCFilter={hasActiveCompany}
                            hideBCFilter={hasActiveCompany}
                            hideDMAFilter={hasActiveCompany}
                            hideOGFilter={hasActiveCompany}
                            hideRegionFilter={hasActiveCompany || isAgencyRole}
                            hideCompanyFilter={hasActiveCompany}
                            showSearchFilter={true}
                            hideLeadStateFilter={true}
                            listCode={this.listCode}
                            filterCode={this.filterCode}
                            counterCompanies={this.state.counterCompanies}
                        />
                        {!this.state.loading ? (
                            <div className="insite-defined-holder">
                                <BootstrapTable {...this.tableOptions()}>
                                    <TableHeaderColumn dataField="id" isKey hidden />
                                    <TableHeaderColumn
                                        dataField="created_at"
                                        width="150"
                                        dataSort
                                        dataFormat={this.dateFormat}
                                    >
                                        Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="company_id"
                                        dataFormat={this.companyNameFormatter}
                                        width="180"
                                        dataSort
                                    >
                                        Company
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="host_uri" dataSort>
                                        Host
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="data" dataFormat={this.dataFormatter}>
                                        Data
                                    </TableHeaderColumn>

                                    <TableHeaderColumn dataField="customer_id" dataFormat={this.TableActions}>
                                        Action
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        ) : null}
                    </Row>
                </Col>
            </>
        );
    }

    protected dataFormatter = (cell: DefinedFields) => {
        return JSON.stringify(cell);
    };

    protected onClose = () => {
        this.setState({ show: false });
    };

    protected TableActions = (leadOrCustomerId: number, data: DefinedFields | any) => {
        const { router, activeCompanyId } = this.injected.store;

        return leadOrCustomerId ? (
            <button
                className="btn btn-primary btn-right"
                onClick={() => {
                    return router.goTo(
                        getRouteWithContext('LeadDetailsByCustomer', router),
                        { customerId: leadOrCustomerId, companyId: activeCompanyId },
                        this.injected.store
                    );
                }}
            >
                Go To Lead
            </button>
        ) : (
            this.button(data, data.id)
        );
    };

    public createLead = (data: DefinedFields & { id: number; company_id: number }) => {
        const { createInsiteLead } = this.injected.store;
        return createInsiteLead(data.id, data.company_id);
    };

    public dateFormat = (cell: Date) => {
        return moment(cell).format(dateTimeFormats.leadDateTime);
    };

    public goToInsitePageView = () => {
        const { activeCompanyId, router } = this.injected.store;
        const { activeAgencyId } = this.injected.store.agencyStore;

        if (activeCompanyId) {
            router.goTo(DefaultRoutes.CompanyInsitePageList, { companyId: activeCompanyId }, this.injected.store);
        } else if (activeAgencyId) {
            router.goTo(DefaultRoutes.AgencyInsitePageList, { agencyId: activeAgencyId }, this.injected.store);
        } else {
            router.goTo(DefaultRoutes.InsitePageList, {}, this.injected.store);
        }
    };

    public goToInsiteDefineFieldsView = () => {
        const { activeCompanyId, router } = this.injected.store;
        const { activeAgencyId } = this.injected.store.agencyStore;

        if (activeCompanyId) {
            router.goTo(
                DefaultRoutes.CompanyInsiteDefinedFieldsList,
                { companyId: activeCompanyId },
                this.injected.store
            );
        } else if (activeAgencyId) {
            router.goTo(DefaultRoutes.AgencyInsiteDefinedFieldsList, { agencyId: activeAgencyId }, this.injected.store);
        } else {
            router.goTo(DefaultRoutes.InsiteDefinedFieldsList, {}, this.injected.store);
        }
    };

    public clickHandler = async (data: any) => {
        const { router, fetchInsiteDefinedFields } = this.injected.store;
        const currentView = { ...this.injected.store.router.currentView! };
        const listHosts = await fetchInsiteDefinedFields('', '', {});
        const hostId = listHosts.data.widget_insite_defined_fields.filter((obj: any) => {
            return obj.host_uri === data.host_uri;
        })[0].id;

        if (!hostId) {
            return;
        }
        router.goTo(DefaultRoutes.InsiteDefinedFieldsEdit, { id: hostId, backRoute: currentView }, this.injected.store);
    };

    public button = (data: any, id: number) => {
        const key = 'id' + id;

        return (
            <>
                <div className="special-holder">
                    {this.state.isError[key] ? (
                        <>
                            <div className="help-block">
                                Can not create a lead.
                                <button
                                    className="btn btn-primary btn-right"
                                    onClick={() => {
                                        this.clickHandler(data).catch(() => {
                                            return;
                                        });
                                    }}
                                >
                                    Define Fields
                                </button>
                            </div>
                        </>
                    ) : (
                        <ButtonSpinner
                            isSaving={this.state.isSaving[key]}
                            onClick={() => {
                                const newIsSaving = { ...this.state.isSaving };
                                newIsSaving[key] = true;

                                this.setState({
                                    isSaving: newIsSaving
                                });

                                this.createLead(data).then(
                                    (res: any) => {
                                        const itemData = JSON.parse(res.data);
                                        const list: any = [...this.state.data];
                                        list.forEach((lead: any) => {
                                            if (lead.id === itemData.id) {
                                                lead.lead_id = itemData.lead_id;
                                            }
                                        });
                                        const stateIsSaving = { ...this.state.isSaving };
                                        stateIsSaving[key] = false;

                                        this.setState({
                                            data: list,
                                            isSaving: stateIsSaving
                                        });
                                    },
                                    () => {
                                        const stateIsSaving = { ...this.state.isSaving };
                                        stateIsSaving[key] = false;
                                        const stateIsError = { ...this.state.isError };
                                        stateIsError[key] = true;

                                        this.setState({
                                            isSaving: stateIsSaving,
                                            isError: stateIsError
                                        });
                                    }
                                );
                            }}
                        >
                            Save As Lead
                        </ButtonSpinner>
                    )}
                </div>
            </>
        );
    };
}

export default InsiteMetricDataList;
