import { timeZoneList } from '../constants/strings';
import { LeadStatus, LeadType } from '../types/Lead';
import { NotificationSettings } from '../types/NotificationSettings';
import { Company } from './../types/Company';
import { DEFAULT_SMS_MESSAGE_SCHEDULING } from '../constants';
import { get, omit } from 'lodash';
import { TextUs } from '../types/TextUs';
import { QuestionResponseFormat } from '../types/QuestionSettings';

export const auth = {};
export const emptyUser = {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    pass: '',
    confirm_pass: '',
    role_id: 0,
    company_ids: [],
    role: {
        name: '',
        slug: 'guest'
    },
    reset_password: true
};
export const users = {};
export const companies = {};
export const agencies = {};
export const calendars = {};
export const apps = {};
export const roles = {};
export const emptyApp = {
    name: ''
};
export const emptyNotification = {
    crm_email: '',
    lead_notification_primary_emails: '',
    reply_to_email: '',
    phone: '',
    crm_config_id: null,
    sms_message_create: ''
};
export const emptyEmailSetting = {
    email_subject: ''
    // custom_message_to_prospect: ''
};
export const emptyQuestion = {
    question_bundle: {
        questions: []
    }
};
export const emptyQuestionBpn = {
    question_bundle: {
        questions: [
            {
                required: true,
                response_format: QuestionResponseFormat.text,
                text: 'How many past owners?',
                label: 'Number of past owners'
            },
            {
                required: true,
                response_format: QuestionResponseFormat.text,
                text: 'What is your desired replacement vehicle?',
                label: 'Desired replacement vehicle'
            }
        ]
    }
};

export const emptyAddress = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: ''
};

export const emptyAgency = {
    id: 0,
    name: '',
    logo_url: '',
    internal: false,
    active: true,
    company_price_id: '',
    ...emptyAddress
};

export const emptyAgencyCustomizationColors = {
    primary_color: '',
    secondary_color: '',
    primary_button_text_color: '',
    secondary_button_text_color: ''
};

export const emptyAgencyCustomization = {
    id: 0,
    agency_id: 0,
    agency_name: '',
    agency_logo: '',
    subdomain: '',
    default_base_hostname: '',
    widget_hostname: '',
    dashboard_hostname: '',
    deployed_dashboard_hostname: '',
    deployed_subdomain: '',
    deployed_widget_hostname: '',
    reply_to_email: '',
    ...emptyAgencyCustomizationColors
};

export const emptyCompany = {
    id: 0,
    name: '',
    website_url: '',
    assigned_users: [],
    logo: '',
    logo_file: [],
    active: true,
    weekly_notification: true,
    is_test: false,
    rev_link_google: '',
    rev_link_yelp: '',
    rev_link_google_small: '',
    rev_link_yelp_small: '',
    rev_link_google_date: '',
    rev_link_yelp_date: '',
    request_review_google:
        'If we provided top-notch service to you please consider taking a few seconds to leave us a review.',
    request_review_yelp:
        'If we provided top-notch service to you please consider taking a few seconds to leave us a review.',
    ...emptyNotification,
    notification_bar_message: null,
    notification_bar_color: '#FFFFFF',
    notification_bar_background_color: '#FF0000',
    notification_bar_links: [{ caption: '', url: '' }]
};

export const emptyCompanyAppLink = {
    id: 0,
    company_id: 0,
    app_id: 0,
    app_data: '{}'
};

export const emptyPublicRole = {
    id: 0,
    api_key: '',
    company_id: 0,
    skin: 'default',
    is_mini: false,
    css_id: 0,
    ga_id: '',
    gm_id: '',
    widget_hidden: false,
    widget_disabled: false,
    widget_logo: '',
    widget_logo_url: '',
    use_custom_colors: 0,
    color_default: '',
    color_background_default: '',
    color_background_icon_small: '',
    color_error: '',
    color_error_field: '',
    color_border_show_hide_button: '',
    color_button: '',
    color_month: '',
    color_back_month: '',
    color_calendar_day: '',
    color_calendar_background: '',
    color_calendar_past_day: '',
    color_back_calendar_past_day: '',
    color_back_calendar_day_hover: '',
    color_background_submit_button: '',
    color_available_times_item: '',
    color_background_available_times_item: '',
    color_background_available_times_item_hover: '',
    color_border_focus: '',
    color_background_chevron: '#ffa500',
    hidden_widgets: [],
    hidden_widgets_mobile: [],
    widget_animation_style: '',
    font: 'inherit',
    widget_position: '',
    widget_position_mobile: '',
    widgets_order: ['MODULE_SCHEDULEGENIE', 'MODULE_CONCESSIONMANAGER'],
    widgets_order_mobile: ['MODULE_SCHEDULEGENIE', 'MODULE_CONCESSIONMANAGER'],
    widgetbar_expanded: false,
    widget_draggable: false,
    widget_code_css: '',
    widget_code_mobile_css: '',
    widget_launcher_button: 'multi',
    widget_spacer_color: 'transparent',
    widget_spacer_enabled: true,
    widget_adjustments: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        mobile_top: 0,
        mobile_left: 0,
        mobile_bottom: 0,
        mobile_right: 0
    },
    widget_zoom_desktop: '',
    widget_zoom_mobile: '',
    beta_widget_enabled: false,
    brand_primary_color: '',
    brand_text_color: '',
    widget_size: '',
    widget_style: ''
};

const browserTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const emptyCalendar = {
    id: 0,
    company_id: 0,
    schedule_id: 0,
    address: '',
    slot_length: 0,
    appointment_length: 15,
    slots_per_day: 0,
    slot_remove_after_taken: false,
    slot_amount_in_same_time: 1,
    slot_amount_in_same_time_sun: 0,
    slot_amount_in_same_time_mon: 0,
    slot_amount_in_same_time_tue: 0,
    slot_amount_in_same_time_wed: 0,
    slot_amount_in_same_time_thu: 0,
    slot_amount_in_same_time_fri: 0,
    slot_amount_in_same_time_sat: 0,
    period_start_work: 2880, // 2 days
    max_appointment_period: '1 year',
    slot_taken_status: '',
    lead_email_attachement: '',
    call_to_action: 'Schedule Now',
    create_success_message: 'Tour Confirmed!',
    confirmation_text: '',
    question_bundle: { questions: [] },
    active: true,
    direct_link_text: '',
    direct_link_url: '',
    thanks_for_scheduling_text: 'Thanks for scheduling an appointment at',
    custom_message_to_prospect: '',
    we_will_see_you_text: 'We’ll see you on',
    use_dynamic_scheduling: false,
    use_external_crm_scheduling: false,
    external_crm_scheduling_available: false,
    block_external_appointments: '',
    selected_appointment_types: [],
    appointment_types_enabled: false,
    pixel_url: '',
    // notification fields
    override_lead_notification: false,
    ...emptyNotification,
    ...emptyEmailSetting
};
const USFederalHolidays = 1;
export const emptySchedule = {
    id: 0,
    company_id: 0,
    time_zone: browserTimezoneName && timeZoneList[browserTimezoneName] ? browserTimezoneName : 'UTC',
    name: '',
    calendar_daysoff_id: USFederalHolidays,
    work_b_sun: '10:00am',
    work_e_sun: '06:00pm',
    break_b_sun: '',
    break_e_sun: '',
    is_closed_sun: false,
    work_b_mon: '10:00am',
    work_e_mon: '06:00pm',
    break_b_mon: '',
    break_e_mon: '',
    is_closed_mon: false,
    work_b_tue: '10:00am',
    work_e_tue: '06:00pm',
    break_b_tue: '',
    break_e_tue: '',
    is_closed_tue: false,
    work_b_wed: '10:00am',
    work_e_wed: '06:00pm',
    break_b_wed: '',
    break_e_wed: '',
    is_closed_wed: false,
    work_b_thu: '10:00am',
    work_e_thu: '06:00pm',
    break_b_thu: '',
    break_e_thu: '',
    is_closed_thu: false,
    work_b_fri: '10:00am',
    work_e_fri: '06:00pm',
    break_b_fri: '',
    break_e_fri: '',
    is_closed_fri: false,
    work_b_sat: '10:00am',
    work_e_sat: '06:00pm',
    break_b_sat: '',
    break_e_sat: '',
    is_closed_sat: false
};

export const emptySGLead = {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    lead_time: '10:00am',
    lead_date: new Date(),
    lead_type: LeadType.SG,
    status: LeadStatus.APPOINTMENT_SCHEDULED,
    question1: '',
    question2: '',
    question3: '',
    question4: '',
    answer1: '',
    answer2: '',
    answer3: '',
    answer4: ''
};

export const emptyCMLead = {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    status: LeadStatus.OPEN_OPPORTUNITY,
    offer_text: '',
    offer_description: ''
};

export const emptyBPNLead = {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    status: '',
    question1: '',
    question2: '',
    question3: '',
    answer1: '',
    answer2: '',
    answer3: '',
    year: 0,
    make: '',
    model: '',
    trim: '',
    trim_name: '',
    state: '',
    state_name: '',
    mileage: 0,
    condition: '',
    owner_amount: '1'
};

export const emptyCrm = {};

export const emptyOffer = {
    id: 0,
    company_id: 0,
    offer_text: '',
    offer_description: '',
    offer_menu_title: 'Get a special offer',
    call_to_action: '',
    disclaimer: '',
    email_customer_text: '',
    include_ready_to_take_action_text: true,
    include_contact_information_text: true,
    fine_print: '',
    active: false,
    hide_offer_on_specific_pages: false,
    concession_automation_enabled: false,
    attachment_link: '',
    ...emptyQuestion,

    direct_link_text: '',
    direct_link_url: '',
    suggest_schedule_appointment: false,
    pathnames: [''], // Make sure we have a pathname to avoid console errors
    widget_icon: 'clxicon-icons_concession_manager',
    // notification fields
    override_lead_notification: false,
    submission_button_text: 'Submit',
    ...emptyNotification,
    ...emptyEmailSetting
};

export const emptyBpn = {
    id: 0,
    company_id: 0,
    bpn_menu_title: 'Get your trade-in value',
    active: false,
    high_trade_multiplier: 1,
    ave_trade_multiplier: 1,
    low_trade_multiplier: 1,
    close_cta_title: '',
    close_cta_url: '',

    // notification fields
    override_lead_notification: false,
    ...emptyNotification,
    ...emptyEmailSetting,
    ...emptyQuestionBpn
};

export const emptyCallUs = {
    id: 0,
    company_id: 0,
    schedule_id: null,
    call_to_action_text: 'Call us now',
    active: false,
    phone: ''
};

export const emptyTextUs: TextUs = {
    id: 0,
    company_id: 0,
    schedule_id: 0,
    twilio_id: 0,
    phone_num_full: '+14251115555',
    phone_num_friendly: '(425) 111-5555',
    call_to_action_text: 'Text us now',
    active: false,
    enable_auto_response_message: false,
    is_forward_sms_enable: false,
    forward_phone_number: '',
    notification_start_time: '',
    notification_end_time: '',
};

export function companyEmptyCallUs(company?: Company): { phone: string } {
    return {
        phone: get(company, 'phone', emptyNotification.phone)
    };
}

export const emptyScheduleDayoffCustom = {
    schedule_id: 0,
    date_off: new Date(),
    entire_dayoff: true,
    open_work_b1: '10:00am',
    open_work_e1: '06:00pm'
};

export const emptyRemoteAuth = {
    id: 0,
    key: '',
    name: '',
    type_id: 0
};

export const emptyChat = {
    ...omit(emptyNotification, ['sms_message_create']), // Chat model doesn't have `sms_message_create` from notification settings so we need it to be undefined
    id: 0,
    active: true,
    title_name: '',
    company_id: 0,
    schedule_id: null,
    account_id: '',
    website_id: '',
    bd_id: '',
    show_floating_invite: false,
    override_lead_notification: false
};

export const emptyCalendarDayoff = {
    title: '',
    daysoff: [{ name: '', month: 1, day: 1 }]
};

export function companyEmptyNotification(company?: Company): NotificationSettings {
    return {
        crm_config_id: get(company, 'crm_config_id', emptyNotification.crm_config_id) || 0,
        crm_email: get(company, 'crm_email', emptyNotification.crm_email),
        lead_notification_primary_emails: get(
            company,
            'lead_notification_primary_emails',
            emptyNotification.lead_notification_primary_emails
        ),
        reply_to_email: get(company, 'reply_to_email', emptyNotification.reply_to_email),
        phone: get(company, 'phone', emptyNotification.phone),
        sms_message_create: DEFAULT_SMS_MESSAGE_SCHEDULING
    };
}

export const emptyIncomeCalculator = {
    active: false,
    menu_title: 'See if you qualify',
    disclaimer: '',
    qualification_policy: '',
    question1_label: 'Number of individuals who will live in your household.',
    question2_label: 'What is your yearly income before taxes?',
    question3_label: 'What floorplan are you looking for?',
    configuration: null,
    custom_message_to_prospect: '',
    feature_slug: 'income_calculator',
    question_bundle: {
        questions: []
    },
    ...emptyEmailSetting
};

export const emptySms = {
    id: 0,
    company_id: 0,
    lead_id: 0,
    status: '',
    from_number: '',
    to_number: '',
    to_name: '',
    message: '',
    message_error: ''
};

export const emptyCategory = {
    title: ''
};

export const emptyOwnershipGroup = {
    name: ''
};

export const emptyRegions = {
    name: ''
};

export const emptyPropertyManagementCompany = {
    name: ''
};

export const emptyAppointmentType = {
    name: '',
    slug: ''
};

export const emptyInsite = {
    id: 0,
    company_id: 0,
    active: true,
    auto_create_leads: false
};

export const emptyFeatureFlags = {
    flags_id: 0,
    name: '',
    allow_global: false,
    company_has_flag: false
};

export const emptyLeadReminder = {
    title: '',
    description: '',
    remind_at: new Date()
};

export const emptyLeadNote = {
    title: '',
    note: '',
    note_small: ''
};

export const emptyLeadSms = {
    message: ''
};

export const emptyChatBotQuestion = {
    id: 0,
    company_id: 0,
    name: '',
    display_name: '',
    description: '',
    enabled: false,
    behavior: '',
    action: ''
};
