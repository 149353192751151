import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    ButtonOutlinePrimary,
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../../constants'

export interface ResManFormProps {
    initialValues: ResManCrm;
    onSubmit: (values: ResManCrm, actions: FormikHelpers<ResManCrm>, crmType: string) => Promise<void>;
    onValidate: (values: ResManCrm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface ResManCrm extends Crm {
    configuration: {
        integration_partner_id: string;
        api_key: string;
        account_id: string;
        property_id: string;
    };
}


export const ResManForm: FC<ResManFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<ResManCrm>) => {
        await onValidate(formikProps.values, crmConfigTypes.resman);
    };

    const handleSubmit = async (values: ResManCrm, actions: FormikHelpers<ResManCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.resman);
    };

    const configurationFields = [
        {
            name: 'configuration.integration_partner_id',
            label: 'Integration Partner ID',
            helperText: 'ResMan Integration Partner ID',
        },
        {
            name: 'configuration.api_key',
            label: 'API Key',
            helperText: 'ResMan API Key',
        },
        {
            name: 'configuration.account_id',
            label: 'Account ID',
            helperText: 'ResMan Account ID',
        },
        {
            name: 'configuration.property_id',
            label: 'Property ID',
            helperText: 'ResMan Property ID',
        },
    ];
    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<ResManCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Resman" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 64 }}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader
                                    title="ResMan Configuration"
                                    subtitle="Contact ResMan support for the access token and workflow ID."
                                />
                                {
                                    configurationFields.map((field) => (
                                        <InputText
                                            key={field.name}
                                            name={field.name}
                                            label={field.label}
                                            helperText={field.helperText}
                                            required={true}
                                            formikProps={formikProps}
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    ))
                                }
                            </div>
                        </AppSection>

                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
