import { Form, FormikProps } from 'formik';
import * as React from 'react';
import {
    ActionList,
    AppFooter,
    AppSection,
    AppSectionHeader,
    ButtonPrimary,
    InputText,
} from '../../components-v2/shared';

export interface IPropertyManagementCompany {
    id?: number;
    name: string;
}

export const labels = {
    name: 'Name'
};

interface ParentProps {
    controls: (bag: FormikProps<IPropertyManagementCompany>) => React.ReactNode;
    editing: boolean;
}

export default (props: ParentProps) => {
    return (bag: FormikProps<IPropertyManagementCompany>) => {
        return (
            <>
                <Form>
                    <AppSection>
                        <div className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="Property Management Company" />
                                <InputText name = "name" label="Property Management Company" formikProps={bag} />
                            </div>
                        </div>
                    </AppSection>
                    <AppFooter sticky={true}>
                        <ActionList position="end">
                            <ButtonPrimary
                                type="submit"
                                onClick={() => props.controls}
                            >
                                {bag.isSubmitting ? 'Saving...' : 'Save'}
                            </ButtonPrimary>
                        </ActionList>
                    </AppFooter>
                </Form>
            </>
        );
    };
};
