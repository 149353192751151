import * as Yup from 'yup';

export const ChatBotAIValidationSchema = () =>
    Yup.object({
        greeting: Yup.string().required('Please add a Greeting.').min(4, 'Greeting must be above 4 characters.').max(60, 'Greeting must be under 60 characters.'),
        call_to_action: Yup.string().required('Please add a Call to Action.').min(4, 'Call to Action must be above 4 characters.').max(60, 'Call to Action may only be 60 characters.')
    });

export const ChatBotAIWidgetCodeValidationSchema = Yup.object().shape({
    widget_id: Yup.string().required('Please add a widget id.'),
});
