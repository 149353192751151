import React, { MutableRefObject, Reducer, useReducer, useState } from 'react';
import { observer } from 'mobx-react';
import {
    AppHeader,
    AppPage,
    IconButton,
    Form,
    ButtonPrimary,
    useSnackbar,
    InputSwitch,
    ButtonOutlinePrimary,
    ModalHeader,
    ModalActions,
    ActionList,
    Modal,
    Icon,
    ButtonDanger
} from '../../components-v2/shared';

import useStore from '../../store/useStore';
import styles from './list-segment-editor.module.scss';
import { handlePromise, useAsyncEffect } from '../../util/async';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { FormikHelpers, FormikProps } from 'formik';
import { ListSegment } from './ListSegmentEditor.types';
import { EditListSegmentNameForm } from './EditListSegmentNameForm';
import Button from '@inovua/reactdatagrid-community/packages/Button'
import { DateTime } from 'luxon';
import {
    listSegmentEditorEditSegmentReducer,
    ListSegmentEditorEditSegmentReducerAction,
    ListSegmentEditorEditSegmentReducerState
} from './ListSegmentEditor.helpers';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import { Link } from 'mobx-router';

export interface ListSegmentFormEntry {
    id?: number;
    list_segment_id: number;
    active: boolean;
    deleted_at?: DateTime;
}

export interface EditListSegmentForm {
    entries: ListSegmentFormEntry[];
}

interface EntryRow {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
}

export const ListSegmentEditorEditSegmentPage = observer(() => {
    const { store } = useStore();
    const { router, Api } = store;
    const { addSnackbar } = useSnackbar();
    const [entries, setEntries] = useState<any[] | undefined>(undefined);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [selectedEntryRow, setSelectedEntryRow] = useState<EntryRow>();
    const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null>>({ current: null });
    const { companyId, listSegmentId } = router.params;

    const [state, dispatch] = useReducer<
        Reducer<ListSegmentEditorEditSegmentReducerState, ListSegmentEditorEditSegmentReducerAction>
    >(listSegmentEditorEditSegmentReducer, {
        modal: {
            active: 'none',
            data: {}
        },
        listSegment: {} as ListSegment
    });
    const {
        isEditingName,
        listSegment
        // modal
    } = state;

    const fetchSegment = async () => {
        const [response, err] = await handlePromise<{ data: ListSegment }>(
            Api.client.get(`company/${companyId}/list-segments/${listSegmentId}`)
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setListSegment', payload: response.data });
    };

    const fetchEntries = async () => {
        const [response, err] = await handlePromise<{ data: any[] }>(
            Api.client.get(`company/${companyId}/list-segments/${listSegmentId}/entries`)
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }

        setEntries(response.data);
    };

    const fetchData = async () => {
        await fetchSegment();
        await fetchEntries();
    };

    useAsyncEffect(fetchData);

    const downloadBlob = (blob, fileName = 'grid-data.csv') => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    const exportCSV = () => {
        const SEPARATOR = ',';
        const columns = gridRef?.current?.visibleColumns;

        if (!columns || !entries || !listSegment.name) {
            return;
        }

        const header = columns.map((c) => c.name).join(SEPARATOR);
        const rows = entries.map((data) => columns.map((c) => data[c.id]).join(SEPARATOR));

        const contents = [header].concat(rows).join('\n');
        const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

        downloadBlob(blob, `${listSegment.name}.csv`);
    };

    const renderPhone = ({ value }) => {
        return value && value.length > 10 ?
            `(${value.slice(2, 5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`
            : ''
    }

    const handleEditSegmentName: (
        values: { name: string },
        formikHelpers: FormikHelpers<{ name: string }>
    ) => void = async values => {
        const { company_id, ...updatedSegment } = listSegment;
        const [response, err] = await handlePromise<{ data: ListSegment }>(
            Api.client.patch(`company/${companyId}/list-segments`, { ...updatedSegment, name: values.name })
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setIsEditingName', payload: false });
        dispatch({ name: 'setListSegment', payload: response.data });
    };

    const handleActiveToggle = async (selectedListSegmentId?: number) => {
        if (!selectedListSegmentId) {
            return;
        }

        const [toggleListSegmentResponse, toggleListSegmentError] = await handlePromise<{
            data: ListSegment;
        }>(Api.client.patch(`company/${companyId}/list-segments/${selectedListSegmentId}/toggle`));

        if (toggleListSegmentError || !toggleListSegmentResponse) {
            addSnackbar(toggleListSegmentError.response.data || 'Failed to toggle List Segment', {
                variant: 'error'
            });
            return;
        }

        dispatch({ name: 'setListSegment', payload: toggleListSegmentResponse.data });
    };

    const handleFormSubmit: (
        values: EditListSegmentForm,
        formikHelpers: FormikHelpers<EditListSegmentForm>
    ) => void = async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        const data = {
            ...listSegment,
            entries: values.entries
        };
        const [response, err] = await handlePromise<{ data: ListSegment }>(
            Api.client.patch(`company/${companyId}/list-segments`, data)
        );

        formikHelpers.setSubmitting(false);

        if (err || !response) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setListSegment', payload: response.data });

        formikHelpers.setFieldValue('entries', response.data.entries);
        formikHelpers.resetForm();
        addSnackbar(`List segment has been updated.`, {
            variant: 'success'
        });
    };

    const deleteButtonHandler = async (leadCustomerId: number) => {
        const [response, err] = await handlePromise<{ data: ListSegment }>(
            Api.client.delete(`company/${companyId}/list-segments/${listSegmentId}/entries/${leadCustomerId}`)
        );
        if (!response?.data || !!err) {
            addSnackbar(`There was an error removing the entry from the list.`, {
                variant: 'error'
            });
            return;
        }

        setEntries(entries?.filter(entry => entry.id !== leadCustomerId));
    }

    const deleteEntryModal = (entryRow: EntryRow) => {
        setSelectedEntryRow(entryRow);
        setDeleteModalOpen(true);
    }

    const renderDeleteButton = ({ value, data }) => {
        return <Button type="button" onClick={() => {
            deleteEntryModal(data);
        }}>Remove</Button>;
    }

    return (
        <AppPage loading={!listSegment.name}>
            <AppHeader
                title={`${!isEditingName ? listSegment.name : ''}`}
                onBack={
                    !isEditingName
                        ? () => router.goTo(DefaultRoutes.ListSegmentEditorListPage, { companyId }, store)
                        : undefined
                }
            >
                {isEditingName ? (
                    <EditListSegmentNameForm
                        name={listSegment.name}
                        onSubmit={handleEditSegmentName}
                        onCancel={() => dispatch({ name: 'setIsEditingName', payload: false })}
                    />
                ) : (
                    <IconButton
                        className={styles['segment-editor-edit-name-button']}
                        icon="pencil"
                        onClick={() => dispatch({ name: 'setIsEditingName', payload: true })}
                    />
                )}
                <div className="flex-spacer" />
                <InputSwitch
                    onClick={() => handleActiveToggle(listSegment.id)}
                    checked={listSegment.active}
                    label="Enabled"
                />
            </AppHeader>
            <Form
                enableReinitialize
                validateOnMount
                confirmUnsavedChanges={true}
                unsavedChangesConfig={{
                    containerQuerySelectorAll: `[class*="app-header "] ~ *`
                }}
                initialValues={{ entries: listSegment.entries }}
                onSubmit={handleFormSubmit}
            >
                {(formikProps: FormikProps<EditListSegmentForm>) => {
                    return (
                        <>
                            <div>
                                <div style={{ margin: 16 }}>
                                    {!entries || entries.length === 0 ? (
                                        <div style={{ margin: 40 }}><p>Your list is empty.</p><p>Add contacts using Advanced Search.</p><Link
                                            view={DefaultRoutes.CompanyAdvancedCustomerSearch}
                                            params={{ companyId }}
                                            store={store}
                                        ><ButtonOutlinePrimary>Advanced Search</ButtonOutlinePrimary></Link></div>
                                    ) : (
                                        <>
                                            <Button style={{ marginBottom: 16 }} onClick={() => exportCSV()}>
                                                Export to CSV
                                            </Button>
                                            <ReactDataGrid
                                                dataSource={entries || []}
                                                onReady={setGridRef}
                                                idProperty="lead_customer_id"
                                                preventRowSelectionOnClickWithMouseMove={true}
                                                columns={[
                                                    { name: 'button', header: '', defaultFlex: 1, render: renderDeleteButton },
                                                    { name: 'first_name', header: 'First Name', defaultFlex: 2 },
                                                    { name: 'last_name', header: 'Last Name', defaultFlex: 2 },
                                                    { name: 'email', header: 'Email Address', minWidth: 200, defaultFlex: 4 },
                                                    { name: 'phone_number', header: 'Phone Number', defaultFlex: 3, render: renderPhone },
                                                    { name: 'is_agree_sms_notification', header: 'SMS', minWidth: 60, defaultFlex: 1, render: ({ value }) => value === 'Yes' ? 'Yes' : 'No' },
                                                    { name: 'created_at', header: 'Created', minWidth: 120, defaultFlex: 1, render: ({ value }) => value ? DateTime.fromISO(value).toISODate() : '' },
                                                ]}
                                                loading={false}
                                                style={{ minHeight: 683 }}
                                                pagination='local'
                                                defaultLimit={15}
                                            /></>
                                    )}
                                </div>
                            </div>
                        </>
                    );
                }}
            </Form>
            <Modal isOpen={deleteModalOpen && !!selectedEntryRow} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="color-danger" />
                            {selectedEntryRow ? <>Are you sure you want to remove {selectedEntryRow.first_name} {selectedEntryRow.last_name} ({selectedEntryRow.email})
                                from the list?</> :
                                <>Are you sure you want to remove this entry from the list?</>}
                        </>
                    }
                />
                <p>This action cannot be undone.</p>
                <ModalActions>
                    <ActionList position="end">
                        <ButtonPrimary
                            onClick={() => {
                                setSelectedEntryRow(undefined);
                                setDeleteModalOpen(false);
                            }}
                        >
                            Cancel
                        </ButtonPrimary>
                        <ButtonDanger
                            onClick={() => {
                                selectedEntryRow && deleteButtonHandler(selectedEntryRow.id);
                                setDeleteModalOpen(false);
                            }}
                        >
                            Confirm
                        </ButtonDanger>
                    </ActionList>
                </ModalActions>
            </Modal>
        </AppPage>
    );
});
