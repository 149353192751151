export const buildUrlParamsQuery = (params: object): string => {
    let u = '';
    if (params) {
        const keysFilter = Object.keys(params);
        keysFilter.forEach(k => {
            if (params[k]) {
                u += `${k}=${params[k]}&`;
            }
        });
    }
    return u;
};

// eslint=disable-next-line no-useless-escape
export const urlRegExp: RegExp = /^(?:https?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
