import * as Yup from 'yup';
import { Behaviors } from './components/EditQuestionForm';

export const EditChatBotQuestionValidationSchema = () =>
    Yup.object({
        name: Yup.string()
            .max(25, 'The question name may be no more than 25 characters.')
            .required('Virtual Assistant questions must have a name.'),
        display_name: Yup.string()
            .max(25, 'The display name may be no more than 25 characters')
            .required('Virtual Assistant questions must have a display name'),
        description: Yup.string(),
        // enabled: Yup.boolean(),
        behavior: Yup.string(),
        action: Yup.string()
            .when('behavior', {
                is: val => val === Behaviors.Link,
                then: Yup.string().required('Link field is required.')
            })
            .when('behavior', {
                is: val => val === Behaviors.OpenOffer,
                then: Yup.string().required('Please select a Spotlight offer.')
            })
            .when('behavior', {
                is: val => val === Behaviors.OpenLiveChat,
                then: Yup.string().required('BoldChat Website ID field is required')
            })
            .when('behavior', {
                is: val => val === Behaviors.ResponseText,
                then: Yup.string().required('Response Text field is required')
            })
    });

export const ChatBotValidationSchema = () =>
    Yup.object({
        greeting: Yup.string().required('Please add a Greeting.').min(4, 'Greeting must be above 4 characters.').max(60, 'Greeting must be under 60 characters.'),
        call_to_action: Yup.string().required('Please add a Call to Action.').min(4, 'Call to Action must be above 4 characters.').max(60, 'Call to Action may only be 60 characters.')
    });
