import React, { ReactNode, useState, useEffect } from 'react';
import { createMuiTheme, ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import useStore from '../store/useStore';
import { getCssVar } from '../util/colors';

const defaultTheme = {
    typography: {
        fontFamily: 'inherit'
    },
    palette: {
        primary: {
            main: '#0054fe'
        },
        secondary: {
            main: '#4ab8ff'
        },
        error: {
            main: '#e53e3e'
        }
    }
};

export const MUIThemeProvider = observer(({ children }: { children: ReactNode }) => {
    const { agencyStore } = useStore();
    const [theme, setTheme] = useState<{}>();

    // Note: Apply custom production prefix to MaterialUI classNames to avoid name collisions with our component library.
    // See: https://material-ui.com/styles/api/
    const classNameOptions = {
        productionPrefix: 'clx-mui-'
    };

    useEffect(() => {
        const computedStyle = getComputedStyle(document.documentElement);
        setTheme({
            ...defaultTheme,
            palette: {
                ...defaultTheme.palette,
                primary: {
                    main: `rgb(${getCssVar(`primary_color`, computedStyle)})`
                },
                secondary: {
                    main: `rgb(${getCssVar(`secondary_color`, computedStyle)})`
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agencyStore.agencyCustomization]);

    if (!agencyStore.agencyCustomization) {
        return <></>;
    }

    return (
        <ThemeProvider theme={createMuiTheme(theme)}>
            <StylesProvider generateClassName={createGenerateClassName(classNameOptions)}>{children}</StylesProvider>
        </ThemeProvider>
    );
});
