import React, { useEffect, useState } from 'react';
import useStore from '../../store/useStore';
import {
    ActionList,
    AppHeader,
    Button,
    ButtonPrimary,
    Form,
    InputSwitch,
    useSnackbar,
    AppPage,
    AppSection,
    InputText,
    NotificationSettingsFieldGroup,
    AppContent,
    AppFooter,
} from '../../components-v2/shared';
import { handlePromise, useAsyncEffect } from '../../util/async';
import { AppsName, ChatbotAI } from '../../types';
import { FormikHelpers, FormikProps } from 'formik';
import { ChatBotAIValidationSchema } from './ChatBotAI.validation';
import { formatValues, returnAsArray } from './ChatbotAI.helpers';
import { CompanyRoutes } from '../../routes/CompanyRoutes';

export const ChatBotAIComponent = () => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, chatbotAI, Api, fetchChatbotAI } = store;
    const { companyId } = router.params;
    const chatBotAiItems = chatbotAI.values.length ? chatbotAI.values[0] : null;
    const [active, setActive] = useState<boolean>(chatBotAiItems && chatBotAiItems[0]?.active);
    const [loading, setLoading] = useState<boolean>(true);
    const [activateWidgetToggle, setActivateWidgetToggle] = useState<boolean>(false);
    const [activateWidgetCodeBtn, setActivateWidgetCodeBtn] = useState<boolean>(false);

    const fetchRequiredData = async () => {
        await fetchChatbotAI();
        setLoading(false);
    };
    useAsyncEffect(fetchRequiredData);

    useEffect(() => {
        const items = returnAsArray(chatBotAiItems);
        if (items) {
            setActivateWidgetToggle(!!items[0]?.widget_id && !!items[0]?.greeting && !!items[0]?.call_to_action)
            setActivateWidgetCodeBtn(!!items[0]?.greeting && !!items[0]?.call_to_action)
            setActive(items[0]?.active || false);
        }
    }, [loading, chatBotAiItems, activateWidgetToggle, activateWidgetCodeBtn])

    const handleToggleClick = async () => {
        try {
            const items = returnAsArray(chatBotAiItems)
            const paylaod = {
                "active": !active,
                "company_id": parseInt(companyId),
                "widget_id": items[0]?.widget_id,
                "story_id": items[0]?.story_id,
                "greeting": items[0]?.greeting,
                "call_to_action": items[0]?.call_to_action
            }
            await chatbotAI.updateChatWidget(paylaod);
            setActive(!active);
            addSnackbar(`Claire AI+ Now has been ${active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });
            const [response, error] = await handlePromise(
                Api.client.patch(`public_roles/updateActiveModules`, {
                    toggleType: active ? 'remove' : 'add',
                    company_id: companyId,
                    moduleName: AppsName.CBAI
                })
            );

            if (!response?.data || error) {
                return console.error('error: ', error);
            }
        } catch (error) {
            addSnackbar(`Failed to ${active ? 'deactivate' : 'activate'} Claire AI+.`, {
                variant: 'error'
            });
        }
    };

    const handleSubmit = async (values: ChatbotAI, actions: FormikHelpers<ChatbotAI>) => {
        try {
            setLoading(true);
            await chatbotAI.updateChatWidget(formatValues(values, companyId));
            addSnackbar('Claire AI+ has been updated', {
                variant: 'success'
            });
        } catch (error) {
            actions.setErrors(error.response.data);
            addSnackbar('Failed to update Claire AI+.', {
                variant: 'error'
            });
        } finally {
            setLoading(false)
        }
    };

    const getInitialValues = () => {
        const items = returnAsArray(chatBotAiItems);

        if (!items[0]?.call_to_action && !items[0]?.greeting) {
            return {
                ...chatbotAI.values[0],
                greeting: '',
                call_to_action: ''
            };
        }
        return items[0];
    };

    const handleManageTopics = () => {
        router.goTo(CompanyRoutes.ChatBotAIWidgetCode, { companyId }, store);
    };

    return (
        <>
            <AppPage>
                <AppHeader title="Chat With Claire AI+" icon={{ name: 'chat', color: 'blue' }}>
                    <ActionList position="end">
                        <InputSwitch
                            disabled={!activateWidgetToggle}
                            labelOn="Active"
                            labelOff="Inactive"
                            labelPlacement="start"
                            onClick={handleToggleClick}
                            checked={active}
                        />
                    </ActionList>
                </AppHeader>
                <Form
                    enableReinitialize
                    initialValues={getInitialValues()}
                    onSubmit={handleSubmit}
                    validationSchema={ChatBotAIValidationSchema}
                    confirmUnsavedChanges
                    unsavedChangesConfig={{}}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {(formikProps: FormikProps<ChatbotAI>) => {
                        return (
                            <AppSection>
                                <InputText formikProps={formikProps} name="call_to_action" label="Call to Action" />
                                <InputText formikProps={formikProps} name="greeting" label="Greeting" />
                                <AppContent className="field-group-content">
                                    <div className="field-group-col">
                                        <NotificationSettingsFieldGroup formikProps={formikProps} crmBlacklist={[]} />
                                    </div>
                                </AppContent>
                                <AppFooter>
                                    <ActionList position="end">
                                        <Button disabled={!activateWidgetCodeBtn} onClick={handleManageTopics}>Widget Code</Button>
                                        {formikProps.dirty && (
                                            <Button
                                                onClick={() => formikProps.resetForm()}
                                                disabled={formikProps.isSubmitting}
                                                data-lc-trigger-unsaved-changes={true}
                                                data-test-id="button_cancel"
                                            >
                                                Cancel
                                            </Button>
                                        )}
                                        <ButtonPrimary
                                            type="submit"
                                            disabled={!formikProps.dirty || formikProps.isSubmitting}
                                            data-test-id="button_save"
                                        >
                                            {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                        </ButtonPrimary>
                                    </ActionList>
                                </AppFooter>
                            </AppSection>
                        );
                    }}
                </Form>
            </AppPage>
        </>
    );
}
