import React, { Fragment } from 'react';
import { AppEventLiveChat } from './AppEventLiveChat';
import { LeadType, Lead } from '../../../../types';
import './app-event-cards.scss';
import { AppEventIncomeCalculator } from './AppEventIncomeCalculator';
import { AppEventBPN } from './AppEventBPN';
import { AppEventScheduleGenie } from './AppEventScheduleGenie';
import { AppEventTextUs } from './AppEventTextUs';
import { AppEventInsite } from './AppEventInsite';
import { AppEventConcessionManager } from './AppEventConcessionManager';
import { AppCard, AppCardGrid } from '../../../../components-v2/shared';
import { AppEventChatbotAI } from './AppEventChatbotAI';
import { AppEventLiveChatLegacy } from './AppEventLiveChatLegacy';

export const AppEventCards: React.FC<{ data?: Lead[]; updateLead: (lead: Lead) => void }> = ({ data, updateLead }) => {
    if (!data) {
        return <AppCard>Loading...</AppCard>;
    }

    const appEventCards = {
        [LeadType.CH]: (appEvent: Lead) => <AppEventLiveChatLegacy lead={appEvent} />,
        [LeadType.IC]: (appEvent: Lead) => <AppEventIncomeCalculator lead={appEvent} />,
        [LeadType.BPN]: (appEvent: Lead) => <AppEventBPN lead={appEvent} />,
        [LeadType.SG]: (appEvent: Lead) => <AppEventScheduleGenie lead={appEvent} updateLead={updateLead} />,
        [LeadType.TU]: (appEvent: Lead) => <AppEventTextUs lead={appEvent} />,
        [LeadType.IN]: (appEvent: Lead) => <AppEventInsite lead={appEvent} />,
        [LeadType.CM]: (appEvent: Lead) => <AppEventConcessionManager lead={appEvent} />,
        [LeadType.CBAI]: (appEvent: Lead) => <AppEventChatbotAI lead={appEvent} />,
        [LeadType.LC]: (appEvent: Lead) => <AppEventLiveChat lead={appEvent} />,
    };

    return (
        <AppCardGrid>
            {data.map((appEvent, index: number) => (
                <Fragment key={`${appEvent.lead_type}-${index}`}>
                    {appEventCards[appEvent.lead_type](appEvent)}
                </Fragment>
            ))}
        </AppCardGrid>
    );
};
