import { FormikProps } from "formik";
import React, { useState } from "react";
import {
    Form,
    AppSection,
    InputText,
    ActionList,
    ButtonPrimary,
    AppContent,
    useSnackbar,
    AppSectionHeader,
    InputSwitch,
    NotificationSettingsFieldGroup,
    AppFooter,
    Button,

} from '../../components-v2/shared';
import { returnAsArray, formatValues } from "./LiveChat.helpers";
import { FormikHelpers } from 'formik';
import { ILiveChat } from "../../types";
import { LivechatValidationSchema } from "./LiveChat.validations";


export const CallToAction = ({ liveChat, companyId, active, setActivateWidgetToggle }) => {
    const { addSnackbar } = useSnackbar();
    const livechatItem = liveChat.values.length ? liveChat.values[0] : null;
    const [activeStandalone, setActiveStandalone] = useState<boolean>(livechatItem && livechatItem[0]?.show_as_standalone);
    const [showOnTop, setShowOnTop] = useState<boolean>(livechatItem?.[0]?.show_on_top ?? false);

    const getInitialValues = () => {
        const items = returnAsArray(livechatItem);

        if (!items[0]?.call_to_action) {
            return {
                ...items[0],
                call_to_action: ''
            };
        }
        return items[0];
    };

    const handleSubmit = async (values: ILiveChat, actions: FormikHelpers<ILiveChat>) => {
        try {
            await liveChat.updateChatWidget(formatValues(values, companyId));
            setActivateWidgetToggle(true);
            addSnackbar('Livechat has been updated.', {
                variant: 'success'
            });
        } catch (error) {
            actions.setErrors(error.response.data);
            addSnackbar('Failed to update Livechat.', {
                variant: 'error'
            });
        }
    };

    const handleStandaloneToggle = () => {
        setActiveStandalone(!activeStandalone);
    }

    return (
        <Form
            enableReinitialize
            initialValues={getInitialValues()}
            onSubmit={handleSubmit}
            validationSchema={LivechatValidationSchema}
            confirmUnsavedChanges
            unsavedChangesConfig={{}}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {(formikProps: FormikProps<ILiveChat>) => {
                return (
                    <AppSection>
                        <InputText formikProps={formikProps} name="call_to_action" label="Call to Action" />
                        <AppContent className="field-group-content">
                            <div className="field-group-col">
                                <NotificationSettingsFieldGroup formikProps={formikProps} crmBlacklist={[]} />
                            </div>
                            <div className="field-group-col" style={{
                                backgroundColor: 'rgb(245, 246, 250)',
                                padding: '20px',
                                borderRadius: '20px'
                            }}>
                                <AppSectionHeader title="Enable Standalone Widget" >
                                    <InputSwitch
                                        labelOn="Enabled"
                                        labelOff="Disabled"
                                        labelPlacement="start"
                                        formikProps={formikProps}
                                        name={'show_as_standalone'}
                                        checked={activeStandalone}
                                        onClick={handleStandaloneToggle}
                                        disabled={!active}
                                    />
                                </AppSectionHeader>

                                <AppSectionHeader title="Show Widget On Top" >
                                    <InputSwitch
                                        labelOn="Enabled"
                                        labelOff="Disabled"
                                        labelPlacement="start"
                                        formikProps={formikProps}
                                        name={'show_on_top'}
                                        checked={showOnTop}
                                        onClick={() => { setShowOnTop(!showOnTop) }}
                                        disabled={!active}
                                    />
                                </AppSectionHeader>
                            </div>
                        </AppContent>

                        <AppFooter>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button
                                        onClick={() => formikProps.resetForm()}
                                        disabled={formikProps.isSubmitting}
                                        data-lc-trigger-unsaved-changes={true}
                                        data-test-id="button_cancel"
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary
                                    type="submit"
                                    disabled={!formikProps.dirty || formikProps.isSubmitting}
                                    data-test-id="button_save"
                                >
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </AppSection>
                );
            }}
        </Form>
    )
}

