import React, { Reducer, useReducer, useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'mobx-router';
import {
    IconButton,
    AppHeader,
    AppPage,
    AppContent,
    AppList,
    AppListItem,
    AppListItemIcon,
    AppListEmpty,
    ButtonUnstyled,
    AppListItemContent,
    ActionList,
    ButtonPrimary,
    Modal,
    ModalHeader,
    Form,
    InputText,
    ModalActions,
    Button,
    InputSelect,
    useSnackbar,
    Icon
} from '../../components-v2/shared';
import DefaultRoutes from '../../routes/DefaultRoutes';
import useStore from '../../store/useStore';
import { handlePromise, useAsyncEffect } from '../../util/async';
import { AppEmail, AppEmailDetailsMap, appEmailIsActive } from './EmailEditor.helpers';
import { ReactComponent as Illustration } from './EmptyEmailTemplatesIllustration.svg';
import {
    emailEditorListReducer,
    EmailEditorListReducerAction,
    EmailEditorListReducerState,
    LeadNurtureAppSource
} from './EmailEditorList.helpers';
import { FormikHelpers, FormikProps } from 'formik';
import { CreateTemplateFormValidationSchema } from './EmailEditorValidationSchema';
import { LeadNurtureTemplate } from './EmailEditor.types';
import { FEATURES } from '../../types/Features';

interface CreateTemplateForm {
    app_source_type: LeadNurtureAppSource;
    template_preset: string;
}

interface LeadNurtureTemplateBundle {
    apps: Array<{ id: string; name: string }>;
    presets: Array<{ id: string; name: string; app: LeadNurtureAppSource }>;
}

export const EmailEditorListPage = observer(() => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { router, Api, features } = store;
    const { companyId } = router.params;
    const [appEmails, setAppEmails] = useState<Array<AppEmail>>();
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [selectedAppEmail, setSelectedAppEmail] = useState<AppEmail>();
    const [leadNurtureTemplateBundle, setLeadNurtureTemplateBundle] = useState<LeadNurtureTemplateBundle>();
    const [appTypeFilter, setAppTypeFilter] = useState<LeadNurtureAppSource>();

    const enabledApps = [LeadNurtureAppSource.customer];
    if (features[FEATURES.schedule_genie]) {
        enabledApps.push(LeadNurtureAppSource.schedule_genie);
    }
    if (features[FEATURES.concession_manager]) {
        enabledApps.push(LeadNurtureAppSource.spotlight);
    }

    const leadNurturingEnabled = features[FEATURES.lead_nurturing] && enabledApps.length > 0;

    const [state, dispatch] = useReducer<Reducer<EmailEditorListReducerState, EmailEditorListReducerAction>>(
        emailEditorListReducer,
        {
            modal: {
                active: 'none',
                data: {}
            }
        }
    );

    const { modal } = state;

    const lnDataSourceList = [
        { id: LeadNurtureAppSource.customer, name: 'General' },
        { id: LeadNurtureAppSource.spotlight, name: 'Spotlight' },
        { id: LeadNurtureAppSource.schedule_genie, name: 'Schedule Genie' }
    ].filter(ds => {
        return enabledApps.includes(ds.id);
    });

    const fetchTemplateList = async () => {
        const [response, err] = await handlePromise(Api.client.get(`mail-templates/company/${companyId}`));

        if (err) {
            // TODO: handle error
            return;
        }
        setAppEmails(response.data);
    };

    const fetchLeadNurtureTemplateBundle = async () => {
        const [response, err] = await handlePromise(
            Api.client.get(`mail-templates/company/${companyId}/lead-nurture-template-bundle`)
        );

        if (err) {
            // TODO: handle error
            return;
        }
        setLeadNurtureTemplateBundle(response.data);
    };

    useAsyncEffect(fetchTemplateList);
    useAsyncEffect(fetchLeadNurtureTemplateBundle);

    const handleOpenModal = (modalName: string, modalData?: object) => {
        dispatch({ name: 'openModal', payload: modalName });

        if (modalData) {
            dispatch({ name: 'setModalData', payload: modalData });
        }
    };

    const handleCreateTemplate: (
        values: CreateTemplateForm,
        formikHelpers: FormikHelpers<CreateTemplateForm>
    ) => void = async ({ app_source_type, template_preset }, formikHelpers) => {
        const newTemplate = {
            company_id: Number.parseInt(companyId, 10),
            template_preset,
            app_source_type
        };
        const [createTemplateResponse, createTemplateError] = await handlePromise<{ data: LeadNurtureTemplate }>(
            Api.client.post(`company/${companyId}/lead-nurture-templates`, newTemplate)
        );

        if (createTemplateError || !createTemplateResponse) {
            // TODO: handle error
            addSnackbar('Failed to create new template.', { variant: 'error' });
            dispatch({ name: 'closeModal', payload: 'createTemplate' });
            return;
        }

        formikHelpers.resetForm();

        router.goTo(
            DefaultRoutes.EmailEditorEditAppEmailPage,
            { companyId, appEmailId: `lead-nurture-${createTemplateResponse.data.id}` },
            store
        );
    };

    const handleTemplateDeleteClick = async (appEmail?: AppEmail) => {
        if (!appEmail || !appEmail.leadNurtureTemplate) {
            return;
        }
        const [deleteTemplateResponse, deleteTemplateError] = await handlePromise<{ data: LeadNurtureTemplate }>(
            Api.client.delete(`company/${companyId}/lead-nurture-templates/${appEmail.leadNurtureTemplate.id}`)
        );
        if (deleteTemplateError || !deleteTemplateResponse) {
            const msg = deleteTemplateError?.response?.data?.msg || 'Failed to delete template.';
            addSnackbar(msg, {
                variant: 'error'
            });
            return;
        }
        addSnackbar(`Template now has been deleted.`, {
            variant: 'success'
        });
        await fetchTemplateList();
    };

    const leadNurtureTemplateFriendlyName = (lnt?: LeadNurtureTemplate): string => {
        const prefix = 'Lead Nurturing';
        if (!lnt) {
            return '';
        }
        switch (lnt.app_source_type) {
            case LeadNurtureAppSource.customer:
                return `${prefix}: General`;
            case LeadNurtureAppSource.schedule_genie:
                return `${prefix}: Schedule Genie`;
            case LeadNurtureAppSource.spotlight:
                return `${prefix}: Spotlight`;
            default:
                return '';
        }
    };

    const toAppType = (app: AppEmail): LeadNurtureAppSource => {
        if (app.leadNurtureTemplate) {
            return app.leadNurtureTemplate.app_source_type;
        }
        if (app.appName === 'schedule_genie') {
            return LeadNurtureAppSource.schedule_genie;
        }
        if (app.appName === 'concession_manager') {
            return LeadNurtureAppSource.spotlight;
        }
        return LeadNurtureAppSource.customer;
    };

    return (
        <>
            <AppPage>
                <AppHeader title="App Email Templates">
                    <div className="flex-spacer" />
                    {leadNurturingEnabled && (
                        <ButtonPrimary onClick={() => handleOpenModal('createTemplate')}>
                            Create New Template
                        </ButtonPrimary>
                    )}
                </AppHeader>
                <AppContent loading={!appEmails}>
                    <AppList>
                        <InputSelect
                            name={`template_filter_selector`}
                            hidden={!leadNurturingEnabled}
                            label="Filter by application type"
                            optionValueKey="id"
                            optionLabelKey="name"
                            onChange={(evt, val) => setAppTypeFilter(val)}
                            value={appTypeFilter}
                            contentEditable={false}
                            allowCreateOption={false}
                            options={lnDataSourceList}
                            autocompleteConfig={{
                                disableClearable: false
                            }}
                        />
                        {appEmails
                            ?.filter(app => {
                                return leadNurturingEnabled || !app.leadNurtureTemplate;
                            })
                            .filter(app => {
                                return !appTypeFilter || appTypeFilter === toAppType(app);
                            })
                            .map(appEmail => {
                                const { id, name, appName, status, leadNurtureTemplate } = appEmail;
                                const { icon, color } = AppEmailDetailsMap[appName] || AppEmailDetailsMap.default;
                                const isActive = appEmailIsActive(appEmail);
                                const rowDescription = leadNurtureTemplateFriendlyName(leadNurtureTemplate);

                                return (
                                    <AppListItem key={name}>
                                        <AppListItemIcon color={isActive ? color : 'light-gray'} name={icon} />
                                        <AppListItemContent
                                            title={
                                                <ButtonUnstyled
                                                    className={!status?.active ? 'inactive' : ''}
                                                    as={buttonProps => (
                                                        <Link
                                                            {...buttonProps}
                                                            view={DefaultRoutes.EmailEditorEditAppEmailPage}
                                                            params={{ companyId, appEmailId: id }}
                                                            store={store}
                                                        />
                                                    )}
                                                >
                                                    {name} {!isActive && '(inactive)'}
                                                </ButtonUnstyled>
                                            }
                                            description={rowDescription}
                                        />

                                        <ActionList position="end">
                                            {leadNurtureTemplate && (
                                                <>
                                                    <IconButton
                                                        icon="trash"
                                                        onClick={() => {
                                                            setSelectedAppEmail(appEmail);
                                                            setDeleteModalOpen(true);
                                                        }}
                                                    />
                                                </>
                                            )}

                                            <IconButton
                                                icon="pencil"
                                                as={buttonProps => (
                                                    <Link
                                                        {...buttonProps}
                                                        view={DefaultRoutes.EmailEditorEditAppEmailPage}
                                                        params={{ companyId, appEmailId: id }}
                                                        store={store}
                                                    />
                                                )}
                                            />
                                        </ActionList>
                                    </AppListItem>
                                );
                            })}
                    </AppList>

                    {appEmails?.length === 0 && (
                        <AppListEmpty
                            illustration={<Illustration />}
                            title="You're almost there!"
                            description="You must choose at least one module for your app before you can access email settings."
                        />
                    )}
                </AppContent>
            </AppPage>
            <Modal
                isOpen={modal.active === 'createTemplate'}
                onAfterClose={() => dispatch({ name: 'closeModal' })}
                closeButton={false}
            >
                <ModalHeader title="New Template" />
                <Form
                    initialValues={{
                        template_preset: '',
                        app_source_type: enabledApps.length === 1 ? enabledApps[0] : ''
                    }}
                    onSubmit={handleCreateTemplate}
                    validationSchema={CreateTemplateFormValidationSchema}
                >
                    {(formikProps: FormikProps<CreateTemplateForm>) => {
                        return (
                            <>
                                <InputSelect
                                    name="app_source_type"
                                    label="Application"
                                    labelPlacement="inset"
                                    optionValueKey="id"
                                    optionLabelKey="name"
                                    options={lnDataSourceList}
                                    formikProps={formikProps}
                                />
                                {leadNurtureTemplateBundle && (
                                    <>
                                        <InputText hidden={true} name="calendar_source_id" formikProps={formikProps} />
                                        <InputSelect
                                            name="template_preset"
                                            label="Template Preset"
                                            labelPlacement="inset"
                                            optionValueKey="id"
                                            optionLabelKey="name"
                                            options={leadNurtureTemplateBundle.presets.filter(tp => {
                                                return tp.app === formikProps.values['app_source_type'];
                                            })}
                                            formikProps={formikProps}
                                        />
                                    </>
                                )}

                                <InputText name="active" value={true} hidden={true} formikProps={formikProps} />

                                <ModalActions>
                                    <div className="flex-spacer" />
                                    <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                                    <ButtonPrimary type="submit" disabled={!leadNurtureTemplateBundle?.presets}>
                                        Create Template
                                    </ButtonPrimary>
                                </ModalActions>
                            </>
                        );
                    }}
                </Form>
            </Modal>
            <Modal isOpen={deleteModalOpen} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="color-danger" />
                            Are you sure you want to remove this template?
                        </>
                    }
                />
                <p>This action cannot be undone.</p>
                <ModalActions>
                    <ActionList position="end">
                        <Button
                            onClick={() => {
                                setDeleteModalOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <ButtonPrimary
                            onClick={() => {
                                handleTemplateDeleteClick(selectedAppEmail);
                                setDeleteModalOpen(false);
                            }}
                        >
                            Confirm
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>
        </>
    );
});
