import { Form, FormikProps } from 'formik';
import * as React from 'react';
import { Panel, FormControl } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

interface DefineFieldsFormProps {
    titleName: string;
    definedFields: {};
    selectItems: [];
    controls: React.ReactNode;
}

export interface DefinedFields {
    titleName: string;
    controls: React.ReactNode;
}
const labels = {};

export default (props: DefineFieldsFormProps) => {
    return (bag: FormikProps<DefinedFields>) => {
        const fieldProps = {
            ...bag,
            labels
        };
        const handleChange = function(field: string, value: []) {
            bag.setFieldValue(field, value);
        };
        const listFields = {
            first_name: 'first_name',
            last_name: 'last_name',
            email: 'email',
            phone: 'phone'
        };

        return (
            <React.Fragment>
                <Form className="fixed-controls">
                    <div className="row">
                        <Panel className="indent-box" bsStyle="primary">
                            <div className="block-indent__padding">
                                <Panel.Body>
                                    <label>Host Name</label>
                                    <FormControl id="host_name" disabled={true} value={props.titleName} />
                                    <br />
                                    <label>First Name</label>
                                    <Typeahead
                                        id={listFields.first_name}
                                        multiple
                                        options={props.selectItems}
                                        selected={fieldProps.values[listFields[listFields.first_name]]}
                                        onChange={handleChange.bind(null, listFields[listFields.first_name])}
                                        placeholder="Please Define Field"
                                    />
                                    <br />
                                    <label>Last Name</label>
                                    <Typeahead
                                        id={listFields.last_name}
                                        multiple
                                        options={props.selectItems}
                                        selected={fieldProps.values[listFields[listFields.last_name]]}
                                        onChange={handleChange.bind(null, listFields[listFields.last_name])}
                                        placeholder="Please Define Field"
                                    />
                                    <br />
                                    <label>Email</label>
                                    <Typeahead
                                        id={listFields.email}
                                        multiple
                                        options={props.selectItems}
                                        selected={fieldProps.values[listFields[listFields.email]]}
                                        onChange={handleChange.bind(null, listFields[listFields.email])}
                                        placeholder="Please Define Field"
                                    />
                                    <br />
                                    <label>Phone</label>
                                    <Typeahead
                                        id={listFields.phone}
                                        multiple
                                        options={props.selectItems}
                                        selected={fieldProps.values[listFields[listFields.phone]]}
                                        onChange={handleChange.bind(null, listFields[listFields.phone])}
                                        placeholder="Please Define Field"
                                    />
                                </Panel.Body>
                            </div>
                        </Panel>
                    </div>
                    {props.controls}
                </Form>
            </React.Fragment>
        );
    };
};
